/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import qs, { ParsedQs } from 'qs';
/**
 * Hooks
 */
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
/**
 * Expo
 */
export const useQueryParams = (): ParsedQs => {
    const { search } = useLocation();

    return useMemo(
        () => qs.parse(search, { ignoreQueryPrefix: true }),
        [search]
    );
};
