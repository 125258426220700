/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
type TRemoveEmptyKeys = Record<string, unknown | undefined>;
/**
 * Expo
 */
export const removeEmptyKeys = (object: TRemoveEmptyKeys): TRemoveEmptyKeys =>
    Object.entries(object).reduce((accumulator, [key, value]) => {
        if (value !== undefined) accumulator[key] = value;
        return accumulator;
    }, {} as TRemoveEmptyKeys);
