/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { STYLE_TO_ALIGNMENT } from '../../../constants';
import { NodeAlignments, StyleAlignments } from 'constants/richTextEditor';
/**
 * Utils
 */
import { requestAttachedImage } from '../utils';
/**
 * Types
 */
import { TGetImage, TSize, TSizeFromEntityData } from './types';
import { IMAGE_DEFAULT_SIZE } from 'constants/file';
/**
 * Expo
 */
export const getImage: TGetImage = async ({ pastedImage, attachedImage }) => {
    if (attachedImage) {
        const uploadedImage = await requestAttachedImage(attachedImage.id);

        return uploadedImage;
    }

    return pastedImage;
};

export const getSizeFromEntityData = ({
    width,
    height,
}: TSizeFromEntityData): TSize | undefined => {
    const numberWidth = Number(width);
    const numberHeight = Number(height);

    if (!numberWidth && !numberHeight) return undefined;

    return {
        width: Number(width),
        height: Number(height),
    };
};
export const getAlignmentStyle = (
    alignment?: StyleAlignments
): { justifyContent?: NodeAlignments } => {
    if (!alignment) return {};

    return {
        justifyContent: STYLE_TO_ALIGNMENT[alignment],
    };
};

export const getImageSize = (
    file: File
): Promise<{ width: number; height: number }> => {
    return new Promise((resolve) => {
        const fileReader = new FileReader();
        const image = new Image();

        fileReader.onload = () => {
            image.onload = () => {
                resolve({
                    width: image.width,
                    height: image.height,
                });
            };

            image.onerror = () => {
                resolve({
                    width: IMAGE_DEFAULT_SIZE.width,
                    height: IMAGE_DEFAULT_SIZE.height,
                });
            };

            image.src = fileReader.result as string;
        };

        fileReader.readAsDataURL(file);
    });
};

export const calculateAspectRatio = (
    width: number,
    height: number,
    movementX: number,
    movementY: number
) => {
    const maxWidth = width + movementX;
    const maxHeight = height + movementY;
    const isDiagonalMovement = movementX && movementY;

    /**
     * If there is a diagonal movement - need to use min ration to have smooth resize
     * Otherwise - need to use max ratio to change size anyway, even if movement only by X or Y
     */
    const ratio = (isDiagonalMovement ? Math.min : Math.max)(
        maxWidth / width,
        maxHeight / height
    );

    return { width: width * ratio, height: height * ratio };
};

// Need to prevent drag event to avoid unexpected behavior
export const preventDrag = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
};
