/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { createSlice } from '@reduxjs/toolkit';
import { ELEMENTS_INITIAL_STATE } from './constants';

export const {
    reducer: elementsReducer,
    actions: {
        saveElements,
        setElementsLoadingInProgress,
        resetElements,
        addElement,
        updateElement,
    },
} = createSlice({
    name: 'elements',
    initialState: ELEMENTS_INITIAL_STATE,
    reducers: {
        saveElements: (state, { payload }) => {
            state.list = payload;
            state.isInitialized = true;
        },
        setElementsLoadingInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        resetElements: () => ELEMENTS_INITIAL_STATE,
        addElement: (state, { payload }) => {
            state.list = [...state.list, payload];
        },
        updateElement: (state, { payload }) => {
            const index = state.list.findIndex(
                (element) => element.id === payload.id
            );
            if (index !== -1) {
                state.list[index] = payload;
            }
        },
    },
});
