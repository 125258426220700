/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { LinkButton } from './LinkButton';
import { BlockStyleControls } from './BlockControls';
import { InlineStyleControls } from './InlineControls';
import { AlignmentControls } from './AlignmentControls';
import { AttachmentsControl } from './AttachmentsControl';
/**
 * Constants
 */
import {
    BLOCK_TYPES,
    HEADING_BLOCK,
    INLINE_STYLES,
    ALIGNMENT_STYLES,
} from './constants';
/**
 * Types
 */
import { TControls } from './types';
import { CodeBlockButton } from './CodeBlockButton';
/**
 * Expo
 */
export const Controls: TControls = {
    LinkButton,
    Attachments: AttachmentsControl,
    BlockStyle: () => (
        <>
            <BlockStyleControls buttonList={BLOCK_TYPES} />
            <CodeBlockButton />
        </>
    ),
    Heading: () => <BlockStyleControls buttonList={[HEADING_BLOCK]} />,
    Alignment: () => <AlignmentControls buttonList={ALIGNMENT_STYLES} />,
    InlineStyle: () => <InlineStyleControls buttonList={INLINE_STYLES} />,
};
