/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { SelectFooter } from 'components';
/**
 * Types
 */
import { TOption } from '../types';
import { PickerFooterProps } from '@epam/uui-core';
/**
 * Expo
 */
export const renderFooter = (
    props: PickerFooterProps<TOption, TOption[keyof TOption]>
): JSX.Element => <SelectFooter<TOption> {...props} />;
