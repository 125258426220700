/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import css from './style.module.scss';
import { IconContainer } from 'components/common';
import { ReactComponent as PlusIcon } from '@epam/assets/icons/action-add-outline.svg';
import { ReactComponent as MinusIcon } from '@epam/assets/icons/content-minus-outline.svg';
import { WithDataTestId } from 'types';

export const ExpandableIcon: React.FC<WithDataTestId & { isOpen: boolean }> = ({
    isOpen,
    dataTestId,
}) => (
    <div className={css.expandableIcon}>
        <IconContainer
            dataTestId={`${dataTestId}/expandButton`}
            icon={isOpen ? MinusIcon : PlusIcon}
        />
    </div>
);
