/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { TPerson } from 'types';
import { TGetRowOptions, TProjectPerson } from './types';
/**
 * Expo
 */
export function getRowOptions(
    persons: TProjectPerson[]
): TGetRowOptions<TPerson> {
    return (option: TPerson) => {
        const isProtectedFromRemove = persons.some(
            (person) => person.id === option.id && !person.is_removable
        );

        return {
            isDisabled: isProtectedFromRemove,
            checkbox: {
                isVisible: true,
                isDisabled: isProtectedFromRemove,
            },
        };
    };
}
