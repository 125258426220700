/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Text, FlexRow } from 'components/common';
import { FlexCell, FlexSpacer } from '@epam/promo';
import { Buttons } from '../Buttons';
/**
 * Types
 */
import { Props } from './types';
/**
 * Expo
 */
export const Header: React.FC<Props> = ({ activityName }) => (
    <FlexCell>
        <FlexRow>
            <Text
                color="gray5"
                fontSize="24"
                lineHeight="30"
                dataTestId="activity/title"
            >
                {activityName}
            </Text>
            <FlexSpacer />
            <Buttons />
        </FlexRow>
    </FlexCell>
);
