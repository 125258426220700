/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { Ref, useMemo, forwardRef } from 'react';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
/**
 * Components
 */
import { Badge, FlexRow } from 'components/common';
/**
 * Store
 */
import { selectNotViewedCount } from 'store/notifications/selectors';
/**
 * Constants
 */
import { ColorNames } from 'constants/colors';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Utils
 */
import { getRawProps } from 'utils';
/**
 * Expo
 */
export const DropdownTarget = forwardRef((props, ref: Ref<HTMLDivElement>) => {
    const notViewedCount = useSelector(selectNotViewedCount);

    const rawProps = useMemo(
        () => getRawProps({ dataTestId: 'header/notifications' }),
        []
    );

    return (
        <div ref={ref} className={css.container} {...props} {...rawProps}>
            {!notViewedCount ? null : (
                <FlexRow cx={css.badge}>
                    <Badge
                        fill="solid"
                        color={ColorNames.red}
                        caption={String(notViewedCount)}
                        dataTestId="header/notifications/badge"
                    />
                </FlexRow>
            )}
            <FlexRow dataTestId="header/notifications/icon">
                <img
                    alt="notifications"
                    className={css.icon}
                    src="/assets/icons/notifications.svg"
                />
            </FlexRow>
        </div>
    );
});
