import { AdaptiveItemProps } from '@epam/uui-components';

export const adaptiveItemsBuilder = () => {
    const items: AdaptiveItemProps[] = [];
    type EmptyType = null | false | undefined;

    return {
        add(item: AdaptiveItemProps[] | AdaptiveItemProps | EmptyType) {
            if (item) {
                if (Array.isArray(item)) {
                    items.push(...item);
                } else {
                    items.push(item);
                }
            }
            return this;
        },
        build: () => items,
    };
};
