/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { ButtonWithOptions } from 'components';
/**
 * Constants
 */
import { DOCX_REPORT_OPTIONS, OPTIONS } from './constants';
/**
 * Types
 */
import { TCreationButtonProps } from './types';
/**
 * Hooks
 */
/**
 * Store
 */
/**
 * Expo
 */
export const CreationButton: React.FC<TCreationButtonProps> = ({ onClick }) => {
    const options = [...DOCX_REPORT_OPTIONS, ...OPTIONS];
    return <ButtonWithOptions options={options} onClick={onClick} />;
};
