/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import * as z from 'zod';
import { zodNamedDto, zodNamedTmDto, zodOptionalString } from '../constants';
import { HARDCODED_INTERACTION_TYPE } from './constants';
import { baseItemFormSchema } from '../baseItemFormSchema';

export const interactionFormSchema = baseItemFormSchema
    .extend({
        from: zodNamedTmDto,
        to: zodNamedTmDto,
        // `type` is not sent to the backend, it's just for presentation in the form AKA "design sugar"
        type: z.literal(HARDCODED_INTERACTION_TYPE).nullish(),
        technologies: z.array(zodNamedTmDto).nullish(),
        protocol: zodNamedDto.nullish(),
        comment: zodOptionalString,
        assets: z.array(zodNamedTmDto).nullish(),
    })
    .refine((data) => data.from.id !== data.to.id, {
        message: 'From and To elements cannot be the same',
        path: ['from', 'to'],
    });

export type InteractionFormValues = z.infer<typeof interactionFormSchema>;
export type InteractionFormSchema = typeof interactionFormSchema;
