/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Hooks
 */
import { useMemo } from 'react';
import { RoleTypes } from 'constants/roles';
/**
 * Services
 */
import { useCheckRole } from './useCheckRole';
/**
 * Types
 */
type TUseRole = {
    isAdmin: boolean;
    isManager: boolean;
    isSalesManager: boolean;
    isOperationsManager: boolean;
    isSecuritySpecialist: boolean;
    isProjectRepresentative: boolean;
};
/**
 * Expo
 */
export const useRole = (): TUseRole => {
    const checkRole = useCheckRole();

    const roles = useMemo(
        () => ({
            isAdmin: checkRole([RoleTypes.admin]),
            isManager: checkRole([RoleTypes.manager]),
            isSalesManager: checkRole([RoleTypes.salesManager]),
            isOperationsManager: checkRole([RoleTypes.operationsManager]),
            isSecuritySpecialist: checkRole([RoleTypes.securitySpecialist]),
            isProjectRepresentative: checkRole([
                RoleTypes.projectRepresentative,
            ]),
        }),
        [checkRole]
    );

    return roles;
};
