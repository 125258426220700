/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Panel } from '@epam/promo';
import { Text, LinkButton, FlexRow } from 'components/common';
import { TermsAndConditionsModal } from 'components/modals/TermsAndConditions';
/**
 * Constants
 */
import { currentYear } from 'constants/dates';
/**
 * Utils
 */
import { showModal } from 'utils';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Store
 */
import { selectBuildVersionApi } from 'store/user';
/**
 * Expo
 */
const buildVersion = process.env.REACT_APP_BUILD_VERSION;

export const Footer: React.FC = () => {
    const handleShowTermsAndConditions = () => {
        showModal(TermsAndConditionsModal, {});
    };
    const currentBuildVersionApi = useSelector(selectBuildVersionApi);

    return (
        <Panel cx={css.footer}>
            <Panel>
                <FlexRow>
                    <Text cx={css.footerText}>
                        © {currentYear} EPAM Systems, Inc. All Rights Reserved.
                    </Text>
                    {buildVersion && (
                        <Text cx={css.buildVersion}>
                            Version: {buildVersion}
                        </Text>
                    )}
                    {currentBuildVersionApi && (
                        <Text cx={css.buildVersionApi}>
                            {currentBuildVersionApi}
                        </Text>
                    )}
                </FlexRow>
            </Panel>
            <Panel cx={css.rightSideFooter}>
                <LinkButton
                    caption="Terms & Conditions"
                    onClick={handleShowTermsAndConditions}
                    dataTestId="home/buttons/termsAndConditions"
                    cx={css.link}
                />
                <LinkButton
                    caption="Privacy Policy"
                    dataTestId="home/buttons/privacyPolicy"
                    href="https://www.epam.com/privacy-policy"
                    target="_blank"
                    cx={css.link}
                />
            </Panel>
        </Panel>
    );
};
