/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Services
 */
import LocalStorage from 'services/localStorage';
/**
 * Constants
 */
import { LOCAL_FEATURE_FLAGS } from './featureFlags';
import { environment, EnvironmentTypes } from './environments';
/**
 * Services
 */
export const isGADisabled =
    environment !== EnvironmentTypes.prod &&
    LocalStorage.get(LOCAL_FEATURE_FLAGS.testGA) !== 'enabled';
