/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { FlexSpacer, ModalFooter } from '@epam/promo';
import { FC, useState } from 'react';
import {
    LinkButton,
    Modal,
    Panel,
    FlexRow,
    FlexCell,
    TextInput,
    Button,
} from 'components/common';
import { ModalProps } from 'components/common/Modal/types';
import { useAppDispatch } from 'hooks';
import { handleCreateType } from 'store/threatModeling/trustBoundary/dispatchers';
import { TNamed } from 'types';
import { showModal } from 'utils';
import { ReactComponent as PlusIcon } from '@epam/assets/icons/action-add-outline.svg';
import css from './addTypeButton.module.scss';

export interface AddButtonProps {
    tmId: string;
    onSuccess: (newType: TNamed) => void;
}

export interface FormBodyProps {
    onClose: () => void;
    onSuccess: (newType: TNamed) => void;
    tmId: string;
}

const ModalBody: FC<FormBodyProps> = ({ onClose, tmId, onSuccess }) => {
    const dispatch = useAppDispatch();
    const [typeName, setTypeName] = useState('');
    const trimmedTypeName = typeName.trim();
    const isTypeNameValid = trimmedTypeName.length > 0;
    const onSubmit = async () => {
        const createdType = await dispatch(
            handleCreateType(trimmedTypeName, tmId)
        );
        onSuccess(createdType);
        onClose();
    };

    return (
        <Panel>
            <FlexRow padding="24" vPadding="48">
                <FlexCell grow={1}>
                    <TextInput
                        label="Type name"
                        value={typeName}
                        onValueChange={setTypeName}
                        placeholder="Enter a text"
                    />
                </FlexCell>
            </FlexRow>
            <ModalFooter borderTop>
                <FlexSpacer />
                <Button
                    caption="Cancel"
                    onClick={onClose}
                    fill="none"
                    color="neutral"
                />
                <Button
                    color="primary"
                    caption="Create"
                    disabled={!isTypeNameValid}
                    onClick={onSubmit}
                />
            </ModalFooter>
        </Panel>
    );
};

export const AddTypeButton: FC<AddButtonProps> = ({ tmId, onSuccess }) => {
    return (
        <LinkButton
            caption="Create new type"
            icon={PlusIcon}
            cx={css.addTypeButton}
            onClick={() => {
                showModal<Partial<ModalProps>>(Modal, {
                    title: 'Create new type for trust boundary',
                    renderBody: ({ abort }) => (
                        <ModalBody
                            tmId={tmId}
                            onClose={abort}
                            onSuccess={onSuccess}
                        />
                    ),
                    withoutFooter: true,
                });
            }}
        />
    );
};
