/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
export const FILE_ICON_BY_EXTENSION: Record<string, string> = {
    PDF: 'pdf',
    XLS: 'xls',
    XLSX: 'xls',
    JPG: 'image',
    PNG: 'image',
    JPEG: 'image',
    DOC: 'doc',
    DOCX: 'doc',
    MP4: 'video',
    MOV: 'video',
    AVI: 'video',
    OTHER: 'other',
};

export const ICON_SRC_TEMPLATE = '/assets/images/docTypes/{icon}.svg';
