/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, memo } from 'react';
/**
 * Types
 */
import { THandlers } from '../types';
import { TProjectAssessmentActionLog } from 'types';
/**
 * Utils
 */
import { useHref } from 'components/RecentActions/RecentAction/DefaultRecentAction';
import { blocksWithTestId } from 'components/RecentActions/RecentAction/utils';
/**
 * Expo
 */

const { RecentActionContent, LinkBlock, TextBlock } =
    blocksWithTestId('assessmentStarted');
export const AssessmentStarted: FC<TProjectAssessmentActionLog & THandlers> =
    memo(
        ({
            getAssessmentHref,
            details: {
                current_state: { name: assessmentName, id },
            },
        }) => {
            const href = useHref({ getHref: () => getAssessmentHref(id) });
            return (
                <RecentActionContent>
                    <TextBlock text="Assessment" />
                    {href ? (
                        <LinkBlock text={assessmentName} href={href} />
                    ) : (
                        <TextBlock cx="text-bold" text={assessmentName} />
                    )}
                    <TextBlock text="started" />
                </RecentActionContent>
            );
        }
    );
