/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { FlexSpacer, ModalFooter, ScrollBars } from '@epam/promo';
import { Button, Panel } from 'components/common';
import { TermsAndConditions } from '../TermsAndConditions';
/**
 * Types
 */
import { TContainer } from './types';
/**
 * Assets
 */
import css from './style.module.scss';
import { WithDataTestId } from 'types';
/**
 * Expo
 */
export const ModalBody: React.FC<TContainer & WithDataTestId> = ({
    onClose,
    dataTestId,
}) => (
    <Panel cx={css.container}>
        <ScrollBars>
            <Panel cx={css.textContainer}>
                <TermsAndConditions />
            </Panel>
        </ScrollBars>
        <Panel>
            <ModalFooter borderTop>
                <FlexSpacer />
                <Button
                    color="primary"
                    onClick={onClose}
                    caption="Close"
                    dataTestId={`${dataTestId}/buttons/close`}
                />
            </ModalFooter>
        </Panel>
    </Panel>
);
