/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createSlice } from '@reduxjs/toolkit';
/**
 * Constants
 */
import { INITIAL_STATE } from './constants';
/**
 * Expo
 */
export const { reducer, actions } = createSlice({
    name: 'singlePolicy',
    initialState: INITIAL_STATE,
    reducers: {
        saveData: (state, { payload }) => {
            state.data = payload;

            state.isInitialized = true;
        },
        setInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        resetData: () => INITIAL_STATE,
    },
});
