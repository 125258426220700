/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { environment, EnvironmentTypes } from 'constants/environments';
/**
 * Expo
 */
const cookieScriptId = 'ddd0826e-96b2-492c-a201-ff7fe517641f';

export const scriptIdByEnvironment =
    environment === EnvironmentTypes.prod
        ? cookieScriptId
        : `${cookieScriptId}-test`;
