/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Hooks
 */
import { FormProvider } from 'react-hook-form';
import { useAppForm } from 'hooks';
/**
 * Components
 */
import { FlexSpacer, ModalFooter, Panel } from '@epam/promo';
import { Button, TextArea } from 'components/common';
import { FieldController } from 'components/Fields';
/**
 * Types
 */
import { TFormProps } from './types';
import { TTextAreaProps } from 'components/common/TextArea/types';
/**
 * Assets
 */
import css from '../style.module.scss';
/**
 * Expo
 */
export const Form: React.FC<TFormProps> = ({ onClose, onSubmit }) => {
    const methods = useAppForm();

    const handleSubmit = methods.handleSubmit(({ comment }) => {
        onSubmit({ comment });

        onClose();
    });

    return (
        <>
            <Panel cx={css.formContainer}>
                <FormProvider {...methods}>
                    <FieldController<TTextAreaProps>
                        isRequired
                        name="comment"
                        label="Comment"
                        component={TextArea}
                        placeholder="The activity is blocked because..."
                        maxLength={500}
                        rows={4}
                        dataTestId="activity/markAsBlockedModal/textArea"
                    />
                </FormProvider>
            </Panel>
            <ModalFooter borderTop>
                <FlexSpacer />
                <Button
                    color="neutral"
                    caption="Cancel"
                    onClick={onClose}
                    fill="none"
                    dataTestId="activity/markAsBlockedModal/cancelButton"
                />
                <Button
                    caption="Submit"
                    onClick={handleSubmit}
                    color="primary"
                    dataTestId="activity/markAsBlockedModal/submitButton"
                />
            </ModalFooter>
        </>
    );
};
