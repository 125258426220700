/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createSelector, createSlice } from '@reduxjs/toolkit';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { INITIAL_STATE } from './constants';
import { Methods } from 'constants/request';
/**
 * Types
 */
import { TData, TUserOptions } from './types';
import { AppDispatch, TMyOrganization, TSelector } from 'types';
/**
 * Utils
 */
import { getUserData, handleGetOrganization, handleGetProject } from './utils';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Store
 */
import { handleGetFlags } from '../featureFlags';
import { openSocketChannel } from 'store/sockets/dispatchers';
import {
    getDropdownList,
    getList as getNotificationsList,
} from 'store/notifications/actions';

/**
 * Slice
 */
const { reducer, actions } = createSlice({
    name: 'user',
    initialState: INITIAL_STATE,
    reducers: {
        logout: () => {
            return INITIAL_STATE;
        },
        login: (state, { payload }) => {
            state.data = { ...state.data, ...payload };

            state.isInitialized = true;
        },
        setCurrentProject: (state, { payload }) => {
            state.data.currentProject = payload;
        },
        setCurrentRole: (state, { payload }) => {
            state.data.role = payload;
        },
        setCurrentOrganization: (state, { payload }) => {
            state.data.currentOrganization = payload;
        },
        setAllOrganizations: (state, { payload }) => {
            state.data.organizationList = payload;
        },
        setInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
    },
});
/**
 * Reducer
 */
export default reducer;
/**
 * Actions
 */
export const {
    logout,
    login,
    setCurrentProject,
    setCurrentOrganization,
    setCurrentRole,
    setAllOrganizations,
    setInProgress,
} = actions;
/**
 * Selectors
 */

export const selectData: TSelector<TData> = ({ user: { data } }) => data;

export const selectBuildVersionApi = createSelector(
    selectData,
    ({ currentBuildVersionApi = '' }) => currentBuildVersionApi
);

export const selectIsUserExists = createSelector(selectData, ({ id }) =>
    Boolean(id)
);

export const selectUserId = createSelector(selectData, ({ id }) => id);

export const selectUserRole = createSelector(selectData, ({ role }) => role);

export const selectUserRoles = createSelector(selectData, ({ roles }) => roles);

export const selectUserProjectId = createSelector(
    selectData,
    ({ currentProject }) => currentProject?.id
);

export const selectUserEmail = createSelector(
    selectData,
    ({ email = '' }) => email
);

export const selectAllOrganizations = createSelector(
    selectData,
    ({ organizationList }) => organizationList
);

export const selectUserName = createSelector(
    selectData,
    ({ fullName = '' }) => fullName
);

export const selectUserIdAndName = createSelector(
    selectUserName,
    selectUserId,
    (name, id = '') => ({
        id,
        name,
    })
);
export const selectUserNameAndRole = createSelector(
    selectUserName,
    selectUserRole,
    (name, role) => ({
        name,
        role,
    })
);

export const selectCurrentProjectName = createSelector(
    selectData,
    ({ currentProject }) => currentProject?.name
);

export const selectCurrentOrganizationName = createSelector(
    selectData,
    ({ currentOrganization }) => currentOrganization?.name ?? ''
);

export const selectCurrentOrganizationId = createSelector(
    selectData,
    ({ currentOrganization }) => currentOrganization?.id
);

export const selectRootOrganizationName = createSelector(
    selectData,
    ({ rootOrganization }) => rootOrganization?.company_name
);

export const selectHeaderInformation = createSelector(
    [selectCurrentProjectName, selectCurrentOrganizationName],
    (projectName, organizationName) => ({
        projectName,
        organizationName,
    })
);
export const selectUserData = createSelector(
    [selectCurrentOrganizationName, selectUserName, selectUserEmail],
    (organizationName, userName, userEmail) => ({
        organizationName,
        userName,
        userEmail,
    })
);

export const selectInProgress: TSelector<boolean> = ({
    user: { inProgress },
}) => inProgress;
/**
 * Dispatchers
 */
export const handleLogout =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        await api.reset();

        dispatch(logout());
    };

export const handleGetOrganizations =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        const data = await api.request<TMyOrganization[]>(Methods.get, {
            url: ENDPOINTS.myOrganizations,
        });

        dispatch(setAllOrganizations(data));
    };

const setHeaderInformation =
    (projectId = '', organizationId = '') =>
    async (dispatch: AppDispatch): Promise<void> => {
        const [project, organization] = await Promise.all([
            handleGetProject(projectId),
            handleGetOrganization(organizationId),
            dispatch(handleGetOrganizations()),
        ]);

        dispatch(setCurrentProject(project));
        dispatch(setCurrentOrganization(organization));
    };

export const handleLogin =
    () =>
    async (dispatch: AppDispatch): Promise<TUserOptions> => {
        dispatch(setInProgress(true));
        const {
            id,
            roles,
            email,
            fullName,
            roleName,
            rootOrganization,
            currentProjectId,
            currentOrganizationId,
            currentBuildVersionApi,
        } = await getUserData();

        if (currentProjectId) {
            await dispatch(
                setHeaderInformation(currentProjectId, currentOrganizationId)
            );
        }

        await dispatch(handleGetFlags());

        dispatch(
            login({
                id,
                roles,
                email,
                fullName,
                role: roleName,
                rootOrganization,
                currentBuildVersionApi,
            })
        );
        dispatch(openSocketChannel({ userId: id }));
        dispatch(setInProgress(false));

        return { projectId: currentProjectId, role: roleName };
    };

export const handleUpdatePersonData =
    () =>
    async (dispatch: AppDispatch): Promise<TUserOptions> => {
        dispatch(setInProgress(true));
        const {
            id,
            roles,
            fullName,
            roleName,
            currentProjectId,
            currentOrganizationId,
            email,
        } = await getUserData();

        if (currentProjectId) {
            await dispatch(
                setHeaderInformation(currentProjectId, currentOrganizationId)
            );
        }

        dispatch(login({ id, role: roleName, fullName, roles, email }));
        dispatch(setInProgress(false));

        return { projectId: currentProjectId, role: roleName };
    };

export const handleSetCurrentProject =
    (projectId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setInProgress(true));

        try {
            const { organization_id: organizationId } = await api.request<
                {
                    organization_id: string;
                },
                { project_id: string }
            >(Methods.post, {
                url: ENDPOINTS.currentProject,
                data: {
                    project_id: projectId,
                },
            });

            const [project, organization] = await Promise.all([
                handleGetProject(projectId),
                handleGetOrganization(organizationId),
            ]);

            dispatch(setCurrentProject(project));
            dispatch(setCurrentOrganization(organization));
        } finally {
            dispatch(setInProgress(false));
        }
    };

export const handleSetCurrentRole =
    (currentRoleId: number) =>
    async (dispatch: AppDispatch): Promise<TUserOptions> => {
        await api.request(Methods.post, {
            url: ENDPOINTS.currentRole,
            data: {
                role_id: currentRoleId,
            },
        });

        dispatch(getNotificationsList());
        dispatch(getDropdownList());

        return dispatch(handleUpdatePersonData());
    };

export const handleSetCurrentOrganization =
    (currentOrganizationId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const organization = await handleGetOrganization(currentOrganizationId);

        dispatch(setCurrentOrganization(organization));
    };
