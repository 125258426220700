/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { useCallback, useMemo, useState } from 'react';
import { DropSpot, FlexSpacer, ModalFooter, Panel } from '@epam/promo';
import { AttachmentList } from 'components/AttachmentList';
import {
    getRawProps,
    getUploadedFiles,
    showError,
    showNotification,
} from 'utils';
import { TDfdUploadModalInternalProps } from './types';
import css from './style.module.scss';
import { TUploadedFile } from 'types';
import { ModalSizes } from 'constants/modals';
import { Button, Modal as ModalComponent, Text } from 'components/common';
import { cx } from '@epam/uui-core';

import { handleUploadDfd } from 'store/threatModeling/utils';
import { handleGetDfdMetadata } from 'store/threatModeling';
import { useAppDispatch } from 'hooks';

export const DfdUploadModal: React.FC<TDfdUploadModalInternalProps> = ({
    dataTestId,
    activityId,
    ...modalProps
}) => {
    const [value, setValue] = useState<TUploadedFile>();
    const [loadError, setLoadError] = useState<string>();
    const [extensionError, setExtensionError] = useState<string>();
    const dispatch = useAppDispatch();
    const uploadFile = (files: File[]): void => {
        if (files.length > 1) {
            showError('You can upload only one file, please try again');
            return;
        }

        const uploadedFiles = getUploadedFiles({
            files,
            fileFormat: 'xml',
            onWrongExtension: () => setExtensionError('Wrong file extension'),
        });
        if (uploadedFiles.length === 0) {
            return;
        }
        setExtensionError(undefined);
        setLoadError(undefined);
        setValue(uploadedFiles[0]);
    };

    const rawProps = useMemo(() => getRawProps({ dataTestId }), [dataTestId]);

    const onSubmit = useCallback(
        (abort: () => void) => {
            if (!value) {
                return;
            }
            handleUploadDfd(activityId, value.file)
                .then(() => {
                    showNotification({ text: 'Diagram uploaded successfully' });
                    dispatch(handleGetDfdMetadata(activityId));
                    abort();
                })
                .catch(() => setLoadError('Upload failed'));
        },
        [value, activityId, dispatch]
    );

    return (
        <ModalComponent
            {...modalProps}
            withoutFooter
            title="Upload data flow"
            width={ModalSizes.large}
            dataTestId={dataTestId}
            allowCloseOnCross={false}
            renderBody={({ abort }) => (
                <>
                    <Panel cx={css.container} {...rawProps}>
                        {!value ? (
                            <>
                                <DropSpot
                                    infoText="One file. Limit is 50 MB. Only XML extension is supported."
                                    onUploadFiles={uploadFile}
                                    accept="xml"
                                    cx={cx(
                                        css.dropSpotContainer,
                                        extensionError && css.redBorder
                                    )}
                                    single
                                />
                                <Text
                                    dataTestId={`${dataTestId}/wrongExtension`}
                                    cx={css.errorMessage}
                                >
                                    {extensionError}
                                </Text>
                            </>
                        ) : (
                            <AttachmentList
                                list={[value]}
                                columnsInRow={2}
                                onRemove={() => setValue(undefined)}
                                error={loadError}
                            />
                        )}
                    </Panel>
                    <ModalFooter borderTop>
                        <FlexSpacer />
                        <Button
                            fill="none"
                            color="neutral"
                            caption="Cancel"
                            onClick={abort}
                            dataTestId={`${dataTestId}/buttons/cancel`}
                        />
                        <Button
                            disabled={!value}
                            color="primary"
                            onClick={() => onSubmit(abort)}
                            caption="Submit"
                            dataTestId={`${dataTestId}/buttons/submit`}
                        />
                    </ModalFooter>
                </>
            )}
        />
    );
};
