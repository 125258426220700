/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { memo, useEffect, useState } from 'react';
/**
 * Components
 */
import { ArraySelect, FlexRow, IconContainer } from 'components/common';
/**
 * Types
 */
import { TSelectProps } from './types';
/**
 * Assets
 */
import css from './style.module.scss';
import { ReactComponent as CancelIcon } from '@epam/assets/icons/common/navigation-close_bold-18.svg';
import { ReactComponent as SuccessIcon } from '@epam/assets/icons/common/notification-done_bold-18.svg';
import { ReactComponent as SearchIcon } from '@epam/assets/icons/action-search-outline.svg';

/**
 * Expo
 */
const Select = <TOption, TValue>({
    value,
    onCancel,
    onSuccess,
    options,
    showButtons,
    showSearchIcon,
    ...props
}: TSelectProps<TOption, TValue>) => {
    const [currentValue, setCurrentValue] = useState(value);

    const handleSuccess = () => {
        onSuccess(currentValue);
    };

    useEffect(() => {
        if (!showButtons) {
            onSuccess(currentValue);
        }
    }, [currentValue, showButtons, onSuccess]);

    return (
        <FlexRow columnGap="12" cx={css.selectRow}>
            {showSearchIcon && (
                <IconContainer cx={css.searchIcon} icon={SearchIcon} />
            )}
            <ArraySelect
                {...props}
                options={options}
                valueType="entity"
                value={currentValue}
                onValueChange={(val) => {
                    setCurrentValue(val);
                    if (!val) {
                        onCancel();
                    }
                }}
                dataTestId="editableSelect/select"
            />
            {showButtons && (
                <>
                    <IconContainer
                        style={{ fill: 'var(--uui-link)' }}
                        icon={SuccessIcon}
                        onClick={handleSuccess}
                        dataTestId="editableSelect/buttons/accept"
                    />
                    <IconContainer
                        style={{ fill: 'var(--uui-link)' }}
                        icon={CancelIcon}
                        onClick={onCancel}
                        dataTestId="editableSelect/buttons/cancel"
                    />
                </>
            )}
        </FlexRow>
    );
};

export default memo(Select) as typeof Select;
