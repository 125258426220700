/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Large, LegacyLarge, Small } from './components';
/**
 * Types
 */
import { CardProps } from './types';
/**
 * Hooks
 */
import { useRole } from 'hooks';
/**
 * Store
 */
/**
 * Constants
 */
import { CardTypes } from './constants';

/**
 * Expo
 */
export const Card: React.FC<CardProps> = ({
    id,
    name,
    status,
    withLink,
    updateDate,
    dataTestId,
    commentsCount,
    assessmentId,
    assessmentName,
    type = CardTypes.small,
    onClick,
    onDelete,
    renderContent,
    renderInformation,
    className,
    information,
}) => {
    const { isOperationsManager, isProjectRepresentative } = useRole();

    const isCardWithoutAdditionalInfo =
        isOperationsManager || isProjectRepresentative;

    if (isCardWithoutAdditionalInfo && type === CardTypes.large) {
        return (
            <Large
                name={name}
                renderContent={renderContent}
                dataTestId={dataTestId}
                className={className}
            />
        );
    }

    return type === CardTypes.small ? (
        <Small
            id={id}
            name={name}
            status={status}
            withLink={withLink}
            assessmentId={assessmentId}
            assessmentName={assessmentName}
            updateDate={updateDate}
            commentsCount={commentsCount}
            onClick={onClick}
            renderInformation={renderInformation}
            dataTestId={dataTestId}
        />
    ) : (
        <LegacyLarge
            name={name}
            status={status}
            className={className}
            dataTestId={dataTestId}
            information={information}
            onDelete={onDelete}
            renderContent={renderContent}
            renderInformation={renderInformation}
        />
    );
};
