/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { StatusTypes } from 'constants/statuses';

export const OPTIONS = [
    { id: StatusTypes.opened },
    { id: StatusTypes.unresolved },
    { id: StatusTypes.readyToRetest },
    { id: StatusTypes.retest },
    { id: StatusTypes.resolved },
];

export const OPTIONS_TO_EXCLUDE = [StatusTypes.opened, StatusTypes.archived];
