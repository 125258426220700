/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useMemo } from 'react';
/**
 * Hooks
 */
import { useLinkClickHandler, useLocation } from 'react-router-dom';
/**
 * Components
 */
import { MainMenu as UuiMainMenu } from '@epam/promo';
/**
 * Types
 */
import { WithDataTestId } from 'types';
import {
    AdaptiveItemProps,
    MainMenuLogo,
    MainMenuProps,
    MainMenuProps as MainMenuMods,
} from '@epam/uui-components';
/**
 * Utils
 */
import { getRawProps } from 'utils';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
import { LogoUrl } from 'constants/system';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const MainMenu: React.FC<
    MainMenuMods & MainMenuProps & WithDataTestId
> = ({ dataTestId, onLogoClick, children, items: contentItems, ...props }) => {
    const { pathname } = useLocation();

    const isSecurityDeliveryConsole = pathname.includes(PAGES.securityCenter);

    const logoUrl = isSecurityDeliveryConsole
        ? LogoUrl.deliveryConsole
        : LogoUrl.securityPlatform;
    const logoHref = isSecurityDeliveryConsole ? PAGES.requests : PAGES.home;

    const internalOnClick = useLinkClickHandler(logoHref || '');

    const handleLogoClick = useCallback(
        (event: React.MouseEvent<Element, MouseEvent>) => {
            if (onLogoClick) {
                onLogoClick(event);
            }

            if (logoHref) {
                internalOnClick(
                    event as React.MouseEvent<HTMLAnchorElement, MouseEvent>
                );
            }
        },
        [onLogoClick, internalOnClick, logoHref]
    );

    const rawProps = useMemo(() => getRawProps({ dataTestId }), [dataTestId]);

    const logo: AdaptiveItemProps = useMemo(
        () => ({
            id: 'logo',
            priority: 99,
            render: () => (
                <MainMenuLogo
                    href={logoHref}
                    logoUrl={logoUrl}
                    onClick={handleLogoClick}
                />
            ),
        }),
        [logoHref, logoUrl, handleLogoClick]
    );
    const items: Array<AdaptiveItemProps> = useMemo(
        () => [logo].concat(contentItems || []),
        [contentItems, logo]
    );

    return (
        <UuiMainMenu
            {...props}
            items={items}
            rawProps={rawProps}
            cx={css.headerContainer}
        />
    );
};
