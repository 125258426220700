/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
import { useAppDispatch, useOnMount, useRedirect, useRole } from 'hooks';
/**
 * Components
 */
import { ListItem } from '../ListItem';
import { Panel, Button } from 'components/common';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
import { FEATURE_FLAGS } from 'constants/featureFlags';
import { markAsReadAllDelay } from '../constants';
/**
 * Types
 */
import { TListProps } from './types';
/**
 * Store
 */
import { markAsReadAllInDropdown } from 'store/notifications/actions';
import { selectFlag } from 'store/featureFlags';
import { selectUserProjectId } from 'store/user';
import { selectDropdownIdList } from 'store/notifications/selectors';
/**
 * Assets
 */
import css from '../style.module.scss';
/**
 * Expo
 */
export const List: React.FC<TListProps> = ({ onClose }) => {
    const dispatch = useAppDispatch();
    const { isOperationsManager } = useRole();

    const projectId = useSelector(selectUserProjectId);
    const idList = useSelector(selectDropdownIdList);

    const redirect = useRedirect();

    const viewNotificationsPageFlag = useSelector(
        selectFlag(FEATURE_FLAGS.viewNotificationsPageFlag)
    );

    const isShowButton = viewNotificationsPageFlag && Boolean(idList.length);

    const handleRedirectToNotificationPage = () => {
        if (isOperationsManager) {
            redirect(PAGES.notifications);
        } else {
            redirect(PAGES.singleProjectNotifications, { projectId });
        }

        onClose?.();
    };

    useOnMount(() => {
        const timerId = setTimeout(() => {
            dispatch(markAsReadAllInDropdown());
        }, markAsReadAllDelay);

        return () => {
            clearTimeout(timerId);
        };
    });

    return (
        <Panel
            shadow
            background="white"
            cx={css.notificationsBody}
            dataTestId="header/notifications/list"
        >
            {idList.map((id) => (
                <ListItem key={id} id={id} onClose={onClose} />
            ))}
            {isShowButton && (
                <Panel cx={css.buttonsWrapperContainer}>
                    <Button
                        className={css.showAllButton}
                        fill="outline"
                        color="neutral"
                        caption="Show all"
                        onClick={handleRedirectToNotificationPage}
                        dataTestId="header/notifications/list/button/showMore"
                    />
                </Panel>
            )}
        </Panel>
    );
};
