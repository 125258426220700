/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { put, call, takeEvery, select, all } from 'redux-saga/effects';
/**
 * Utils
 */
import {
    getDropdownNotificationsList,
    getNotificationsList,
    getNotViewedCount,
    getUnreadNotificationsIds,
    handleMarkNotificationsAsRead,
    handleMarkSingleNotificationAsRead,
} from './utils';
/**
 * Actions
 */
import {
    getList,
    getDropdownList,
    saveDropdownList,
    saveList,
    markAsRead,
    markAsReadAll,
    markAsReadAllInDropdown,
} from './actions';
/**
 * Store
 */
import { selectDropdownNotifications, selectNotifications } from './selectors';
/**
 * Types
 */
import { TNotification } from 'types';
import { PayloadAction } from '@reduxjs/toolkit';
/**
 * Expo
 */

function* handleGetDropdownList() {
    const [notifications, notViewedCount]: [TNotification[], number] =
        yield all([
            call(getDropdownNotificationsList),
            call(getNotViewedCount),
        ]);

    yield put(saveDropdownList({ notifications, notViewedCount }));
}

function* handleGetList() {
    const [notifications]: [TNotification[], number] = yield all([
        call(getNotificationsList),
    ]);
    yield put(saveList({ notifications }));
}

function* handleMarkAsRead({ payload: notificationId }: PayloadAction<string>) {
    yield call(handleMarkSingleNotificationAsRead, notificationId);

    yield put(getList());
}

function* handleMarkAsReadAllInDropdown() {
    const notifications: TNotification[] = yield select(
        selectDropdownNotifications
    );

    const unreadNotificationsIds = getUnreadNotificationsIds(notifications);

    yield call(handleMarkNotificationsAsRead, unreadNotificationsIds);

    yield put(getDropdownList());
}

function* handleMarkAsReadAll() {
    const notifications: TNotification[] = yield select(selectNotifications);

    const unreadNotificationsIds = getUnreadNotificationsIds(notifications);

    yield call(handleMarkNotificationsAsRead, unreadNotificationsIds);
    yield call(handleMarkAsReadAllInDropdown);

    yield put(getList());
}

export default function* sagas(): Generator {
    yield takeEvery(getDropdownList.type, handleGetDropdownList);
    yield takeEvery(getList.type, handleGetList);
    yield takeEvery(markAsRead.type, handleMarkAsRead);
    yield takeEvery(markAsReadAll.type, handleMarkAsReadAll);
    yield takeEvery(
        markAsReadAllInDropdown.type,
        handleMarkAsReadAllInDropdown
    );
}
