/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { ElementType } from 'types/threatModeling/element';
import { NonNullAndAllFieldsRequired } from 'types/utils';
import * as z from 'zod';

export const TEXT_AREA_ROWS = 4;

export const EMPTY_VALUE_PLACEHOLDER = '–';

export const elementTypeToLabelMap: Record<ElementType, string> = {
    [ElementType.process]: 'Process',
    [ElementType.externalEntity]: 'External entity',
    [ElementType.storage]: 'Storage',
};

export enum ActionOnAsset {
    Stored = 'STORED',
    Transferred = 'TRANSFERRED',
    Processed = 'PROCESSED',
}

export const REQUIRED_STRING_MESSAGE = 'This field is required';
export const zodRequiredStringWithMessage = z
    .string({ required_error: REQUIRED_STRING_MESSAGE })
    .trim()
    .min(1, { message: REQUIRED_STRING_MESSAGE });

export const zodNamedDto = z.object(
    {
        id: z.string(),
        name: z.string(),
    },
    {
        invalid_type_error: REQUIRED_STRING_MESSAGE,
        required_error: REQUIRED_STRING_MESSAGE,
    }
);

export const zodNamedTmDto = z.object(
    {
        id: z.string(),
        name: z.string(),
        readableId: z.number().nullish(),
    },
    {
        invalid_type_error: REQUIRED_STRING_MESSAGE,
        required_error: REQUIRED_STRING_MESSAGE,
    }
);

export type ZodNamedTmDto = z.infer<typeof zodNamedTmDto>;

/**
 * Just like `zodNamedTmDto`, but with nullable `id` to allow for new entries
 */
export const zodEntryPointsDto = z.object(
    {
        id: z.string().nullish(),
        name: z.string().nullish(),
    },
    {
        invalid_type_error: REQUIRED_STRING_MESSAGE,
        required_error: REQUIRED_STRING_MESSAGE,
    }
);

export type ZodEntryPointsDto = z.infer<typeof zodEntryPointsDto>;

export const zodEntryPointsDtoArray = z
    .array(zodEntryPointsDto)
    .transform((value) =>
        value.filter(function stripUnfilled(
            entryPoint
        ): entryPoint is NonNullAndAllFieldsRequired<typeof entryPoint> {
            return Boolean(entryPoint?.name);
        })
    );

export type ZodEntryPointsDtoArray = z.infer<typeof zodEntryPointsDtoArray>;

export const zodAssetFootprint = z
    .object({
        id: z.string().nullish(),
        asset: zodNamedTmDto.nullish(),
        actionOnAsset: z.nativeEnum(ActionOnAsset).nullish(),
    })
    .superRefine(function checkAssetFootprintFilled(value, ctx) {
        const ERROR_MESSAGE =
            'Both asset and action must be filled in. Or none of them.';

        if (value.actionOnAsset && !value?.asset?.id) {
            const path: keyof ZodAssetFootprint = 'asset';
            ctx.addIssue({
                path: [path],
                code: z.ZodIssueCode.custom,
                message: ERROR_MESSAGE,
            });
        }

        if (value?.asset?.id && !value.actionOnAsset) {
            const path: keyof ZodAssetFootprint = 'actionOnAsset';
            ctx.addIssue({
                path: [path],
                code: z.ZodIssueCode.custom,
                message: ERROR_MESSAGE,
            });
        }
    });

export type ZodAssetFootprint = z.infer<typeof zodAssetFootprint>;

export const zodAssetFootprintArray = z
    .array(zodAssetFootprint)
    .transform((values) => {
        return values.filter(function stripUnfilled(
            assetFootPrint
        ): assetFootPrint is NonNullAndAllFieldsRequired<
            typeof assetFootPrint
        > {
            return Boolean(assetFootPrint.asset?.id);
        });
    });

export type ZodAssetFootprintArray = z.infer<typeof zodAssetFootprintArray>;

export const zodOptionalString = z
    .string()
    .trim()
    .nullish()
    .transform(function convertEmptyStringToNull(value) {
        return value === '' ? null : value;
    });
