/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, useContext } from 'react';
/**
 * Components
 */
import { PendingFile } from './PendingFile';
import { FlexRow } from 'components/common';
import { AttachedFile } from './AttachedFile';
/**
 * Context
 */
import { AttachmentsContext, EditorContext } from '../../context';
/**
 * Utils
 */
import { cx } from '@epam/uui-core';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const Attachments: FC = () => {
    const { readOnly } = useContext(EditorContext);
    const { attachments, pendingAttachments } = useContext(AttachmentsContext);

    return (
        <FlexRow cx={cx(css.container, readOnly && css.oneRow)}>
            {pendingAttachments?.map((item) => (
                <PendingFile key={item.id} file={item} />
            ))}
            {attachments?.map((item) => (
                <AttachedFile key={item.id} file={item} />
            ))}
        </FlexRow>
    );
};
