/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { handleGetTrustBoundaries } from './trustBoundary/dispatchers';
import { AppDispatch, TDocument } from 'types';
import {
    handleGetData,
    saveActivity,
    setInitialized,
    setInProgress,
} from '../singleActivity';
import { requestSingleActivity } from '../singleActivity/utils';
import { handleGetInteractions } from './interaction/dispatchers';
import {
    saveDfdMetadata,
    setDfdMetadataInitialized,
    setDfdMetadataLoadingInProgress,
} from './slice';
import { templateString } from 'utils';
import { ENDPOINTS } from 'constants/api';
import { api } from 'services/api';
import { Methods } from 'constants/request';
import { handleGetTechnologies } from './technology/dispatchers';
import { handleGetAssets } from './asset/dispatchers';
import { handleElements } from './element/fetchAllDispatcher';

export const handleGetThreatModelingActivity =
    (activityId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            dispatch(setInProgress(true));
            const activity = await requestSingleActivity(activityId);
            dispatch(saveActivity(activity));
            dispatch(handleGetData(activityId));
        } finally {
            dispatch(setInitialized(true));
            dispatch(setInProgress(false));
        }
    };

export const handleGetDfdMetadata =
    (activityId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            dispatch(setDfdMetadataLoadingInProgress(true));
            const url = templateString(ENDPOINTS.getDfdMetadata, {
                activityId,
            });
            const dfdMetadata = await api.request<TDocument>(
                Methods.get,
                {
                    url,
                },
                true,
                false
            );
            dispatch(saveDfdMetadata(dfdMetadata));
        } finally {
            dispatch(setDfdMetadataInitialized(true));
            dispatch(setDfdMetadataLoadingInProgress(false));
        }
    };

export const handleModelTreeItems =
    (threatModelingActivityId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(handleGetTechnologies(threatModelingActivityId));
        dispatch(handleGetAssets(threatModelingActivityId));
        dispatch(handleElements(threatModelingActivityId));
        dispatch(handleGetInteractions(threatModelingActivityId));
        dispatch(handleGetTrustBoundaries(threatModelingActivityId));
    };
