/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useMemo } from 'react';
/**
 * Components
 */
import { FindingSeverity } from 'components';
import { FlexSpacer } from '@epam/promo';
import { FindingStatus } from 'components/FindingStatus';
import {
    Badge,
    User,
    Text,
    FlexRow,
    Checkbox,
    Breadcrumb,
    Panel,
} from 'components/common';
/**
 * Hooks
 */
import { useRouteParams } from 'hooks';
/**
 * Types
 */
import { TListItemProps } from './types';
/**
 * Constants
 */
import { ColorNames } from 'constants/colors';
/**
 * Utils
 */
import { formatDate } from 'utils';
import { getBreadCrumbs } from 'components/FindingInformation/utils';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const ListItem: React.FC<TListItemProps> = ({
    id,
    status,
    summary,
    severity,
    onChange,
    isChecked,
    target,
    activity,
    assessment,
    resolution,
    updated_at,
    updated_by,
    linked_tracker_issue,
    assignee: { full_name },
    marked_as_risk_accepted,
}) => {
    const issueKey = linked_tracker_issue?.key;

    const { projectId } = useRouteParams();

    const findingName = !issueKey ? summary : `[${issueKey}] ${summary}`;

    const handleChange = (checked: boolean) => {
        onChange(checked, id);
    };
    const breadcrumbs = useMemo(() => {
        if (target && activity && assessment) {
            return getBreadCrumbs({
                target,
                activity,
                projectId,
                assessment,
            });
        }

        return null;
    }, [target, activity, projectId, assessment]);

    return (
        <Panel
            background="white"
            shadow
            cx={css.listItem}
            dataTestId="configureReport/findings/item"
        >
            <Panel cx={css.checkboxContainer}>
                <Checkbox
                    value={isChecked}
                    onValueChange={handleChange}
                    dataTestId="configureReport/findings/item/checkbox"
                />
            </Panel>
            <Panel cx={css.content}>
                <FlexRow cx={css.spaceReducer}>
                    {breadcrumbs && <Breadcrumb list={breadcrumbs} />}
                </FlexRow>
                <FlexRow size="24" cx={css.spaceReducer}>
                    <Text
                        fontSize="16"
                        lineHeight="24"
                        dataTestId="configureReport/findings/item/name"
                    >
                        {findingName}
                    </Text>
                </FlexRow>

                <FlexRow columnGap="6">
                    <FindingSeverity
                        severity={severity}
                        dataTestId="configureReport/findings/item/severity"
                    />
                    <FindingStatus
                        type={status}
                        resolution={resolution}
                        dataTestId="configureReport/findings/item/status"
                    />
                    {marked_as_risk_accepted && (
                        <Badge
                            fill="outline"
                            caption="Risk accepted"
                            color={ColorNames.cyan}
                            dataTestId="configureReport/findings/item/riskAccepted"
                        />
                    )}
                    <User
                        textSize="12"
                        avatarSize="18"
                        name={full_name}
                        dataTestId="configureReport/findings/item/assignee"
                    />
                    <FlexSpacer />
                    <Text
                        size="18"
                        fontSize="12"
                        color="gray60"
                        dataTestId="configureReport/findings/item/updatedAt"
                    >
                        Last changed {formatDate(updated_at)} by
                    </Text>
                    <User
                        textSize="12"
                        avatarSize="18"
                        name={updated_by.full_name}
                        dataTestId="configureReport/findings/item/updatedBy"
                    />
                </FlexRow>
            </Panel>
        </Panel>
    );
};
