/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { createContext, ReactNode, useContext, useReducer } from 'react';
import { z } from 'zod';
import { FormMode } from '../types';

export enum ActionType {
    switchToEditMode = 'switchToEditMode',
    switchToViewMode = 'switchToViewMode',
}

type Action = {
    type: ActionType.switchToViewMode | ActionType.switchToEditMode;
};

type State = {
    formSchema: z.AnyZodObject | z.ZodEffects<z.AnyZodObject>;
    mode: FormMode;
};

type EditableItemCardProviderProps = {
    initialState: State;
    children: ReactNode;
};

export class EditableItemCardError extends Error {}

export type SwitchFunction = () => void;
const EditableItemCardStateContext = createContext<
    | {
          state: State;
          switchToViewMode: SwitchFunction;
          switchToEditMode: SwitchFunction;
      }
    | undefined
>(undefined);

function editableItemCardReducer(state: State, action: Action): State {
    if (state.mode === 'create')
        throw new EditableItemCardError('Cannot switch from create mode');
    switch (action.type) {
        case 'switchToEditMode': {
            return { ...state, mode: FormMode.edit };
        }
        case 'switchToViewMode': {
            return { ...state, mode: FormMode.view };
        }
        default: {
            throw new Error(`Unhandled action type: ${action}`);
        }
    }
}

function EditableItemCardProvider({
    initialState,
    children,
}: EditableItemCardProviderProps) {
    const [state, dispatch] = useReducer(editableItemCardReducer, initialState);

    const switchToEditMode = () =>
        dispatch({ type: ActionType.switchToEditMode });
    const switchToViewMode = () =>
        dispatch({ type: ActionType.switchToViewMode });
    const value = { state, switchToViewMode, switchToEditMode };

    return (
        <EditableItemCardStateContext.Provider value={value}>
            {children}
        </EditableItemCardStateContext.Provider>
    );
}

function useEditableItemCard() {
    const context = useContext(EditableItemCardStateContext);

    if (context === undefined) {
        throw new Error(
            'useEditableItemCard must be used within an EditableItemCardProvider'
        );
    }

    return context;
}

export { EditableItemCardProvider, useEditableItemCard };
