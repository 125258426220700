/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import {
    TIssueDetails,
    TIssueMappingOptions,
    TIssueTrackerAzure,
    TIssueTrackerAzureRequestBody,
    TIssueTrackerJira,
    TIssueTrackerJiraRequestBody,
    TMappingOption,
    TMappingOptionsByType,
} from './types';
/**
 * Constants
 */
import { AZURE_DEVOPS_URL } from './constants';
import { IntegrationTypes } from 'constants/project';
import { TMappingKeys } from 'constants/mapping';
/**
 * Expo
 */
export const configureTrackerIssueBodyJira = ({
    url,
    username,
    password,
    projectKey,
}: TIssueTrackerJira): TIssueTrackerJiraRequestBody => ({
    url,
    password,
    username,
    project_key: projectKey,
    integration_type: IntegrationTypes.jira,
});

export const configureTrackerIssueBodyAzure = ({
    organization,
    project,
    personalAccessToken,
}: TIssueTrackerAzure): TIssueTrackerAzureRequestBody => ({
    url: AZURE_DEVOPS_URL,
    password: personalAccessToken,
    organization,
    project_key: project,
    integration_type: IntegrationTypes.azure,
});

export const convertToOptions = (list?: string[]): TMappingOption[] => {
    if (!list) return [];

    return list.map((item) => ({ id: item, description: item }));
};

export const convertToIssueTypes = (
    issueDetails: TIssueDetails
): TMappingOption[] => convertToOptions(Object.keys(issueDetails));

export const convertToTrackerDetails = (
    issueDetails: TIssueDetails
): TIssueMappingOptions =>
    Object.entries(issueDetails).reduce(
        (issueMappingOptions, [issueType, issueValues]) => {
            const options = Object.entries(issueValues).reduce(
                (mappingOptions, [mappingFieldKey, mappingFieldValues]) => {
                    if (Array.isArray(mappingFieldValues)) {
                        mappingOptions[mappingFieldKey as TMappingKeys] =
                            convertToOptions(mappingFieldValues);
                    }
                    return mappingOptions;
                },
                {} as TMappingOptionsByType
            );
            issueMappingOptions[issueType] = {
                ...options,
                workflow: issueValues.work_flow,
            };
            return issueMappingOptions;
        },
        {} as TIssueMappingOptions
    );
