/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { pdfjs } from 'react-pdf';
/**
 * Constants
 */
import {
    PlatformRoutes,
    PlatformTypes,
    redirectStartTime,
} from 'constants/system';
/**
 * Expo
 */
const ONE_TRUST_STYLE_TAG_IDS = ['onetrust-style', 'onetrust-legacy-style'];

export const definePlatformType = (pathname: string): PlatformTypes => {
    const securityDeliveryConsoleRegExp = new RegExp(
        `/${PlatformRoutes.securityDeliveryConsole}/`
    );

    return securityDeliveryConsoleRegExp.test(pathname)
        ? PlatformTypes.securityDeliveryConsole
        : PlatformTypes.agileSecurityPlatform;
};

export const forbidInlineStyles = (): void => {
    const { setAttribute } = Element.prototype;
    const appendChild = document.head.appendChild.bind(document.head);

    document.head.appendChild = <T extends Node>(node: T) => {
        const { id } = node as T & { id: string };

        if (ONE_TRUST_STYLE_TAG_IDS.includes(id)) {
            return node;
        }

        return appendChild(node);
    };

    // We need to redefine Element.prototype.setAttribute
    // because browser will count element.setAttribute('style', 'some-style: value') as "unsafe-inline" style
    // this will leads us to Content Security Policy errors
    // to avoid it we need to change element.setAttribute('style', 'some-style: value') to element.style['some-style'] = 'value'
    // eslint-disable-next-line func-names
    Element.prototype.setAttribute = function (attribute, value) {
        if (attribute.toLowerCase() !== 'style') {
            setAttribute.call(this, attribute, value);
        } else {
            value.split(';').forEach((style) => {
                let [property, propertyValue] = style.split(':');

                if (property && propertyValue) {
                    property = property.trim();
                    propertyValue = propertyValue.trim();

                    this.style[property] = propertyValue;
                }
            });
        }
    };
};

/**
 * We need to track redirectionTime by this way instead of tracking location change
 * As we need to get time when redirection is started instead of when redirection is over
 * By this approach time tracking is more accurate
 */
export const trackRedirectStart = (): void => {
    const go = window.history.go.bind(window.history);
    const push = window.history.pushState.bind(window.history);
    const replace = window.history.replaceState.bind(window.history);

    const trackRedirectStartTime = () => {
        window[redirectStartTime] = Date.now();
    };

    window.history.go = (...args) => {
        trackRedirectStartTime();
        return go(...args);
    };
    window.history.pushState = (...args) => {
        trackRedirectStartTime();
        return push(...args);
    };
    window.history.replaceState = (...args) => {
        trackRedirectStartTime();
        return replace(...args);
    };

    window.addEventListener('popstate', trackRedirectStartTime);
};

export const configurePdfViewer = (): void => {
    pdfjs.GlobalWorkerOptions.workerSrc = '/assets/scripts/pdf.worker.min.js';
};
