/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Controller, useFormContext } from 'react-hook-form';
/**
 * Constants
 */
import { VALIDATION_MESSAGE } from 'constants/validation';
/**
 * Types
 */
import { TFieldControllerProps, TPropsWithoutValue } from './types';

/**
 * Expo
 */

export type FieldControllerProps<
    TProps,
    TValue = string
> = TFieldControllerProps<TProps, TValue> & TPropsWithoutValue<TProps, TValue>;

export const FieldController = <TProps, TValue = string>({
    name,
    pattern,
    validate,
    defaultValue,
    dataTestId,
    isRequired = false,
    component: Component,
    ...props
}: FieldControllerProps<TProps, TValue>): JSX.Element => {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={{
                pattern,
                validate,
                required: {
                    value: isRequired,
                    message: VALIDATION_MESSAGE,
                },
            }}
            render={({
                field: { onChange, onBlur, value },
                fieldState: { invalid, error },
                formState,
            }) => (
                <Component
                    {...props}
                    value={value}
                    onBlur={onBlur}
                    isInvalid={invalid}
                    isRequired={isRequired}
                    onValueChange={onChange}
                    validationMessage={error?.message}
                    dataTestId={dataTestId}
                    formState={formState}
                />
            )}
        />
    );
};
