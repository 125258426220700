/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { EditorThemeClasses } from 'lexical';

export const editorID = 'RichEditor2';

type CustomClasses = {
    editor: {
        root: string;
        sticky: string;
        readonly: string;
        input: string;
    };
    toolbar: {
        root: string;
        sticky: string;
    };
    labeledInput: string;
};

export const theme: EditorThemeClasses & CustomClasses = {
    rtl: `${editorID}-rtl`,
    ltr: `${editorID}-ltr`,
    paragraph: `${editorID}-paragraph`,
    link: `${editorID}-link`,
    text: {
        bold: `${editorID}-bold`,
        italic: `${editorID}-italic`,
        underline: `${editorID}-underline`,
        strikethrough: `${editorID}-strikethrough`,
        code: `${editorID}-code`,
        highlight: `${editorID}-highlight`,
    },
    heading: {
        h1: `${editorID}-h1`,
        h2: `${editorID}-h2`,
        h3: `${editorID}-h3`,
    },
    codeBlock: `${editorID}-codeBlock`,
    image: `${editorID}-image`,
    list: {
        listitem: `${editorID}-listItem`,
        nested: {
            listitem: `${editorID}-nestedListItem`,
        },
        ol: `${editorID}-ol`,
        olDepth: [
            `${editorID}-olDepth1`,
            `${editorID}-olDepth2`,
            `${editorID}-olDepth3`,
            `${editorID}-olDepth4`,
        ],
        ul: `${editorID}-ul`,
    },
    editor: {
        root: `${editorID}-root`,
        sticky: `${editorID}-root-sticky`,
        readonly: `${editorID}-root-readonly`,
        input: `${editorID}-input`,
    },
    toolbar: {
        root: `${editorID}-toolbar`,
        sticky: `${editorID}-toolbar-sticky`,
    },
    labeledInput: `${editorID}-labeledInput`,
};
