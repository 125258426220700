/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { UserRow } from 'components/common';
/**
 * Types
 */
import { TItem } from './types';
/**
 * Expo
 */
export const renderItem: TItem = ({ full_name, email }) => (
    <UserRow name={full_name} email={email} />
);
