/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Tab } from './Tab';
/**
 * Types
 */
import { TabsProps } from './types';
/**
 * Assets
 */
import css from './style.module.scss';

/**
 * Expo
 */
export const VerticalTabs: React.FC<TabsProps> = ({
    tabs,
    currentTabId,
    disabledTabs,
    dataTestId,
}) => (
    <div className={css.container} id={dataTestId}>
        {tabs.map(({ id, name, nestedList }) => {
            const testId = `${dataTestId}/${id}`;

            return (
                <Tab
                    key={id}
                    id={id}
                    caption={name}
                    nestedList={nestedList}
                    currentTabId={currentTabId}
                    disabledTabs={disabledTabs}
                    dataTestId={testId}
                />
            );
        })}
    </div>
);
