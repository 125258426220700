/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Hooks
 */
import { useCallback } from 'react';
import { useRedirect } from 'hooks';
/**
 * Services
 */
import SessionStorage from 'services/sessionStorage';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
import { RoleTypes } from 'constants/roles';
import { PlatformTypes } from 'constants/system';
import { SESSION_STORAGE_KEYS } from 'constants/localStorage';
import {
    AGILE_SECURITY_PLATFORM_ROLES,
    SECURITY_DELIVERY_CONSOLE_ROLES,
} from './constants';
/**
 * Types
 */
import {
    TRedirectToAgileSecurityPlatform,
    TRedirectToDefaultPage,
    TRedirectToSecurityDeliveryConsole,
} from './types';

/**
 * Expo
 */
const useRedirectToSecurityDeliveryConsole =
    (): TRedirectToSecurityDeliveryConsole => {
        const redirect = useRedirect();

        return useCallback(
            (role) => {
                switch (role) {
                    case RoleTypes.salesManager:
                    case RoleTypes.operationsManager:
                    case RoleTypes.projectRepresentative:
                    default:
                        redirect(PAGES.requests);
                        break;
                }
            },
            [redirect]
        );
    };

const useRedirectToAgileSecurityPlatform =
    (): TRedirectToAgileSecurityPlatform => {
        const redirect = useRedirect();
        const redirectToSecurityDeliveryConsole =
            useRedirectToSecurityDeliveryConsole();

        return useCallback(
            (role, projectId) => {
                switch (role) {
                    case RoleTypes.admin:
                        redirect(PAGES.persons);
                        break;
                    case RoleTypes.operationsManager:
                        redirect(PAGES.projects);
                        break;
                    case RoleTypes.projectRepresentative:
                        if (projectId) {
                            redirect(PAGES.dashboard, {
                                projectId,
                            });
                        } else {
                            redirectToSecurityDeliveryConsole(role);
                        }
                        break;
                    case RoleTypes.securitySpecialist:
                    default:
                        if (projectId) {
                            redirect(PAGES.dashboard, {
                                projectId,
                            });
                        } else {
                            redirect(PAGES.withoutProject);
                        }
                        break;
                }
            },
            [redirect, redirectToSecurityDeliveryConsole]
        );
    };

export const useRedirectToDefaultPage = (): TRedirectToDefaultPage => {
    const redirect = useRedirect();
    const redirectToAgileSecurityPlatform =
        useRedirectToAgileSecurityPlatform();
    const redirectToSecurityDeliveryConsole =
        useRedirectToSecurityDeliveryConsole();

    const redirectUrl = SessionStorage.get(SESSION_STORAGE_KEYS.redirectUrl);

    return useCallback(
        (role, projectId, platform = PlatformTypes.agileSecurityPlatform) => {
            if (redirectUrl) {
                redirect(redirectUrl);
                SessionStorage.remove(SESSION_STORAGE_KEYS.redirectUrl);

                return;
            }

            const isSecurityDeliveryConsole =
                platform === PlatformTypes.securityDeliveryConsole;
            const isAgileSecurityPlatform =
                platform === PlatformTypes.agileSecurityPlatform;

            if (isSecurityDeliveryConsole) {
                if (SECURITY_DELIVERY_CONSOLE_ROLES.includes(role)) {
                    redirectToSecurityDeliveryConsole(role);
                } else {
                    redirectToAgileSecurityPlatform(role, projectId);
                }
            } else if (isAgileSecurityPlatform) {
                if (AGILE_SECURITY_PLATFORM_ROLES.includes(role)) {
                    redirectToAgileSecurityPlatform(role, projectId);
                } else {
                    redirectToSecurityDeliveryConsole(role);
                }
            }
        },
        [
            redirectUrl,
            redirect,
            redirectToAgileSecurityPlatform,
            redirectToSecurityDeliveryConsole,
        ]
    );
};
