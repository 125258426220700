/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { cx } from '@epam/uui-core';
import { Badge, FlexRow, PanelBlock, Text, UserList } from 'components/common';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Constants
 */
import { CAPTIONS_BY_STATUS, StatusTypes } from 'constants/statuses';
import { ColorNames } from 'constants/colors';
/**
 * Types
 */
import { TActivityInfoProps } from './types';
/**
 * Expo
 */
export const ActivityInfo: React.FC<TActivityInfoProps> = ({
    activity: { status, assignees, description, blocked },
}) => {
    const statusClassName = cx({
        [css.toDo]: status === StatusTypes.toDo,
        [css.done]: status === StatusTypes.done,
        [css.inProgress]: status === StatusTypes.inProgress,
    });

    return (
        <PanelBlock dataTestId="activity/activityInfo">
            <Text
                fontSize="24"
                color="gray80"
                fontWeight="400"
                dataTestId="activity/activityInfo/title"
            >
                Activity info
            </Text>
            <FlexRow vPadding="12" columnGap="6">
                <Text color="gray60" fontSize="16" cx={css.titleColumn}>
                    Status
                </Text>
                <Text
                    cx={[statusClassName, css.activityStatus]}
                    fontSize="16"
                    lineHeight="24"
                    fontWeight="600"
                    dataTestId="activity/activityInfo/status"
                >
                    {status && CAPTIONS_BY_STATUS[status]}
                </Text>
                {blocked && (
                    <Badge
                        cx={css.blockedBadge}
                        caption="Blocked"
                        color={ColorNames.red}
                        fill="solid"
                    />
                )}
            </FlexRow>
            {!assignees?.persons ? null : (
                <FlexRow vPadding="12" cx="align-baseline">
                    <Text color="gray60" fontSize="16" cx={css.titleColumn}>
                        Performers
                    </Text>
                    <UserList
                        list={assignees.persons}
                        dataTestId="activity/activityInfo/performers"
                    />
                </FlexRow>
            )}
            {description && (
                <FlexRow>
                    <Text color="gray60" fontSize="16" cx={css.titleColumn}>
                        Description
                    </Text>
                    <Text
                        fontSize="16"
                        dataTestId="activity/activityInfo/description"
                    >
                        {description}
                    </Text>
                </FlexRow>
            )}
        </PanelBlock>
    );
};
