/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createSlice, createSelector } from '@reduxjs/toolkit';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { INITIAL_STATE } from './constants';
import { Methods } from 'constants/request';
/**
 * Utils
 */
import { LoadAsync } from 'utils/loadAsync';
import { createQueryUrl, templateString } from 'utils';
import { getCategories, mapOptions, normalizeTasks } from './utils';
/**
 * Types
 */
import {
    AppDispatch,
    TAssessmentTrackerIssues,
    TGetState,
    TSelector,
    TSelectorWithProps,
} from 'types';
import {
    TOption,
    TOnLoadAsync,
    TTrackerTaskById,
    TManualTaskById,
    TAsyncTaskDetails,
} from './types';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Store
 */
import { selectCurrentProjectId } from 'store/project';
/**
 * Reducer
 */
const { reducer, actions } = createSlice({
    name: 'trackerIssues',
    initialState: INITIAL_STATE,
    reducers: {
        saveTrackerIssues: (state, { payload: { idList, tasksById } }) => {
            state.trackerTasks = { tasksById, idList };
        },
        saveManualTasks: (state, { payload: { idList, tasksById } }) => {
            state.manualTasks = { tasksById, idList };
        },
        saveFilter: (state, { payload }) => {
            state.filter = payload;
        },
        setIsInitialized: (state, { payload }) => {
            state.isInitialized = payload;
        },
        setIsTrackerIssueRequested: (state, { payload }) => {
            state.isTrackerIssueRequested = payload;
        },
        setInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        resetTrackerIssues: () => INITIAL_STATE,
    },
});

export default reducer;
/**
 * Selectors
 */
export const selectIsInitialized: TSelector<boolean> = ({
    trackerIssues: { isInitialized },
}) => isInitialized;
export const selectIsisTrackerIssueRequested: TSelector<boolean> = ({
    trackerIssues: { isTrackerIssueRequested },
}) => isTrackerIssueRequested;
export const selectTrackerTasksIdList: TSelector<string[]> = ({
    trackerIssues: {
        trackerTasks: { idList },
    },
}) => idList;
export const selectManualTasksIdList: TSelector<string[]> = ({
    trackerIssues: {
        manualTasks: { idList },
    },
}) => idList;
export const selectTrackerTasksById: TSelector<TTrackerTaskById> = ({
    trackerIssues: {
        trackerTasks: { tasksById },
    },
}) => tasksById;
export const selectManualTaskById: TSelector<TManualTaskById> = ({
    trackerIssues: {
        manualTasks: { tasksById },
    },
}) => tasksById;
export const selectTasksFilterOptions: TSelector<TOption[]> = ({
    trackerIssues: {
        filter: { options },
    },
}) => options;
export const selectFilterOptionsTotalCount: TSelector<number> = ({
    trackerIssues: {
        filter: { totalCount },
    },
}) => totalCount;
const selectId: TSelectorWithProps<string, string> = (_, id) => id;

export const selectTrackerTask = createSelector(
    [selectTrackerTasksById, selectId],
    (tasksById, id) => tasksById[id]
);

export const selectManualTask = createSelector(
    [selectManualTaskById, selectId],
    (tasksById, id) => tasksById[id]
);

export const selectTrackerTaskList = createSelector(
    [selectTrackerTasksById],
    (tasksById) => Object.values(tasksById)
);

export const selectInProgress: TSelector<boolean> = ({
    trackerIssues: { inProgress },
}) => inProgress;

/**
 * Actions
 */
export const {
    saveFilter,
    setInProgress,
    setIsInitialized,
    saveManualTasks,
    saveTrackerIssues,
    resetTrackerIssues,
    setIsTrackerIssueRequested,
} = actions;

export const handleSaveManualTasks =
    (manualTasks: TAssessmentTrackerIssues[]) =>
    (dispatch: AppDispatch): void => {
        const { idList, itemById } = normalizeTasks(manualTasks);

        dispatch(saveManualTasks({ idList, tasksById: itemById }));
    };

export const handleRequestTasks =
    (categories: Record<string, string[]>) =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const projectId = selectCurrentProjectId(getState());

        const url = templateString(ENDPOINTS.trackerIssues, { projectId });

        const queryUrl = createQueryUrl(
            url,
            { ...categories },
            { arrayFormat: 'repeat' }
        );

        const trackerTasks = await api.request<TAssessmentTrackerIssues[]>(
            Methods.get,
            { url: queryUrl }
        );

        const { idList, itemById } = normalizeTasks(trackerTasks);

        dispatch(saveTrackerIssues({ idList, tasksById: itemById }));
        dispatch(setIsTrackerIssueRequested(true));
    };

const loadTasks =
    (projectId: string, categories: Record<string, string[]>) =>
    (dispatch: AppDispatch, getState: TGetState): Promise<void> =>
        new Promise<void>((resolve) => {
            const idList = selectTrackerTasksIdList(getState());
            const onLoad: TOnLoadAsync = () => {
                const url = templateString(ENDPOINTS.loadIssues, { projectId });

                const queryUrl = createQueryUrl(
                    url,
                    { ...categories },
                    { arrayFormat: 'repeat' }
                );

                return api.request(Methods.get, { url: queryUrl });
            };

            const instance = new LoadAsync<TAsyncTaskDetails>({
                onLoad,
                onComplete: async () => {
                    resolve();

                    await dispatch(handleRequestTasks(categories));
                    dispatch(setInProgress(false));
                },
                onFail: () => {
                    dispatch(setInProgress(false));
                },
                onCallAsyncTask: async ({
                    details_json: { itemsProcessed, totalItemsCount },
                }) => {
                    if (
                        itemsProcessed === 0 ||
                        idList.length === totalItemsCount
                    )
                        return;

                    await dispatch(handleRequestTasks(categories));
                },
            });

            instance.start();
        });

export const handleLoadTrackerTasks =
    (filterValue: TOption[]) =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        dispatch(setInProgress(true));

        const projectId = selectCurrentProjectId(getState());
        const categories = getCategories(filterValue);

        await dispatch(loadTasks(projectId, categories));
    };

export const handleGetTasksFilterOptions =
    (projectId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setInProgress(true));

        const url = templateString(ENDPOINTS.loadTasksCategories, {
            projectId,
        });

        const response = await api.request<Record<string, TOption[]>>(
            Methods.post,
            { url }
        );

        const filter = mapOptions(response);

        dispatch(saveFilter(filter));
    };

export const handleGetManualTasks =
    (projectId: string, assessmentId?: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setInProgress(true));

        const url = createQueryUrl(ENDPOINTS.manualTasks, {
            projectId,
            assessmentId,
        });

        const response = await api.request<TAssessmentTrackerIssues[]>(
            Methods.get,
            { url }
        );

        dispatch(handleSaveManualTasks(response));
    };

export const handleGetTrackerIssueData =
    (projectId: string, assessmentId: string) =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const {
            project: {
                data: { jira },
            },
            assessment: { issueFilter },
        } = getState();

        try {
            dispatch(setInProgress(true));

            const issueFilterExisted =
                issueFilter &&
                Object.values(issueFilter).flat().filter(Boolean).length;

            if (issueFilterExisted && jira) {
                await dispatch(handleRequestTasks(issueFilter));
            }

            if (jira) {
                await dispatch(handleGetTasksFilterOptions(projectId));
            }

            await dispatch(handleGetManualTasks(projectId, assessmentId));

            dispatch(setIsInitialized(true));
        } finally {
            dispatch(setInProgress(false));
        }
    };
