/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import React, { FC } from 'react';
import { ReactComponent as hIcon } from '@epam/assets/icons/common/editor-headline-24.svg';
import { ReactComponent as h1Icon } from '@epam/assets/icons/common/editor-headline_h1-24.svg';
import { ReactComponent as h2Icon } from '@epam/assets/icons/common/editor-headline_h2-24.svg';
import { ReactComponent as h3Icon } from '@epam/assets/icons/common/editor-headline_h3-24.svg';
import { ReactComponent as bulletListIcon } from '@epam/assets/icons/common/editor-list_bullet-24.svg';
import { ReactComponent as codeIcon } from '@epam/assets/icons/common/editor-code-24.svg';
import { ReactComponent as numberListIcon } from '@epam/assets/icons/common/editor-list_number-24.svg';
import { ToolbarButton } from './ToolbarButton';
import {
    $createParagraphNode,
    $getNodeByKey,
    $getSelection,
    $isRangeSelection,
    LexicalEditor,
} from 'lexical';
import { $setBlocksType } from '@lexical/selection';
import { $createHeadingNode } from '@lexical/rich-text';
import {
    $createListNode,
    $isListItemNode,
    $isListNode,
    insertList,
    ListNode,
    removeList,
} from '@lexical/list';
import {
    DELETE_CODE_BLOCK_COMMAND,
    INSERT_CODE_BLOCK_COMMAND,
} from '../../CodeBlock';
import { useOnMount } from 'hooks';
import { CodeBlockNode } from 'components/common/RichEditor2/nodes/CodeBlock/CodeBlockNode';
import { calculateNextListStartNumber } from 'components/common/RichEditor2/utils';

// TODO: don't use hardcoded literals all over RTE2 files, use one enum instead
export enum BlockType {
    paragraph = 'extended-paragraph',
    bullet = 'bullet',
    number = 'number',
    code = 'code-block',
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    quote = 'quote',
}

export const blockTypes = [
    BlockType.paragraph,
    BlockType.bullet,
    BlockType.number,
    BlockType.code,
    BlockType.h1,
    BlockType.h2,
    BlockType.h3,
    BlockType.quote,
];

export type HeadingType = BlockType.h1 | BlockType.h2 | BlockType.h3;
const HEADINGS = [
    { icon: h1Icon, style: BlockType.h1 as HeadingType },
    { icon: h2Icon, style: BlockType.h2 as HeadingType },
    { icon: h3Icon, style: BlockType.h3 as HeadingType },
];

export const HEADING_BLOCK = {
    icon: hIcon,
    items: HEADINGS,
};

export interface HeadingProps {
    activeBlockType: BlockType;
    editor: LexicalEditor;
}

export const Heading: FC<HeadingProps> = ({ editor, activeBlockType }) => {
    const handleClick = (heading: HeadingType) => {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                if (activeBlockType === heading) {
                    $setBlocksType(selection, () => $createParagraphNode());
                } else {
                    $setBlocksType(selection, () =>
                        $createHeadingNode(heading)
                    );
                }
            }
        });
    };
    return (
        <ToolbarButton<HeadingType>
            icon={hIcon}
            items={HEADINGS}
            blockType={activeBlockType}
            onClick={handleClick}
            isActive={HEADINGS.some(({ style }) => style === activeBlockType)}
        />
    );
};

export type ListType = BlockType.bullet | BlockType.number;

interface ListControlProps {
    style: ListType;
    activeBlockType: BlockType;
    editor: LexicalEditor;
}

const iconToStyleMap = {
    [BlockType.bullet]: bulletListIcon,
    [BlockType.number]: numberListIcon,
};
export const ListControl: FC<ListControlProps> = ({
    style,
    activeBlockType,
    editor,
}) => {
    const handleClick = () => {
        if (activeBlockType === style) {
            removeList(editor);
        }

        insertList(editor, style);
    };

    function updateNumbering(listNode: ListNode, start: number) {
        const newList = $createListNode(listNode.getListType(), start);
        listNode.replace(newList, true);
    }

    useOnMount(() => {
        // In lists with code blocks, when a list item is added or removed, the following lists numbering needs to be updated.
        // See `insertNewAfter` method in `CodeBlockNode
        return editor.registerMutationListener(
            ListNode,
            function updateListsWithCodeBlocksNumbering(
                nodeMutations,
                { prevEditorState }
            ) {
                nodeMutations.forEach((action, key) => {
                    const editorState =
                        action === 'destroyed'
                            ? prevEditorState
                            : editor.getEditorState();
                    editorState.read(() => {
                        const currentNode = $getNodeByKey<ListNode>(key);
                        const currentNodeFirstChildKey = currentNode
                            ?.getFirstChild()
                            ?.getKey();
                        const currentNodeListItemsLength =
                            currentNode?.getChildren()?.filter($isListItemNode)
                                .length ?? 0;
                        const nextNode = currentNode?.getNextSibling();
                        const nextNextNodeKey = nextNode
                            ?.getNextSibling()
                            ?.getKey();

                        editor.update(() => {
                            const nextNextNode =
                                nextNextNodeKey &&
                                $getNodeByKey(nextNextNodeKey);
                            const containsCodeBlock =
                                currentNode &&
                                nextNode &&
                                nextNode.getType() ===
                                    CodeBlockNode.getType() &&
                                nextNextNode &&
                                $isListNode(nextNextNode);

                            if (containsCodeBlock) {
                                // When the upper list is updated, the `replace` method inside `updateNumbering` initiates a chain of updates.
                                // This process creates a shallow copy of the next list with updated numbering and so on.
                                // The immutability of the list triggers an extra `destroyed` mutation action as a side effect.
                                // However, we need to react only to the real `destroyed` action (when the user really removed, it starts the chain of updates), so we skip these side mutations.
                                const wasAlreadyReplacedWithUpdatedNumbering =
                                    action === 'destroyed' &&
                                    currentNodeFirstChildKey &&
                                    $getNodeByKey(currentNodeFirstChildKey);

                                if (!wasAlreadyReplacedWithUpdatedNumbering) {
                                    const nextNumber =
                                        calculateNextListStartNumber(
                                            currentNode,
                                            currentNodeListItemsLength,
                                            action
                                        );

                                    updateNumbering(nextNextNode, nextNumber);
                                }
                            }
                        });
                    });
                });
            }
        );
    });

    return (
        <ToolbarButton<ListType>
            icon={iconToStyleMap[style]}
            style={style}
            onClick={handleClick}
            isActive={activeBlockType === style}
        />
    );
};

interface CodeBlockControlProps {
    editor: LexicalEditor;
    isActive: boolean;
}

export const CodeBlockControl: FC<CodeBlockControlProps> = ({
    editor,
    isActive,
}) => {
    const handleClick = () => {
        if (isActive) {
            editor.dispatchCommand(DELETE_CODE_BLOCK_COMMAND, undefined);
        } else {
            editor.dispatchCommand(INSERT_CODE_BLOCK_COMMAND, undefined);
        }
    };
    return (
        <ToolbarButton
            icon={codeIcon}
            isActive={isActive}
            onClick={handleClick}
        />
    );
};
