/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
/**
 * Types
 */
import { TComment, TAttachment, TActivity } from 'types';
import { TBlockActivityPayload, TCreateCommentFormValue } from './types';
/**
 * Utils
 */
import { templateString } from 'utils';
/**
 * Services
 */
import { api } from 'services/api';

/**
 * Expo
 */
export const requestComments = async (
    activityId: string
): Promise<TComment[]> => {
    const url = templateString(ENDPOINTS.singleActivityComments, {
        activityId,
    });

    return api.request(Methods.get, { url });
};

export const requestAddComment = async (
    activityId: string,
    { comment, attachments }: TCreateCommentFormValue
): Promise<void> => {
    const formData = new FormData();

    const url = templateString(ENDPOINTS.singleActivityComments, {
        activityId,
    });

    attachments.forEach(({ file }: TAttachment) => {
        formData.append('files', file, file.name);
    });

    formData.append('commentPayload', JSON.stringify({ comment }));

    await api.request(Methods.post, { url, data: formData });
};

export const requestBlockActivity = async ({
    activityId,
    comment,
}: TBlockActivityPayload): Promise<void> => {
    const url = templateString(ENDPOINTS.blockActivity, { activityId });

    await api.request(Methods.post, { url, data: { comment } });
};

export const requestUnblockActivity = async (
    activityId: string
): Promise<void> => {
    const url = templateString(ENDPOINTS.unblockActivity, { activityId });

    await api.request(Methods.put, { url });
};

export const requestSingleActivity = async (
    activityId: string
): Promise<TActivity> => {
    const url = templateString(ENDPOINTS.singleActivity, {
        activityId,
    });

    return api.request(Methods.get, { url });
};
