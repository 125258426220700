/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React from 'react';
import { PanelBlock } from 'components/common';
import { ExpandableTree } from './components/ExpandableTree';
import css from './style.module.scss';
import { WithDataTestId } from 'types';
import { useModelTreeItems } from 'pages/ThreatModeling/tabs/ModelTreeTab/hooks/useModelTreeItems';

export const ModelTreeTab: React.FC<WithDataTestId & { tmId: string }> = ({
    tmId,
    dataTestId,
}) => {
    const { treeItems } = useModelTreeItems(tmId);

    return (
        <PanelBlock cx={css.panel}>
            {treeItems.map((item) => (
                <ExpandableTree
                    key={item.type}
                    title={item.title}
                    type={item.type}
                    emptyListMessage={item.emptyListMessage}
                    subItems={item.subItems}
                    openByDefault
                    dataTestId={`${dataTestId}/${item.title}`}
                />
            ))}
        </PanelBlock>
    );
};
