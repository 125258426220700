/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useMemo, useState } from 'react';
/**
 * Components
 */
import { LabeledInput, TextInput as UuiTextInput } from '@epam/promo';
/**
 * Constants
 */
import { VALIDATION_MESSAGE } from 'constants/validation';
/**
 * Assets
 */
import { ReactComponent as eyeIcon } from '@epam/assets/icons/common/action-eye-18.svg';
import css from './style.module.scss';
/**
 * Types
 */
import { TTextInputProps } from './types';
/**
 * Utils
 */
import { getRawProps } from 'utils';
import { cx } from '@epam/uui-core';
/**
 * Expo
 */
export const TextInput: React.FC<TTextInputProps> = ({
    type,
    label,
    isInvalid,
    value = '',
    isDisabled,
    isReadonly,
    isRequired,
    dataTestId,
    placeholder,
    validationMessage = VALIDATION_MESSAGE,
    onValueChange,
    onBlur,
    isOptional,
    info,
    size,
    labelCx,
    hint,
    customErrorMessage,
    ...props
}) => {
    const [showPasswordValue, setPasswordValueVisibility] = useState(false);

    const handleChange = useCallback(
        (inputValue: string) => {
            if (onValueChange) {
                onValueChange(inputValue.trim() ? inputValue : '');
            }
        },
        [onValueChange]
    );

    const handleClickIcon = useCallback(() => {
        setPasswordValueVisibility((origin) => !origin);
    }, []);

    const rawProps = useMemo(() => getRawProps({ dataTestId }), [dataTestId]);

    const inputRawProps = useMemo(
        () => getRawProps({ dataTestId: `${dataTestId}/input` }),
        [dataTestId]
    );

    const customErrorMessageNode = useMemo(
        () =>
            isInvalid &&
            !!customErrorMessage && (
                <span className={`uui-invalid-message ${css.customError}`}>
                    {customErrorMessage}
                </span>
            ),
        [isInvalid, customErrorMessage]
    );
    return (
        <LabeledInput
            label={label}
            isInvalid={isInvalid}
            isRequired={isRequired}
            validationMessage={validationMessage}
            isOptional={isOptional}
            cx={cx(css.inputLabel, labelCx)}
            info={info}
            rawProps={rawProps}
        >
            <UuiTextInput
                {...props}
                value={value}
                rawProps={inputRawProps}
                iconPosition="right"
                isInvalid={isInvalid}
                isDisabled={isDisabled}
                isReadonly={isReadonly}
                placeholder={placeholder}
                onValueChange={handleChange}
                onIconClick={handleClickIcon}
                size={size}
                onBlur={onBlur}
                type={showPasswordValue ? 'text' : type}
                icon={type === 'password' ? eyeIcon : undefined}
            />
            {!isInvalid && (
                <span className={`uui-invalid-message ${css.hint}`}>
                    {hint}
                </span>
            )}
            {customErrorMessageNode}
        </LabeledInput>
    );
};
