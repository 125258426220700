/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback } from 'react';
/**
 * Components
 */
import { Modal as ModalComponent } from 'components/common';
/**
 * Hooks
 */
import { useAppDispatch } from 'hooks';
/**
 * Store
 */
import { handleCompleteAssessment } from 'store/assessment';
import { handleGetList } from 'store/assessments';
/**
 * Types
 */
import { IModal } from '@epam/uui-core';
import { CompleteModalProps } from './types';
/**
 * Expo
 */
export const ConfirmationModal: React.FC<
    IModal<string> & CompleteModalProps
> = ({ assessment, projectId, ...props }) => {
    const dispatch = useAppDispatch();
    const handleSubmit = useCallback(async () => {
        await dispatch(handleCompleteAssessment());
        dispatch(handleGetList(projectId));
    }, [dispatch, projectId]);

    return (
        <ModalComponent
            {...props}
            title={`Complete assessment "${assessment}"`}
            bodyText={`You are going to complete "${assessment}". 
            It will no longer be available for adding or working with findings or activities.`}
            successButtonCaption="Submit"
            allowCloseOnCross={false}
            onSuccess={handleSubmit}
        />
    );
};
