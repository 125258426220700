/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { TStatusList } from './types';
/**
 * Expo
 */
export const getTotalAmount = (list: TStatusList): number =>
    Object.values(list).reduce(
        (accumulator, { amount }) => accumulator + amount,
        0
    );

export const calculateWidth = (list: TStatusList, amount: number): number => {
    const totalAmount = getTotalAmount(list);

    return (amount / totalAmount) * 100;
};
