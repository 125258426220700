/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Badge, FlexRow } from 'components/common';
/**
 * Types
 */
import { TOption } from '../types';
/**
 * Constants
 */
import {
    SeverityTypes,
    SEVERITY_COLOR_NAMES,
    SEVERITY_RANGE_CAPTIONS,
} from 'constants/index';
/**
 * Expo
 */
export const renderItem = ({ name }: TOption): JSX.Element => (
    <FlexRow>
        <Badge
            fill="solid"
            color={SEVERITY_COLOR_NAMES[name as SeverityTypes]}
            caption={SEVERITY_RANGE_CAPTIONS[name as SeverityTypes]}
        />
    </FlexRow>
);
