/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useEffect, useMemo, useRef } from 'react';
/**
 * Hooks
 */
import { useAppDispatch, useHover, useAppSelector, useRedirect } from 'hooks';
/**
 * Components
 */
import { DateFromNow } from 'components/DateFromNow';
import { LinkButton, Panel, Text, User, FlexRow } from 'components/common';
/**
 * Assets
 */
import css from '../style.module.scss';
/**
 * Utils
 */
import { cx } from '@epam/uui-core';
import { getRawProps } from 'utils';
/**
 * Store
 */
import { markAsRead } from 'store/notifications/actions';
import { selectDropdownNotification } from 'store/notifications/selectors';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
import { markAsReadDelay } from '../constants';
/**
 * Types
 */
import { TListItemProps } from './type';
/**
 * Expo
 */
export const ListItem: React.FC<TListItemProps> = ({ id, onClose }) => {
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const redirect = useRedirect();

    const isHovered = useHover(ref);

    const {
        title,
        description,
        is_viewed: isViewed,
        project_id: projectId,
        created_at: createdAt,
        sender: { full_name: fullName },
    } = useAppSelector((state) => selectDropdownNotification(state, id));

    useEffect(() => {
        let timerId = NaN;

        if (!isViewed && isHovered) {
            timerId = window.setTimeout(() => {
                dispatch(markAsRead(id));
            }, markAsReadDelay);
        } else if (timerId) {
            clearTimeout(timerId);
        }

        return () => {
            if (timerId) clearTimeout(timerId);
        };
    }, [id, isViewed, isHovered, dispatch]);

    const handleClickProject = () => {
        redirect(PAGES.projectInfo, { projectId });

        onClose?.();
    };

    const rawProps = useMemo(
        () => getRawProps({ dataTestId: 'header/notifications/unreadMark' }),
        []
    );

    return (
        <div ref={ref}>
            <Panel
                cx={cx(css.container, isHovered && css.hovered)}
                dataTestId="header/notifications/list/block"
            >
                {!isViewed && <div {...rawProps} className={css.unread} />}
                <Text
                    lineHeight="24"
                    fontSize="14"
                    size="24"
                    dataTestId="header/notifications/list/title"
                >
                    {title}
                </Text>
                <LinkButton
                    caption={description}
                    cx={css.projectName}
                    onClick={handleClickProject}
                    dataTestId="header/notifications/list/description"
                />
                <FlexRow columnGap="6" size="24">
                    <DateFromNow
                        date={createdAt}
                        dataTestId="header/notifications/list/date"
                    />
                    <User
                        name={fullName}
                        avatarSize="12"
                        textSize="12"
                        lineHeight="18"
                    />
                </FlexRow>
            </Panel>
        </div>
    );
};
