/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { ThreatsTab } from 'pages/ThreatModeling/tabs/ThreatsTab';
import React, { useEffect, useMemo } from 'react';
import { PageLoadHandler } from 'components/PageLoadHandler';
import { PageNavigation } from 'components/common';
import { useAppDispatch, useAppSelector, useRouteParams } from 'hooks';
import { useSelector } from 'react-redux';
import { resetData as resetActivityData } from 'store/singleActivity';
import css from './style.module.scss';
import { buildTabList } from './utils';
import {
    handleGetDfdMetadata,
    handleGetThreatModelingActivity,
    selectThreatModelingActivity,
    selectThreatModelingDfdMetadata,
    selectThreatModelingInProgress,
    selectThreatModelingIsInitialized,
} from 'store/threatModeling';
import { Tabs } from './constants';
import { ThreatModelingHeader } from './components/Header';
import { resetDfdMetadata } from 'store/threatModeling/slice';
import { ActivityInfoTab } from './tabs/ActivityInfoTab';
import { ModelTreeTab } from 'pages/ThreatModeling/tabs/ModelTreeTab';
import { ControlsTab } from './tabs/ControlsTab';

const ThreatModeling: React.FC = () => {
    const dispatch = useAppDispatch();

    const inProgress = useSelector(selectThreatModelingInProgress);
    const isInitialized = useSelector(selectThreatModelingIsInitialized);

    const {
        tabId = Tabs.activityInfo,
        projectId,
        activityId: threatModelingActivityId,
    } = useRouteParams();

    const activity = useAppSelector(selectThreatModelingActivity);
    const dfdMetadata = useSelector(selectThreatModelingDfdMetadata);

    useEffect(() => {
        if (threatModelingActivityId) {
            dispatch(handleGetThreatModelingActivity(threatModelingActivityId));
            dispatch(handleGetDfdMetadata(threatModelingActivityId));
        }

        return () => {
            dispatch(resetActivityData());
            dispatch(resetDfdMetadata());
        };
    }, [dispatch, threatModelingActivityId]);
    const tabList = buildTabList(projectId, threatModelingActivityId);
    const tabContent: React.ReactNode = useMemo(() => {
        switch (tabId) {
            case Tabs.activityInfo:
                return (
                    <ActivityInfoTab
                        threatModelingActivityId={threatModelingActivityId}
                        dfdMetadata={dfdMetadata}
                        activity={activity}
                        dataTestId="threatModeling/tab/activityInfo"
                    />
                );
            case Tabs.modelTree:
                return (
                    <ModelTreeTab
                        tmId={threatModelingActivityId}
                        dataTestId="threatModeling/tab/modelTree"
                    />
                );
            case Tabs.threats:
                return <ThreatsTab />;
            case Tabs.controls:
                return <ControlsTab />;
            default:
                return <div />;
        }
    }, [tabId, dfdMetadata, activity, threatModelingActivityId]);
    return (
        <PageLoadHandler
            isInitialized={isInitialized}
            inProgress={inProgress}
            renderHeader={() => (
                <ThreatModelingHeader
                    activityId={threatModelingActivityId}
                    updatedAt={activity?.updated_at}
                    hasDfd={!!dfdMetadata}
                    tabId={tabId}
                    dataTestId="threatModeling/header"
                />
            )}
        >
            <PageNavigation
                tabs={tabList}
                currentTab={tabId}
                className={css.tabMenu}
                dataTestId="threatModeling/navigation"
            />
            {tabContent}
        </PageLoadHandler>
    );
};

export default ThreatModeling;
