/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useMemo } from 'react';
/**
 * Components
 */
import { PickerInput, LabeledInput, Tooltip, IconContainer } from '@epam/promo';
/**
 * Constants
 */
import { VALIDATION_MESSAGE } from 'constants/validation';
/**
 * Utils
 */
import { getRawProps } from 'utils';
import { renderEmptyFooter } from './utils';
/**
 * Types
 */
import { DefaultSelectProps, TNameSelector } from './types';
/**
 * Assets
 */
import css from './style.module.scss';
import { TSelectValue } from 'types';
import { cx } from '@epam/uui-core';

/**
 * Expo
 */

export function Select<T>({
    icon,
    value,
    label,
    sorting,
    labelKey,
    maxItems,
    editMode,
    isInvalid,
    entityName,
    dataSource,
    isDisabled,
    isRequired,
    isOptional,
    dataTestId,
    isReadonly,
    placeholder,
    disableClear,
    searchPosition,
    tooltipContent,
    mode = 'single',
    tooltipPlacement,
    valueType = 'id',
    validationMessage = VALIDATION_MESSAGE,
    getName,
    renderRow,
    renderFooter,
    getRowOptions,
    renderToggler,
    onValueChange,
    onBlur,
    minBodyWidth = 360,
    cascadeSelection,
    getSearchFields,
    info,
    labelCx,
    size,
    notFoundMessage,
    onInput,
    emptyValue = [],
}: DefaultSelectProps<T>): JSX.Element {
    const handleChange = useCallback(
        (inputValue: TSelectValue) => {
            const valueIsEmpty = Array.isArray(inputValue)
                ? !inputValue.length
                : !inputValue;

            if (valueIsEmpty && !value) return;

            if (onValueChange) {
                onValueChange(inputValue);
            }
        },
        [value, onValueChange]
    );

    const getOptionName: TNameSelector<T> = useCallback(
        (option: T) => {
            if (getName) {
                return getName(option);
            }

            if (labelKey) {
                return String(option[labelKey]);
            }

            return '';
        },
        [getName, labelKey]
    );

    const rawProps = useMemo(() => getRawProps({ dataTestId }), [dataTestId]);

    const pickerInputRawProps = useMemo(() => {
        if (!dataTestId) return undefined;

        return {
            body: getRawProps({ dataTestId: `${dataTestId}/body` }),
            input: {
                ...getRawProps({ dataTestId: `${dataTestId}/input` }),
                onChange: onInput,
            },
        };
    }, [dataTestId, onInput]);

    return (
        <LabeledInput
            label={label}
            isRequired={isRequired}
            isOptional={isOptional}
            isInvalid={!isDisabled && isInvalid}
            validationMessage={validationMessage}
            cx={cx(css.optionalTextAlign, labelCx)}
            rawProps={rawProps}
            info={info}
        >
            <Tooltip content={tooltipContent} placement={tooltipPlacement}>
                <div className={icon && css.inputContainer}>
                    {icon && <IconContainer icon={icon} cx={css.icon} />}
                    <PickerInput<T, T[keyof T]>
                        size={size}
                        sorting={sorting}
                        editMode={editMode}
                        maxItems={maxItems}
                        rawProps={pickerInputRawProps}
                        selectionMode={mode}
                        value={value || null}
                        valueType={valueType}
                        entityName={entityName}
                        isDisabled={isDisabled}
                        isReadonly={isReadonly}
                        dataSource={dataSource}
                        getSearchFields={getSearchFields}
                        placeholder={placeholder}
                        disableClear={disableClear}
                        searchPosition={searchPosition}
                        isInvalid={!isDisabled && isInvalid}
                        renderRow={renderRow}
                        getName={getOptionName}
                        onValueChange={handleChange}
                        getRowOptions={getRowOptions}
                        renderToggler={renderToggler}
                        minBodyWidth={minBodyWidth}
                        onBlur={onBlur}
                        renderNotFound={
                            notFoundMessage ? () => notFoundMessage : undefined
                        }
                        emptyValue={emptyValue}
                        cascadeSelection={cascadeSelection}
                        renderFooter={renderFooter || renderEmptyFooter}
                    />
                </div>
            </Tooltip>
        </LabeledInput>
    );
}
