/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { TActivityCommonActionLog } from 'types';
import { DefaultRecentAction } from 'components/RecentActions/RecentAction';
import { FC, memo } from 'react';

export const UpdateActivity: FC<
    TActivityCommonActionLog & { activityName: string }
> = memo(({ activityName, author: { name } }) => (
    <DefaultRecentAction
        dataTestId="activityUpdated"
        user={name}
        action={`updated activity ${activityName}`}
    />
));
