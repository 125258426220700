/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Hooks
 */
import { useRole } from 'hooks';
import { useSelector } from 'react-redux';
/**
 * Components
 */
import { SearchInput, Text } from 'components/common';
import { FlexCell, FlexRow, FlexSpacer } from '@epam/promo';
/**
 * Constants
 */
/**
 * Types
 */
import { Props } from './types';
/**
 * Store
 */
import { selectIsEmpty } from 'store/assessments';
/**
 * Assets
 */
import { AddAssessmentButton } from '../AddAssessmentButton';

/**
 * Expo
 */
export const Header: React.FC<Props> = ({ onLoadAssessments }) => {
    const { isOperationsManager, isProjectRepresentative } = useRole();

    const isEmpty = useSelector(selectIsEmpty);

    return (
        <FlexRow>
            <Text cx="text-bold text-white text-size-30">Assessments</Text>
            <FlexSpacer />
            <FlexRow columnGap="12">
                {!isEmpty && (
                    <FlexCell minWidth={600}>
                        <SearchInput
                            dataTestId="assessments/search"
                            onValueChange={onLoadAssessments}
                            placeholder="Search by assessment name"
                        />
                    </FlexCell>
                )}
                {(isOperationsManager || isProjectRepresentative) && (
                    <AddAssessmentButton
                        onLoadAssessments={onLoadAssessments}
                    />
                )}
            </FlexRow>
        </FlexRow>
    );
};
