/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { PlatformRoutes } from './system';

const HOME = '/';
const ABOUT = '/about';
const LOGIN = '/login';
const CREATION = '/create';
const DEMO_REQUEST = '/demo-request';
const BLOCKED_USER_PAGE = '/blocked-user';
const UNKNOWN_USER_PAGE = '/unknown-user';
const SECURITY_CENTER = `/${PlatformRoutes.securityDeliveryConsole}`;
const AGILE_SECURITY_PLATFORM = `/${PlatformRoutes.agileSecurityPlatform}`;
// Feature toggles
const FEATURE_TOGGLES = `${AGILE_SECURITY_PLATFORM}/feature-toggles`;
// Persons
const PERSONS = `${AGILE_SECURITY_PLATFORM}/persons`;
const PERSON = `${PERSONS}/:personId`;
// Organizations
const ORGANIZATIONS = `${AGILE_SECURITY_PLATFORM}/organizations`;
const ORGANIZATION = `${AGILE_SECURITY_PLATFORM}/organization/:organizationId`;
// Projects
const PROJECTS = `${AGILE_SECURITY_PLATFORM}/projects`;
const WITHOUT_PROJECT_PAGE = `${AGILE_SECURITY_PLATFORM}/not-assigned-project`;
const PROJECT = `${AGILE_SECURITY_PLATFORM}/project/:projectId`;
const SINGLE_PROJECT_INFO = `${PROJECT}/info`;
const PROJECT_ATTACHMENTS = `${SINGLE_PROJECT_INFO}/attachments`;
const PROJECTS_SINGLE_PROJECT = `${PROJECTS}/:projectId`;
const PROJECT_CREATE = `${PROJECTS}${CREATION}`;
const PROJECTS_PROJECT_INFO = `${PROJECTS_SINGLE_PROJECT}/info`;
const PROJECT_POLICIES = `${PROJECT}/policies`;
const PROJECT_SINGLE_POLICY = `${PROJECT}/policies/:policyId`;
const SINGLE_PROJECT_POLICY_CREATION = `${PROJECT_POLICIES}/add`;
const PROJECTS_PROJECT_ATTACHMENTS = `${PROJECTS_PROJECT_INFO}/attachments`;
const PROJECTS_PROJECT_TARGETS = `${PROJECTS_SINGLE_PROJECT}/targets`;
const PROJECT_SINGLE_EDIT = `${PROJECTS_SINGLE_PROJECT}/edit`;
const JIRA_INTEGRATION = `${PROJECTS_SINGLE_PROJECT}/jira/add`;
const PROJECT_WITH_ENTITY = `${PROJECT}/:projectEntity/*`;
const PROJECTS_WITH_ENTITY = `${PROJECTS_SINGLE_PROJECT}/:projectEntity/*`;
const MESSENGER_INTEGRATION = `${PROJECT}/:messenger`;
const MESSENGER_INTEGRATION_WITH_ACTION = `${MESSENGER_INTEGRATION}/:action`;
const SLACK_INTEGRATION = `${PROJECT}/slack`;
const ADD_SLACK_INTEGRATION = `${SLACK_INTEGRATION}/add`;
const EDIT_SLACK_INTEGRATION = `${SLACK_INTEGRATION}/edit`;
const TEAMS_INTEGRATION = `${PROJECT}/teams`;
const ADD_TEAMS_INTEGRATION = `${TEAMS_INTEGRATION}/add`;
const EDIT_TEAMS_INTEGRATION = `${TEAMS_INTEGRATION}/edit`;
const AZURE_INTEGRATION = `${PROJECT}/azure`;
const ADD_AZURE_INTEGRATION = `${AZURE_INTEGRATION}/add`;
const EDIT_AZURE_INTEGRATION = `${AZURE_INTEGRATION}/edit`;
const AZURE_INTEGRATION_OPERATIONS_MANAGER = `${PROJECTS_SINGLE_PROJECT}/azure`;
const ADD_AZURE_INTEGRATION_OPERATIONS_MANAGER = `${AZURE_INTEGRATION_OPERATIONS_MANAGER}/add`;
const EDIT_AZURE_INTEGRATION_OPERATIONS_MANAGER = `${AZURE_INTEGRATION_OPERATIONS_MANAGER}/edit`;
const TRACKER_INTEGRATION = `${PROJECT}/:tracker`;
const TRACKER_INTEGRATION_OPERATIONS_MANAGER = `${PROJECTS_SINGLE_PROJECT}/:tracker`;
const TRACKER_INTEGRATION_WITH_ACTION = `${TRACKER_INTEGRATION}/:action`;
const TRACKER_INTEGRATION_OPERATIONS_MANAGER_WITH_ACTION = `${TRACKER_INTEGRATION_OPERATIONS_MANAGER}/:action`;
// Dashboard
const DASHBOARD = `${PROJECT}/dashboard`;
// Targets
const TARGETS = `${PROJECT}/targets`;
const TARGETS_OPERATIONS_MANAGER = `${PROJECTS_SINGLE_PROJECT}/targets`;
// Reports
const REPORTS = `${PROJECT}/reports`;
const SINGLE_REPORT = `${REPORTS}/:reportId`;
const CONFIGURE_DOCX_REPORT = `${REPORTS}/docx`;
const CONFIGURE_PDF_REPORT = `${REPORTS}/pdf`;
const REPORT_WITH_TYPE = `${REPORTS}/:reportType`;
// Assessments
const ASSESSMENTS = `${PROJECT}/assessments`;
const ASSESSMENT_CREATION = `${ASSESSMENTS}/create`;
const SINGLE_ASSESSMENTS = `${ASSESSMENTS}/:assessmentId`;
const ASSESSMENT_EDITING = `${SINGLE_ASSESSMENTS}/edit`;
const ASSESSMENTS_OPERATIONS_MANAGER = `${PROJECTS_SINGLE_PROJECT}/assessments`;
const ASSESSMENT_CREATION_OPERATIONS_MANAGER = `${ASSESSMENTS_OPERATIONS_MANAGER}/create`;
const SINGLE_ASSESSMENT_OPERATIONS_MANAGER = `${ASSESSMENTS_OPERATIONS_MANAGER}/:assessmentId`;
const ASSESSMENT_EDITING_OPERATIONS_MANAGER = `${SINGLE_ASSESSMENT_OPERATIONS_MANAGER}/edit`;
// Activities
const ACTIVITIES = `${AGILE_SECURITY_PLATFORM}/activities`;
const SINGLE_ACTIVITY = `${SINGLE_ASSESSMENTS}/activity/:activityId`;
const SINGLE_ACTIVITY_OPERATIONS_MANAGER = `${SINGLE_ASSESSMENT_OPERATIONS_MANAGER}/activity/:activityId`;
// Findings
const FINDINGS = `${PROJECT}/findings`;
const FINDING_CREATION = `${FINDINGS}/create`;
const SINGLE_FINDINGS = `${FINDINGS}/:findingId`;
const FINDING_EDITING = `${SINGLE_FINDINGS}/edit`;
// Vulnerabilities
const VULNERABILITIES = `${PROJECT}/vulnerabilities`;
const SINGLE_VULNERABILITY = `${VULNERABILITIES}/:vulnerabilityId`;
const FINDING_GENERATION = `${SINGLE_VULNERABILITY}/generate-finding`;
// Tasks
const TASKS = `${PROJECT}/tasks`;
const SINGLE_TASKS = `${TASKS}/:taskId`;
const SINGLE_MANUAL_TASK = `${TASKS}/manual/:taskId`;
// Action log
const ACTION_LOG = `${AGILE_SECURITY_PLATFORM}/action-log`;
// Requests
const REQUESTS = `${SECURITY_CENTER}/requests`;
const SINGLE_REQUEST = `${REQUESTS}/:requestId`;
// Notifications
const NOTIFICATIONS = 'notifications';
const NOTIFICATION_SETTINGS = `${PERSON}/notification-settings`;
const SINGLE_PROJECT_NOTIFICATIONS = `${PROJECT}/${NOTIFICATIONS}`;

const THREAT_MODELING = `${PROJECT}/threat-modeling/:activityId`;
const THREAT_MODELING_WITH_TAB = `${PROJECT}/threat-modeling/:activityId/:tabId`;

export const PAGES = {
    home: HOME,
    about: ABOUT,
    login: LOGIN,
    targets: TARGETS,
    targetsOperationManager: TARGETS_OPERATIONS_MANAGER,
    person: PERSON,
    persons: PERSONS,
    featureToggles: FEATURE_TOGGLES,
    demoRequest: DEMO_REQUEST,
    agileSecurityPlatform: AGILE_SECURITY_PLATFORM,
    securityCenter: SECURITY_CENTER,
    blockedUser: BLOCKED_USER_PAGE,
    unknownUser: UNKNOWN_USER_PAGE,
    // Organizations
    organization: ORGANIZATION,
    organizations: ORGANIZATIONS,
    // Projects
    project: PROJECT,
    projects: PROJECTS,
    projectInfo: SINGLE_PROJECT_INFO,
    projectsCreate: PROJECT_CREATE,
    projectEdit: PROJECT_SINGLE_EDIT,
    jiraIntegration: JIRA_INTEGRATION,
    messengerIntegrationWithAction: MESSENGER_INTEGRATION_WITH_ACTION,
    addSlackIntegration: ADD_SLACK_INTEGRATION,
    editSlackIntegration: EDIT_SLACK_INTEGRATION,
    addTeamsIntegration: ADD_TEAMS_INTEGRATION,
    editTeamsIntegration: EDIT_TEAMS_INTEGRATION,
    addAzureIntegration: ADD_AZURE_INTEGRATION,
    editAzureIntegration: EDIT_AZURE_INTEGRATION,
    projectPolicies: PROJECT_POLICIES,
    projectSinglePolicy: PROJECT_SINGLE_POLICY,
    addProjectPolicy: SINGLE_PROJECT_POLICY_CREATION,
    addAzureIntegrationOperationsManager:
        ADD_AZURE_INTEGRATION_OPERATIONS_MANAGER,
    editAzureIntegrationOperationsManager:
        EDIT_AZURE_INTEGRATION_OPERATIONS_MANAGER,
    trackerIntegrationWithAction: TRACKER_INTEGRATION_WITH_ACTION,
    trackerIntegrationOperationsManagerWithAction:
        TRACKER_INTEGRATION_OPERATIONS_MANAGER_WITH_ACTION,
    projectsSingleProject: PROJECTS_SINGLE_PROJECT,
    projectsSingleProjectsTargets: PROJECTS_PROJECT_TARGETS,
    projectsProjectInfo: PROJECTS_PROJECT_INFO,
    projectsProjecAttachments: PROJECTS_PROJECT_ATTACHMENTS,
    projectAttachments: PROJECT_ATTACHMENTS,
    withoutProject: WITHOUT_PROJECT_PAGE,
    projectWithEntity: PROJECT_WITH_ENTITY,
    projectsSingleProjectWithEntity: PROJECTS_WITH_ENTITY,
    // Dashboard
    dashboard: DASHBOARD,
    // Assessments
    assessments: ASSESSMENTS,
    singleAssessment: SINGLE_ASSESSMENTS,
    assessmentCreation: ASSESSMENT_CREATION,
    assessmentEditing: ASSESSMENT_EDITING,
    singleAssessmentOperationsManager: SINGLE_ASSESSMENT_OPERATIONS_MANAGER,
    assessmentsOperationsManager: ASSESSMENTS_OPERATIONS_MANAGER,
    assessmentCreationOperationsManager: ASSESSMENT_CREATION_OPERATIONS_MANAGER,
    assessmentEditingOperationManager: ASSESSMENT_EDITING_OPERATIONS_MANAGER,
    // Vulnerabilities
    vulnerabilities: VULNERABILITIES,
    singleVulnerability: SINGLE_VULNERABILITY,
    findingGeneration: FINDING_GENERATION,
    // Findings
    findings: FINDINGS,
    singleFinding: SINGLE_FINDINGS,
    findingCreation: FINDING_CREATION,
    findingEditing: FINDING_EDITING,
    // Tasks
    tasks: TASKS,
    singleTask: SINGLE_TASKS,
    singleManualTask: SINGLE_MANUAL_TASK,
    // Activities
    activities: ACTIVITIES,
    singleActivity: SINGLE_ACTIVITY,
    singleActivityOperationManager: SINGLE_ACTIVITY_OPERATIONS_MANAGER,
    // Reports
    reports: REPORTS,
    singleReport: SINGLE_REPORT,
    configureDocxReport: CONFIGURE_DOCX_REPORT,
    configurePdfReport: CONFIGURE_PDF_REPORT,
    reportWithType: REPORT_WITH_TYPE,
    // Action log
    actionLog: ACTION_LOG,
    // Requests
    requests: REQUESTS,
    singleRequest: SINGLE_REQUEST,
    // Notification
    singleProjectNotifications: SINGLE_PROJECT_NOTIFICATIONS,
    notifications: NOTIFICATIONS,
    notificationSettings: NOTIFICATION_SETTINGS,
    threatModeling: THREAT_MODELING,
    threatModelingWithTab: THREAT_MODELING_WITH_TAB,
};
