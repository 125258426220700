/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { ReportTypes } from 'constants/reports';
/**
 * Expo
 */
export const OPTIONS = [
    {
        id: ReportTypes.pdf,
        name: 'Generate pdf report',
        dataTestId: 'pdfReport',
    },
    {
        id: ReportTypes.withFiles,
        name: 'Upload report files',
        dataTestId: 'filesReport',
    },
    {
        id: ReportTypes.withDocxFile,
        name: 'Upload docx report and convert to pdf',
        dataTestId: 'uploadedDocxReport',
    },
    {
        id: ReportTypes.withLink,
        name: 'Add report link',
        dataTestId: 'linkReport',
    },
];

export const DOCX_REPORT_OPTIONS = [
    {
        id: ReportTypes.docx,
        name: 'Generate docx report',
        dataTestId: 'configuredDocxReport',
    },
];
