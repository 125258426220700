/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { AnyZodObject, ZodEffects } from 'zod';

export const extractShapeFromSchema = <
    Schema extends AnyZodObject | ZodEffects<AnyZodObject>
>(
    schema: Schema
) => {
    // when .refine() used on schema.
    if (schema instanceof ZodEffects) {
        // eslint-disable-next-line no-underscore-dangle
        return schema._def.schema.shape;
    }

    return schema.shape;
};
