/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Panel } from 'components/common';
import { ChooseRoleButton } from './ChooseRoleButton';
import { DropdownMenuButton, DropdownMenuBody } from '@epam/promo';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
/**
 * Constants
 */
import { ROLE_TITLES } from 'constants/roles';
/**
 * Types
 */
import { TDropdownBody } from './types';
/**
 * Store
 */
import { selectUserNameAndRole, selectUserRoles } from 'store/user';
/**
 * Expo
 */
export const DropdownBody: React.FC<TDropdownBody> = ({
    onClose,
    onLogOut,
    onChangeRole,
}) => {
    const roles = useSelector(selectUserRoles);
    const { name: userName, role } = useSelector(selectUserNameAndRole);

    return (
        <Panel
            shadow
            cx={css.dropdownMenuBodyContainer}
            dataTestId="header/userMenu/dropdown"
        >
            <DropdownMenuBody onClose={onClose} cx={css.rolesMenuBody}>
                <DropdownMenuButton
                    isDisabled
                    caption={userName}
                    cx={css.userName}
                />
                {roles.map(({ id, name }) => (
                    <ChooseRoleButton
                        key={id}
                        id={id}
                        onClose={onClose}
                        caption={ROLE_TITLES[name]}
                        onValueChange={onChangeRole}
                        isDisabled={role === name}
                        isActive={roles.length > 1 && role === name}
                    />
                ))}
                <DropdownMenuButton
                    caption="Log out"
                    onClick={onLogOut}
                    cx={css.logoutButton}
                />
            </DropdownMenuBody>
        </Panel>
    );
};
