/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Components
 */
import {
    Persons,
    Statuses,
    Assessment,
    Target,
    Severities,
    Activities,
} from './components';
/**
 * Constants
 */
import { FilterFields } from 'constants/filter';
/**
 * Types
 */
import { TFilterValue } from './types';
/**
 * Expo
 */
export const INITIAL_VALUE: TFilterValue = {};

export const FIELDS_BY_NAME = {
    [FilterFields.status]: Statuses,
    [FilterFields.persons]: Persons,
    [FilterFields.severity]: Severities,
    [FilterFields.assessment]: Assessment,
    [FilterFields.target]: Target,
    [FilterFields.activity]: Activities,
};
