/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useMemo } from 'react';
import { useArrayDataSource } from '@epam/uui-core';
/**
 * Components
 */
import { Select } from './DefaultSelect';
/**
 * Types
 */
import { ArraySelectProps, TArrayDataSource } from './types';

/**
 * Expo
 */
export function ArraySelect<T>({
    options = [],
    idKey,
    mode,
    value,
    valueType = 'id',
    isRequired,
    onBlur,
    onValueChange,
    ...props
}: ArraySelectProps<T>): JSX.Element {
    const dataSourceConfig = useMemo(() => {
        const config: TArrayDataSource<T> = {
            items: options,
        };

        if (idKey) {
            config.getId = (option: T) => option[idKey];
        }

        return config;
    }, [options, idKey]);

    const dataSource = useArrayDataSource<T, T[keyof T], unknown>(
        dataSourceConfig,
        [dataSourceConfig]
    );

    /**
     * it needs explicit type declaration because otherwise it's inferred as `null | never[]`
     * @see https://github.com/microsoft/TypeScript/issues/51979
     */
    const emptyValue: null | [] = useMemo(
        () => (mode === 'multi' ? [] : null),
        [mode]
    );

    return (
        <Select<T>
            {...props}
            value={options.length ? value : emptyValue}
            mode={mode}
            isRequired={isRequired}
            valueType={valueType}
            onBlur={onBlur}
            onValueChange={onValueChange}
            dataSource={dataSource}
            emptyValue={emptyValue}
        />
    );
}
