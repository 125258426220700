/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Modal as ModalComponent } from 'components/common';
/**
 * Types
 */
import { IModal } from '@epam/uui-core';
/**
 * Expo
 */
export const Modal: React.FC<IModal<string> & { name: string }> = ({
    name,
    ...props
}) => (
    <ModalComponent
        {...props}
        successButtonCaption="Submit"
        title={name}
        bodyText="This action cannot be undone. Are you sure?"
    />
);
