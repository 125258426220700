/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback } from 'react';
/**
 * Components
 */
import { Column } from './Column';
import { Panel } from '@epam/promo';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Utils
 */
import { getReordered } from './utils';
/**
 * Types
 */
import { TBoardProps } from './types';
import { TActivityStatus } from 'types';
/**
 * Expo
 */
export const ActivityBoard: React.FC<TBoardProps> = ({ list, onChange }) => {
    const onDragEnd = useCallback(
        (result: DropResult) => {
            const reorderedList = getReordered(result, list);

            if (reorderedList) {
                onChange({
                    reorderedList,
                    activityId: result.draggableId,
                    status: result.destination?.droppableId as TActivityStatus,
                });
            }
        },
        [list, onChange]
    );

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Panel cx={css.board}>
                {Object.entries(list).map(([status, idList]) => (
                    <Column
                        key={status}
                        idList={idList}
                        status={status as TActivityStatus}
                    />
                ))}
            </Panel>
        </DragDropContext>
    );
};
