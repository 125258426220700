/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { forwardRef } from 'react';
/**
 * Components
 */
import { Part } from './Part';
import { FlexRow } from 'components/common/FlexItems/FlexRow';
/**
 * Constants
 */
import { COLORS } from './constants';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { TStatusList } from './types';
import { WithDataTestId } from 'types';
/**
 * Utils
 */
import { calculateWidth, getTotalAmount } from './utils';
/**
 * Expo
 */
export const StatusBar: React.FC<
    {
        statusList?: TStatusList;
    } & WithDataTestId
> = forwardRef(({ statusList, dataTestId, ...rest }, ref) => {
    const totalAmount = getTotalAmount(statusList || []);

    return (
        <FlexRow ref={ref} dataTestId={dataTestId} rawProps={rest}>
            {!totalAmount ? (
                <span className={css.empty} />
            ) : (
                statusList?.map(({ status, amount }, index) => (
                    <Part
                        key={status + amount}
                        zIndex={statusList?.length - index}
                        width={calculateWidth(statusList, amount)}
                        color={COLORS[status]}
                    />
                ))
            )}
        </FlexRow>
    );
});
