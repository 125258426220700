/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { memo } from 'react';
/**
 * Components
 */
import { NestedTab } from './NestedTab';
import { SingleTab } from './SingleTab';
/**
 * Types
 */
import { TTabProps } from './types';
/**
 * Expo
 */
export const Tab: React.FC<TTabProps> = memo(
    ({ id, title = '', nestedMenu, dataTestId, activeTabId, href }) => {
        if (nestedMenu) {
            return (
                <NestedTab
                    nestedMenu={nestedMenu}
                    dataTestId={dataTestId}
                    activeTabId={activeTabId}
                />
            );
        }

        return (
            <SingleTab
                href={href}
                title={title}
                dataTestId={dataTestId}
                isActive={activeTabId === id}
            />
        );
    }
);
