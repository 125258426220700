/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useMemo, useCallback } from 'react';
/**
 * Components
 */
import { Buttons } from '../Buttons';
import { Steps } from 'components/Steps';
/**
 * Utils
 */
import { getDisabledSteps } from './utils';
/**
 * Types
 */
import { IStepsProps } from './types';
import { useFormContext } from 'react-hook-form';
/**
 * Expo
 */
export const ConfigureSteps: React.FC<IStepsProps> = ({
    dataTestId,
    steps,
    contentStepsIds,
    handleCancel,
    handleSubmitWitState,
    validateStep,
    renderStepContent,
}) => {
    const { getValues } = useFormContext();

    const selectedSteps = getValues('content');

    const disabledSteps = useMemo(
        () => getDisabledSteps({ contentStepsIds, selectedSteps }),
        [contentStepsIds, selectedSteps]
    );

    const renderButtons = useCallback(
        (tabId: string, lastTabId: string) => (
            <Buttons
                tabId={tabId}
                lastTabId={lastTabId}
                onCancel={handleCancel}
                selectedSteps={selectedSteps}
                onSubmit={handleSubmitWitState}
            />
        ),
        [handleCancel, handleSubmitWitState, selectedSteps]
    );

    return (
        <Steps
            steps={steps}
            dataTestId={dataTestId}
            validateStep={validateStep}
            renderButtons={renderButtons}
            disabledSteps={disabledSteps}
            renderStepContent={renderStepContent}
        />
    );
};
