/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
/**
 * Components
 */
import { PrivateRoute } from '../../../PrivateRoute';
/**
 * Constants
 */
import { RoleTypes } from 'constants/roles';
/**
 * Pages
 */
const FindingList = lazy(() => import('pages/FindingList'));
const SingleFinding = lazy(() => import('pages/SingleFinding'));
const FindingCreation = lazy(() => import('pages/FindingCreation'));
const FindingEditing = lazy(() => import('pages/FindingEditing'));
/**
 * Expo
 */
export const FindingsRoute: React.FC = () => (
    <Suspense fallback={null}>
        <Routes>
            <Route
                path="/"
                element={
                    <PrivateRoute
                        component={FindingList}
                        roles={[
                            RoleTypes.securitySpecialist,
                            RoleTypes.projectRepresentative,
                        ]}
                    />
                }
            />
            <Route
                path=":findingId"
                element={
                    <PrivateRoute
                        component={SingleFinding}
                        roles={[
                            RoleTypes.securitySpecialist,
                            RoleTypes.projectRepresentative,
                        ]}
                    />
                }
            />
            <Route
                path="create"
                element={
                    <PrivateRoute
                        component={FindingCreation}
                        roles={[RoleTypes.securitySpecialist]}
                    />
                }
            />
            <Route
                path=":findingId/edit"
                element={
                    <PrivateRoute
                        component={FindingEditing}
                        roles={[RoleTypes.securitySpecialist]}
                    />
                }
            />
        </Routes>
    </Suspense>
);
