/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback } from 'react';
/**
 * Hooks
 */
import { useRedirect } from 'hooks';
/**
 * Components
 */
import { FlexRow } from '@epam/promo';
import { IconButton } from 'components/common';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
/**
 * Types
 */
import { TActionsCell } from './types';
/**
 * Assets
 */
import { ReactComponent as editIcon } from '@epam/assets/icons/common/content-edit-18.svg';
/**
 * Expo
 */
export const ActionsCell: React.FC<TActionsCell> = ({ assessmentId }) => {
    const redirect = useRedirect();

    const handleEdit = useCallback(() => {
        redirect(PAGES.assessmentEditingOperationManager, {
            assessmentId,
        });
    }, [redirect, assessmentId]);

    return (
        <FlexRow cx="align-right action-icons" columnGap="12">
            <IconButton
                color="info"
                icon={editIcon}
                onClick={handleEdit}
                dataTestId="assessments/table/row/edit"
            />
        </FlexRow>
    );
};
