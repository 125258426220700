/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { useEffect, useState } from 'react';
import { Button } from 'components/common';
import { showModal } from 'utils';
import { ReactComponent as mailIcon } from '@epam/assets/icons/common/communication-mail-18.svg';
import {
    handleRequestAssessmentRetest,
    hasNonResolvedFindings,
} from 'store/assessment';
import { TRequestAssessmentRetestModalProps } from 'components/modals/RequestAssessmentRetest/types';
import { RequestAssessmentRetestModal } from 'components/modals/RequestAssessmentRetest';
import { Tooltip } from '@epam/promo';
import css from './style.module.scss';

export const RequestRetestButton: React.FC<{ id: string }> = ({ id }) => {
    const [disabled, setDisabled] = useState(true);
    useEffect(() => {
        hasNonResolvedFindings(id).then((has) => setDisabled(!has));
    }, [id, setDisabled]);
    const openRequestRetestModal = () =>
        showModal<TRequestAssessmentRetestModalProps>(
            RequestAssessmentRetestModal,
            {
                dataTestId: 'requestAssessmentRetestModal',
                onSubmit: () => handleRequestAssessmentRetest(id),
            }
        );
    const tooltipContent = disabled ? (
        <span className={css.tooltipContent}>
            All existing findings have been resolved already
        </span>
    ) : undefined;
    return (
        <Tooltip content={tooltipContent} color="white">
            <Button
                fill="outline"
                icon={mailIcon}
                iconPosition="left"
                onClick={openRequestRetestModal}
                caption="Request retest"
                dataTestId="singleAssessment/buttons/requestRetest"
                disabled={disabled}
            />
        </Tooltip>
    );
};
