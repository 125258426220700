/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
class SessionStorage {
    static storage: Storage = sessionStorage;

    static set(key: string, value: string): void {
        this.storage.setItem(key, value);
    }

    static get(key: string): string | null {
        return this.storage.getItem(key);
    }

    static remove(key: string): void {
        this.storage.removeItem(key);
    }
}

export default SessionStorage;
