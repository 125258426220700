/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
export enum FileExtensions {
    pdf = 'pdf',
}

export const IMAGE_DEFAULT_SIZE = {
    width: 300,
    height: 200,
};

export const POSSIBLE_FILE_EXTENSIONS = [
    'doc',
    'docx',
    'xls',
    'xlsx',
    'pdf',
    'png',
    'jpg',
    'jpeg',
    'ppt',
    'pptx',
    'ods',
    'odt',
    'csv',
    'txt',
    'xml',
    'html',
    'json',
    'mov',
    'avi',
    'mp4',
];

export const FILE_INPUT_EXTENSIONS = POSSIBLE_FILE_EXTENSIONS.reduce(
    (accumulator, item) => `${accumulator}.${item}, `,
    ''
);
