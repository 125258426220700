/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Badge } from 'components/common';
/**
 * Constants
 */
import { SEVERITY_CAPTIONS, SEVERITY_COLOR_NAMES } from 'constants/severity';
/**
 * Types
 */
import { Props } from './types';
/**
 * Expo
 */
export const FindingSeverity: React.FC<Props> = ({ severity, dataTestId }) => (
    <Badge
        fill="solid"
        caption={SEVERITY_CAPTIONS[severity]}
        dataTestId={`${dataTestId}/severity`}
        color={SEVERITY_COLOR_NAMES[severity]}
    />
);
