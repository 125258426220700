/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createReducer } from '@reduxjs/toolkit';
/**
 * Actions
 */
import { saveDropdownList, markAsRead, saveList } from './actions';
/**
 * Constants
 */
import { INITIAL_STATE } from './constants';
import { normalizeNotifications } from './utils';
/**
 * Expo
 */
export default createReducer(INITIAL_STATE, {
    [saveDropdownList.type]: (
        state,
        { payload: { notifications, notViewedCount } }
    ) => {
        const { idList, notificationsById } =
            normalizeNotifications(notifications);

        state.notificationsListDropdown.idList = idList;
        state.notificationsListDropdown.itemById = notificationsById;

        state.notViewedCount = notViewedCount;
    },
    [markAsRead.type]: (state, { payload: id }) => {
        state.notificationsListDropdown.itemById[id].is_viewed = true;
        state.notViewedCount -= 1;
    },
    [saveList.type]: (state, { payload: { notifications } }) => {
        const { idList, notificationsById } =
            normalizeNotifications(notifications);

        state.notificationsListPage.idList = idList;
        state.notificationsListPage.itemById = notificationsById;
        state.isInitialized = true;
    },
});
