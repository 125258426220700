/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { IDropdownTogglerProps } from '@epam/uui-core/src/types/props';
/**
 * Dependencies
 */
import React, { useCallback, useEffect } from 'react';
/**
 * Hooks
 */
import { useAppDispatch } from 'hooks';
import { useSelector } from 'react-redux';
/**
 * Components
 */
import { Dropdown } from '@epam/promo';
import { DropdownTarget } from './components/DropdownTarget';
import { List } from './components/List';
/**
 * Store
 */
import { getDropdownList } from 'store/notifications/actions';
import { selectDropdownIdList } from 'store/notifications/selectors';
import { DropdownBodyProps } from '@epam/uui-core';
/**
 * Expo
 */
export const Notifications: React.FC = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getDropdownList());
    }, [dispatch]);

    const idList = useSelector(selectDropdownIdList);

    const renderBody = useCallback(
        (props: DropdownBodyProps) => <List {...props} />,
        []
    );

    const renderTarget = useCallback(
        (props: IDropdownTogglerProps) => <DropdownTarget {...props} />,
        []
    );

    if (!idList.length) {
        return null;
    }

    return (
        <Dropdown
            placement="bottom-end"
            renderBody={renderBody}
            renderTarget={renderTarget}
        />
    );
};
