/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Badge, BadgeProps } from '@epam/promo';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { TPart } from './types';
/**
 * Expo
 */
export const Part: React.FC<TPart> = ({ color, zIndex, width }) => (
    <div
        className={css.chartSection}
        style={{
            zIndex,
            width: `calc(${width}% + 7px)`,
        }}
    >
        <Badge size="18" cx={css.part} color={color as BadgeProps['color']} />
    </div>
);
