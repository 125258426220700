/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import React from 'react';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
/**
 * Utils
 */
import { removeEmptyKeys, generatePath } from 'utils';
/**
 * Types
 */
type TGetRawProps = (props: {
    dataTestId?: string;
    onBlur?: (event: React.SyntheticEvent<HTMLElement>) => void;
}) => {
    id?: string;
    onBlur?: (event: React.SyntheticEvent<HTMLElement>) => void;
};
/**
 * Expo
 */
export const getRawProps: TGetRawProps = ({ dataTestId, onBlur }) =>
    removeEmptyKeys({ id: dataTestId, onBlur });

export const getDeepAddress = (search: string): string => {
    const searchParams = new URLSearchParams(search);
    const { redirectPage, ...params } = Object.fromEntries(searchParams);

    if (!redirectPage) {
        return '';
    }

    return generatePath(PAGES[redirectPage as keyof typeof PAGES], params);
};
