/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { StatusTypes } from 'constants/statuses';
/**
 * Types
 */
import { IInitialState } from './types';
/**
 * Expo
 */
export const INITIAL_STATE: IInitialState = {
    name: '',
    status: undefined,
    inProgress: false,
    isInitialized: false,
    activitiesById: {},
    activitiesInTargets: {},
    activitiesIdByStatus: {
        [StatusTypes.toDo]: [],
        [StatusTypes.inProgress]: [],
        [StatusTypes.done]: [],
    },
};
