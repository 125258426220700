/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { FieldController } from 'components/Fields';
import {
    TFieldControllerProps,
    TPropsWithoutValue,
} from 'components/Fields/FieldController/types';
import { ComponentType, FC, ReactNode } from 'react';
import { FieldPath, FieldValues, useWatch } from 'react-hook-form';
import { RequireExactlyOne, RequireOneOrNone } from 'type-fest';
import { FormMode } from '../../../../../types';

import { useEditableItemCard } from '../../../../EditableItemCardContext';
import { DefaultView } from './DefaultView';
import { extractShapeFromSchema } from './utils';

type ValuesFromContextKeys = 'isRequired' | 'formSchema';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EditableFieldPropsKeys = keyof EditableFieldProps<any, any, any>;

type EditableFieldInternalKeys = ValuesFromContextKeys | EditableFieldPropsKeys;

export type EditableFieldControllerProps<Props> = Omit<
    TFieldControllerProps<Props>,
    EditableFieldInternalKeys | 'component'
> &
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    TPropsWithoutValue<Props, any>;

export type ExtractComponentProps<Component> = Component extends ComponentType<
    infer P
>
    ? P
    : never;

interface ViewOptions<Value> {
    transformValueForView: (value: Value) => ReactNode;
    renderView: (value: Value, label: string) => ReactNode;
}

/**
 * If the value is not a ReactNode, then it is required to provide either `transformValueForView` or `renderView`.
 * Since React doesn't render objects.
 * If the value is a ReactNode, then they are optional.
 *
 * It must take two type parameters because with just one `Value` it cannot infer the type (TS shows as `any`)
 */
export type ViewOptionsConstrained<
    FormValues extends FieldValues,
    Name extends FieldPath<FormValues>
> = FormValues[Name] extends ReactNode
    ? RequireOneOrNone<ViewOptions<FormValues[Name]>>
    : RequireExactlyOne<ViewOptions<FormValues[Name]>>;

export type EditableFieldProps<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ControllerComponent extends FC<any>,
    FormValues extends FieldValues,
    Name extends FieldPath<FormValues> = FieldPath<FormValues>
> = {
    label: string;
    name: Name;
    controllerComponent: ControllerComponent;
    controllerProps: EditableFieldControllerProps<
        ExtractComponentProps<ControllerComponent>
    >;
    dataTestId: string;
} & ViewOptionsConstrained<FormValues, Name>;

export const createEditableField = <FormValues extends FieldValues>() => {
    return function EditableField<
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Component extends FC<any>,
        Name extends FieldPath<FormValues>
    >(props: EditableFieldProps<Component, FormValues, Name>): JSX.Element {
        const {
            name,
            label,
            controllerProps,
            controllerComponent,
            dataTestId,
            ...viewProps
        } = props;
        const {
            state: { mode, formSchema },
        } = useEditableItemCard();

        const shape = extractShapeFromSchema(formSchema);
        const isRequired = !shape[name]?.isOptional();

        const viewValue = useWatch<FormValues>({ name });

        if (mode === FormMode.view) {
            if (viewProps.renderView) {
                return <>{viewProps.renderView(viewValue, label)}</>;
            }
            const transformedViewValue = viewProps.transformValueForView
                ? viewProps.transformValueForView(viewValue)
                : viewValue;

            return (
                <DefaultView
                    labelText={label}
                    value={transformedViewValue}
                    dataTestId={dataTestId}
                />
            );
        }

        return (
            <FieldController
                {...controllerProps}
                name={name}
                component={controllerComponent}
                label={label}
                isRequired={isRequired}
                dataTestId={dataTestId}
            />
        );
    };
};
