/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Expo
 */
export enum EntityTypes {
    link = 'LINK',
    image = 'IMAGE',
}

export enum BlockTypes {
    atomic = 'atomic',
}

export enum BlockTypeByTagName {
    h1 = 'header-one',
    h2 = 'header-two',
    h3 = 'header-three',
    h4 = 'header-four',
    h5 = 'header-five',
    h6 = 'header-six',
    ul = 'unordered-list-item',
    ol = 'ordered-list-item',
    p = 'paragraph',
    blockquote = 'blockquote',
    pre = 'code-block',
    figure = 'atomic',
    div = 'unstyled',
}

export enum MutabilityTypes {
    mutable = 'MUTABLE',
    immutable = 'IMMUTABLE',
}

export enum StyleAlignments {
    left = 'ALIGN_LEFT',
    right = 'ALIGN_RIGHT',
    center = 'ALIGN_CENTER',
}

export enum StyleBackgroundHighLights {
    BACKGROUND_HIGHLIGHTS_YELLOW = 'BACKGROUND_HIGHLIGHTS_YELLOW',
}

export enum NodeAlignments {
    left = 'left',
    right = 'right',
    center = 'center',
}

export enum NodeNames {
    link = 'a',
    image = 'img',
    span = 'span',
    li = 'li',
}

export enum BlockNodeNames {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    h5 = 'h5',
    h6 = 'h6',
    p = 'p',
    blockquote = 'blockquote',
    pre = 'pre',
    figure = 'figure',
    div = 'div',
}

export enum HandleValue {
    handled = 'handled',
    notHandled = 'not-handled',
}
