/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
/**
 * Constants
 */
import { browserIsLoaded } from 'constants/system';
import { isGADisabled } from 'constants/googleAnalytics';
/**
 * Utils
 */
import { makeIsInitializedSelector } from 'utils/store';
import { trackInitialLoadingTiming, trackRedirectionTiming } from './utils';
/**
 * Types
 */
import { TUsePageLoadingPerformanceProps } from './types';
/**
 * Expo
 */
export const usePageLoadingPerformance = (
    props: TUsePageLoadingPerformanceProps = {
        reducerNames: [],
        waitForApi: false,
    }
): void => {
    const {
        waitForApi,
        reducerNames,
        withLocalFlag,
        isInitialized: isLocalInitialized,
    } = props;

    const isStoreInitialized = useSelector(
        makeIsInitializedSelector(...reducerNames)
    );

    const isInitialized = useMemo(() => {
        switch (true) {
            case withLocalFlag && waitForApi:
                return isLocalInitialized && isStoreInitialized;
            case withLocalFlag:
                return isLocalInitialized;
            case waitForApi:
                return isStoreInitialized;
            default:
                return true;
        }
    }, [waitForApi, withLocalFlag, isStoreInitialized, isLocalInitialized]);

    useEffect(() => {
        if (!isInitialized || isGADisabled) {
            return;
        }

        const gaClientId =
            window.gaGlobal?.vid ??
            document.cookie.match(/_ga=GA[0-9]\.[0-9]\.([0-9]*\.[0-9]*);/)?.[1];

        if (!gaClientId) {
            return;
        }

        if (!window[browserIsLoaded]) {
            trackInitialLoadingTiming();
            window[browserIsLoaded] = true;
        } else {
            trackRedirectionTiming();
        }
    }, [isInitialized]);
};
