/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { forwardRef, Ref } from 'react';
/**
 * Components
 */
import { IconContainer, Text, UserAvatar, FlexRow } from 'components/common';
/**
 * Assets
 */
import css from './style.module.scss';
import { ReactComponent as foldingArrow } from '@epam/assets/icons/common/navigation-chevron-down-18.svg';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
/**
 * Constants
 */
import { ROLE_TITLES } from 'constants/roles';
/**
 * Types
 */
import { IDropdownToggler } from '@epam/uui-core';
/**
 * Store
 */
import { selectUserNameAndRole } from 'store/user';
/**
 * Expo
 */
export const DropdownTarget: React.FC<IDropdownToggler> = forwardRef(
    ({ isOpen, ...props }, ref: Ref<unknown>) => {
        const { name: userName, role } = useSelector(selectUserNameAndRole);

        return (
            <FlexRow
                {...props}
                ref={ref}
                columnGap="6"
                cx={css.dropdownTarget}
                dataTestId="header/userMenu"
            >
                <UserAvatar
                    name={userName}
                    dataTestId="header/userMenu/avatar"
                />
                <div>
                    <Text
                        cx={css.text}
                        color="gray5"
                        dataTestId="header/userMenu/userName"
                    >
                        {userName}
                    </Text>
                    <Text
                        cx={css.text}
                        color="gray5"
                        dataTestId="header/userMenu/role"
                    >
                        {role && ROLE_TITLES[role]}
                    </Text>
                </div>
                <IconContainer
                    flipY={isOpen}
                    style={{ fill: 'white' }}
                    icon={foldingArrow}
                    dataTestId="header/userMenu/icon"
                />
            </FlexRow>
        );
    }
);
