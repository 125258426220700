/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React from 'react';
import { PanelBlock, PanelsContainer } from 'components/common';
import { DfdCard } from 'pages/ThreatModeling/components/DfdCard';
import { ActivityComments } from 'components/ActivityComments';
import { ActivityInfo } from 'pages/SingleActivity/components/ActivityInfo';
import { ThreatModelingActivityInfoTabProps } from 'pages/ThreatModeling/tabs/ActivityInfoTab/types';
import { ActivityRecentActions } from 'components';

export const ActivityInfoTab: React.FC<ThreatModelingActivityInfoTabProps> = ({
    dfdMetadata,
    activity,
    threatModelingActivityId,
    dataTestId,
}) => (
    <PanelsContainer>
        <PanelsContainer direction="vertical" size={30}>
            <DfdCard
                document={dfdMetadata}
                dataTestId={`${dataTestId}/diagramCard`}
            />
            {threatModelingActivityId && (
                <PanelBlock>
                    <ActivityComments activityId={threatModelingActivityId} />
                </PanelBlock>
            )}
        </PanelsContainer>
        <PanelsContainer direction="vertical" size={30}>
            {activity && (
                <>
                    <ActivityInfo activity={activity} />
                    <ActivityRecentActions
                        dataTestId={dataTestId}
                        activityId={threatModelingActivityId}
                        activityName={activity.name}
                    />
                </>
            )}
        </PanelsContainer>
    </PanelsContainer>
);
