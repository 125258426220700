/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useState } from 'react';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
import { useAppDispatch, useRole, useRouteParams } from 'hooks';
/**
 * Components
 */
import { Text } from '@epam/promo';
import { Modal } from './components/Modal';
import { LinkButton, FlexRow } from 'components/common';
import { AvatarStack } from 'components/common/AvatarStack';
/**
 * Store
 */
import { selectProjectAdditionalInfo } from 'store/project';
import { handleChangeActivityAssignee } from 'store/assessment';
import { handleGetList } from 'store/assessments';
/**
 * Types
 */
import { TPerson } from 'types';
import { AssigneeProps, TModalAssigneeProps } from './types';
/**
 * Assets
 */
import css from './style.module.scss';
import { ReactComponent as addIcon } from '@epam/assets/icons/common/action-add-18.svg';
/**
 * Utils
 */
import { showModal } from 'utils';
/**
 * Expo
 */
export const SelectAssignee: React.FC<AssigneeProps> = ({
    value = [],
    isDisabled,
    id: activityId,
}) => {
    const [chosenList, changeChosenList] = useState<TPerson[]>(value);

    const { securitySpecialists } = useSelector(selectProjectAdditionalInfo);

    const { isProjectRepresentative } = useRole();

    const { projectId } = useRouteParams();

    const dispatch = useAppDispatch();

    const handleChangeAssignee = useCallback(
        async (assigneeList: TPerson[]) => {
            await dispatch(
                handleChangeActivityAssignee(activityId, assigneeList)
            );
        },
        [activityId, dispatch]
    );

    const handleSubmit = useCallback(
        async (changedValue: TPerson[] = []) => {
            if (isDisabled) return;

            if (!changedValue.length && !chosenList.length) return;

            await handleChangeAssignee(changedValue);
            changeChosenList(changedValue);

            dispatch(handleGetList(projectId));
        },
        [
            chosenList.length,
            isDisabled,
            handleChangeAssignee,
            dispatch,
            projectId,
        ]
    );

    const handleShowModal = useCallback(() => {
        showModal<TModalAssigneeProps>(Modal, {
            onSubmit: handleSubmit,
            initialValue: chosenList,
            options: securitySpecialists,
        });
    }, [handleSubmit, chosenList, securitySpecialists]);

    if (isProjectRepresentative && !chosenList.length) {
        return (
            <Text fontSize="12" cx={css.emptyListText}>
                performer is not assigned yet
            </Text>
        );
    }

    return (
        <FlexRow
            cx={css.assigneeContainer}
            dataTestId="singleAssessment/boardColumn/card/assignee"
        >
            <AvatarStack
                size="24"
                list={chosenList}
                dataTestId="singleAssessment/boardColumn/card/assignee/item"
            />
            {!isProjectRepresentative && (
                <LinkButton
                    color="primary"
                    icon={addIcon}
                    isDisabled={isDisabled}
                    onClick={handleShowModal}
                    caption={chosenList.length ? '' : 'Assign performer'}
                    dataTestId="singleAssessment/boardColumn/card/assignee/change"
                />
            )}
        </FlexRow>
    );
};
