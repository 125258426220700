/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
import { createPortal } from 'react-dom';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { IPopoverProps } from './types';
/**
 * Expo
 */

export const Popover = ({
    data,
    renderTooltip,
    mouseCoordinates,
}: IPopoverProps): JSX.Element => {
    const tooltipContent = (
        <div
            className={css.wrapper}
            style={{
                transform: `translate(${mouseCoordinates.x}px, ${mouseCoordinates.y}px)`,
            }}
        >
            <div className={css.container}>
                <div>{renderTooltip(data)}</div>
                <div className={css.arrow} />
            </div>
        </div>
    );

    // Need to render this Popover by portal as @nivo renders tooltip near the Chart
    // Because of this approach Popover may be rendered "under" some blocks as a lot of UUI's blocks have "overflow: hidden"
    return createPortal(tooltipContent, document.body);
};
