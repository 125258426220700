/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import css from './style.module.scss';
import { Text } from 'components/common';
import { cx } from '@epam/uui-core';
import { WithDataTestId } from 'types';

export const EmptyItem: React.FC<WithDataTestId & { message: string }> = ({
    message,
    dataTestId,
}) => (
    <li className={cx(css.treeItem, css.treeItemEmpty)}>
        <Text
            cx={css.treeItemEmptyContent}
            dataTestId={`${dataTestId}/emptyMessage`}
        >
            {message}
        </Text>
    </li>
);
