/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { FlexSpacer } from '@epam/uui-components';
import { Badge, BadgeProps, FlexRow, Text } from '@epam/promo';
/**
 * Constants
 */
import { CAPTIONS, COLORS } from './constants';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { TStatusList } from './types';
/**
 * Utils
 */
import { getTotalAmountText } from './utils';
/**
 * Expo
 */
export const renderPopoverMarkup = (
    title: string,
    statusList: TStatusList
): JSX.Element => (
    <div style={{ width: 200 }}>
        <Text fontSize="14" lineHeight="18" size="30" color="gray80">
            {title}
        </Text>
        <Text fontSize="14" lineHeight="18" size="18" color="gray80">
            {getTotalAmountText(statusList)}
        </Text>
        <div className={css.popoverBody}>
            {statusList.map(({ status, amount }) => (
                <FlexRow size="24" key={status + amount}>
                    <Text
                        color="gray60"
                        fontSize="14"
                        lineHeight="18"
                        size="18"
                        fontWeight="400"
                        cx={css.popoverActivityText}
                    >
                        {CAPTIONS[status]}
                    </Text>
                    <FlexSpacer />
                    <Badge
                        size="18"
                        cx={css.badge}
                        caption={amount}
                        color={COLORS[status] as BadgeProps['color']}
                    />
                </FlexRow>
            ))}
        </div>
    </div>
);
