/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import {
    DOMConversionMap,
    DOMExportOutput,
    LexicalEditor,
    LexicalNode,
    ParagraphNode,
    SerializedParagraphNode,
    $applyNodeReplacement,
    DOMConversionOutput,
} from 'lexical';

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { removeStyleAndSetDataAttribute } from '../helpers';
import { Alignment } from '../../enums';

export function hasAlignmentCss(element: Element): element is HTMLElement {
    return element instanceof HTMLElement && Boolean(element.style.textAlign);
}

export function exportAlignment(element: Element): void {
    if (hasAlignmentCss(element))
        removeStyleAndSetDataAttribute(element, {
            name: Alignment.AttributeName,
            value: element.style.textAlign,
        });
}

export class ExtendedParagraphNode extends ParagraphNode {
    static override importJSON(
        serializedNode: SerializedParagraphNode
    ): ExtendedParagraphNode {
        return ParagraphNode.importJSON(serializedNode);
    }

    static override getType(): string {
        return 'extended-paragraph';
    }

    static override clone(node: ExtendedParagraphNode): ExtendedParagraphNode {
        // eslint-disable-next-line no-underscore-dangle
        return new ExtendedParagraphNode(node.__key);
    }

    static override importDOM(): DOMConversionMap | null {
        ParagraphNode.importDOM();
        return {
            p: () => ({
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                conversion: importWithAlignment,
                priority: 1,
            }),
        };
    }

    override exportDOM(editor: LexicalEditor): DOMExportOutput {
        const dom = super.exportDOM(editor);
        const { element } = dom;
        if (element) exportAlignment(element);

        return dom;
    }

    override exportJSON(): SerializedParagraphNode {
        return {
            ...super.exportJSON(),
            type: 'extended-paragraph',
        };
    }
}

export function $isExtendedParagraphNode(
    node: LexicalNode | null | undefined
): node is ExtendedParagraphNode {
    return node instanceof ExtendedParagraphNode;
}

export function $createExtendedParagraphNode(): ExtendedParagraphNode {
    return $applyNodeReplacement(new ExtendedParagraphNode());
}

export function getAlignmentDataAttribute(element: HTMLElement) {
    return element.dataset.alignment;
}

export function importWithAlignment(
    domNode: HTMLElement
): null | DOMConversionOutput {
    const alignment = getAlignmentDataAttribute(domNode);
    const node = $createExtendedParagraphNode();
    node.setFormat(alignment as 'right');

    return { node };
}
