/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { FlexCell, FlexRow } from 'components/common';
import { FC } from 'react';
import css from './style.module.scss';
import { WithRequiredChildren, WithDataTestId } from 'types';

export const HalfFieldRow: FC<WithDataTestId & WithRequiredChildren> = ({
    children,
    dataTestId,
}) => (
    <FlexRow>
        <FlexCell dataTestId={dataTestId} cx={css.halfFieldRow}>
            {children}
        </FlexCell>
    </FlexRow>
);
