/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
/**
 * Components
 */
import { PrivateRoute } from 'navigation/PrivateRoute';
import { PrivatePageContainer } from 'components/PageContainer/PrivatePageContainer';
/**
 * Constants
 */
import { RoleTypes } from 'constants/roles';
import { PlatformRoutes } from 'constants/system';
import { PAGES } from 'constants/pages';
/**
 * Pages
 */
import { AgileSecurityPlatform } from './Routes/AgileSecurityPlatform';
import { SecurityDeliveryConsole } from './Routes/SecurityDeliveryConsole';
import { FilesPreview } from './Routes/FilesPreview';
import { PublicPageContainer } from 'components/PageContainer/PublicPageContainer';

const Login = lazy(() => import('pages/Login'));
const About = lazy(() => import('pages/About'));
const Landing = lazy(() => import('pages/Landing'));
const BlockedUser = lazy(() => import('pages/BlockedUserPage'));
const UnknownUser = lazy(() => import('pages/UnknownUserPage'));
/**
 * Expo
 */
const PrivateNavigation: React.FC = () => (
    <Suspense fallback={null}>
        <Routes>
            <Route element={<PrivatePageContainer />}>
                <Route
                    path={`${PlatformRoutes.files}/:id`}
                    element={
                        <PrivateRoute
                            component={FilesPreview}
                            roles={[
                                RoleTypes.projectRepresentative,
                                RoleTypes.securitySpecialist,
                            ]}
                        />
                    }
                />
                <Route
                    path={`${PlatformRoutes.agileSecurityPlatform}/*`}
                    element={
                        <PrivateRoute
                            component={AgileSecurityPlatform}
                            roles={[
                                RoleTypes.admin,
                                RoleTypes.operationsManager,
                                RoleTypes.securitySpecialist,
                                RoleTypes.projectRepresentative,
                            ]}
                        />
                    }
                />
                <Route
                    path={`${PlatformRoutes.securityDeliveryConsole}/*`}
                    element={
                        <PrivateRoute
                            component={SecurityDeliveryConsole}
                            roles={[
                                RoleTypes.salesManager,
                                RoleTypes.projectRepresentative,
                                RoleTypes.operationsManager,
                            ]}
                        />
                    }
                />
            </Route>
            <Route path="*" element={<Navigate to={PAGES.home} replace />} />
        </Routes>
    </Suspense>
);

export const Navigation: React.FC = () => {
    return (
        <Suspense fallback={null}>
            <Routes>
                <Route path={PAGES.blockedUser} element={<BlockedUser />} />
                <Route path={PAGES.unknownUser} element={<UnknownUser />} />
                <Route element={<PublicPageContainer />}>
                    <Route path={PAGES.home} element={<Landing />} />
                    <Route path={PAGES.login} element={<Login />} />
                    <Route path={PAGES.about} element={<About />} />
                </Route>
                <Route path="*" element={<PrivateNavigation />} />
            </Routes>
        </Suspense>
    );
};
