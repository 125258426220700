/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
/**
 * Hooks
 */
import { useRole } from 'hooks';
import { useSelector } from 'react-redux';
/**
 * Components
 */
import { FlexSpacer } from '@epam/promo';
import { UserMenu } from 'components/UserMenu';
import { useSecurityHeader } from '../SecurityHeader';
import { Notifications } from './components/Notifications';
import { AdaptiveItemProps } from '@epam/uui-components';
import { ActionLogButton } from './components/ActionLogButton';
import { HelpGuideButton } from './components/HelpGuideButton';
import { PlatformSwitcher } from './components/PlatformSwitcher';
/**
 * Store
 */
import { selectFlag } from 'store/featureFlags';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
import { FEATURE_FLAGS } from 'constants/featureFlags';
import { projectHeader } from '../ProjectHeader';
import { usePagesHeader } from '../PagesHeader';
import { adaptiveItemsBuilder } from '../../../../utils/adaptiveItems';
import { NotificationSettingsButton } from 'components/Header/components/HeaderContent/components/NotififcationSettingsButton';
/**
 * Expo
 */
export const useHeaderContent = (): Array<AdaptiveItemProps> => {
    const { pathname } = useLocation();
    const {
        isSecuritySpecialist,
        isOperationsManager,
        isProjectRepresentative,
        isAdmin,
    } = useRole();

    const helpGuideFeatureFlag = useSelector(
        selectFlag(FEATURE_FLAGS.helpGuide)
    );
    const notificationsFeatureFlag = useSelector(
        selectFlag(FEATURE_FLAGS.notifications)
    );

    const hidePlatformSwitcherProjectRepresentativeFlag = useSelector(
        selectFlag(FEATURE_FLAGS.hidePlatformSwitcherProjectRepresentative)
    );

    const isProjectPage = pathname.includes('/project/');
    const isOrganizationPage = pathname.includes('/organization/');
    const isSecurityDeliveryConsolePage = pathname.includes(
        PAGES.securityCenter
    );

    const canSeePlatformSwitcher =
        !isProjectRepresentative ||
        !hidePlatformSwitcherProjectRepresentativeFlag;

    const canSeeHelpGuide = helpGuideFeatureFlag && isProjectRepresentative;
    const canSeeNotifications =
        notificationsFeatureFlag &&
        !isSecurityDeliveryConsolePage &&
        (isSecuritySpecialist ||
            isProjectRepresentative ||
            isOperationsManager);

    const projectItems = projectHeader();
    const securityItems = useSecurityHeader();
    const pagesItems = usePagesHeader();
    const rightSideItems = useMemo(
        () =>
            adaptiveItemsBuilder()
                .add(
                    canSeeHelpGuide && {
                        id: 'help-guide',
                        priority: 1,
                        render: () => <HelpGuideButton />,
                    }
                )
                .add(
                    (isOperationsManager || isAdmin) && {
                        id: 'action-log',
                        priority: 1,
                        render: () => <ActionLogButton />,
                    }
                )
                .add(
                    canSeeNotifications && {
                        id: 'notifications',
                        priority: 1,
                        render: () => <Notifications />,
                    }
                )
                .add({
                    id: 'notification-settings',
                    priority: 1,
                    render: () => <NotificationSettingsButton />,
                })
                .add({
                    id: 'user-menu',
                    priority: 1,
                    render: () => <UserMenu />,
                })
                .add(
                    canSeePlatformSwitcher && {
                        id: 'platform-switcher',
                        priority: 1,
                        render: () => <PlatformSwitcher />,
                    }
                )
                .build(),
        [
            canSeeHelpGuide,
            canSeeNotifications,
            canSeePlatformSwitcher,
            isAdmin,
            isOperationsManager,
        ]
    );

    const headerItems = useMemo(() => {
        switch (true) {
            case isProjectPage:
            case isOrganizationPage:
                return projectItems;
            case isSecurityDeliveryConsolePage:
                return securityItems;
            default:
                return pagesItems;
        }
    }, [
        isProjectPage,
        isOrganizationPage,
        projectItems,
        isSecurityDeliveryConsolePage,
        securityItems,
        pagesItems,
    ]);

    return [
        ...headerItems,
        { id: 'spacer', priority: 100500, render: () => <FlexSpacer /> },
        ...rightSideItems,
    ];
};
