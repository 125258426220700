/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { forwardRef, memo, useMemo } from 'react';
/**
 * Components
 */
import { TabButton } from 'components/common';
/**
 * Utils
 */
import { getRawProps } from 'utils';
/**
 * Types
 */
import { TabProps } from '../types';
/**
 * Expo
 */
export const SingleTab: React.FC<TabProps> = memo(
    forwardRef(({ title, href, dataTestId, isActive, ...props }, ref) => {
        const rawProps = useMemo(
            () => getRawProps({ dataTestId: `${dataTestId}/item` }),
            [dataTestId]
        );

        return (
            <TabButton
                {...props}
                size="48"
                ref={ref}
                href={href}
                caption={title}
                rawProps={rawProps}
                isLinkActive={isActive}
            />
        );
    })
);
