/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createSelector, createSlice } from '@reduxjs/toolkit';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
import { RoleTypes } from 'constants/roles';
import { IntegrationTypes } from 'constants/project';
import { INITIAL_STATE, INTEGRATIONS_BY_TYPE } from './constants';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Types
 */
import { TProjectData } from './types';
import {
    AppDispatch,
    PaginatedResponse,
    RootState,
    TAssessment,
    TAttachment,
    TDocument,
    TGetState,
    TPerson,
    TProject,
    TSelector,
    TShortPolicy,
} from 'types';
/**
 * Utils
 */
import { createQueryUrl, templateString } from 'utils';
import { getIntegrationsByType } from './utils';

/**
 * Reducer
 */
const { reducer, actions } = createSlice({
    name: 'project',
    initialState: INITIAL_STATE,
    reducers: {
        saveProjectData: (
            state,
            {
                payload: {
                    assessment,
                    organization_id,
                    configuration_type,
                    security_specialists,
                    project_representatives,
                    project_operations_manager,
                    assessment_id,
                    threat_modeling_activity_id,
                    ...payload
                },
            }
        ) => {
            state.data = {
                ...payload,
                type: configuration_type,
                currentAssessmentId: assessment?.id,
                currentAssessmentType: assessment?.type,
                securitySpecialists: security_specialists,
                projectRepresentatives: project_representatives,
                operationsManager: project_operations_manager,
                threatModelingActivityId: threat_modeling_activity_id,
            };
            state.isInitialized = true;
        },
        saveAttachments: (state, { payload }) => {
            state.attachments = payload;
        },
        saveAssessmentHistory: (state, { payload }) => {
            state.assessmentHistory = payload;
        },
        savePolicies: (state, { payload }) => {
            state.policies = payload;
        },
        changeDescription: (state, { payload }) => {
            state.data.description = payload;
        },
        removeIntegration: (
            state,
            { payload }: { payload: IntegrationTypes }
        ) => {
            delete state.data[INTEGRATIONS_BY_TYPE[payload]];
        },
        removeSlackIntegration: (state) => {
            delete state.data.slack;
        },
        setInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        resetProjectData: () => INITIAL_STATE,
    },
});

export default reducer;
/**
 * Selectors
 */
export const selectProjectData: TSelector<TProjectData> = ({
    project: { data },
}) => data;

export const selectCurrentProjectName = createSelector(
    selectProjectData,
    ({ name }) => name
);

export const selectCurrentProjectId = createSelector(
    selectProjectData,
    ({ id }) => id
);

export const selectProjectAdditionalInfo = createSelector(
    selectProjectData,
    ({ operationsManager, securitySpecialists, projectRepresentatives }) => ({
        operationsManager,
        securitySpecialists,
        projectRepresentatives,
    })
);

export const selectProjectType = createSelector(
    selectProjectData,
    ({ type }) => type
);

export const selectCurrentAssessmentId = createSelector(
    selectProjectData,
    ({ currentAssessmentId }) => currentAssessmentId
);

export const selectCurrentAssessmentType = createSelector(
    selectProjectData,
    ({ currentAssessmentType }) => currentAssessmentType
);

export const selectIsIntegratedWithJira = createSelector(
    selectProjectData,
    ({ jira }) => Boolean(jira)
);

export const selectIsIntegratedWithAzure = createSelector(
    selectProjectData,
    ({ azure }) => Boolean(azure)
);

export const selectIsIntegratedWithSonarQube = createSelector(
    selectProjectData,
    ({ sonarQube }) => Boolean(sonarQube)
);

export const selectIntegrationId = createSelector(
    [
        selectProjectData,
        (_: RootState, integration: IntegrationTypes) => integration,
    ],
    (projectData, integration: IntegrationTypes) =>
        projectData[INTEGRATIONS_BY_TYPE[integration]]?.id
);

export const selectAttachments: TSelector<TDocument[]> = ({
    project: { attachments },
}) => attachments;
export const selectAssessmentHistory: TSelector<TAssessment[]> = ({
    project: { assessmentHistory },
}) => assessmentHistory;
export const selectPolicy: TSelector<TShortPolicy[]> = ({
    project: { policies },
}) => policies;
export const selectInProgress: TSelector<boolean> = ({
    project: { inProgress },
}) => inProgress;

export const selectProjectIsInitialized: TSelector<boolean> = ({
    project: { isInitialized },
}) => isInitialized;

export const selectThreatModelingActivityId: TSelector<string | null> = ({
    project: {
        data: { threatModelingActivityId },
    },
}) => threatModelingActivityId;
/**
 * Actions
 */
export const {
    setInProgress,
    saveProjectData,
    saveAttachments,
    resetProjectData,
    changeDescription,
    removeIntegration,
    removeSlackIntegration,
    saveAssessmentHistory,
    savePolicies,
} = actions;
/**
 * Dispatchers
 */
const getProjectData =
    (id: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const projectUrl = templateString(ENDPOINTS.project, { id });

        const { assignees, integrations, ...response } =
            await api.request<TProject>(Methods.get, {
                url: projectUrl,
            });

        const { jira, slack, azure, sonarQube, teams } =
            getIntegrationsByType(integrations);

        dispatch(
            saveProjectData({
                ...response,
                jira,
                slack,
                azure,
                sonarQube,
                teams,
            })
        );
    };

export const handleGetAssessmentHistory =
    (projectId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = templateString(ENDPOINTS.projectAssessments, { projectId });

        const response = await api.request<TAssessment[]>(Methods.get, { url });

        dispatch(saveAssessmentHistory(response));
    };

export const handleGetAttachments =
    (projectId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = templateString(ENDPOINTS.projectAttachments, {
            projectId,
        });
        const queryUrl = createQueryUrl(url, {
            limit: 2,
        });

        const response = await api.request(Methods.get, { url: queryUrl });

        dispatch(saveAttachments(response));
    };
export const handleGetPolicies =
    (projectId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const queryUrl = createQueryUrl(ENDPOINTS.policiesByProject, {
            projectId,
            limit: 2,
        });

        const { data } = await api.request<PaginatedResponse<TShortPolicy[]>>(
            Methods.get,
            { url: queryUrl }
        );

        dispatch(savePolicies(data));
    };

export const handleGetProjectData =
    (id: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setInProgress(true));

        await Promise.all([
            dispatch(getProjectData(id)),
            dispatch(handleGetAttachments(id)),
            dispatch(handleGetAssessmentHistory(id)),
        ]);

        dispatch(setInProgress(false));
    };

export const handleGetProjectDataOnUpdate =
    (id: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setInProgress(true));

        await dispatch(getProjectData(id));

        dispatch(setInProgress(false));
    };

export const handleChangeDescription =
    (description: string) =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const id = selectCurrentProjectId(getState());

        const url = templateString(ENDPOINTS.projectDescription, {
            projectId: id,
        });

        await api.request(Methods.post, { url, data: { description } });

        dispatch(changeDescription(description));
    };

export const handleRemoveTrackerIntegration =
    (integrationId: string, integrationType: IntegrationTypes) =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const id = selectCurrentProjectId(getState());

        const url = templateString(ENDPOINTS.projectTracker, {
            projectId: id,
        });

        await api.request(Methods.delete, { url });

        dispatch(removeIntegration(integrationType));
    };

export const handleRemoveMessengerIntegration =
    (integrationId: string, integrationType: IntegrationTypes) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = templateString(ENDPOINTS.singleMessenger, {
            messengerId: integrationId,
        });

        await api.request(Methods.delete, { url });

        dispatch(removeIntegration(integrationType));
    };

export const handleAddAttachments =
    (attachments: TAttachment[]) =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const id = selectCurrentProjectId(getState());

        const url = templateString(ENDPOINTS.projectAttachments, {
            projectId: id,
        });

        const formData = new FormData();

        attachments.forEach(({ file }: TAttachment) => {
            formData.append('files', file, file.name);
        });

        await api.request(Methods.post, { url, data: formData });
    };

export const handleRemoveAttachment =
    (attachmentId: string) =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const id = selectCurrentProjectId(getState());

        const url = templateString(ENDPOINTS.singleProjectAttachment, {
            projectId: id,
            attachmentId,
        });

        await api.request(Methods.delete, { url });
    };

export const handleChangeAssignee =
    (assignees?: TPerson[], role?: RoleTypes) =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const projectId = selectCurrentProjectId(getState());

        const url = templateString(ENDPOINTS.projectsAssignees, {
            projectId,
        });

        try {
            dispatch(setInProgress(true));

            await api.request(Methods.put, {
                url,
                data: {
                    project_assignees: assignees?.map(({ id }) => ({
                        id,
                        role,
                    })),
                },
            });

            await dispatch(getProjectData(projectId));
        } finally {
            dispatch(setInProgress(false));
        }
    };

export const handleChangeProjectRepresentative =
    (assignees: TPerson[]) =>
    async (dispatch: AppDispatch): Promise<void> => {
        await dispatch(
            handleChangeAssignee(assignees, RoleTypes.projectRepresentative)
        );
    };

export const handleChangeSecuritySpecialists =
    (assignees: TPerson[]) =>
    async (dispatch: AppDispatch): Promise<void> => {
        await dispatch(
            handleChangeAssignee(assignees, RoleTypes.securitySpecialist)
        );
    };

export const updateAttachments =
    () =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const { id } = getState().project.data;

        dispatch(setInProgress(true));

        try {
            await dispatch(handleGetAttachments(id));
        } finally {
            dispatch(setInProgress(false));
        }
    };
