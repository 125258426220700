/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { BadgeProps } from '@epam/promo';
/**
 * Constants
 */
import { ColorNames } from 'constants/colors';
import { StatusCaptions, StatusTypes } from 'constants/statuses';

/**
 * Expo
 */
export const CAPTIONS = {
    [StatusTypes.opened]: StatusCaptions.opened,
    [StatusTypes.unresolved]: StatusCaptions.unresolved,
    [StatusTypes.archived]: StatusCaptions.archived,
    [StatusTypes.readyToRetest]: StatusCaptions.readyToRetest,
    [StatusTypes.resolved]: StatusCaptions.resolved,
    [StatusTypes.retest]: StatusCaptions.retest,
};

// TODO: refactor, at least extract and reuse types
export const COLORS: Record<keyof typeof CAPTIONS, BadgeProps['color']> = {
    [StatusTypes.opened]: ColorNames.blue,
    [StatusTypes.unresolved]: ColorNames.red,
    [StatusTypes.readyToRetest]: ColorNames.amber,
    [StatusTypes.retest]: ColorNames.purple,
    [StatusTypes.resolved]: ColorNames.green,
    [StatusTypes.archived]: ColorNames.cyan,
};
