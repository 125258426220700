/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import React, { FC, useCallback, useContext, useState } from 'react';
import { FlexRow, FlexSpacer } from '@epam/promo';
import { FileIcon } from 'components/common/FileIcon';
import { Blocker, IconContainer, Panel, Text } from 'components/common/index';
import {
    downloadFile,
    templateString,
    getSizeToMegabytes,
    getExtensionFromFileName,
} from 'utils';
import { Methods } from 'constants/request';
import { api } from 'services/api';
import css from './style.module.scss';
import { ReactComponent as downloadIcon } from '@epam/assets/icons/common/file-download-18.svg';
import { AttachmentsContext } from './context';
import { TDocument } from 'types';

export type TAttachedFileProps = {
    file: TDocument;
};

export const AttachedFile: FC<TAttachedFileProps> = ({
    file: { id, name, size_bytes },
}) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const { downloadEndpoint } = useContext(AttachmentsContext);

    const extension = getExtensionFromFileName(name).toLowerCase();

    const handleDownloadFile = useCallback(async () => {
        if (!downloadEndpoint) return;

        const url = templateString(downloadEndpoint, {
            attachmentId: id,
        });

        setIsDownloading(true);

        try {
            const blobFile: Blob = await api.request(Methods.get, {
                url,
                responseType: 'blob',
                headers: { 'Content-type': 'application/doc' },
            });

            downloadFile(blobFile, name);
        } finally {
            setIsDownloading(false);
        }
    }, [id, name, downloadEndpoint]);

    return (
        <Panel cx={css.attachedFile}>
            <Blocker isEnabled={isDownloading} hideSpinner />
            <FlexRow columnGap="6">
                <FileIcon extension={extension} />
                <Text>
                    {name} ({getSizeToMegabytes(size_bytes)}MB)
                </Text>
                <FlexSpacer />
                <IconContainer
                    icon={downloadIcon}
                    style={{ fill: 'var(--uui-link)' }}
                    onClick={handleDownloadFile}
                />
            </FlexRow>
        </Panel>
    );
};
