/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import {
    FlexRow,
    FlexCell,
    ArraySelect,
    IconContainer,
    IconButton,
    LinkButton,
    Text,
} from 'components/common';
import { ArraySelectProps } from 'components/common/Select/types';
import { FieldController } from 'components/Fields';
import { useAppSelector } from 'hooks';
import { DefaultView } from './EditableField/DefaultView';
import { useEditableItemCard } from '../../../EditableItemCardContext';
import { extractShapeFromSchema } from './EditableField/utils';
import css from './style.module.scss';
import { ActionOnAsset, ZodAssetFootprintArray } from '../constants';
// TODO: fix import after full migration, including technology
import { FormMode } from '../../../../types';
import { FC, useCallback, useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { selectAssets } from 'store/threatModeling/asset/selectors';
import { Asset, TNamed } from 'types';
import { ReactComponent as addIcon } from '@epam/assets/icons/common/action-add-18.svg';
import { ReactComponent as deleteIcon } from '@epam/assets/icons/common/action-delete-18.svg';
import { ReactComponent as arrowIcon } from '@epam/assets/icons/common/navigation-back-18.svg';

const FIELD_NAME = 'assetFootprints';

const getActionOnAssetLabel = (action: ActionOnAsset) =>
    Object.keys(ActionOnAsset)[Object.values(ActionOnAsset).indexOf(action)];

const actionOptions = Object.entries(ActionOnAsset).map(([key, value]) => {
    return {
        id: value,
        name: key,
    };
});

// TODO: fix paddings after full migration to make the same as in the design and other fields
export const AssetFootprints: FC = () => {
    const { control } = useFormContext();
    const { fields, remove, append } = useFieldArray({
        control,
        name: 'assetFootprints',
    });

    const {
        state: { mode, formSchema },
    } = useEditableItemCard();

    const appendField = useCallback(() => {
        append({ id: null, asset: null, actionOnAsset: null });
    }, [append]);

    const assetOptions = useAppSelector(selectAssets);
    const shape = extractShapeFromSchema(formSchema);
    const isRequired = !shape[FIELD_NAME]?.isOptional();

    useEffect(() => {
        if (isRequired && fields.length === 0 && mode !== FormMode.view)
            appendField();
    }, [appendField, fields, mode, isRequired]);

    const assetFootprints = useWatch({
        name: FIELD_NAME,
    }) as ZodAssetFootprintArray;

    if (mode === FormMode.view) {
        return (
            <DefaultView
                labelText="Asset footprints"
                value={assetFootprints.map((item) =>
                    item.asset?.name && item.actionOnAsset
                        ? `${item.asset?.name} (${getActionOnAssetLabel(
                              item.actionOnAsset
                          )})`
                        : '–'
                )}
                dataTestId={FIELD_NAME}
            />
        );
    }

    return (
        // TODO: add red asterisk when isRequired, now it's not required, though
        <div>
            <Text fontSize="14" fontWeight="400" cx={css.multiFieldLabel}>
                Asset footprints
            </Text>
            {fields.map((item, index) => {
                const dataIdIndex = index + 1;

                return (
                    <FlexRow
                        key={item.id}
                        vPadding="12"
                        cx={css.assetFootprints}
                    >
                        <FlexCell grow={0.5}>
                            <FieldController<ArraySelectProps<Asset>>
                                labelKey="name"
                                idKey="id"
                                options={assetOptions}
                                component={ArraySelect}
                                placeholder="Select asset"
                                valueType="entity"
                                name={`assetFootprints.${index}.asset`}
                                isRequired={isRequired}
                                dataTestId={`assetFootprints/asset/${dataIdIndex}`}
                            />
                        </FlexCell>
                        <FlexCell minWidth={18} alignSelf="center">
                            <IconContainer
                                style={{ fill: 'var(--uui-link)' }}
                                icon={arrowIcon}
                                rotate="180"
                            />
                        </FlexCell>
                        <FlexCell grow={0.5}>
                            <FieldController<ArraySelectProps<TNamed>>
                                labelKey="name"
                                idKey="id"
                                options={actionOptions}
                                component={ArraySelect}
                                placeholder="Select action performed on asset"
                                name={`assetFootprints.${index}.actionOnAsset`}
                                dataTestId={`assetFootprints/actionOnAsset/${dataIdIndex}`}
                                isRequired={isRequired}
                            />
                        </FlexCell>
                        <FlexCell
                            minWidth={18}
                            cx={css.deleteButton}
                            alignSelf="center"
                        >
                            <IconButton
                                dataTestId={`${FIELD_NAME}/delete/${dataIdIndex}`}
                                icon={deleteIcon}
                                color="info"
                                onClick={() => remove(index)}
                            />
                        </FlexCell>
                    </FlexRow>
                );
            })}
            {(mode === FormMode.edit || mode === FormMode.create) && (
                <LinkButton
                    onClick={appendField}
                    icon={addIcon}
                    caption="Add asset footprint"
                    dataTestId={`${FIELD_NAME}/add`}
                />
            )}
        </div>
    );
};
