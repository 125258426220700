/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { Ref, useMemo, forwardRef } from 'react';
/**
 * Components
 */
import { Panel as UuiPanel } from '@epam/promo';
/**
 * Types
 */
import type { WithDataTestId } from 'types';
import type { FC, ComponentProps } from 'react';
/**
 * Utils
 */
import { getRawProps } from 'utils';
/**
 * Expo
 */

export type TPanelProps = ComponentProps<typeof UuiPanel> & WithDataTestId;

/**
 * If you want to create a white curved panel with shadow (the basic panel on which our UI is built) for some page
 * use /common/common/Panel/PanelBlock instead
 */
export const Panel: FC<TPanelProps> = forwardRef(
    ({ dataTestId, ...props }, ref: Ref<unknown>) => {
        const rawProps = useMemo(
            () => getRawProps({ dataTestId }),
            [dataTestId]
        );

        return <UuiPanel ref={ref} {...props} rawProps={rawProps} />;
    }
);
