/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { PieSvgProps } from '@nivo/pie/dist/types/types';
import { TChartData } from './types';

/**
 * Expo
 */
export const DEFAULT_PROPS: Omit<
    PieSvgProps<TChartData>,
    'height' | 'width' | 'data'
> = {
    startAngle: 0,
    innerRadius: 0.7,
    enableArcLabels: false,
    activeInnerRadiusOffset: 4,
    activeOuterRadiusOffset: 4,
    enableArcLinkLabels: false,
    colors: { datum: 'data.color' },
    margin: { top: 10, right: 10, bottom: 10, left: 10 },
    borderWidth: 2,
    padAngle: 1,
    borderColor: {
        from: 'color',
        modifiers: [['darker', 0.2]],
    },
};
