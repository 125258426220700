/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { IModal } from '@epam/uui-core';
import { Text } from '@epam/promo';
import { Modal as ModalComponent } from 'components/common';
/**
 * Types
 */
export type ConfirmationModalProps = {
    text: string;
    onSuccess?: () => void;
};
/**
 * Expo
 */
export const ConfirmationModal = ({
    text,
    onSuccess,
    ...props
}: IModal<string> & ConfirmationModalProps): JSX.Element => (
    <ModalComponent
        {...props}
        title="Confirmation"
        onSuccess={onSuccess}
        renderBody={() => <Text>{text}</Text>}
    />
);
