/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { BadgeProps } from '@epam/promo';
/**
 * Constants
 */
import { ColorNames, Colors } from 'constants/colors';

/**
 * Expo
 */
export enum StatusTypes {
    done = 'DONE',
    toDo = 'TO_DO',
    resolved = 'RESOLVED',
    opened = 'OPENED',
    retest = 'RETEST',
    active = 'ACTIVE',
    archived = 'ARCHIVED',
    pendingFix = 'PENDING_FIX',
    inProgress = 'IN_PROGRESS',
    riskAccepted = 'RISK_ACCEPTED',
    readyToRetest = 'READY_TO_RETEST',
    unresolved = 'UNRESOLVED',
    approved = 'APPROVED',
    rejected = 'REJECTED',
    waitingForApproval = 'WAITING_FOR_APPROVAL',
    pending = 'PENDING',
    completed = 'COMPLETED',
    statusNew = 'NEW',
    finished = 'FINISHED',
    onHold = 'ON_HOLD',
    draft = 'DRAFT',
}

export enum StatusCaptions {
    done = 'Done',
    toDo = 'To do',
    resolved = 'Resolved',
    opened = 'Opened',
    retest = 'Retest',
    active = 'Active',
    archived = 'Archived',
    pendingFix = 'Pending fix',
    inProgress = 'In progress',
    riskAccepted = 'Risk accepted',
    readyToRetest = 'Ready to retest',
    unresolved = 'Unresolved',
    approved = 'Approved',
    rejected = 'Rejected',
    waitingForApproval = 'Waiting for approval',
    pending = 'Pending',
    completed = 'Completed',
    captionNew = 'New',
    finished = 'Finished',
    onHold = 'On hold',
    draft = 'Draft',
}

export enum UserStatuses {
    blocked = 'N_A',
    active = 'ACTIVE',
    waitingForActivation = 'WAITING_FOR_ACTIVATION',
    waitingForActivationOld = 'WAITING_FOR_ACTIVATION_OLD',
    waitingForInvite = 'WAITING_FOR_INVITE',
    waitingForReactivation = 'WAITING_FOR_REACTIVATION',
    waitingForReinvite = 'WAITING_FOR_REINVITE',
    waitingForInviteFederation = 'WAITING_FOR_INVITE_FEDERATION',
    obfuscated = 'OBFUSCATED',
}

export enum UserStatusCaptions {
    active = 'Active',
    blocked = 'Blocked',
    waitingForActivation = 'Waiting for activation',
    waitingForActivationOld = 'Waiting for activation (old)',
    waitingForInvite = 'Waiting for invite',
    waitingForReactivation = 'Waiting for re-activation',
    waitingForReinvite = 'Waiting for re-invite',
    waitingForInviteFederation = 'Waiting for invite (federation)',
    obfuscated = 'Obfuscated',
}

export const CAPTIONS_BY_STATUS = {
    [StatusTypes.done]: StatusCaptions.done,
    [StatusTypes.toDo]: StatusCaptions.toDo,
    [StatusTypes.resolved]: StatusCaptions.resolved,
    [StatusTypes.opened]: StatusCaptions.opened,
    [StatusTypes.retest]: StatusCaptions.retest,
    [StatusTypes.active]: StatusCaptions.active,
    [StatusTypes.archived]: StatusCaptions.archived,
    [StatusTypes.pendingFix]: StatusCaptions.pendingFix,
    [StatusTypes.inProgress]: StatusCaptions.inProgress,
    [StatusTypes.riskAccepted]: StatusCaptions.riskAccepted,
    [StatusTypes.unresolved]: StatusCaptions.unresolved,
    [StatusTypes.readyToRetest]: StatusCaptions.readyToRetest,
    [StatusTypes.approved]: StatusCaptions.approved,
    [StatusTypes.waitingForApproval]: StatusCaptions.waitingForApproval,
    [StatusTypes.rejected]: StatusCaptions.rejected,
    [StatusTypes.pending]: StatusCaptions.pending,
    [StatusTypes.completed]: StatusCaptions.completed,
    [StatusTypes.statusNew]: StatusCaptions.captionNew,
    [StatusTypes.finished]: StatusCaptions.finished,
    [StatusTypes.onHold]: StatusCaptions.onHold,
    [StatusTypes.draft]: StatusCaptions.draft,
};
// TODO: extract BadgeProps['color'] somewhere in the upper level, e.g. in the colors.ts or types directory, then replace all the BadgeProps['color'] with the extracted type

export const STATUS_COLORS_NAMES: Record<StatusTypes, BadgeProps['color']> = {
    [StatusTypes.done]: ColorNames.green,
    [StatusTypes.retest]: ColorNames.purple,
    [StatusTypes.toDo]: ColorNames.violet,
    [StatusTypes.active]: ColorNames.blue,
    [StatusTypes.resolved]: ColorNames.green,
    [StatusTypes.opened]: ColorNames.blue,
    [StatusTypes.archived]: ColorNames.cyan,
    [StatusTypes.inProgress]: ColorNames.blue,
    [StatusTypes.pendingFix]: ColorNames.orange,
    [StatusTypes.riskAccepted]: ColorNames.cyan,
    [StatusTypes.unresolved]: ColorNames.red,
    [StatusTypes.readyToRetest]: ColorNames.amber,
    [StatusTypes.waitingForApproval]: ColorNames.red,
    [StatusTypes.approved]: ColorNames.green,
    [StatusTypes.rejected]: ColorNames.orange,
    [StatusTypes.pending]: ColorNames.orange,
    [StatusTypes.completed]: ColorNames.green,
    [StatusTypes.statusNew]: ColorNames.violet,
    [StatusTypes.finished]: ColorNames.violet,
    // TODO decide what to do with gray20 in the whole app. Reach out to the designer
    [StatusTypes.onHold]: ColorNames.gray30,
    [StatusTypes.draft]: ColorNames.gray30,
};

export const STATUS_COLORS = {
    [StatusTypes.done]: Colors.greenLightest,
    [StatusTypes.retest]: Colors.purpleLightest,
    [StatusTypes.toDo]: Colors.violetLightest,
    [StatusTypes.active]: Colors.blueLightest,
    [StatusTypes.resolved]: Colors.greenLightest,
    [StatusTypes.opened]: Colors.blueLightest,
    [StatusTypes.archived]: Colors.cyanLightest,
    [StatusTypes.inProgress]: Colors.blueLightest,
    [StatusTypes.pendingFix]: Colors.orangeLightest,
    [StatusTypes.riskAccepted]: Colors.cyanLightest,
    [StatusTypes.unresolved]: Colors.redLightest,
    [StatusTypes.readyToRetest]: Colors.amberLightest,
    [StatusTypes.waitingForApproval]: Colors.redLightest,
    [StatusTypes.approved]: Colors.greenLightest,
    [StatusTypes.rejected]: Colors.orangeLightest,
    [StatusTypes.pending]: Colors.orangeLightest,
    [StatusTypes.completed]: Colors.greenLightest,
    [StatusTypes.statusNew]: Colors.violetLightest,
    [StatusTypes.finished]: Colors.violetLightest,
    [StatusTypes.onHold]: Colors.gray20,
    [StatusTypes.draft]: Colors.gray20,
};

export enum VulnerabilityStatusTypes {
    open = 'OPEN',
    confirmed = 'CONFIRMED',
    reopened = 'REOPENED',
    resolved = 'RESOLVED',
    closed = 'CLOSED',
}

export enum AuthStepType {
    softwareTokenMFA = 'SOFTWARE_TOKEN_MFA',
    newPasswordRequired = 'NEW_PASSWORD_REQUIRED',
    rotatePasswordRequired = 'ROTATE_PASSWORD_REQUIRED',
}
