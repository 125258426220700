/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useMemo } from 'react';
import { cx } from '@epam/uui-core';
/**
 * Components
 */
import { Badge } from 'components/common';
/**
 * Types
 */
import { StatusProps } from './types';
/**
 * Constants
 */
import { FINDING_RESOLUTION_CAPTIONS } from 'constants/finding';
import {
    StatusTypes,
    CAPTIONS_BY_STATUS,
    STATUS_COLORS_NAMES,
} from 'constants/statuses';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const Status: React.FC<StatusProps> = ({
    type,
    dataTestId,
    fill,
    resolution,
}) => {
    const caption = useMemo(() => {
        if (type === StatusTypes.resolved && resolution) {
            return FINDING_RESOLUTION_CAPTIONS[resolution];
        }

        return CAPTIONS_BY_STATUS[type];
    }, [type, resolution]);

    // This need because we can receive status as a label instead of id
    // Therefore we don't know what exactly status we have
    // This can be happen Jira tasks have their own values
    if (!Object.values(StatusTypes).includes(type)) {
        return (
            <Badge
                fill={fill || 'outline'}
                caption={type}
                dataTestId={dataTestId}
                color={STATUS_COLORS_NAMES.IN_PROGRESS}
            />
        );
    }

    return (
        <div
            className={cx(
                type === StatusTypes.archived && css.archived,
                type === StatusTypes.draft && css.draft
            )}
        >
            <Badge
                fill={fill}
                color={STATUS_COLORS_NAMES[type]}
                dataTestId={dataTestId}
                caption={caption}
            />
        </div>
    );
};
