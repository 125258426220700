/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/* eslint-disable no-underscore-dangle, class-methods-use-this */
import type {
    DOMConversionMap,
    DOMConversionOutput,
    DOMExportOutput,
    EditorConfig,
    LexicalNode,
    NodeKey,
    SerializedLexicalNode,
    Spread,
} from 'lexical';
import { v4 as uuidv4 } from 'uuid';

import { $applyNodeReplacement, DecoratorNode } from 'lexical';
import * as React from 'react';
import { Suspense } from 'react';
// eslint-disable-next-line import/no-cycle
import { ImageComponent } from './ImageComponent';
import { TDocument } from 'types';

export type ImagePayload = {
    altText: string;
    height?: number;
    key?: NodeKey;
    maxWidth?: number;
    width?: number;
    name?: string | null;
    id?: string | null;
    src?: string | null;
};

function convertImageElement(domNode: Node): null | DOMConversionOutput {
    if (domNode instanceof HTMLImageElement) {
        const { alt: altText, src, width, height } = domNode;
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const node = $createImageNode({ altText, height, src, width });
        return { node };
    }
    return null;
}

export type SerializedImageNode = Spread<
    {
        altText: string;
        name: string;
        id: string | null;
        maxWidth?: number;
        src: string | null;
        height?: number;
        width?: number;
    },
    SerializedLexicalNode
>;

export class ImageNode extends DecoratorNode<JSX.Element> {
    /**
     * src is a base64 image
     * it can be null in case when we initialise an image which is attached(uploaded from the server)
     */
    __src: string | null;
    /**
     * id is the identification of the image in database
     * it can be null when we past image from the clipboard, because it still not uploaded
     */
    __id: string | null;
    __name: string;
    __altText: string;
    __width: 'inherit' | number;
    __height: 'inherit' | number;
    __maxWidth?: number;
    __mimeType: string;

    /**
     * This two static properties looks very like a dirty hack
     * Especially the second one, why lexical don't have a convenient API for searching nodes?
     */
    static imagesTable: Record<string, TDocument> = {};
    static imageNodes: Record<NodeKey, ImageNode> = {};
    /**
     * Absolutely dirty trick for managing export of the node
     * When we copy an image to the clipboard, we need to preserve the base64 content of the image inside the src
     * But when exporting the node to html for its further sending to the server, we should put the name of the image into the src field
     *
     * so, the "copy" mode is keeping base64 in the src
     * the "export" mode puts a "name" (uuid) of the into the src
     */
    static exportMode: 'copy' | 'export' = 'copy';
    static turnOnExportMode() {
        ImageNode.exportMode = 'export';
    }

    static turnOffExportMode() {
        this.exportMode = 'copy';
    }

    static getType(): string {
        return 'image';
    }

    static clone(node: ImageNode): ImageNode {
        return new ImageNode(
            node.getSrc(),
            node.__id,
            node.__name,
            node.__altText,
            node.__maxWidth,
            node.__width,
            node.__height
        );
    }

    static importJSON(serializedNode: SerializedImageNode): ImageNode {
        const { altText, height, width, maxWidth, src } = serializedNode;
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const node = $createImageNode({
            altText,
            height,
            maxWidth,
            src,
            width,
        });

        return node;
    }

    exportDOM(): DOMExportOutput {
        // We need to wrap img node with a figure in order to properly generate DOCX report
        const figure = document.createElement('figure');

        const img = document.createElement('img');
        const src = this.getSrc();
        if (ImageNode.exportMode === 'copy' && src !== null) {
            img.setAttribute('src', src);
        } else {
            img.setAttribute('src', this.__name);
        }
        img.setAttribute('alt', this.__altText);
        img.setAttribute('width', this.__width.toString());
        img.setAttribute('height', this.__height.toString());

        figure.append(img);
        return { element: figure };
    }

    static importDOM(): DOMConversionMap | null {
        return {
            img: () => ({
                conversion: convertImageElement,
                priority: 0,
            }),
        };
    }

    constructor(
        src: string | null,
        id: string | null,
        name: string | null,
        altText: string,
        maxWidth?: number,
        width?: 'inherit' | number,
        height?: 'inherit' | number,
        key?: NodeKey
    ) {
        super(key);

        if (name != null) {
            /**
             * -> Cloning image: (props state)
             *   cloning pasted: { name: string; id: null; src: base64; }
             *   cloning attachment: { name: string; id: string; src: string | null  }
             */
            this.__src = src;
            this.__id = id;
            this.__name = name;
        } else if (src == null) {
            throw new Error('src cannot be null during creation of image');
        } else if (src.startsWith('data:image')) {
            /**
             * -> Creating pasted image:
             * { name: null; id: null; src: base64 }
             */
            const [, type] = src.split(';')[0].split('/');

            this.__name = `${uuidv4()}.${type}`;
            this.__mimeType = type;
            this.__id = null;
            this.__src = src;
        } else {
            /**
             * -> Creating attached image:
             * { name: null; id: string; src: url }
             *
             * Initially only the name of the file are in the src field
             * but for some reason, HTML is parsed in such a way that the entire URL
             * of the current page is appended to the img src. So we need to flush it.
             */
            this.__name = src.startsWith('http')
                ? src.split('/').slice(-1)[0]
                : src;
            this.__id = ImageNode.imagesTable[this.__name]?.id ?? null;
            this.__src = null;
        }
        this.__altText = altText;
        this.__maxWidth = maxWidth;
        this.__width = width || 'inherit';
        this.__height = height || 'inherit';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.__mimeType = this.__name.split('.')[1]!;
        this.__DEV_src = { value: this.__src };
        this.setSrc = this.setSrc.bind(this);

        ImageNode.imageNodes[this.__key] = this;
    }

    exportJSON(): SerializedImageNode {
        return {
            altText: this.getAltText(),
            height: this.__height === 'inherit' ? 0 : this.__height,
            maxWidth: this.__maxWidth,
            src: this.getSrc(),
            name: this.__name,
            id: this.__id,
            type: 'image',
            version: 1,
            width: this.__width === 'inherit' ? 0 : this.__width,
        };
    }

    setWidthAndHeight(
        width: 'inherit' | number,
        height: 'inherit' | number
    ): void {
        const writable = this.getWritable();
        writable.__width = width;
        writable.__height = height;
    }

    // View

    createDOM(config: EditorConfig): HTMLElement {
        const span = document.createElement('span');
        const { theme } = config;
        const className = theme.image;
        if (className !== undefined) {
            span.className = className;
        }
        return span;
    }

    updateDOM(): false {
        return false;
    }

    getSrc(): string | null {
        return this.__src;
    }

    setSrc(src: string): string {
        const writable = this.getWritable();
        writable.__src = src;

        return src;
    }

    getAltText(): string {
        return this.__altText;
    }

    getName() {
        return this.__name;
    }

    getId() {
        return this.__id;
    }

    getMimeType() {
        return this.__mimeType;
    }

    decorate(): JSX.Element {
        return (
            <Suspense fallback={null}>
                <ImageComponent
                    src={this.getSrc()}
                    imageId={this.__id}
                    setNodeSrc={this.setSrc}
                    altText={this.__altText}
                    width={this.__width}
                    height={this.__height}
                    maxWidth={this.__maxWidth}
                    nodeKey={this.getKey()}
                    resizable
                />
            </Suspense>
        );
    }
}

export function $createImageNode({
    altText,
    height,
    maxWidth,
    width,
    src = null,
    id = null,
    name = null,
}: ImagePayload): ImageNode {
    return $applyNodeReplacement(
        new ImageNode(src, id, name, altText, maxWidth, width, height)
    );
}

export function $isImageNode(
    node: LexicalNode | null | undefined
): node is ImageNode {
    return node instanceof ImageNode;
}
