/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { combineReducers } from 'redux';
import { interactionsReducer } from './interaction/slice';
import { dfdMetadataReducer } from './slice';
import { technologiesReducer } from './technology/slice';
import { assetsReducer } from './asset/slice';
import { elementsReducer } from './element/slice';
import { trustBoundariesReducer } from './trustBoundary/slice';

export const threatModelingReducer = combineReducers({
    dfdMetadata: dfdMetadataReducer,
    technologies: technologiesReducer,
    assets: assetsReducer,
    elements: elementsReducer,
    interactions: interactionsReducer,
    trustBoundaries: trustBoundariesReducer,
});
