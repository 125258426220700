/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createReducer } from '@reduxjs/toolkit';
/**
 * Actions
 */
import { setInProgress } from './actions';
/**
 * Constants
 */
import { INITIAL_STATE } from './constants';
/**
 * Expo
 */
export default createReducer(INITIAL_STATE, {
    [setInProgress.type]: (state, { payload }) => {
        state.inProgress = payload;
    },
});
