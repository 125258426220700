/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Tooltip } from '@epam/promo';
import { renderPopoverMarkup } from './renderPopoverMarkup';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { TPopover } from './types';
/**
 * Expo
 */
export const Popover: React.FC<TPopover> = ({
    title,
    children,
    statusList,
}) => (
    <Tooltip
        color="white"
        cx={css.popover}
        content={renderPopoverMarkup(title, statusList)}
    >
        {children}
    </Tooltip>
);
