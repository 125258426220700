/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useMemo } from 'react';
/**
 * Components
 */
import { SelectAssignee } from '../SelectAssignee';
import { ActivityCard as CommonActivityCard } from 'components/ActivityCard';
import {
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
} from 'react-beautiful-dnd';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
import { useAppSelector, useRole } from 'hooks';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { TProps } from './types';
/**
 * Constants
 */
import { StatusTypes } from 'constants/statuses';
/**
 * Store
 */
import { selectUserId } from 'store/user';
import { selectActivity, selectAssessment } from 'store/assessment';
/**
 * Expo
 */
export const ActivityCard: React.FC<TProps> = ({ id, index }) => {
    const { isProjectRepresentative } = useRole();

    const userId = useSelector(selectUserId);
    const { status } = useSelector(selectAssessment);
    const {
        name,
        target,
        blocked,
        description,
        assessment_id: assessmentId,
        assignees: { persons } = { persons: [] },
    } = useAppSelector((state) => selectActivity(state, id));

    const { name: targetName } = target || {};

    const isDisabled = useMemo(() => {
        return (
            status !== StatusTypes.active ||
            isProjectRepresentative ||
            !persons?.some(({ id: assigneeId }) => assigneeId === userId)
        );
    }, [status, isProjectRepresentative, persons, userId]);

    return (
        <Draggable index={index} draggableId={id} isDragDisabled={isDisabled}>
            {(
                dragProvided: DraggableProvided,
                { isDragging }: DraggableStateSnapshot
            ) => (
                <div
                    ref={dragProvided.innerRef}
                    {...dragProvided.draggableProps}
                    {...dragProvided.dragHandleProps}
                >
                    <CommonActivityCard
                        id={id}
                        name={name}
                        blocked={blocked}
                        targetName={targetName}
                        description={description}
                        assessmentId={assessmentId}
                        dataTestId="singleAssessment/boardColumn/card"
                        className={isDragging ? css.containerDragging : ''}
                        renderAssignees={() => (
                            <SelectAssignee
                                id={id}
                                value={persons}
                                isDisabled={status === StatusTypes.completed}
                            />
                        )}
                    />
                </div>
            )}
        </Draggable>
    );
};
