/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { DAY, HOUR, MINUTE } from './constants';
/**
 * Types
 */
import { TFunctionWithArgs } from 'types';
import { TDateTextCalculationProps } from './type';
/**
 * Expo
 */
const createFromTemplate = (text: string, count: number) => {
    if (count === 1) {
        return `1 ${text} ago`;
    }

    return `${count} ${text}s ago`;
};

export const getText = (milliseconds: number): string => {
    switch (true) {
        case milliseconds >= MINUTE && milliseconds < HOUR: {
            const minutes = Math.floor(milliseconds / MINUTE);

            return createFromTemplate('minute', minutes);
        }
        case milliseconds >= HOUR && milliseconds < DAY: {
            const hours = Math.floor(milliseconds / HOUR);

            return createFromTemplate('hour', hours);
        }
        case milliseconds >= DAY: {
            const days = Math.floor(milliseconds / DAY);

            return createFromTemplate('day', days);
        }
        default:
            return 'few seconds ago';
    }
};

const getDelay = (milliseconds: number): number => {
    switch (true) {
        case milliseconds < HOUR: {
            return MINUTE;
        }
        case milliseconds >= HOUR && milliseconds < DAY: {
            return HOUR;
        }
        case milliseconds >= DAY:
        default:
            return 0;
    }
};

export const getMillisecondsFromNow = (date: Date): number => {
    const millisecondsFromToday = new Date().getTime();
    const millisecondsFromCreatedAt = new Date(date).getTime();

    return millisecondsFromToday - millisecondsFromCreatedAt;
};

export class DateTextCalculation {
    date: Date;
    setText: TFunctionWithArgs<string>;
    timerId?: ReturnType<typeof setTimeout>;

    constructor({ date, setText }: TDateTextCalculationProps) {
        this.date = date;
        this.setText = setText;
    }

    get milliseconds(): number {
        return getMillisecondsFromNow(this.date);
    }

    get text(): string {
        return getText(this.milliseconds);
    }

    get delay(): number {
        return getDelay(this.milliseconds);
    }

    start(): void {
        this.setText(this.text);

        if (!this.delay) return;

        this.timerId = setTimeout(() => {
            this.start();
        }, this.delay);
    }

    stop(): void {
        if (!this.timerId) return;

        clearTimeout(this.timerId);
    }
}
