/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Expo
 */
export enum EntityTypes {
    project = 'PROJECT',
    activity = 'ACTIVITY',
    assessment = 'ASSESSMENT',
}

export enum GeneralActionLogTypes {
    createFinding = 'CREATE_FINDING',
    updateFindingStatus = 'UPDATE_FINDING_STATUS',
    updateFindingSeverity = 'UPDATE_FINDING_SEVERITY',
    updateFindingRiskAcceptedLabel = 'UPDATE_FINDING_RISK_ACCEPTED_LABEL',
    organizationCreated = 'ORGANIZATION_CREATED',
    projectCreated = 'PROJECT_CREATED',
    projectUpdated = 'PROJECT_UPDATED',
    projectArchived = 'PROJECT_ARCHIVED',
    projectDescriptionUpdated = 'PROJECT_DESCRIPTION_UPDATED',
    personCreated = 'PERSON_CREATED',
    personUpdated = 'PERSON_UPDATED',
    personBlocked = 'PERSON_BLOCKED',
    personUnblocked = 'PERSON_UNBLOCKED',
    invitationResent = 'INVITATION_RESENT',
    logIn = 'LOG_IN',
    logOut = 'LOG_OUT',
    unsuccessfulLogin = 'UNSUCCESSFUL_LOG_IN',
    resetPasswordAttempt = 'RESET_PASSWORD_ATTEMPT',
    resetPasswordSuccess = 'RESET_PASSWORD_SUCCESS',
    authorizationFault = 'AUTHORIZATION_FAULT',
    activityReferenceCreated = 'ACTIVITY_REFERENCE_CREATED',
    activityReferenceUpdated = 'ACTIVITY_REFERENCE_UPDATED',
    activityReferenceArchived = 'ACTIVITY_REFERENCE_ARCHIVED',
    activityReferenceUnArchived = 'ACTIVITY_REFERENCE_UNARCHIVED',
}

export enum ActivityActionLogTypes {
    updateActivity = 'ACTIVITY_UPDATED',
    activityCommentCreated = 'ACTIVITY_COMMENT_CREATED',
}

export enum ProjectActionLogTypes {
    reportDeletedPublished = 'PUBLISHED_REPORT_DELETED',
    reportDeletedDraft = 'DRAFT_REPORT_DELETED',
    reportPublish = 'REPORT_PUBLISHED',
    reportCreatedDraft = 'DRAFT_REPORT_CREATED',
    reportCreatedPublic = 'PUBLISHED_REPORT_CREATED',
    findingCreated = 'FINDING_CREATED',
    findingArchived = 'FINDING_ARCHIVED',
    findingGenerated = 'FINDING_GENERATED',
    findingOpenedUpdated = 'OPENED_FINDING_UPDATED',
    findingMarkedUpdated = 'MARKED_FINDING_UPDATED',
    findingMarkedAsIssue = 'FINDING_MARKED_AS_ISSUE',
    assessmentCreated = 'ASSESSMENT_CREATED',
    assessmentUpdated = 'ASSESSMENT_UPDATED',
    assessmentStarted = 'ASSESSMENT_STARTED',
    assessmentCompleted = 'ASSESSMENT_COMPLETED',
    addTrackerIntegration = 'TRACKER_INTEGRATION_CREATED',
    deleteTrackerIntegration = 'TRACKER_INTEGRATION_DELETED',
    updateTrackerIntegration = 'TRACKER_INTEGRATION_UPDATED',
    addProjectAttachments = 'PROJECT_ATTACHMENTS_CREATED',
    deleteProjectAttachments = 'PROJECT_ATTACHMENTS_DELETED',
    targetCreated = 'TARGET_CREATED',
    projectCreatedPrivate = 'PROJECT_CREATED_PRIVATE',
    commentCreatedForOpenedFinding = 'OPENED_FINDING_COMMENT_CREATED',
    commentUpdatedForOpenedFinding = 'OPENED_FINDING_COMMENT_UPDATED',
    commentDeletedForOpenedFinding = 'OPENED_FINDING_COMMENT_DELETED',
    commentCreatedForMarkedFinding = 'MARKED_FINDING_COMMENT_CREATED',
    commentUpdatedForMarkedFinding = 'MARKED_FINDING_COMMENT_UPDATED',
    commentDeletedForMarkedFinding = 'MARKED_FINDING_COMMENT_DELETED',
    addMessengerIntegration = 'MESSENGER_INTEGRATION_CREATED',
    deleteMessengerIntegration = 'MESSENGER_INTEGRATION_DELETED',
    updateMessengerIntegration = 'MESSENGER_INTEGRATION_UPDATED',
    openedFindingAttachmentToCommentDeleted = 'OPENED_FINDING_ATTACHMENT_TO_COMMENT_DELETED',
    markedFindingAttachmentToCommentDeleted = 'MARKED_FINDING_ATTACHMENT_TO_COMMENT_DELETED',
    manualTaskCreated = 'MANUAL_TASK_CREATED',
    assessmentScopeManaged = 'ASSESSMENT_SCOPE_MANAGED',
}

export enum AssessmentActionLogTypes {
    startAssessment = 'ASSESSMENT_STARTED',
    createAssessment = 'ASSESSMENT_CREATED',
    updateAssessment = 'ASSESSMENT_UPDATED',
    completeAssessment = 'ASSESSMENT_COMPLETED',
}
