/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useMemo } from 'react';
/**
 * Components
 */
import { FlexRow, FlexSpacer } from '@epam/promo';
import { FindingStatus } from 'components/FindingStatus';
import { Breadcrumb, CopyToClipboard } from 'components/common';
import { Badge, Severity, User, Text, LinkButton, Panel } from '../common';
/**
 * Hooks
 */
import { useRedirect, useRouteParams } from 'hooks';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
import { ColorNames } from 'constants/colors';
/**
 * Types
 */
import { TFindingInformation } from './types';
/**
 * Utils
 */
import {
    formatDate,
    generatePath,
    NotificationTypes,
    showNotification,
} from 'utils';
/**
 * Assets
 */
import css from './style.module.scss';
import { ReactComponent as LinkIcon } from '@epam/assets/icons/common/content-link-18.svg';
import { getBreadCrumbs, getFindingName } from './utils';
import { IssueKey } from 'components/FindingInformation/IssueKey';
/**
 * Expo
 */
export const FindingInformation: React.FC<TFindingInformation> = React.memo(
    ({
        id,
        status,
        severity,
        full_name,
        updated_by,
        updated_at,
        dataTestId,
        assessment,
        marked_as_risk_accepted,
        resolution,
        summary,
        issueKey,
        compositeId,
        isGenerated,
        target,
        activity,
        copyLinkAvailable = true,
    }) => {
        const redirect = useRedirect();
        const { projectId } = useRouteParams();

        const findingHref = generatePath(PAGES.singleFinding, {
            projectId,
            findingId: id,
        });

        const findingName = getFindingName(compositeId, summary, issueKey);

        const handleRedirect = useCallback(() => {
            redirect(findingHref);
        }, [redirect, findingHref]);

        const handleCopyLink = useCallback(() => {
            showNotification({
                text: 'Link copied to clipboard',
                type: NotificationTypes.success,
            });
        }, []);

        const breadcrumbs = useMemo(() => {
            if (target && activity && assessment) {
                return getBreadCrumbs({
                    target,
                    activity,
                    projectId,
                    assessment,
                    dataTestId,
                });
            }

            return null;
        }, [target, activity, projectId, assessment, dataTestId]);

        return (
            <Panel
                background="white"
                onClick={handleRedirect}
                cx={css.findingInformationContainer}
            >
                <Panel cx={css.findingInfoContent}>
                    {!breadcrumbs && !copyLinkAvailable ? null : (
                        <FlexRow cx={css.spaceReducer}>
                            {breadcrumbs && <Breadcrumb list={breadcrumbs} />}
                            <FlexSpacer />
                            {copyLinkAvailable && (
                                <CopyToClipboard
                                    onCopy={handleCopyLink}
                                    text={window.location.origin + findingHref}
                                >
                                    <LinkButton
                                        size="24"
                                        icon={LinkIcon}
                                        caption="Copy link"
                                        dataTestId={`${dataTestId}/copyLink`}
                                    />
                                </CopyToClipboard>
                            )}
                        </FlexRow>
                    )}
                    <FlexRow cx={css.spaceReducer}>
                        <LinkButton
                            href={findingHref}
                            cx={css.link}
                            caption={findingName}
                            dataTestId={`${dataTestId}/title`}
                        />
                        <FlexSpacer />
                        <IssueKey issueKey={issueKey} dataTestId={dataTestId} />
                        <Text
                            fontSize="12"
                            color="gray60"
                            cx={css.itemOnRight}
                            dataTestId={`${dataTestId}/findingType`}
                        >
                            {isGenerated ? 'Generated' : 'Manual'}
                        </Text>
                    </FlexRow>
                    <FlexRow columnGap="6">
                        <Severity
                            type={severity}
                            dataTestId={`${dataTestId}/severity`}
                        />
                        <FindingStatus
                            type={status}
                            dataTestId={`${dataTestId}/status`}
                            resolution={resolution}
                        />
                        {marked_as_risk_accepted && (
                            <Badge
                                fill="outline"
                                caption="Risk accepted"
                                color={ColorNames.cyan}
                            />
                        )}
                        <User
                            textSize="12"
                            avatarSize="18"
                            name={full_name}
                            dataTestId={`${dataTestId}/assignee`}
                        />
                        <FlexSpacer />
                        <Text
                            size="18"
                            fontSize="12"
                            color="gray60"
                            dataTestId={`${dataTestId}/updatedAt`}
                        >
                            Last changed {formatDate(updated_at)} by
                        </Text>
                        <User
                            textSize="12"
                            avatarSize="18"
                            name={updated_by.full_name}
                            dataTestId={`${dataTestId}/updatedBy`}
                        />
                    </FlexRow>
                </Panel>
            </Panel>
        );
    }
);
