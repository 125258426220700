/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, useContext } from 'react';
/**
 * Components
 */
import { FlexRow, FlexSpacer } from '@epam/promo';
import { FileIcon } from 'components/common/FileIcon';
import { IconContainer, Panel, Text } from 'components/common';
import {
    EditorContext,
    AttachmentsContext,
} from 'components/common/RichEditorInput/context';
/**
 * Assets
 */
import { ReactComponent as removeIcon } from '@epam/assets/icons/common/navigation-close-18.svg';
/**
 * Utils
 */
import { getExtensionFromFileName, getSizeToMegabytes } from 'utils';
/**
 * Types
 */
import { TPendingFileProps } from './types';
/**
 * Expo
 */
export const PendingFile: FC<TPendingFileProps> = ({ file }) => {
    const { readOnly } = useContext(EditorContext);

    const {
        file: { size, name },
    } = file;

    const { onRemove } = useContext(AttachmentsContext);

    const handleRemove = () => {
        onRemove?.(file);
    };

    const extension = getExtensionFromFileName(name).toLowerCase();

    return (
        <Panel>
            <FlexRow columnGap="6">
                <FileIcon extension={extension} />
                <Text>
                    {name} ({getSizeToMegabytes(size)}MB)
                </Text>
                <FlexSpacer />
                {!readOnly && (
                    <IconContainer
                        size={24}
                        style={{ fill: 'var(--uui-link)' }}
                        icon={removeIcon}
                        onClick={handleRemove}
                    />
                )}
            </FlexRow>
        </Panel>
    );
};
