/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Hooks
 */
import { useRouteParams } from 'hooks';
/**
 * Components
 */
import { LinkButton, Text } from 'components/common';
import { FlexRow, IconContainer } from '@epam/promo';
/**
 * Assets
 */
import css from './style.module.scss';
import { ReactComponent as myIcon } from '@epam/assets/icons/common/navigation-back-18.svg';
/**
 * Type
 */
import { BreadcrumbItemProps } from './types';
/**
 * Utils
 */
import { generatePath } from 'utils';
/**
 * Expo
 */
export const CrumbItem: React.FC<BreadcrumbItemProps> = ({
    isFirst,
    caption,
    pathname,
    dataTestId,
}) => {
    const params = useRouteParams();
    const pathToRedirect = pathname ? generatePath(pathname, params) : '';

    return (
        <FlexRow>
            {!isFirst && (
                <IconContainer
                    icon={myIcon}
                    style={{ fill: 'var(--uui-link)' }}
                    cx={css.arrowContainer}
                />
            )}
            {pathToRedirect ? (
                <LinkButton
                    size="36"
                    caption={caption}
                    href={pathToRedirect}
                    dataTestId={dataTestId}
                />
            ) : (
                <Text dataTestId={dataTestId} color="gray60">
                    {caption}
                </Text>
            )}
        </FlexRow>
    );
};
