/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { User } from 'components/common';
/**
 * Types
 */
import { UserRowProps } from './types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const UserRow: React.FC<UserRowProps> = ({ name, email }) => (
    <div className={css.container}>
        <User
            name={name}
            email={email}
            textSize="12"
            avatarSize="24"
            lineHeight="12"
        />
    </div>
);
