/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { zodOptionalString } from '../constants';
import * as z from 'zod';
import {
    AssetAvailability,
    AssetConfidentiality,
    AssetIntegrity,
    AssetType,
} from 'types/threatModeling/asset';
import { baseItemFormSchema } from '../baseItemFormSchema';

export const assetFormSchema = baseItemFormSchema.extend({
    type: z.nativeEnum(AssetType).nullish(),
    comment: zodOptionalString,
    confidentiality: z.nativeEnum(AssetConfidentiality).nullish(),
    confidentialityComment: zodOptionalString,
    integrity: z.nativeEnum(AssetIntegrity).nullish(),
    integrityComment: zodOptionalString,
    availability: z.nativeEnum(AssetAvailability).nullish(),
    availabilityComment: zodOptionalString,
});
export type AssetFormValues = z.infer<typeof assetFormSchema>;
export type AssetFormSchema = typeof assetFormSchema;
