/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { StatusTypes } from 'constants/statuses';
/**
 * Types
 */
import { TActivity } from 'types';
import { TSortedActivities } from './types';
/**
 * Expo
 */
export const sortActivitiesIdByStatus = (
    activities: TActivity[]
): TSortedActivities =>
    activities.reduce(
        (accumulator, { status, id }: TActivity) => {
            accumulator[status].push(id);

            return accumulator;
        },
        {
            [StatusTypes.toDo]: [],
            [StatusTypes.inProgress]: [],
            [StatusTypes.done]: [],
        } as TSortedActivities
    );

export const sortActivitiesById = (
    activities: TActivity[]
): Record<string, TActivity> =>
    activities.reduce((accumulator, activity: TActivity) => {
        accumulator[activity.id] = activity;

        return accumulator;
    }, {} as Record<string, TActivity>);
