/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { TMapDataToState } from './types';
/**
 * Expo
 */
export const mapDataToState: TMapDataToState = ({
    url,
    key,
    title,
    status,
    assignee,
    updated_at,
    description,
}) => ({
    url,
    title,
    status,
    assignee,
    description,
    taskKey: key,
    updatedAt: updated_at,
});
