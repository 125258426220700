/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { AppDispatch } from 'types';
import { useEditableItemCard } from '../../../EditableItemCardContext';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch } from 'hooks';
import { Button, FlexRow } from 'components/common';
import { BaseItemFormValues } from '../baseItemFormSchema';
// TODO: fix file location after complete migration
import css from './style.module.scss';

import { ReactComponent as EditIcon } from '@epam/assets/icons/common/content-edit-18.svg';
import { FormMode } from '../../../../types';

export interface FormFooterProps<Values extends BaseItemFormValues> {
    onClose: () => void;
    onCreate: (values: Values) => (dispatch: AppDispatch) => Promise<void>;
    onEdit: (
        id: string,
        values: Values
    ) => (dispatch: AppDispatch) => Promise<void>;
}

export const FormFooter = <Values extends BaseItemFormValues>({
    onClose: onClosePromProps,
    onEdit,
    onCreate,
}: FormFooterProps<Values>) => {
    const {
        state: { mode },
    } = useEditableItemCard();
    const formMethods = useFormContext<Values>();
    const dispatch = useAppDispatch();
    const { switchToViewMode, switchToEditMode } = useEditableItemCard();

    const onClose = () => {
        if (mode === FormMode.edit) {
            switchToViewMode();
        } else if (mode === FormMode.create) {
            onClosePromProps?.();
        }
    };
    const onCancel = () => {
        onClose();
        formMethods.reset(undefined, {
            keepDefaultValues: true,
        });
    };

    const handleSubmit = formMethods.handleSubmit(async (values) => {
        // workaround fot react-hook-form issue with types
        const typedValues = values as Values;
        if (mode === FormMode.create) {
            await dispatch(onCreate(typedValues));
        } else if (mode === FormMode.edit) {
            if (typedValues.id) {
                await dispatch(onEdit(typedValues.id, typedValues));
            }
        }

        onClose();
    });
    return (
        <>
            {mode === FormMode.view && (
                <FlexRow>
                    <Button
                        icon={EditIcon}
                        caption="Edit"
                        fill="outline"
                        onClick={switchToEditMode}
                    />
                </FlexRow>
            )}
            {(mode === FormMode.edit || mode === FormMode.create) && (
                <FlexRow cx={css.footerButtonsRow}>
                    <Button
                        caption="Cancel"
                        fill="outline"
                        onClick={onCancel}
                    />
                    <Button
                        caption="Save"
                        color="primary"
                        onClick={handleSubmit}
                    />
                </FlexRow>
            )}
        </>
    );
};
