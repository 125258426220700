/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { EnvironmentTypes, environment } from 'constants/environments';
/**
 * Expo
 */
export const GA_TRACKING_IDS = {
    prod: 'G-8VHCN6E61F',
    test: 'G-54WHLQS84Y',
};

export const GTM_TRACKING_IDS = {
    prod: 'GTM-MQVBWCD',
    test: 'GTM-TGV967T',
};

export const isProduction = environment === EnvironmentTypes.prod;

/**
 * this delay is needed so we can send updated document.title https://github.com/nfl/react-helmet/issues/189
 */
export const GA_DELAY = 1000;
