/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { NamedTmDto, TAssetFootprint } from 'types';
import { ELEMENT_READABLE_ID_PREFIX } from './Element/constants';
import { ZodAssetFootprintArray, ZodNamedTmDto } from './constants';

export function transformElementViewValue(element: ZodNamedTmDto) {
    return `${ELEMENT_READABLE_ID_PREFIX}${element.readableId} ${element.name}`;
}

export const isEmptyField = (value: unknown) =>
    value === null ||
    value === undefined ||
    (typeof value === 'string' && value.trim().length === 0) ||
    (Array.isArray(value) && value.length === 0);

export const mapAssetFootprintsFormToPayload = (
    assetFootprints: ZodAssetFootprintArray
) =>
    assetFootprints.map((assetFootprint) => ({
        asset_id: assetFootprint.asset.id,
        action_on_asset: assetFootprint.actionOnAsset,
    }));

export const mapAssetFootprintsPayloadToForm = (
    assetFootprints: Array<TAssetFootprint>
) =>
    assetFootprints.map((assetFootprint) => ({
        id: assetFootprint.id,
        asset: {
            ...assetFootprint.asset,
            readableId: assetFootprint.asset.readable_id,
        },
        actionOnAsset: assetFootprint.action_on_asset,
    }));

export const mapNamedTmDtoToZodNamedTmDto = (
    namedTmDto: NamedTmDto | null
): ZodNamedTmDto | null => {
    return namedTmDto
        ? {
              id: namedTmDto.id,
              name: namedTmDto.name,
              readableId: namedTmDto.readable_id,
          }
        : null;
};
