import type { TActivitiesByStatus } from './types';
import type { DraggableLocation, DropResult } from 'react-beautiful-dnd';
import { TActivityStatus } from 'types';

type ReorderQuoteMapArgs = {
    list: TActivitiesByStatus;
    source: DraggableLocation;
    destination: DraggableLocation;
};

// a little function to help us with reordering the result
const reorder = (
    list: string[],
    startIndex: number,
    endIndex: number
): string[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
};

const reorderList = ({
    list,
    source,
    destination,
}: ReorderQuoteMapArgs): TActivitiesByStatus => {
    const current: string[] = [...list[source.droppableId as TActivityStatus]];
    const next: string[] = [
        ...list[destination.droppableId as TActivityStatus],
    ];
    const target: string = current[source.index];

    // moving to same list
    if (source.droppableId === destination.droppableId) {
        const reordered: string[] = reorder(
            current,
            source.index,
            destination.index
        );

        return {
            ...list,
            [source.droppableId]: reordered,
        };
    }

    // moving to different list

    // remove from original
    current.splice(source.index, 1);
    // insert into next
    next.splice(destination.index, 0, target);

    return {
        ...list,
        [source.droppableId]: current,
        [destination.droppableId]: next,
    };
};

export const getDestination = (
    result: DropResult
): DraggableLocation | null => {
    const { source, destination } = result;

    const { index: destinationIndex, droppableId: destinationDroppableId } =
        destination || {};

    const { index: sourceIndex, droppableId: sourceDroppableId } = source || {};

    // dropped nowhere
    if (!destination) {
        return null;
    }

    // did not move anywhere - can bail early
    if (
        sourceDroppableId === destinationDroppableId &&
        sourceIndex === destinationIndex
    ) {
        return null;
    }

    return destination;
};

export const getReordered = (
    result: DropResult,
    columns: TActivitiesByStatus
): TActivitiesByStatus | null => {
    const { source } = result;

    const destination = getDestination(result);

    if (!destination) return null;

    return reorderList({
        source,
        destination,
        list: columns,
    });
};
