/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import {
    TFormValues,
    TVulnerabilitiesFormValue,
} from 'components/Forms/Finding/types';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
/**
 * Utils
 */
import { pick, templateString } from 'utils';
import { TFinding } from 'types';
/**
 * Expo
 */
const getVulnerabilityIds = (
    vulnerabilities: TVulnerabilitiesFormValue[]
): string[] =>
    vulnerabilities
        .filter(({ cwe_type }) => cwe_type)
        .map(({ cwe_type: { id } }) => id);

export const createFinding = async (
    values: TFormValues,
    projectId: string
): Promise<void> => {
    const {
        images,
        activityId,
        assessmentId,
        vulnerabilities,
        asvsRequirements,
        removing_inline_images: removingInlineImages,
        ...formValues
    } = values;

    const vulnerabilityIds = getVulnerabilityIds(vulnerabilities);

    const formData = new FormData();
    const findingPayload = {
        ...formValues,
        project_id: projectId,
        activity_id: activityId,
        assessment_id: assessmentId,
        vulnerability_ids: vulnerabilityIds,
        asvs_requirements_ids: pick(asvsRequirements, 'shortCode'),
    };

    images?.forEach((image: File) => {
        formData.append('images', image, image.name);
    });

    formData.append('findingPayload', JSON.stringify(findingPayload));

    await api.request(Methods.post, {
        url: ENDPOINTS.findings,
        data: formData,
    });
};

export const editFinding = async (
    values: TFormValues,
    projectId: string,
    findingId: string,
    notify: boolean
): Promise<void> => {
    const {
        images,
        activityId,
        assessmentId,
        vulnerabilities: formVulnerabilities,
        attached_images: formAttachedImages,
        asvsRequirements,
        ...formValues
    } = values;
    const vulnerabilityIds = getVulnerabilityIds(formVulnerabilities);

    const formData = new FormData();
    const findingPayload = {
        ...formValues,
        project_id: projectId,
        activity_id: activityId,
        assessment_id: assessmentId,
        vulnerability_ids: vulnerabilityIds,
        asvs_requirements_ids: pick(asvsRequirements, 'shortCode'),
        notify,
    };

    images?.forEach((image: File) => {
        formData.append('addingImages', image, image.name);
    });

    formData.append('findingPayload', JSON.stringify(findingPayload));

    const url = templateString(ENDPOINTS.singleFinding, {
        findingId,
    });

    await api.request(Methods.put, {
        url,
        data: formData,
    });
};

export const generateFinding = async (
    values: TFormValues,
    vulnerabilityId: string
): Promise<string> => {
    const {
        images,
        tracker_issue_id: trackerIssueId,
        severity,
        description,
    } = values;

    const formData = new FormData();
    const findingPayload = {
        tracker_issue_id: trackerIssueId,
        vulnerability_id: vulnerabilityId,
        severity,
        description,
    };

    images?.forEach((image: File) => {
        formData.append('images', image, image.name);
    });

    formData.append('findingPayload', JSON.stringify(findingPayload));

    const { id } = await api.request<TFinding, FormData>(Methods.post, {
        url: ENDPOINTS.generateFinding,
        data: formData,
    });

    return id;
};
