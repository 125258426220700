/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
import { cx } from '@epam/uui-core';
/**
 * Components
 */
import { Text } from 'components/common';
import { ActivityCard } from '../ActivityCard';
import { FlexRow, FlexSpacer } from '@epam/promo';
import { Droppable, DroppableProvided } from 'react-beautiful-dnd';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { TColumnProps } from './types';
/**
 * Constants
 */
import { StatusTypes } from 'constants/statuses';
import { CAPTIONS, COLUMN_RAW_PROPS } from './constants';
/**
 * Expo
 */
export const Column: React.FC<TColumnProps> = ({ status, idList }) => {
    const statusClassName = cx(css.column, {
        [css.toDo]: status === StatusTypes.toDo,
        [css.done]: status === StatusTypes.done,
        [css.inProgress]: status === StatusTypes.inProgress,
    });

    return (
        <div className={statusClassName} {...COLUMN_RAW_PROPS}>
            <FlexRow cx={css.columnTitle}>
                <Text
                    fontSize="16"
                    cx={css.columnTitleText}
                    dataTestId="singleAssessment/boardColumn/title"
                >
                    {CAPTIONS[status]}
                </Text>
                <FlexSpacer />
                <Text
                    fontSize="16"
                    cx={css.columnTitleText}
                    dataTestId="singleAssessment/boardColumn/count"
                >
                    {idList.length}
                </Text>
            </FlexRow>

            <Droppable droppableId={status}>
                {(dropProvided: DroppableProvided) => (
                    <div
                        className={statusClassName}
                        ref={dropProvided.innerRef}
                        {...dropProvided.droppableProps}
                    >
                        {idList.map((id, index) => (
                            <ActivityCard key={id} id={id} index={index} />
                        ))}

                        {dropProvided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
};
