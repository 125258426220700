/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Expo
 */
export const DEFAULT_TEXT_SETTINGS = {
    '18': { lineHeight: 12, fontSize: 10 },
    '24': { lineHeight: 18, fontSize: 12 },
    '30': { lineHeight: 18, fontSize: 14 },
    '36': { lineHeight: 18, fontSize: 14 },
    '42': { lineHeight: 24, fontSize: 16 },
    '48': { lineHeight: 24, fontSize: 16 },
    '60': { lineHeight: 30, fontSize: 24 },
};
