/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { memo, useCallback, useMemo } from 'react';
/**
 * Components
 */
import { Checkbox as UuiCheckbox } from '@epam/uui';
/**
 * Utils
 */
import { getRawProps } from 'utils';
/**
 * Types
 */
import { TCheckboxProps } from './types';
/**
 * Expo
 */
export const Checkbox: React.FC<TCheckboxProps> = memo(
    ({
        label,
        isRequired,
        isInvalid,
        className,
        isDisabled,
        dataTestId,
        value = false,
        indeterminate,
        // TODO dont need it, no one usage for Checkbox and it's removed in new version of uui
        //  but TCheckboxProps inherited from FieldProps and still have it
        // validationMessage,
        renderLabel,
        onValueChange,
        onBlur,
    }) => {
        const rawProps = useMemo(
            () => getRawProps({ dataTestId, onBlur }),
            [dataTestId, onBlur]
        );

        const handleValueChange = useCallback(
            (changedValue: boolean) => {
                if (onValueChange) onValueChange(changedValue);
            },
            [onValueChange]
        );

        return (
            <UuiCheckbox
                label={label}
                value={value}
                cx={className}
                rawProps={rawProps}
                isInvalid={isInvalid}
                isRequired={isRequired}
                isDisabled={isDisabled}
                renderLabel={renderLabel}
                indeterminate={indeterminate}
                onValueChange={handleValueChange}
                id={`${dataTestId}/checkbox`}
            />
        );
    }
);
