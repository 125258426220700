/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { PAGES } from 'constants/pages';
import { ITab } from 'components/common/PageNavigation/types';

/**
 * Expo
 */
export enum Tabs {
    info = 'info',
    policies = 'policies',
    tasks = 'tasks',
    targets = 'targets',
    reports = 'reports',
    findings = 'findings',
    dashboard = 'dashboard',
    assessments = 'assessments',
    vulnerabilities = 'vulnerabilities',
    securityIssuesMenu = 'securityIssuesMenu',
    threatModeling = 'threat-modeling',
}

enum TabsCaption {
    tasks = 'Tasks',
    targets = 'Targets',
    reports = 'Reports',
    info = 'Project info',
    findings = 'Findings',
    dashboard = 'Dashboard',
    assessments = 'Assessments',
    vulnerabilities = 'Scanner Findings',
    policies = 'Policies',
    threatModeling = 'Threat Modeling',
}

export const TASKS_TAB = {
    id: Tabs.tasks,
    title: TabsCaption.tasks,
};

export const INFO_TAB = {
    id: Tabs.info,
    title: TabsCaption.info,
};

export const POLICIES_TAB = {
    id: Tabs.policies,
    title: TabsCaption.policies,
};

export const TARGETS_TAB = {
    id: Tabs.targets,
    title: TabsCaption.targets,
};

export const ASSESSMENTS_TAB = {
    id: Tabs.assessments,
    title: TabsCaption.assessments,
};

export const VULNERABILITIES_TAB = {
    id: Tabs.vulnerabilities,
    title: TabsCaption.vulnerabilities,
};

export const REPORTS_TAB = {
    id: Tabs.reports,
    title: TabsCaption.reports,
};

export const FINDINGS_TAB = {
    id: Tabs.findings,
    title: TabsCaption.findings,
};

export const FINDINGS_WITH_VULNERABILITIES = {
    id: Tabs.securityIssuesMenu,
    nestedMenu: [FINDINGS_TAB, VULNERABILITIES_TAB],
};

export const DASHBOARD_TAB = {
    id: Tabs.dashboard,
    title: TabsCaption.dashboard,
};

export const THREAT_MODELING_TAB = {
    id: Tabs.threatModeling,
    title: TabsCaption.threatModeling,
    href: PAGES.threatModeling,
};

export function mkThreatModelingTab(params: { activityId: string }): ITab {
    return {
        ...THREAT_MODELING_TAB,
        params,
    };
}
