/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useEffect } from 'react';
/**
 * Components
 */
import { PAGES } from 'constants/pages';
import { Button } from 'components/common';
import CreationModal from '../CreationModal';
import { RequestAssessmentModal } from 'components/modals';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Types
 */
import { TProps } from './types';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
import {
    useAppDispatch,
    useProjectType,
    useRedirect,
    useRole,
    useRouteParams,
} from 'hooks';
/**
 * Store
 */
import { selectIsIntegratedWithJira } from 'store/project';
import {
    handleGetAddAssessmentAllowed,
    selectAddAssessmentAllowed,
} from 'store/assessments';
/**
 * Assets
 */
import { ReactComponent as PlusIcon } from '@epam/assets/icons/action-add-outline.svg';
/**
 * Utils
 */
import { checkCanAddAssessment } from './utils';
import {
    NotificationTypes,
    showModal,
    showNotification,
    templateString,
} from 'utils';
/**
 * Types
 */
import { TFormValues } from '../CreationModal/types';

/**
 * Expo
 */
export const AddAssessmentButton: React.FC<TProps> = ({
    onLoadAssessments,
}) => {
    const dispatch = useAppDispatch();
    const redirect = useRedirect();
    const { isBasic } = useProjectType();
    const { isOperationsManager } = useRole();

    const { projectId } = useRouteParams();
    const addAssessmentAllowed = useSelector(selectAddAssessmentAllowed);
    const isIntegratedWithJira = useSelector(selectIsIntegratedWithJira);

    const handleCreateAssessment = useCallback(
        async ({ name, dates: { from, to } }: TFormValues) => {
            const url = templateString(ENDPOINTS.projectAssessments, {
                projectId,
            });

            await api.request(Methods.post, {
                url,
                data: {
                    name,
                    end_date: to,
                    start_date: from,
                    project_id: projectId,
                },
            });

            showNotification({
                text: `Assessment "${name}" added`,
                type: NotificationTypes.success,
            });

            onLoadAssessments();
        },
        [projectId, onLoadAssessments]
    );

    const checkIsCreationAllowed = useCallback(() => {
        const redirectUrl = isOperationsManager
            ? PAGES.targetsOperationManager
            : PAGES.targets;

        return checkCanAddAssessment({
            redirect,
            redirectUrl,
            isIntegratedWithJira,
            ...addAssessmentAllowed,
        });
    }, [
        redirect,
        isIntegratedWithJira,
        addAssessmentAllowed,
        isOperationsManager,
    ]);

    const handleCreate = useCallback(() => {
        const canAddAssessment = checkIsCreationAllowed();

        if (!canAddAssessment) return;

        if (isBasic) {
            redirect(PAGES.assessmentCreationOperationsManager);

            return;
        }

        showModal(CreationModal, { onSubmit: handleCreateAssessment });
    }, [isBasic, redirect, handleCreateAssessment, checkIsCreationAllowed]);

    const handleRequestAssessment = () => {
        showModal(RequestAssessmentModal, {});
    };

    useEffect(() => {
        dispatch(handleGetAddAssessmentAllowed(projectId));
    }, [dispatch, projectId]);

    return (
        <Button
            fill="outline"
            icon={PlusIcon}
            iconPosition="left"
            dataTestId="assessments/buttons/creation"
            caption={
                isOperationsManager
                    ? 'Add assessment'
                    : 'Request new assessment'
            }
            onClick={
                isOperationsManager ? handleCreate : handleRequestAssessment
            }
        />
    );
};
