/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
// moved from other dispatchers to avoid cyclomatic dependency
import { AppDispatch, TElement } from '../../../types';
import { saveElements, setElementsLoadingInProgress } from './slice';
import { createQueryUrl } from '../../../utils';
import { ENDPOINTS } from '../../../constants/api';
import { api } from '../../../services/api';
import { Methods } from '../../../constants/request';

export const handleElements =
    (tmId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            dispatch(setElementsLoadingInProgress(true));
            const url = createQueryUrl(ENDPOINTS.elements, {
                tmId,
            });
            const elements = await api.request<TElement[]>(Methods.get, {
                url,
            });
            dispatch(saveElements(elements));
        } finally {
            dispatch(setElementsLoadingInProgress(false));
        }
    };
