/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { SeverityTypes, ColorNames } from 'constants/index';
/**
 * Expo
 */
export const MAX_SEVERITY_VISIBLE_ITEMS = 1;

export const SEVERITY_COLOR_NAMES = {
    [SeverityTypes.low]: ColorNames.green,
    [SeverityTypes.high]: ColorNames.red,
    [SeverityTypes.medium]: ColorNames.orange,
    [SeverityTypes.critical]: ColorNames.purple,
    [SeverityTypes.informational]: ColorNames.blue,
};
