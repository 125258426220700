/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { PickerFooterProps } from '@epam/uui-core';
import { ArraySelect, TextInput, TextArea } from 'components/common';
import {
    useAppDispatch,
    useAppSelector,
    useSelectOptionsFromEnum,
} from 'hooks';
import { TEXT_AREA_ROWS, ZodNamedTmDto } from '../constants';
import { selectElementsOptionsByTrustBoundaryId } from 'store/threatModeling/element/selectors';
import { TmArraySelect } from '../common/TmArraySelect';
import { mapRiskLevelToLabel, transformTrustBoundaryViewValue } from './utils';
import { FC, memo, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
    handleCreateTrustBoundary,
    handleGetTypes,
    handleUpdateTrustBoundary,
} from 'store/threatModeling/trustBoundary/dispatchers';
import {
    selectTrustBoundariesCount,
    selectTrustBoundaryParentOptions,
    selectTrustBoundaryTypes,
} from 'store/threatModeling/trustBoundary/selectors';
import { AppDispatch, TNamed } from 'types';
import { FormFieldsProps } from '../../../../types';
import { createEditableItemCard } from '../../../createEditableItemCard';
import { TwoFieldsRow, FullFieldRow } from '../common';
import { createEditableField } from '../common/EditableField/EditableField';
import { SelectFooter } from '../common/multiSelectArrayFooter';
import { TRUST_BOUNDARY_READABLE_ID_PREFIX } from './constants';
import { trustBoundaryFormSchema, TrustBoundaryFormValues } from './schema';
import { LevelOfRisk } from 'types/threatModeling/trustBoundary';
import { transformElementViewValue } from '../utils';
import { createRenderRowWithAddTypeButton } from './renderTypePickerRow';
import { AddTypeButton } from './AddTypeButton';

const TrustBoundaryEditableField =
    createEditableField<TrustBoundaryFormValues>();

const TrustBoundaryFormFields: FC<
    FormFieldsProps<TrustBoundaryFormValues>
> = () => {
    const dispatch = useAppDispatch();

    const [id, tmId] = useWatch<TrustBoundaryFormValues>({
        name: ['id', 'tmId'],
    }) as [TrustBoundaryFormValues['id'], TrustBoundaryFormValues['tmId']];
    const elementOptions = useAppSelector(
        selectElementsOptionsByTrustBoundaryId(id ?? undefined)
    );
    const { setValue } = useFormContext<TrustBoundaryFormValues>();

    const parentOptions = useAppSelector((state) =>
        selectTrustBoundaryParentOptions(state, id)
    );

    const levelRiskOptions = useSelectOptionsFromEnum(
        LevelOfRisk,
        mapRiskLevelToLabel
    );

    const typeOptions = useAppSelector(selectTrustBoundaryTypes);

    useEffect(() => {
        dispatch(handleGetTypes(tmId));
    }, [dispatch, tmId]);

    // TODO: remove any and extract  to a common place
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const renderFooter = (props: PickerFooterProps<any, any>): JSX.Element => (
        <SelectFooter {...props} />
    );

    const addTypeButton = useMemo(() => {
        const onCreateTypeSuccess = (type: TrustBoundaryFormValues['type']) => {
            setValue('type', type);
        };

        return <AddTypeButton onSuccess={onCreateTypeSuccess} tmId={tmId} />;
    }, [setValue, tmId]);

    return (
        <>
            <TwoFieldsRow>
                <TrustBoundaryEditableField
                    name="readableId"
                    label="Trust boundary ID"
                    controllerComponent={TextInput}
                    controllerProps={{
                        placeholder: 'Enter a text',
                        isReadonly: true,
                    }}
                    dataTestId="trustBoundaryForm/readableId"
                />
                <TrustBoundaryEditableField
                    name="name"
                    label="Trust boundary name"
                    controllerComponent={TextInput}
                    controllerProps={{
                        placeholder: 'Enter a text',
                    }}
                    dataTestId="trustBoundaryForm/name"
                />
            </TwoFieldsRow>
            <TwoFieldsRow>
                <TrustBoundaryEditableField
                    name="type"
                    label="Type"
                    controllerComponent={ArraySelect<TNamed>}
                    controllerProps={{
                        placeholder: 'Please select',
                        valueType: 'entity',
                        labelKey: 'name',
                        options: typeOptions,
                        renderRow:
                            createRenderRowWithAddTypeButton(addTypeButton),
                        notFoundMessage: addTypeButton,
                    }}
                    transformValueForView={(value) => value?.name}
                    dataTestId="trustBoundaryForm/type"
                />
                <TrustBoundaryEditableField
                    name="levelOfRisk"
                    label="Level of risk"
                    controllerComponent={TmArraySelect}
                    controllerProps={{
                        placeholder: 'Please select',
                        labelKey: 'label',
                        options: levelRiskOptions,
                    }}
                    transformValueForView={(value) =>
                        value && mapRiskLevelToLabel(value)
                    }
                    dataTestId="trustBoundaryForm/levelOfRisk"
                />
            </TwoFieldsRow>
            <TwoFieldsRow>
                <TrustBoundaryEditableField
                    name="parent"
                    label="Parent trust boundary"
                    controllerComponent={ArraySelect<ZodNamedTmDto>}
                    controllerProps={{
                        placeholder: 'Please select',
                        valueType: 'entity',
                        options: parentOptions,
                        getName: transformTrustBoundaryViewValue,
                    }}
                    transformValueForView={(value) =>
                        value && transformTrustBoundaryViewValue(value)
                    }
                    dataTestId="trustBoundaryForm/parent"
                />
                <TrustBoundaryEditableField
                    name="elements"
                    label="Elements"
                    controllerComponent={ArraySelect<ZodNamedTmDto>}
                    controllerProps={{
                        placeholder: 'Please select',
                        info: 'You cannot select Elements, that have Trust boundaries.',
                        labelKey: 'name',
                        valueType: 'entity',
                        options: elementOptions,
                        disableClear: true,
                        mode: 'multi',
                        searchPosition: 'body',
                        idKey: 'id',
                        renderFooter,
                        maxItems: 3,
                        sorting: { field: 'name', direction: 'asc' },
                        getName: transformElementViewValue,
                    }}
                    transformValueForView={(elements) =>
                        elements?.map(transformElementViewValue)
                    }
                    dataTestId="trustBoundaryForm/elements"
                />
            </TwoFieldsRow>
            <FullFieldRow>
                <TrustBoundaryEditableField
                    name="description"
                    label="Description"
                    controllerComponent={TextArea}
                    controllerProps={{
                        placeholder: 'Enter a text',
                        rows: TEXT_AREA_ROWS,
                    }}
                    dataTestId="interactionForm/description"
                />
            </FullFieldRow>
        </>
    );
};

export const TrustBoundaryItemCard = memo(
    createEditableItemCard(TrustBoundaryFormFields, {
        formSchema: trustBoundaryFormSchema,
        onCreate: (values) => {
            return async (dispatch: AppDispatch) => {
                await dispatch(handleCreateTrustBoundary(values));
            };
        },
        onEdit: (id, values) => {
            return async (dispatch: AppDispatch) => {
                await dispatch(handleUpdateTrustBoundary(id, values));
            };
        },
        readableIdPrefix: TRUST_BOUNDARY_READABLE_ID_PREFIX,
        countSelector: selectTrustBoundariesCount,
    })
);

TrustBoundaryItemCard.displayName = 'TrustBoundaryItemCard';
