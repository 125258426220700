/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, useState, useEffect } from 'react';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { TOnMouseMove, TResizeBoxProps } from './types';
/**
 * Utils
 */
import { preventDrag, calculateAspectRatio } from './utils';
/**
 * Expo
 */
export const ResizeBox: FC<TResizeBoxProps> = ({
    src,
    size,
    containerRef,
    onChangeSize,
}) => {
    const [sizes, setSize] = useState(size);
    const [isClicked, setIsClicked] = useState(false);
    const [isMouseMove, setIsMouseMove] = useState(false);

    useEffect(() => {
        if (isMouseMove || !sizes.width || !sizes.height) return;

        onChangeSize(sizes);
    }, [isMouseMove, sizes, onChangeSize]);

    const handleResizeEvent = (onMouseMove: TOnMouseMove) => {
        setIsMouseMove(true);

        const onMouseUp = () => {
            setIsMouseMove(false);

            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    const topLeftHandler = () => {
        handleResizeEvent(({ movementX, movementY }) => {
            setSize(({ width, height }) =>
                calculateAspectRatio(width, height, -movementX, -movementY)
            );
        });
    };

    const topRightHandler = () => {
        handleResizeEvent(({ movementX, movementY }) => {
            setSize(({ width, height }) =>
                calculateAspectRatio(width, height, movementX, -movementY)
            );
        });
    };

    const bottomRightHandler = () => {
        handleResizeEvent(({ movementX, movementY }) => {
            setSize(({ width, height }) =>
                calculateAspectRatio(width, height, movementX, movementY)
            );
        });
    };

    const bottomLeftHandler = () => {
        handleResizeEvent(({ movementX, movementY }) => {
            setSize(({ width, height }) =>
                calculateAspectRatio(width, height, -movementX, movementY)
            );
        });
    };

    useEffect(() => {
        const { current: containerElement } = containerRef;

        if (!containerElement) return undefined;

        const onMouseDown = ({ target }: MouseEvent) => {
            if (target instanceof HTMLElement) {
                const isContains = containerElement.contains(target);

                setIsClicked(isContains);
            }
        };

        document.addEventListener('mousedown', onMouseDown);

        return () => {
            document.removeEventListener('mousedown', onMouseDown);
        };
    }, [containerRef]);

    if (!isClicked) return null;

    return (
        <>
            <div
                style={sizes}
                onDragStart={preventDrag}
                className={css.resizeContainer}
            >
                <div
                    role="none"
                    onMouseDown={topLeftHandler}
                    className={css.resizeButtonTopLeft}
                />
                <div
                    role="none"
                    onMouseDown={topRightHandler}
                    className={css.resizeButtonTopRight}
                />
                <div
                    role="none"
                    onMouseDown={bottomLeftHandler}
                    className={css.resizeButtonBottomLeft}
                />
                <div
                    role="none"
                    onMouseDown={bottomRightHandler}
                    className={css.resizeButtonBottomRight}
                />
                {isMouseMove && (
                    <img
                        src={src}
                        alt="resize placeholder"
                        className={css.placeholderImage}
                    />
                )}
            </div>
        </>
    );
};
