/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
import { FormProvider } from 'react-hook-form';
/**
 * Hooks
 */
import { useAppForm } from 'hooks';
/**
 * Components
 */
import { FieldController } from 'components/Fields';
import { Button, TextArea } from 'components/common';
import { FlexRow, FlexCell, Panel, ModalFooter, FlexSpacer } from '@epam/promo';
/**
 * Types
 */
import { FormProps } from './types';
import { TTextAreaProps } from 'components/common/TextArea/types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const Form: React.FC<FormProps> = ({
    onClose,
    onSubmit,
    description,
}) => {
    const methods = useAppForm({
        id: 'projectDescriptionForm',
        defaultValues: { description },
    });

    const handleSubmit = methods.handleSubmit(async (values) => {
        await onSubmit(values);

        onClose();
    });

    return (
        <>
            <Panel cx={css.formContainer}>
                <FormProvider {...methods}>
                    <FlexRow>
                        <FlexCell grow={1}>
                            <FieldController<TTextAreaProps>
                                isRequired
                                rows={11}
                                maxLength={500}
                                name="description"
                                label="Description"
                                component={TextArea}
                            />
                        </FlexCell>
                    </FlexRow>
                </FormProvider>
            </Panel>
            <ModalFooter borderTop>
                <FlexSpacer />
                <Button
                    caption="Cancel"
                    onClick={onClose}
                    fill="none"
                    color="neutral"
                />
                <Button
                    color="primary"
                    caption="Submit"
                    onClick={handleSubmit}
                />
            </ModalFooter>
        </>
    );
};
