/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useState, useMemo, useCallback } from 'react';
/**
 * Components
 */
import { DataTable } from '@epam/promo';
import { cx, DataColumnProps, DataRowOptions } from '@epam/uui-core';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { DefaultTableProps, TablePropsId } from './types';
/**
 * Utils
 */
import { getRawProps } from 'utils';
/**
 * Expo
 */
export function DefaultTable<T>({
    dataSource,
    dataTestId,
    tableClassName,
    withoutPaddings,
    getColumns,
    onRowClick,
}: DefaultTableProps<T>): JSX.Element {
    const [value, onValueChange] = useState({});
    const rowOptions: DataRowOptions<T, TablePropsId> = {};

    if (onRowClick) {
        rowOptions.onClick = onRowClick;
    }

    const handleReload = useCallback(() => {
        dataSource.reload?.();
    }, [dataSource]);

    const columns: DataColumnProps<T>[] = useMemo(
        () =>
            getColumns({ reload: handleReload }).map((column) => ({
                ...column,
                cx: cx(column.cx, withoutPaddings && 'reduce-padding'),
            })),
        [getColumns, handleReload, withoutPaddings]
    );

    const view = dataSource.useView(value, onValueChange, {
        rowOptions,
    });

    const rawProps = useMemo(() => getRawProps({ dataTestId }), [dataTestId]);

    return (
        <div className={css.container}>
            <div {...rawProps} className={cx(css.table, tableClassName)}>
                <DataTable
                    {...view.getListProps()}
                    value={value}
                    border={false}
                    columns={columns}
                    getRows={view.getVisibleRows}
                    onValueChange={onValueChange}
                />
            </div>
        </div>
    );
}
