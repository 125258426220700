/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useState } from 'react';
/**
 * Components
 */
import { Button, TextInput } from 'components/common/index';
import { FlexRow, FlexSpacer, ModalFooter } from '@epam/promo';
/**
 * Types
 */
import { FormProps } from './types';
/**
 * Constants
 */
import { PATTERNS } from 'constants/validation';
/**
 * Expo
 */

const checkIsValidUrl = (url: string) => PATTERNS.url.test(url);
export const Form: React.FC<FormProps> = ({
    close,
    onSubmit,
    initialValue = '',
}) => {
    const [link, changeLink] = useState<string>(initialValue);
    const [isInvalid, setIsInvalid] = useState<boolean>(false);

    const handleSave = useCallback(() => {
        if (!checkIsValidUrl(link)) {
            setIsInvalid(true);
            return;
        }
        onSubmit(link);

        close();
    }, [onSubmit, link, close]);

    return (
        <>
            <FlexRow padding="12" vPadding="24">
                <TextInput
                    isRequired
                    validationMessage="Link is invalid"
                    isInvalid={isInvalid}
                    value={link}
                    label="Link"
                    placeholder="e.g. https://epamsecuritycenter.com"
                    onValueChange={changeLink}
                    dataTestId="creatingFinding/description/addLinkButton"
                />
            </FlexRow>
            <ModalFooter borderTop>
                <FlexSpacer />
                <FlexRow columnGap="6">
                    <Button
                        dataTestId="creatingFinding/description/cancelButton"
                        fill="none"
                        caption="Cancel"
                        onClick={close}
                    />
                    <Button
                        dataTestId="creatingFinding/description/submitButton"
                        caption="Save"
                        onClick={handleSave}
                    />
                </FlexRow>
            </ModalFooter>
        </>
    );
};
