/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { FlexRow, Panel, Text } from 'components/common';
import { FindingSeverityTable } from './FindingSeverityTable';
/**
 * Utils
 */
import { cx } from '@epam/uui-core';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const Approach: React.FC = () => (
    <Panel cx={cx(css.formPaddings, css.container, css.richText)}>
        <FlexRow>
            <Text fontSize="14" lineHeight="24">
                All assessment observations and recommendations made during the
                project have received one of the following risk classification
                rankings. The rankings are made based on both objective and
                subjective assessment by the EPAM Security Team and indicate
                both the significance and likelihood of business risk posed to
                the client. The results may be used by management and technical
                staff to prioritize remediation and corrective measures.
            </Text>
        </FlexRow>
        <FlexRow>
            <Text cx={css.title} lineHeight="24">
                EPAM Security Assessment Vulnerability Risk Classification Scale
            </Text>
        </FlexRow>
        <FindingSeverityTable />
    </Panel>
);
