/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { THttpError } from 'types';
import { TAuthResponse, TAuthReturnValue, TRestParams } from './types';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
import { Methods } from 'constants/request';
import { ApiStatusCodes } from 'constants/api';
import { DEFAULT_API_HEADERS } from '../constants';
/**
 * Utils
 */
import { getBaseAuthUrl } from 'utils';
/**
 * Services
 */
import { request } from 'services/api/request';
/**
 * Expo
 */
export const requestAuth = async <TData = undefined>(
    method: Methods,
    params: TRestParams<TData>
): Promise<TAuthReturnValue> => {
    try {
        const {
            data: {
                payload: {
                    url: redirectUrl,
                    id_token: idToken,
                    challenge: nextAuthStep,
                    session_key: sessionKey,
                    refresh_token: refreshToken,
                },
            },
        } = await request<TAuthResponse, TData>(method, {
            ...params,
            baseURL: getBaseAuthUrl(),
            headers: DEFAULT_API_HEADERS,
        });

        return { idToken, nextAuthStep, refreshToken, redirectUrl, sessionKey };
    } catch (error) {
        const {
            response: {
                status,
                data: { messages },
            },
        } = <THttpError>error;

        if (status === ApiStatusCodes.forbidden) {
            window.location.replace(PAGES.blockedUser);
        }

        return Promise.reject(messages);
    }
};
