/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { call, put, takeEvery } from 'redux-saga/effects';
/**
 * Actions
 */
import { getProjectPolicyList, saveList, setInProgress } from './actions';
/**
 * Utils
 */
import { getProjectPoliciesList } from './utils';
/**
 * Types
 */
import { PayloadAction } from '@reduxjs/toolkit';
/**
 * Expo
 */
function* handleGetProjectPolicyList({ payload }: PayloadAction<string>) {
    yield put(setInProgress(true));

    const { data } = yield call(getProjectPoliciesList, payload);

    yield put(saveList(data));
    yield put(setInProgress(false));
}

export default function* sagas(): Generator {
    yield takeEvery(getProjectPolicyList.type, handleGetProjectPolicyList);
}
