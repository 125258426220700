/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { scriptIdByEnvironment } from './constants';
import './style.scss';

export const CookiesBanner: React.FC = () => {
    return (
        <Helmet>
            <script
                src={`https://cookie-cdn.cookiepro.com/consent/${scriptIdByEnvironment}/OtAutoBlock.js`}
            />
            <script
                data-domain-script={scriptIdByEnvironment}
                src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
            />
        </Helmet>
    );
};
