/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Expo
 */
export const FEATURE_FLAGS = {
    configureReportSelectContent: '2800',
    helpGuide: '1419-help-guide',
    notifications: '948-view-notifications',
    integrationWithAzure: '3693',
    remediationPeriodBySeverityPolicy: '1749',
    viewRequestProgressOperationsManager: '3503',
    viewNotificationsPageFlag: '953-view-notifications-page',
    addProjectWizard: '4329',
    hidePlatformSwitcherProjectRepresentative: '4889',
    asvs: '4668',
    editAssessment: '4854',
    asvsPolicy: '4765',
};

export const LOCAL_FEATURE_FLAGS = {
    testGA: '4417',
};
