/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { forwardRef, useCallback } from 'react';
import { TabButton as UuiTabButton } from '@epam/promo';
import { TabButtonProps } from './types';
import { useLinkClickHandler } from 'react-router-dom';

export const TabButton = forwardRef((props: TabButtonProps) => {
    const {
        href,
        target,
        onClick,
        dataTestId,
        type = 'button',
        rawProps: rawPropsFromProps,
        ...rest
    } = props;

    const internalOnClick = useLinkClickHandler(href || '');

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            if (onClick) {
                onClick(event);
            }

            if (href && target !== '_blank') {
                internalOnClick(event);
            }
        },
        [onClick, internalOnClick, href, target]
    );
    const rawPropsWithType = { ...rawPropsFromProps, type };

    return (
        <UuiTabButton
            {...rest}
            size="60"
            href={href}
            rawProps={rawPropsWithType}
            onClick={handleClick}
        />
    );
});
