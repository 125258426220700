/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, {
    useRef,
    useState,
    useEffect,
    useCallback,
    MouseEvent,
} from 'react';
import { cx } from '@epam/uui-core';
/**
 * Hooks
 */
import { useOnMount } from 'hooks';
/**
 * Components
 */
import { Panel } from 'components/common';
/**
 * Types
 */
import { PageHeaderProps } from './types';
/**
 * Utils
 */
import { getHeaderHeight } from './utils';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const PageHeader: React.FC<PageHeaderProps> = ({
    renderHeader,
    renderBackTo,
}) => {
    const [topPadding, setTopPadding] = useState(0);
    const [isMouseOver, setIsMouseOver] = useState(false);
    const [isScrolledUnderHeader, setIsScrolledUnderHeader] = useState(false);
    const [rteToolbarSize, setRteToolbarSize] = useState<
        [number, number] | null
    >(null);

    const headerRef = useRef<HTMLDivElement>(null);
    const topBorderRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const isSmallScreen = window.screen.height < 1000;

    const headerHeight = getHeaderHeight(headerRef);

    useOnMount(() => {
        if (!topBorderRef.current || !containerRef.current) return;

        /**
         * Need to use calculation with document.body as page may be loaded with initial scroll
         * If page was loaded with initial scroll - topBorder element would has wrong top value
         * So to avoid unexpected numbers - we have to avoid using top field of topBorder as it is
         */
        const { top: bodyTopOffset } = document.body.getBoundingClientRect();
        const { height: containerHeight } =
            containerRef.current.getBoundingClientRect();
        const { top: elementTopOffset } =
            topBorderRef.current.getBoundingClientRect();

        const topOffset = elementTopOffset - bodyTopOffset;

        // 60px bottom blue panel
        const offset = topOffset + containerHeight + 60;

        const rtePanel = document.querySelector('.rte-toolbar');

        if (rtePanel) {
            const { x, width } = rtePanel.getBoundingClientRect();
            setRteToolbarSize([x, x + width]);
        }

        setTopPadding(offset);
        setIsScrolledUnderHeader(window.scrollY > offset);
    });

    const scrollHandler = useCallback(() => {
        if (window.scrollY > topPadding && !isScrolledUnderHeader) {
            setIsScrolledUnderHeader(true);
        }

        if (window.scrollY < topPadding && isScrolledUnderHeader) {
            setIsScrolledUnderHeader(false);
        }
    }, [topPadding, isScrolledUnderHeader]);

    const mouseEnterHandler = useCallback(
        (event: Event) => {
            let isOver = isScrolledUnderHeader;

            if (rteToolbarSize) {
                const [x, x2] = rteToolbarSize;
                const { clientX } = event as unknown as MouseEvent;
                isOver = clientX < x || clientX > x2;
            }

            if (isOver) {
                setIsMouseOver(true);
            }
        },
        [isScrolledUnderHeader, rteToolbarSize]
    );

    const mouseLeaveHandler = useCallback(() => {
        if (isScrolledUnderHeader) {
            setIsMouseOver(false);
        }
    }, [isScrolledUnderHeader]);

    useEffect(() => {
        if (!isSmallScreen) return undefined;

        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, [scrollHandler, isSmallScreen]);

    useEffect(() => {
        const { current } = containerRef;

        if (!current || !isSmallScreen) {
            return undefined;
        }

        current.addEventListener('mouseenter', mouseEnterHandler);
        current.addEventListener('mouseleave', mouseLeaveHandler);

        return () => {
            current.removeEventListener('mouseenter', mouseEnterHandler);
            current.removeEventListener('mouseleave', mouseLeaveHandler);
        };
    }, [mouseEnterHandler, mouseLeaveHandler, containerRef, isSmallScreen]);

    const getContainerStyles = () => {
        if (!isSmallScreen) return {};

        return { top: isMouseOver ? 0 : -headerHeight };
    };

    const containerClassNames = cx({
        [css.stickyPositionHeader]: !isSmallScreen,
        [css.fixedPositionHeader]: isSmallScreen && isScrolledUnderHeader,
    });

    return (
        <>
            <div ref={topBorderRef} />
            <div
                ref={containerRef}
                style={getContainerStyles()}
                className={containerClassNames}
                id="page-header-container"
            >
                <div ref={headerRef}>
                    <Panel
                        cx={cx(
                            css.pageHeader,
                            css.pageHeader_blue,
                            renderBackTo && css.pageHeader_small
                        )}
                    >
                        {renderBackTo?.()}
                        {renderHeader()}
                    </Panel>
                </div>
                {isSmallScreen && isScrolledUnderHeader && (
                    <div className={css.bottomTrigger} />
                )}
            </div>
            {isSmallScreen && isScrolledUnderHeader && (
                <div style={{ height: headerHeight }} />
            )}
        </>
    );
};
