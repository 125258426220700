/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useRef } from 'react';
/**
 * Assets
 */
import css from '../style.module.scss';
/**
 * Hooks
 */
import { useLocation } from 'react-router-dom';
import { useHover } from 'hooks';
/**
 * Components
 */
import { LinkButton, Panel, Text } from 'components/common';
/**
 * Constants
 */
import { PlatformTypes } from 'constants/system';
/**
 * Types
 */
import { TSecurityCenterProps } from '../type';
/**
 * Utils
 */
import { definePlatformType } from 'utils/system';
import { cx } from '@epam/uui-core';
/**
 * Expo
 */
export const AgileSecurityPlatform: React.FC<TSecurityCenterProps> = ({
    onClick,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    const isHovered = useHover(ref);

    const handleClick = useCallback(() => {
        onClick(PlatformTypes.agileSecurityPlatform);
    }, [onClick]);

    const { pathname } = useLocation();

    const platform = definePlatformType(pathname);

    const isAgileSecurityPlatform =
        platform === PlatformTypes.agileSecurityPlatform;

    const iconPath = isAgileSecurityPlatform
        ? '/assets/icons/aspActive.svg'
        : '/assets/icons/aspIcon.svg';

    return (
        <div
            ref={ref}
            onClick={handleClick}
            aria-hidden
            className={cx(css.container, isHovered && css.hovered)}
        >
            <img
                className={css.icon}
                src={iconPath}
                alt="Agile Security Platform"
            />
            <Panel cx={css.panelContent}>
                <LinkButton
                    caption="Agile Security Platform"
                    cx={css.linkButton}
                />
                <Text
                    cx={css.textPadding}
                    lineHeight="18"
                    fontSize="12"
                    color="gray60"
                >
                    A platform to coordinate your security activities and manage
                    your vulnerabilities.
                </Text>
            </Panel>
        </div>
    );
};
