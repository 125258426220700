/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Hooks
 */
import { useRole, useRouteParams } from 'hooks';
/**
 * Components
 */
import { LinkButton } from 'components/common';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
/**
 * Utils
 */
import { generatePath } from 'utils';
/**
 * Expo
 */
export const NameCell: React.FC<{ id: string; name: string }> = ({
    id,
    name,
}) => {
    const { projectId } = useRouteParams();
    const { isOperationsManager } = useRole();

    const redirectPath = isOperationsManager
        ? PAGES.singleAssessmentOperationsManager
        : PAGES.singleAssessment;

    const href = generatePath(redirectPath, {
        projectId,
        assessmentId: id,
    });

    return (
        <LinkButton
            href={href}
            caption={name}
            dataTestId="assessments-cells-name"
        />
    );
};
