/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { ExtendNavigator, Transition } from './types';

/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 * `useBlocker` and `usePrompt` were temporarily removed from React Router after 6.0.0 beta. This is a reimplementation.
 *
 * Source: https://gist.github.com/rmorse/426ffcc579922a82749934826fa9f743
 *
 * Issue: https://github.com/remix-run/react-router/issues/8139
 * @see https://reactrouter.com/api/useBlocker
 */
export function useBlocker(blocker: (tx: Transition) => void, when = true) {
    const { navigator } = useContext(NavigationContext);

    useEffect(() => {
        if (!when) return;

        const unblock = (navigator as unknown as ExtendNavigator).block(
            (tx) => {
                const autoUnblockingTx = {
                    ...tx,
                    retry() {
                        unblock();
                        tx.retry();
                    },
                };

                blocker(autoUnblockingTx);
            }
        );

        // eslint-disable-next-line consistent-return
        return unblock;
    }, [navigator, blocker, when]);
}
