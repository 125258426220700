/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { ScrollBars } from '@epam/promo';
import { FieldDecorator } from './FieldDecorator';
import { FieldController } from 'components/Fields';
import { AsyncSelect, Panel, TextArea, TextInput } from 'components/common';
import { AssessmentForReport } from 'components/AssessmentForReports';
/**
 * Utils
 */
import { cx } from '@epam/uui-core';
import { templateString } from 'utils';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { TTextAreaProps } from 'components/common/TextArea/types';
import { TTextInputProps } from 'components/common/TextInput/types';
import { TAssessmentForReportsProps } from 'components/AssessmentForReports/types';
import { AsyncSelectProps } from 'components/common/Select/types';
import { TActivityReference } from 'types';
/**
 * Hooks
 */
import { useRouteMatchParams } from 'hooks';
import { useFormContext } from 'react-hook-form';
/**
 * Constants
 */
import { ReportsNavigationType } from 'constants/reports';
import { ENDPOINTS } from 'constants/api';
import { PAGES } from 'constants/pages';
/**
 * Expo
 */

export const General: React.FC = () => {
    const { reportType } = useRouteMatchParams(PAGES.reportWithType);
    const isPdfReport = reportType === ReportsNavigationType.pdf;

    const { watch } = useFormContext();

    const assessmentId = watch('assessmentId');

    const url = templateString(ENDPOINTS.assessmentActivities, {
        assessmentId,
    });

    return (
        <ScrollBars>
            <Panel cx={cx(css.formPaddings, css.halfWidth)}>
                <FieldDecorator>
                    <FieldController<TTextInputProps>
                        isRequired
                        name="name"
                        label="Report name"
                        component={TextInput}
                        placeholder="Ex: Threat modelling"
                        dataTestId="configureReport/general/fields/name"
                    />
                </FieldDecorator>
                <FieldDecorator>
                    <FieldController<TAssessmentForReportsProps>
                        isRequired
                        name="assessmentId"
                        component={AssessmentForReport}
                        dataTestId="configureReport/general/fields/assessment"
                    />
                </FieldDecorator>
                <FieldDecorator>
                    <FieldController<TTextInputProps>
                        component={TextInput}
                        isRequired
                        name="organizationName"
                        label={
                            isPdfReport
                                ? 'Organization name'
                                : 'Customer/Company name'
                        }
                        dataTestId="configureReport/general/fields/companyName"
                    />
                </FieldDecorator>
                {isPdfReport && (
                    <FieldDecorator>
                        <FieldController<AsyncSelectProps<TActivityReference>>
                            valueType="id"
                            labelKey="name"
                            url={url}
                            component={AsyncSelect}
                            isRequired
                            name="activityId"
                            label="Activity name"
                            dataTestId="configureReport/general/fields/activityName"
                        />
                    </FieldDecorator>
                )}
                {!isPdfReport && (
                    <>
                        <FieldDecorator>
                            <FieldController<TTextInputProps>
                                component={TextInput}
                                isRequired
                                name="projectName"
                                label="Project name"
                                dataTestId="configureReport/general/fields/projectName"
                            />
                        </FieldDecorator>
                        <FieldDecorator>
                            <FieldController<TTextAreaProps>
                                component={TextArea}
                                rows={3}
                                isOptional
                                maxLength={300}
                                name="description"
                                label="Description"
                                dataTestId="configureReport/general/fields/description"
                            />
                        </FieldDecorator>
                    </>
                )}
            </Panel>
        </ScrollBars>
    );
};
