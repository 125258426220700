/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { BadgeProps } from '@epam/promo';
/**
 * Constants
 */
// TODO: fix import, I got some error when tried to remove index from path
import { ColorNames } from 'constants/index';
import { ReportState, ReportTypes } from 'constants/reports';

/**
 * Expo
 */
export const REPORT_TYPES_WITH_SIZE = [
    ReportTypes.withFiles,
    ReportTypes.pdf,
    ReportTypes.docx,
];

export const STATE_BADGE_COLOR: Record<ReportState, BadgeProps['color']> = {
    [ReportState.draft]: ColorNames.gray30,
    [ReportState.published]: ColorNames.green,
};

export const STATE_BADGE_TEXT = {
    [ReportState.draft]: 'Draft',
    [ReportState.published]: 'Published',
};

export const EXTENSION_BY_REPORT_TYPE = {
    [ReportTypes.pdf]: 'pdf',
    [ReportTypes.docx]: 'docx',
};

export const EXTENSION_NAME_BY_REPORT_TYPE = {
    [ReportTypes.pdf]: 'PDF',
    [ReportTypes.docx]: 'DOC',
};
