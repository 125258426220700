/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { Text, Button } from 'components/common';
import css from './style.module.scss';
import { FC } from 'react';
import { ReactComponent as PlusIcon } from '@epam/assets/icons/action-add-outline.svg';

export const EmptyView: FC = () => (
    <div className={css.emptyTableView}>
        <Text color="gray60" lineHeight="24">
            No threats. Add threats to display them here.
        </Text>
        <img
            src="/assets/images/emptyViews/noTasks.svg"
            alt="no data"
            width="82"
            height="60"
        />
        <Button
            fill="none"
            color="primary"
            caption="Add threat"
            icon={PlusIcon}
            onClick={() => null}
        />
    </div>
);
