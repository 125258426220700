/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createSlice } from '@reduxjs/toolkit';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
import { DEFAULT_TOP_FINDINGS_COUNT, INITIAL_STATE } from './constants';
/**
 * Utils
 */
import {
    getFindingsForChartBySeverity,
    getFindingsForChartByStatuses,
} from './utils';
import { createQueryUrl, templateString } from 'utils';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Types
 */
import { TFindingForChartByType, TFindingComparison } from './types';
import {
    AppDispatch,
    TActivity,
    TFindingShort,
    TSelector,
    TPolicyViolationWarning,
    TAssessment,
    TGetState,
} from 'types';
/**
 * Reducer
 */
const { reducer, actions } = createSlice({
    name: 'dashboard',
    initialState: INITIAL_STATE,
    reducers: {
        saveFindingsSummary: (
            state,
            {
                payload: {
                    total,
                    details,
                    summaries_by_statuses,
                    summaries_by_severity,
                },
            }
        ) => {
            state.findings.total = total;
            state.findings.comparison = details;
            state.findings.chartData.statuses = getFindingsForChartByStatuses(
                summaries_by_statuses
            );
            state.findings.chartData.severities = getFindingsForChartBySeverity(
                summaries_by_severity
            );
        },
        saveTopFindings: (state, { payload }) => {
            state.findings.list = payload;
        },
        saveAssessmentsList: (state, { payload }) => {
            state.assessments.list = payload;
        },
        saveSelectedAssessment: (state, { payload }) => {
            state.assessments.selectedAssessment = payload;
        },
        savePolicyViolationWarnings: (state, { payload }) => {
            state.violatedPolicies = payload;
        },
        saveBlockedActivities: (state, { payload }) => {
            state.blockedActivities = payload;
        },
        setInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        setIsInitialized: (state, { payload }) => {
            state.isInitialized = payload;
        },
        resetData: () => INITIAL_STATE,
    },
});

export default reducer;
/**
 * Selectors
 */
export const selectTotal: TSelector<number> = ({
    dashboard: {
        findings: { total },
    },
}) => total;
export const selectFindingsForChart: TSelector<TFindingForChartByType> = ({
    dashboard: {
        findings: { chartData },
    },
}) => chartData;
export const selectTopFindings: TSelector<TFindingShort[]> = ({
    dashboard: {
        findings: { list },
    },
}) => list;
export const selectFindingsComparison: TSelector<TFindingComparison[]> = ({
    dashboard: {
        findings: { comparison },
    },
}) => comparison;
export const selectBlockedActivities: TSelector<TActivity[]> = ({
    dashboard: { blockedActivities },
}) => blockedActivities;
export const selectViolatedPolicies: TSelector<TPolicyViolationWarning[]> = ({
    dashboard: { violatedPolicies },
}) => violatedPolicies;
export const selectAssessments: TSelector<TAssessment[]> = ({
    dashboard: {
        assessments: { list },
    },
}) => list;
export const selectSelectedAssessment: TSelector<string | undefined> = ({
    dashboard: {
        assessments: { selectedAssessment },
    },
}) => selectedAssessment;
export const selectInProgress: TSelector<boolean> = ({
    dashboard: { inProgress },
}) => inProgress;
export const selectIsInitialized: TSelector<boolean> = ({
    dashboard: { isInitialized },
}) => isInitialized;
/**
 * Actions
 */
export const {
    resetData,
    setInProgress,
    saveTopFindings,
    setIsInitialized,
    saveFindingsSummary,
    saveBlockedActivities,
    savePolicyViolationWarnings,
    saveSelectedAssessment,
    saveAssessmentsList,
} = actions;
/**
 * Dispatchers
 */
const handleGetFindingsBySeverity =
    () =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const assessmentId = selectSelectedAssessment(getState());

        if (!assessmentId) return;

        const url = createQueryUrl(ENDPOINTS.dashboardFindingsSummary, {
            assessmentId,
        });

        const response = await api.request(Methods.get, { url });

        dispatch(saveFindingsSummary(response));
    };

const handleGetTopFindings =
    () =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const assessmentId = selectSelectedAssessment(getState());

        if (!assessmentId) return;

        const url = templateString(ENDPOINTS.topFindings, {
            assessmentId,
        });

        const queryUrl = createQueryUrl(url, {
            limit: DEFAULT_TOP_FINDINGS_COUNT,
        });

        const response = await api.request(Methods.get, { url: queryUrl });

        dispatch(saveTopFindings(response));
    };

const handleGetBlockedActivities =
    () =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const assessmentId = selectSelectedAssessment(getState());

        if (!assessmentId) return;

        const url = templateString(ENDPOINTS.assessmentActivities, {
            assessmentId,
        });

        const queryUrl = createQueryUrl(url, { blocked: true });

        const response = await api.request(Methods.get, { url: queryUrl });

        dispatch(saveBlockedActivities(response));
    };

const handleGetPolicyViolationWarnings =
    () =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const assessmentId = selectSelectedAssessment(getState());

        if (!assessmentId) return;

        const url = templateString(ENDPOINTS.dashboardViolationWarnings, {
            assessmentId,
        });

        const response = await api.request(Methods.get, { url });

        dispatch(savePolicyViolationWarnings(response));
    };
export const handleGetAssessmentsForSelect =
    () =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const {
            user: {
                data: { currentProject },
            },
        } = getState();

        dispatch(setInProgress(true));

        const url = createQueryUrl(ENDPOINTS.assessmentsForDashboard, {
            projectId: currentProject?.id,
        });

        try {
            const response = await api.request<TAssessment[]>(Methods.get, {
                url,
            });

            if (response.length) {
                const [{ id: firstAssessmentId }] = response;

                dispatch(saveSelectedAssessment(firstAssessmentId));
            }
        } finally {
            dispatch(setInProgress(false));
        }
    };

export const handleGetAssessments =
    () =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const {
            user: {
                data: { currentProject },
            },
        } = getState();

        dispatch(setInProgress(true));

        const url = createQueryUrl(ENDPOINTS.assessmentHistory, {
            projectId: currentProject?.id,
        });

        try {
            const response = await api.request<TAssessment[]>(Methods.get, {
                url,
            });

            dispatch(saveAssessmentsList(response));
        } finally {
            dispatch(setInProgress(false));
        }
    };

export const handleGetDashboardData =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        await Promise.all([
            dispatch(handleGetTopFindings()),
            dispatch(handleGetFindingsBySeverity()),
            dispatch(handleGetBlockedActivities()),
            dispatch(handleGetPolicyViolationWarnings()),
        ]);
    };

export const handleGetAssessmentsData =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        await Promise.all([
            dispatch(handleGetAssessments()),
            dispatch(handleGetAssessmentsForSelect()),
        ]);
    };

export const handleInitializeDashboard =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            await dispatch(handleGetAssessmentsData());
            await dispatch(handleGetDashboardData());
        } finally {
            dispatch(setIsInitialized(true));
        }
    };

export const handleChooseAssessment =
    (id: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(saveSelectedAssessment(id));

        dispatch(setInProgress(true));

        try {
            await dispatch(handleGetDashboardData());
        } finally {
            dispatch(setInProgress(false));
        }
    };

export const updateFindingsData =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setInProgress(true));

        try {
            await Promise.all([
                dispatch(handleGetTopFindings()),
                dispatch(handleGetFindingsBySeverity()),
            ]);
        } finally {
            dispatch(setInProgress(false));
        }
    };

export const updateBlockedActivities =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setInProgress(true));

        try {
            await dispatch(handleGetBlockedActivities());
        } finally {
            dispatch(setInProgress(false));
        }
    };

export const updatePolicyViolation =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setInProgress(true));

        try {
            await dispatch(handleGetPolicyViolationWarnings());
        } finally {
            dispatch(setInProgress(false));
        }
    };
