/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { NotificationTypes, showNotification } from './notification';

type TErrorList = { message: string }[];

export const showError = (
    message = 'An exception occurred and is under review by our team'
): void => {
    showNotification({ text: message, type: NotificationTypes.error });
};

export const showErrors = (error: unknown): void => {
    // This needs because error from catch is always have unknown type
    const errorList = <TErrorList>error;

    if (Array.isArray(errorList) && errorList.length) {
        errorList.forEach(({ message }: { message: string }) => {
            showError(message);
        });
    } else {
        showError();
    }
};

export const showWarning = (
    message = 'An exception occurred and is under review by our team'
): void => {
    showNotification({ text: message, type: NotificationTypes.warning });
};
