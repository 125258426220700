/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { forwardRef, useMemo } from 'react';
/**
 * Components
 */
import {
    IconContainerProps as IconContainerMods,
    IconContainer as UuiIconContainer,
} from '@epam/promo';
/**
 * Types
 */
import { WithDataTestId } from 'types';
import { ControlIconProps } from '@epam/uui-components';
/**
 * Utils
 */
import { getRawProps } from 'utils';
/**
 * Expo
 */
export const IconContainer: React.FC<
    ControlIconProps & IconContainerMods & WithDataTestId
> = forwardRef(({ dataTestId, ...props }, ref) => {
    const rawProps = useMemo(() => getRawProps({ dataTestId }), [dataTestId]);

    return <UuiIconContainer {...props} ref={ref} rawProps={rawProps} />;
});
