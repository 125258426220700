/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { Table } from 'pages/ThreatModeling/tabs/ControlsTab/components/Table/Table';
import React, { FC } from 'react';
import { PanelBlock, Text, FlexRow } from 'components/common';

import css from './style.module.scss';

type TODO = string;

// TODO: fix the types
export interface Control {
    id: string;
    controlName: TODO;
    controlCategory: TODO;
    implementationPoint: TODO;
}

export const ControlsTab: FC = () => {
    return (
        <PanelBlock cx={css.panel}>
            <FlexRow vPadding="36" padding="18">
                <Text fontSize="24" fontWeight="400" lineHeight="30">
                    Controls
                </Text>
            </FlexRow>
            <Table />
        </PanelBlock>
    );
};
