/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { DATE_FORMATS } from 'constants/dates';
import { format, isAfter, isBefore } from 'date-fns';
/**
 * Types
 */
type TDateValue = Date | string;
type TDateJsInstance = { toDate: () => Date };

type TFormatDate = (date?: TDateValue, format?: string) => string;
type FormatDateRange = (date: {
    to: TDateValue;
    from: TDateValue;
    format?: string;
}) => string;
/**
 * Expo
 */
export const formatDate: TFormatDate = (
    date,
    dateFormat = DATE_FORMATS.US.dateAtTime
) => {
    let dateToFormat = date;

    if (!dateToFormat) return '';

    if (typeof dateToFormat === 'string') {
        dateToFormat = new Date(dateToFormat);
    }

    return format(dateToFormat, dateFormat);
};

export const formatDateRange: FormatDateRange = ({
    to,
    from,
    format: dateFormat,
}) => `${formatDate(from, dateFormat)} - ${formatDate(to, dateFormat)}`;

export const getDateFilter =
    (minDate?: Date, maxDate?: Date) =>
    (day: TDateJsInstance): boolean => {
        const date = day.toDate();

        if (minDate && maxDate) {
            return isAfter(date, minDate) && isBefore(date, maxDate);
        }

        if (minDate) {
            return isAfter(date, minDate);
        }

        if (maxDate) {
            return isBefore(date, maxDate);
        }

        return true;
    };

export const getUserTimeZone = (): string =>
    Intl.DateTimeFormat().resolvedOptions().timeZone;
