/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useEffect, useCallback, useState } from 'react';
import { cx } from '@epam/uui-core';
/**
 * Components
 */
import { FlexRow } from 'components/common';
import { SingleAttachedFile } from 'components/SingleAttachedFile';
/**
 * Utils
 */
import { handleDownloadFile, handleGetDocument } from './utils';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { IDocumentList } from './types';
import { ENDPOINTS } from 'constants/api';
/**
 * Expo
 */
export const DocumentList: React.FC<IDocumentList> = ({
    onLoad,
    onClick,
    list = [],
    dataTestId,
    columnsInRow = 1,
    clickable = true,
    onDelete,
    isEditingMode,
    onRemove,
    contentUrl = ENDPOINTS.downloadAttachedFile,
}) => {
    const [currentFileId, setCurrentFileId] = useState<string>();

    const handleClick = useCallback(
        async ({ id, name }: { id: string; name: string }) => {
            if (!clickable) return;

            onLoad?.();
            setCurrentFileId(id);

            const blobFile: Blob = await handleGetDocument(id, contentUrl);

            onClick?.({ blobFile, name });
        },
        [clickable, onClick, onLoad, contentUrl]
    );

    const className = cx(
        css.attachedFilesContainer,
        css[`columnSize${columnsInRow}`]
    );

    useEffect(() => {
        if (list?.length) {
            const [first] = list;
            handleClick(first);
        }
    }, [handleClick, list]);

    return (
        <FlexRow cx={className} dataTestId={`${dataTestId}/attachments`}>
            {list?.map(({ id, name, size_bytes, created_by }) => (
                <SingleAttachedFile
                    id={id}
                    key={id}
                    name={name}
                    contentUrl={contentUrl}
                    size={size_bytes}
                    onDelete={onDelete}
                    onClick={handleClick}
                    dataTestId={dataTestId}
                    onDownload={handleDownloadFile}
                    isChosen={id === currentFileId}
                    isEditingMode={isEditingMode}
                    onClose={onRemove}
                    isClickable={clickable}
                    createdBy={created_by}
                />
            ))}
        </FlexRow>
    );
};
