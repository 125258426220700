/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import {
    CharacterMetadata,
    ContentBlock,
    ContentState,
    EditorState,
    genKey,
    Modifier,
    SelectionState,
} from 'draft-js';
/**
 * Utils
 */
import { getSelectedBlocksList } from 'components/common/RichEditorInput/utils';
/**
 * Expo
 */
export const mergeSelectedBlocks = (editorState: EditorState): EditorState => {
    const selectedBlockList = getSelectedBlocksList(editorState);
    const mergedText =
        ContentState.createFromBlockArray(selectedBlockList).getPlainText();
    const mergedCharacterList = selectedBlockList
        .map((block) => block.getCharacterList())
        .reduce((first, second) => {
            const lineBreak = CharacterMetadata.create();
            const listWithLineBreak = second.toArray();
            listWithLineBreak.unshift(lineBreak);

            return first.concat(listWithLineBreak).toList();
        });

    const mergedBlock = new ContentBlock({
        ket: genKey(),
        type: 'unstyled',
        text: mergedText,
        characterList: mergedCharacterList,
    });

    const blockMap = ContentState.createFromBlockArray([
        mergedBlock,
    ]).getBlockMap();

    /**
     * Need to select whole blocks's content before replacing it
     * Otherwise content might be replaced with errors
     */
    const selectionState = new SelectionState({
        anchorKey: selectedBlockList[0].getKey(),
        anchorOffset: 0,
        focusKey: selectedBlockList[selectedBlockList.length - 1].getKey(),
        focusOffset:
            selectedBlockList[selectedBlockList.length - 1].getLength(),
    });

    const newState = Modifier.replaceWithFragment(
        editorState.getCurrentContent(),
        selectionState,
        blockMap
    );

    return EditorState.push(editorState, newState, 'insert-fragment');
};
