/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { BadgeProps } from '@epam/promo';
/**
 * Constants
 */
import { ColorNames, Colors } from './colors';

/**
 * Expo
 */
export enum SeverityTypes {
    informational = 'INFORMATIONAL',
    low = 'LOW',
    high = 'HIGH',
    medium = 'MEDIUM',
    critical = 'CRITICAL',
}

export enum SeverityCaptions {
    informational = 'Informational',
    low = 'Low',
    high = 'High',
    medium = 'Medium',
    critical = 'Critical',
}

export enum VulnerabilitySeverities {
    info = 'INFO',
    minor = 'MINOR',
    major = 'MAJOR',
    critical = 'CRITICAL',
    blocker = 'BLOCKER',
}

export const SEVERITY_CAPTIONS = {
    [SeverityTypes.low]: SeverityCaptions.low,
    [SeverityTypes.high]: SeverityCaptions.high,
    [SeverityTypes.medium]: SeverityCaptions.medium,
    [SeverityTypes.critical]: SeverityCaptions.critical,
    [SeverityTypes.informational]: SeverityCaptions.informational,
};

// TODO: extract BadgeProps['color'] somewhere in the upper level, e.g. in the colors.ts or types directory, then replace all the BadgeProps['color'] with the extracted type
export const SEVERITY_COLOR_NAMES: Record<SeverityTypes, BadgeProps['color']> =
    {
        [SeverityTypes.low]: ColorNames.green,
        [SeverityTypes.high]: ColorNames.orange,
        [SeverityTypes.medium]: ColorNames.amber,
        [SeverityTypes.critical]: ColorNames.red,
        [SeverityTypes.informational]: ColorNames.blue,
    };

export const SEVERITY_COLORS = {
    [SeverityTypes.low]: Colors.green,
    [SeverityTypes.high]: Colors.orange,
    [SeverityTypes.medium]: Colors.amber,
    [SeverityTypes.critical]: Colors.red,
    [SeverityTypes.informational]: Colors.blue,
};

export const SEVERITY_RANGE_CAPTIONS = {
    [SeverityTypes.critical]: 'Critical',
    [SeverityTypes.high]: 'High',
    [SeverityTypes.medium]: 'Medium',
    [SeverityTypes.low]: 'Low',
    [SeverityTypes.informational]: 'Informational',
};
