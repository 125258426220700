/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useEffect, useState } from 'react';
/**
 * Components
 */
import Label from './Label';
import Select from './Select';
/**
 * Types
 */
import { TEditableArraySelectProps } from './types';
import { TPerson } from 'types';
/**
 * Constants
 */
import { Methods } from 'constants/request';
/**
 * Utils
 */
import { createQueryUrl } from 'utils';
/**
 * Services
 */
import { api } from 'services/api';
import { ENDPOINTS } from 'constants/api';
/**
 * Expo
 */
export const EditableSelect = <TOption, TValue>({
    value,
    onSave,
    onClear,
    labelKey,
    url,
    queryParams,
    caption,
    isDisabled,
    isAlwaysEdit = false,
    showButtons = true,
    showSearchIcon = false,
    ...props
}: TEditableArraySelectProps<TOption, TValue>): JSX.Element => {
    const [isEdit, setIsEdit] = useState(isAlwaysEdit);
    const [options, setOptions] = useState<TOption[]>([]);

    const handleShowSelect = () => {
        setIsEdit(true);
    };

    const handleShowLabel = () => {
        setIsEdit(false);
    };

    const handleCancel = () => {
        if (onClear) onClear();
        if (!isAlwaysEdit) handleShowLabel();
    };

    const handleSuccess = (selectedValue: TValue) => {
        onSave(selectedValue);
        if (!isAlwaysEdit) handleShowLabel();
    };

    const handleGetOptions = useCallback(async () => {
        const queriedUrl = createQueryUrl(url, queryParams);

        const response = await api.request<TOption[]>(Methods.get, {
            url: queriedUrl,
        });

        setOptions(response);
    }, [queryParams, url]);

    useEffect(() => {
        handleGetOptions();
    }, [handleGetOptions]);

    const filteredValue = React.useMemo(() => {
        if (
            url === ENDPOINTS.persons &&
            value.length > 0 &&
            options.length > 0
        ) {
            const ids = (options as TPerson[]).reduce<Record<string, 1>>(
                (hashmap, user) => {
                    hashmap[user.id] = 1;
                    return hashmap;
                },
                {}
            );
            return (value as TPerson[]).filter((user) => ids[user.id]);
        }
        return value;
    }, [value, options, url]);

    return isEdit ? (
        <Select<TOption, TValue>
            {...props}
            options={options}
            value={filteredValue}
            labelKey={labelKey}
            onSuccess={handleSuccess}
            onCancel={handleCancel}
            showButtons={showButtons}
            showSearchIcon={showSearchIcon}
        />
    ) : (
        <Label<TOption>
            value={value}
            labelKey={labelKey}
            onEdit={handleShowSelect}
            caption={caption}
            isDisabled={isDisabled}
        />
    );
};
