/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { memo } from 'react';
/**
 * Hooks
 */
import { useRole, useRouteParams } from 'hooks';
/**
 * Components
 */
import { Text } from '@epam/promo';
import { LinkButton } from 'components/common';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
/**
 * Types
 */
import { TAssessment } from 'types';
/**
 * Utils
 */
import { generatePath } from 'utils';
/**
 * Assets
 */
import css from '../../style.module.scss';
/**
 * Expo
 */
export const NameCell: React.FC<TAssessment> = memo(
    ({ id: assessmentId, name }) => {
        const { projectId } = useRouteParams();
        const { isOperationsManager } = useRole();

        if (isOperationsManager) {
            return <Text>{name}</Text>;
        }

        return (
            <LinkButton
                caption={name}
                cx={css.assessmentLinkButton}
                dataTestId="projectInfo-assessmentHistory-cells-name"
                href={generatePath(PAGES.singleAssessment, {
                    projectId,
                    assessmentId,
                })}
            />
        );
    }
);
