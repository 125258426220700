/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { DataPickerRow, PickerItem } from '@epam/promo';
import { DataRowProps } from '@epam/uui-core';
import { ReactNode } from 'react';
import { TNamed } from 'types';

/* eslint-disable react/destructuring-assignment */

const renderItem = (item: TNamed, rowProps: DataRowProps<TNamed, string>) => {
    return <PickerItem title={item.name} {...rowProps} />;
};

export const createRenderRowWithAddTypeButton =
    (addTypeButton: ReactNode) => (rowProps: DataRowProps<TNamed, string>) => {
        return (
            <>
                {rowProps.index === 0 && addTypeButton}
                <DataPickerRow
                    {...rowProps}
                    rawProps={{
                        ...rowProps.rawProps,
                        'data-testid': rowProps.isLoading
                            ? `uui-PickerInput-loading-item-${rowProps.rowKey}`
                            : `uui-PickerInput-item-${rowProps.rowKey}`,
                    }}
                    padding="12"
                    key={rowProps.rowKey}
                    renderItem={renderItem}
                />
            </>
        );
    };
