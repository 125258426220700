/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { BadgeProps } from '@epam/promo';
/**
 * Constants
 */
import { ColorNames } from 'constants/colors';
import { StatusCaptions, StatusTypes } from 'constants/statuses';

/**
 * Expo
 */
export const CAPTIONS = {
    [StatusTypes.active]: StatusCaptions.active,
    [StatusTypes.pending]: StatusCaptions.pending,
    [StatusTypes.completed]: StatusCaptions.completed,
};

// TODO extract keyof typeof CAPTIONS to a separate type ans use in CAPTIONS and COLORS
export const COLORS: Record<keyof typeof CAPTIONS, BadgeProps['color']> = {
    [StatusTypes.active]: ColorNames.blue,
    [StatusTypes.pending]: ColorNames.purple,
    [StatusTypes.completed]: ColorNames.green,
};
