/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { AppDispatch, Asset } from 'types';
import { createQueryUrl } from 'utils';
import { ENDPOINTS } from 'constants/api';
import { api } from 'services/api';
import { Methods } from 'constants/request';
import { assetFormSchema } from '../../../pages/ThreatModeling/tabs/ModelTreeTab/components/ExpandableTree/components/ItemEditor/forms/Asset/schema';
import { mapFormValuesToAssetPayload } from '../../../pages/ThreatModeling/tabs/ModelTreeTab/components/ExpandableTree/components/ItemEditor/forms/Asset/utils';
import {
    addAsset,
    saveAssets,
    setAssetsLoadingInProgress,
    updateAsset,
} from './slice';
import { AssetCreatePayload } from './types';

export const handleGetAssets =
    (tmId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            dispatch(setAssetsLoadingInProgress(true));
            const url = createQueryUrl(ENDPOINTS.assets, {
                tmId,
            });
            const assets = await api.request<Asset[]>(Methods.get, {
                url,
            });
            dispatch(saveAssets(assets));
        } finally {
            dispatch(setAssetsLoadingInProgress(false));
        }
    };

export const handleCreateAsset =
    (form: typeof assetFormSchema._output) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = ENDPOINTS.assets;
        const requestBody: AssetCreatePayload =
            mapFormValuesToAssetPayload(form);
        const asset = await api.request<Asset, AssetCreatePayload>(
            Methods.post,
            { url, data: requestBody }
        );

        dispatch(addAsset(asset));
    };

export const handleUpdateAsset =
    (id: string, form: typeof assetFormSchema._output) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = `${ENDPOINTS.assets}/${id}`;
        const requestBody: AssetCreatePayload =
            mapFormValuesToAssetPayload(form);
        const updatedAsset = await api.request<Asset, AssetCreatePayload>(
            Methods.put,
            { url, data: requestBody }
        );

        dispatch(updateAsset(updatedAsset));
    };
