/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import * as z from 'zod';

import { zodRequiredStringWithMessage } from './constants';

export const baseItemFormSchema = z.object({
    // TODO: id field used to pass it to the form in edit mode, may be reconsidered
    id: z.string().nullish(),
    tmId: zodRequiredStringWithMessage,
    readableId: zodRequiredStringWithMessage,
    name: zodRequiredStringWithMessage,
});

export type BaseItemFormValues = z.infer<typeof baseItemFormSchema>;
export type BaseItemFormSchema = typeof baseItemFormSchema;

export type FormValuesWithoutTmId<Values extends BaseItemFormValues> = Omit<
    Values,
    'tmId'
>;

export type BaseFormValuesWithoutID = FormValuesWithoutTmId<BaseItemFormValues>;
