/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, memo } from 'react';
/**
 * Types
 */
import { TProjectFindingActionLog } from 'types';
import { DefaultRecentAction } from 'components/RecentActions/RecentAction/DefaultRecentAction';
import { THandlers } from 'components/ProjectRecentActions/types';
import { getFindingName } from 'components/FindingInformation/utils';
/**
 * Expo
 */
export const FindingArchived: FC<TProjectFindingActionLog & THandlers> = memo(
    ({
        author: { name },
        details: {
            current_state: { summary, composite_id },
        },
    }) => (
        <DefaultRecentAction
            dataTestId="findingArchived"
            user={name}
            action={`archived finding ${getFindingName(composite_id, summary)}`}
        />
    )
);
