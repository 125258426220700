/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { TBreadCrumb } from 'components/common/Breadcrumb/types';
import { PAGES } from 'constants/pages';
import { TNamed } from 'types';
import { generatePath } from 'utils';

export const getBreadCrumbs = ({
    target,
    activity,
    projectId,
    assessment,
    dataTestId,
}: {
    target: TNamed;
    activity: TNamed;
    projectId: string;
    assessment: TNamed;
    dataTestId?: string;
}): TBreadCrumb[] => [
    {
        dataTestId: `${dataTestId}/assessmentName`,
        caption: assessment.name,
        pathname: generatePath(PAGES.singleAssessment, {
            projectId,
            assessmentId: assessment.id,
        }),
    },
    {
        dataTestId: `${dataTestId}/targetName`,
        caption: target.name,
        pathname: generatePath(PAGES.targets, {
            projectId,
        }),
    },
    {
        dataTestId: `${dataTestId}/activityName`,
        caption: activity.name,
        pathname: generatePath(PAGES.singleActivity, {
            projectId,
            activityId: activity.id,
            assessmentId: assessment.id,
        }),
    },
];

export const getFindingName = (
    compositeId: string | undefined,
    summary: string,
    issueKey?: string
): string => {
    if (compositeId) {
        return `${compositeId} ${summary}`;
    }
    return issueKey ? `[${issueKey}] ${summary}` : summary;
};
