/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { ValueText } from './ValueText';
import { FC, ReactNode } from 'react';
import { Text } from 'components/common';
import { EMPTY_VALUE_PLACEHOLDER } from '../../constants';
import css from './style.module.scss';
import { isEmptyField } from '../../utils';
import { Label } from './Label';

interface DefaultViewProps {
    labelText: string;
    value: ReactNode;
    dataTestId: string;
}

export const DefaultView: FC<DefaultViewProps> = ({
    labelText,
    value: valueFromProps,
    dataTestId: dataTestIdFromProps,
}) => {
    const valueAsArray = Array.isArray(valueFromProps)
        ? valueFromProps
        : [valueFromProps];

    const emptyValue = isEmptyField(valueFromProps);

    return (
        <div className={css.readonlyRowContainer}>
            <Label dataTestId={dataTestIdFromProps}>{labelText}</Label>
            <>
                {!emptyValue ? (
                    valueAsArray.map((item, index) => {
                        const dataTestId =
                            valueAsArray.length === 1
                                ? `${dataTestIdFromProps}/value`
                                : `${dataTestIdFromProps}/value/${index + 1}`;

                        return (
                            <ValueText
                                key={item}
                                value={item}
                                dataTestId={dataTestId}
                            />
                        );
                    })
                ) : (
                    <Text
                        fontSize="14"
                        font="sans"
                        cx={css.readonlyRowText}
                        dataTestId={`${dataTestIdFromProps}/value`}
                    >
                        {EMPTY_VALUE_PLACEHOLDER}
                    </Text>
                )}
            </>
        </div>
    );
};
