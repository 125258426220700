/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useRef } from 'react';
/**
 * Assets
 */
import css from '../style.module.scss';
/**
 * Components
 */
import { LinkButton, Panel, Text } from 'components/common';
/**
 * Hooks
 */
import { useLocation } from 'react-router-dom';
import { useHover } from 'hooks';
/**
 * Constants
 */
import { PlatformTypes } from 'constants/system';
/**
 * Types
 */
import { TSecurityCenterProps } from '../type';
/**
 * Utils
 */
import { definePlatformType } from 'utils/system';
import { cx } from '@epam/uui-core';
/**
 * Expo
 */
export const ServicesDiscoveryConsole: React.FC<TSecurityCenterProps> = ({
    onClick,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    const isHovered = useHover(ref);

    const { pathname } = useLocation();

    const platform = definePlatformType(pathname);

    const isSecurityDeliveryConsole =
        platform === PlatformTypes.securityDeliveryConsole;

    const handleClick = useCallback(() => {
        onClick(PlatformTypes.securityDeliveryConsole);
    }, [onClick]);

    const iconPath = isSecurityDeliveryConsole
        ? '/assets/icons/sdcActive.svg'
        : '/assets/icons/sdcIcon.svg';

    return (
        <div
            ref={ref}
            aria-hidden
            onClick={handleClick}
            className={cx(css.container, isHovered && css.hovered)}
        >
            <img
                src={iconPath}
                className={css.icon}
                alt="Services Discovery Console"
            />
            <Panel cx={css.panelContent}>
                <LinkButton
                    caption="Services Discovery Console"
                    cx={css.linkButton}
                />
                <Text
                    cx={css.textPadding}
                    lineHeight="18"
                    fontSize="12"
                    color="gray60"
                >
                    Discuss your project scope, needs and all business goals you
                    would like to <br /> achieve with our help
                </Text>
            </Panel>
        </div>
    );
};
