/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { configureStore, MiddlewareArray } from '@reduxjs/toolkit';
import rootSaga from './rootSaga';
import reducer from './combineReducer';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { backgroundUpdateMiddleware } from './middleware/backgroundUpdate';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer,
    middleware: new MiddlewareArray().concat(
        thunkMiddleware,
        sagaMiddleware,
        backgroundUpdateMiddleware
    ),
});

sagaMiddleware.run(rootSaga);

export default store;
