/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { TextArea, TextInput } from 'components/common';
import { useSelectOptionsFromEnum } from 'hooks';
/* eslint-disable react/destructuring-assignment */
import { FC, memo } from 'react';
import {
    handleCreateAsset,
    handleUpdateAsset,
} from 'store/threatModeling/asset/dispatchers';
import { selectAssetsCount } from 'store/threatModeling/asset/selectors';
import { AppDispatch } from 'types';
import {
    AssetAvailability,
    AssetConfidentiality,
    AssetIntegrity,
    AssetType,
} from 'types/threatModeling/asset';
import { FormFieldsProps } from '../../../../types';
import { createEditableItemCard } from '../../../createEditableItemCard';
import { FullFieldRow, HalfFieldRow, TwoFieldsRow } from '../common';
import { createEditableField } from '../common/EditableField/EditableField';
import { TmArraySelect } from '../common/TmArraySelect';
import { TEXT_AREA_ROWS } from '../constants';
import { ASSET_READABLE_ID_PREFIX } from './constants';
import { assetFormSchema, AssetFormValues } from './schema';

import {
    mapAssetAvailabilityToLabel,
    mapAssetConfidentialityToLabel,
    mapAssetIntegrityToLabel,
    mapAssetTypeToLabel,
} from './utils';

const AssetEditableField = createEditableField<AssetFormValues>();

const AssetFormFields: FC<FormFieldsProps<AssetFormValues>> = () => {
    const typeOptions = useSelectOptionsFromEnum(
        AssetType,
        mapAssetTypeToLabel
    );
    const confidentialityOptions = useSelectOptionsFromEnum(
        AssetConfidentiality,
        mapAssetConfidentialityToLabel
    );
    const integrityOptions = useSelectOptionsFromEnum(
        AssetIntegrity,
        mapAssetIntegrityToLabel
    );

    const availabilityOptions = useSelectOptionsFromEnum(
        AssetAvailability,
        mapAssetAvailabilityToLabel
    );
    return (
        <>
            <TwoFieldsRow>
                <AssetEditableField
                    name="readableId"
                    label="Asset ID"
                    controllerComponent={TextInput}
                    controllerProps={{
                        placeholder: 'Enter a text',
                        isReadonly: true,
                    }}
                    dataTestId="assetForm/readableId"
                />
                <AssetEditableField
                    name="name"
                    label="Asset name"
                    controllerComponent={TextInput}
                    controllerProps={{
                        placeholder: 'Enter a text',
                    }}
                    dataTestId="assetForm/name"
                />
            </TwoFieldsRow>
            <HalfFieldRow>
                <AssetEditableField
                    name="type"
                    label="Type"
                    transformValueForView={(value) =>
                        value && mapAssetTypeToLabel(value)
                    }
                    controllerComponent={TmArraySelect}
                    controllerProps={{
                        labelKey: 'label',
                        options: typeOptions,
                        placeholder: 'Please select',
                    }}
                    dataTestId="assetForm/type"
                />
            </HalfFieldRow>
            <FullFieldRow>
                <AssetEditableField
                    name="comment"
                    label="Comment"
                    controllerComponent={TextArea}
                    controllerProps={{
                        placeholder: 'Enter a text',
                        rows: TEXT_AREA_ROWS,
                    }}
                    dataTestId="assetForm/comment"
                />
            </FullFieldRow>
            <HalfFieldRow>
                <AssetEditableField
                    label="Confidentiality"
                    name="confidentiality"
                    transformValueForView={(value) =>
                        value && mapAssetConfidentialityToLabel(value)
                    }
                    controllerComponent={TmArraySelect}
                    controllerProps={{
                        labelKey: 'label',
                        options: confidentialityOptions,
                        placeholder: 'Please select',
                    }}
                    dataTestId="assetForm/confidentiality"
                />
            </HalfFieldRow>
            <FullFieldRow>
                <AssetEditableField
                    label="Confidentiality comment"
                    name="confidentialityComment"
                    controllerComponent={TextArea}
                    controllerProps={{
                        placeholder: 'Enter a text',
                        rows: TEXT_AREA_ROWS,
                    }}
                    dataTestId="assetForm/confidentialityComment"
                />
            </FullFieldRow>
            <HalfFieldRow>
                <AssetEditableField
                    label="Integrity"
                    name="integrity"
                    transformValueForView={(value) =>
                        value && mapAssetIntegrityToLabel(value)
                    }
                    controllerComponent={TmArraySelect}
                    controllerProps={{
                        labelKey: 'label',
                        options: integrityOptions,
                        placeholder: 'Please select',
                    }}
                    dataTestId="assetForm/integrity"
                />
            </HalfFieldRow>
            <FullFieldRow>
                <AssetEditableField
                    label="Integrity comment"
                    name="integrityComment"
                    controllerComponent={TextArea}
                    controllerProps={{
                        placeholder: 'Enter a text',
                        rows: TEXT_AREA_ROWS,
                    }}
                    dataTestId="assetForm/integrityComment"
                />
            </FullFieldRow>
            <HalfFieldRow>
                <AssetEditableField
                    name="availability"
                    label="Availability"
                    transformValueForView={(value) =>
                        value && mapAssetAvailabilityToLabel(value)
                    }
                    controllerComponent={TmArraySelect}
                    controllerProps={{
                        labelKey: 'label',
                        options: availabilityOptions,
                        placeholder: 'Please select',
                    }}
                    dataTestId="assetForm/availability"
                />
            </HalfFieldRow>
            <FullFieldRow>
                <AssetEditableField
                    name="availabilityComment"
                    label="Availability comment"
                    controllerComponent={TextArea}
                    controllerProps={{
                        placeholder: 'Enter a text',
                        rows: TEXT_AREA_ROWS,
                    }}
                    dataTestId="assetForm/availabilityComment"
                />
            </FullFieldRow>
        </>
    );
};

export const AssetItemCard = memo(
    createEditableItemCard(AssetFormFields, {
        formSchema: assetFormSchema,
        onCreate: (values) => {
            return async (dispatch: AppDispatch) => {
                await dispatch(handleCreateAsset(values));
            };
        },
        onEdit: (id, values) => {
            return async (dispatch: AppDispatch) => {
                await dispatch(handleUpdateAsset(id, values));
            };
        },
        readableIdPrefix: ASSET_READABLE_ID_PREFIX,
        countSelector: selectAssetsCount,
    })
);

AssetItemCard.displayName = 'AssetItemCard';
