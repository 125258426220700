/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useMemo } from 'react';
/**
 * Components
 */
import { UserAvatar } from '../UserAvatar';
import { Tooltip, Text } from '@epam/promo';
import { FlexRow, User } from 'components/common';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Utils
 */
import { cx } from '@epam/uui-core';
/**
 * Types
 */
import { AvatarStackProps } from './types';
/**
 * Expo
 */
export const AvatarStack: React.FC<AvatarStackProps> = ({
    list,
    size,
    dataTestId,
    avatarsCount = 0,
}) => {
    const restList = list.slice(avatarsCount);
    const availableList = avatarsCount ? list.slice(0, avatarsCount) : list;
    const isCounterVisible = avatarsCount && list.length > avatarsCount;

    const renderCustomMarkup = useMemo(
        () =>
            restList.map(({ id, full_name: fullName }) => (
                <User
                    key={id}
                    textSize="14"
                    avatarSize="18"
                    name={fullName}
                    textClassName={css.tooltipText}
                />
            )),
        [restList]
    );

    return (
        <FlexRow cx={css.container}>
            {availableList?.map(({ id, full_name: fullName }) => (
                <Tooltip key={id} content={fullName}>
                    <UserAvatar
                        size={size}
                        name={fullName}
                        className={css.listItem}
                        dataTestId={dataTestId}
                    />
                </Tooltip>
            ))}
            {!isCounterVisible ? null : (
                <Tooltip content={renderCustomMarkup} placement="top-start">
                    <div className={cx(css.counterBadge, css.listItem)}>
                        <Text
                            fontSize="12"
                            cx={cx(
                                css.avatarText,
                                size === '12' && css.smallText
                            )}
                        >
                            +{restList.length}
                        </Text>
                    </div>
                </Tooltip>
            )}
        </FlexRow>
    );
};
