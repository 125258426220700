/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, {
    useRef,
    useMemo,
    useState,
    useEffect,
    useCallback,
} from 'react';
/**
 * Components
 */
import { ActionButtons } from '../ActionButtons';
import { AttachFilesModal } from 'components/modals';
import { FlexSpacer } from '@epam/promo';
import { CombineAttachments } from './CombineAttachments';
import {
    Text,
    User,
    IconContainer,
    Panel,
    FlexRow,
    RichEditor2,
} from 'components/common';
import { Delete } from './components/Delete';
/**
 * Hooks
 */
import { useAppDispatch, useHover, useRouteParams } from 'hooks';
import { useSelector } from 'react-redux';
/**
 * Utils
 */
import { formatDate, showModal } from 'utils';
/**
 * Assets
 */
import css from './style.module.scss';
import { ReactComponent as editCommentIcon } from '@epam/assets/icons/common/content-edit-18.svg';
/**
 * Store
 */
import { selectUserId } from 'store/user';
import { handleEditComment } from 'store/singleFinding';
/**
 * Types
 */
import { TCommentListProps } from './types';
import { TAttachProps } from '../CreateComment/types';
import { TAttachment, TComment, TDocument } from 'types';
/**
 * Expo
 */
export const CommentList: React.FC<TComment & TCommentListProps> = ({
    id,
    created_at,
    external_author,
    created_by: createdBy,
    person: { full_name },
    comment: initialComment,
    attachments: previouslySavedAttachments,
    setIsEditingMode,
    editable = true,
    dataTestId,
}) => {
    const ref = useRef(null);
    const isHovered = useHover(ref);

    const [comment, setComment] = useState(initialComment);

    const [isReadOnly, setIsReadOnly] = useState(true);

    const [deletedIds, setDeletedIds] = useState<string[]>([]);
    const [newAttachments, setNewAttachments] = useState<TAttachment[]>([]);
    const [savedAttachments, changeSavedAttachments] = useState<TDocument[]>(
        previouslySavedAttachments
    );

    const { findingId } = useRouteParams();

    const currentUserId = useSelector(selectUserId);

    const dispatch = useAppDispatch();

    const onEditComment = useCallback(() => {
        setIsReadOnly(false);
        setIsEditingMode(true);
    }, [setIsEditingMode]);

    const commentData = useMemo(
        () => ({
            comment,
            commentId: id,
            attachments: newAttachments,
            deletedAttachmentsIds: deletedIds,
        }),
        [id, comment, deletedIds, newAttachments]
    );

    const handleSave = useCallback(async () => {
        await dispatch(handleEditComment(findingId, commentData));

        setDeletedIds([]);
        setNewAttachments([]);

        setIsReadOnly(true);
        setIsEditingMode(false);
    }, [commentData, findingId, dispatch, setIsEditingMode]);

    const handleSetAttachments = useCallback(
        async (payload: TAttachment[]) => {
            setNewAttachments([...newAttachments, ...payload]);
        },
        [newAttachments]
    );

    const handleAddAttachment = useCallback(() => {
        showModal<TAttachProps>(AttachFilesModal, {
            onSubmit: handleSetAttachments,
            dataTestId,
            successButtonCaption: 'Attach',
        });
    }, [handleSetAttachments, dataTestId]);

    const handleCancel = useCallback(() => {
        setComment(initialComment);

        setIsReadOnly(true);
        setIsEditingMode(false);

        setNewAttachments([]);
        changeSavedAttachments(previouslySavedAttachments);
    }, [initialComment, previouslySavedAttachments, setIsEditingMode]);

    const deleteNewAttachment = useCallback(
        (attachmentId: string) => {
            const filteredAttachments = newAttachments.filter(
                (attachment) => attachment.id !== attachmentId
            );

            setNewAttachments(filteredAttachments);
        },
        [newAttachments]
    );

    const deleteSavedAttachment = useCallback(
        (attachmentId: string) => {
            const filteredAttachments = savedAttachments.filter(
                (attachment) => attachment.id !== attachmentId
            );

            changeSavedAttachments(filteredAttachments);
            setDeletedIds([...deletedIds, attachmentId]);
        },
        [deletedIds, savedAttachments]
    );

    useEffect(() => {
        changeSavedAttachments(previouslySavedAttachments);
    }, [previouslySavedAttachments]);

    const isOwner = createdBy === currentUserId;

    return (
        <div ref={ref} key={id}>
            <Panel cx={css.comment}>
                <FlexRow
                    columnGap="18"
                    dataTestId={`${dataTestId}/comment/info`}
                >
                    <User
                        name={full_name}
                        fromTracker={Boolean(external_author)}
                        dataTestId={`${dataTestId}/comment/author`}
                    />
                    <Text
                        color="gray60"
                        dataTestId={`${dataTestId}/comment/createdAt`}
                    >
                        {formatDate(created_at)}
                    </Text>
                    {editable && isHovered && isOwner && isReadOnly && (
                        <IconContainer
                            style={{ fill: 'var(--uui-link)' }}
                            onClick={onEditComment}
                            icon={editCommentIcon}
                            dataTestId={`${dataTestId}/comment/editButton`}
                        />
                    )}
                    {editable && isHovered && isOwner && isReadOnly && (
                        <Delete
                            id={id}
                            title="Delete comment"
                            dataTestId={dataTestId}
                        />
                    )}
                    <FlexSpacer />
                </FlexRow>
                <Panel cx={css.commentText}>
                    <FlexRow dataTestId={`${dataTestId}/comment/text`}>
                        <RichEditor2
                            minRows={0}
                            readOnly={isReadOnly}
                            onChange={setComment}
                            initialValue={initialComment}
                        />
                    </FlexRow>
                    <CombineAttachments
                        dataTestId={`${dataTestId}/comment`}
                        isEditingMode={!isReadOnly}
                        newAttachments={newAttachments}
                        savedAttachments={savedAttachments}
                        onDeleteNewAttachment={deleteNewAttachment}
                        onDeleteSavedAttachment={deleteSavedAttachment}
                    />
                </Panel>
                {!isReadOnly && (
                    <ActionButtons
                        captionSubmit="Save"
                        onSubmit={handleSave}
                        isDisabled={!comment}
                        onCancel={handleCancel}
                        handleAddAttachment={handleAddAttachment}
                        dataTestId={dataTestId}
                    />
                )}
            </Panel>
        </div>
    );
};
