/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { EditorState, Modifier } from 'draft-js';
/**
 * Types
 */
import { getSelectedBlocksList } from 'components/common/RichEditorInput/utils';
/**
 * Expo
 */
export const setAlignmentInSelectedBlocks = (
    editorState: EditorState,
    alignment: string
): EditorState => {
    const currentEditorState = editorState;

    let currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const selectedBlockList = getSelectedBlocksList(currentEditorState);

    let canApplyAlignment = false;

    selectedBlockList.forEach((block) => {
        const blockAlignment = block.getData().get('alignment');

        if (blockAlignment !== alignment) {
            canApplyAlignment = true;
        }
    });

    const anchorKey = selection.getAnchorKey();
    const map = currentContent
        .getBlockForKey(anchorKey)
        .toMap()
        .set('alignment', canApplyAlignment ? alignment : '');

    currentContent = Modifier.setBlockData(currentContent, selection, map);

    return EditorState.push(
        currentEditorState,
        currentContent,
        'change-block-data'
    );
};
