/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { ZodNamedTmDto } from '../constants';
import {
    TrustBoundaryCreatePayload,
    TrustBoundaryUpdatePayload,
} from 'store/threatModeling/trustBoundary/types';
import { TrustBoundary } from 'types';
import { LevelOfRisk } from 'types/threatModeling/trustBoundary';
import { createReadableId } from '../../../utils';

import { FormValuesWithoutTmId } from '../baseItemFormSchema';
import {
    TRUST_BOUNDARY_READABLE_ID_PREFIX,
    riskLevelToLabelMap,
} from './constants';
import { TrustBoundaryFormValues } from './schema';

export function mapTrustBoundaryToFormValues(
    trustBoundary: TrustBoundary
): FormValuesWithoutTmId<TrustBoundaryFormValues> {
    const elements: TrustBoundaryFormValues['elements'] =
        trustBoundary?.elements?.map((element) => ({
            id: element.id,
            name: element.name,
            readableId: element.readable_id,
        }));

    const parent: TrustBoundaryFormValues['parent'] = trustBoundary.parent && {
        id: trustBoundary.parent.id,
        name: trustBoundary.parent.name,
        readableId: trustBoundary.parent.readable_id,
    };

    return {
        id: trustBoundary.id,
        name: trustBoundary.name,
        readableId: createReadableId(
            TRUST_BOUNDARY_READABLE_ID_PREFIX,
            String(trustBoundary.readable_id)
        ),
        type: trustBoundary.type,
        levelOfRisk: trustBoundary.level_of_risk,
        parent,
        elements,
        description: trustBoundary.description,
    };
}

export function mapFormValuesToTrustBoundaryPayload(
    form: TrustBoundaryFormValues
): TrustBoundaryCreatePayload {
    return {
        tm_id: form.tmId,
        name: form.name,
        type_id: form.type.id,
        level_of_risk: form.levelOfRisk,
        parent_id: form.parent?.id,
        elements: form.elements?.map((element) => element.id),
        description: form.description,
    };
}

export function mapFormValuesToTrustBoundaryUpdatePayload(
    form: TrustBoundaryFormValues
): TrustBoundaryUpdatePayload {
    return {
        name: form.name,
        type_id: form.type.id,
        level_of_risk: form.levelOfRisk,
        parent_id: form.parent?.id,
        elements: form.elements?.map((element) => element.id),
        description: form.description,
    };
}

export const mapRiskLevelToLabel = (riskLevel: LevelOfRisk) => {
    return riskLevelToLabelMap[riskLevel];
};

export function transformTrustBoundaryOption(
    value: TrustBoundary
): ZodNamedTmDto {
    return {
        ...value,
        // To make form work, since schema uses camelCase
        readableId: value.readable_id,
    };
}

export function transformTrustBoundaryViewValue(value: ZodNamedTmDto) {
    return `${TRUST_BOUNDARY_READABLE_ID_PREFIX}${value.readableId} ${value.name}`;
}
