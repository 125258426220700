/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useMemo, useState } from 'react';
import { cx } from '@epam/uui-core';
/**
 * Types
 */
import { TLastAttachedFiles } from './types';
/**
 * Components
 */
import { FlexRow, FlexSpacer } from '@epam/promo';
import { TextEllipsis } from 'components/TextEllipsis';
import { IconContainer, Text, Panel, Blocker } from 'components/common';
/**
 * Utils
 */
import { getIconSrcByExtension } from './utils';
import {
    getSizeToMegabytes,
    getNameWithoutExtension,
    getExtensionFromFileName,
} from 'utils';
/**
 * Assets
 */
import css from './style.module.scss';
import { ReactComponent as deleteIcon } from '@epam/assets/icons/common/action-delete-18.svg';
import { ReactComponent as downloadIcon } from '@epam/assets/icons/common/file-download-18.svg';
import { ReactComponent as closeIcon } from '@epam/assets/icons/common/navigation-close-18.svg';
import { ReactComponent as errorIcon } from '@epam/assets/icons/common/notification-error-fill-12.svg';
/**
 * Expo
 */
export const SingleAttachedFile: React.FC<TLastAttachedFiles> = ({
    id,
    name,
    contentUrl,
    size,
    isChosen,
    dataTestId,
    alt = 'Upload image',
    onClick,
    onClose,
    onDelete,
    onDownload,
    isEditingMode = false,
    isClickable,
    createdBy,
    error,
}) => {
    const [isDownloading, setIsDownloading] = useState(false);

    const sizeInMegabyteFormat = useMemo(
        () => getSizeToMegabytes(size),
        [size]
    );
    const nameWithoutExt = useMemo(() => getNameWithoutExtension(name), [name]);
    const fileExt = useMemo(() => getExtensionFromFileName(name), [name]);
    const iconSrc = useMemo(() => getIconSrcByExtension(fileExt), [fileExt]);

    const handleDownload = useCallback(async () => {
        if (!onDownload || !id) return;

        setIsDownloading(true);
        await onDownload({ id, name, contentUrl });
        setIsDownloading(false);
    }, [id, name, onDownload, contentUrl]);

    const handleClick = useCallback(() => {
        if (!onClick || !id) return;

        onClick({ id, name });
    }, [id, name, onClick]);

    const handleDelete = useCallback(() => {
        if (!onDelete || !id) return;
        onDelete({ id, name: nameWithoutExt, createdBy });
    }, [id, nameWithoutExt, createdBy, onDelete]);

    const handleClose = useCallback(() => {
        if (!onClose || !id) return;

        onClose(id);
    }, [id, onClose]);

    return (
        <Panel
            onClick={handleClick}
            dataTestId={`${dataTestId}/card`}
            cx={cx(
                css.card,
                isChosen && css.chosen,
                !isClickable && css.cursorDefault,
                !!error && css.redBorder
            )}
        >
            <Blocker isEnabled={isDownloading} hideSpinner />
            <FlexRow cx={css.cardContent} alignItems="center" size="30">
                <FlexRow size="30">
                    <img className={css.iconSize} src={iconSrc} alt={alt} />
                </FlexRow>
                <Panel>
                    <TextEllipsis
                        title={nameWithoutExt}
                        dataTestId={`${dataTestId}/card/name`}
                    />
                    {error ? (
                        <FlexRow cx={css.error}>
                            <IconContainer
                                style={{ fill: 'red' }}
                                icon={errorIcon}
                            />
                            <Text dataTestId={`${dataTestId}/card/error`}>
                                {error}
                            </Text>
                        </FlexRow>
                    ) : (
                        <Text
                            fontSize="12"
                            color="gray60"
                            lineHeight="12"
                            cx={css.textPadding}
                            dataTestId={`${dataTestId}/card/extension`}
                        >
                            {fileExt}, {sizeInMegabyteFormat} MB
                        </Text>
                    )}
                </Panel>
                <FlexSpacer />
                {onDownload && !isEditingMode && (
                    <FlexRow size="30">
                        <IconContainer
                            style={{ fill: 'var(--uui-neutral-60)' }}
                            icon={downloadIcon}
                            onClick={handleDownload}
                            dataTestId={`${dataTestId}/card/buttons/download`}
                        />
                    </FlexRow>
                )}
                {onDelete && (
                    <FlexRow size="30">
                        <IconContainer
                            cx={css.deleteIcon}
                            icon={deleteIcon}
                            style={{ fill: 'var(--uui-neutral-60)' }}
                            onClick={handleDelete}
                            dataTestId={`${dataTestId}/card/buttons/delete`}
                        />
                    </FlexRow>
                )}
                {onClose && isEditingMode && (
                    <FlexRow>
                        <IconContainer
                            cx={css.closeIcon}
                            icon={closeIcon}
                            style={{ fill: 'var(--uui-neutral-60)' }}
                            onClick={handleClose}
                        />
                    </FlexRow>
                )}
            </FlexRow>
        </Panel>
    );
};
