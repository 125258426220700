/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { useEffect } from 'react';

/**
 * Chrome requires returnValue to be true.
 * @see https://github.com/jacobbuck/react-beforeunload/issues/33
 */
export function useBeforeUnload(enabled = true) {
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (enabled) {
            const listener = (event: Event) => {
                event.preventDefault();

                event.returnValue = true;
            };

            window.addEventListener('beforeunload', listener);
            return () => {
                window.removeEventListener('beforeunload', listener);
            };
        }
    }, [enabled]);
}
