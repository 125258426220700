/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Expo
 */
export enum EventTypes {
    newMessage = 'NEW_MESSAGE',
    projectStarted = 'PROJECT_STARTED',
    forcedUserLogout = 'FORCED_USER_LOGOUT',
    reportDocxCreated = 'REPORT_DOCX_CREATED',
    reportDocxFailed = 'REPORT_DOCX_FAILED',
}
