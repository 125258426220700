/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { memo } from 'react';
/**
 * Components
 */
import { IconContainer } from '@epam/promo';
import { FlexRow, LinkButton, User } from 'components/common';
import { AvatarStack } from 'components/common/AvatarStack';
/**
 * Utils
 */
import { cx } from '@epam/uui-core';
/**
 * Types
 */
import { TLabelProps } from './types';
/**
 * Assets
 */
import { ReactComponent as addIcon } from '@epam/assets/icons/common/action-add-12.svg';
import { ReactComponent as editIcon } from '@epam/assets/icons/common/content-edit-18.svg';
import css from './style.module.scss';
/**
 * Expo
 */
const Label = <TOption,>({
    value,
    onEdit,
    labelKey,
    caption = 'assign person',
    isDisabled = false,
}: TLabelProps<TOption>): JSX.Element => {
    if (!value) {
        return (
            <FlexRow columnGap="12">
                <LinkButton
                    isDisabled={isDisabled}
                    icon={addIcon}
                    onClick={onEdit}
                    caption={caption}
                />
            </FlexRow>
        );
    }

    return Array.isArray(value) ? (
        <FlexRow columnGap="12" cx={cx(isDisabled && css.disabledRow)}>
            <AvatarStack size="18" list={value} />
            <IconContainer
                style={{ fill: 'var(--uui-link)' }}
                icon={editIcon}
                onClick={onEdit}
            />
        </FlexRow>
    ) : (
        <FlexRow columnGap="12" cx={cx(isDisabled && css.disabledRow)}>
            <User avatarSize="18" name={String(value[labelKey])} />
            <IconContainer
                style={{ fill: 'var(--uui-link)' }}
                icon={editIcon}
                onClick={onEdit}
            />
        </FlexRow>
    );
};

export default memo(Label) as typeof Label;
