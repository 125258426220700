/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { TGetNameString } from './types';
/**
 * Expo
 */
export const getNamesString: TGetNameString = ({ options, value }) => {
    if (!value || value.length <= 1) {
        return '';
    }

    const nameList = value.map((id: string) => {
        const { name } = options.find((option) => option.id === id) || {};

        return name;
    });

    return nameList.join('; ');
};
