/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { useMemo } from 'react';
import { cx } from '@epam/uui-core';
import { TabButton } from './TabButton';
import css from './style.module.scss';
import { pick } from 'utils/array';
import { TabProps } from './types';

export const Tab: React.FC<TabProps> = ({
    id,
    caption,
    nestedList,
    currentTabId,
    disabledTabs,
    dataTestId,
}) => {
    const nestedIds = useMemo(() => pick(nestedList, 'id'), [nestedList]);
    const isActive = id === currentTabId || nestedIds.includes(currentTabId);
    const isParentTabDisabled = disabledTabs?.includes(id);

    return (
        <>
            <TabButton
                caption={caption}
                dataTestId={dataTestId}
                isDisabled={isParentTabDisabled}
                cx={cx(css.button, isActive && css.active)}
            />

            {nestedList?.map(({ id: nestedTabId, name }) => {
                const isNestedTabDisabled =
                    isParentTabDisabled || disabledTabs?.includes(nestedTabId);
                return (
                    <TabButton
                        key={nestedTabId}
                        caption={name}
                        dataTestId={dataTestId}
                        isDisabled={isNestedTabDisabled}
                        cx={cx(
                            css.nestedMenuButton,
                            currentTabId === nestedTabId && css.active
                        )}
                    />
                );
            })}
        </>
    );
};
