/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { DataTable } from '@epam/promo';
import { DataColumnProps, useArrayDataSource } from '@epam/uui-core';
import { IconContainer, Text } from 'components/common';
import { EmptyView } from 'pages/ThreatModeling/tabs/ThreatsTab/components/Table/EmptyView';
import { Threat } from '../../index';
import { useState } from 'react';
import { ReactComponent as NavigationExpandAllOutlineIcon } from '@epam/assets/icons/common/navigation-unfold-more-18.svg';

const columns: Array<DataColumnProps<Threat>> = [
    {
        key: 'expand',
        width: 30,
        grow: 1,
        caption: <IconContainer icon={NavigationExpandAllOutlineIcon} />,
    },
    {
        key: 'id',
        width: 39,
        grow: 1,
        caption: 'ID',
        render: ({ id }: Threat) => <Text>{id}</Text>,
    },
    {
        grow: 1,
        key: 'attackType',
        width: 100,
        caption: 'Attack Type',
        render: ({ attackType }: Threat) => <Text>{attackType}</Text>,
    },
    {
        grow: 1,
        key: 'attackTarget',
        width: 100,
        caption: 'Attack Target',
        render: ({ attackTarget }: Threat) => <Text>{attackTarget}</Text>,
    },
    {
        grow: 1,
        key: 'attackSurface',
        width: 100,
        caption: 'Attack Surface',
        render: ({ attackSurface }: Threat) => <Text>{attackSurface}</Text>,
    },
    {
        grow: 1,
        key: 'assetFootprint',
        width: 100,
        caption: 'Asset Footprint',
        render: ({ assetFootprint }: Threat) => <Text>{assetFootprint}</Text>,
    },
    {
        grow: 1,
        key: 'attackSource',
        width: 100,
        caption: 'Attack Source',
        render: ({ attackSource }: Threat) => <Text>{attackSource}</Text>,
    },
    {
        grow: 1,
        key: 'threatCategory',
        width: 100,
        caption: 'Threat Category',
        render: ({ threatCategory }: Threat) => <Text>{threatCategory}</Text>,
    },
    {
        grow: 1,
        key: 'severity',
        width: 100,
        caption: 'Severity',
        render: ({ severity }: Threat) => <Text>{severity}</Text>,
    },
    {
        grow: 1,
        key: 'controls',
        width: 100,
        caption: 'Controls',
        render: ({ controls }: Threat) => <Text>{controls}</Text>,
    },
];

export const Table = () => {
    const dataSource = useArrayDataSource<Threat, Threat['id'], unknown>(
        {
            items: [],
        },
        []
    );
    const [value, onValueChange] = useState({});

    const view = dataSource.useView(value, onValueChange);
    return (
        <DataTable
            {...view.getListProps()}
            value={value}
            onValueChange={onValueChange}
            columns={columns}
            getRows={view.getVisibleRows}
            headerTextCase="upper"
            renderNoResultsBlock={() => <EmptyView />}
        />
    );
};
