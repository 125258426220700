/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { TAssessmentStatus } from 'types';
import { StatusTypes } from 'constants/statuses';
import { DateLimit } from 'components/common/RangeDatePicker/types';

export const getDateRangeLimitFromStatus = (
    status?: TAssessmentStatus
): DateLimit => {
    if (status === StatusTypes.completed) {
        return {
            maxDate: new Date(),
        };
    }
    return {};
};
