/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useContext, useMemo } from 'react';
/**
 * Hooks
 */
import { useRole } from 'hooks';
/**
 * Components
 */
import { FlexRow } from '@epam/promo';
import { ArraySelect } from 'components/common';
import { renderRow } from './components/renderRow';
/**
 * Constants
 */
import { StatusTypes } from 'constants/statuses';
/**
 * Types
 */
import { TOption } from './types';
import { TFieldProps } from '../../types';
/**
 * Utils
 */
import { getName, getTooltipContent } from './utils';
/**
 * Context
 */
import { Context } from '../FilterContext/context';
import { OPTIONS, OPTIONS_TO_EXCLUDE } from './constants';
/**
 * Expo
 */
export const Statuses: React.FC<TFieldProps<StatusTypes[]>> = ({
    fieldName,
    dataTestId,
    onChange,
}) => {
    const { getFieldData } = useContext(Context);

    const { isProjectRepresentative } = useRole();

    const { value, queryParameter } = useMemo(
        () => getFieldData(fieldName),
        [fieldName, getFieldData]
    );

    const handleChange = useCallback(
        (selectedValue: StatusTypes[]) => {
            onChange({ [queryParameter]: selectedValue });
        },
        [onChange, queryParameter]
    );

    const tooltipContent = useMemo(() => getTooltipContent(value), [value]);

    const optionsByRole = useMemo(() => {
        if (!isProjectRepresentative) return OPTIONS;

        return OPTIONS?.filter(({ id }) => !OPTIONS_TO_EXCLUDE.includes(id));
    }, [isProjectRepresentative]);

    return (
        <FlexRow vPadding="18">
            <ArraySelect<TOption>
                disableClear
                mode="multi"
                maxItems={1}
                value={value}
                label="Status"
                getName={getName}
                entityName="status"
                renderRow={renderRow}
                searchPosition="none"
                options={optionsByRole}
                tooltipPlacement="right"
                placeholder="All statuses"
                onValueChange={handleChange}
                tooltipContent={tooltipContent}
                dataTestId={`${dataTestId}/statuses`}
            />
        </FlexRow>
    );
};
