/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC } from 'react';
/**
 * Constants
 */
import { EntityTypes } from 'constants/richTextEditor';
import { TDecoratorComponentProps, TFindLinkEntities } from './types';
/**
 * Expo
 */
const findLinkEntities: TFindLinkEntities = (
    contentBlock,
    callback,
    contentState
) => {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();

        if (entityKey === null) return false;

        const entityType = contentState.getEntity(entityKey).getType();

        return entityType === EntityTypes.link;
    }, callback);
};

const Link: FC<TDecoratorComponentProps> = ({
    children,
    entityKey,
    contentState,
}) => {
    const { url } = contentState.getEntity(entityKey).getData();

    return (
        <a href={url} target="_blank" rel="noreferrer">
            {children}
        </a>
    );
};

export default {
    strategy: findLinkEntities,
    component: Link,
};
