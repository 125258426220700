/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useMemo } from 'react';
/**
 * Components
 */
import {
    LabeledInput,
    RangeDatePicker as UuiRangeDatePicker,
} from '@epam/promo';
/**
 * Types
 */
import { TRangeDatePickerProps } from './types';
/**
 * Utils
 */
import { getRawProps, getDateFilter } from 'utils';
/**
 * Constants
 */
import { DEFAULT_VALUE } from './constants';
import { VALIDATION_MESSAGE } from 'constants/validation';
/**
 * Expo
 */
export const RangeDatePicker: React.FC<TRangeDatePickerProps> = ({
    label,
    format,
    isInvalid,
    isRequired,
    isDisabled,
    isReadonly,
    dataTestId,
    validationMessage = VALIDATION_MESSAGE,
    value = DEFAULT_VALUE,
    getLimit,
    onValueChange,
    onBlur,
}) => {
    const { minDate, maxDate } = useMemo(
        () => (getLimit ? getLimit(value) : {}),
        [value, getLimit]
    );

    const dateFilter = useMemo(
        () =>
            minDate || maxDate ? getDateFilter(minDate, maxDate) : undefined,
        [minDate, maxDate]
    );

    const rawProps = useMemo(() => {
        if (!dataTestId) return undefined;

        return {
            to: getRawProps({ dataTestId: `${dataTestId}/to`, onBlur }),
            from: getRawProps({ dataTestId: `${dataTestId}/from`, onBlur }),
            body: getRawProps({ dataTestId: `${dataTestId}/body` }),
        };
    }, [dataTestId, onBlur]);

    return (
        <LabeledInput
            label={label}
            isInvalid={isInvalid}
            isRequired={isRequired}
            validationMessage={validationMessage}
        >
            <UuiRangeDatePicker
                value={value}
                format={format}
                rawProps={rawProps}
                isInvalid={isInvalid}
                isDisabled={isDisabled}
                isReadonly={isReadonly}
                filter={dateFilter}
                onValueChange={onValueChange}
            />
        </LabeledInput>
    );
};
