/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
/**
 * Hooks
 */
import { useProjectType } from 'hooks';
/**
 * Components
 */
import { TasksRoute } from './Tasks';
import { ReportsRoute } from './Reports';
import { FindingsRoute } from './Findings';
import { AssessmentsRoute } from './Assessments';
import { PrivateRoute } from '../../../PrivateRoute';
import { VulnerabilitiesRoute } from './Vulnerabilities';
/**
 * Constants
 */
import { RoleTypes } from 'constants/roles';
import { ThreatModelingRoute } from './ThreatModelingRoute';

/**
 * Pages
 */
const Dashboard = lazy(() => import('pages/Dashboard'));
const SingleProject = lazy(() => import('pages/SingleProject'));
const Attachments = lazy(() => import('pages/ProjectAttachments'));
const Targets = lazy(() => import('pages/Targets'));
const ProjectInfo = lazy(() => import('pages/ProjectInfo'));
const ProjectPolicies = lazy(() => import('pages/ProjectPolicies'));
const ProjectPolicyCreation = lazy(() => import('pages/ProjectPolicyCreation'));
const SinglePolicy = lazy(() => import('pages/Policy'));
const AzureIntegration = lazy(() => import('pages/Integrations/Azure'));
const SlackIntegration = lazy(() => import('pages/Integrations/Slack'));
const TeamsIntegration = lazy(() => import('pages/Integrations/Teams'));
const NotificationsSingleProject = lazy(
    () => import('pages/NotificationsSingleProject')
);
/**
 * Expo
 */
export const SingleProjectRoute: React.FC = () => {
    const { isBasic, isTemplate } = useProjectType();

    return (
        <Suspense fallback={null}>
            <Routes>
                <Route
                    path="/*"
                    element={
                        <PrivateRoute
                            component={SingleProject}
                            roles={[
                                RoleTypes.securitySpecialist,
                                RoleTypes.projectRepresentative,
                            ]}
                        />
                    }
                >
                    <Route
                        path="assessments/*"
                        element={<AssessmentsRoute />}
                    />
                    <Route
                        path="threat-modeling/:activityId/*"
                        element={<ThreatModelingRoute />}
                    />
                    <Route path="reports/*" element={<ReportsRoute />} />
                    <Route
                        path="info"
                        element={
                            <PrivateRoute
                                component={ProjectInfo}
                                roles={[
                                    RoleTypes.projectRepresentative,
                                    RoleTypes.securitySpecialist,
                                ]}
                            />
                        }
                    />
                    <Route
                        path="policies"
                        element={
                            <PrivateRoute
                                component={ProjectPolicies}
                                roles={[RoleTypes.projectRepresentative]}
                            />
                        }
                    />
                    <Route
                        path="policies/:policyId"
                        element={
                            <PrivateRoute
                                component={SinglePolicy}
                                roles={[RoleTypes.projectRepresentative]}
                            />
                        }
                    />
                    <Route
                        path="policies/add"
                        element={
                            <PrivateRoute
                                component={ProjectPolicyCreation}
                                roles={[RoleTypes.projectRepresentative]}
                            />
                        }
                    />
                    <Route
                        path="info/attachments"
                        element={
                            <PrivateRoute
                                component={Attachments}
                                roles={[
                                    RoleTypes.securitySpecialist,
                                    RoleTypes.projectRepresentative,
                                ]}
                            />
                        }
                    />
                    <Route
                        path="dashboard"
                        element={
                            <PrivateRoute
                                component={Dashboard}
                                roles={[
                                    RoleTypes.projectRepresentative,
                                    RoleTypes.securitySpecialist,
                                ]}
                            />
                        }
                    />
                    <Route
                        path="targets"
                        element={
                            <PrivateRoute
                                component={Targets}
                                roles={[
                                    RoleTypes.securitySpecialist,
                                    RoleTypes.projectRepresentative,
                                ]}
                            />
                        }
                    />
                    {(isBasic || isTemplate) && (
                        <>
                            <Route
                                path="findings/*"
                                element={<FindingsRoute />}
                            />
                            <Route
                                path="vulnerabilities/*"
                                element={<VulnerabilitiesRoute />}
                            />
                            <Route path="tasks/*" element={<TasksRoute />} />
                        </>
                    )}
                    <Route
                        path="slack/add"
                        element={
                            <PrivateRoute
                                component={SlackIntegration}
                                roles={[RoleTypes.projectRepresentative]}
                            />
                        }
                    />
                    <Route
                        path="slack/edit"
                        element={
                            <PrivateRoute
                                component={SlackIntegration}
                                roles={[RoleTypes.projectRepresentative]}
                            />
                        }
                    />
                    <Route
                        path="teams/add"
                        element={
                            <PrivateRoute
                                component={TeamsIntegration}
                                roles={[RoleTypes.projectRepresentative]}
                            />
                        }
                    />
                    <Route
                        path="teams/edit"
                        element={
                            <PrivateRoute
                                component={TeamsIntegration}
                                roles={[RoleTypes.projectRepresentative]}
                            />
                        }
                    />
                    <Route
                        path="azure/add"
                        element={
                            <PrivateRoute
                                component={AzureIntegration}
                                roles={[RoleTypes.projectRepresentative]}
                            />
                        }
                    />
                    <Route
                        path="azure/edit"
                        element={
                            <PrivateRoute
                                component={AzureIntegration}
                                roles={[RoleTypes.projectRepresentative]}
                            />
                        }
                    />
                    <Route
                        path="notifications"
                        element={
                            <PrivateRoute
                                component={NotificationsSingleProject}
                                roles={[
                                    RoleTypes.projectRepresentative,
                                    RoleTypes.securitySpecialist,
                                ]}
                            />
                        }
                    />
                </Route>
            </Routes>
        </Suspense>
    );
};
