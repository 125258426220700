/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { TechnologyFormValues } from './forms/Technology/schema';
import { TechnologyItemCard } from './forms/Technology/TechnologyForm';
import { ElementItemCard } from './forms/Element/ElementForm';
import { ElementFormValues } from './forms/Element/schema';
import { mapElementTypeToLabel } from './forms/Element/utils';
import { exhaustiveCheck } from 'utils/exhaustiveCheck';
import { TrustBoundaryFormValues } from './forms/TrustBoundary/schema';
import { TrustBoundaryItemCard } from './forms/TrustBoundary/TrustBoundaryForm';
import { HeaderEditIconButton } from '../HeaderEditIconButton';
import { mapAssetTypeToLabel } from './forms/Asset/utils';
import { AssetFormValues } from './forms/Asset/schema';
import { ItemType } from '../../../../constants';
import { FormMode } from '../../types';
import {
    ViewAndEditItemHeader,
    ViewAndEditItemHeaderProps,
} from '../ViewAndEditItemHeader';
import { AssetItemCard } from './forms/Asset/AssetForm';
import { BaseFormValuesWithoutID } from './forms/baseItemFormSchema';
import { HARDCODED_INTERACTION_TYPE } from './forms/Interaction/constants';
import { InteractionItemCard } from './forms/Interaction/InteractionForm';
import { InteractionFormValues } from './forms/Interaction/schema';
import { AccordionProps } from '@epam/uui-components';
import { FC } from 'react';

const EmptyComponent: FC = () => <></>;
// TODO: fix any, but it's no critical here
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const typeToItemCardMap: Record<ItemType, FC<any>> = {
    [ItemType.asset]: AssetItemCard,
    [ItemType.interaction]: InteractionItemCard,
    [ItemType.trustBoundary]: TrustBoundaryItemCard,
    [ItemType.element]: ElementItemCard,
    [ItemType.technology]: TechnologyItemCard,
    [ItemType.attacker]: EmptyComponent,
};

const renderEditPencilInHeader: AccordionProps['renderAdditionalItems'] = (
    isOpen
) => <HeaderEditIconButton isOpen={isOpen} />;

// TODO: temporary dirty solution, will be removed after migration to createEditableItemCard
export function createItemForViewAndEdit(
    type: ItemType,
    item: BaseFormValuesWithoutID
) {
    const commonCardProps = {
        key: item.id,
        initialMode: FormMode.view,
        renderAdditionalHeaderItems: renderEditPencilInHeader,
    } as const;

    const commonHeaderProps: ViewAndEditItemHeaderProps = {
        readableId: item.readableId,
        name: item.name,
    };

    switch (type) {
        case ItemType.asset: {
            const assertedItem = item as AssetFormValues;
            return (
                <AssetItemCard
                    {...commonCardProps}
                    values={assertedItem}
                    header={
                        <ViewAndEditItemHeader
                            {...commonHeaderProps}
                            additionalText={
                                assertedItem.type
                                    ? mapAssetTypeToLabel(assertedItem.type)
                                    : undefined
                            }
                        />
                    }
                />
            );
        }
        case ItemType.interaction: {
            const assertedItem = item as InteractionFormValues;
            return (
                <InteractionItemCard
                    {...commonCardProps}
                    values={{
                        ...assertedItem,
                        // This is not sent to the backend, it's just for presentation in the form AKA "design sugar"
                        // TODO: defaults should be extracted from schema: in one place as single source of truth
                        type: HARDCODED_INTERACTION_TYPE,
                    }}
                    header={
                        <ViewAndEditItemHeader
                            {...commonHeaderProps}
                            additionalText={HARDCODED_INTERACTION_TYPE}
                        />
                    }
                />
            );
        }
        case ItemType.trustBoundary: {
            const assertedItem = item as TrustBoundaryFormValues;
            return (
                <TrustBoundaryItemCard
                    {...commonCardProps}
                    values={assertedItem}
                    header={
                        <ViewAndEditItemHeader
                            {...commonHeaderProps}
                            additionalText={assertedItem.type.name}
                        />
                    }
                />
            );
        }
        case ItemType.element: {
            const assertedItem = item as ElementFormValues;
            return (
                <ElementItemCard
                    {...commonCardProps}
                    values={assertedItem}
                    header={
                        <ViewAndEditItemHeader
                            {...commonHeaderProps}
                            additionalText={mapElementTypeToLabel(
                                assertedItem.type
                            )}
                        />
                    }
                />
            );
        }
        case ItemType.technology: {
            const assertedItem = item as TechnologyFormValues;
            return (
                <TechnologyItemCard
                    {...commonCardProps}
                    values={assertedItem}
                    header={<ViewAndEditItemHeader {...commonHeaderProps} />}
                />
            );
        }
        case ItemType.attacker: {
            return <EmptyComponent />;
        }
        default:
            return exhaustiveCheck(type);
    }
}
