/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { TOption } from './types';
/**
 * Constants
 */
import { CAPTIONS_BY_STATUS, StatusTypes } from 'constants/statuses';
/**
 * Expo
 */
export const getTooltipContent = (
    statusList: string[] | undefined
): string | undefined => {
    if (!statusList || statusList.length <= 1) return undefined;

    return statusList
        .map((status) => CAPTIONS_BY_STATUS[status as StatusTypes])
        .join('; ');
};

export const getName = ({ id }: TOption): string =>
    CAPTIONS_BY_STATUS[id as StatusTypes];
