/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Badge } from 'components/common';
/**
 * Constants
 */
import { StatusTypes } from 'constants/statuses';
import { COLORS, CAPTIONS } from './constants';
/**
 * Types
 */
import { Props } from './types';
/**
 * Assets
 */
import css from './style.module.scss';
import { ReactComponent as doneIcon } from '@epam/assets/icons/common/notification-done-12.svg';
import { FlexRow } from '@epam/promo';
/**
 * Expo
 */
export const AssessmentStatus: React.FC<Props> = ({ type, dataTestId }) => (
    <FlexRow cx={css.statusContainer}>
        <Badge
            color={COLORS[type]}
            fill="outline"
            dataTestId={dataTestId}
            caption={CAPTIONS[type]}
            icon={type === StatusTypes.completed ? doneIcon : undefined}
            cx={css.status}
        />
    </FlexRow>
);
