/**
 * Dependencies
 */
import React, { PropsWithChildren } from 'react';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';

type TAttachmentsContext = {
    attachmentsEndpoint: string;
};

const defaultContext: TAttachmentsContext = {
    attachmentsEndpoint: ENDPOINTS.downloadAttachedFile,
};
export const AttachmentsContext =
    React.createContext<TAttachmentsContext>(defaultContext);

export const AttachmentsContextProvider: React.FC<
    PropsWithChildren<Partial<TAttachmentsContext>>
> = ({ children, ...context }) => (
    <AttachmentsContext.Provider value={{ ...defaultContext, ...context }}>
        {children}
    </AttachmentsContext.Provider>
);
