/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { RoleTypes } from 'constants/roles';
import { ApiStatusCodes } from 'constants/api';
import { TIssue } from 'store/singleFinding/types';
import { AssessmentTypes } from 'constants/assessment';
import { AsvsLevelViolated, FindingResolution } from 'constants/finding';
import { ReportState, ReportStatus, ReportTypes } from 'constants/reports';
import { MessengerNotificationTypes } from 'constants/notification';
import { IntegrationTypes, ProjectTypes } from 'constants/project';
import { SeverityTypes, VulnerabilitySeverities } from 'constants/severity';
import {
    AuthStepType,
    StatusTypes,
    UserStatuses,
    VulnerabilityStatusTypes,
} from 'constants/statuses';
import { PolicyRuleType } from 'constants/policy';
import { TNamed } from 'types';
import { EventTypes } from 'constants/sockets';
import { ActionOnAsset } from 'pages/ThreatModeling/tabs/ModelTreeTab/components/ExpandableTree/components/ItemEditor/forms/constants';
import { LevelOfRisk } from 'types/threatModeling/trustBoundary';
import {
    AssetAvailability,
    AssetConfidentiality,
    AssetIntegrity,
    AssetType,
} from './threatModeling/asset';
import { ElementType } from './threatModeling/element';

/**
 * Expo
 */
export type TActivityStatus =
    | StatusTypes.toDo
    | StatusTypes.inProgress
    | StatusTypes.done;

export type TAssessmentStatus =
    | StatusTypes.active
    | StatusTypes.pending
    | StatusTypes.completed;

export type TProjectOptions = {
    findings: boolean;
    continuous_assessments: boolean;
};

export type TAssigneeOptions = {
    id: string;
    email: string;
    last_name: string;
    first_name: string;
    full_name: string;
};

export type TRole = {
    id: number;
    name: RoleTypes;
    description: string;
    enabled: boolean;
};

export type TIntegration = {
    config_json: string;
    created_at: Date;
    created_by: Date;
    credentials_id: string;
    id: string;
    integration_version: string;
    project_id: string;
    updated_at: Date;
};
export type TIntegrationWithType = {
    integration_type: IntegrationTypes;
} & TIntegration;

export type TMessengerConfiguration = {
    url: string;
    notifications: Record<MessengerNotificationTypes, boolean>;
};

export type TMessengerIntegration = {
    configuration: TMessengerConfiguration;
    integration_type: IntegrationTypes.slack | IntegrationTypes.teams;
};

export type TProject = {
    id: string;
    name: string;
    status: StatusTypes;
    assignees: TPerson[];
    configuration_type: ProjectTypes;
    assessment: {
        name: string;
    };
    integrations: TIntegrationWithType[];
    organization?: {
        company_name: string;
        code?: string;
    };
    project_representatives: TPerson[];
    security_specialists: TPerson[];
    threat_modeling_activity_id: string | null;
};

export type TProjectWithLastUpdateDate = {
    id: string;
    name: string;
    status: StatusTypes;
    last_update: Date;
};

export type TShortProject = {
    id: string;
    name: string;
    status: StatusTypes;
    assignees: TShortPerson[];
    assessment: {
        name: string;
    };
    integrations: TIntegrationWithType[];
    organization: {
        company_name: string;
    };
    has_approved_target?: boolean;
};

export type TPerson = {
    id: string;
    full_name: string;
    first_name: string;
    last_name: string;
    email: string;
    role: TRole;
    roles: TRole[];
    status: UserStatuses;
    is_blocked: boolean;
    avatar?: string;
    organization?: TOrganization;
    organization_id: string;
};

export type TShortPersonActionLog = {
    id: string;
    name: string;
};

export type TShortPerson = {
    id: string;
    full_name: string;
    // todo: split project assignees by role on BE and remove this field
    role: TRole;
};

export type TTarget = {
    id: string;
    name: string;
    project_id: string;
    created_at: string;
    created_by: string;
    updated_at: string;
    updated_by: string;
    status: StatusTypes;
    rejected_note?: string;
    activities: TActivityReference[];
};

export type TVulnerability = {
    id: string;
    severity: VulnerabilitySeverities;
    status: VulnerabilityStatusTypes;
    component: string;
    line: number;
    message: string;
    update_date: Date;
    url: string;
    tags: { name: string; id: string }[];
    finding?: {
        id: string;
    };
    activity: {
        id: string;
        name: string;
        assessment: {
            name: string;
            id: string;
        };
        target: {
            name: string;
            id: string;
        };
    };
};

export type TActivityAssignees = {
    activity_id: string;
    created_at: null;
    created_by: null;
    persons: TPerson[];
    updated_at: null;
    updated_by: null;
};

export type TActivityReference = {
    id: string;
    name: string;
    description: string | null;
    projects: TProject[] | null;
    created_by: string;
    created_at: string;
    updated_by: string;
    updated_at: string;
    projectsWithApprovedTargets: TProject[] | null;
    is_archived: boolean;
};

export type TActivity = {
    id: string;
    name: string;
    assessment_id: string;
    due_date: string;
    status: TActivityStatus;
    priority: string;
    target: TTarget | null;
    created_by: Date;
    created_at: Date;
    updated_by: Date;
    updated_at: Date;
    assignees?: TActivityAssignees;
    description?: string;
    blocked: boolean;
};

export type TSeverityReferences = {
    description: string;
    finding_severity_type: SeverityTypes;
};

export type TFindingType = {
    id: string;
    name: string;
};

export type TStatusReferences = {
    description: string;
    finding_status: string;
};

export type TAssessmentTrackerIssues = {
    created_by: string;
    created_at: Date;
    updated_by: string;
    updated_at: Date;
    id: string;
    tracker_integration_id: string;
    tracker_issue_id: string;
    url: string;
    last_synced_on: string;
    title: string;
    description: string;
    status: StatusTypes;
    sprint_url: string;
    severity: string;
    assignee: string;
    updated_date: string;
    key: string;
    manual: boolean;
    assessment: TAssessment;
};

export type TManualTrackerTask = Omit<TAssessmentTrackerIssues, 'assignee'> & {
    author: string;
};

export type TAssessment = {
    id: string;
    name: string;
    status: TAssessmentStatus;
    performers: TPerson[];
    metrics: {
        activity_statuses: {
            status: TActivityStatus;
            amount: number;
        }[];
        finding_metrics: {
            backlog: number;
            findings: number;
            solved: number;
        };
    };
    updated_at: Date;
    end_date: string;
    start_date: string;
    type: AssessmentTypes;
};

export type TReportExistsByName = {
    exists: boolean;
};

export type TReport = {
    id: string;
    name: string;
    assessment: TAssessment;
    assessment_id: string;
    created_at: Date;
    created_by: string;
    documents_amount: number;
    owner_full_name: string;
    project_id: string;
    total_size_bytes: number;
    updated_by: string;
    updated_at: Date;
    type: ReportTypes;
    url: string;
    state: ReportState;
    status: ReportStatus;
};

export type TManagerRole = {
    id: number;
    name: RoleTypes;
    description: string;
    is_enabled: boolean;
};

export type TManager = {
    email: string;
    first_name: string;
    full_name: string;
    id: string;
    is_blocked: boolean;
    is_deleted: boolean;
    last_name: string;
    organization_id: string;
    phone: string;
    role: TManagerRole;
    roles: TManagerRole[];
};

export type TRequest = {
    created_at: Date;
    created_by: Date;
    email: string;
    id: string;
    operation_manager: TManager;
    organization: string;
    previous_status: null;
    representative: string;
    request_date: Date;
    request_number: string;
    sales_manager: TManager;
    status: StatusTypes;
    updated_at: Date;
    updated_by: string;
};

export type TFindingStatus =
    | StatusTypes.opened
    | StatusTypes.unresolved
    | StatusTypes.archived
    | StatusTypes.readyToRetest
    | StatusTypes.resolved
    | StatusTypes.retest;

export type TFinding = {
    id: string;
    summary: string;
    status: TFindingStatus;
    type?: TFindingType;
    updated_at: Date;
    severity: SeverityTypes;
    marked_as_risk_accepted: boolean;
    assessment: TAssessment;
    activity: { assessment_id: string };
    assignee: { first_name: string; last_name: string; full_name: string };
    resolution?: FindingResolution;
    vulnerabilities?: TCweWeakness[];
    linked_tracker_issue?: TIssue;
    cvss_score?: number;
    documents_map?: {
        FINDING_CONTENT_IMAGE: TDocument[];
    };
    scanner_finding: TVulnerability;
    project_id: string;
    composite_id?: string;
};

export type TFindingListItem = {
    id: string;
    summary: string;
    status: TFindingStatus;
    updated_at: Date;
    severity: SeverityTypes;
    marked_as_risk_accepted: boolean;
    assessment: TNamed;
    activity: TNamed;
    target: TNamed;
    assignee: TShortPerson;
    updated_by: TShortPerson;
    resolution?: FindingResolution;
    linked_tracker_issue_key?: string;
    generated: boolean;
    composite_id?: string;
};

export type TFindingTemplate = {
    id: string;
    type_id?: string;
    summary: string;
    severity: SeverityTypes;
    vulnerabilities: TCweWeakness[];
    cvss_score: string;
    description: string;
};

export type TFindingByActivityId = {
    id: string;
    summary: string;
    status: TFindingStatus;
    linked_tracker_issue: {
        url: string;
        key: string;
    } | null;
    assignee: { full_name: string };
    composite_id?: string;
};

export type TFindingShort = {
    id: string;
    summary: string;
    composite_id: string | undefined;
    severity: SeverityTypes;
    status: TFindingStatus;
    updated_at: Date;
    author: TPerson;
    updated_by: TShortPerson;
    linked_tracker_issue?: TIssue;
    resolution?: FindingResolution;
};

export type TFindingResolutionReference = {
    description: string;
    finding_resolution: string;
};

export type Task = {
    id: string;
    title: string;
    assignee: string;
    status: StatusTypes;
    description: string;
    updated_at: Date;
    url: string;
    taskKey?: string;
    key?: string;
    assessment: TAssessment | null;
    manual?: boolean;
};

export type TDocument = {
    content_type: string;
    created_at: Date;
    created_by: string;
    holder_id: string;
    id: string;
    name: string;
    size_bytes: number;
    type: string;
    updated_at: Date;
    updated_by: string;
};

export type THttpError = {
    response: {
        data: { messages: { message: string }[] };
        status: ApiStatusCodes;
    };
};

export type TApiErrors = {
    message: string;
}[];

export type TOrganizationAccount = {
    id: string;
    name: string;
    code?: string;
};
export type TOrganization = {
    id: string;
    created_at: Date;
    created_by: Date;
    updated_at: Date;
    company_name: string;
};
export type TFullOrganization = TOrganization & {
    account: TOrganizationAccount;
    code?: string;
};

export type TShortOrganizationActionLog = {
    name: string;
};

export type TMyOrganization = {
    projects: TProjectWithLastUpdateDate[];
} & TOrganization;

export type TCweDescription = {
    scopes: string[];
    impacts: string[];
    note: string;
};

export type TCweCategory = {
    id: number;
    name: string;
};

export type TCweWeakness = {
    id: number;
    name: string;
    descriptions: TCweDescription[];
    categories: TCweCategory[];
};

export type TComment = {
    id: string;
    comment: string;
    external_id: string | null;
    external_author: string | null;
    created_at: Date;
    created_by: string;
    attachments: TDocument[];
    person: { full_name: string; id: string };
};

export type TChatMessage = TComment & {
    type: string;
};

export type PaginatedResponse<TData> = {
    data: TData;
    page: {
        total_pages: number;
        total_elements: number;
    };
};

type TCommonNotification<TAdditionNotificationData> = {
    id: string;
    kind: EventTypes;
    title: string;
    description: string;
    details: string;
    sender: TPerson;
    created_at: Date;
    is_viewed: boolean;
} & TAdditionNotificationData;

export type TProjectNotification = {
    project_id: string;
};

export type TNotification = TCommonNotification<TProjectNotification>;

export type TShortPolicy = {
    id: string;
    name: string;
    rule_type: PolicyRuleType;
};

export type TProjectPolicy = {
    id: string;
    name: string;
    rule_type: PolicyRuleType;
    status: string;
    email_notifications: string[];
};

export type TPolicyRemediationPeriod = {
    id: string;
    period: string;
    severity: SeverityTypes;
};

export type TPolicyAsvs = {
    id: string;
    target: TTarget;
    asvs_level: AsvsLevelViolated;
};

export type TPolicy = {
    name: string;
    description: string;
    holders: {
        id: string;
        name: string;
    }[];
    rules: {
        asvs: TPolicyAsvs[];
        remediation_periods: TPolicyRemediationPeriod[];
    };
};

export type TPolicyViolationWarning = {
    finding_count: number;
    id: string;
    policy_name: string;
};

export type TAuthOptions = {
    id_token: string;
    session_key?: string;
    refresh_token: string;
    challenge: AuthStepType;
};

export enum ProgressStepsTypes {
    finalized = 'FINALIZED',
    contractSent = 'CONTRACT_SENT',
    proposalSent = 'PROPOSAL_SENT',
    teamAssigned = 'TEAM_ASSIGNED',
    clientApproved = 'CLIENT_APPROVED',
    projectCreated = 'PROJECT_CREATED',
    requestQualified = 'REQUEST_QUALIFIED',
    projectNeedsClarified = 'PROJECT_NEEDS_CLARIFIED',
}

export type TRequestProgressStep = {
    id: string;
    checked: boolean;
    type: ProgressStepsTypes;
    document_link?: { name: string; link: string };
};

export type TAsyncTask<TAsyncTaskDetails = unknown> = {
    id: string;
    details_json: TAsyncTaskDetails;
    detail_message: string;
    status: 'PROCESSING' | 'COMPLETED' | 'FAILED' | 'PARTIALLY_COMPLETED';
};

export type TTrackerResponse = {
    integration_type: IntegrationTypes;
    url: string | null;
    username: string;
    configuration: {
        field_to_map_finding_severity: string;
        ticket_type_for_finding: string;
        labels: string[];
        project_key: string;
        severity_mappings: {
            agpt_severity: string;
            client_severity?: string;
        }[];
        status_mappings: {
            agpt_status: string;
            client_status?: string;
            type: string;
        }[];
        resolution_mappings: {
            agpt_resolution: string;
            client_resolution?: string;
        }[];
    };
};

export type TFeatureToggle = {
    id: string;
    name: string;
    active: boolean;
    description: string;
};

export type TAsvsRequirements = {
    shortCode: string;
    name: string;
    parentId?: string;
    l1?: boolean;
    l2?: boolean;
    l3?: boolean;
};

export type TAsvsVerification = {
    level: AsvsLevelViolated;
    requirements: TAsvsRequirements[];
};

export type TPresignedContent = {
    presigned_url: string;
};

export type TAuthorizationFaultActionLog = {
    link: string;
};

export type TAssetFootprint = {
    id: string;
    asset: NamedTmDto;
    action_on_asset: ActionOnAsset;
};
export type TTechnology = {
    id: string;
    readable_id: number;
    name: string;
    entry_points: TNamed[];
    asset_footprints: TAssetFootprint[];
};

export interface Asset {
    id: string;
    name: string;
    readable_id: number;
    type: AssetType | null;
    comment: string | null;
    confidentiality: AssetConfidentiality | null;
    confidentiality_comment: string | null;
    integrity: AssetIntegrity | null;
    integrity_comment: string | null;
    availability: AssetAvailability | null;
    availability_comment: string | null;
}

export type TElement = {
    id: string;
    name: string;
    readable_id: number;
    type: ElementType;
    comment: string | null;
    // from requirements (and zod schema) it's always non-empty
    technologies: [NamedTmDto, ...NamedTmDto[]];
    asset_footprints: TAssetFootprint[];
    trust_boundary: NamedTmDto | null;
};

export interface NamedTmDto {
    id: string;
    name: string;
    readable_id: number;
}

export interface Interaction {
    id: string;
    name: string;
    readable_id: number;
    from: NamedTmDto;
    to: NamedTmDto;
    technologies: Array<TNamed>;
    protocol: TNamed;
    asset_footprints: Array<TAssetFootprint>;
    comment: string | null;
}

export type TrustBoundaryType = TNamed;

export interface TrustBoundary {
    id: string;
    name: string;
    tm_id: string;
    readable_id: number;
    type: TrustBoundaryType;
    level_of_risk: LevelOfRisk | null;
    parent: NamedTmDto | null;
    elements: Array<NamedTmDto> | null;
    description: string | null;
}
