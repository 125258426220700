/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Hooks
 */
import { useRouteParams } from 'hooks';
/**
 * Components
 */
import { Findings } from './components/Findings';
import { ActivityInfo } from './components/ActivityInfo';
/**
 * Types
 */
import { TRightSideProps } from './types';
/**
 * Store
 */
/**
 * Constants
 */
/**
 * Utils
 */
import { ActivityRecentActions } from 'components';

/**
 * Expo
 */
export const RightSide: React.FC<TRightSideProps> = ({
    activity,
    activity: { name: activityName },
}) => {
    const { activityId } = useRouteParams();

    return (
        <>
            <ActivityInfo activity={activity} />
            <Findings />
            <ActivityRecentActions
                dataTestId="activity"
                activityId={activityId}
                activityName={activityName}
            />
        </>
    );
};
