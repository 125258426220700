/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback } from 'react';
/**
 * Hooks
 */
import { useAppDispatch, useRouteParams } from 'hooks';
/**
 * Components
 */
import { Modal } from './Modal';
import { IconContainer } from 'components/common';
/**
 * Types
 */
import { TDeleteProps } from './types';
import { TConfirmationModal, WithDataTestId } from 'types';
/**
 * Assets
 */
import { ReactComponent as deleteIcon } from '@epam/assets/icons/common/action-delete-18.svg';
/**
 * Store
 */
import { handleDeleteComment } from 'store/singleFinding';
/**
 * Utils
 */
import { showModal } from 'utils';
/**
 * Expo
 */
export const Delete: React.FC<TDeleteProps & WithDataTestId> = ({
    id,
    title,
    dataTestId,
}) => {
    const dispatch = useAppDispatch();

    const { findingId } = useRouteParams();

    const handleClick = useCallback(() => {
        dispatch(handleDeleteComment(id, findingId));
    }, [dispatch, findingId, id]);

    const handleShowModalWindow = useCallback(() => {
        showModal<TConfirmationModal>(Modal, {
            onSuccess: handleClick,
            name: title,
        });
    }, [title, handleClick]);

    return (
        <IconContainer
            icon={deleteIcon}
            style={{ fill: 'var(--uui-link)' }}
            onClick={handleShowModalWindow}
            dataTestId={`${dataTestId}/comment/deleteButton`}
        />
    );
};
