/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/* eslint-disable @typescript-eslint/no-use-before-define, no-underscore-dangle */
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $wrapNodeInElement, mergeRegister } from '@lexical/utils';
import {
    $createParagraphNode,
    $createRangeSelection,
    $getSelection,
    $insertNodes,
    $isNodeSelection,
    $isRootOrShadowRoot,
    $setSelection,
    COMMAND_PRIORITY_EDITOR,
    COMMAND_PRIORITY_HIGH,
    COMMAND_PRIORITY_LOW,
    createCommand,
    DRAGOVER_COMMAND,
    DRAGSTART_COMMAND,
    DROP_COMMAND,
    EditorState,
    LexicalCommand,
    LexicalEditor,
} from 'lexical';
import { useEffect, useMemo, useRef } from 'react';

import {
    $createImageNode,
    $isImageNode,
    ImageNode,
    ImagePayload,
} from '../../nodes/Image/ImageNode';
import { TDocument } from 'types';
import { TContentImages } from '../../types';
import { urlToFile } from '../../../../../utils';

export type InsertImagePayload = Readonly<ImagePayload>;

const getDOMSelection = (targetWindow: Window | null): Selection | null =>
    (targetWindow || window).getSelection();

export const INSERT_IMAGE_COMMAND: LexicalCommand<InsertImagePayload> =
    createCommand('INSERT_IMAGE_COMMAND');

export const useDecorateChangeHandler = (
    handler: (
        images: TContentImages
    ) => (editorState: EditorState, editor: LexicalEditor) => void,
    editor: LexicalEditor | null
) => {
    const pastedFilesBuffer = useRef<Record<string, File>>({});
    const images = useRef<TContentImages>({
        removedAttachedImages: [],
        pastedImages: [],
    });
    const changeHandler = useRef(handler(images.current));

    const isInRemovedAttachments = (node: ImageNode) =>
        images.current.removedAttachedImages.some(
            (img) => img.name === node.getName()
        );
    const restoreAttachment = (node: ImageNode) => {
        const removedList = images.current.removedAttachedImages;
        const inx = removedList.findIndex((img) => img.name === node.getName());
        if (inx !== -1) {
            removedList.splice(inx, 1);
        }
    };
    const isInPasted = (node: ImageNode) =>
        images.current.pastedImages.some(
            (file) => file.name === node.getName()
        );
    const isInBuffer = (node: ImageNode) =>
        node.getName() in pastedFilesBuffer.current;
    const restorePasted = (node: ImageNode) => {
        const pastedList = images.current.pastedImages;
        const file = pastedFilesBuffer.current[node.getName()];
        if (file != null) {
            pastedList.push(file);
        } else {
            throw new Error('no file in buffer to restore!');
        }
    };
    const removePasted = (node: ImageNode) => {
        const pastedList = images.current.pastedImages;
        const inx = pastedList.findIndex(
            (file) => file.name === node.getName()
        );
        if (inx === -1) {
            throw new Error(
                `no such a file "${node.getName()}" in pasted files!`
            );
        }
        pastedList.splice(inx, 1);
    };

    useEffect(() => {
        let unmount = () => {};

        if (editor) {
            // [nodeKey, mutationType]
            const previousCall: [string, string] = ['', ''];
            unmount = editor.registerMutationListener(
                ImageNode,
                (mutatedNodes) => {
                    for (const [nodeKey, mutation] of mutatedNodes) {
                        // Sometimes by some reason mutations are duplicated, we need to skip it
                        if (
                            nodeKey === previousCall[0] &&
                            mutation === previousCall[1]
                        ) {
                            // eslint-disable-next-line no-continue
                            continue;
                        }
                        previousCall[0] = nodeKey;
                        previousCall[1] = mutation;

                        const node = ImageNode.imageNodes[nodeKey];
                        if (node == null) {
                            // For some reason sometimes initializing an images behave as modifying already existed state the state...
                            // eslint-disable-next-line no-continue
                            continue;
                        }
                        if (mutation === 'destroyed') {
                            const name = node.getName();
                            const attachment = ImageNode.imagesTable[name];
                            if (attachment) {
                                images.current.removedAttachedImages.push(
                                    attachment
                                );
                            } else if (isInPasted(node)) {
                                removePasted(node);
                            }
                        } else if (mutation === 'created') {
                            if (isInRemovedAttachments(node)) {
                                restoreAttachment(node);
                            } else if (isInBuffer(node)) {
                                restorePasted(node);
                            } else {
                                const src = node.getSrc();
                                if (src === null) {
                                    // For some reason sometimes initializing an images behave as modifying already existed state the state...
                                    // eslint-disable-next-line no-continue
                                    continue;
                                    // throw new Error(
                                    //     'No src during creation of the pasted file!'
                                    // );
                                }
                                urlToFile(
                                    src,
                                    node.getName(),
                                    `image/${node.getMimeType()}`
                                ).then((file) => {
                                    pastedFilesBuffer.current[node.getName()] =
                                        file;
                                    images.current.pastedImages.push(file);
                                    changeHandler.current(
                                        editor.getEditorState(),
                                        editor
                                    );
                                });
                            }
                        }
                    }
                }
            );
        }

        return () => {
            unmount();
        };
    }, [editor]);

    return changeHandler.current;
};

interface ImagesPluginProps {
    attachedImages?: TDocument[];
}

export default function ImagesPlugin({
    attachedImages = [],
}: ImagesPluginProps): JSX.Element | null {
    const [editor] = useLexicalComposerContext();
    const imagesTable = useMemo(
        () =>
            attachedImages.reduce<{ [name: TDocument['name']]: TDocument }>(
                (table, doc) => {
                    table[doc.name] = doc;
                    return table;
                },
                {}
            ),
        [attachedImages]
    );

    useEffect(() => {
        if (!editor.hasNodes([ImageNode])) {
            throw new Error('ImagesPlugin: ImageNode not registered on editor');
        }
        ImageNode.imageNodes = {};

        return mergeRegister(
            editor.registerCommand<InsertImagePayload>(
                INSERT_IMAGE_COMMAND,
                (payload) => {
                    const imageNode = $createImageNode(payload);
                    $insertNodes([imageNode]);
                    if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
                        $wrapNodeInElement(
                            imageNode,
                            $createParagraphNode
                        ).selectEnd();
                    }

                    return true;
                },
                COMMAND_PRIORITY_EDITOR
            ),
            editor.registerCommand<DragEvent>(
                DRAGSTART_COMMAND,
                (event) => {
                    return onDragStart(event);
                },
                COMMAND_PRIORITY_HIGH
            ),
            editor.registerCommand<DragEvent>(
                DRAGOVER_COMMAND,
                (event) => {
                    return onDragover(event);
                },
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand<DragEvent>(
                DROP_COMMAND,
                (event) => {
                    return onDrop(event, editor);
                },
                COMMAND_PRIORITY_HIGH
            )
        );
    }, [editor]);

    useEffect(() => {
        ImageNode.imagesTable = imagesTable;
        return () => {
            ImageNode.imagesTable = {};
            ImageNode.imageNodes = {};
        };
    }, [imagesTable]);

    return null;
}

const TRANSPARENT_IMAGE =
    'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
const img = document.createElement('img');
img.src = TRANSPARENT_IMAGE;

function onDragStart(event: DragEvent): boolean {
    const node = getImageNodeInSelection();
    if (!node) {
        return false;
    }
    const { dataTransfer } = event;
    if (!dataTransfer) {
        return false;
    }
    dataTransfer.setData('text/plain', '_');
    dataTransfer.setDragImage(img, 0, 0);
    dataTransfer.setData(
        'application/x-lexical-drag',
        JSON.stringify({
            data: {
                // eslint-disable-next-line no-underscore-dangle
                altText: node.__altText,
                caption: node.__caption,
                height: node.__height,
                key: node.getKey(),
                maxWidth: node.__maxWidth,
                src: node.__src,
                width: node.__width,
            },
            type: 'image',
        })
    );

    return true;
}

function onDragover(event: DragEvent): boolean {
    const node = getImageNodeInSelection();
    if (!node) {
        return false;
    }
    if (!canDropImage(event)) {
        event.preventDefault();
    }
    return true;
}

function onDrop(event: DragEvent, editor: LexicalEditor): boolean {
    const node = getImageNodeInSelection();
    if (!node) {
        return false;
    }
    const data = getDragImageData(event);
    if (!data) {
        return false;
    }
    event.preventDefault();
    if (canDropImage(event)) {
        const range = getDragSelection(event);
        node.remove();
        const rangeSelection = $createRangeSelection();
        if (range !== null && range !== undefined) {
            rangeSelection.applyDOMRange(range);
        }
        $setSelection(rangeSelection);
        editor.dispatchCommand(INSERT_IMAGE_COMMAND, data);
    }
    return true;
}

function getImageNodeInSelection(): ImageNode | null {
    const selection = $getSelection();
    if (!$isNodeSelection(selection)) {
        return null;
    }
    const nodes = selection.getNodes();
    const node = nodes[0];
    return $isImageNode(node) ? node : null;
}

function getDragImageData(event: DragEvent): null | InsertImagePayload {
    const dragData = event.dataTransfer?.getData('application/x-lexical-drag');
    if (!dragData) {
        return null;
    }
    const { type, data } = JSON.parse(dragData);
    if (type !== 'image') {
        return null;
    }

    return data;
}

declare global {
    interface DragEvent {
        rangeOffset?: number;
        rangeParent?: Node;
    }
}

function canDropImage(event: DragEvent): boolean {
    const { target } = event;
    return !!(
        target &&
        target instanceof HTMLElement &&
        !target.closest('code, span.editor-image') &&
        target.parentElement &&
        target.parentElement.closest('div.ContentEditable__root')
    );
}

function getDragSelection(event: DragEvent): Range | null | undefined {
    let range;
    const target = event.target as null | Element | Document;
    const targetWindow =
        // eslint-disable-next-line no-nested-ternary
        target == null
            ? null
            : target.nodeType === 9
            ? (target as Document).defaultView
            : (target as Element).ownerDocument.defaultView;
    const domSelection = getDOMSelection(targetWindow);
    if (document.caretRangeFromPoint) {
        range = document.caretRangeFromPoint(event.clientX, event.clientY);
    } else if (event.rangeParent && domSelection !== null) {
        domSelection.collapse(event.rangeParent, event.rangeOffset || 0);
        range = domSelection.getRangeAt(0);
    } else {
        throw Error(`Cannot get the selection when dragging`);
    }

    return range;
}
