/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Form } from './Form';
import { Modal as ModalComponent } from 'components/common';
/**
 * Types
 */
import { ModalProps } from './types';
/**
 * Constants
 */
import { ModalSizes } from 'constants/modals';
/**
 * Expo
 */
export const Modal: React.FC<ModalProps> = ({
    title,
    onSubmit,
    description,
    ...props
}) => (
    <ModalComponent
        {...props}
        withoutFooter
        title={title}
        width={ModalSizes.large}
        allowCloseOnCross={false}
        renderBody={({ abort }) => (
            <Form
                onClose={abort}
                onSubmit={onSubmit}
                description={description}
            />
        )}
    />
);
