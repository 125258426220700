/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Component } from './Component';
import { Panel } from 'components/common';
import { FieldController } from 'components/Fields';
/**
 * Utils
 */
import { cx } from '@epam/uui-core';
/**
 * Assets
 */
import css from '../style.module.scss';
/**
 * Types
 */
import { FieldProps, WithDataTestId } from 'types';
import { TRichEditorValueWithImages } from './types';
import { TRichEditorProps } from 'components/RichEditor/types';
/**
 * Expo
 */
export const RichEditorField: React.FC<
    FieldProps & WithDataTestId & { name: string }
> = ({ name, ...props }) => (
    <Panel cx={cx(css.formPaddings, css.halfWidth, css.richText)}>
        <FieldController<TRichEditorProps, TRichEditorValueWithImages>
            {...props}
            name={name}
            component={Component}
            defaultValue={{
                html: '',
            }}
        />
    </Panel>
);
