/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { ElementFormValues } from 'pages/ThreatModeling/tabs/ModelTreeTab/components/ExpandableTree/components/ItemEditor/forms/Element/schema';
import {
    mapFormValuesToElementCreatePayload,
    mapFormValuesToElementUpdatePayload,
} from 'pages/ThreatModeling/tabs/ModelTreeTab/components/ExpandableTree/components/ItemEditor/forms/Element/utils';
import { AppDispatch, TElement } from 'types';
import { ENDPOINTS } from 'constants/api';
import { api } from 'services/api';
import { Methods } from 'constants/request';
import { addElement, updateElement } from './slice';
import {
    ElementCreatePayload,
    ElementUpdatePayload,
} from 'store/threatModeling/element/types';
import { handleGetTrustBoundaries } from '../trustBoundary/dispatchers';

export const handleCreateElement =
    (form: ElementFormValues) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = ENDPOINTS.elements;
        const requestBody = mapFormValuesToElementCreatePayload(form);
        const element = await api.request<TElement, ElementCreatePayload>(
            Methods.post,
            { url, data: requestBody }
        );
        dispatch(addElement(element));
        await dispatch(handleGetTrustBoundaries(form.tmId));
    };

export const handleUpdateElement =
    (id: string, form: ElementFormValues) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = `${ENDPOINTS.elements}/${id}`;
        const requestBody = mapFormValuesToElementUpdatePayload(form);
        const updatedElement = await api.request<
            TElement,
            ElementUpdatePayload
        >(Methods.put, { url, data: requestBody });
        dispatch(updateElement(updatedElement));
        await dispatch(handleGetTrustBoundaries(form.tmId));
    };
