/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useMemo } from 'react';
/**
 * Components
 */
import { Button } from 'components/common';
import { ConfirmationModal } from '../ConfirmationModal';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
import { useRouteParams } from 'hooks';
/**
 * Constants
 */
import { StatusTypes } from 'constants/statuses';
/**
 * Types
 */
import { CompleteButtonProps } from './types';
/**
 * Store
 */
import { selectProjectAdditionalInfo } from 'store/project';
import { selectUserId } from 'store/user';
/**
 * Utils
 */
import { showModal } from 'utils';
/**
 * Assets
 */
import { ReactComponent as doneIcon } from '@epam/assets/icons/common/notification-done-18.svg';
/**
 * Expo
 */
export const CompleteButton: React.FC<CompleteButtonProps> = ({
    status,
    name,
}) => {
    const { securitySpecialists } = useSelector(selectProjectAdditionalInfo);
    const userId = useSelector(selectUserId);
    const { projectId } = useRouteParams();

    const hasPermission = useMemo(() => {
        return (
            (status === StatusTypes.pending || status === StatusTypes.active) &&
            securitySpecialists.some(({ id }) => userId === id)
        );
    }, [status, securitySpecialists, userId]);

    const handleComplete = useCallback(() => {
        showModal(ConfirmationModal, { assessment: name, projectId });
    }, [name, projectId]);

    return hasPermission ? (
        <Button
            fill="outline"
            icon={doneIcon}
            iconPosition="left"
            onClick={handleComplete}
            caption="Complete assessment"
            dataTestId="singleAssessment/buttons/complete"
        />
    ) : null;
};
