/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC } from 'react';
/**
 * Components
 */
import { MainMenu } from 'components/common';
import { UserMenu } from 'components/UserMenu';
import { ErrorPage, FlexSpacer, getErrorPageConfig } from '@epam/promo';
/**
 * Types
 */
import { TErrorViewProps } from './types';
/**
 * Expo
 */
export const ErrorView: FC<TErrorViewProps> = ({ onChangeUrl }) => (
    <>
        <MainMenu onLogoClick={onChangeUrl}>
            <FlexSpacer />
            <UserMenu onChangeRole={onChangeUrl} onLogOut={onChangeUrl} />
        </MainMenu>
        <ErrorPage {...getErrorPageConfig().default} />
    </>
);
