/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { SortVariants } from 'constants/filter';
import { StatusTypes } from 'constants/statuses';
/**
 * Types
 */
import { TInitialState } from './types';
/**
 * Expo
 */
export const INITIAL_STATE: TInitialState = {
    list: [],
    totalElements: 0,
    search: '',
    filter: {},
    sort: SortVariants.updatedAtDesc,
    pagination: { page: 1, size: 25 },
    isEmpty: true,
    inProgress: false,
    isInitialized: false,
};

export const PROJECT_REPRESENTATIVE_DEFAULT_STATUSES = [
    StatusTypes.retest,
    StatusTypes.resolved,
    StatusTypes.unresolved,
    StatusTypes.readyToRetest,
];

export const SETH_DEFAULT_STATUSES = [
    StatusTypes.retest,
    StatusTypes.resolved,
    StatusTypes.unresolved,
    StatusTypes.readyToRetest,
    StatusTypes.opened,
];
