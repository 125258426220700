/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useEffect, useRef, useState } from 'react';
import { cx } from '@epam/uui-core';
/**
 * Components
 */
import { Tooltip } from '@epam/promo';
import { Text } from 'components/common';
/**
 * Types
 */
import { TTextEllipsisProps } from './types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const TextEllipsis: React.FC<TTextEllipsisProps> = ({
    title,
    dataTestId,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const elementRef = useRef<HTMLDivElement>(null);

    const [isTooltip, setIsTooltip] = useState(false);

    useEffect(() => {
        if (!containerRef?.current || !elementRef?.current) {
            return;
        }

        const containerWidth =
            containerRef.current.getBoundingClientRect().width;
        const textWidth = elementRef.current.getBoundingClientRect().width;

        setIsTooltip(textWidth > containerWidth);
    }, [containerRef, elementRef]);

    return (
        <div ref={containerRef} className={css.ellipsisContainer}>
            <Tooltip content={isTooltip ? title : ''}>
                <div
                    ref={elementRef}
                    className={cx(isTooltip && css.textContainer)}
                >
                    <Text
                        fontSize="14"
                        lineHeight="18"
                        dataTestId={dataTestId}
                        cx={css.textWithTooltip}
                    >
                        {title}
                    </Text>
                </div>
            </Tooltip>
        </div>
    );
};
