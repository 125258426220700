import React, { ComponentProps } from 'react';
import { RecentActionContent } from './RecentActionContent';
import { LinkBlock, TextBlock, UserBlock } from './components';

export const blocksWithTestId = (testId: string) => ({
    RecentActionContent: (
        props: ComponentProps<typeof RecentActionContent>
    ) => <RecentActionContent dataTestId={testId} {...props} />,
    TextBlock: (props: ComponentProps<typeof TextBlock>) => (
        <TextBlock dataTestId={testId} {...props} />
    ),
    UserBlock: (props: ComponentProps<typeof UserBlock>) => (
        <UserBlock dataTestId={testId} {...props} />
    ),
    LinkBlock: (props: ComponentProps<typeof LinkBlock>) => (
        <LinkBlock dataTestId={testId} {...props} />
    ),
});
