/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { ScrollBars } from '@epam/promo';
import { Panel } from 'components/common';
import { ProjectItem } from '../ProjectItem';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Utils
 */
import { getPosition } from './utils';
/**
 * Types
 */
import { TProjectListProps } from './types';
/**
 * Expo
 */
export const ProjectList: React.FC<TProjectListProps> = ({
    list,
    containerRef,
    onChooseProject,
}) => (
    <div style={getPosition(containerRef)} className={css.projectListContainer}>
        <Panel
            shadow
            background="white"
            cx={css.projectList}
            dataTestId="header/chooseProjectByOrganization/projects"
        >
            <ScrollBars>
                {list.map((item) => (
                    <ProjectItem
                        key={item.id}
                        {...item}
                        onChoose={onChooseProject}
                    />
                ))}
            </ScrollBars>
        </Panel>
    </div>
);
