/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
/**
 * Hooks
 */
import { useAppForm } from 'hooks';
/**
 * Components
 */
import { UploadCard } from 'components';
import { FieldDecorator } from './FieldDecorator';
import { FieldController } from 'components/Fields';
import { FlexSpacer, ModalFooter, Panel, Text } from '@epam/promo';
import { Button, Blocker, TextInput } from 'components/common';
import { AssessmentForReport } from 'components/AssessmentForReports';
/**
 * Types
 */
import { TUploadedFile } from 'types';
import { FormProps, TFormValues } from './types';
import { TUploadCardProps } from 'components/UploadCard/types';
import { TTextInputProps } from 'components/common/TextInput/types';
import { TAssessmentForReportsProps } from 'components/AssessmentForReports/types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Utils
 */
import { showError } from 'utils';
import { showReportNotificationByState } from './utils';
/**
 * Constants
 */
import { FORM_ID_BY_REPORT_TYPE } from './constants';
import { ReportTypes, ReportState } from 'constants/reports';
/**
 * Expo
 */
export const ReportCreationForm: React.FC<FormProps> = ({
    abort,
    success,
    onSubmit,
    creationType,
    dataTestId,
}) => {
    const [inProgress, setInProgress] = useState(false);

    const methods = useAppForm<TFormValues>({
        id: FORM_ID_BY_REPORT_TYPE[creationType],
        defaultValues: { files: [] },
    });

    const withLink = creationType === ReportTypes.withLink;
    const withFiles = creationType === ReportTypes.withFiles;
    const withDocxFile = creationType === ReportTypes.withDocxFile;

    const handleSubmit = methods.handleSubmit(async (values: TFormValues) => {
        if ((withFiles || withDocxFile) && values.files.length < 1) {
            showError('At least one file should be uploaded');

            return;
        }

        setInProgress(true);

        try {
            await onSubmit({ ...values, creationType });

            showReportNotificationByState(values);

            success('Success action');
        } finally {
            setInProgress(false);
        }
    });

    const handleSaveAsPublished = () => {
        methods.setValue('state', ReportState.published);

        handleSubmit();
    };

    const handleSaveAsDraft = () => {
        methods.setValue('state', ReportState.draft);

        handleSubmit();
    };

    return (
        <>
            <Blocker isFixed isEnabled={inProgress} />
            <Panel cx={css.formContainer}>
                {withDocxFile && (
                    <Text color="gray60" cx={css.text}>
                        Upload docx file from your computer to generate a pdf
                        report with watermarks and digital signature
                    </Text>
                )}
                <FormProvider {...methods}>
                    <FieldDecorator>
                        <FieldController<TTextInputProps>
                            isRequired
                            name="title"
                            maxLength={1000}
                            label="Report name"
                            component={TextInput}
                            placeholder="e.g. Threat modeling report"
                            dataTestId={`${dataTestId}/fields/name`}
                        />
                    </FieldDecorator>
                    <FieldDecorator>
                        <FieldController<TAssessmentForReportsProps>
                            isRequired
                            name="assessmentId"
                            component={AssessmentForReport}
                            dataTestId={`${dataTestId}/fields/assessment`}
                        />
                    </FieldDecorator>
                    {(withDocxFile || withFiles) && (
                        <FieldDecorator>
                            <FieldController<TUploadCardProps, TUploadedFile[]>
                                name="files"
                                component={UploadCard}
                                infoText={
                                    withFiles
                                        ? 'Up to 15 files. Limit for 1 file is 50MB.'
                                        : 'Up to 1 file. Limit for the file is 50MB.'
                                }
                                dataTestId={`${dataTestId}/fields/attachments`}
                                single={withDocxFile}
                                fileFormat={withDocxFile ? '.docx' : undefined}
                            />
                        </FieldDecorator>
                    )}
                    {withLink && (
                        <FieldDecorator>
                            <FieldController<TTextInputProps>
                                isRequired
                                name="url"
                                maxLength={256}
                                label="Report link"
                                component={TextInput}
                                dataTestId="reports/creationModal/fields/link"
                            />
                        </FieldDecorator>
                    )}
                </FormProvider>
            </Panel>
            <ModalFooter borderTop>
                <FlexSpacer />
                <Button
                    fill="none"
                    color="neutral"
                    caption="Cancel"
                    onClick={abort}
                    dataTestId="reports/creationModal/buttons/cancel"
                />
                <Button
                    fill="outline"
                    caption="Save as draft"
                    onClick={handleSaveAsDraft}
                    dataTestId="reports/creationModal/buttons/submitAsDraft"
                />
                <Button
                    color="primary"
                    caption="Save"
                    onClick={handleSaveAsPublished}
                    dataTestId="reports/creationModal/buttons/submit"
                />
            </ModalFooter>
        </>
    );
};
