/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, forwardRef } from 'react';
/**
 * Types
 */
import { WithChildren } from 'types';
import { TProps, TChild } from './types';
/**
 * Utils
 */
import copy from 'copy-to-clipboard';
/**
 * Expo
 */
export const CopyToClipboard: React.FC<TProps & WithChildren> = forwardRef(
    ({ text, children, onCopy }, ref) => {
        const elem = React.Children.only(children) as TChild;

        const handleCopy = useCallback(() => {
            copy(text);

            if (onCopy) {
                onCopy();
            }
        }, [onCopy, text]);

        return React.cloneElement(elem, { ref, onClick: handleCopy });
    }
);
