/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { Icon } from '@epam/uui-core';
/**
 * Assets
 */
import css from './style.module.scss';
import { ReactComponent as fileIcon } from '@epam/assets/icons/common/file-file-24.svg';
import { ReactComponent as pdfIcon } from '@epam/assets/icons/common/file-file_pdf-24.svg';
import { ReactComponent as mailIcon } from '@epam/assets/icons/common/file-file_eml-24.svg';
import { ReactComponent as docIcon } from '@epam/assets/icons/common/file-file_word-24.svg';
import { ReactComponent as textIcon } from '@epam/assets/icons/common/file-file_text-24.svg';
import { ReactComponent as imgIcon } from '@epam/assets/icons/common/file-file_image-24.svg';
import { ReactComponent as excelIcon } from '@epam/assets/icons/common/file-file_excel-24.svg';
import { ReactComponent as videoIcon } from '@epam/assets/icons/common/file-file_video-24.svg';
import { ReactComponent as tableIcon } from '@epam/assets/icons/common/file-file_table-24.svg';
import { getExtensionFromFileName } from 'utils';
/**
 * Expo
 */
export const getIconProps = (fileName: string): { icon: Icon; cx: string } => {
    const extension = getExtensionFromFileName(fileName).toLowerCase();

    switch (extension) {
        case 'doc':
        case 'docx': {
            return {
                icon: docIcon,
                cx: css.docIcon,
            };
        }
        case 'xls':
        case 'xlsx': {
            return {
                icon: excelIcon,
                cx: css.excelIcon,
            };
        }
        case 'pdf': {
            return {
                icon: pdfIcon,
                cx: css.pdfIcon,
            };
        }
        case 'gif':
        case 'jpg':
        case 'jpeg':
        case 'svg':
        case 'png':
        case 'webp': {
            return {
                icon: imgIcon,
                cx: css.imgIcon,
            };
        }
        case 'avi':
        case 'mov':
        case 'mp4':
        case 'wmw':
        case 'mkv': {
            return {
                icon: videoIcon,
                cx: css.videoIcon,
            };
        }
        case 'csv':
        case 'xml': {
            return {
                icon: tableIcon,
                cx: css.tableIcon,
            };
        }
        case 'rtf':
        case 'txt': {
            return {
                icon: textIcon,
                cx: css.textIcon,
            };
        }
        case 'eml':
        case 'emlx': {
            return {
                icon: mailIcon,
                cx: css.mailIcon,
            };
        }
        default: {
            return {
                icon: fileIcon,
                cx: css.fileIcon,
            };
        }
    }
};
