/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useState } from 'react';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
/**
 * Components
 */
import { Status } from 'components/common';
import { FlexRow, Text, Panel } from '@epam/promo';
/**
 * Types
 */
import { TProjectItem } from './types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Utils
 */
import { cx } from '@epam/uui-core';
import { formatDate } from 'utils';
/**
 * Store
 */
import { selectProjectData } from 'store/project';
/**
 * Expo
 */
export const ProjectItem: React.FC<TProjectItem> = ({
    id,
    name,
    status,
    last_update,
    onChoose,
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const { id: selectedProjectId } = useSelector(selectProjectData);

    const handleChoose = useCallback(() => {
        onChoose(id);
    }, [id, onChoose]);

    const handleMouseEnter = useCallback(() => {
        setIsHovered(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setIsHovered(false);
    }, []);

    return (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <FlexRow
                key={id}
                cx={cx(
                    css.rowContainer,
                    (isHovered || selectedProjectId === id) && css.hovered
                )}
                onClick={handleChoose}
            >
                <div className={css.title}>
                    <div className={css.status}>
                        <Status type={status} />
                    </div>
                    <Panel>
                        <Text
                            cx={css.textWithoutPadding}
                            fontSize="16"
                            lineHeight="24"
                        >
                            {name}
                        </Text>
                        <Text
                            cx={css.textWithoutPadding}
                            fontSize="16"
                            lineHeight="24"
                            color="gray60"
                        >
                            Last changed {formatDate(last_update)}
                        </Text>
                    </Panel>
                </div>
            </FlexRow>
        </div>
    );
};
