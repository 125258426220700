/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback } from 'react';
/**
 * Components
 */
import { AttachFilesModal } from 'components/modals';
import { RichEditor2 } from 'components/common/RichEditor2';
import { AttachmentList } from 'components/AttachmentList';
import { ActionButtons } from '../ActionButtons';
/**
 * Utils
 */
import { showModal } from 'utils';
/**
 * Types
 */
import { TAttachProps, TCreateCommentProps } from './types';
import { TAttachment } from 'types';
/**
 * Expo
 */
export const CreateComment: React.FC<TCreateCommentProps> = ({
    attachments,
    onCancel,
    onSubmit,
    onChangeComment,
    onChangeAttachments,
    isDisabledAddButton,
    dataTestId,
}) => {
    const removeAttachment = useCallback(
        (attachmentId: string): void => {
            onChangeAttachments(
                attachments.filter(({ id }) => id !== attachmentId)
            );
        },
        [attachments, onChangeAttachments]
    );

    const addAttachments = useCallback(
        async (payload: TAttachment[]) => {
            onChangeAttachments([...attachments, ...payload]);
        },
        [attachments, onChangeAttachments]
    );

    const handleAddAttachment = useCallback(() => {
        showModal<TAttachProps>(AttachFilesModal, {
            onSubmit: addAttachments,
            dataTestId,
            successButtonCaption: 'Attach',
        });
    }, [addAttachments, dataTestId]);

    return (
        <>
            <RichEditor2
                onChange={onChangeComment}
                placeholder="Type something"
            />
            {!attachments.length ? null : (
                <AttachmentList
                    columnsInRow={3}
                    list={attachments}
                    onRemove={removeAttachment}
                />
            )}
            <ActionButtons
                onCancel={onCancel}
                onSubmit={onSubmit}
                handleAddAttachment={handleAddAttachment}
                isDisabled={isDisabledAddButton}
                dataTestId={dataTestId}
            />
        </>
    );
};
