/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createAction } from '@reduxjs/toolkit';
/**
 * Utils
 */
import { createActionContext } from 'utils/store';
/**
 * Types
 */
import { TShortPolicy } from 'types';
/**
 * Expo
 */
const createActionType = createActionContext('POLICIES');

export const getProjectPolicyList = createAction<string>(
    createActionType('GET_PROJECT_POLICY_LIST')
);
export const saveList = createAction<TShortPolicy[]>(
    createActionType('SAVE_LIST')
);
export const setInProgress = createAction<boolean>(
    createActionType('SET_IN_PROGRESS')
);
