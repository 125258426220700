/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback } from 'react';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
import { useRedirect, useRole } from 'hooks';
/**
 * Components
 */
import { AssessmentsList } from '../Table';
import { renderNoAssessments } from '../renderNoAssessments';
import { Panel } from 'components/common';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
/**
 * Types
 */
import { DataRowProps } from '@epam/uui-core';
import { TAssessment } from 'types';
/**
 * Store
 */
import { selectInProgress, selectList } from 'store/assessments';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const Content: React.FC<{ hasSearch: boolean }> = ({ hasSearch }) => {
    const { isOperationsManager } = useRole();

    const redirect = useRedirect();

    const list = useSelector(selectList);
    const inProgress = useSelector(selectInProgress);

    const handleRowClick = useCallback(
        ({ value }: DataRowProps<TAssessment, string | number>) => {
            const singleAssessmentPath = isOperationsManager
                ? PAGES.singleAssessmentOperationsManager
                : PAGES.singleAssessment;

            if (value?.id) {
                redirect(singleAssessmentPath, {
                    assessmentId: value?.id,
                });
            }
        },
        [isOperationsManager, redirect]
    );

    if (inProgress && !list.length) {
        return null;
    }

    return (
        <Panel background="white" cx={css.container}>
            {!list.length ? (
                renderNoAssessments(hasSearch, isOperationsManager)
            ) : (
                <AssessmentsList data={list} onRowClick={handleRowClick} />
            )}
        </Panel>
    );
};
