/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createAction } from '@reduxjs/toolkit';
/**
 * Expo
 */
export const findingsDidUpdateAction = createAction('FINDINGS_DID_UPDATE');
export const activityStatusDidUpdateAction = createAction(
    'ACTIVITY_STATUS_DID_UPDATE'
);
export const attachmentsDidUpdateAction = createAction(
    'ATTACHMENTS_DID_UPDATE'
);
export const resetSingleProjectReducers = createAction(
    'RESET_SINGLE_PROJECT_REDUCERS'
);
