/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, {
    FC,
    useRef,
    useMemo,
    useState,
    useContext,
    useCallback,
} from 'react';
import { cx } from '@epam/uui-core';
import { Editor, EditorState, getDefaultKeyBinding, RichUtils } from 'draft-js';
/**
 * Components
 */
import { ScrollBars } from '@epam/promo';
import { Controls } from './components/Controls';
import { Attachments } from './components/Attachments';
/**
 * Context
 */
import { EditorContext } from './context';
/**
 * Utils
 */
import { v4 as uuidv4 } from 'uuid';
import { getRawProps } from 'utils';
import {
    getBlockStyle,
    mediaBlockRenderer,
    getCurrentEntityParams,
    handleKeyCommandDefault,
    hasSelectionInCodeBlock,
    onMapKeyToEditorCommand,
    checkIfImage,
    getImageName,
    insertImage,
    handlePastedTextWithImageEntities,
    handleEnterInCodeBlock,
    handlePasteTextInCodeBlock,
    isListBlock,
} from './utils';
/**
 * Constants
 */
import { blockRenderMap, STYLE_MAP } from './constants';
import { EntityTypes, HandleValue } from 'constants/richTextEditor';
/**
 * Types
 */
import { TRichEditorProps } from './types';
/**
 * Assets
 */
import './style.scss';
import 'draft-js/dist/Draft.css';
/**
 * Expo
 */
const RichEditorInput: FC<TRichEditorProps> = ({
    isInvalid,
    className,
    dataTestId,
    isDisabled,
    placeholder,
    minRows = 2,
    withInlineImages,
    onBlur,
    onFocus,
    onKeyDown,
    onKeyCommand,
}) => {
    const editor = useRef<Editor>(null);

    const [isFocused, setIsFocused] = useState(false);

    const { editorState, setEditorState, onChange, readOnly } =
        useContext(EditorContext);

    const handleFocusEditor = useCallback(() => {
        if (isFocused || !editor.current) return;

        setIsFocused(true);

        onFocus?.();
    }, [isFocused, onFocus]);

    const handleBlur = useCallback(
        (event: React.SyntheticEvent<HTMLElement, Event>) => {
            setIsFocused(false);

            onBlur?.(event);
        },
        [onBlur]
    );

    const handleBeforeInput = (chars: string, state: EditorState) => {
        const { type } = getCurrentEntityParams(state);

        return type === EntityTypes.image
            ? HandleValue.handled
            : HandleValue.notHandled;
    };

    const onReturn = useCallback(
        (event: React.KeyboardEvent) => {
            if (hasSelectionInCodeBlock(editorState)) {
                const { handleValue, editorState: newEditorState } =
                    handleEnterInCodeBlock(event, editorState);

                if (handleValue === HandleValue.handled) {
                    setEditorState(newEditorState);
                    return HandleValue.handled;
                }
            }
            const blockType = RichUtils.getCurrentBlockType(editorState);
            if (event.shiftKey && isListBlock(blockType)) {
                setEditorState(RichUtils.insertSoftNewline(editorState));
                return HandleValue.handled;
            }

            return HandleValue.notHandled;
        },
        [editorState, setEditorState]
    );

    const handleKeyCommand = useCallback(
        (command: string, oldEditorState: EditorState) => {
            if (onKeyCommand) {
                const { handleValue } = onKeyCommand(command, oldEditorState);

                if (handleValue === HandleValue.handled) {
                    return HandleValue.handled;
                }
            }

            const { handleValue, editorState: newEditorState } =
                handleKeyCommandDefault(command, oldEditorState);

            if (handleValue === HandleValue.handled) {
                onChange(newEditorState);
                return HandleValue.handled;
            }

            return HandleValue.notHandled;
        },
        [onKeyCommand, onChange]
    );

    const mapKeyToEditorCommand = useCallback(
        (event: React.KeyboardEvent) => {
            const keyDownResult = onKeyDown?.(event);

            if (keyDownResult === HandleValue.handled) {
                return null;
            }

            const { handleValue, editorState: newEditorState } =
                onMapKeyToEditorCommand(event, editorState);

            if (handleValue === HandleValue.handled) {
                onChange(newEditorState);
                return null;
            }

            return getDefaultKeyBinding(event);
        },
        [editorState, onChange, onKeyDown]
    );

    const handlePastedFiles = useCallback(
        ([file]: File[]) => {
            if (!withInlineImages || !checkIfImage(file.type)) {
                return HandleValue.notHandled;
            }

            const imageName = getImageName(file, uuidv4());
            const fileWithNeededName = new File([file], imageName, {
                type: file.type,
            });

            onChange(insertImage(editorState, imageName, fileWithNeededName));

            return HandleValue.handled;
        },
        [editorState, withInlineImages, onChange]
    );

    const handlePastedText = useCallback(
        (text: string, html: string, currentEditorState: EditorState) => {
            if (hasSelectionInCodeBlock(currentEditorState)) {
                onChange(handlePasteTextInCodeBlock(text, currentEditorState));

                return HandleValue.handled;
            }

            if (!withInlineImages || !html) return HandleValue.notHandled;

            const { handleValue, editorState: newEditorState } =
                handlePastedTextWithImageEntities(html, currentEditorState);

            if (handleValue === HandleValue.handled) {
                onChange(newEditorState);
            }

            return handleValue;
        },
        [onChange, withInlineImages]
    );

    const rawProps = useMemo(
        () => getRawProps({ dataTestId: `${dataTestId}/input` }),
        [dataTestId]
    );

    return (
        <>
            <div
                {...rawProps}
                tabIndex={-1}
                onBlur={handleBlur}
                onFocus={handleFocusEditor}
                className={cx(
                    className,
                    'RichEditor-root',
                    readOnly && 'RichEditor-root__readOnly',
                    isInvalid && 'RichEditor-root__invalid',
                    isDisabled && 'RichEditor-root__disabled',
                    !isInvalid && isFocused && 'RichEditor-root__focused'
                )}
            >
                <div
                    className="RichEditor-editor"
                    style={{ minHeight: `${minRows * 50}px` }}
                >
                    <ScrollBars cx="RichEditor-scrollbar">
                        <div className="RichEditor-editor-container">
                            <Editor
                                spellCheck
                                ref={editor}
                                editorState={editorState}
                                placeholder={placeholder}
                                customStyleMap={STYLE_MAP}
                                readOnly={readOnly || isDisabled}
                                onChange={onChange}
                                blockRenderMap={blockRenderMap}
                                handleReturn={onReturn}
                                blockStyleFn={getBlockStyle}
                                handleKeyCommand={handleKeyCommand}
                                blockRendererFn={mediaBlockRenderer}
                                keyBindingFn={mapKeyToEditorCommand}
                                handleBeforeInput={handleBeforeInput}
                                handlePastedFiles={handlePastedFiles}
                                handlePastedText={handlePastedText}
                            />
                        </div>
                    </ScrollBars>
                    <Attachments />
                </div>
            </div>
        </>
    );
};

export { RichEditorInput, Controls };
