/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Expo
 */
export const BACKGROUND_COLORS = [
    '#A72014',
    '#89370E',
    '#995A00',
    '#669900',
    '#0F9E9E',
    '#00689B',
    '#3F0F9E',
    '#860F9E',
];

export const FONT_SIZE_MEDIUM = '10';
export const FONT_SIZE_LARGE = '14';
