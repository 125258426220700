/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, memo } from 'react';
/**
 * Types
 */
import { THandlers } from '../types';
import { TProjectTargetsActionLog } from 'types';
/**
 * Utils
 */
import {
    DefaultRecentAction,
    useHref,
} from 'components/RecentActions/RecentAction/DefaultRecentAction';
/**
 * Expo
 */
export const TargetCreated: FC<TProjectTargetsActionLog & THandlers> = memo(
    ({
        getTargetsHref,
        author: { name },
        details: {
            current_state: { name: targetName },
        },
    }) => {
        const href = useHref({ getHref: getTargetsHref });
        return (
            <DefaultRecentAction
                dataTestId="targetCreated"
                user={name}
                action="created target"
                target={targetName}
                href={href}
            />
        );
    }
);
