/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Expo
 */
export const VALIDATION_MESSAGE = 'Please fill in this field';
export const NUMBER_IN_RANGE_VALIDATION_MESSAGE =
    'Value must be in range {min} - {max}';
export const GENERATE_REPORT_VALIDATION_MESSAGE = 'Select at least one chapter';
export const REQUEST_DEMO_EMAIL_VALIDATION_MESSAGE =
    'Enter a valid email address to use as your business email.';

const firstOrgV4 =
    '4.0#CVSS:4.0/AV:[NALP]/AC:[LH]/AT:[NP]/PR:[NLH]/UI:[NPA]/VC:[NLH]/VI:[NLH]/VA:[NLH]/SC:[NLH]/SI:[NLH]/SA:[NLH]';
const firstOrgV3 =
    '3.[01]#CVSS:3.[01]/AV:[NALP]/AC:[LH]/PR:[NLH]/UI:[NR]/S:[UC]/C:[NLH]/I:[NLH]/A:[NLH]';
export const PATTERNS = {
    url: /^\bhttps?:\/{2}[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|]$/,
    email: /^(?=.{1,64}@)[a-zA-Z0-9]+(\.?[a-zA-Z0-9+_-]+){0,64}@[^-][a-z0-9-]+(\.[a-z0-9-]+){0,64}(\.[a-z]{2,})$/,
    firstOrg: new RegExp(
        `^https://(www.)?first.org/cvss/calculator/(${firstOrgV4}|${firstOrgV3})([-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|])?`
    ),
};

export const PASSWORD_VALIDATION_MESSAGES = {
    isNotStrong: "Password isn't strong enough",
    isNotMatch: "Passwords don't match. Please, check both passwords",
};
