/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useMemo } from 'react';
/**
 * Components
 */
import { FlexRow } from '../FlexItems';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import type { TFlexRowProps } from '../FlexItems/FlexRow';
import type { FC } from 'react';
/**
 * Utils
 */
import { cx } from '@epam/uui-core';

export type TPanelsContainerProps = Omit<TFlexRowProps, 'size'> & {
    direction?: 'horizontal' | 'vertical';
    // A number would be treated as a percent, a string as is
    size?: number | string;
};

/**
 * The container for composing other panels
 * It takes carry of gaps and way how to compose elements inside
 */
export const PanelsContainer: FC<TPanelsContainerProps> = ({
    children,
    direction = 'horizontal',
    size,
    cx: cxProp,
    ...rest
}) => {
    const style = useMemo(
        () =>
            size != null
                ? {
                      style: {
                          width: typeof size === 'number' ? `${size}%` : size,
                      },
                  }
                : {},
        [size]
    );
    return (
        <FlexRow
            alignItems="top"
            rawProps={style}
            cx={cx(css.container, css[direction], cxProp)}
            {...rest}
        >
            {children}
        </FlexRow>
    );
};
