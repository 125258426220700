/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createAction } from '@reduxjs/toolkit';
/**
 * Utils
 */
import { createActionContext } from 'utils/store';
/**
 * Types
 */
import { TResponse } from './types';
/**
 * Expo
 */
const createActionType = createActionContext('ACTION_LOG');

export const getList = createAction<{
    page?: number;
    size?: number;
    person_id?: string;
}>(createActionType('GET_LIST'));
export const saveList = createAction<TResponse>(createActionType('SAVE_LIST'));
export const setInProgress = createAction<boolean>(
    createActionType('SET_IN_PROGRESS')
);
export const resetData = createAction(createActionType('RESET_DATA'));
