/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Hooks
 */
import { useEffect, useCallback, useState } from 'react';
/**
 * Constants
 */
import { Methods } from 'constants/request';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Expo
 */
export function useSelectOptions<T>(url: string): T | [] {
    const [options, setOptions] = useState<T | []>([]);

    const handleFetchList = useCallback(async () => {
        const list = await api.request<T>(Methods.get, { url });

        setOptions(list);
    }, [url]);

    useEffect(() => {
        handleFetchList();
    }, [handleFetchList]);

    return options;
}
