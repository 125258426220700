/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { TextInput } from 'components/common';
import { EntryPoints } from '../common/AttackSurfaceEntryPoints';
import { AssetFootprints } from '../common/AssetFootprints';
import { FC, memo } from 'react';
import {
    handleCreateTechnology,
    handleUpdateTechnology,
} from 'store/threatModeling/technology/dispatchers';
import { selectTechnologiesCount } from 'store/threatModeling/technology/selectors';
import { AppDispatch } from 'types';
import { FormFieldsProps } from '../../../../types';
import { createEditableItemCard } from '../../../createEditableItemCard';
import { TwoFieldsRow, HalfFieldRow } from '../common';
import { createEditableField } from '../common/EditableField/EditableField';
import { TECHNOLOGY_READABLE_ID_PREFIX } from './constants';
import { technologyFormSchema, TechnologyFormValues } from './schema';

const TechnologyEditableField = createEditableField<TechnologyFormValues>();

const TechnologyFormFields: FC<FormFieldsProps<TechnologyFormValues>> = () => {
    return (
        <>
            <TwoFieldsRow>
                <TechnologyEditableField
                    name="readableId"
                    label="Technology ID"
                    controllerComponent={TextInput}
                    controllerProps={{
                        // TODO: remove hardcoded value
                        placeholder: 'Enter a text',
                        isReadonly: true,
                    }}
                    dataTestId="technologyForm/readableId"
                />
                <TechnologyEditableField
                    name="name"
                    label="Technology name"
                    controllerComponent={TextInput}
                    controllerProps={{
                        placeholder: 'Enter a text',
                    }}
                    dataTestId="technologyForm/name"
                />
            </TwoFieldsRow>
            <AssetFootprints />
            <HalfFieldRow>
                <EntryPoints />
            </HalfFieldRow>
        </>
    );
};

export const TechnologyItemCard = memo(
    createEditableItemCard(TechnologyFormFields, {
        formSchema: technologyFormSchema,
        onCreate: (values) => {
            return async (dispatch: AppDispatch) => {
                await dispatch(handleCreateTechnology(values));
            };
        },
        // TODO: fix during update implementation
        onEdit: (id, values) => {
            return async (dispatch: AppDispatch) => {
                await dispatch(handleUpdateTechnology(id, values));
            };
        },
        readableIdPrefix: TECHNOLOGY_READABLE_ID_PREFIX,
        countSelector: selectTechnologiesCount,
    })
);

TechnologyItemCard.displayName = 'TechnologyItemCard';
