/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
import { useProjectType, useRole } from 'hooks';
/**
 * Components
 */
import { FlexCell } from '@epam/promo';
import { Info } from './components/Info';
import {
    AssessmentsHistory,
    Attachments,
    Integrations,
    Policies,
} from './components';
/**
 * Constants
 */
import { FEATURE_FLAGS } from 'constants/featureFlags';
/**
 * Store
 */
import { selectFlag } from 'store/featureFlags';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Utils
 */
import { ProjectRecentActions } from 'components/ProjectRecentActions';

/**
 * Expo
 */
export const ProjectInfoPanels: React.FC = () => {
    const { isBasic } = useProjectType();
    const { isOperationsManager, isProjectRepresentative } = useRole();

    const integrationWithAzureFeatureFlag = useSelector(
        selectFlag(FEATURE_FLAGS.integrationWithAzure)
    );

    const canSeeIntegration =
        isProjectRepresentative ||
        (isOperationsManager && (isBasic || integrationWithAzureFeatureFlag));

    return (
        <div className={css.sideContainer}>
            <FlexCell cx={css.leftSide}>
                <Info />
                <Attachments />
                {canSeeIntegration && <Integrations />}
            </FlexCell>
            <FlexCell cx={css.side}>
                <AssessmentsHistory />
                <Policies />
                <ProjectRecentActions dataTestId="projectInfo" />
            </FlexCell>
        </div>
    );
};
