/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Hooks
 */
import { useParams } from 'react-router-dom';
import { useOnMount } from 'hooks';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
/**
 * Utils
 */
import { handleGetFileUrl } from 'components/DocumentList/utils';
/**
 * Components
 */
import { Blocker } from 'components/common/Blocker';
/**
 * Expo
 */

export const FilesPreview: React.FC = () => {
    const [inProgress, setInProgress] = React.useState(true);
    const { id } = useParams<{ id: string }>();

    useOnMount(async () => {
        try {
            if (id == null) return;

            const url = await handleGetFileUrl(id, ENDPOINTS.files);
            window.location.replace(url);
        } finally {
            setInProgress(false);
        }
    });

    return <Blocker isEnabled={inProgress} />;
};
