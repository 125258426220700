/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, memo } from 'react';
/**
 * Components
 */
import { blocksWithTestId } from 'components/RecentActions/RecentAction/utils';
/**
 * Types
 */
import { THandlers } from '../types';
import { TProjectAssessmentActionLog } from 'types';
/**
 * Expo
 */

const { RecentActionContent, UserBlock, LinkBlock, TextBlock } =
    blocksWithTestId('assessmentScopeManaged');
export const AssessmentScopeManaged: FC<
    TProjectAssessmentActionLog & THandlers
> = memo(
    ({
        author,
        getAssessmentHref,
        details: {
            current_state: { id, name: assessmentName },
        },
    }) => (
        <RecentActionContent>
            <UserBlock text={author.name} />
            <TextBlock text="changed assessment's" />
            <LinkBlock text={assessmentName} href={getAssessmentHref(id)} />
            <TextBlock text="scope" />
        </RecentActionContent>
    )
);
