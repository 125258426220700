/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { memo } from 'react';
/**
 * Types
 */
import { THandlers } from '../types';
import { TProjectFindingActionLog, WithDataTestId } from 'types';
/**
 * Utils
 */
import {
    DefaultRecentAction,
    useHref,
} from 'components/RecentActions/RecentAction/DefaultRecentAction';
import { getFindingName } from 'components/FindingInformation/utils';
/**
 * Expo
 */
export const FindingCreated: React.FC<
    TProjectFindingActionLog & THandlers & WithDataTestId
> = memo(
    ({
        getFindingHref,
        author: { name },
        details: {
            current_state: { id, summary, composite_id },
        },
    }) => {
        const href = useHref({ getHref: () => getFindingHref(id) });
        return (
            <DefaultRecentAction
                dataTestId="findingCreated"
                user={name}
                action="created finding"
                target={getFindingName(composite_id, summary)}
                href={href}
            />
        );
    }
);
