/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback } from 'react';
/**
 * Hooks
 */
import { useAppDispatch } from 'hooks';
/**
 * Components
 */
import { Modal } from './Modal';
import { IconContainer } from 'components/common';
/**
 * Types
 */
import { TDeleteProps } from './types';
import { TConfirmationModal } from 'types';
/**
 * Assets
 */
import { ReactComponent as deleteIcon } from '@epam/assets/icons/common/action-delete-18.svg';
/**
 * Store
 */
import { handleDeleteReport } from 'store/reports';
/**
 * Utils
 */
import { showModal } from 'utils';
/**
 * Expo
 */
export const Delete: React.FC<TDeleteProps> = ({ id, title }) => {
    const dispatch = useAppDispatch();

    const handleClick = useCallback(() => {
        dispatch(handleDeleteReport(id));
    }, [id, dispatch]);

    const handleShowModalWindow = useCallback(() => {
        showModal<TConfirmationModal>(Modal, {
            onSuccess: handleClick,
            name: title,
        });
    }, [title, handleClick]);

    return (
        <IconContainer
            style={{ fill: 'var(--uui-neutral-60)' }}
            icon={deleteIcon}
            onClick={handleShowModalWindow}
            dataTestId="reports/list/item/deleteButton"
        />
    );
};
