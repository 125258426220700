/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback } from 'react';
/**
 * Hooks
 */
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useRedirectToDefaultPage } from 'hooks';
/**
 * Components
 */
import { Dropdown } from '@epam/promo';
import { DropdownBody } from './DropdownBody';
import { DropdownTarget } from './DropdownTarget';
/**
 * Utils
 */
import { definePlatformType } from 'utils/system';
/**
 * Types
 */
import { TUserMenuProps } from './types';
/**
 * Store
 */
import { handleLogout, handleSetCurrentRole } from 'store/user';
import { resetSingleProjectReducers } from 'store/middleware/backgroundUpdate/actions';
/**
 * Expo
 */
export const UserMenu: React.FC<TUserMenuProps> = ({
    onLogOut,
    onChangeRole,
}) => {
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const redirectToDefaultPage = useRedirectToDefaultPage();

    const handleLogOut = useCallback(async () => {
        await dispatch(handleLogout());
        onLogOut?.();
    }, [dispatch, onLogOut]);

    const handleChangeUserRole = useCallback(
        async (roleId: number) => {
            const { projectId, role } = await dispatch(
                handleSetCurrentRole(roleId)
            );

            const platform = definePlatformType(pathname);

            redirectToDefaultPage(role, projectId, platform);

            onChangeRole?.();

            dispatch(resetSingleProjectReducers());
        },
        [pathname, onChangeRole, dispatch, redirectToDefaultPage]
    );

    return (
        <Dropdown
            renderTarget={(props) => <DropdownTarget {...props} />}
            renderBody={({ onClose }) => (
                <DropdownBody
                    onClose={onClose}
                    onLogOut={handleLogOut}
                    onChangeRole={handleChangeUserRole}
                />
            )}
        />
    );
};
