/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React from 'react';
import { Button, FlexRow, Text } from 'components/common';
import { FlexCell, FlexSpacer } from '@epam/promo';
import { CompleteButton } from '../CompleteButton';
import { EditButton } from '../EditButton';
import { Props } from './types';
import css from './style.module.scss';
import { StatusTypes } from 'constants/statuses';
import { useAppDispatch, useRole } from 'hooks';
import { ReactComponent as replayIcon } from '@epam/assets/icons/common/media-replay-18.svg';
import { handleRetestAssessment } from 'store/assessment';
import { RequestRetestButton } from 'pages/SingleAssessment/components/RequestRetestButton';

/**
 * Expo
 */
export const Header: React.FC<Props> = ({ id, name, status }) => {
    const dispatch = useAppDispatch();
    const { isProjectRepresentative, isOperationsManager } = useRole();
    const canEdit = isProjectRepresentative && status === StatusTypes.pending;
    const canRequestRetest =
        isProjectRepresentative && status === StatusTypes.completed;
    const canStartRetest =
        isOperationsManager && status === StatusTypes.completed;

    return (
        <FlexCell>
            <FlexRow>
                <div className={css.title}>
                    <Text
                        color="gray5"
                        fontSize="24"
                        lineHeight="30"
                        dataTestId="singleAssessment/title"
                    >
                        {name}
                    </Text>
                </div>
                <FlexSpacer />
                {!isProjectRepresentative && (
                    <CompleteButton status={status} name={name} />
                )}
                {canEdit && <EditButton />}
                {canRequestRetest && <RequestRetestButton id={id} />}
                {canStartRetest && (
                    <Button
                        fill="outline"
                        icon={replayIcon}
                        iconPosition="left"
                        onClick={() => dispatch(handleRetestAssessment(id))}
                        caption="Start retest"
                        dataTestId="singleAssessment/buttons/startRetest"
                    />
                )}
            </FlexRow>
        </FlexCell>
    );
};
