/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { format, sub } from 'date-fns';
/**
 * Types
 */
import { TInitialState } from './types';
/**
 * Constants
 */
import { DATE_FORMATS } from 'constants/dates';
/**
 * Expo
 */
export const INITIAL_STATE: TInitialState = {
    notViewedCount: 0,
    notificationsListDropdown: {
        idList: [],
        itemById: {},
    },
    notificationsListPage: {
        idList: [],
        itemById: {},
    },
    isInitialized: false,
};

export const createdFrom = format(
    sub(new Date(), { months: 1 }),
    DATE_FORMATS.forApi.date
);
