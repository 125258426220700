/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { useCallback } from 'react';
import { LinkButton, Switch } from 'components/common';
import { FlexRow, FlexSpacer, Panel } from '@epam/promo';
import css from './style.module.scss';
import { PickerFooterProps } from '@epam/uui-core';

export function SelectFooter<TOption>({
    view: { selectAll },
    showSelected,
}: PickerFooterProps<TOption, TOption[keyof TOption]>): JSX.Element {
    const handleSelectAll = useCallback(() => {
        if (!selectAll) return;

        const { onValueChange, value } = selectAll;

        onValueChange(!value);
    }, [selectAll]);

    return (
        <Panel background="white" cx={css.footerContainer}>
            <FlexRow>
                <Switch
                    cx={css.switcher}
                    label="Show only selected"
                    value={showSelected.value}
                    onValueChange={showSelected.onValueChange}
                />
                <FlexSpacer />
                <LinkButton
                    onClick={handleSelectAll}
                    caption={selectAll?.value ? 'CLEAR ALL' : 'SELECT ALL'}
                />
            </FlexRow>
        </Panel>
    );
}
