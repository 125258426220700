/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { FormContainer } from './FormContainer';
import { Modal as ModalComponent } from 'components/common';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Constants
 */
import { ModalSizes } from 'constants/modals';
/**
 * Types
 */
import { TModal } from './types';
import { IModal } from '@epam/uui-core';
/**
 * Expo
 */
const CreationModal: React.FC<TModal & IModal<string>> = ({
    onSubmit,
    ...props
}) => (
    <ModalComponent
        {...props}
        withoutFooter
        allowCloseOnCross={false}
        title="Add assessment"
        width={ModalSizes.large}
        className={css.modalContainer}
        dataTestId="assessments/creationModal"
        renderBody={({ abort }) => (
            <FormContainer {...props} onClose={abort} onSubmit={onSubmit} />
        )}
    />
);

export default CreationModal;
