/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { ListItemNode, ListNode } from '@lexical/list';
import { HeadingNode } from '@lexical/rich-text';
import { ImageNode } from './Image/ImageNode';
import { CodeBlockNode } from './CodeBlock/CodeBlockNode';
import { LinkNode } from '@lexical/link';
import { MarkNode } from '@lexical/mark';
import { ExtendedTextNode } from './ExtendedText/ExtendedTextNode';
import { ParagraphNode, TextNode } from 'lexical';
import { ExtendedParagraphNode } from './ExtentedParagrapthNode/ExtendedParagraphNode';

export const Nodes = [
    ExtendedTextNode,
    {
        replace: TextNode,
        with: (node: TextNode) => new ExtendedTextNode(node.getTextContent()),
    },
    ExtendedParagraphNode,
    {
        replace: ParagraphNode,
        with: () => {
            return new ExtendedParagraphNode();
        },
    },
    HeadingNode,
    ListNode,
    ListItemNode,
    ImageNode,
    CodeBlockNode,
    LinkNode,
    MarkNode,
];
