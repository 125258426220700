/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { ArraySelect } from 'components/common';
import { renderUserRow } from './renderUserRow';
/**
 * Types
 */
import { ArraySelectProps } from 'components/common/Select/types';
import { TAssigneeOptions } from 'types';
/**
 * Expo
 */
export const SelectPerson = (
    props: ArraySelectProps<TAssigneeOptions>
): JSX.Element => <ArraySelect {...props} renderRow={renderUserRow} />;
