/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback } from 'react';
/**
 * Hooks
 */
import { useRouteParams } from 'hooks';
/**
 * Components
 */
import { RecentActions } from 'components/RecentActions';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
import { EntityTypes } from 'constants/actionLog';
/**
 * Types
 */
import { TProjectActionLog, WithDataTestId } from 'types';
/**
 * Utils
 */
import { generatePath } from 'utils';
import { renderAction } from './utils';
/**
 * Expo
 */
export const ProjectRecentActions: React.FC<WithDataTestId> = ({
    dataTestId,
}) => {
    const { projectId } = useRouteParams();

    const getActivityHref = useCallback(
        (assessmentId: string, activityId: string) =>
            generatePath(PAGES.singleActivity, {
                projectId,
                activityId,
                assessmentId,
            }),
        [projectId]
    );

    const getAssessmentHref = useCallback(
        (id: string) =>
            generatePath(PAGES.singleAssessment, {
                projectId,
                assessmentId: id,
            }),
        [projectId]
    );

    const getFindingHref = useCallback(
        (id: string) =>
            generatePath(PAGES.singleFinding, {
                projectId,
                findingId: id,
            }),
        [projectId]
    );

    const getReportHref = useCallback(
        (id: string) =>
            generatePath(PAGES.singleReport, {
                projectId,
                reportId: id,
            }),
        [projectId]
    );

    const getTargetsHref = useCallback(
        () =>
            generatePath(PAGES.targets, {
                projectId,
            }),
        [projectId]
    );

    const getTaskHref = useCallback(
        (taskId: string) =>
            generatePath(PAGES.singleTask, {
                taskId,
                projectId,
            }),
        [projectId]
    );

    const getManualTaskHref = useCallback(
        (taskId: string) =>
            generatePath(PAGES.singleManualTask, {
                taskId,
                projectId,
            }),
        [projectId]
    );

    const renderActionWithAdditionalProps = useCallback(
        (props: TProjectActionLog) =>
            renderAction(props, {
                getTaskHref,
                getTargetsHref,
                getReportHref,
                getFindingHref,
                getActivityHref,
                getAssessmentHref,
                getManualTaskHref,
            }),
        [
            getTaskHref,
            getTargetsHref,
            getReportHref,
            getFindingHref,
            getActivityHref,
            getAssessmentHref,
            getManualTaskHref,
        ]
    );

    return (
        <RecentActions<TProjectActionLog>
            entityId={projectId}
            dataTestId={dataTestId}
            entityType={EntityTypes.project}
            renderAction={renderActionWithAdditionalProps}
        />
    );
};
