/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useMemo } from 'react';
/**
 * Components
 */
import { Text } from '@epam/promo';
/**
 * Types
 */
import { TAssessment } from 'types';
/**
 * Utils
 */
import { formatDateRange } from 'utils';
/**
 * Constants
 */
import { DATE_FORMATS } from 'constants/dates';
/**
 * Assets
 */
import css from '../../style.module.scss';
/**
 * Expo
 */
export const DateRangeCell: React.FC<TAssessment> = ({
    end_date,
    start_date,
}) => {
    const dateRange = useMemo(
        () =>
            formatDateRange({
                to: end_date,
                from: start_date,
                format: DATE_FORMATS.US.date,
            }),
        [start_date, end_date]
    );

    return <Text cx={css.dateText}>{dateRange}</Text>;
};
