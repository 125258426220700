/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useEffect, useState } from 'react';
import { PanelBlock, Text } from 'components/common';
import { FindingCard } from './FindingCard';
/**
 * Assets
 */
/**
 * Hooks
 */
import { useRedirect, useRouteParams } from 'hooks';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Types
 */
import { TFindingByActivityId } from 'types';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
/**
 * Utils
 */
import { createQueryUrl } from 'utils';
/**
 * Expo
 */
export const Findings: React.FC = () => {
    const { activityId } = useRouteParams();
    const [findings, setFindings] = useState<TFindingByActivityId[]>([]);
    const redirect = useRedirect();

    const redirectToSingleFinding = useCallback(
        (id: string) => {
            redirect(PAGES.singleFinding, { findingId: id });
        },
        [redirect]
    );

    const handleLoadFindings = useCallback(async () => {
        const url = createQueryUrl(ENDPOINTS.findings, {
            activityId,
        });

        const response = await api.request<TFindingByActivityId[]>(
            Methods.get,
            { url }
        );

        setFindings(response);
    }, [activityId]);

    useEffect(() => {
        handleLoadFindings();
    }, [handleLoadFindings]);

    return findings.length ? (
        <PanelBlock dataTestId="activity/findings">
            <Text
                fontSize="24"
                color="gray80"
                fontWeight="400"
                dataTestId="activity/findings/title"
            >
                Created findings
            </Text>
            {findings.map(
                ({
                    id,
                    assignee,
                    summary,
                    status,
                    linked_tracker_issue,
                    composite_id,
                }) => (
                    <FindingCard
                        key={id}
                        id={id}
                        summary={summary}
                        compositeId={composite_id}
                        linkedIssue={linked_tracker_issue}
                        status={status}
                        assignee={assignee?.full_name}
                        handleRedirect={redirectToSingleFinding}
                    />
                )
            )}
        </PanelBlock>
    ) : null;
};
