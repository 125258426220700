/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import React, {
    createContext,
    FC,
    PropsWithChildren,
    useMemo,
    useState,
} from 'react';
import { Blocker } from '../common';

type GlobalLoaderContextType = [boolean, (isEnabled: boolean) => void];
export const GlobalLoaderContext = createContext<GlobalLoaderContextType>([
    false,
    () => {},
]);

export const GlobalLoader: FC<PropsWithChildren> = ({ children }) => {
    const [isEnabled, setIsEnabled] = useState(false);
    const state: GlobalLoaderContextType = useMemo(
        () => [isEnabled, setIsEnabled],
        [isEnabled]
    );

    return (
        <GlobalLoaderContext.Provider value={state}>
            {children}
            <Blocker isFixed isEnabled={isEnabled} />
        </GlobalLoaderContext.Provider>
    );
};
