/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
    $createParagraphNode,
    $getSelection,
    $isRangeSelection,
    COMMAND_PRIORITY_LOW,
    createCommand,
    LexicalCommand,
} from 'lexical';
import {
    $createCodeBlockNode,
    $isCodeBlockNode,
} from '../../nodes/CodeBlock/CodeBlockNode';
import { $setBlocksType } from '@lexical/selection';
import { mergeRegister } from '@lexical/utils';

export const INSERT_CODE_BLOCK_COMMAND: LexicalCommand<void> = createCommand(
    'INSERT_CODE_BLOCK_COMMAND'
);
export const DELETE_CODE_BLOCK_COMMAND: LexicalCommand<void> = createCommand(
    'DELETE_CODE_BLOCK_COMMAND'
);

export const CodeBlockPlugin = () => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        return mergeRegister(
            editor.registerCommand(
                INSERT_CODE_BLOCK_COMMAND,
                () => {
                    let selection = $getSelection();
                    if ($isRangeSelection(selection)) {
                        if (selection.isCollapsed()) {
                            $setBlocksType(selection, () =>
                                $createCodeBlockNode()
                            );
                        } else {
                            const textContent = selection.getTextContent();
                            const codeNode = $createCodeBlockNode();
                            selection.insertNodes([codeNode]);
                            selection = $getSelection();
                            if ($isRangeSelection(selection))
                                selection.insertRawText(textContent);
                        }
                    }
                    return true;
                },
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                DELETE_CODE_BLOCK_COMMAND,
                () => {
                    const selection = $getSelection();
                    if ($isRangeSelection(selection)) {
                        const block = selection.anchor.getNode().getParent();
                        if ($isCodeBlockNode(block)) {
                            block.replaceWith($createParagraphNode());
                        }
                    }
                    return true;
                },
                COMMAND_PRIORITY_LOW
            )
        );
    }, [editor]);

    return null;
};
