/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
/**
 * Types
 */
import { TAsyncTask, TFunction, TFunctionWithArgs } from 'types';
/**
 * Services
 */
import { showError, showWarning, templateString } from 'utils';
/**
 * Utils
 */
import { api } from 'services/api';

type TLoadAsync<TAsyncTaskDetails> = {
    onFail?: TFunction;
    onWarning?: TFunction;
    onStart?: TFunction;
    onComplete: TFunction;
    onLoad: TFunction<Promise<TAsyncTask<TAsyncTaskDetails>>>;
    onCallAsyncTask?: TFunctionWithArgs<TAsyncTask<TAsyncTaskDetails>>;
    delay?: number;
};
/**
 * This should be re-write on MVP with WebSockets
 */
export class LoadAsync<TAsyncTaskDetails> {
    onFail?: TFunction;
    onWarning?: TFunction;
    onStart?: TFunction;
    onComplete: TFunction;
    onLoad: TFunction<Promise<TAsyncTask<TAsyncTaskDetails>>>;
    onCallAsyncTask?: TFunctionWithArgs<TAsyncTask<TAsyncTaskDetails>>;
    delay?: number;

    status = '';
    asyncTaskId = '';
    message = '';

    constructor({
        onLoad,
        onFail,
        onWarning,
        onStart,
        onComplete,
        onCallAsyncTask,
        delay = 1000,
    }: TLoadAsync<TAsyncTaskDetails>) {
        this.delay = delay;
        this.onFail = onFail;
        this.onWarning = onWarning;
        this.onLoad = onLoad;
        this.onStart = onStart;
        this.onComplete = onComplete;
        this.onCallAsyncTask = onCallAsyncTask;
    }

    get inProgress(): boolean {
        return this.status === 'PROCESSING';
    }

    get isCompleted(): boolean {
        return this.status === 'COMPLETED';
    }

    get isFailed(): boolean {
        return this.status === 'FAILED';
    }

    get isPartiallyCompleted(): boolean {
        return this.status === 'PARTIALLY_COMPLETED';
    }

    async callAsyncTask(): Promise<void> {
        const asyncTaskUrl = templateString(ENDPOINTS.asyncTask, {
            id: this.asyncTaskId,
        });

        const asyncTask = await api.request<TAsyncTask<TAsyncTaskDetails>>(
            Methods.get,
            {
                url: asyncTaskUrl,
            }
        );

        this.status = asyncTask.status;
        this.message = asyncTask.detail_message;

        if (this.onCallAsyncTask) {
            this.onCallAsyncTask(asyncTask);
        }
    }

    async handleLoad(): Promise<void> {
        const { id: asyncTaskId, status: loadIssuesTaskStatus } =
            await this.onLoad();

        this.asyncTaskId = asyncTaskId;
        this.status = loadIssuesTaskStatus;
    }

    handleStart(): void {
        if (this.onStart) this.onStart();
    }

    handleComplete(): void {
        if (this.onComplete) this.onComplete();
    }

    handleFail(): void {
        if (this.onFail) this.onFail();
        if (this.message === '') {
            showError();
        } else {
            showError(this.message);
        }
    }

    handleWarning(): void {
        if (this.onWarning) this.onWarning();
        if (this.message === '') {
            showWarning();
        } else {
            showWarning(this.message);
        }
    }

    loadByTimer(): void {
        window.setTimeout(async () => {
            await this.callAsyncTask();

            if (this.isCompleted) {
                this.handleComplete();
            } else if (this.isFailed) {
                this.handleFail();
            } else if (this.inProgress) {
                this.loadByTimer();
            } else if (this.isPartiallyCompleted) {
                this.handleWarning();
            }
        }, this.delay);
    }

    async start(): Promise<void> {
        this.handleStart();

        await this.handleLoad();

        if (this.isCompleted) {
            this.handleComplete();
        } else if (this.inProgress) {
            this.loadByTimer();
        }
    }
}
