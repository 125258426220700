/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useMemo } from 'react';
/**
 * Hooks
 */
import { useAppDispatch, useRole } from 'hooks';
import { useSelector } from 'react-redux';
/**
 * Components
 */
import { Modal } from './DescriptionModal';
import {
    Avatar,
    Button,
    FlexCell,
    FlexRow,
    IconButton,
    Panel,
    Status,
    Text,
    User,
} from 'components/common';
import { AvatarStack } from 'components/common/AvatarStack';
import { Tooltip } from '@epam/promo';
import { EditableSelect } from 'components/Fields/EditableSelect';
import { renderUserRow } from './renderUserRow';
import { getRowOptions } from './rowOptions';
/**
 * Assets
 */
import css from '../../style.module.scss';
import { ReactComponent as editIcon } from '@epam/assets/icons/common/content-edit-12.svg';
import { ReactComponent as addIcon } from '@epam/assets/icons/common/action-add-18.svg';
/**
 * Store
 */
import {
    handleChangeDescription,
    handleChangeProjectRepresentative,
    handleChangeSecuritySpecialists,
    selectIsIntegratedWithJira,
    selectProjectAdditionalInfo,
    selectProjectData,
} from 'store/project';
import { selectFlag } from 'store/featureFlags';
/**
 * Types
 */
import { TModalProps } from './types';
import { TFormValues } from './DescriptionModal/Form/types';
/**
 * Utils
 */
import { showModal } from 'utils';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { QUERY_PARAMS } from './constants';
import { FEATURE_FLAGS } from 'constants/featureFlags';
import { TPerson } from 'types';

/**
 * Expo
 */
export const Info: React.FC = () => {
    const dispatch = useAppDispatch();
    const { isProjectRepresentative, isOperationsManager } = useRole();

    const {
        status,
        description,
        organization: { code: customerCode },
    } = useSelector(selectProjectData);
    const isIntegratedWithJira = useSelector(selectIsIntegratedWithJira);
    const {
        securitySpecialists,
        projectRepresentatives,
        operationsManager: { full_name: operationsManagerName },
    } = useSelector(selectProjectAdditionalInfo);
    const viewSingleDraftProject = useSelector(
        selectFlag(FEATURE_FLAGS.addProjectWizard)
    );

    const handleSetDescription = useCallback(
        async ({ description: changedDescription = '' }: TFormValues) => {
            await dispatch(handleChangeDescription(changedDescription));
        },
        [dispatch]
    );

    const getRowOptionsForSecuritySpecialists = useMemo(
        () => getRowOptions(securitySpecialists),
        [securitySpecialists]
    );

    const handleAddDescription = useCallback(() => {
        showModal<TModalProps>(Modal, {
            description,
            onSubmit: handleSetDescription,
            title: 'Add description',
            dataTestId: 'projectInfo/description/add/modal',
        });
    }, [description, handleSetDescription]);

    const handleEditDescription = useCallback(() => {
        showModal<TModalProps>(Modal, {
            description,
            onSubmit: handleSetDescription,
            title: 'Edit description',
            dataTestId: 'projectInfo/description/edit/modal',
        });
    }, [description, handleSetDescription]);

    const handleSaveProjectRepresentative = useCallback(
        (projectRepresentative: TPerson[]) => {
            dispatch(handleChangeProjectRepresentative(projectRepresentative));
        },
        [dispatch]
    );

    const handleSaveSecuritySpecialists = useCallback(
        (securitySpecialist: TPerson[]) => {
            dispatch(handleChangeSecuritySpecialists(securitySpecialist));
        },
        [dispatch]
    );

    return (
        <Panel background="white" cx={css.panel} dataTestId="projectInfo/info">
            <FlexRow cx={css.panelHeader}>
                <Text
                    fontSize="24"
                    fontWeight="400"
                    dataTestId="projectInfo/info/title"
                >
                    Project info
                </Text>
            </FlexRow>
            <FlexRow cx={css.content}>
                <FlexCell width="auto">
                    {viewSingleDraftProject && (
                        <FlexRow>
                            <Text cx={css.categoryColumn}>Status</Text>
                        </FlexRow>
                    )}
                    {customerCode && (
                        <FlexRow>
                            <Text cx={css.categoryColumn}>Customer code</Text>
                        </FlexRow>
                    )}
                    <FlexRow>
                        <Text cx={css.categoryColumn}>
                            Project representative
                        </Text>
                    </FlexRow>
                    <FlexRow>
                        <Text cx={css.categoryColumn}>Operations manager</Text>
                    </FlexRow>
                    <FlexRow>
                        <Text cx={css.categoryColumn}>Security team</Text>
                    </FlexRow>
                    {!isOperationsManager && isIntegratedWithJira && (
                        <FlexRow>
                            <Text cx={css.categoryColumn}>
                                Project integrations
                            </Text>
                        </FlexRow>
                    )}
                    {(isProjectRepresentative || description) && (
                        <FlexRow>
                            <Text cx={css.categoryColumn}>
                                Project description
                            </Text>
                        </FlexRow>
                    )}
                </FlexCell>

                <FlexCell grow={1}>
                    {viewSingleDraftProject && status && (
                        <FlexRow dataTestId="projectInfo/info/status">
                            <Status type={status} />
                        </FlexRow>
                    )}
                    {customerCode && (
                        <FlexRow dataTestId="projectInfo/info/customerCode">
                            <Text>{customerCode}</Text>
                        </FlexRow>
                    )}
                    <FlexRow dataTestId="projectInfo/info/projectRepresentative">
                        {isOperationsManager ? (
                            <EditableSelect<TPerson, TPerson[]>
                                disableClear
                                mode="multi"
                                labelKey="full_name"
                                value={projectRepresentatives}
                                url={ENDPOINTS.persons}
                                onSave={handleSaveProjectRepresentative}
                                queryParams={QUERY_PARAMS.projectRepresentative}
                                renderRow={renderUserRow}
                                placeholder="Select Project Representative from list"
                            />
                        ) : (
                            <AvatarStack
                                size="18"
                                list={projectRepresentatives}
                            />
                        )}
                    </FlexRow>
                    <FlexRow dataTestId="projectInfo/info/operationsManager">
                        <User
                            textSize="14"
                            avatarSize="18"
                            name={operationsManagerName}
                        />
                    </FlexRow>
                    <FlexRow dataTestId="projectInfo/info/securityTeam">
                        {isOperationsManager ? (
                            <EditableSelect<TPerson, TPerson[]>
                                disableClear
                                mode="multi"
                                labelKey="full_name"
                                value={securitySpecialists}
                                url={ENDPOINTS.persons}
                                onSave={handleSaveSecuritySpecialists}
                                getRowOptions={
                                    getRowOptionsForSecuritySpecialists
                                }
                                renderRow={renderUserRow}
                                queryParams={QUERY_PARAMS.securitySpecialists}
                                placeholder="Select Security Specialist from list"
                            />
                        ) : (
                            <AvatarStack size="18" list={securitySpecialists} />
                        )}
                    </FlexRow>
                    {!isOperationsManager && isIntegratedWithJira && (
                        <FlexRow dataTestId="projectInfo/info/integrations">
                            <Tooltip content="Jira">
                                <Avatar img="/assets/images/jira.svg" />
                            </Tooltip>
                        </FlexRow>
                    )}
                    {!isProjectRepresentative && !description ? null : (
                        <FlexRow
                            cx={css.descriptionContainer}
                            dataTestId="projectInfo/info/description"
                        >
                            <Text cx={css.description}>{description}</Text>
                            {isProjectRepresentative && description && (
                                <FlexRow cx={css.editIcon}>
                                    <IconButton
                                        color="info"
                                        icon={editIcon}
                                        onClick={handleEditDescription}
                                        dataTestId="projectInfo/info/description/edit"
                                    />
                                </FlexRow>
                            )}
                            {!description && isProjectRepresentative && (
                                <Button
                                    icon={addIcon}
                                    caption="Add description"
                                    onClick={handleAddDescription}
                                    dataTestId="projectInfo/info/description/add"
                                />
                            )}
                        </FlexRow>
                    )}
                </FlexCell>
            </FlexRow>
        </Panel>
    );
};
