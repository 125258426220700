/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { ErrorView } from './ErrorView';
/**
 * Types
 */
import { TErrorBoundaryProps, TErrorBoundaryState } from './types';
/**
 * Expo
 */
export class ErrorBoundary extends React.Component<
    TErrorBoundaryProps,
    TErrorBoundaryState
> {
    constructor(props: TErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    handleUrlChanged = () => {
        this.setState({ hasError: false });
    };

    render() {
        const { hasError } = this.state;
        const { children } = this.props;

        return !hasError ? (
            children
        ) : (
            <ErrorView onChangeUrl={this.handleUrlChanged} />
        );
    }
}
