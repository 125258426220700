/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { TStatusList, TotalActivitiesTitles } from './types';
/**
 * constants
 */
import { STATUS_LIST_ORDER } from './constants';
/**
 * Expo
 */
const getTotalAmount = (list: TStatusList): number =>
    Object.values(list).reduce(
        (accumulator, { amount }) => accumulator + amount,
        0
    );

export const getTotalAmountText = (list: TStatusList): string => {
    const totalAmount = getTotalAmount(list);

    return totalAmount === 1
        ? `${totalAmount} ${TotalActivitiesTitles.activity}`
        : `${totalAmount} ${TotalActivitiesTitles.activities}`;
};

export const sortStatuses = (statuses: TStatusList): TStatusList => {
    return [...statuses].sort(
        ({ status: statusA }, { status: statusB }) =>
            STATUS_LIST_ORDER.indexOf(statusA) -
            STATUS_LIST_ORDER.indexOf(statusB)
    );
};
