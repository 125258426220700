/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createSlice } from '@reduxjs/toolkit';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { INITIAL_STATE } from './constants';
import { Methods } from 'constants/request';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Types
 */
import { AppDispatch, TOrganization, TSelector } from 'types';
/**
 * Utils
 */
import { createQueryUrl } from 'utils';
/**
 * Reducer
 */
const { reducer, actions } = createSlice({
    name: 'organizations',
    initialState: INITIAL_STATE,
    reducers: {
        saveList: (state, { payload }) => {
            state.list = payload;

            state.isInitialized = true;
        },
        setInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
    },
});

export default reducer;
/**
 * Selectors
 */
export const selectList: TSelector<TOrganization[]> = ({
    organizations: { list },
}) => list;
export const selectInProgress: TSelector<boolean> = ({
    organizations: { inProgress },
}) => inProgress;
export const selectIsInitialized: TSelector<boolean> = ({
    organizations: { isInitialized },
}) => isInitialized;
/**
 * Actions
 */
export const { saveList, setInProgress } = actions;
/**
 * Dispatchers
 */
export const handleGetList =
    (search?: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setInProgress(true));

        const url = createQueryUrl(ENDPOINTS.organizations, { search });

        const response = await api.request<TOrganization[]>(Methods.get, {
            url,
        });

        dispatch(saveList(response));

        dispatch(setInProgress(false));
    };
