/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { memo, useCallback } from 'react';
/**
 * Hooks
 */
import { useAppDispatch, useRole, useRouteParams } from 'hooks';
/**
 * Components
 */
import { Delete } from '../../../Delete';
import { StateIcon, Blocker } from '..';
import { PublishModal as Modal } from '../../PublishModal';
import { FlexRow, FlexSpacer, Tooltip } from '@epam/promo';
import {
    LinkButton,
    Panel,
    Text,
    User,
    CopyToClipboard,
    Badge,
} from 'components/common';
/**
 * Utils
 */
import {
    formatDate,
    getSizeToMegabytes,
    NotificationTypes,
    sanitizeUrl,
    showModal,
    showNotification,
    generatePath,
} from 'utils';
/**
 * Types
 */
import { TReport } from 'types';
import { TConfirmationModal, TReportProps } from '../../types';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
import {
    EXTENSION_BY_REPORT_TYPE,
    EXTENSION_NAME_BY_REPORT_TYPE,
    REPORT_TYPES_WITH_SIZE,
    STATE_BADGE_COLOR,
    STATE_BADGE_TEXT,
} from './constants';
import { ReportState, ReportStatus, ReportTypes } from 'constants/reports';
/**
 * Store
 */
import { handlePublishReport } from 'store/reports';
/**
 * Assets
 */
import css from './style.module.scss';
import { ReactComponent as LinkIcon } from '@epam/assets/icons/common/content-link-18.svg';
import { FileIcon } from 'components/common/FileIcon';

/**
 * Expo
 */
export const ListItem: React.FC<TReport & TReportProps> = memo(
    ({
        id,
        url,
        name,
        type,
        state,
        status,
        updated_at,
        owner_full_name,
        total_size_bytes,
        assessment: { id: assessmentId, name: assessmentName },
        onClick,
    }) => {
        const dispatch = useAppDispatch();

        const { projectId } = useRouteParams();
        const { isSecuritySpecialist } = useRole();

        const isWithLink = type === ReportTypes.withLink;
        const isProcessingDocxReport =
            type === ReportTypes.docx && status === ReportStatus.processing;
        const isCardClickable = !isWithLink && !isProcessingDocxReport;
        const isSizeShowed = REPORT_TYPES_WITH_SIZE.includes(type);
        const isExtensionIconShowed =
            type === ReportTypes.pdf || type === ReportTypes.docx;

        const reportHref = generatePath(PAGES.singleReport, {
            projectId,
            reportId: id,
        });
        const assessmentHref = generatePath(PAGES.singleAssessment, {
            projectId,
            assessmentId,
        });

        const isReportStateIconVisible =
            state === ReportState.draft &&
            isSecuritySpecialist &&
            type !== ReportTypes.docx;

        const handleClick = useCallback(() => {
            onClick({ reportId: id });
        }, [onClick, id]);

        const handleChangeReportState = useCallback(async () => {
            await dispatch(handlePublishReport(id));

            showNotification({
                text: `Report "${name}" published`,
                type: NotificationTypes.success,
            });
        }, [dispatch, id, name]);

        const handleOpenConfirmationModal = useCallback(
            (event: React.SyntheticEvent<HTMLDivElement, Event>) => {
                event.stopPropagation();

                showModal<TConfirmationModal>(Modal, {
                    title: 'Publish report',
                    onSuccess: handleChangeReportState,
                });
            },
            [handleChangeReportState]
        );

        const handleCopyLink = useCallback(() => {
            showNotification({
                text: 'Source link copied',
                type: NotificationTypes.success,
            });
        }, []);

        return (
            <Panel
                shadow
                background="white"
                cx={css.smallCardContainer}
                dataTestId="reports/list/item"
                onClick={!isCardClickable ? undefined : handleClick}
            >
                <FlexRow size="24">
                    <LinkButton
                        size="24"
                        href={assessmentHref}
                        caption={assessmentName}
                        dataTestId="reports/list/item/assessmentName"
                    />
                    <FlexSpacer />
                    <FlexRow size="24" columnGap="18">
                        <Tooltip content="Copy link">
                            <CopyToClipboard
                                onCopy={handleCopyLink}
                                text={window.location.origin + reportHref}
                            >
                                <LinkButton
                                    size="24"
                                    icon={LinkIcon}
                                    dataTestId="reports/list/item/copyLink"
                                />
                            </CopyToClipboard>
                        </Tooltip>
                        {isReportStateIconVisible && (
                            <StateIcon
                                state={state}
                                onClick={handleOpenConfirmationModal}
                            />
                        )}
                        {isSecuritySpecialist && (
                            <Delete id={id} title={name} />
                        )}
                    </FlexRow>
                </FlexRow>
                <FlexRow size="24">
                    <LinkButton
                        caption={name}
                        cx={css.name}
                        target={isWithLink ? '_blank' : undefined}
                        href={isWithLink ? sanitizeUrl(url) : reportHref}
                        dataTestId={`reports/list/item/${
                            isWithLink ? 'link' : 'name'
                        }`}
                    />
                    <FlexSpacer />
                </FlexRow>
                <FlexRow size="24" columnGap="6">
                    {isSecuritySpecialist && (
                        <Badge
                            fill="outline"
                            color={STATE_BADGE_COLOR[state]}
                            caption={STATE_BADGE_TEXT[state]}
                        />
                    )}
                    {isExtensionIconShowed && (
                        <FileIcon
                            size={18}
                            extension={EXTENSION_BY_REPORT_TYPE[type]}
                        />
                    )}
                    {isSizeShowed && (
                        <Text
                            size="18"
                            fontSize="12"
                            lineHeight="18"
                            color="gray60"
                            dataTestId="reports/list/item/size"
                        >
                            {isExtensionIconShowed &&
                                `${EXTENSION_NAME_BY_REPORT_TYPE[type]}, `}
                            {getSizeToMegabytes(total_size_bytes)}MB
                        </Text>
                    )}
                    <User
                        textSize="12"
                        avatarSize="18"
                        name={owner_full_name}
                        dataTestId="reports/list/item/owner"
                    />
                    <FlexSpacer />
                    <Text
                        size="18"
                        fontSize="12"
                        color="gray60"
                        dataTestId="reports/list/item/updatedAt"
                    >
                        Last changed {formatDate(updated_at)}
                    </Text>
                </FlexRow>
                <Blocker
                    isEnabled={isProcessingDocxReport}
                    title="Processing ..."
                />
            </Panel>
        );
    }
);
