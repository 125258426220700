/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependency
 */
import React, { useCallback } from 'react';
import {
    useVirtualList,
    DataSourceState,
    useLazyDataSource,
} from '@epam/uui-core';
/**
 * Components
 */
import { ScrollBars } from '@epam/promo';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { TProps } from './types';
import { ScrollbarsApi } from '@epam/uui-components';
/**
 * Utils
 */
import { getScrollRef } from './utils';
/**
 * Expo
 */
export const VirtualList = <TListItem,>({
    count,
    onGetList,
    maxHeight,
    renderItem,
}: TProps<TListItem>): JSX.Element => {
    const [viewParams, onChangeViewParams] = React.useState<DataSourceState>({
        topIndex: 0,
        visibleCount: count,
    });

    const dataSource = useLazyDataSource<TListItem, string, string>(
        { api: onGetList },
        [onGetList]
    );

    const { getVisibleRows, getListProps } = dataSource.useView(
        viewParams,
        onChangeViewParams,
        {}
    );

    const {
        offsetY,
        handleScroll,
        estimatedHeight,
        listContainerRef,
        scrollContainerRef,
    } = useVirtualList({
        value: viewParams,
        onValueChange: onChangeViewParams,
        rowsCount: getListProps().rowsCount ?? 0,
    });

    const getRef = useCallback(
        (ref: ScrollbarsApi) => {
            if (!ref) return;

            scrollContainerRef.current = getScrollRef(ref);
        },
        [scrollContainerRef]
    );

    return (
        <ScrollBars
            ref={getRef}
            style={{ maxHeight }}
            onScroll={handleScroll}
            cx={css.containerWrapper}
        >
            <div
                className={css.container}
                style={{ minHeight: `${estimatedHeight}px` }}
            >
                <div
                    ref={listContainerRef}
                    style={{ marginTop: `${offsetY}px` }}
                >
                    {getVisibleRows().map(renderItem)}
                </div>
            </div>
        </ScrollBars>
    );
};
