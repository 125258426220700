/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { memo } from 'react';
import { Outlet } from 'react-router-dom';
/**
 * Components
 */
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
/**
 * Expo
 */
export const PrivatePageContainer: React.FC = memo(() => (
    <div className={css.app}>
        <header className={css.header}>
            <Header />
        </header>
        <main className={css.pageContainer}>
            <Outlet />
        </main>
        <footer className={css.footer}>
            <Footer />
        </footer>
    </div>
));
