/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { TActivityActionLog } from 'types';
import { ActivityActionLogTypes } from 'constants/actionLog';
import { CommentCreated } from './components/CommentCreated';
import { UpdateActivity } from './components/UpdateActivity';

export const renderAction = (
    activityName: string,
    props: TActivityActionLog
): JSX.Element | null => {
    switch (props.type) {
        case ActivityActionLogTypes.activityCommentCreated:
            return <CommentCreated {...props} activityName={activityName} />;
        case ActivityActionLogTypes.updateActivity:
            return <UpdateActivity {...props} activityName={activityName} />;
        default:
            return null;
    }
};
