/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, useCallback, useContext, useState } from 'react';
/**
 * Components
 */
import { FlexRow, FlexSpacer } from '@epam/promo';
import { FileIcon } from 'components/common/FileIcon';
import { Blocker, IconContainer, Panel, Text } from 'components/common';
/**
 * Utils
 */
import {
    downloadFile,
    templateString,
    getSizeToMegabytes,
    getExtensionFromFileName,
} from 'utils';
/**
 * Types
 */
import { TAttachedFileProps } from './types';
/**
 * Constants
 */
import { Methods } from 'constants/request';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Assets
 */
import css from './style.module.scss';
import { ReactComponent as downloadIcon } from '@epam/assets/icons/common/file-download-18.svg';
/**
 * Context
 */
import { AttachmentsContext } from '../../context';
/**
 * Expo
 */
export const AttachedFile: FC<TAttachedFileProps> = ({
    file: { id, name, size_bytes },
}) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const { downloadEndpoint } = useContext(AttachmentsContext);

    const extension = getExtensionFromFileName(name).toLowerCase();

    const handleDownloadFile = useCallback(async () => {
        if (!downloadEndpoint) return;

        const url = templateString(downloadEndpoint, {
            attachmentId: id,
        });

        setIsDownloading(true);

        try {
            const blobFile: Blob = await api.request(Methods.get, {
                url,
                responseType: 'blob',
                headers: { 'Content-type': 'application/doc' },
            });

            downloadFile(blobFile, name);
        } finally {
            setIsDownloading(false);
        }
    }, [id, name, downloadEndpoint]);

    return (
        <Panel cx={css.attachedFile}>
            <Blocker isEnabled={isDownloading} hideSpinner />
            <FlexRow columnGap="6">
                <FileIcon extension={extension} />
                <Text>
                    {name} ({getSizeToMegabytes(size_bytes)}MB)
                </Text>
                <FlexSpacer />
                <IconContainer
                    icon={downloadIcon}
                    style={{ fill: 'var(--uui-link)' }}
                    onClick={handleDownloadFile}
                />
            </FlexRow>
        </Panel>
    );
};
