/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { FlexRow } from '@epam/promo';
import { CrumbItem } from './CrumbItem';
/**
 * Type
 */
import { BreadcrumbProps } from './types';
/**
 * Expo
 */
export const Breadcrumb: React.FC<BreadcrumbProps> = ({ list }) => (
    <FlexRow>
        {list.map(({ caption, pathname, dataTestId }, index) => (
            <CrumbItem
                caption={caption}
                pathname={pathname}
                dataTestId={dataTestId}
                isFirst={index === 0}
                key={pathname ? `${caption}_${pathname}` : caption}
            />
        ))}
    </FlexRow>
);
