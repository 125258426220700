/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useMemo } from 'react';
/**
 * Components
 */
import { Badge } from 'components/common';
/**
 * Constants
 */
import { StatusTypes } from 'constants/statuses';
import { CAPTIONS, COLORS } from './constants';
import { FINDING_RESOLUTION_CAPTIONS } from 'constants/finding';
/**
 * Types
 */
import { Props } from './types';
/**
 * Expo
 */
export const FindingStatus: React.FC<Props> = ({
    type,
    className,
    dataTestId,
    resolution,
}) => {
    const caption = useMemo(() => {
        if (type === StatusTypes.resolved && resolution) {
            return FINDING_RESOLUTION_CAPTIONS[resolution];
        }

        return CAPTIONS[type];
    }, [type, resolution]);

    return (
        <Badge
            color={COLORS[type]}
            fill="outline"
            caption={caption}
            dataTestId={dataTestId}
            cx={className}
        />
    );
};
