/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { normalize, schema } from 'normalizr';
import { PARENT_IDS, PARENT_OPTIONS, QUERY_PARAMETERS } from './constants';
import { TOption, TMapOptions, TNormalizeTasks } from './types';
/**
 * Expo
 */
const tasksSchema = new schema.Entity('tasks');

export const normalizeTasks: TNormalizeTasks = (list) => {
    const {
        result,
        entities: { tasks = {} },
    } = normalize(list, [tasksSchema]);

    return { idList: result, itemById: tasks };
};

export const mapOptions: TMapOptions = (options) => {
    const parentOptions = Object.values(PARENT_OPTIONS);
    const result = [...parentOptions];

    Object.entries(options).forEach(([category, items]) => {
        const { id: parentId } = PARENT_OPTIONS[category];

        const optionsWithParent = items.map((item) => ({ ...item, parentId }));

        result.push(...optionsWithParent);
    });

    return {
        options: result,
        totalCount: result.length - parentOptions.length,
    };
};

const getDefaultCategories = (): Record<string, string[]> => ({
    [QUERY_PARAMETERS[PARENT_IDS.sprints]]: [],
    [QUERY_PARAMETERS[PARENT_IDS.releases]]: [],
});

export const getCategories = (
    categories: TOption[]
): Record<string, string[]> =>
    categories.reduce((accumulator, { parentId, id }) => {
        if (parentId) {
            const queryParameter = QUERY_PARAMETERS[parentId];

            accumulator[queryParameter].push(id);
        }

        return accumulator;
    }, getDefaultCategories());
