/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Hooks
 */
import { useRouteParams } from 'hooks';
/**
 * Components
 */
import { Target } from './components/Target';
import { PanelBlock, TPanelBlockProps } from 'components/common';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
/**
 * Constants
 */
import { AssessmentTypes } from 'constants/assessment';
/**
 * Store
 */
import { selectAssessment } from 'store/assessment';
/**
 * Types
 */
import { TLeftSideProps } from './types';
import { ActivityComments } from 'components/ActivityComments';

/**
 * Expo
 */
export const LeftSide: React.FC<TLeftSideProps & TPanelBlockProps> = ({
    targetName,
    ...rest
}) => {
    const { type } = useSelector(selectAssessment);
    const { activityId } = useRouteParams();

    return (
        <PanelBlock {...rest}>
            {type === AssessmentTypes.regular && (
                <Target targetName={targetName} />
            )}
            <ActivityComments activityId={activityId} />
        </PanelBlock>
    );
};
