/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { TStep } from './types';
/**
 * Expo
 */
export const getFlatSteps = (steps: TStep[]): TStep[] =>
    steps.reduce(
        (accumulator: TStep[], { hasContent, nestedList, ...step }) => {
            if (hasContent && nestedList) {
                accumulator.push(step, ...nestedList);
            } else if (nestedList) {
                accumulator.push(...nestedList);
            } else {
                accumulator.push(step);
            }

            return accumulator;
        },
        []
    );

export const getAvailableSteps = (
    steps: TStep[],
    disabledSteps: string[]
): TStep[] => {
    return steps.filter((step) => !disabledSteps?.includes(step.id));
};
