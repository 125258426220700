/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { TGeneralInitialState } from 'types';
import { TNotificationSettingsState } from 'store/notificationSettings/types';

export const INITIAL_STATE: TGeneralInitialState<TNotificationSettingsState> = {
    data: {
        filter: {
            options: [],
        },
        state: {
            FINDING: [],
            ACTIVITY: [],
            ASSESSMENT: [],
            REPORT: [],
        },
    },
    isInitialized: false,
    inProgress: false,
};
