/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useMemo } from 'react';
/**
 * Components
 */
import { Tag as TagComponent } from '@epam/promo';
/**
 * Types
 */
import { TTag } from './types';
/**
 * Constants
 */
import { SYSTEM_LABEL } from 'constants/system';
/**
 * Utils
 */
import { getRawProps } from 'utils';
/**
 * Expo
 */
export const Tag: React.FC<TTag> = ({
    index,
    caption,
    dataTestId,
    onClear,
}) => {
    const isSystemTag = caption === SYSTEM_LABEL;

    const handleClear = useCallback(() => {
        onClear(index);
    }, [index, onClear]);

    const rawProps = useMemo(
        () => getRawProps({ dataTestId: `${dataTestId}/tag` }),
        [dataTestId]
    );

    return (
        <TagComponent
            size="24"
            caption={caption}
            rawProps={rawProps}
            onClear={isSystemTag ? undefined : handleClear}
        />
    );
};
