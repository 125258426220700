/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { LevelOfRisk } from 'types/threatModeling/trustBoundary';
import * as z from 'zod';
import { zodNamedDto, zodNamedTmDto, zodOptionalString } from '../constants';
import { baseItemFormSchema } from '../baseItemFormSchema';

export const trustBoundaryFormSchema = baseItemFormSchema.extend({
    type: zodNamedDto,
    levelOfRisk: z.nativeEnum(LevelOfRisk).nullish(),
    parent: zodNamedTmDto.nullish(),
    elements: z.array(zodNamedTmDto).nullish(),
    description: zodOptionalString,
});

export type TrustBoundaryFormValues = z.infer<typeof trustBoundaryFormSchema>;

export type TrustBoundaryFormSchema = typeof trustBoundaryFormSchema;
