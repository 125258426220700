/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
/**
 * Utils
 */
import { templateString } from 'utils';
/**
 * Services
 */
import { api } from 'services/api';
import { TGetUserDataReturnValue } from './types';
import { TOrganization, TPerson, TProject } from 'types';
/**
 * Expo
 */
export const handleGetProject = async (
    id: string
): Promise<Record<string, string>> => {
    const projectEndpoint = templateString(ENDPOINTS.project, {
        id,
    });

    const { name } = await api.request<TProject>(Methods.get, {
        url: projectEndpoint,
    });

    return { id, name };
};

export const handleGetOrganization = async (
    id: string
): Promise<Record<string, string>> => {
    const organizationEndpoint = templateString(ENDPOINTS.singleOrganization, {
        organizationId: id,
    });

    const { company_name: name } = await api.request<TOrganization>(
        Methods.get,
        {
            url: organizationEndpoint,
        }
    );

    return { id, name };
};

export const getUserData = async (): Promise<TGetUserDataReturnValue> => {
    const {
        id,
        roles,
        email,
        status,
        organization,
        full_name: fullName,
        role: { name: roleName },
        current_project_id: currentProjectId,
        current_organization_id: currentOrganizationId,
        version: { current_build_version: currentBuildVersionApi },
    } = await api.request<
        TPerson & {
            organization: TOrganization;
            current_project_id: string;
            current_organization_id: string;
            version: { current_build_version: string };
        }
    >(Methods.get, {
        url: ENDPOINTS.currentPersons,
    });

    return {
        id,
        roles,
        email,
        status,
        fullName,
        roleName,
        currentProjectId,
        currentOrganizationId,
        rootOrganization: organization,
        currentBuildVersionApi,
    };
};
