/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { ListItem } from './components/ListItem';
/**
 * Types
 */
import { TReportList } from './types';
/**
 * Expo
 */
export const ReportList: React.FC<TReportList> = ({ list, onClick }) => (
    <>
        {list.map((item) => (
            <ListItem {...item} key={item.id} onClick={onClick} />
        ))}
    </>
);
