/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { memo, useEffect } from 'react';
import ReactGA from 'react-ga4';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
/**
 * Store
 */
import {
    selectUserRole,
    selectRootOrganizationName,
    selectCurrentOrganizationName,
} from 'store/user';
/**
 * Constants
 */
import { GA_DELAY } from './constants';
import { ROLE_TITLES } from 'constants/roles';
import { isGADisabled } from 'constants/googleAnalytics';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Utils
 */
import { initializeGA, initializeGTM } from './utils';
/**
 * Expo
 */
initializeGA();
initializeGTM();

export const GoogleAnalytics: React.FC = memo(() => {
    const { pathname, search } = useLocation();

    const userRole = useSelector(selectUserRole);
    const rootOrganizationName = useSelector(selectRootOrganizationName);
    const currentOrganizationName = useSelector(selectCurrentOrganizationName);

    const fullPath = pathname + search;
    const userRoleName = !userRole ? undefined : ROLE_TITLES[userRole];
    const isDisabled = isGADisabled || !userRoleName || !api.isTokenExists;

    useEffect(() => {
        if (isDisabled) return;

        ReactGA.set({
            user_properties: {
                user_role: userRoleName,
                user_organization: rootOrganizationName,
                organization: currentOrganizationName || rootOrganizationName,
            },
        });
    }, [
        isDisabled,
        userRoleName,
        rootOrganizationName,
        currentOrganizationName,
    ]);

    useEffect(() => {
        if (isDisabled) return;

        setTimeout(() => {
            ReactGA.send({
                page: fullPath,
                hitType: 'pageview',
            });
        }, GA_DELAY);
    }, [isDisabled, fullPath]);

    return null;
});
