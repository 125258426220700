/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { IconButton } from 'components/common';
import { useEditableItemCard } from './EditableItemCardContext';
import { FC } from 'react';
import { ReactComponent as EditIcon } from '@epam/assets/icons/common/content-edit-18.svg';
import css from './style.module.scss';

export interface HeaderEditIconButtonProps {
    isOpen: boolean;
}

export const HeaderEditIconButton: FC<HeaderEditIconButtonProps> = ({
    isOpen,
}) => {
    const { switchToEditMode } = useEditableItemCard();

    return (
        <>
            {!isOpen && (
                <IconButton
                    cx={css.treeSubitemHeaderAdditionalItems}
                    icon={EditIcon}
                    onClick={switchToEditMode}
                    dataTestId="pencilEditButton"
                />
            )}
        </>
    );
};
