/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Panel } from '@epam/promo';
import { Form } from './Form';
import { Modal as ModalComponent } from 'components/common/index';
/**
 * Types
 */
import { ModalProps } from './types';
/**
 * Expo
 */
export const LinkModal: React.FC<ModalProps> = ({
    onSubmit,
    initialValue,
    ...props
}) => {
    return (
        <ModalComponent
            {...props}
            withoutFooter
            title="Add link"
            dataTestId="creatingFinding/description/addLink/modal"
            renderBody={({ abort }) => (
                <Panel>
                    <Form
                        close={abort}
                        onSubmit={onSubmit}
                        initialValue={initialValue}
                    />
                </Panel>
            )}
        />
    );
};
