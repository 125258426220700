/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { AvatarStack } from 'components/common/AvatarStack';
import { FlexRow } from '@epam/promo';
/**
 * Types
 */
import { TAssessment } from 'types';
/**
 * Expo
 */
export const Performers: React.FC<TAssessment> = ({ performers }) => (
    <FlexRow alignItems="center">
        <AvatarStack size="24" list={performers} avatarsCount={4} />
    </FlexRow>
);
