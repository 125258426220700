/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useEffect, memo, useState } from 'react';
import { cx } from '@epam/uui-core';
/**
 * Components
 */
import { Text } from 'components/common';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Utils
 */
import { DateTextCalculation, getMillisecondsFromNow, getText } from './utils';
/**
 * Types
 */
import { TDateFromNowProps } from './type';
/**
 * Expo
 */
export const DateFromNow: React.FC<TDateFromNowProps> = memo(
    ({ dataTestId, date, className }) => {
        const [text, setText] = useState(getText(getMillisecondsFromNow(date)));

        useEffect(() => {
            const calculation = new DateTextCalculation({ date, setText });

            calculation.start();

            return () => {
                calculation.stop();
            };
        }, [date]);

        return (
            <Text
                fontSize="12"
                lineHeight="18"
                size="18"
                cx={cx(className, css.dateFromNow)}
                dataTestId={dataTestId}
            >
                {text}
            </Text>
        );
    }
);
