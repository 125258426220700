/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useState } from 'react';
/**
 * Components
 */
import { FlexRow, FlexSpacer, ModalFooter, Panel } from '@epam/promo';
import { Button } from 'components/common';
import { SelectPerson } from 'components/SelectPerson';
/**
 * Types
 */
import { AssigneeFormProps } from './types';
import { TPerson } from 'types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const AssigneeForm: React.FC<AssigneeFormProps> = ({
    onSubmit,
    success,
    initialValue,
    abort,
    options,
}) => {
    const [chosenList, changeChosenList] = useState<TPerson[]>(initialValue);

    const handleChange = useCallback(
        (changedValue: TPerson[]) => {
            if (!changedValue.length && !chosenList.length) return;

            changeChosenList(changedValue);
        },
        [chosenList.length]
    );
    const handleSave = useCallback(async () => {
        onSubmit(chosenList);

        success('Success added');
    }, [chosenList, onSubmit, success]);

    return (
        <>
            <Panel cx={css.paddingInput}>
                <SelectPerson
                    mode="multi"
                    valueType="entity"
                    value={chosenList}
                    onValueChange={handleChange}
                    labelKey="full_name"
                    options={options}
                    placeholder="Select one or more performers from list"
                    dataTestId="singleAssessment/boardColumn/card/asignee/modal/selectPerson"
                />
            </Panel>
            <ModalFooter borderTop>
                <FlexSpacer />
                <FlexRow columnGap="6" vPadding="12">
                    <Button
                        fill="none"
                        caption="Cancel"
                        onClick={abort}
                        dataTestId="singleAssessment/boardColumn/card/asignee/modal/cancel"
                    />
                    <Button
                        caption="Save"
                        onClick={handleSave}
                        dataTestId="singleAssessment/boardColumn/card/asignee/modal/submit"
                    />
                </FlexRow>
            </ModalFooter>
        </>
    );
};
