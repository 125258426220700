/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import css from './style.module.scss';
import { IconButton } from 'components/common';
import { ReactComponent as PlusIcon } from '@epam/assets/icons/action-add-outline.svg';
import { WithDataTestId } from 'types';

export interface AddItemIconProps {
    onClick: () => void;
    disabled?: boolean;
}

export const AddItemIcon: React.FC<WithDataTestId & AddItemIconProps> = ({
    onClick,
    dataTestId,
    disabled = false,
}) => (
    <IconButton
        cx={css.addItemIcon}
        icon={PlusIcon}
        onClick={(event) => {
            event.stopPropagation();
            onClick();
        }}
        color="info"
        isDisabled={disabled}
        dataTestId={`${dataTestId}/addItem`}
    />
);
