/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { TextSettings } from './types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Constants
 */
import { DEFAULT_TEXT_SETTINGS } from './constants';
import { TextProps } from '@epam/promo';
/**
 * Expo
 */
const getTextClasses = ({
    lineHeight,
    fontSize,
    size,
}: TextSettings & { size: '18' | TextProps['size'] | '60' }): string[] => {
    if (size === 'none') {
        return [css[`line-height-${lineHeight}`], css[`font-size-${fontSize}`]];
    }

    const setting = {
        size,
        lineHeight:
            lineHeight || (size && DEFAULT_TEXT_SETTINGS[size].lineHeight),
        fontSize: fontSize || (size && DEFAULT_TEXT_SETTINGS[size].fontSize),
    };

    const vPadding = (Number(setting.size) - Number(setting.lineHeight)) / 2;

    return [
        css[`line-height-${lineHeight}`],
        css[`font-size-${fontSize}`],
        css[`v-padding-${vPadding}`],
    ];
};

export const applyTextProps = ({
    size = '36',
    lineHeight,
    fontSize,
    color = 'primary',
}: TextProps): string[] => {
    const textClasses = getTextClasses({
        size,
        lineHeight,
        fontSize,
    });

    return [css.root, css[`font-sans`], css[`text-color-${color}`]].concat(
        textClasses
    );
};
