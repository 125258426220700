/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createSelector, createSlice } from '@reduxjs/toolkit';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
import { INITIAL_STATE } from './constants';
import { StatusTypes } from 'constants/statuses';
/**
 * Utils
 */
import { templateString } from 'utils';
import { mapIssueToState } from './utils';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Types
 */
import {
    AppDispatch,
    TActivity,
    TAttachment,
    TComment,
    TFindingStatus,
    TGetState,
    TSelector,
} from 'types';
import {
    TCreationFormValue,
    TCreationEditCommentValue,
    TIssue,
    TFindingDataToSelect,
} from './types';
/**
 * Reducer
 */
const { reducer, actions } = createSlice({
    name: 'singleFinding',
    initialState: INITIAL_STATE,
    reducers: {
        saveData: (
            state,
            {
                payload: {
                    id,
                    asvs,
                    status,
                    summary,
                    assignee,
                    updated_by,
                    severity,
                    activity,
                    updated_at,
                    cvss_score,
                    cvss_link,
                    resolution,
                    description,
                    vulnerabilities,
                    cause_tracker_issue,
                    linked_tracker_issue,
                    marked_as_risk_accepted,
                    risk_accepted_revision_date,
                    documents_map,
                    created_at,
                    scanner_finding,
                    assessment,
                    type,
                    composite_id,
                },
            }
        ) => {
            state.id = id;
            state.asvs = asvs;
            state.status = status;
            state.activity = activity;
            state.summary = summary;
            state.severity = severity;
            state.updatedAt = updated_at;
            state.createdAt = created_at;
            state.cvssScore = cvss_score;
            state.cvssLink = cvss_link;
            state.resolution = resolution;
            state.description = description;
            state.assignee = assignee?.full_name;
            state.updatedBy = updated_by?.full_name;
            state.vulnerabilities = vulnerabilities;
            state.markedAsRiskAccepted = marked_as_risk_accepted;
            state.riskAcceptedRevisionDate = risk_accepted_revision_date;
            state.attachedImages = documents_map?.FINDING_CONTENT_IMAGE;
            state.commentsAttachedImages = documents_map?.FINDING_ATTACHMENT;
            state.scannerFinding = scanner_finding;
            state.assessment = assessment;
            state.type = type;
            state.compositeId = composite_id;

            if (cause_tracker_issue) {
                state.causeTrackerIssue = mapIssueToState(cause_tracker_issue);
            }
            if (linked_tracker_issue) {
                state.linkedIssue = mapIssueToState(linked_tracker_issue);
            }

            state.isInitialized = true;
        },
        setInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        archiveFinding: (state) => {
            state.status = StatusTypes.archived;
        },
        saveComments: (state, { payload }) => {
            state.comments = payload;
        },
        addComment: (state, { payload }) => {
            state.comments = [...state.comments, payload];
        },
        resetData: () => INITIAL_STATE,
    },
});

export default reducer;
/**
 * Selectors
 */
export const selectFindingData: TSelector<TFindingDataToSelect> = ({
    singleFinding: {
        status,
        summary,
        severity,
        assignee,
        updatedBy,
        updatedAt,
        createdAt,
        cvssScore,
        cvssLink,
        inProgress,
        resolution,
        description,
        vulnerabilities,
        causeTrackerIssue,
        markedAsRiskAccepted,
        riskAcceptedRevisionDate,
        attachedImages,
        scannerFinding,
        assessment,
        asvs,
        type,
        compositeId,
    },
}) => ({
    status,
    summary,
    severity,
    assignee,
    updatedBy,
    updatedAt,
    createdAt,
    cvssScore,
    cvssLink,
    resolution,
    inProgress,
    assessment,
    description,
    attachedImages,
    scannerFinding,
    vulnerabilities,
    markedAsRiskAccepted,
    riskAcceptedRevisionDate,
    causeTrackerIssueIsExists: Boolean(causeTrackerIssue),
    asvs,
    type,
    compositeId,
});
export const selectFindingName: TSelector<string> = ({
    singleFinding: { summary },
}) => summary;
export const selectFindingActivity: TSelector<
    TActivity | Record<string, undefined>
> = ({ singleFinding: { activity = {} } }) => activity;

export const selectAssessmentId: TSelector<string | undefined> = ({
    singleFinding: { assessment },
}) => assessment?.id;
export const selectInProgress: TSelector<boolean> = ({
    singleFinding: { inProgress },
}) => inProgress;
export const selectIsInitialized: TSelector<boolean> = ({
    singleFinding: { isInitialized },
}) => isInitialized;
export const selectMarkedAsIssue: TSelector<boolean> = ({
    singleFinding: { linkedIssue },
}) => Boolean(linkedIssue);
export const selectMarkedAsRiskAccepted: TSelector<boolean> = ({
    singleFinding: { markedAsRiskAccepted },
}) => markedAsRiskAccepted;
export const selectLinkedIssue: TSelector<TIssue> = ({
    singleFinding: { linkedIssue },
}) => linkedIssue || {};
export const selectCauseTrackerIssue: TSelector<TIssue | undefined> = ({
    singleFinding: { causeTrackerIssue },
}) => causeTrackerIssue;
export const selectRevisionDate: TSelector<Date | undefined> = ({
    singleFinding: { riskAcceptedRevisionDate },
}) => riskAcceptedRevisionDate;
export const selectStatus: TSelector<TFindingStatus | undefined> = ({
    singleFinding: { status },
}) => status;
export const selectCauseTrackerIssueExists: TSelector<boolean> = ({
    singleFinding: { causeTrackerIssue },
}) => Boolean(causeTrackerIssue);
export const selectComments: TSelector<TComment[]> = ({
    singleFinding: { comments },
}) => comments;
export const selectScannerFinding = createSelector(
    selectFindingData,
    ({ scannerFinding }) => scannerFinding
);
/**
 * Actions
 */
export const {
    saveData,
    resetData,
    addComment,
    saveComments,
    setInProgress,
    archiveFinding,
} = actions;
/**
 * Dispatchers
 */
const handleGetFindingComments =
    (findingId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = templateString(ENDPOINTS.singleFindingComments, {
            findingId,
        });

        const response = await api.request(Methods.get, { url });

        dispatch(saveComments(response));
    };

export const handleGetFindingData =
    (findingId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = templateString(ENDPOINTS.singleFinding, {
            findingId,
        });

        const response = await api.request(Methods.get, { url });

        dispatch(saveData(response));
    };

export const handleGetData =
    (findingId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setInProgress(true));

        await Promise.all([
            dispatch(handleGetFindingData(findingId)),
            dispatch(handleGetFindingComments(findingId)),
        ]);

        dispatch(setInProgress(false));
    };

export const handleArchiveFinding =
    () =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const { id } = getState().singleFinding;

        if (!id) return;

        const url = templateString(ENDPOINTS.archiveFinding, { findingId: id });

        await api.request(Methods.post, { url });

        dispatch(archiveFinding());
    };

export const handleAddComment =
    (findingId: string, { comment, attachments }: TCreationFormValue) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const formData = new FormData();

        const url = templateString(ENDPOINTS.singleFindingComments, {
            findingId,
        });

        attachments.forEach(({ file }: TAttachment) => {
            formData.append('files', file, file.name);
        });

        formData.append('commentPayload', JSON.stringify({ comment }));

        dispatch(setInProgress(true));

        try {
            await api.request(Methods.post, {
                url,
                data: formData,
            });

            await dispatch(handleGetFindingComments(findingId));
        } finally {
            dispatch(setInProgress(false));
        }
    };

export const handleEditComment =
    (
        findingId: string,
        {
            comment,
            attachments,
            commentId,
            deletedAttachmentsIds = [],
        }: TCreationEditCommentValue
    ) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const formData = new FormData();

        const url = templateString(ENDPOINTS.singleFindingEditComment, {
            findingId,
            commentId,
        });

        attachments.forEach(({ file }: TAttachment) => {
            formData.append('files', file, file.name);
        });

        formData.append(
            'commentPayload',
            JSON.stringify({
                comment,
                deleted_attachment_ids: deletedAttachmentsIds,
            })
        );

        dispatch(setInProgress(true));

        try {
            await api.request(Methods.put, {
                url,
                data: formData,
            });

            await dispatch(handleGetFindingComments(findingId));
        } finally {
            dispatch(setInProgress(false));
        }
    };

export const handleDeleteComment =
    (commentId: string, findingId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = templateString(ENDPOINTS.singleComment, {
            findingId,
            commentId,
        });

        try {
            dispatch(setInProgress(true));

            await api.request(Methods.delete, {
                url,
            });

            await dispatch(handleGetFindingComments(findingId));
        } finally {
            dispatch(setInProgress(false));
        }
    };
