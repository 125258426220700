/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
/**
 * Components
 */
import { PrivateRoute } from '../../PrivateRoute';
/**
 * Constants
 */
import { RoleTypes } from 'constants/roles';
/**
 * Pages
 */
import { ProjectsRoute } from './Routes/Projects';
import { SingleProjectRoute } from './Routes/SingleProject';
import { SingleOrganizationRoute } from './Routes/SingleOrganization';

const Persons = lazy(() => import('pages/Persons'));
const Person = lazy(() => import('pages/Person'));
const ActionLog = lazy(() => import('pages/ActionLog'));
const Activities = lazy(() => import('pages/Activities'));
const Organizations = lazy(() => import('pages/Organizations'));
const FeatureToggles = lazy(() => import('pages/FeatureToggles'));
const WithoutProjectPage = lazy(() => import('pages/WithoutProject'));
const NotificationsProjects = lazy(() => import('pages/NotificationsProjects'));
const NotificationSettings = lazy(() => import('pages/NotificationSettings'));
/**
 * Expo
 */
export const AgileSecurityPlatform: React.FC = () => {
    return (
        <Suspense fallback={null}>
            <Routes>
                <Route path="/projects/*" element={<ProjectsRoute />} />
                <Route
                    path="/project/:projectId/*"
                    element={<SingleProjectRoute />}
                />
                <Route
                    path="/organization/:organizationId/*"
                    element={<SingleOrganizationRoute />}
                />
                <Route
                    path="/not-assigned-project"
                    element={
                        <PrivateRoute
                            component={WithoutProjectPage}
                            roles={[
                                RoleTypes.securitySpecialist,
                                RoleTypes.projectRepresentative,
                            ]}
                        />
                    }
                />
                <Route
                    path="/persons"
                    element={
                        <PrivateRoute
                            component={Persons}
                            roles={[RoleTypes.admin]}
                        />
                    }
                />
                <Route
                    path="/persons/:personId/notification-settings"
                    element={
                        <PrivateRoute
                            component={NotificationSettings}
                            roles={Object.values(RoleTypes)}
                        />
                    }
                />
                <Route
                    path="/persons/:personId"
                    element={
                        <PrivateRoute
                            component={Person}
                            roles={[RoleTypes.admin]}
                        />
                    }
                />
                <Route
                    path="/feature-toggles"
                    element={
                        <PrivateRoute
                            component={FeatureToggles}
                            roles={[RoleTypes.admin]}
                        />
                    }
                />
                <Route
                    path="/activities"
                    element={
                        <PrivateRoute
                            component={Activities}
                            roles={[RoleTypes.admin]}
                        />
                    }
                />
                <Route
                    path="/organizations"
                    element={
                        <PrivateRoute
                            component={Organizations}
                            roles={[RoleTypes.admin]}
                        />
                    }
                />
                <Route
                    path="action-log"
                    element={
                        <PrivateRoute
                            component={ActionLog}
                            roles={[
                                RoleTypes.operationsManager,
                                RoleTypes.admin,
                            ]}
                        />
                    }
                />
                <Route
                    path="notifications"
                    element={
                        <PrivateRoute
                            component={NotificationsProjects}
                            roles={[RoleTypes.operationsManager]}
                        />
                    }
                />
                {/* Default page */}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Suspense>
    );
};
