/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

export interface Option {
    id: string;
    label: string;
}

export function getSelectOptionsFromEnum<
    Enumeration extends Record<string, string>,
    Value extends Enumeration[keyof Enumeration]
>(
    enumeration: Enumeration,
    mapLabel?: (label: Value) => string
): Array<Option> {
    return Object.values(enumeration).map((value) => ({
        id: value,
        label: mapLabel ? mapLabel(value as Value) : value,
    }));
}
