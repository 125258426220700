/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useMemo, useRef } from 'react';
/**
 * Hooks
 */
import { useHover } from 'hooks';
/**
 * Components
 */
import { Panel } from 'components/common';
import { ProjectList } from './ProjectList';
import {
    Text,
    FlexRow,
    FlexCell,
    FlexSpacer,
    IconContainer,
} from '@epam/promo';
/**
 * Assets
 */
import css from './style.module.scss';
import { ReactComponent as arrowIcon } from '@epam/assets/icons/common/navigation-chevron-right-24.svg';
/**
 * Utils
 */
import { cx } from '@epam/uui-core';
/**
 * Types
 */
import { TOrganizationItemProps } from './types';
/**
 * Expo
 */
export const OrganizationItem: React.FC<TOrganizationItemProps> = ({
    projects,
    isHovered,
    onHoverOrganization,
    id: organizationId,
    company_name: companyName,
    onChooseProject,
    onChooseOrganization,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    useHover(ref, (hovered) => {
        if (hovered) {
            onHoverOrganization(organizationId);
        }
    });

    const projectsLabel = useMemo(
        () =>
            `${projects.length} ${
                projects.length === 1 ? 'project' : 'projects'
            }`,
        [projects]
    );

    const handleClick = useCallback(() => {
        onChooseOrganization(organizationId);
    }, [organizationId, onChooseOrganization]);

    return (
        <div ref={ref} className={css.container}>
            <Panel
                onClick={handleClick}
                cx={cx(css.panel, isHovered && css.hovered)}
            >
                <FlexRow>
                    <FlexCell cx={css.organizationName}>
                        <Text fontSize="16" lineHeight="24">
                            {companyName}
                        </Text>
                        <Text fontSize="16" lineHeight="24" color="gray60">
                            {projectsLabel}
                        </Text>
                    </FlexCell>
                    <FlexSpacer />
                    <IconContainer
                        icon={arrowIcon}
                        style={{ fill: 'var(--uui-neutral-50)' }}
                    />
                </FlexRow>
            </Panel>
            {isHovered && (
                <ProjectList
                    list={projects}
                    containerRef={ref}
                    onChooseProject={onChooseProject}
                />
            )}
        </div>
    );
};
