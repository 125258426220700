import { $isTextNode, DOMConversion, DOMConversionOutput } from 'lexical';
import { EXTRA_WRAPPERS, ExtraTag, Highlighting } from '../../enums';
import { removeStyleAndSetDataAttribute } from '../helpers';

export function importWithHighlighting(
    originalDOMConverter?: (node: HTMLElement) => DOMConversion | null
): (node: HTMLElement) => DOMConversionOutput | null {
    return (node) => {
        const original = originalDOMConverter?.(node);
        if (!original) {
            return null;
        }
        const originalOutput = original.conversion(node);

        if (!originalOutput) {
            return originalOutput;
        }
        const isHighlight =
            node.dataset?.background === 'BACKGROUND_HIGHLIGHTS_YELLOW';
        return {
            ...originalOutput,
            forChild: (lexicalNode, parent) => {
                const originalForChild = originalOutput?.forChild ?? ((x) => x);
                const result = originalForChild(lexicalNode, parent);
                if ($isTextNode(result)) {
                    // All additional CSS styles should be listed here
                    const style = [
                        isHighlight
                            ? `background-color: ${Highlighting.Color}`
                            : null,
                    ]
                        .filter((value) => value != null)
                        .join('; ');
                    if (style.length) {
                        return result.setStyle(style);
                    }
                }
                return result;
            },
        };
    };
}

function isHighlighted(element: Element): element is HTMLElement {
    return (
        element instanceof HTMLElement &&
        element.style.backgroundColor === Highlighting.Color
    );
}

function isExtraWrapperElement(element: Element) {
    return EXTRA_WRAPPERS.includes(element.tagName.toLowerCase() as ExtraTag);
}

function handleExtraWrapperHighlighting(extraElement: Element) {
    const child = extraElement.firstElementChild;

    if (!child) return;

    if (!isExtraWrapperElement(child)) {
        if (isHighlighted(child)) {
            removeStyleAndSetDataAttribute(child, {
                name: Highlighting.AttributeName,
                value: Highlighting.AttributeValue,
            });
        }
        return;
    }

    handleExtraWrapperHighlighting(child);
}

export function exportHighlighting(element: Element): void {
    if (isHighlighted(element)) {
        removeStyleAndSetDataAttribute(element, {
            name: Highlighting.AttributeName,
            value: Highlighting.AttributeValue,
        });
        return;
    }

    if (isExtraWrapperElement(element)) {
        handleExtraWrapperHighlighting(element);
    }
}

/**
 * function to modify resulted HTML element during importing DOM
 */
export function exportDom(element: Element): void {
    exportHighlighting(element);
}
