/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createAction } from '@reduxjs/toolkit';
/**
 * Utils
 */
import { createActionContext } from 'utils/store';
/**
 * Types
 */
import { TNotification } from 'types';
/**
 * Expo
 */
const createActionType = createActionContext('NOTIFICATIONS');

export const getList = createAction(createActionType('GET_LIST'));
export const getDropdownList = createAction(
    createActionType('GET_DROPDOWN_LIST')
);
export const saveCount = createAction<number>(createActionType('SAVE_COUNT'));
export const saveDropdownList = createAction<{
    notifications: TNotification[];
    notViewedCount: number;
}>(createActionType('SAVE_DROPDOWN_LIST'));
export const saveList = createAction<{
    notifications: TNotification[];
}>(createActionType('SAVE_LIST'));
export const markAsRead = createAction<string>(
    createActionType('MARK_AS_READ')
);
export const markAsReadAll = createAction(createActionType('MARK_AS_READ_ALL'));

export const markAsReadAllInDropdown = createAction(
    createActionType('MARK_AS_READ_ALL_IN_DROPDOWN')
);
