/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Text } from '../Text';
import React, { FC } from 'react';
import { TitleSeparator } from './TitleSeparator';
import css from './style.module.scss';
import { WithDataTestId } from 'types';
import { cx } from '@epam/uui-core';

export type TPanelTitleProps = {
    title: string;
    withSeparator?: boolean;
    withMargin?: boolean;
} & WithDataTestId;

export const PanelTitle: FC<TPanelTitleProps> = ({
    title,
    withSeparator = false,
    withMargin = true,
    dataTestId,
}) => (
    <div className={cx(css.title, withMargin ? css.marginBottom : undefined)}>
        <Text
            dataTestId={dataTestId}
            fontSize="24"
            fontWeight="400"
            lineHeight="30"
        >
            {title}
        </Text>
        {withSeparator && <TitleSeparator />}
    </div>
);
