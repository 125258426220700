/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

// The Current TS version is unable to infer types of discriminated unions after destructuring assignment, so we use `props.mode` instead of `mode`
/* eslint-disable react/destructuring-assignment */

import { AccordionProps } from '@epam/uui-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { Panel } from 'components/common';
import {
    useAppForm,
    useAppSelector,
    useRouteParams,
    UseAppFormProps,
} from 'hooks';
import { FC, ReactElement } from 'react';
import { DefaultValues, FormProvider } from 'react-hook-form';
import { AppDispatch, RootState } from 'types';

import { z } from 'zod';
import { FormMode } from '../types';
import { EditableItemCardProvider } from './EditableItemCardContext';
import { Item } from './Item';
import {
    BaseItemFormSchema,
    FormValuesWithoutTmId,
} from './ItemEditor/forms/baseItemFormSchema';
import { FormFooter } from './ItemEditor/forms/common/FormFooter';
import css from './style.module.scss';
import { createReadableId } from './utils';

interface BaseEditableFormProps {
    initialMode: FormMode;
    header: ReactElement;
    isOpen?: boolean;
    renderAdditionalHeaderItems?: AccordionProps['renderAdditionalItems'];
}

/**
 * `BaseItemFormSchemaConstraint` represents either a basic `BaseItemFormSchema`
 * or a `ZodEffects<BaseItemFormSchema>` when cross-field validation is used
 * with `refine`, which always results in a schema with effects.
 */
type BaseItemFormSchemaConstraint =
    | BaseItemFormSchema
    | z.ZodEffects<BaseItemFormSchema>;

interface FormControlsProps<
    Schema extends BaseItemFormSchemaConstraint,
    FormValues extends z.infer<Schema> = z.infer<Schema>
> {
    formSchema: Schema;
    onCreate: (values: FormValues) => (dispatch: AppDispatch) => Promise<void>;
    onEdit: (
        id: string,
        values: FormValues
    ) => (dispatch: AppDispatch) => Promise<void>;
    readableIdPrefix: string;
    countSelector: (state: RootState) => number;
}

type CreateModeProps<PartialDataWithoutId> = {
    initialMode: FormMode.create;
    defaultValues?: PartialDataWithoutId;
    onClose: () => void;
};

type ViewOrEditModeProps<DataWithoutId> = {
    initialMode: FormMode.view | FormMode.edit;
    values: DataWithoutId;
};

type EditableFormProps<
    Schema extends BaseItemFormSchemaConstraint,
    FormValues extends z.infer<Schema>,
    DataWithoutId extends FormValuesWithoutTmId<FormValues> = FormValuesWithoutTmId<FormValues>,
    PartialDataWithoutId extends DefaultValues<DataWithoutId> = DefaultValues<DataWithoutId>
> = BaseEditableFormProps &
    (
        | CreateModeProps<PartialDataWithoutId>
        | ViewOrEditModeProps<DataWithoutId>
    );

export const createEditableItemCard = <
    Schema extends BaseItemFormSchemaConstraint,
    FormValues extends z.infer<Schema> = z.infer<Schema>
>(
    FormFieldsComponent: FC,
    {
        formSchema,
        onCreate,
        onEdit,
        readableIdPrefix,
        countSelector,
    }: FormControlsProps<Schema>
) => {
    const EditableItemCard: React.FC<EditableFormProps<Schema, FormValues>> = (
        props
    ) => {
        const { activityId: tmId } = useRouteParams();
        const itemsCount = useAppSelector(countSelector);
        const { header, isOpen, renderAdditionalHeaderItems } = props;
        const onClose =
            props.initialMode === FormMode.create ? props.onClose : () => {};

        const formProps: UseAppFormProps<FormValues> = {
            resolver: zodResolver(formSchema),
            shouldSaveDraftInStorage: false,
            shouldBlockNavigationWhenUnsaved: true,
        };

        if (props.initialMode === FormMode.create) {
            /**
             * Bug with type inference, so we use ` as DefaultValues<FullSchemaShape>` to fix it.
             * I created issues:
             * @see https://github.com/react-hook-form/react-hook-form/issues/11865
             * @see https://stackoverflow.com/questions/78434157/react-hook-form-typescipt-error-with-defaultvalues-ts2322-type-is-not-assignab
             */
            formProps.defaultValues = {
                tmId,
                readableId: createReadableId(
                    readableIdPrefix,
                    String(itemsCount + 1)
                ),
                ...props.defaultValues,
            } as DefaultValues<FormValues>;
        }
        if (props.initialMode !== FormMode.create) {
            /**
             * Bug with type inference, so we use ` as FormValues` to fix it.
             * I created issues:
             * @see https://github.com/react-hook-form/react-hook-form/issues/11865
             * @see https://stackoverflow.com/questions/78434157/react-hook-form-typescipt-error-with-defaultvalues-ts2322-type-is-not-assignab
             */
            const values = {
                tmId,
                ...props.values,
            };
            formProps.values = values as FormValues;
        }
        const formMethods = useAppForm<FormValues>(formProps);

        return (
            <EditableItemCardProvider
                initialState={{ formSchema, mode: props.initialMode }}
            >
                <Item
                    open={isOpen}
                    header={header}
                    renderAdditionalHeaderItems={renderAdditionalHeaderItems}
                >
                    <Panel cx={css.itemEditorForm}>
                        <FormProvider {...formMethods}>
                            <FormFieldsComponent />
                            <FormFooter
                                onCreate={onCreate}
                                onEdit={onEdit}
                                onClose={onClose}
                            />
                        </FormProvider>
                    </Panel>
                </Item>
            </EditableItemCardProvider>
        );
    };

    return EditableItemCard;
};
