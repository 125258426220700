/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { FlexRow } from 'components/common';
import { FindingStatus } from 'components/FindingStatus';
/**
 * Types
 */
import { TOption } from '../types';
import { TFindingStatus } from 'types';
/**
 * Expo
 */
export const renderItem = ({ id }: TOption): JSX.Element => (
    <FlexRow>
        <FindingStatus type={id as TFindingStatus} />
    </FlexRow>
);
