/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useState } from 'react';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
/**
 * Components
 */
import { FlexSpacer, ModalFooter } from '@epam/promo';
import {
    Text,
    Panel,
    Button,
    Checkbox,
    Modal as ModalComponent,
} from 'components/common';
/**
 * Types
 */
import { IModal } from '@epam/uui-core';
import { TMarkAsIssueModalProps } from './types';
/**
 * Constants
 */
import { ModalSizes } from 'constants/modals';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Store
 */
import {
    selectIsIntegratedWithAzure,
    selectIsIntegratedWithJira,
} from 'store/project';
/**
 * Utils
 */
import { getTrackerName } from './utils';
/**
 * Expo
 */
export const MarkAsIssueModal: React.FC<
    IModal<string> & TMarkAsIssueModalProps
> = ({ name, onSubmit, ...props }) => {
    const [withTrackerIssue, setWIthTrackerIssue] = useState(true);

    const isIntegratedWithJira = useSelector(selectIsIntegratedWithJira);
    const isIntegratedWithAzure = useSelector(selectIsIntegratedWithAzure);

    const isIntegrated = isIntegratedWithJira || isIntegratedWithAzure;

    const trackerName = getTrackerName({
        isIntegratedWithJira,
        isIntegratedWithAzure,
    });

    return (
        <ModalComponent
            {...props}
            withoutFooter
            width={ModalSizes.large}
            allowCloseOnCross={false}
            title={`Mark ${name} as issue?`}
            renderBody={({ abort }) => (
                <>
                    <Panel cx={css.formContainer}>
                        <Text>
                            Findings marked as issues will be visible to Project
                            Representatives, and it will not be possible to
                            archive this finding once marked.
                        </Text>
                    </Panel>
                    <ModalFooter borderTop>
                        {!trackerName ? null : (
                            <Checkbox
                                value={withTrackerIssue}
                                label={`Create issue in ${trackerName}`}
                                onValueChange={setWIthTrackerIssue}
                            />
                        )}
                        <FlexSpacer />
                        <Button
                            fill="none"
                            color="neutral"
                            onClick={abort}
                            caption="Cancel"
                            dataTestId="singleFinding/retestForm/cancel"
                        />
                        <Button
                            color="primary"
                            caption="Submit"
                            dataTestId="singleFinding/retestForm/submit"
                            onClick={() => {
                                onSubmit(
                                    isIntegrated ? withTrackerIssue : false
                                );
                                abort();
                            }}
                        />
                    </ModalFooter>
                </>
            )}
        />
    );
};
