/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { Middleware } from 'redux';
/**
 * Dependencies
 */
import { resetData as resetTasksData } from 'store/tasks';
import { resetData as resetTargetsData } from 'store/targets';
import { resetData as resetReportsData } from 'store/reports';
import { resetData as resetFindingsData } from 'store/findings';
import { resetData as resetAssessmentsData } from 'store/assessments';
import {
    resetProjectData,
    updateAttachments as updateProjectsAttachments,
} from 'store/project';
import {
    resetData as resetDashboardData,
    updateFindingsData as updateDashboardFindingsData,
    updatePolicyViolation as updateDashboardPolicyViolation,
    updateBlockedActivities as updateDashboardBlockedActivities,
} from 'store/dashboard';
/**
 * Types
 */
import { AppDispatch, RootState, TGetState } from 'types';
/**
 * Actions
 */
import {
    activityStatusDidUpdateAction,
    findingsDidUpdateAction,
    attachmentsDidUpdateAction,
    resetSingleProjectReducers,
} from './actions';
/**
 * Expo
 */
export const backgroundUpdateMiddleware: Middleware<
    Record<string, unknown>,
    RootState
> =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        const thunkDispatch = (
            thunkAction: (
                dispatch: AppDispatch,
                getState: TGetState
            ) => Promise<void>
        ) => thunkAction(dispatch, getState);

        switch (action.type) {
            case findingsDidUpdateAction.type:
                thunkDispatch(updateDashboardFindingsData());
                thunkDispatch(updateDashboardPolicyViolation());
                break;
            case attachmentsDidUpdateAction.type:
                thunkDispatch(updateProjectsAttachments());
                break;
            case activityStatusDidUpdateAction.type:
                thunkDispatch(updateDashboardBlockedActivities());
                break;
            case resetSingleProjectReducers.type:
                dispatch(resetProjectData());
                dispatch(resetTasksData());
                dispatch(resetTargetsData());
                dispatch(resetReportsData());
                dispatch(resetFindingsData());
                dispatch(resetDashboardData());
                dispatch(resetAssessmentsData());
                break;
            default:
                break;
        }

        return next(action);
    };
