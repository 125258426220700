/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
import { useAppForm, useRedirect } from 'hooks';
/**
 * Components
 */
import { Blocker } from 'components/common';
import { ConfigureSteps } from './components/Steps';
/**
 * Constants
 */
import { TabsIds } from './constants';
import { PAGES } from 'constants/pages';
import { ReportState } from 'constants/reports';
/**
 * Store
 */
import { selectHeaderInformation } from 'store/user';
/**
 * Types
 */
import { TProps } from './types';
/**
 * Utils
 */
import { checkIfReportExisted, renderContent } from './utils';
import { NotificationTypes, pick, showNotification } from 'utils';
/**
 * Expo
 */

export const ReportWizard = <
    TFormValues extends { name: string; content: string[] }
>({
    tabs,
    onSubmit,
    contentSteps,
    defaultValues,
}: TProps<TFormValues>): JSX.Element => {
    const [inProgress, setInProgress] = useState(false);
    const methods = useAppForm<TFormValues>({
        mode: 'all',
        defaultValues,
        id: 'configureReportForm',
    });

    const { trigger, reset, getValues } = methods;

    const redirect = useRedirect();

    const { projectName, organizationName } = useSelector(
        selectHeaderInformation
    );

    useEffect(() => {
        reset({
            ...defaultValues,
            projectName,
            organizationName,
        });
    }, [reset, projectName, organizationName, defaultValues]);

    const handleGoNext = useCallback(
        async (tabId: string) => {
            const isValid = await trigger();

            if (!isValid) return Promise.reject();

            if (tabId === TabsIds.general) {
                try {
                    setInProgress(true);

                    await checkIfReportExisted(getValues().name);
                } finally {
                    setInProgress(false);
                }
            }

            return Promise.resolve();
        },
        [getValues, trigger]
    );

    const handleCancel = useCallback(() => {
        redirect(PAGES.reports);
    }, [redirect]);

    const handleSubmit = useCallback(
        async (values: TFormValues, state: ReportState) => {
            setInProgress(true);

            try {
                await onSubmit(values, state);

                showNotification({
                    text: 'Report is being generated, it will appear in a moment after refreshing the "Reports" page',
                    type: NotificationTypes.success,
                });

                redirect(PAGES.reports);
            } finally {
                setInProgress(false);
            }
        },
        [onSubmit, redirect]
    );

    const handleSubmitWitState = useCallback(
        (state: ReportState) => {
            methods.handleSubmit((values) => handleSubmit(values, state))();
        },
        [methods, handleSubmit]
    );

    const renderStepsContent = useCallback(
        (stepId: string) => {
            return renderContent(stepId, contentSteps);
        },
        [contentSteps]
    );

    const contentStepsIds = useMemo(
        () => pick(contentSteps, 'id'),
        [contentSteps]
    );

    return (
        <>
            <Blocker isEnabled={inProgress} />
            <FormProvider {...methods}>
                <ConfigureSteps
                    steps={tabs}
                    contentStepsIds={contentStepsIds}
                    handleCancel={handleCancel}
                    validateStep={handleGoNext}
                    dataTestId="configureReport"
                    renderStepContent={renderStepsContent}
                    handleSubmitWitState={handleSubmitWitState}
                />
            </FormProvider>
        </>
    );
};
