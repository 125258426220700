/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Panel, Text } from 'components/common';
import { FlexRow } from '@epam/promo';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { LargeCardProps } from './types';
/**
 * Expo
 */
export const Large: React.FC<LargeCardProps> = ({
    name,
    className,
    dataTestId,
    renderContent,
}) => {
    return (
        <Panel
            background="white"
            shadow
            cx={className}
            dataTestId={`${dataTestId}/cardContainer`}
        >
            <FlexRow cx={css.headerCardContainer} borderBottom columnGap="12">
                <Text
                    cx={css.headerCardTitle}
                    fontSize="24"
                    dataTestId={`${dataTestId}/card/title`}
                >
                    {name}
                </Text>
            </FlexRow>
            <FlexRow padding="18" vPadding="18">
                {renderContent?.()}
            </FlexRow>
        </Panel>
    );
};
