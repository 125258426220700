/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { useCallback } from 'react';
/**
 * Types
 */
type TGetDebouncedDataLayerEvent = (props: {
    event: string;
    delay: number;
}) => {
    send: <TParameters extends Record<string, unknown>>(
        parameters: TParameters
    ) => void;
    stop: () => void;
};

type TAnalyticsParameters = {
    element_ID: string;
    [key: string]: unknown;
};
/**
 * Expo
 */
export const sendDataLayerEvent = <TParameters extends Record<string, unknown>>(
    event: string,
    parameters: TParameters
): void => {
    if (!window.dataLayer) {
        window.dataLayer = [];
    }

    window.dataLayer.push({
        event,
        ...parameters,
    });
};

export const getDebouncedDataLayerEvent: TGetDebouncedDataLayerEvent = ({
    event,
    delay,
}) => {
    let timer: number;

    return {
        send(parameters) {
            this.stop();
            timer = window.setTimeout(() => {
                sendDataLayerEvent(event, parameters);
            }, delay);
        },
        stop() {
            clearTimeout(timer);
        },
    };
};

export const useHoverEvent = <T extends TAnalyticsParameters>(
    event: string,
    parameters: T
) => {
    const onMouseEnter = useCallback(() => {
        sendDataLayerEvent(event, parameters);
    }, [event, parameters]);

    return {
        onMouseEnter,
    };
};
