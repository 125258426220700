/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createSlice } from '@reduxjs/toolkit';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { INITIAL_STATE } from './constants';
import { Methods } from 'constants/request';
/**
 * Types
 */
import { TReportData } from './types';
import { AppDispatch, TSelector } from 'types';
/**
 * Utils
 */
import { templateString } from 'utils';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Expo
 */
const { reducer, actions } = createSlice({
    name: 'singleReport',
    initialState: INITIAL_STATE,
    reducers: {
        saveData: (state, { payload: { id, updated_at: updatedAt, name } }) => {
            state.data = {
                ...state.data,
                id,
                name,
                updatedAt,
            };

            state.isInitialized = true;
        },
        saveAttachments: (state, { payload }) => {
            state.data.attachments = payload;
        },
        setInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        resetData: () => INITIAL_STATE,
    },
});

export default reducer;
/**
 * Selectors
 */
export const selectReportData: TSelector<TReportData> = ({
    singleReport: { data },
}) => data;
export const selectInProgress: TSelector<boolean> = ({
    singleReport: { inProgress },
}) => inProgress;
export const selectIsInitialized: TSelector<boolean> = ({
    singleReport: { isInitialized },
}) => isInitialized;
/**
 * Actions
 */
export const { saveData, resetData, saveAttachments, setInProgress } = actions;
/**
 * Dispatchers
 */
const handleGetAttachments =
    (reportId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = templateString(ENDPOINTS.reportAttachments, {
            reportId,
        });

        const response = await api.request(Methods.get, { url });

        dispatch(saveAttachments(response));
    };

export const handleGetReport =
    (reportId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = templateString(ENDPOINTS.singleReport, {
            reportId,
        });

        const response = await api.request(Methods.get, { url });

        dispatch(saveData(response));
    };

export const handleGetData =
    (reportId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setInProgress(true));

        await dispatch(handleGetReport(reportId));
        await dispatch(handleGetAttachments(reportId));

        dispatch(setInProgress(false));
    };
