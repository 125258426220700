/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import {
    FlexRow,
    FlexCell,
    IconButton,
    LinkButton,
    TextInput,
} from 'components/common';
import { TTextInputProps } from 'components/common/TextInput/types';
import { FieldController } from 'components/Fields';
import { Label } from './EditableField/Label';
import { DefaultView } from './EditableField/DefaultView';
import { useEditableItemCard } from '../../../EditableItemCardContext';
import { extractShapeFromSchema } from './EditableField/utils';
import css from './style.module.scss';
import { ZodEntryPointsDto } from '../constants';
// TODO: change import path after full migration, including technology, label is related not only to technology
import { FormMode } from '../../../../types';
import { FC } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { ReactComponent as addIcon } from '@epam/assets/icons/common/action-add-18.svg';
import { ReactComponent as deleteIcon } from '@epam/assets/icons/common/action-delete-18.svg';

const FIELD_NAME = 'entryPoints';

// TODO: fix paddings after full migration to make the same as in the design and other fields
export const EntryPoints: FC = () => {
    const { control } = useFormContext();
    const { fields, remove, append } = useFieldArray({
        control,
        name: FIELD_NAME,
    });

    const {
        state: { mode, formSchema },
    } = useEditableItemCard();

    const shape = extractShapeFromSchema(formSchema);
    const isRequired = !shape[FIELD_NAME]?.isOptional();

    const entryPoints = useWatch({
        name: FIELD_NAME,
    }) as Array<ZodEntryPointsDto>;

    if (mode === FormMode.view) {
        const entryPointsArr = entryPoints.map((item) => item.name || '–');
        return (
            <DefaultView
                labelText="Attack surface entry point"
                value={entryPointsArr}
                dataTestId={FIELD_NAME}
            />
        );
    }

    return (
        // TODO: handle case when isRequired to add red asterisk, now it's not required, though
        <>
            <Label dataTestId={FIELD_NAME}>Attack surface entry points</Label>
            {fields.map((item, index) => (
                <FlexRow key={item.id} vPadding="12">
                    <FlexCell grow={1}>
                        <FieldController<TTextInputProps>
                            name={`${FIELD_NAME}.${index}.name`}
                            component={TextInput}
                            maxLength={200}
                            dataTestId={`${FIELD_NAME}/${index + 1}`}
                            placeholder="Enter a text"
                            isRequired={isRequired}
                        />
                    </FlexCell>
                    <FlexCell cx={css.deleteButton}>
                        <IconButton
                            dataTestId={`${FIELD_NAME}/delete/${index + 1}`}
                            icon={deleteIcon}
                            color="info"
                            onClick={() => remove(index)}
                        />
                    </FlexCell>
                </FlexRow>
            ))}
            <LinkButton
                onClick={() => append({})}
                icon={addIcon}
                caption="Add attack surface entry point"
                dataTestId={`${FIELD_NAME}/add`}
            />
        </>
    );
};
