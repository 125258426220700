/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useMemo } from 'react';
/**
 * Components
 */
import { TextArea as UuiTextArea, LabeledInput } from '@epam/promo';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Constants
 */
import { VALIDATION_MESSAGE } from 'constants/validation';
/**
 * Types
 */
import { TTextAreaProps } from './types';
/**
 * Utils
 */
import { getRawProps } from 'utils';
import { cx } from '@epam/uui-core';
/**
 * Expo
 */
export const TextArea: React.FC<TTextAreaProps> = ({
    label,
    rows,
    info,
    isInvalid,
    value = '',
    maxLength,
    isDisabled,
    isReadonly,
    isRequired,
    placeholder,
    isOptional,
    dataTestId,
    labelCx,
    validationMessage = VALIDATION_MESSAGE,
    infoIcon,
    onFocus,
    onBlur,
    onKeyDown,
    onValueChange,
}) => {
    const handleChange = useCallback(
        (inputValue: string) => {
            if (onValueChange) {
                onValueChange(inputValue.trim() ? inputValue : '');
            }
        },
        [onValueChange]
    );

    const rawProps = useMemo(() => getRawProps({ dataTestId }), [dataTestId]);

    return (
        <LabeledInput
            label={label}
            isInvalid={isInvalid}
            isRequired={isRequired}
            validationMessage={validationMessage}
            info={info}
            isOptional={isOptional}
            infoIcon={infoIcon}
            cx={cx(css.optionalTextAlign, labelCx)}
        >
            <UuiTextArea
                rows={rows}
                value={value}
                onFocus={onFocus}
                rawProps={rawProps}
                onKeyDown={onKeyDown}
                maxLength={maxLength}
                isInvalid={isInvalid}
                isDisabled={isDisabled}
                isReadonly={isReadonly}
                placeholder={placeholder}
                onBlur={onBlur}
                onValueChange={handleChange}
            />
        </LabeledInput>
    );
};
