/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
/**
 * Types
 */
type TGetRedirectUrl = (pathname: string) => string;
/**
 * Expo
 */
/**
 * After choosing current project user should be redirected to 1 navigation level
 * i.e. single assessment -> assessment list
 */
export const getRedirectUrl: TGetRedirectUrl = (pathname) => {
    switch (true) {
        case pathname.includes('assessments'):
            return PAGES.assessments;
        case pathname.includes('findings'):
            return PAGES.findings;
        case pathname.includes('tasks'):
            return PAGES.tasks;
        case pathname.includes('reports'):
            return PAGES.reports;
        case pathname.includes('targets'):
            return PAGES.targets;
        case pathname.includes('info'):
            return PAGES.projectInfo;
        default:
            return PAGES.dashboard;
    }
};
