/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useEffect, useRef } from 'react';
import headerCSS from '../../../../PageLoadHandler/PageHeader/style.module.scss';
import { theme } from '../../theme';

type ToolbarState = {
    isSticky: boolean;
    stickPosition: number;
};
export const useStickyToolbar = () => {
    const toolbarRef = useRef<HTMLDivElement>(null);
    const state = useRef<ToolbarState>({
        isSticky: false,
        stickPosition: 0,
    });

    useEffect(() => {
        const toolbar = toolbarRef.current;

        if (!toolbar) {
            throw Error('Toolbar not found');
        }
        const richEditor = toolbar.nextElementSibling;
        if (!richEditor || !richEditor.classList.contains(theme.editor.root)) {
            throw Error('RichEditor not found');
        }

        const header = document.getElementById('page-header-container');
        let headerPosition = 0;
        let observer: MutationObserver | undefined;

        if (header) {
            // If header is sticky, the position of the header is not changing
            if (header.classList.contains(headerCSS.stickyPositionHeader)) {
                headerPosition = header.clientHeight;
            } else {
                const headerHeight = header.clientHeight;
                headerPosition = headerHeight + header.offsetTop;

                // On every header attributes change the header position is recalculated
                observer = new MutationObserver(() => {
                    headerPosition =
                        headerHeight + Number(header.style.top.slice(0, -2));
                    if (state.current.isSticky) {
                        toolbar.style.top = `${headerPosition}px`;
                    }
                });
                observer.observe(header, { attributes: true });
            }
        }

        const width = `${toolbar.clientWidth}px`;

        const handler = () => {
            if (
                !state.current.isSticky &&
                toolbar.getBoundingClientRect().top <= headerPosition
            ) {
                state.current.isSticky = true;
                state.current.stickPosition = window.scrollY;

                toolbar.classList.add(theme.toolbar.sticky);
                toolbar.style.width = width;
                toolbar.style.top = `${headerPosition}px`;
                richEditor.classList.add(theme.editor.sticky);
            } else if (
                state.current.isSticky &&
                window.scrollY < state.current.stickPosition
            ) {
                state.current.isSticky = false;

                toolbar.style.width = '';
                toolbar.style.top = '';
                toolbar.classList.remove(theme.toolbar.sticky);
                richEditor.classList.remove(theme.editor.sticky);
            }
        };
        document.addEventListener('scroll', handler);

        return () => {
            document.removeEventListener('scroll', handler);
            if (observer) {
                observer.disconnect();
            }
        };
    }, []);

    return toolbarRef;
};
