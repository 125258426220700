/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { TComment } from 'types';
/**
 * Utils
 */
import { getEditorState } from 'components/common/RichEditorInput/utils';
/**
 * Expo
 */
export const checkIsEmptyComment = ({ comment }: TComment): boolean => {
    const htmlContent = getEditorState({ value: comment })
        .getCurrentContent()
        .getPlainText()
        .trim();

    return htmlContent === 'null' || htmlContent === '';
};
