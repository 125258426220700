/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useMemo } from 'react';
/**
 * Components
 */
import { Popover } from './Popover';
import { StatusBar } from 'components/common/StatusBar';
/**
 * Types
 */
import { ActivityProps } from './types';
/**
 * Utils
 */
import { sortStatuses } from './utils';
import { useHoverEvent } from 'utils';
/**
 * Constants
 */
import { DEFAULT_STATUS_LIST } from './constants';
/**
 * Expo
 */
export const Activities: React.FC<ActivityProps> = ({
    activity_statuses = DEFAULT_STATUS_LIST,
    name,
}) => {
    const statusList = useMemo(
        () => sortStatuses(activity_statuses),
        [activity_statuses]
    );

    const analyticsData = useMemo(
        () => ({
            element_ID: `assessments/activities_bar`,
            name,
        }),
        [name]
    );
    const hoverHandlers = useHoverEvent(
        'hover_current_activities',
        analyticsData
    );

    return (
        <Popover statusList={statusList} title={name}>
            <StatusBar
                dataTestId="assessments/activities_bar"
                statusList={statusList}
                {...hoverHandlers}
            />
        </Popover>
    );
};
