/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createSlice } from '@reduxjs/toolkit';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { INITIAL_STATE } from './constants';
import { Methods } from 'constants/request';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Types
 */
import {
    Task,
    TFilter,
    TGetState,
    TSelector,
    AppDispatch,
    PaginatedResponse,
} from 'types';
/**
 * Utils
 */
import { createQueryUrl } from 'utils';

/**
 * Reducer
 */
const { reducer, actions } = createSlice({
    name: 'tasks',
    initialState: INITIAL_STATE,
    reducers: {
        saveList: (state, { payload }) => {
            state.list = payload;

            state.isInitialized = true;
        },
        setInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        setIsEmpty: (state, { payload }) => {
            state.isEmpty = payload;
        },
        saveFilter: (state, { payload }) => {
            state.filter = payload;
        },
        setIsInitialized: (state, { payload }) => {
            state.isInitialized = payload;
        },
        resetData: () => INITIAL_STATE,
    },
});

export default reducer;
/**
 * Selectors
 */
export const selectList: TSelector<Array<Task>> = ({ tasks: { list } }) => list;
export const selectInProgress: TSelector<boolean> = ({
    tasks: { inProgress },
}) => inProgress;
export const selectIsInitialized: TSelector<boolean> = ({
    tasks: { isInitialized },
}) => isInitialized;
export const selectIsEmpty: TSelector<boolean> = ({ tasks: { isEmpty } }) =>
    isEmpty;
export const selectFilter: TSelector<TFilter> = ({ tasks: { filter } }) =>
    filter;
/**
 * Actions
 */
export const {
    saveList,
    setInProgress,
    resetData,
    setIsEmpty,
    setIsInitialized,
    saveFilter,
} = actions;
/**
 * Dispatchers
 */
export const handleGetList =
    (projectId: string) =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const filter = selectFilter(getState());
        const isEmpty = selectIsEmpty(getState());

        const queryUrl = createQueryUrl(
            ENDPOINTS.tasksFiltered,
            {
                ...filter,
                projectId,
            },
            { arrayFormat: 'repeat' }
        );

        dispatch(setInProgress(true));

        try {
            const { data } = await api.request<PaginatedResponse<Array<Task>>>(
                Methods.get,
                { url: queryUrl }
            );

            if (data.length && isEmpty) {
                dispatch(setIsEmpty(false));
            }

            dispatch(saveList(data));
        } finally {
            dispatch(setIsInitialized(true));
            dispatch(setInProgress(false));
        }
    };
