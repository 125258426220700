/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import css from './style.module.scss';
import React, { FC } from 'react';
import { cx } from '@epam/uui-core';
import { WithRequiredChildren } from 'types';
import { Accordion } from '@epam/promo';
import { AccordionProps } from '@epam/uui-components';

export const Item: FC<
    WithRequiredChildren & {
        header: React.ReactElement;
        open?: boolean;
        renderAdditionalHeaderItems?: AccordionProps['renderAdditionalItems'];
    }
> = ({ children, header, open, renderAdditionalHeaderItems }) => {
    // component ignores value without onValueChange
    const props = open
        ? {
              value: open,
              onValueChange: () => {},
          }
        : {};
    return (
        <li className={cx(css.treeItem)}>
            <Accordion
                title={header}
                mode="inline"
                renderAdditionalItems={renderAdditionalHeaderItems}
                cx={css.treeItemContent}
                {...props}
            >
                {children}
            </Accordion>
        </li>
    );
};
