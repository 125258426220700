/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import React, { FC, useEffect, useState } from 'react';
import {
    BlockType,
    blockTypes,
    CodeBlockControl,
    Heading,
    ListControl,
} from './Controls/BlockControls';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, $isRangeSelection, $isRootOrShadowRoot } from 'lexical';
import { $findMatchingParent, $getNearestNodeOfType } from '@lexical/utils';
import { $isListNode, ListNode } from '@lexical/list';
import { $isHeadingNode } from '@lexical/rich-text';
import {
    HighlightControl,
    InlineControl,
    InlineStyle,
    isHighlighted,
    LinkControl,
} from './Controls/InlineControls';
import { AlignControl, AlignStyle } from './Controls/AlignControl';
import { getSelectedNode } from '../../utils';
import { $isLinkNode } from '@lexical/link';
import { CodeBlockNode } from '../../nodes/CodeBlock/CodeBlockNode';
import { theme } from '../../theme';
import { useStickyToolbar } from './useStickyToolbar';

export const ToolbarPlugin: FC = () => {
    const [editor] = useLexicalComposerContext();
    const [blockType, setBlockType] = useState<BlockType>(BlockType.paragraph);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isHighlight, setIsHighlight] = useState(false);
    const [isLink, setIsLink] = useState(false);

    const toolbarRef = useStickyToolbar();

    useEffect(() => {
        return editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    const anchorNode = getSelectedNode(selection);
                    const parentNode = anchorNode.getParent();
                    let element =
                        anchorNode.getKey() === 'root'
                            ? anchorNode
                            : $findMatchingParent(anchorNode, (e) => {
                                  const parent = e.getParent();
                                  return (
                                      parent !== null &&
                                      $isRootOrShadowRoot(parent)
                                  );
                              });

                    if (element === null) {
                        element = anchorNode.getTopLevelElementOrThrow();
                    }

                    const elementKey = element.getKey();
                    const elementDOM = editor.getElementByKey(elementKey);

                    setIsBold(selection.hasFormat(InlineStyle.bold));
                    setIsItalic(selection.hasFormat(InlineStyle.italic));
                    setIsUnderline(selection.hasFormat(InlineStyle.underline));
                    setIsHighlight(isHighlighted(selection));
                    setIsLink(
                        $isLinkNode(anchorNode) || $isLinkNode(parentNode)
                    );

                    if (elementDOM !== null) {
                        if ($isListNode(element)) {
                            const parentList = $getNearestNodeOfType<ListNode>(
                                anchorNode,
                                ListNode
                            );
                            const type = parentList
                                ? parentList.getListType()
                                : element.getListType();
                            if (type === 'number') {
                                setBlockType(BlockType.number);
                            } else if (type === 'bullet') {
                                setBlockType(BlockType.bullet);
                            }
                        } else {
                            const type = $isHeadingNode(element)
                                ? element.getTag()
                                : element.getType();
                            if (blockTypes.includes(type as BlockType)) {
                                setBlockType(type as BlockType);
                            }
                        }
                    }
                }
            });
        });
    }, [editor]);

    return (
        <div className={theme.toolbar.root} ref={toolbarRef}>
            <Heading activeBlockType={blockType} editor={editor} />
            <InlineControl
                isActive={isBold}
                style={InlineStyle.bold}
                editor={editor}
            />
            <InlineControl
                isActive={isItalic}
                style={InlineStyle.italic}
                editor={editor}
            />
            <InlineControl
                isActive={isUnderline}
                style={InlineStyle.underline}
                editor={editor}
            />
            <HighlightControl isActive={isHighlight} editor={editor} />
            <AlignControl style={AlignStyle.left} editor={editor} />
            <AlignControl style={AlignStyle.center} editor={editor} />
            <AlignControl style={AlignStyle.right} editor={editor} />
            <ListControl
                style={BlockType.bullet}
                activeBlockType={blockType}
                editor={editor}
            />
            <ListControl
                style={BlockType.number}
                activeBlockType={blockType}
                editor={editor}
            />
            <CodeBlockControl
                editor={editor}
                isActive={blockType === CodeBlockNode.getType()}
            />
            <LinkControl editor={editor} isActive={isLink} />
        </div>
    );
};
