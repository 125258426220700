/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createSlice, createSelector } from '@reduxjs/toolkit';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
import { INITIAL_STATE } from './constants';
import { StatusTypes } from 'constants/statuses';
/**
 * Utils
 */
import { createQueryUrl, pick, templateString } from 'utils';
import { sortActivitiesById, sortActivitiesIdByStatus } from './utils';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Types
 */
import { IInitialState, TSortedActivities, TActivitiesById } from './types';
import {
    TPerson,
    TGetState,
    TSelector,
    TActivity,
    AppDispatch,
    TSelectorWithProps,
    TAssessment,
} from 'types';

/**
 * Reducer
 */
const { reducer, actions } = createSlice({
    name: 'assessment',
    initialState: INITIAL_STATE,
    reducers: {
        saveAssessment: (
            state,
            {
                payload: {
                    id,
                    name,
                    type,
                    status,
                    start_date,
                    end_date,
                    updated_at,
                    issue_filter,
                    activities_in_targets,
                },
            }
        ) => {
            state.id = id;
            state.type = type;
            state.name = name;
            state.status = status;
            state.endDate = end_date;
            state.updatedAt = updated_at;
            state.startDate = start_date;
            state.issueFilter = issue_filter;
            state.activitiesInTargets = activities_in_targets;

            state.isInitialized = true;
        },
        saveActivities: (state, { payload: { activities } }) => {
            state.activitiesById = sortActivitiesById(activities);
            state.activitiesIdByStatus = sortActivitiesIdByStatus(activities);
        },
        saveActivitiesIdByStatus: (state, { payload }) => {
            state.activitiesIdByStatus = payload;
        },
        updateActivityAssignee: (state, { payload: { id, assignees } }) => {
            state.activitiesById[id].assignees = assignees;
        },
        completeAssessment: (state) => {
            state.status = StatusTypes.completed;
        },
        setInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        resetData: () => INITIAL_STATE,
        saveAssessmentStatus: (state, { payload }) => {
            state.status = payload;
        },
    },
});

export default reducer;
/**
 * Selectors
 */
export const selectAssessment: TSelector<IInitialState> = ({ assessment }) =>
    assessment;
export const selectActivitiesIdByStatus: TSelector<TSortedActivities> = ({
    assessment: { activitiesIdByStatus },
}) => activitiesIdByStatus;

export const selectActivities: TSelector<TActivitiesById> = ({
    assessment: { activitiesById },
}) => activitiesById;
const selectActivityId: TSelectorWithProps<string, string> = (state, id) => id;

export const selectActivity = createSelector(
    [selectActivities, selectActivityId],
    (activitiesById, id): TActivity => activitiesById[id] || {}
);
export const selectActivityAssessments = createSelector(
    selectActivity,
    ({ assignees }) => assignees
);

export const selectInProgress: TSelector<boolean> = ({
    assessment: { inProgress },
}) => inProgress;

export const selectIsInitialized: TSelector<boolean> = ({
    assessment: { isInitialized },
}) => isInitialized;
/**
 * Actions
 */
export const {
    resetData,
    setInProgress,
    saveAssessment,
    saveActivities,
    completeAssessment,
    updateActivityAssignee,
    saveActivitiesIdByStatus,
} = actions;
/**
 * Dispatchers
 */
const getActivities =
    (assessmentId: string, targetIds?: string[]) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = templateString(ENDPOINTS.assessmentActivities, {
            assessmentId,
        });

        const queryUrl = createQueryUrl(
            url,
            {
                targetIds,
            },
            { arrayFormat: 'repeat' }
        );

        const response = await api.request<TActivity[]>(Methods.get, {
            url: queryUrl,
        });

        dispatch(
            saveActivities({
                activities: response,
            })
        );
    };

const getAssessment =
    (id: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = templateString(ENDPOINTS.singleAssessment, { id });

        const response = await api.request(Methods.get, { url });

        dispatch(saveAssessment(response));
    };

export const handleGetActivities =
    (assessmentId: string, targetIds: string[]) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setInProgress(true));

        await dispatch(getActivities(assessmentId, targetIds));

        dispatch(setInProgress(false));
    };

export const handleGetAssessmentWithActivities =
    (assessmentId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setInProgress(true));

        await dispatch(getAssessment(assessmentId));
        await dispatch(getActivities(assessmentId));

        dispatch(setInProgress(false));
    };

export const handleGetAssessment =
    (assessmentId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setInProgress(true));

        await dispatch(getAssessment(assessmentId));

        dispatch(setInProgress(false));
    };

export const handleChangeActivityAssignee =
    (activityId: string, assigneeList: TPerson[]) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const requestBody = {
            activity_id: activityId,
            person_ids: pick(assigneeList, 'id'),
        };
        const assigneeUrl = templateString(ENDPOINTS.assignees, {
            id: activityId,
        });

        const { assignees: receivedAssignees } = await api.request<
            {
                assignees: TPerson[];
            },
            typeof requestBody
        >(Methods.post, {
            url: assigneeUrl,
            data: requestBody,
        });

        dispatch(
            updateActivityAssignee({
                id: activityId,
                assignees: receivedAssignees,
            })
        );
    };

export const handleCompleteAssessment =
    () =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const { id } = getState().assessment;

        if (!id) return;

        const url = templateString(ENDPOINTS.assessmentStatus, { id });

        await api.request(Methods.post, {
            url,
            data: { status: StatusTypes.completed },
        });

        dispatch(completeAssessment());
    };

export const hasNonResolvedFindings = async (
    assessmentId: string
): Promise<boolean> => {
    const url = templateString(ENDPOINTS.assessmentHasNonResolvedFindings, {
        id: assessmentId,
    });
    const response = await api.request<{ exists: boolean }>(Methods.get, {
        url,
    });
    return response.exists;
};

export const handleRequestAssessmentRetest = async (assessmentId: string) => {
    const url = templateString(ENDPOINTS.requestAssessmentRetest, {
        id: assessmentId,
    });
    await api.request(Methods.post, {
        url,
    });
};

export const handleRetestAssessment =
    (assessmentId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = templateString(ENDPOINTS.assessmentStatus, {
            id: assessmentId,
        });
        const assessment = await api.request<
            TAssessment,
            { status: StatusTypes }
        >(Methods.post, {
            url,
            data: { status: StatusTypes.active },
        });
        dispatch(actions.saveAssessmentStatus(assessment.status));
    };
