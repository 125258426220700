export enum Highlighting {
    AttributeName = 'data-background',
    AttributeValue = 'BACKGROUND_HIGHLIGHTS_YELLOW',
    Color = 'rgb(255, 253, 62)',
}

export enum Alignment {
    AttributeName = 'data-alignment',
}

// Lexical adds redundant tags for some text formats, such as combining <strong> with <b> and <em> with <i>, for compatibility with various editors.
// During export, we transform the `backgroundColor` style of highlighted nodes to a data attribute to preserve highlighting, which can later be restored.
// However, if the highlighted node also has one of the mentioned formats, it gets wrapped by the superclass method before we can perform the transformation.
// As a result, the original node with the style cannot be reached, leading to the loss of highlighting.
// To handle this, we need to go through the wrappers in the DOM hierarchy until we reach the original node.
// For reference, see: https://github.com/facebook/lexical/blob/a02164ba480d02aff1d1bb22006df1773393419b/packages/lexical/src/nodes/LexicalTextNode.ts#L605
export const EXTRA_WRAPPERS = ['b', 'i', 's', 'u'] as const;
export type ExtraTag = (typeof EXTRA_WRAPPERS)[number];
