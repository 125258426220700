/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useMemo } from 'react';
/**
 * Types
 */
import { THandlers } from '../types';
import { TProjectFindingActionLog, WithDataTestId } from 'types';
/**
 * Utils
 */
import { useHref } from 'components/RecentActions/RecentAction/DefaultRecentAction';
import { blocksWithTestId } from 'components/RecentActions/RecentAction/utils';
import { getFindingName } from 'components/FindingInformation/utils';
/**
 * Expo
 */

const { RecentActionContent, UserBlock, TextBlock, LinkBlock } =
    blocksWithTestId('findingMarkedAsIssue');

export const FindingMarkedAsIssue: React.FC<
    TProjectFindingActionLog & THandlers & WithDataTestId
> = ({
    getFindingHref,
    author: { name },
    details: {
        current_state: { id, summary, composite_id },
    },
}) => {
    const href = useHref({ getHref: () => getFindingHref(id) });
    const findingName = useMemo(
        () => getFindingName(composite_id, summary),
        [composite_id, summary]
    );
    return (
        <RecentActionContent>
            <UserBlock text={name} />
            <TextBlock text="marked finding" />
            {href ? (
                <LinkBlock text={findingName} href={href} />
            ) : (
                <TextBlock text={findingName} />
            )}
            <TextBlock text="as issue" />
        </RecentActionContent>
    );
};
