/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Types
 */
import { TAssessment } from 'types';
/**
 * Utils
 */
import { createQueryUrl } from 'utils';
/**
 * Expo
 */
export const getOptions = async (projectId: string): Promise<TAssessment[]> => {
    const url = createQueryUrl(ENDPOINTS.assessmentsForReports, {
        projectId,
    });

    return api.request<TAssessment[]>(Methods.get, { url });
};
