/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Text, Panel } from 'components/common';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { TTargetProps } from './types';
/**
 * Expo
 */
export const Target: React.FC<TTargetProps> = ({ targetName }) => (
    <Panel cx={css.panel} dataTestId="activity/target">
        <Text
            fontSize="24"
            lineHeight="30"
            color="gray80"
            fontWeight="400"
            dataTestId="activity/target/title"
        >
            Target
        </Text>
        <Text
            fontSize="16"
            lineHeight="24"
            color="gray80"
            dataTestId="activity/target/name"
        >
            {targetName}
        </Text>
    </Panel>
);
