/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
/**
 * Components
 */
import { PrivateRoute } from '../../../PrivateRoute';
/**
 * Constants
 */
import { RoleTypes } from 'constants/roles';
/**
 * Pages
 */
import Assessments from 'pages/Assessments';
import { SingleActivity } from 'pages/SingleActivity';
import { SingleAssessment } from 'pages/SingleAssessment';

const AssessmentCreation = lazy(() => import('pages/AssessmentCreation'));
const AssessmentEditing = lazy(() => import('pages/AssessmentEditing'));
/**
 * Expo
 */
export const AssessmentsRoute: React.FC = () => (
    <Suspense fallback={null}>
        <Routes>
            <Route
                path="/"
                element={
                    <PrivateRoute
                        component={Assessments}
                        roles={[
                            RoleTypes.projectRepresentative,
                            RoleTypes.securitySpecialist,
                            RoleTypes.operationsManager,
                        ]}
                    />
                }
            />
            <Route
                path=":assessmentId"
                element={
                    <PrivateRoute
                        component={SingleAssessment}
                        roles={[
                            RoleTypes.projectRepresentative,
                            RoleTypes.securitySpecialist,
                            RoleTypes.operationsManager,
                        ]}
                    />
                }
            />
            <Route
                path=":assessmentId/activity/:activityId"
                element={
                    <PrivateRoute
                        component={SingleActivity}
                        roles={[
                            RoleTypes.projectRepresentative,
                            RoleTypes.securitySpecialist,
                            RoleTypes.operationsManager,
                        ]}
                    />
                }
            />
            <Route
                path=":assessmentId/edit"
                element={
                    <PrivateRoute
                        component={AssessmentEditing}
                        roles={[
                            RoleTypes.projectRepresentative,
                            RoleTypes.operationsManager,
                        ]}
                    />
                }
            />
            <Route
                path="create"
                element={
                    <PrivateRoute
                        component={AssessmentCreation}
                        roles={[RoleTypes.operationsManager]}
                    />
                }
            />
        </Routes>
    </Suspense>
);
