/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useMemo } from 'react';
/**
 * Components
 */
import { cx } from '@epam/uui-core';
import { DropSpot } from '@epam/promo';
import { AttachmentList } from 'components/AttachmentList';
/**
 * Utils
 */
import { getRawProps, getUploadedFiles, showError } from 'utils';
/**
 * Types
 */
import { TUploadCardProps } from './types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Constants
 */
import { FILE_INPUT_EXTENSIONS } from 'constants/file';
/**
 * Expo
 */
export const UploadCard: React.FC<TUploadCardProps> = ({
    value,
    infoText,
    className,
    dataTestId,
    onValueChange,
    fileFormat = FILE_INPUT_EXTENSIONS,
    single,
}) => {
    const removeAttachment = (id: string): void => {
        onValueChange(value.filter((item) => id !== item.id));
    };

    const uploadFile = (files: File[]): void => {
        // the max attached files
        if (value.length + files.length > 15) {
            return;
        }

        if (single && value.length + files.length > 1) {
            showError('You can upload only one file, please try again');
            return;
        }

        const uploadedFiles = getUploadedFiles({ files, fileFormat });

        onValueChange([...value, ...uploadedFiles]);
    };

    const rawProps = useMemo(() => getRawProps({ dataTestId }), [dataTestId]);

    return (
        <div className={css.container} {...rawProps}>
            <DropSpot
                infoText={infoText}
                onUploadFiles={uploadFile}
                accept={fileFormat}
                cx={cx(css.dropSpotContainer, className)}
                single={single}
            />
            {Boolean(value.length) && (
                <AttachmentList
                    list={value}
                    columnsInRow={2}
                    onRemove={removeAttachment}
                />
            )}
        </div>
    );
};
