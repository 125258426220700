/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { redirectStartTime } from 'constants/system';
/**
 * Utils
 */
import { sendDataLayerEvent } from 'utils';
/**
 * Expo
 */
export const trackInitialLoadingTiming = (): void => {
    const performance = window.performance || window.webkitPerformance;

    const {
        connectEnd,
        domComplete,
        redirectEnd,
        responseEnd,
        requestStart,
        connectStart,
        redirectStart,
        responseStart,
        domainLookupEnd,
        domainLookupStart,
    } = performance.getEntriesByType('navigation')[0];

    const timing = {
        page_load_time: domComplete,
        redirection_time: redirectEnd - redirectStart,
        dns_time: domainLookupEnd - domainLookupStart,
        server_connect_time: connectEnd - connectStart,
        page_download_time: responseEnd - responseStart,
        api_calls_time: performance.now() - domComplete,
        server_response_time: responseStart - requestStart,
    };

    sendDataLayerEvent('performance_timing_initial_loading', { timing });
};

export const trackRedirectionTiming = (): void => {
    sendDataLayerEvent('performance_timing_redirect_loading', {
        timing: {
            api_calls_time: Date.now() - window[redirectStartTime],
        },
    });
};
