/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { denormalize, normalize, schema } from 'normalizr';
/**
 * Utils
 */
import { v4 as uuidv4 } from 'uuid';
/**
 * Types
 */
import {
    TCreateMessage,
    TNormalizeSteps,
    TDenormalizeSteps,
    TGetCompletedStepsCount,
} from './types';
/**
 * Expo
 */
const stepsSchema = new schema.Entity('steps');

export const normalizeSteps: TNormalizeSteps = (list) => {
    const {
        result,
        entities: { fields, steps },
    } = normalize(list, [stepsSchema]);

    return { stepsIds: result, fieldsById: fields, stepsById: steps };
};

export const denormalizeStep: TDenormalizeSteps = ({
    stepId,
    stepsById: steps,
}) => denormalize(stepId, stepsSchema, { steps });

export const getCompletedStepsCount: TGetCompletedStepsCount = (steps) =>
    steps.reduce((count, { checked }) => {
        count += Number(checked);

        return count;
    }, 0);

export const createPendingMessage: TCreateMessage = (
    comment,
    attachments,
    { id, name }
) => ({
    comment,
    attachments,
    id: uuidv4(),
    created_by: id,
    external_id: null,
    external_author: null,
    created_at: new Date(),
    person: { id, full_name: name },
});
