/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, memo } from 'react';
/**
 * Types
 */
import { TAssessmentCommonActionLog } from 'types';
import { DefaultRecentAction } from 'components/RecentActions/RecentAction';
/**
 * Expo
 */
export const UpdateAssessment: FC<TAssessmentCommonActionLog> = memo(
    ({
        author: { name },
        details: {
            current_state: { name: assessmentName },
        },
    }) => (
        <DefaultRecentAction
            dataTestId="assessmentUpdated"
            user={name}
            action={`updated assessment ${assessmentName}`}
        />
    )
);
