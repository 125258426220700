/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { forwardRef, useCallback, useMemo } from 'react';
/**
 * Components
 */
import { LinkButton as UuiLinkButton } from '@epam/promo';
/**
 * Utils
 */
import { getRawProps } from 'utils';
/**
 * Types
 */
import { LinkButtonProps } from './types';
import { useLinkClickHandler } from 'react-router-dom';
import { RawButtonHTMLProps } from 'types';
/**
 * Expo
 */
export const LinkButton: React.FC<LinkButtonProps> = forwardRef(
    ({ dataTestId, type = 'button', href, target, onClick, ...props }, ref) => {
        const internalOnClick = useLinkClickHandler(href || '');

        const handleClick = useCallback(
            (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                if (onClick) {
                    onClick(event);
                }

                if (href && target !== '_blank') {
                    internalOnClick(event);
                }
            },
            [onClick, internalOnClick, href, target]
        );

        const rawProps: RawButtonHTMLProps = useMemo(
            () => ({ ...getRawProps({ dataTestId }), type }),
            [dataTestId, type]
        );

        return (
            <UuiLinkButton
                {...props}
                ref={ref}
                href={href}
                target={target}
                rawProps={rawProps}
                onClick={handleClick}
            />
        );
    }
);
