/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { forwardRef, memo, Ref, useMemo } from 'react';
import { cx } from '@epam/uui-core';
/**
 * Components
 */
import { Text } from '@epam/promo';
import { UserAvatarSubicon } from './UserAvatarSubicon';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { UserAvatarProps } from './types';
/**
 * Utils
 */
import { getRawProps } from 'utils';
import {
    getFontSize,
    getFirstLetters,
    getAvatarBackgroundColor,
} from './utils';
/**
 * Expo
 */
export const UserAvatar: React.FC<UserAvatarProps> = memo(
    forwardRef(
        (
            {
                name,
                size = '36',
                className,
                fromTracker = false,
                dataTestId = 'avatar',
            },
            ref: Ref<HTMLDivElement>
        ) => {
            const firstLetters = useMemo(() => {
                return getFirstLetters(name);
            }, [name]);

            const backgroundColor = useMemo(() => {
                return getAvatarBackgroundColor(name);
            }, [name]);

            const fontSize = useMemo(() => {
                return getFontSize(size);
            }, [size]);

            const rawProps = useMemo(
                () => getRawProps({ dataTestId }),
                [dataTestId]
            );

            return (
                <div
                    {...rawProps}
                    ref={ref}
                    className={cx(css.avatarContainer, className)}
                    style={{
                        height: `${size}px`,
                        width: `${size}px`,
                        minWidth: `${size}px`,
                        backgroundColor,
                    }}
                >
                    <UserAvatarSubicon fromTracker={fromTracker} />
                    <Text
                        color="gray5"
                        fontSize={fontSize}
                        cx={cx(css.avatarText, size === '12' && css.smallText)}
                    >
                        {firstLetters}
                    </Text>
                </div>
            );
        }
    )
);
