/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import css from './style.module.scss';
import { Accordion, FlexSpacer } from '@epam/promo';
import { FlexCell, FlexRow, Text } from 'components/common';
import React, { memo, useCallback, useState } from 'react';
import { WithDataTestId } from 'types';
import { getRawProps } from 'utils';
import { AddItemIcon } from './components/AddItemIcon';
import { EmptyItem } from './components/EmptyItem';
import { ExpandableIcon } from './components/ExpandableIcon';
import { ItemEditor } from './components/ItemEditor';
import { createItemForViewAndEdit } from './components/ItemEditor/helpers';

import { TExpandableTreeProps } from './types';

export const ExpandableTree: React.FC<TExpandableTreeProps & WithDataTestId> =
    memo(
        ({
            title,
            emptyListMessage,
            subItems,
            type,
            openByDefault = false,
            dataTestId,
        }) => {
            const [expanded, setExpanded] = useState(openByDefault);
            const [editorOpened, setEditorOpened] = useState(false);

            const renderTitle = useCallback(
                () => (
                    <FlexCell grow={1}>
                        <FlexRow columnGap="12" cx="accordionHeader">
                            <ExpandableIcon
                                dataTestId={dataTestId}
                                isOpen={expanded}
                            />
                            <Text
                                dataTestId={`${dataTestId}/title`}
                                cx="text-bold"
                            >
                                {title}
                            </Text>
                            <FlexSpacer />
                            <AddItemIcon
                                dataTestId={dataTestId}
                                onClick={() => {
                                    setExpanded(true);
                                    setEditorOpened(true);
                                }}
                                disabled={editorOpened}
                            />
                        </FlexRow>
                    </FlexCell>
                ),
                [expanded, title, dataTestId, editorOpened]
            );

            const shouldShowEmptyItem = !subItems.length && !editorOpened;

            const subItemsJsx = subItems.map((item) => {
                return createItemForViewAndEdit(type, item);
            });

            return (
                <Accordion
                    mode="inline"
                    rawProps={getRawProps({ dataTestId: `${dataTestId}` })}
                    renderTitle={renderTitle}
                    dropdownIcon={null}
                    value={expanded}
                    onValueChange={setExpanded}
                    cx={css.accordion}
                >
                    <ul className={css.tree}>
                        <>
                            {editorOpened && (
                                <ItemEditor
                                    type={type}
                                    onClose={() => setEditorOpened(false)}
                                />
                            )}
                            {subItemsJsx}
                            {shouldShowEmptyItem && (
                                <EmptyItem
                                    message={emptyListMessage}
                                    dataTestId={dataTestId}
                                />
                            )}
                        </>
                    </ul>
                </Accordion>
            );
        }
    );
