/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, forwardRef, useMemo } from 'react';
/**
 * Types
 */
import { TextProps as TextMods } from '@epam/promo';
import { TTextBlockProps } from './types';
/**
 * Utils
 */
import { getRawProps } from 'utils';
import { applyTextProps } from './utils';
import { cx, withMods } from '@epam/uui-core';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
const TitleBlock: FC<TTextBlockProps> = forwardRef(
    ({ dataTestId, level, onClick, ...props }, ref) => {
        const rawProps = useMemo(
            () => getRawProps({ dataTestId }),
            [dataTestId]
        );

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const Component = `h${level}` as any;

        return (
            <Component
                ref={ref}
                onClick={onClick}
                className={cx(props.cx, css.container)}
                {...rawProps}
            >
                {props.children}
            </Component>
        );
    }
);

export const Title = withMods<TTextBlockProps, TextMods>(
    TitleBlock,
    applyTextProps
);
