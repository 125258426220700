/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { useMemo } from 'react';
import { Button } from '@epam/uui-components';
import { getRawProps, none } from 'utils';
import { TabButtonProps } from './types';
import { RawButtonHTMLProps } from 'types';

export const TabButton: React.FC<TabButtonProps> = ({
    caption,
    dataTestId,
    isDisabled,
    cx,
}) => {
    const rawProps: RawButtonHTMLProps = useMemo(
        () => ({
            ...getRawProps({ dataTestId }),
            type: 'button',
        }),
        [dataTestId]
    );

    return (
        <Button
            onClick={none}
            caption={caption}
            rawProps={rawProps}
            isDisabled={isDisabled}
            cx={cx}
        />
    );
};
