/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useCallback, useEffect, useRef } from 'react';
import {
    FieldValues,
    Path,
    useForm,
    UseFormProps,
    UseFormReturn,
} from 'react-hook-form';
import {
    useUnsavedChangesDialog,
    useOnMount,
    useRouteMatchParams,
    useUnMount,
} from 'hooks';
import { PAGES } from 'constants/pages';
import { isEmpty, NotificationTypes, showNotification } from 'utils';
import SessionStorage from 'services/sessionStorage';
import { useUuiContext } from '@epam/uui-core';
import { TFunction, TFunctionWithArgs } from 'types';

export interface UseAppFormProps<TFieldValues extends FieldValues>
    extends UseFormProps<TFieldValues> {
    id?: string;
    shouldSaveDraftInStorage?: boolean;
    shouldBlockNavigationWhenUnsaved?: boolean;
}

export const useAppForm = <TFieldValues extends FieldValues>(
    props?: UseAppFormProps<TFieldValues>
): UseFormReturn<TFieldValues> => {
    const form = useForm(props);
    const { uuiNotifications } = useUuiContext();
    const isSubmitSuccessfully = useRef<boolean>(false);
    const notificationId = useRef<number>();
    const { projectId } = useRouteMatchParams(PAGES.projectWithEntity);
    const {
        id: formId,
        shouldSaveDraftInStorage = true,
        shouldBlockNavigationWhenUnsaved = false,
    } = props || {};

    const formIdValue =
        formId && (projectId ? `${formId}_${projectId}` : formId);

    const {
        getValues,
        formState: { dirtyFields, touchedFields, isDirty: hasUnsavedChanges },
    } = form;

    const handleClose = (onClose?: TFunction) => {
        if (formIdValue && shouldSaveDraftInStorage) {
            SessionStorage.remove(formIdValue);
        }
        onClose?.();
    };

    const shouldBlockNavigation =
        shouldBlockNavigationWhenUnsaved && hasUnsavedChanges;

    useUnsavedChangesDialog(shouldBlockNavigation);

    const extendedForm = {
        ...form,
        handleSubmit: (onValid: TFunctionWithArgs<TFieldValues>) =>
            form.handleSubmit((value) => {
                isSubmitSuccessfully.current = true;
                onValid(value);
                handleClose();
            }),
    };

    const setNotificationId = (id: number) => {
        notificationId.current = id;
    };

    const filteredTouchedFields = useCallback(() => {
        const fieldKeys = <Path<TFieldValues>[]>Object.keys(touchedFields);

        return fieldKeys.filter(
            (fieldName) =>
                touchedFields[fieldName] &&
                dirtyFields[fieldName] &&
                !isEmpty(getValues(fieldName))
        );
    }, [dirtyFields, getValues, touchedFields]);

    const getDraftForm = useCallback(
        (fields: Path<TFieldValues>[]) =>
            fields.reduce((accumulator, fieldName) => {
                accumulator[fieldName] = getValues(fieldName);

                return accumulator;
            }, {} as TFieldValues),
        [getValues]
    );

    const resetForm = (formValues: TFieldValues) =>
        Object.entries(formValues).forEach(([fieldName, fieldValue]) => {
            form.setValue(fieldName as Path<TFieldValues>, fieldValue, {
                shouldTouch: true,
                shouldDirty: true,
            });
        });

    const handleShowNotification = (data: string) => {
        showNotification({
            text: 'Draft is found. Restore it and continue?',
            type: NotificationTypes.hint,
            actions: [
                {
                    name: 'APPLY',
                    action: ({ onClose }) => {
                        resetForm(JSON.parse(data));

                        handleClose(onClose);
                    },
                },
                {
                    name: 'DENY',
                    action: ({ onClose }) => {
                        handleClose(onClose);
                    },
                },
            ],
            config: {
                duration: 'forever',
                position: 'bot-left',
            },
            setNotificationId,
        });
    };

    useOnMount(() => {
        let formData;

        if (formIdValue && shouldSaveDraftInStorage) {
            formData = SessionStorage.get(formIdValue) || '';

            if (formData.length) {
                handleShowNotification(formData);
            }
        }
    });

    useUnMount(() => {
        if (notificationId.current) {
            uuiNotifications.remove(notificationId.current);
        }
    });

    useEffect(
        () => () => {
            const filteredFields = filteredTouchedFields();

            if (
                formIdValue &&
                filteredFields.length &&
                !isSubmitSuccessfully.current &&
                shouldSaveDraftInStorage
            ) {
                const draftForm = getDraftForm(filteredFields);

                SessionStorage.set(formIdValue, JSON.stringify(draftForm));
            }
        },
        [
            touchedFields,
            dirtyFields,
            getValues,
            formIdValue,
            filteredTouchedFields,
            getDraftForm,
            shouldSaveDraftInStorage,
        ]
    );

    return extendedForm;
};
