/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { createSlice } from '@reduxjs/toolkit';
import { TECHNOLOGIES_INITIAL_STATE } from './constants';

export const {
    reducer: technologiesReducer,
    actions: {
        saveTechnologies,
        setTechnologiesLoadingInProgress,
        resetTechnologies,
        addTechnology,
        updateTechnology,
    },
} = createSlice({
    name: 'technologies',
    initialState: TECHNOLOGIES_INITIAL_STATE,
    reducers: {
        saveTechnologies: (state, { payload }) => {
            state.list = payload;
            state.isInitialized = true;
        },
        setTechnologiesLoadingInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        resetTechnologies: () => TECHNOLOGIES_INITIAL_STATE,
        addTechnology: (state, { payload }) => {
            state.list = [...state.list, payload];
        },
        updateTechnology: (state, { payload }) => {
            const index = state.list.findIndex(
                (technology) => technology.id === payload.id
            );
            if (index !== -1) {
                state.list[index] = payload;
            }
        },
    },
});
