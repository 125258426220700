/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useRef } from 'react';
import { cx } from '@epam/uui-core';
/**
 * Hooks
 */
import { useHover } from 'hooks';
/**
 * Components
 */
import { Text } from '@epam/promo';
/**
 * Types
 */
import { TListItem } from './types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const ListItem = <TValue,>({
    id,
    name,
    onClick,
    dataTestId,
}: TListItem<TValue>): JSX.Element => {
    const ref = useRef<HTMLDivElement>(null);
    const isHovered = useHover(ref);

    const handleClick = useCallback(() => {
        onClick(id);
    }, [id, onClick]);

    return (
        <div
            ref={ref}
            id={dataTestId}
            className={cx(css.container, isHovered && css.hovered)}
        >
            <Text onClick={handleClick}>{name}</Text>
        </div>
    );
};
