/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, memo } from 'react';
/**
 * Constants
 */
import { INTEGRATION_CAPTION } from 'constants/project';
/**
 * Types
 */
import { TProjectIntegrationActionLog, WithDataTestId } from 'types';
import { DefaultRecentAction } from 'components/RecentActions/RecentAction/DefaultRecentAction';
/**
 * Expo
 */
export const UpdateTrackerIntegration: FC<
    TProjectIntegrationActionLog & WithDataTestId
> = memo(
    ({
        author: { name },
        details: {
            previous_state: { integration_type },
        },
    }) => (
        <DefaultRecentAction
            dataTestId="updateTrackerIntegration"
            user={name}
            action={`updated integration with ${INTEGRATION_CAPTION[integration_type]}`}
        />
    )
);
