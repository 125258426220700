/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { forwardRef } from 'react';
/**
 * Components
 */
import { UserAvatar } from '../UserAvatar';
import { FlexRow, Panel, Text } from 'components/common';
/**
 * Types
 */
import { UserProps } from './types';
/**
 * Expo
 */
export const User: React.FC<UserProps> = forwardRef(
    (
        {
            name,
            email,
            fromTracker,
            dataTestId,
            containerCx,
            textClassName,
            textSize = '16',
            avatarSize = '36',
            lineHeight = '24',
        },
        ref
    ) => (
        <FlexRow
            ref={ref}
            size="24"
            cx={containerCx}
            dataTestId={dataTestId}
            columnGap="6"
        >
            <UserAvatar
                name={name}
                size={avatarSize}
                fromTracker={fromTracker}
            />
            <Panel>
                <Text
                    cx={textClassName}
                    fontSize={textSize}
                    lineHeight={lineHeight}
                    size="18"
                    dataTestId="user/name"
                >
                    {name}
                </Text>
                {email && (
                    <Text
                        cx={textClassName}
                        fontSize={textSize}
                        lineHeight={lineHeight}
                        size="18"
                    >
                        {email}
                    </Text>
                )}
            </Panel>
        </FlexRow>
    )
);
