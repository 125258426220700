/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Modal as ModalComponent } from 'components/common';
import { ModalBody } from './components/ModalBody';
/**
 * Types
 */
import { IModal } from '@epam/uui-core';
/**
 * Constants
 */
import { ModalSizes } from 'constants/modals';
import { WithDataTestId } from 'types';
/**
 * Expo
 */
export const TermsAndConditionsModal: React.FC<
    IModal<string> & WithDataTestId
> = ({ dataTestId, ...props }) => (
    <ModalComponent
        {...props}
        allowCloseOnCross={false}
        title="Terms and Conditions"
        width={ModalSizes.large}
        withoutFooter
        dataTestId="termsAndConditions"
        renderBody={({ abort }) => (
            <ModalBody onClose={abort} dataTestId="termsAndConditions" />
        )}
    />
);
