/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
import { cx } from '@epam/uui-core';
/**
 * Components
 */
import { PageHeader } from './PageHeader';
import { Blocker, FormPanel, Panel } from 'components/common';
/**
 * Types
 */
import { PageLoadHandlerProps } from './types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const PageLoadHandler: React.FC<PageLoadHandlerProps> = ({
    inProgress = false,
    isInitialized,
    renderHeader,
    renderBackTo,
    children,
}) => {
    const isFirstLoading = isInitialized === false;

    return (
        <>
            <Blocker isFixed isEnabled={inProgress} />
            <PageHeader
                renderBackTo={renderBackTo}
                renderHeader={renderHeader}
            />
            <FormPanel>
                <Panel cx={css.container}>
                    <Panel
                        background={isFirstLoading ? 'white' : undefined}
                        cx={css.contentContainer}
                    >
                        <Panel
                            cx={cx(
                                isFirstLoading && css.hiddenContent,
                                css.content
                            )}
                        >
                            {children}
                        </Panel>
                    </Panel>
                </Panel>
            </FormPanel>
        </>
    );
};
