/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Text } from 'components/common';
import { List } from './List';
/**
 * Assets
 */
import terms from './terms.json';
import css from './style.module.scss';
/**
 * Utils
 */
import { cx } from '@epam/uui-core';
/**
 * Expo
 */
export const TermsAndConditions: React.FC = () => (
    <>
        <Text fontWeight="400">{terms.header}</Text>
        <Text fontWeight="400">{terms.disclaimer}</Text>
        <Text>{terms.text}</Text>
        <Text cx={css.title} fontWeight="400">
            {terms.partA.header}
        </Text>

        {terms.partA.list.map(({ header, text, list }, index) => (
            <div key={`partA_${index + 1}`} className={css.textBlock}>
                <Text fontWeight="400" cx={css.inlineText}>
                    {index + 1}. {header}{' '}
                </Text>
                <Text cx={css.inlineText}>{text}</Text>
                {list && <List list={list} />}
            </div>
        ))}

        <Text cx={css.title} fontWeight="400">
            {terms.partB.header}
        </Text>
        <Text>
            {terms.partB.disclaimer[0]}
            <a
                href={terms.partB.disclaimer[1]}
                target="_blank"
                rel="noreferrer"
            >
                {terms.partB.disclaimer[1]}
            </a>
            {terms.partB.disclaimer[2]}
        </Text>
        {terms.partB.list.map(({ header, text, list }, index) => (
            <div key={`partB_${index + 1}`} className={css.textBlock}>
                <Text fontWeight="400" cx={css.inlineText}>
                    {header}{' '}
                </Text>
                {text.map((textElement, textIndex) => (
                    <Text
                        key={`textElement_${textIndex + 1}`}
                        cx={cx(
                            textIndex === 0 && css.inlineText,
                            css.removeBottomPadding
                        )}
                    >
                        {textElement}
                    </Text>
                ))}
                {list && <List list={list} />}
            </div>
        ))}
        <Text fontWeight="400" cx={css.removePadding}>
            {terms.contacts[0]}
        </Text>
        <Text fontWeight="400" cx={css.removePadding}>
            {terms.contacts[1]}
        </Text>
        <Text fontWeight="400" cx={css.removePadding}>
            {terms.contacts[2]}
        </Text>
        <Text fontWeight="400" cx={css.removePadding}>
            {terms.contacts[3]}
        </Text>
        <Text fontWeight="400" cx={css.removePadding}>
            {terms.contacts[4][0]}
            <a href={`mailto:${terms.contacts[4][1]}`}>
                {terms.contacts[4][1]}
            </a>
        </Text>
    </>
);
