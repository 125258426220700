/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { RootState } from 'types';

export const createActionContext =
    (context: string) =>
    (actionType: string): string =>
        `${context}/${actionType}`;

export type ReducersWithIsInitialized =
    | 'actionLog'
    | 'activities'
    | 'assessment'
    | 'assessments'
    | 'targets'
    | 'dashboard'
    | 'findings'
    | 'organizations'
    | 'persons'
    | 'policies'
    | 'singlePolicy'
    | 'project'
    | 'projectAttachments'
    | 'projects'
    | 'reports'
    | 'requests'
    | 'singleActivity'
    | 'singleFinding'
    | 'singleReport'
    | 'singleRequest'
    | 'singleTask'
    | 'tasks'
    | 'user'
    | 'notifications'
    | 'trackerIssues';

export const makeIsInitializedSelector =
    (...reducerNames: ReducersWithIsInitialized[]) =>
    (state: RootState): boolean =>
        reducerNames.reduce(
            (isInitialized: boolean, reducerName: ReducersWithIsInitialized) =>
                isInitialized && state[reducerName].isInitialized,
            true
        );
