/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { ItemType } from '../../../../constants';

export const itemTypeToEditorTitleMap: Record<ItemType, string> = {
    [ItemType.element]: 'element',
    [ItemType.interaction]: 'interaction',
    [ItemType.trustBoundary]: 'trust boundary',
    [ItemType.asset]: 'asset',
    [ItemType.attacker]: 'attacker',
    [ItemType.technology]: 'technology',
};
