/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Badge } from 'components/common';
/**
 * Types
 */
import { SeverityProps } from './types';
/**
 * Constants
 */
import {
    SeverityTypes,
    SEVERITY_CAPTIONS,
    SEVERITY_COLOR_NAMES,
} from 'constants/severity';
/**
 * Expo
 */
export const Severity: React.FC<SeverityProps> = ({ type, ...props }) => {
    // This need because we can receive status as a label instead of id
    // Therefore we don't know what exactly status we have
    // This can be happen Jira tasks have their own values
    if (!Object.values(SeverityTypes).includes(type)) {
        return (
            <Badge
                {...props}
                fill="solid"
                caption={type}
                color={SEVERITY_COLOR_NAMES.LOW}
            />
        );
    }

    return (
        <Badge
            {...props}
            fill="solid"
            color={SEVERITY_COLOR_NAMES[type]}
            caption={SEVERITY_CAPTIONS[type]}
        />
    );
};
