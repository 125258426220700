/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useMemo, useState } from 'react';
/**
 * Hooks
 */
import { useRedirect, useRole } from 'hooks';
import { useSelector } from 'react-redux';
/**
 * Components
 */
import { List } from './List';
import { FlexRow, FlexSpacer } from '@epam/promo';
import { Panel, Button, Text } from 'components/common';
/**
 * Store
 */
import { selectPolicy } from 'store/project';
/**
 * Assets
 */
import css from './style.module.scss';
import generalCss from '../../style.module.scss';
import { ReactComponent as PlusIcon } from '@epam/assets/icons/action-add-outline.svg';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
/**
 * Expo
 */
export const Policies: React.FC = () => {
    const [showMore, setShowMore] = useState(false);

    const { isProjectRepresentative } = useRole();
    const redirect = useRedirect();

    const list = useSelector(selectPolicy);

    const handleClickShowMore = useCallback(() => {
        setShowMore((origin) => !origin);
    }, []);

    const handleAddPolicy = () => {
        redirect(PAGES.addProjectPolicy);
    };

    const paginatedList = useMemo(() => {
        if (showMore) return list;

        return list.slice(0, 3);
    }, [list, showMore]);

    return (
        <Panel
            background="white"
            cx={generalCss.panel}
            dataTestId="projectInfo/history"
        >
            <FlexRow size="30" cx={generalCss.panelHeader}>
                <Text
                    size="30"
                    fontSize="24"
                    lineHeight="30"
                    fontWeight="400"
                    dataTestId="projectInfo/policy/title"
                >
                    Policies
                </Text>
                {isProjectRepresentative && (
                    <>
                        <FlexSpacer />
                        <Button
                            icon={PlusIcon}
                            iconPosition="left"
                            caption="Add new policy"
                            fill="none"
                            dataTestId="projectInfo/add-policy"
                            onClick={handleAddPolicy}
                        />
                    </>
                )}
            </FlexRow>
            <List list={paginatedList} />
            {list.length <= 3 ? null : (
                <div className={css.showMoreButton}>
                    <FlexRow vPadding="24">
                        <Button
                            fill="outline"
                            onClick={handleClickShowMore}
                            dataTestId="projectInfo/policy/buttons/show"
                            caption={showMore ? 'Show less' : 'Show more'}
                        />
                    </FlexRow>
                </div>
            )}
        </Panel>
    );
};
