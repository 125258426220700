/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { Text } from 'components/common';
import css from './style.module.scss';
import { WithRequiredChildren } from 'types';

export interface LabelProps extends WithRequiredChildren {
    dataTestId: string;
}

export const Label: React.FC<LabelProps> = ({ children, dataTestId }) => {
    return (
        <Text
            fontSize="14"
            fontWeight="400"
            cx={css.readonlyRowLabel}
            dataTestId={`${dataTestId}/label`}
        >
            {children}
        </Text>
    );
};
