/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, useContext, useRef } from 'react';
/**
 * Components
 */
import { FlexRow } from '@epam/promo';
import { ToolbarButton } from '../../ToolbarButton';
/**
 * Context
 */
import { AttachmentsContext } from '../../../context';
/**
 * Utils
 */
import { getUploadedFiles, NotificationTypes, showNotification } from 'utils';
/**
 * Assets
 */
import css from './style.module.scss';
import { ReactComponent as attachmentIcon } from '@epam/assets/icons/common/file-attachment-18.svg';
/**
 * Types
 */
import { TAttachmentsControlProps, TFileInputEvent } from './types';
/**
 * Constants
 */
import { FILE_INPUT_EXTENSIONS } from 'constants/file';
/**
 * Expo
 */
export const AttachmentsControl: FC<TAttachmentsControlProps> = ({ limit }) => {
    const { onChange, pendingAttachments = [] } =
        useContext(AttachmentsContext);

    const fileInput = useRef<null | HTMLInputElement>(null);

    const handleClick = () => {
        fileInput.current?.click();
    };

    const handleChooseFiles = ({
        currentTarget: { files },
    }: TFileInputEvent) => {
        if (!files) return;

        const attachmentsCount = files.length + pendingAttachments.length;

        if (limit && attachmentsCount > limit) {
            showNotification({
                text: `You can upload ${limit} files at a time`,
                type: NotificationTypes.error,
            });

            return;
        }

        const uploadedFiles = getUploadedFiles({ files: Array.from(files) });

        onChange?.(uploadedFiles);
    };

    return (
        <FlexRow cx="RichEditor-controls">
            <input
                multiple
                type="file"
                ref={fileInput}
                className={css.input}
                onChange={handleChooseFiles}
                accept={FILE_INPUT_EXTENSIONS}
            />
            <ToolbarButton icon={attachmentIcon} onClick={handleClick} />
        </FlexRow>
    );
};
