/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import {
    StartAssessment,
    CreateAssessment,
    UpdateAssessment,
    CompleteAssessment,
} from './components/RecentActions';
/**
 * Constants
 */
import { AssessmentActionLogTypes } from 'constants/actionLog';
/**
 * Types
 */
import { TAssessmentActionLog } from 'types';
/**
 * Expo
 */
export const renderAction = (
    props: TAssessmentActionLog
): JSX.Element | null => {
    switch (props.type) {
        case AssessmentActionLogTypes.createAssessment:
            return <CreateAssessment {...props} />;
        case AssessmentActionLogTypes.updateAssessment:
            return <UpdateAssessment {...props} />;
        case AssessmentActionLogTypes.startAssessment:
            return <StartAssessment {...props} />;
        case AssessmentActionLogTypes.completeAssessment:
            return <CompleteAssessment {...props} />;
        default:
            return null;
    }
};
