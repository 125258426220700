/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
/**
 * Components
 */
import { PrivateRoute } from '../../../PrivateRoute';
/**
 * Constants
 */
import { RoleTypes } from 'constants/roles';
/**
 * Pages
 */

import { Reports } from 'pages/Reports';

const DocxReport = lazy(() => import('pages/DocxReport'));
const SingleReport = lazy(() => import('pages/SingleReport'));
const ConfigurePdfReport = lazy(() => import('pages/ConfigurePdfReport'));
/**
 * Expo
 */
export const ReportsRoute: React.FC = () => (
    <Suspense fallback={null}>
        <Routes>
            <Route
                path="/"
                element={
                    <PrivateRoute
                        component={Reports}
                        roles={[
                            RoleTypes.projectRepresentative,
                            RoleTypes.securitySpecialist,
                        ]}
                    />
                }
            />
            <Route
                path=":reportId"
                element={
                    <PrivateRoute
                        component={SingleReport}
                        roles={[
                            RoleTypes.securitySpecialist,
                            RoleTypes.projectRepresentative,
                        ]}
                    />
                }
            />
            <Route
                path="docx"
                element={
                    <PrivateRoute
                        component={DocxReport}
                        roles={[RoleTypes.securitySpecialist]}
                    />
                }
            />
            <Route
                path="pdf"
                element={
                    <PrivateRoute
                        component={ConfigurePdfReport}
                        roles={[RoleTypes.securitySpecialist]}
                    />
                }
            />
        </Routes>
    </Suspense>
);
