/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { EmptyView } from 'components/EmptyView';
/**
 * Expo
 */
export const renderNoAssessments = (
    hasSearch: boolean,
    isOperationsManager: boolean
): JSX.Element => {
    if (hasSearch) {
        return (
            <EmptyView
                alt="Empty view"
                src="/assets/images/no_users.svg"
                title="No assessments found"
                text="Correct your mistakes or try another phrase"
            />
        );
    }

    return (
        <EmptyView
            alt="Empty view"
            src="/assets/images/noContent.svg"
            title="No assessments"
            text={
                isOperationsManager
                    ? 'Add assessment to display it here'
                    : 'Assessment will be created soon '
            }
        />
    );
};
