/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { Text } from 'components/common';
import css from './style.module.scss';
import { FC } from 'react';

export interface ValueTextProps {
    value: string;
    dataTestId: string;
}

export const ValueText: FC<ValueTextProps> = ({ value, dataTestId }) => (
    <Text
        key={value}
        fontSize="14"
        font="sans"
        cx={css.readonlyRowText}
        dataTestId={dataTestId}
    >
        {value}
    </Text>
);
