/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useState } from 'react';
/**
 * Components
 */
import { ScrollBars } from '@epam/promo';
import { Document, Page } from 'react-pdf';
/**
 * Assets
 */
import css from './style.module.scss';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
/**
 * Types
 */
import { IPreviewPDF } from './types';
/**
 * Expo
 */
export const PreviewPDF: React.FC<IPreviewPDF> = ({ file }) => {
    const [pagesList, setPagesList] = useState<unknown[]>([]);

    const onDocumentLoadSuccess = useCallback(
        ({ numPages }: { numPages: number }) => {
            setPagesList(new Array(numPages));
        },
        []
    );

    return (
        <div className={css.container}>
            <ScrollBars>
                <Document
                    file={file}
                    loading={<div />}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {Array.from(pagesList, (_, index) => (
                        <Page
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                            width={800}
                            loading={<div />}
                            pageNumber={index + 1}
                            key={`page_${index + 1}`}
                        />
                    ))}
                </Document>
            </ScrollBars>
        </div>
    );
};
