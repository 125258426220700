/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
import { createRoot } from 'react-dom/client';
/**
 * Assets
 */
import '@epam/uui-components/styles.css';
import '@epam/uui/styles.css';
import '@epam/promo/styles.css';
import '@epam/internal/styles.css';
import 'draft-js/dist/Draft.css';
import './assets/style.scss';
/**
 * Components
 */
import { App } from 'App';
import { UuiEnhancedApp, GoogleAnalytics, CookiesBanner } from 'components';

/**
 * Utils
 */
import {
    forbidInlineStyles,
    trackRedirectStart,
    configurePdfViewer,
} from 'utils/system';
/**
 * App
 */
forbidInlineStyles();
trackRedirectStart();
configurePdfViewer();

const RoutedApp = () => (
    <UuiEnhancedApp>
        <CookiesBanner />
        <GoogleAnalytics />
        <App />
    </UuiEnhancedApp>
);
/**
 * Render
 */
document.getElementById('root')?.classList.add('uui-theme-promo');
const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(<RoutedApp />);
