/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import {
    environment,
    LOCAL_ENVIRONMENTS,
    ENVIRONMENT_BY_HOST_NAME,
} from 'constants/environments';
import {
    apiType,
    ApiTypes,
    API_URLS,
    AUTH_URLS,
    WEB_SOCKETS_URLS,
} from 'constants/api';
/**
 * Utils
 */
import qs from 'qs';
/**
 * Expo
 */
const environmentForApi = (() => {
    const {
        location: { host },
    } = window;

    if (LOCAL_ENVIRONMENTS.includes(environment)) {
        return environment;
    }

    return ENVIRONMENT_BY_HOST_NAME[
        host.startsWith('www') ? host.slice(4) : host
    ];
})();

export const getBaseApiUrl = (): string => API_URLS[apiType][environmentForApi];
export const getBaseAuthUrl = (): string =>
    AUTH_URLS[apiType][environmentForApi];
export const getWebSocketUrl = (): string => {
    if (apiType === ApiTypes.gateway) {
        return WEB_SOCKETS_URLS[environmentForApi];
    }

    return `${API_URLS[ApiTypes.basic][environmentForApi].replace(
        /^http/,
        'ws'
    )}/ws`;
};

export const createQueryUrl = (
    url: string,
    queryParams?: Record<
        string,
        string | boolean | null | undefined | number | string[]
    >,
    options?: Record<string, string | boolean>
): string => {
    const query = qs.stringify(queryParams, {
        skipNulls: true,
        arrayFormat: 'comma',
        ...options,
    });

    return `${url}${query ? '?' : ''}${query}`;
};
