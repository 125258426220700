/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
/**
 * Types
 */
import { PaginatedResponse, TShortPolicy } from 'types';
/**
 * Utils
 */
import { createQueryUrl } from 'utils';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Expo
 */
export const getProjectPoliciesList = async (
    projectId: string
): Promise<PaginatedResponse<TShortPolicy[]>> => {
    const url = createQueryUrl(ENDPOINTS.policiesByProject, {
        projectId,
    });

    return api.request(Methods.get, { url });
};
