/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Tooltip } from '@epam/promo';
import { User } from '../User';
import { FlexRow } from '../FlexItems';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { TPerson, WithDataTestId } from 'types';

type TUserListProps = {
    list: TPerson[];
} & WithDataTestId;

export const UserList: React.FC<TUserListProps> = ({ list, dataTestId }) => (
    <FlexRow cx={css.container} dataTestId={dataTestId}>
        {list.map(({ id, full_name }) => (
            <Tooltip content={full_name} key={id}>
                <User
                    name={full_name}
                    textSize="14"
                    avatarSize="24"
                    containerCx={css.userContainer}
                />
            </Tooltip>
        ))}
    </FlexRow>
);
