/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Hooks
 */
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
/**
 * Storage
 */
import { selectUserRole } from 'store/user';
/**
 * Types
 */
type TUseRole = (roles: string[]) => boolean;
/**
 * Expo
 */
export const useCheckRole = (): TUseRole => {
    const userRole = useSelector(selectUserRole);

    return useCallback(
        (roles: string[]) => {
            if (!userRole) return false;

            return roles.includes(userRole);
        },
        [userRole]
    );
};
