/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { ElementType } from 'types/threatModeling/element';
import * as z from 'zod';
import {
    REQUIRED_STRING_MESSAGE,
    zodOptionalString,
    zodNamedTmDto,
    zodAssetFootprintArray,
} from '../constants';
import { baseItemFormSchema } from '../baseItemFormSchema';

export const elementFormSchema = baseItemFormSchema.extend({
    type: z.nativeEnum(ElementType, {
        invalid_type_error: REQUIRED_STRING_MESSAGE,
        required_error: REQUIRED_STRING_MESSAGE,
    }),
    technologies: z
        .array(zodNamedTmDto)
        .nonempty({ message: REQUIRED_STRING_MESSAGE }),
    comment: zodOptionalString,
    assetFootprints: zodAssetFootprintArray.default([]),
    trustBoundary: zodNamedTmDto.nullish(),
});

export type ElementFormValues = z.infer<typeof elementFormSchema>;
export type ElementFormSchema = typeof elementFormSchema;
