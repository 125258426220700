/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { memo } from 'react';
/**
 * Hooks
 */
import { useRouteParams } from 'hooks';
/**
 * Components
 */
import { FlexRow, Panel } from '@epam/promo';
import { Status, User, LinkButton } from 'components/common';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { TFindingCardProps } from './types';
/**
 * Utils
 */
import { generatePath } from 'utils';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
import { getFindingName } from 'components/FindingInformation/utils';
/**
 * Expo
 */
export const FindingCard: React.FC<TFindingCardProps> = memo(
    ({ id, summary, status, linkedIssue, assignee, compositeId }) => {
        const { projectId } = useRouteParams();
        const { key } = linkedIssue || {};

        const findingHref = generatePath(PAGES.singleFinding, {
            projectId,
            findingId: id,
        });

        const findingName = getFindingName(compositeId, summary, key);

        return (
            <Panel cx={css.findingContainer}>
                <LinkButton
                    target="_blank"
                    href={findingHref}
                    caption={findingName}
                    dataTestId="activity/findings/linkButton"
                />
                <FlexRow columnGap="12">
                    <Status
                        type={status}
                        dataTestId="activity/findings/status"
                    />
                    <User
                        name={assignee}
                        textSize="14"
                        avatarSize="24"
                        textClassName={css.assignee}
                        dataTestId="activity/findings/assignee"
                    />
                </FlexRow>
            </Panel>
        );
    }
);
