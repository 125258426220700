/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useMemo } from 'react';
/**
 * Components
 */
import { Helmet } from 'react-helmet';
/**
 * Hooks
 */
import { useLocation } from 'react-router-dom';
/**
 * Utils
 */
import { getPageMetaByPath } from './utils';
/**
 * Expo
 */
export const HtmlHead: React.FC = () => {
    const { pathname } = useLocation();

    const { title, description } = useMemo(
        () => getPageMetaByPath(pathname),
        [pathname]
    );

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="robots" content="nosnippet, noarchive" />
            {description && <meta name="description" content={description} />}
        </Helmet>
    );
};
