/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Form } from './Form';
import { Modal as ModalComponent } from 'components/common';
/**
 * Constants
 */
import { ModalSizes } from 'constants/modals';
/**
 * Types
 */
import { ModalProps } from './types';
/**
 * Expo
 */
export const AttachFilesModal: React.FC<ModalProps> = ({
    onSubmit,
    dataTestId,
    successButtonCaption,
    ...props
}) => {
    return (
        <ModalComponent
            {...props}
            withoutFooter
            title="Attach files"
            width={ModalSizes.large}
            dataTestId={`${dataTestId}/attachFilesModal`}
            allowCloseOnCross={false}
            renderBody={({ abort }) => (
                <Form
                    onClose={abort}
                    infoText="Up to 15 files. Limit for 1 file is 50 MB."
                    onSubmit={onSubmit}
                    dataTestId={dataTestId}
                    successButtonCaption={successButtonCaption}
                />
            )}
        />
    );
};
