/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Panel } from '../FlexItems';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import type { TPanelProps as UuiPanelProps } from '../FlexItems/Panel';
import type { FC } from 'react';
/**
 * Utils
 */
import { cx } from '@epam/uui-core';

export type TPanelBlockProps = UuiPanelProps & { size?: number };

export const PanelBlock: FC<TPanelBlockProps> = ({
    children,
    shadow = true,
    cx: cxProp,
    size,
    ...rest
}) => (
    <Panel
        shadow={shadow}
        background="white"
        style={size != null ? { width: `${size}%` } : {}}
        cx={cx(css.block, cxProp)}
        {...rest}
    >
        {children}
    </Panel>
);
