/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useEffect, useState } from 'react';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
import {
    useAppDispatch,
    usePageLoadingPerformance,
    useRole,
    useRouteParams,
} from 'hooks';
/**
 * Components
 */
import { Content } from './components/Content';
import { PageLoadHandler } from 'components/PageLoadHandler';
import { Header } from './components/Header';
/**
 * Store
 */
import {
    handleGetList,
    selectInProgress,
    selectIsInitialized,
} from 'store/assessments';
import { selectUserProjectId } from 'store/user';
/**
 * Expo
 */
const Assessments: React.FC = () => {
    usePageLoadingPerformance({
        waitForApi: true,
        reducerNames: ['user', 'project', 'assessments'],
    });

    const [hasSearch, setHasSearch] = useState(false);

    const { isOperationsManager } = useRole();
    const { projectId: projectIdFromUrl } = useRouteParams();

    const inProgress = useSelector(selectInProgress);
    const isInitialized = useSelector(selectIsInitialized);
    const currentProjectId = useSelector(selectUserProjectId);

    const projectId = isOperationsManager ? projectIdFromUrl : currentProjectId;

    const dispatch = useAppDispatch();

    const handleGetAssessments = useCallback(
        (search?: string) => {
            if (!projectId) return;

            dispatch(handleGetList(projectId, search));
        },
        [projectId, dispatch]
    );

    const handleLoadAssessments = useCallback(
        async (search?: string) => {
            setHasSearch(Boolean(search));

            handleGetAssessments(search);
        },
        [handleGetAssessments]
    );

    useEffect(() => {
        handleLoadAssessments();
    }, [handleLoadAssessments]);

    return (
        <PageLoadHandler
            inProgress={inProgress}
            isInitialized={isInitialized}
            renderHeader={() => (
                <Header onLoadAssessments={handleLoadAssessments} />
            )}
        >
            <Content hasSearch={hasSearch} />
        </PageLoadHandler>
    );
};

export default Assessments;
