/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
import { cx } from '@epam/uui-core';
/**
 * Components
 */
import { Blocker as UuiBlocker, Text } from '@epam/promo';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const Blocker: React.FC<{ title: string; isEnabled: boolean }> = ({
    title,
    isEnabled,
}) => (
    <UuiBlocker
        isEnabled={isEnabled}
        cx={css.blocker}
        renderSpinner={() => (
            <Text cx={cx(css.blockerTitle, 'centered')} fontSize="16">
                {title}
            </Text>
        )}
    />
);
