/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { createReadableId } from 'pages/ThreatModeling/tabs/ModelTreeTab/components/ExpandableTree/components/utils';
import { AssetCreatePayload } from 'store/threatModeling/asset/types';
import { Asset } from 'types';

import {
    AssetAvailability,
    AssetConfidentiality,
    AssetIntegrity,
    AssetType,
} from 'types/threatModeling/asset';
import { FormValuesWithoutTmId } from '../baseItemFormSchema';
import {
    ASSET_READABLE_ID_PREFIX,
    assetAvailabilityToLabelMap,
    assetConfidentialityToLabelMap,
    assetIntegrityToLabelMap,
    assetTypeToLabelMap,
} from './constants';
import { AssetFormValues } from './schema';

export const mapAssetTypeToLabel = (assetType: AssetType) =>
    assetTypeToLabelMap[assetType];
export const mapAssetConfidentialityToLabel = (
    assetConfidentiality: AssetConfidentiality
) => assetConfidentialityToLabelMap[assetConfidentiality];
export const mapAssetAvailabilityToLabel = (
    assetAvailability: AssetAvailability
) => assetAvailabilityToLabelMap[assetAvailability];
export const mapAssetIntegrityToLabel = (assetIntegrity: AssetIntegrity) =>
    assetIntegrityToLabelMap[assetIntegrity];

export function mapAssetToFormValues(
    asset: Asset
): FormValuesWithoutTmId<AssetFormValues> {
    return {
        id: asset.id,
        name: asset.name,
        readableId: createReadableId(
            ASSET_READABLE_ID_PREFIX,
            String(asset.readable_id)
        ),
        type: asset.type,
        comment: asset.comment,
        confidentiality: asset.confidentiality,
        confidentialityComment: asset.confidentiality_comment,
        integrity: asset.integrity,
        integrityComment: asset.integrity_comment,
        availability: asset.availability,
        availabilityComment: asset.availability_comment,
    };
}

export function mapFormValuesToAssetPayload(
    form: AssetFormValues
): AssetCreatePayload {
    return {
        tm_id: form.tmId,
        name: form.name,
        type: form.type,
        comment: form.comment,
        confidentiality: form.confidentiality,
        confidentiality_comment: form.confidentialityComment,
        integrity: form.integrity,
        integrity_comment: form.integrityComment,
        availability: form.availability,
        availability_comment: form.availabilityComment,
    };
}
