/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import {
    AssetAvailability,
    AssetConfidentiality,
    AssetIntegrity,
    AssetType,
} from 'types/threatModeling/asset';

export const assetTypeToLabelMap: Record<AssetType, string> = {
    [AssetType.technical]: 'Technical',
    [AssetType.business]: 'Business',
};
export const assetConfidentialityToLabelMap: Record<
    AssetConfidentiality,
    string
> = {
    [AssetConfidentiality.high]: 'High',
    [AssetConfidentiality.moderate]: 'Moderate',
    [AssetConfidentiality.low]: 'Low',
};
export const assetAvailabilityToLabelMap: Record<AssetAvailability, string> = {
    [AssetAvailability.high]: 'High',
    [AssetAvailability.moderate]: 'Moderate',
    [AssetAvailability.low]: 'Low',
};
export const assetIntegrityToLabelMap: Record<AssetIntegrity, string> = {
    [AssetConfidentiality.high]: 'High',
    [AssetConfidentiality.moderate]: 'Moderate',
    [AssetConfidentiality.low]: 'Low',
};

export const ASSET_READABLE_ID_PREFIX = 'DA';
