/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { TResponse, TQueryParams } from './types';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Utils
 */
import { createQueryUrl } from 'utils';
/**
 * Expo
 */
export const getActionLogs = async ({
    page,
    size,
    person_id,
}: TQueryParams): Promise<TResponse> => {
    const url = createQueryUrl(ENDPOINTS.publicActionLog, {
        page,
        size,
        person_id,
    });

    return api.request<TResponse>(Methods.get, { url });
};
