/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { ProgressStepsTypes } from 'types';
import { TInitialState } from './types';
/**
 * Expo
 */
export const INITIAL_STATE: TInitialState = {
    data: {},
    inProgress: false,
    isInitialized: false,
    messageChannels: {},
    stepsData: {
        stepsIds: [],
        totalCheckedCount: 0,
    },
};

export const STEPS_WITH_LINK = [
    ProgressStepsTypes.proposalSent,
    ProgressStepsTypes.contractSent,
    ProgressStepsTypes.projectCreated,
    ProgressStepsTypes.projectNeedsClarified,
];
