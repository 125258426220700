/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import ReactGA from 'react-ga4';
/**
 * Constants
 */
import { isGADisabled } from 'constants/googleAnalytics';
import { isProduction, GA_TRACKING_IDS, GTM_TRACKING_IDS } from './constants';
/**
 * Utils
 */
import { sendDataLayerEvent } from 'utils';
/**
 * Expo
 */
export const initializeGA = (): void => {
    if (isGADisabled) return;

    const trackingId = isProduction
        ? GA_TRACKING_IDS.prod
        : GA_TRACKING_IDS.test;

    ReactGA.initialize(trackingId, {
        gtagOptions: {
            custom_map: {
                dimension1: 'user_role',
                dimension2: 'organization',
                dimension3: 'user_organization',
            },
            debug_mode: !isProduction,
        },
    });
};

export const initializeGTM = (): void => {
    if (isGADisabled) return;

    const trackingId = isProduction
        ? GTM_TRACKING_IDS.prod
        : GTM_TRACKING_IDS.test;

    sendDataLayerEvent('gtm.js', { 'gtm.start': new Date().getTime() });

    const GTMScript = document.createElement('script');

    GTMScript.async = true;
    GTMScript.src = `https://www.googletagmanager.com/gtm.js?id=${trackingId}`;

    document.head.appendChild(GTMScript);
};
