/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import {
    BlockTypeByTagName,
    EntityTypes,
    NodeAlignments,
    StyleAlignments,
    StyleBackgroundHighLights,
} from 'constants/richTextEditor';
import { DefaultDraftBlockRenderMap } from 'draft-js';
/**
 * Types
 */
import { TStyleMap } from './types';

/**
 * Expo
 */
export const STYLE_MAP: TStyleMap = {
    [StyleBackgroundHighLights.BACKGROUND_HIGHLIGHTS_YELLOW]: {
        backgroundColor: '#FFFD3E',
    },
};

export const ALIGNMENT_CLASSES = {
    [StyleAlignments.left]: 'left',
    [StyleAlignments.right]: 'right',
    [StyleAlignments.center]: 'center',
};

export const ENTITY_HTML_TEMPLATES = {
    [EntityTypes.link]: '<a href="{url}">{text}</a>',
    [EntityTypes.image]:
        '<img src="{src}" width="{width}" height="{height}" data-align="{alignment}" />',
};

export const STYLE_TO_ALIGNMENT = {
    [StyleAlignments.left]: NodeAlignments.left,
    [StyleAlignments.right]: NodeAlignments.right,
    [StyleAlignments.center]: NodeAlignments.center,
};
export const ALIGNMENT_TO_STYLE = {
    [NodeAlignments.left]: StyleAlignments.left,
    [NodeAlignments.right]: StyleAlignments.right,
    [NodeAlignments.center]: StyleAlignments.center,
};
export const BACKSPACE_COMMANDS = [
    'backspace',
    'backspace-word',
    'backspace-to-start-of-line',
];

export const DELETE_COMMANDS = ['delete', 'delete-word'];

export const TAG_BY_BLOCK_TYPE = {
    [BlockTypeByTagName.h1]: 'h1',
    [BlockTypeByTagName.h2]: 'h2',
    [BlockTypeByTagName.h3]: 'h3',
    [BlockTypeByTagName.h4]: 'h4',
    [BlockTypeByTagName.h5]: 'h5',
    [BlockTypeByTagName.h6]: 'h6',
    [BlockTypeByTagName.p]: 'p',
    [BlockTypeByTagName.blockquote]: 'blockquote',
    [BlockTypeByTagName.pre]: 'pre',
    [BlockTypeByTagName.figure]: 'figure',
    [BlockTypeByTagName.div]: 'div',
};

export const blockRenderMap = DefaultDraftBlockRenderMap.set('code-block', {
    element: 'pre',
});
