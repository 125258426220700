/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Types
 */
import { TReportExistsByName } from 'types';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
/**
 * Components
 */
import {
    Approach,
    Conclusion,
    Content,
    Findings,
    General,
    Introduction,
    Recommendations,
    RemediationReport,
    Risks,
    Scope,
    Summary,
    Tools,
} from './components';
/**
 * Utils
 */
import { createQueryUrl, NotificationTypes, showNotification } from 'utils';
/**
 * Services
 */
import { api } from 'services/api';
import { TabsIds } from './constants';
/**
 * Expo
 */
export const checkIfReportExisted = async (
    reportName: string
): Promise<void> => {
    const url = createQueryUrl(ENDPOINTS.existingReportByName, {
        report_name: reportName,
    });

    const { exists } = await api.request<TReportExistsByName>(Methods.get, {
        url,
    });

    if (exists) {
        showNotification({
            type: NotificationTypes.error,
            text: 'Report with this name already exists. Please try another name',
        });

        return Promise.reject();
    }

    return Promise.resolve();
};

export const renderContent = (
    tabId: string,
    contentSteps?: { id: string; name: string }[]
): JSX.Element | null => {
    switch (tabId) {
        case TabsIds.general:
            return <General />;
        case TabsIds.introduction:
            return <Introduction />;
        case TabsIds.scope:
            return <Scope />;
        case TabsIds.tools:
            return <Tools />;
        case TabsIds.approach:
            return <Approach />;
        case TabsIds.summary:
            return <Summary />;
        case TabsIds.risks:
            return <Risks />;
        case TabsIds.recommendations:
            return <Recommendations />;
        case TabsIds.conclusions:
            return <Conclusion />;
        case TabsIds.findings:
            return <Findings />;
        case TabsIds.remediationReport:
            return <RemediationReport />;
        case TabsIds.content:
            return <Content contentSteps={contentSteps} />;
        default:
            return null;
    }
};
