/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useState } from 'react';
/**
 * Components
 */
import { DateFromNow } from 'components';
import { TextPlaceholder } from '@epam/promo';
import {
    FlexCell,
    Panel,
    PanelBlock,
    Text,
    VirtualList,
    PanelTitle,
} from 'components/common';
/**
 * Types
 */
import { TProps } from './types';
import { PaginatedResponse } from 'types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Constants
 */
import { createQueryUrl } from 'utils';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
import { MAIN_DATA_TEST_ID } from './constants';
/**
 * Services
 */
import { api } from 'services/api';
import { DataRowProps, LazyDataSourceApi } from '@epam/uui-core';
/**
 * Expo
 */
export const RecentActions = <
    TActionLog extends {
        datetime: Date;
    }
>({
    entityId,
    entityType,
    dataTestId,
    renderAction,
}: TProps<TActionLog>): JSX.Element | null => {
    const [isEmpty, setIsEmpty] = useState(false);

    const handleGetActions: LazyDataSourceApi<TActionLog, string, string> =
        useCallback(
            async ({ range }) => {
                const { from = 0, count = 0 } = range ?? {};

                const queryUrl = createQueryUrl(ENDPOINTS.privateActionLog, {
                    size: count,
                    offset: from,
                    entity_id: entityId,
                    origin_page: entityType,
                });

                const {
                    data,
                    page: { total_elements: total },
                } = await api.request<PaginatedResponse<TActionLog[]>>(
                    Methods.get,
                    {
                        url: queryUrl,
                    }
                );

                if (!data.length && !isEmpty) {
                    setIsEmpty(true);
                }

                return { items: data, count: total };
            },
            [isEmpty, entityId, entityType]
        );

    const handleRenderAction = useCallback(
        ({ rowKey, value }: DataRowProps<TActionLog, string>) => {
            if (!value) {
                return (
                    <FlexCell key={rowKey} cx={css.listItem}>
                        <TextPlaceholder wordsCount={8} />
                    </FlexCell>
                );
            }

            const actionElement = renderAction(value);

            if (!actionElement) {
                return null;
            }

            return (
                <FlexCell
                    key={rowKey}
                    cx={css.listItem}
                    dataTestId={MAIN_DATA_TEST_ID}
                >
                    <DateFromNow
                        date={value.datetime}
                        className={css.dateTime}
                        dataTestId="actionLog/time"
                    />
                    {actionElement}
                </FlexCell>
            );
        },
        [renderAction]
    );

    return (
        <PanelBlock dataTestId={`${dataTestId}/actions`}>
            <PanelTitle
                title="Recent actions"
                withSeparator
                dataTestId={`${dataTestId}/actions/title`}
            />
            <Panel dataTestId={`${dataTestId}/actions/content`}>
                {isEmpty ? (
                    <Panel cx={css.emptyBlock}>
                        <Text color="gray60">No recent actions</Text>
                        <img
                            alt="no attachments"
                            src="/assets/images/emptyViews/noContent.svg"
                        />
                    </Panel>
                ) : (
                    <VirtualList
                        count={20}
                        maxHeight={400}
                        onGetList={handleGetActions}
                        renderItem={handleRenderAction}
                    />
                )}
            </Panel>
        </PanelBlock>
    );
};
