/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { RootState, TDocument, TSelector } from 'types';
import { selectThreatModelingActivityId } from 'store/project';

export const selectThreatModelingInProgress: TSelector<boolean> = ({
    singleActivity: { inProgress: threatModelingInProgress },
    assessment: { inProgress: assessmentInProgress },
}) => threatModelingInProgress || assessmentInProgress;

export const selectThreatModelingIsInitialized: TSelector<boolean> = ({
    singleActivity: { isInitialized: threatModelingIsInitialized },
    assessment: { isInitialized: assessmentIsInitialized },
}) => threatModelingIsInitialized || assessmentIsInitialized;

export const selectThreatModelingActivity = (state: RootState) =>
    state.singleActivity.activity ?? undefined;

export const selectIsThreatModelingActivity: (
    id: string
) => TSelector<boolean> = (id) => (state) =>
    selectThreatModelingActivityId(state) === id;

export const selectThreatModelingDfdMetadata: TSelector<
    TDocument | undefined
> = ({
    threatModeling: {
        dfdMetadata: { document },
    },
}) => document;

export const selectThreatModelingDfdInProgress: TSelector<boolean> = ({
    threatModeling: {
        dfdMetadata: { inProgress },
    },
}) => inProgress;
