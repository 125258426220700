/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useMemo } from 'react';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
/**
 * Components
 */
import { ArrayTable } from 'components/common';
/**
 * Constants
 */
/**
 * Types
 */
import { TableProps } from './types';
/**
 * Utils
 */
import { getColumnsByRole } from './utils';
/**
 * Store
 */
import { selectUserRole } from 'store/user';
/**
 * Assets
 */
import css from './style.module.scss';

/**
 * Expo
 */
export const AssessmentsList: React.FC<TableProps> = ({ data, onRowClick }) => {
    const currentRole = useSelector(selectUserRole);

    const getColumns = useMemo(
        () => getColumnsByRole(currentRole),
        [currentRole]
    );

    return (
        <div className={css.container}>
            <ArrayTable
                data={data}
                getColumns={getColumns}
                onRowClick={onRowClick}
                dataTestId="assessments/table"
            />
        </div>
    );
};
