/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { createSlice } from '@reduxjs/toolkit';
import { TRUST_BOUNDARIES_INITIAL_STATE } from './constants';

export const {
    reducer: trustBoundariesReducer,
    actions: {
        saveTrustBoundaries,
        setTrustBoundariesLoadingInProgress,
        resetTrustBoundaries,
        addTrustBoundary,
        updateTrustBoundary,
        saveTrustBoundaryTypes,
    },
} = createSlice({
    name: 'trustBoundary',
    initialState: TRUST_BOUNDARIES_INITIAL_STATE,
    reducers: {
        saveTrustBoundaries: (state, { payload }) => {
            state.list = payload;
            state.isInitialized = true;
        },
        setTrustBoundariesLoadingInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        resetTrustBoundaries: () => TRUST_BOUNDARIES_INITIAL_STATE,
        addTrustBoundary: (state, { payload }) => {
            state.list = [...state.list, payload];
        },
        updateTrustBoundary: (state, { payload }) => {
            const index = state.list.findIndex(
                (trustBoundary) => trustBoundary.id === payload.id
            );
            if (index !== -1) {
                state.list[index] = { ...state.list[index], ...payload };
            }
        },
        saveTrustBoundaryTypes: (state, { payload }) => {
            state.types = payload;
        },
    },
});
