/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Types
 */
import { TAttachment } from 'types';
/**
 * Utils
 */
import { templateString } from 'utils';
/**
 * Expo
 */
export const addAttachments = async (
    projectId: string,
    attachments: TAttachment[]
): Promise<void> => {
    const url = templateString(ENDPOINTS.projectAttachments, {
        projectId,
    });

    const formData = new FormData();

    attachments.forEach(({ file }: TAttachment) => {
        formData.append('files', file, file.name);
    });

    await api.request(Methods.post, { url, data: formData });
};

export const removeAttachment = async (
    projectId: string,
    attachmentId: string
): Promise<void> => {
    const url = templateString(ENDPOINTS.singleProjectAttachment, {
        projectId,
        attachmentId,
    });

    await api.request(Methods.delete, { url });
};
