/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { normalize, schema } from 'normalizr';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { createdFrom } from './constants';
/**
 * Types
 */
import { PaginatedResponse, TNotification } from 'types';
import { TNormalizeNotifications } from './types';
/**
 * Utils
 */
import { createQueryUrl, templateString } from 'utils';
/**
 * Services
 */
import { api } from 'services/api';
import { Methods } from 'constants/request';
/**
 * Expo
 */
const notificationsSchema = new schema.Entity('notifications');

const fetchNotifications = ({
    size,
    isViewed,
}: {
    size?: number;
    isViewed?: boolean;
}) => {
    const url = createQueryUrl(ENDPOINTS.myNotifications, {
        size,
        isViewed,
        createdFrom,
    });

    return api.request<PaginatedResponse<TNotification[]>>(Methods.get, {
        url,
    });
};

export const getDropdownNotificationsList = async (): Promise<
    TNotification[]
> => {
    const { data } = await fetchNotifications({ size: 5 });

    return data;
};

export const getNotViewedCount = async (): Promise<number> => {
    const {
        page: { total_elements: totalElements },
    } = await fetchNotifications({ size: 1, isViewed: false });

    return totalElements;
};
export const getNotificationsList = async (): Promise<TNotification[]> => {
    const { data } = await fetchNotifications({ size: 1000 });

    return data;
};

export const normalizeNotifications: TNormalizeNotifications = (list) => {
    const {
        result,
        entities: { notifications = {} },
    } = normalize(list, [notificationsSchema]);

    return { idList: result, notificationsById: notifications };
};

export const getUnreadNotificationsIds = (
    notifications: TNotification[]
): string[] =>
    notifications.reduce((accumulator: string[], { id, is_viewed }) => {
        if (!is_viewed) accumulator.push(id);

        return accumulator;
    }, []);

export const handleMarkNotificationsAsRead = (
    notificationsIds: string[]
): Promise<void> =>
    api.request(Methods.put, {
        url: ENDPOINTS.readNotifications,
        data: {
            notification_ids: notificationsIds,
        },
    });

export const handleMarkSingleNotificationAsRead = (
    notificationId: string
): Promise<void> => {
    const url = templateString(ENDPOINTS.readSingleNotification, {
        notificationId,
    });

    return api.request(Methods.put, { url });
};
