/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Button } from 'components/common';
import { FlexRow, FlexSpacer } from '@epam/promo';
/**
 * Assets
 */
import css from '../CreateComment/style.module.scss';
import { ReactComponent as attachmentIcon } from '@epam/assets/icons/common/file-attachment-18.svg';
/**
 * Types
 */
import { TActionButtons } from './types';
/**
 * Expo
 */
export const ActionButtons: React.FC<TActionButtons> = ({
    onCancel,
    onSubmit,
    handleAddAttachment,
    isDisabled,
    captionSubmit = 'Add',
    dataTestId,
}) => (
    <FlexRow columnGap="12" cx={css.editorButtons}>
        <Button
            fill="outline"
            color="primary"
            icon={attachmentIcon}
            caption="Attach files"
            onClick={handleAddAttachment}
            dataTestId={`${dataTestId}/comment/attachFilesButton`}
        />
        <FlexSpacer />
        <Button
            fill="outline"
            color="neutral"
            caption="Cancel"
            onClick={onCancel}
            dataTestId={`${dataTestId}/comment/CancelButton`}
        />
        <Button
            caption={captionSubmit}
            onClick={onSubmit}
            disabled={isDisabled}
            dataTestId={`${dataTestId}/comment/submitButton`}
        />
    </FlexRow>
);
