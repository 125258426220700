/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Hooks
 */
import { useDataSource } from 'hooks';
/**
 * Components
 */
import { Select } from './DefaultSelect';
/**
 * Types
 */
import { AsyncSelectProps } from './types';
/**
 * Expo
 */
export function AsyncSelect<T>({
    url,
    idKey,
    filter,
    additionalOptions,
    ...props
}: AsyncSelectProps<T>): JSX.Element {
    const dataSource = useDataSource<T>({
        url,
        idKey,
        filter,
        additionalOptions,
    });

    return <Select<T> {...props} dataSource={dataSource} />;
}
