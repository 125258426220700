/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Panel } from '@epam/promo';
import { FlexRow, Text } from 'components/common';
/**
 * Types
 */
import { ILegendProps } from './types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export function Legend({
    data,
    dataTestId,
    renderBadge,
}: ILegendProps): JSX.Element {
    return (
        <Panel>
            <FlexRow
                columnGap="6"
                cx={css.legendContainer}
                dataTestId={`${dataTestId}/legend/item`}
            >
                {data.map(({ id, value }) => {
                    if (!value) return null;

                    return (
                        <FlexRow columnGap="6" key={`legend_${id}`}>
                            {renderBadge(id)}
                            <Text>— </Text>
                            <Text dataTestId={`${dataTestId}/value/item`}>
                                {value}
                            </Text>
                        </FlexRow>
                    );
                })}
            </FlexRow>
        </Panel>
    );
}
