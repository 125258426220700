/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { TActivityActionLog } from 'types';
import { EntityTypes } from 'constants/actionLog';
import { RecentActions } from 'components/RecentActions';
import { memo, useCallback } from 'react';
import { TProps } from 'components/RecentActions/types';
import { renderAction } from './utils';

export type OmittedRecentActionProps = Omit<
    TProps<TActivityActionLog>,
    'entityType' | 'renderAction' | 'entityId'
>;

export interface ActivityRecentActionsProps extends OmittedRecentActionProps {
    activityName: string;
    activityId: string;
}

export const ActivityRecentActions: React.FC<ActivityRecentActionsProps> = memo(
    ({ dataTestId, activityId, activityName }) => {
        const renderActionWithAdditionalProps = useCallback(
            (props: TActivityActionLog) => renderAction(activityName, props),
            [activityName]
        );

        return (
            <RecentActions
                dataTestId={dataTestId}
                entityId={activityId}
                entityType={EntityTypes.activity}
                renderAction={renderActionWithAdditionalProps}
            />
        );
    }
);
