/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Hooks
 */
/**
 * Components
 */
import { ChooseProjectByOrganization } from './components/ChooseProjectByOrganization';
import { AdaptiveItemProps } from '@epam/uui-components';
/**
 * Expo
 */
export const projectHeader = (): Array<AdaptiveItemProps> => [
    {
        id: 'choose-project',
        priority: 2,
        render: () => <ChooseProjectByOrganization />,
    },
];
