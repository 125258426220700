/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createSlice } from '@reduxjs/toolkit';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
import { INITIAL_STATE } from './constants';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Types
 */
import { AppDispatch, TAttachment, TSelector, TDocument } from 'types';
/**
 * Utils
 */
import { templateString } from 'utils';
import { addAttachments, removeAttachment } from './utils';
import { attachmentsDidUpdateAction } from 'store/middleware/backgroundUpdate/actions';
/**
 * Reducer
 */
const { reducer, actions } = createSlice({
    name: 'projectAttachments',
    initialState: INITIAL_STATE,
    reducers: {
        saveAttachments: (state, { payload }) => {
            state.attachments = payload;
        },
        setInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        setIsInitialized: (state, { payload }) => {
            state.isInitialized = payload;
        },
        resetProjectData: () => INITIAL_STATE,
    },
});

export default reducer;
/**
 * Selectors
 */
export const selectAttachments: TSelector<TDocument[]> = ({
    projectAttachments: { attachments },
}) => attachments;
export const selectInProgress: TSelector<boolean> = ({
    projectAttachments: { inProgress },
}) => inProgress;
export const selectIsInitialized: TSelector<boolean> = ({
    projectAttachments: { isInitialized },
}) => isInitialized;
/**
 * Actions
 */
export const {
    setInProgress,
    saveAttachments,
    resetProjectData,
    setIsInitialized,
} = actions;
/**
 * Dispatchers
 */
export const handleGetAttachments =
    (projectId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = templateString(ENDPOINTS.projectAttachments, {
            projectId,
        });

        const response = await api.request(Methods.get, { url });

        dispatch(setIsInitialized(true));
        dispatch(saveAttachments(response));
    };

export const handleAddAttachments =
    (projectId: string, attachments: TAttachment[]) =>
    async (dispatch: AppDispatch): Promise<void> => {
        await addAttachments(projectId, attachments);

        dispatch(attachmentsDidUpdateAction());
    };

export const handleRemoveAttachment =
    (projectId: string, attachmentId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        await removeAttachment(projectId, attachmentId);

        dispatch(attachmentsDidUpdateAction());
    };
