/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import {
    TChangeStatusRequest,
    TChangeStatusResponse,
    TNewMessageRequest,
} from './types';
import {
    AppDispatch,
    PaginatedResponse,
    TAttachment,
    TChatMessage,
    TComment,
    TGetState,
    TRequest,
    TRequestProgressStep,
    TShortPerson,
} from 'types';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
import { CAPTIONS_BY_STATUS, StatusTypes } from 'constants/statuses';
/**
 * Actions
 */
import {
    addMessage,
    addPendingMessage,
    changeDocumentLink,
    changeOperationsManager,
    changeSalesManager,
    changeStatus,
    checkStep,
    saveData,
    saveMessages,
    saveSteps,
    setInProgress,
} from './actions';
/**
 * Utils
 */
import { createQueryUrl, showNotification, templateString } from 'utils';
import {
    normalizeSteps,
    denormalizeStep,
    createPendingMessage,
    getCompletedStepsCount,
} from './utils';
/**
 * Store
 */
import { selectUserIdAndName } from 'store/user';
import {
    selectRequest,
    selectRequestId,
    selectSalesManager,
    selectOperationsManager,
} from './selectors';
/**
 * Expo
 */
export const handleGetInfo =
    (requestId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = templateString(ENDPOINTS.singleRequest, {
            requestId,
        });

        const data = await api.request<TRequest>(Methods.get, { url });

        dispatch(saveData(data));
    };

export const handleGetProgressData =
    (requestId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = templateString(ENDPOINTS.singleRequestProgressSteps, {
            requestId,
        });

        const steps = await api.request<TRequestProgressStep[]>(Methods.get, {
            url,
        });

        const normalizedData = normalizeSteps(steps);
        const totalCheckedCount = getCompletedStepsCount(steps);

        dispatch(saveSteps({ ...normalizedData, totalCheckedCount }));
    };

export const handleGetData =
    (requestId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setInProgress(true));

        try {
            await Promise.all([
                dispatch(handleGetInfo(requestId)),
                dispatch(handleGetProgressData(requestId)),
            ]);
        } finally {
            dispatch(setInProgress(false));
        }
    };

const handleUpdateStep =
    (stepId: string) =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const {
            singleRequest: {
                stepsData: { stepsById },
            },
        } = getState();

        const url = templateString(ENDPOINTS.singleRequestProgressStep, {
            stepId,
        });

        if (stepsById) {
            const step = denormalizeStep({ stepId, stepsById });

            await api.request(Methods.put, {
                url,
                data: step,
            });
        }
    };

export const handleCheckStep =
    (stepId: string, isChecked: boolean) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(checkStep({ stepId, isChecked }));

        dispatch(handleUpdateStep(stepId));
    };

export const handleChangeStatus =
    (status: StatusTypes) =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const {
            data: { id, requestNumber },
        } = selectRequest(getState());

        if (!id) return;

        const url = templateString(ENDPOINTS.singleRequestStatus, {
            requestId: id,
        });

        try {
            dispatch(setInProgress(true));

            const { status: currentStatus, previous_status: previousStatus } =
                await api.request<TChangeStatusResponse, TChangeStatusRequest>(
                    Methods.put,
                    {
                        url,
                        data: { status },
                    }
                );

            dispatch(changeStatus({ currentStatus, previousStatus }));

            showNotification({
                text: `Request "${requestNumber}" status changed "${CAPTIONS_BY_STATUS[previousStatus]}" -> "${CAPTIONS_BY_STATUS[currentStatus]}"`,
            });
        } finally {
            dispatch(setInProgress(false));
        }
    };

export const handleChangeStatusAfterCheckStep =
    () =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const {
            data: { id, status },
            stepsData: { stepsIds, totalCheckedCount },
        } = selectRequest(getState());

        if (!status || !id) return;

        let actualStatus;

        if (totalCheckedCount > 0 && status === StatusTypes.statusNew) {
            actualStatus = StatusTypes.active;
        } else if (totalCheckedCount === stepsIds.length) {
            actualStatus = StatusTypes.finished;
        }

        if (actualStatus) {
            dispatch(handleChangeStatus(actualStatus));
        }
    };

export const changeManager =
    () =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const requestId = selectRequestId(getState());
        const salesManager = selectSalesManager(getState());
        const operationsManager = selectOperationsManager(getState());

        if (!requestId) return;

        const url = templateString(ENDPOINTS.singleRequest, {
            requestId,
        });

        try {
            dispatch(setInProgress(true));

            await api.request(Methods.put, {
                url,
                data: {
                    sales_manager_id: salesManager?.id || null,
                    operations_manager_id: operationsManager?.id || null,
                },
            });
        } finally {
            dispatch(setInProgress(false));
        }
    };

export const handleChangeSalesManager =
    (salesManager: TShortPerson) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(changeSalesManager(salesManager));
        dispatch(changeManager());
    };

export const handleChangeOperationsManager =
    (operationsManager: TShortPerson) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(changeOperationsManager(operationsManager));
        dispatch(changeManager());
    };

export const handleChangeDocumentLink =
    (stepId: string, documentLink: { name: string; link: string }) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(changeDocumentLink({ stepId, documentLink }));

        dispatch(handleUpdateStep(stepId));
    };

export const handleGetMessages =
    (requestId: string, type: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        if (!requestId) return;

        const url = templateString(ENDPOINTS.singleRequestMessages, {
            requestId,
        });

        const queryUrl = createQueryUrl(url, {
            type,
            size: 1000,
            sort: 'createdAt,desc',
        });

        const { data } = await api.request<PaginatedResponse<TComment[]>>(
            Methods.get,
            {
                url: queryUrl,
            }
        );

        dispatch(saveMessages({ type, messages: data }));
    };

export const handleSendMessage =
    ({ text, attachments, type }: TNewMessageRequest) =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const formData = new FormData();

        const user = selectUserIdAndName(getState());
        const requestId = selectRequestId(getState());

        if (!requestId) return;

        const url = templateString(ENDPOINTS.singleRequestMessages, {
            requestId,
        });

        const pendingMessage = createPendingMessage(text, attachments, user);

        dispatch(addPendingMessage({ type, pendingMessage }));

        attachments.forEach(({ file }: TAttachment) => {
            formData.append('files', file, file.name);
        });

        formData.append(
            'payload',
            JSON.stringify({
                text,
                type,
            })
        );

        const message = await api.request<TComment, FormData>(Methods.post, {
            url,
            data: formData,
        });

        dispatch(addMessage({ type, message, pendingMessage }));
    };

export const handleGetMessage =
    (messageId: string) =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const requestId = selectRequestId(getState());

        if (!requestId) return;

        const url = templateString(ENDPOINTS.singleRequestMessage, {
            messageId,
            requestId,
        });

        const message = await api.request<TChatMessage>(Methods.get, { url });

        dispatch(addMessage({ message, type: message.type }));
    };
