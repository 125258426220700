/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createSlice } from '@reduxjs/toolkit';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
import { ReportState, ReportStatus } from 'constants/reports';
import { SortVariants } from 'constants/filter';
import {
    CREATE_REQUEST_BODY_BY_TYPE,
    ENDPOINTS_BY_TYPE,
    INITIAL_STATE,
} from './constants';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Types
 */
import {
    TFilter,
    TReport,
    TGetState,
    TSelector,
    AppDispatch,
    PaginatedResponse,
} from 'types';
import { TFormValues } from './types';
/**
 * Utils
 */
import {
    createQueryUrl,
    isEmpty as isEmptyObject,
    templateString,
} from 'utils';
import { selectUserProjectId } from 'store/user';
/**
 * Reducer
 */
const { reducer, actions } = createSlice({
    name: 'reports',
    initialState: INITIAL_STATE,
    reducers: {
        saveList: (state, { payload }) => {
            state.list = payload;

            state.isInitialized = true;
        },
        saveFilter: (state, { payload }) => {
            state.filter = payload;
        },
        saveSearch: (state, { payload }) => {
            state.search = payload;
        },
        setInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        setIsEmpty: (state, { payload }) => {
            state.isEmpty = payload;
        },
        resetData: () => INITIAL_STATE,
    },
});

export default reducer;
/**
 * Selectors
 */
export const selectReports: TSelector<TReport[]> = ({ reports: { list } }) =>
    list;
export const selectFilter: TSelector<TFilter> = ({ reports: { filter } }) =>
    filter;
export const selectSearch: TSelector<string | undefined> = ({
    reports: { search },
}) => search;
export const selectInProgress: TSelector<boolean> = ({
    reports: { inProgress },
}) => inProgress;
export const selectIsInitialized: TSelector<boolean> = ({
    reports: { isInitialized },
}) => isInitialized;
export const selectIsEmpty: TSelector<boolean> = ({ reports: { isEmpty } }) =>
    isEmpty;
/**
 * Actions
 */
export const {
    saveList,
    resetData,
    saveFilter,
    setIsEmpty,
    saveSearch,
    setInProgress,
} = actions;
/**
 * Dispatchers
 */
export const handleGetReports =
    (projectId: string) =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const search = selectSearch(getState());
        const filter = selectFilter(getState());
        const isEmpty = selectIsEmpty(getState());

        dispatch(setInProgress(true));

        const queryUrl = createQueryUrl(
            ENDPOINTS.reportsFiltered,
            {
                search,
                projectId,
                status: [ReportStatus.processing, ReportStatus.completed],
                sort: SortVariants.updatedAtDesc,
                ...filter,
            },
            { arrayFormat: 'repeat' }
        );

        const { data } = await api.request<PaginatedResponse<TReport[]>>(
            Methods.get,
            { url: queryUrl }
        );

        const nextIsEmptyState =
            data.length === 0 && !search && isEmptyObject(filter);

        if (nextIsEmptyState !== isEmpty) {
            dispatch(setIsEmpty(nextIsEmptyState));
        }

        dispatch(saveList(data));

        dispatch(setInProgress(false));
    };

export const handleDeleteReport =
    (reportId: string) =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const projectId = selectUserProjectId(getState());

        if (!projectId) return;

        const url = templateString(ENDPOINTS.singleReport, { reportId });

        await api.request(Methods.delete, { url });

        dispatch(handleGetReports(projectId));
    };

export const handleCreateReport =
    (formValues: TFormValues) =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const { creationType } = formValues;
        const projectId = selectUserProjectId(getState());

        if (!projectId) return;

        const endpoint = ENDPOINTS_BY_TYPE[creationType];
        const createRequestBody = CREATE_REQUEST_BODY_BY_TYPE[creationType];

        await api.request(Methods.post, {
            url: endpoint,
            data: createRequestBody(projectId, formValues),
        });

        dispatch(handleGetReports(projectId));
    };

export const handlePublishReport =
    (id: string) =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const projectId = selectUserProjectId(getState());

        if (!projectId) return;

        const url = templateString(ENDPOINTS.singleReportState, { id });

        const queryUrl = createQueryUrl(url, { state: ReportState.published });

        await api.request(Methods.put, { url: queryUrl });

        dispatch(handleGetReports(projectId));
    };
