/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useEffect, useState } from 'react';
/**
 * Hooks
 */
import { Link, Outlet, useLocation } from 'react-router-dom';
/**
 * Components
 */
import { HashLink } from './HashLink';
import { TermsAndConditionsModal } from 'components/modals/TermsAndConditions';
/**
 * Assets
 */
import './style.scss';
import css from './style.module.scss';
import epamLogo from './epamLogo.svg';
/**
 * Components
 */
import { LoginButton } from 'pages/Login/components';
/**
 * Utils
 */
import { showModal } from 'utils';
import { cx } from '@epam/uui-core';
/**
 * Types
 */
/**
 * Expo
 */
export const PublicPageContainer: React.FC = () => {
    const [isPageScrolled, setIsPageScrolled] = useState(
        Boolean(window.scrollY)
    );

    const { pathname } = useLocation();
    const handleShowTermsAndConditions = () => {
        showModal(TermsAndConditionsModal, {});
    };

    useEffect(() => {
        const scrollHandler = () => {
            setIsPageScrolled(Boolean(window.scrollY));
        };
        document.addEventListener('scroll', scrollHandler);

        return () => {
            document.removeEventListener('scroll', scrollHandler);
        };
    }, []);
    return (
        <>
            <header className={cx(css.header, isPageScrolled && css.small)}>
                <Link to="/" className={css.logo}>
                    <figure>
                        <img alt="Logo" src="/assets/images/header/logo.svg" />
                    </figure>
                </Link>
                <>
                    <nav className={css.headerNavigation}>
                        <ul className={css.navigationList}>
                            <li>
                                <HashLink
                                    to="/#solutions"
                                    caption="SOLUTIONS"
                                    dataTestId="home-nav-solutions"
                                />
                            </li>
                            <li>
                                <HashLink
                                    dataTestId="home-nav-how-it-works"
                                    to="/#how-it-works"
                                    caption="HOW IT WORKS"
                                />
                            </li>
                            <li>
                                <LoginButton />
                            </li>
                            <li>
                                <Link
                                    to="/about"
                                    className={cx(
                                        pathname.startsWith('/about') &&
                                            css.activeLink
                                    )}
                                >
                                    ABOUT
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <div className={css.buttonGroup}>
                        <HashLink
                            to="/#request-demo"
                            caption="GET STARTED"
                            dataTestId="home-nav-get-started"
                            className={css.button}
                        />
                    </div>
                </>
            </header>
            <main className={css.main}>
                <Outlet />
            </main>
            <footer className={css.footer}>
                <aside>
                    <p>
                        © {new Date().getFullYear()} EPAM Systems, Inc, All
                        Rights Reserved.
                    </p>
                    <p className={css['power-by']}>
                        <span>Powered by</span>
                        <img src={epamLogo} alt="EPAM logo" />
                    </p>
                </aside>
                <nav className={css.footerNavigation}>
                    <ul className={css.navigationList}>
                        <li>
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://www.epam.com/privacy-policy"
                            >
                                PRIVACY POLICY
                            </a>
                        </li>
                        <li>
                            <button
                                type="button"
                                onClick={handleShowTermsAndConditions}
                            >
                                TERMS AND CONDITIONS
                            </button>
                        </li>
                    </ul>
                </nav>
            </footer>
        </>
    );
};
