/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { RoleTypes } from 'constants/roles';
/**
 * Expo
 */
export const SECURITY_DELIVERY_CONSOLE_ROLES = [
    RoleTypes.salesManager,
    RoleTypes.operationsManager,
    RoleTypes.projectRepresentative,
];

export const AGILE_SECURITY_PLATFORM_ROLES = [
    RoleTypes.admin,
    RoleTypes.operationsManager,
    RoleTypes.securitySpecialist,
    RoleTypes.projectRepresentative,
];
