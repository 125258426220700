/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { ReportCreationForm } from '../Form';
import { Modal as ModalComponent } from 'components/common';
/**
 * Constants
 */
import { ModalSizes } from 'constants/modals';
/**
 * Types
 */
import { ModalProps } from './types';
/**
 * Expo
 */
export const Modal: React.FC<ModalProps> = ({
    title,
    onSubmit,
    creationType,
    dataTestId,
    ...props
}) => {
    return (
        <ModalComponent
            {...props}
            withoutFooter
            allowCloseOnCross={false}
            title={title}
            width={ModalSizes.large}
            dataTestId={dataTestId}
            renderBody={({ success, abort }) => (
                <ReportCreationForm
                    abort={abort}
                    success={success}
                    onSubmit={onSubmit}
                    creationType={creationType}
                    dataTestId={dataTestId}
                />
            )}
        />
    );
};
