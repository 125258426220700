/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { createSelector } from '@reduxjs/toolkit';
import { TrustBoundaryFormValues } from 'pages/ThreatModeling/tabs/ModelTreeTab/components/ExpandableTree/components/ItemEditor/forms/TrustBoundary/schema';
import { TSelector, RootState } from 'types';
import { transformTrustBoundaryOption } from '../../../pages/ThreatModeling/tabs/ModelTreeTab/components/ExpandableTree/components/ItemEditor/forms/TrustBoundary/utils';

export const selectTrustBoundaries = (state: RootState) =>
    state.threatModeling.trustBoundaries.list;

export const selectTrustBoundariesCount: TSelector<number> = ({
    threatModeling: {
        trustBoundaries: { list },
    },
}) => list.length;

const selectId = (state: RootState, id: TrustBoundaryFormValues['id']) => id;

export const selectTrustBoundaryParentOptions = createSelector(
    [selectTrustBoundaries, selectId],
    (trustBoundaries, id) =>
        trustBoundaries
            .filter((trustBoundary) => trustBoundary.id !== id)
            .map(transformTrustBoundaryOption)
);

export const selectTrustBoundaryOptions = (state: RootState) =>
    state.threatModeling.trustBoundaries.list.map(transformTrustBoundaryOption);

export const selectTrustBoundaryTypes = (state: RootState) =>
    state.threatModeling.trustBoundaries.types;
