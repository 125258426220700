/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
/**
 * Components
 */
import { FlexCell, FlexSpacer } from '@epam/promo';
import { CreationButton } from '../CreationButton';
import { Text, FlexRow, SearchInput } from 'components/common';
/**
 * Types
 */
import { Props } from './types';
/**
 * Store
 */
import { selectSearch } from 'store/reports';
/**
 * Expo
 */
export const Header: React.FC<Props> = ({
    isEmpty,
    onSearch,
    onAddReport,
    isSecuritySpecialist,
}) => {
    const search = useSelector(selectSearch);

    return (
        <FlexRow>
            <Text
                cx="text-bold text-white text-size-30"
                dataTestId="reports/title"
            >
                Reports
            </Text>
            <FlexSpacer />
            <FlexRow columnGap="12">
                {!isEmpty && (
                    <FlexCell minWidth={600}>
                        <SearchInput
                            value={search}
                            onValueChange={onSearch}
                            dataTestId="reports/search"
                            placeholder="Search by name"
                        />
                    </FlexCell>
                )}
                {isSecuritySpecialist && (
                    <FlexCell>
                        <CreationButton onClick={onAddReport} />
                    </FlexCell>
                )}
            </FlexRow>
        </FlexRow>
    );
};
