/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import {
    Panel,
    Text,
    Status,
    LinkButton,
    IconContainer,
} from 'components/common';
import { ConfirmationModal } from 'components/modals';
import { FlexRow, FlexSpacer, Tooltip } from '@epam/promo';
/**
 * Assets
 */
import { ReactComponent as iconInfo } from '@epam/assets/icons/common/notification-info-outline-18.svg';
/**
 * Constants
 */
import { StatusTypes } from 'constants/statuses';
/**
 * Utils
 */
import { showModal } from 'utils';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { LegacyLargeCardProps } from './types';
import { ConfirmationModalProps } from 'components/modals/Confirmation';
/**
 * Expo
 */
export const LegacyLarge: React.FC<LegacyLargeCardProps> = ({
    name,
    status,
    className,
    dataTestId,
    information,
    onDelete,
    renderContent,
    renderInformation,
}) => {
    const handleDelete = () => {
        showModal<ConfirmationModalProps>(ConfirmationModal, {
            text: 'Are you sure?',
            onSuccess: onDelete,
        });
    };

    return (
        <Panel
            background="white"
            shadow
            cx={className}
            dataTestId={`${dataTestId}/cardContainer`}
        >
            <FlexRow cx={css.headerCardContainer} borderBottom columnGap="12">
                <Text
                    cx={css.headerCardTitle}
                    fontSize="24"
                    dataTestId={`${dataTestId}/card/title`}
                >
                    {name}
                </Text>
                <Status
                    fill="solid"
                    type={status}
                    dataTestId={`${dataTestId}/card/status`}
                />
                {information && status === StatusTypes.rejected ? (
                    <Tooltip content={information}>
                        <IconContainer
                            style={{ fill: 'var(--uui-neutral-50)' }}
                            icon={iconInfo}
                            cx={css.informationIcon}
                            dataTestId={`${dataTestId}/card/information`}
                        />
                    </Tooltip>
                ) : null}
                <FlexSpacer />
                {renderInformation ? (
                    renderInformation()
                ) : (
                    <LinkButton
                        onClick={handleDelete}
                        caption="Delete"
                        size="36"
                    />
                )}
            </FlexRow>
            <FlexRow padding="18" vPadding="18">
                {renderContent?.()}
            </FlexRow>
        </Panel>
    );
};
