/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { memo, useCallback, useMemo } from 'react';
/**
 * Components
 */
import { CheckboxGroup as UuiCheckboxGroup, LabeledInput } from '@epam/promo';
/**
 * Utils
 */
import { getRawProps } from 'utils';
/**
 * Types
 */
import { TCheckboxGroupProps } from './types';
/**
 * Expo
 */
export const CheckboxGroup: React.FC<TCheckboxGroupProps> = memo(
    ({
        label,
        items,
        isInvalid,
        value = [],
        isOptional,
        isRequired,
        dataTestId,
        validationMessage,
        direction = 'vertical',
        onValueChange,
    }) => {
        const rawProps = useMemo(
            () => getRawProps({ dataTestId }),
            [dataTestId]
        );

        const handleValueChange = useCallback(
            (changedValue: string[]) => {
                if (onValueChange) onValueChange(changedValue);
            },
            [onValueChange]
        );

        return (
            <LabeledInput
                label={label}
                isInvalid={isInvalid}
                isRequired={isRequired}
                isOptional={isOptional}
                validationMessage={validationMessage}
            >
                <UuiCheckboxGroup
                    value={value}
                    items={items}
                    rawProps={rawProps}
                    isInvalid={isInvalid}
                    direction={direction}
                    isRequired={isRequired}
                    onValueChange={handleValueChange}
                />
            </LabeledInput>
        );
    }
);
