/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import type {
    GridSelection,
    LexicalEditor,
    NodeKey,
    NodeSelection,
    RangeSelection,
} from 'lexical';

import './ImageNode.css';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import { mergeRegister } from '@lexical/utils';
import {
    $getNodeByKey,
    $getSelection,
    $isNodeSelection,
    CLICK_COMMAND,
    COMMAND_PRIORITY_LOW,
    DRAGSTART_COMMAND,
    KEY_BACKSPACE_COMMAND,
    KEY_DELETE_COMMAND,
    SELECTION_CHANGE_COMMAND,
} from 'lexical';
import * as React from 'react';
import { Suspense, useCallback, useEffect, useRef, useState } from 'react';

import ImageResizer from './ImageResizer';
// eslint-disable-next-line import/no-cycle
import { $isImageNode } from './ImageNode';
import { requestAttachedImage } from '../../utils';
import { convertToBase64 } from 'utils';

const imageCache = new Set();

function useSuspenseImage(src: string) {
    if (!imageCache.has(src)) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw new Promise((resolve) => {
            const img = new Image();
            img.src = src;
            img.onload = () => {
                imageCache.add(src);
                resolve(null);
            };
        });
    }
}

interface LazyImageProps {
    altText: string;
    className: string | null;
    height: 'inherit' | number;
    imageRef: { current: null | HTMLImageElement };
    maxWidth?: number;
    src: string;
    width: 'inherit' | number;
}
function LazyImage({
    altText,
    className,
    imageRef,
    src,
    width,
    height,
    maxWidth,
}: LazyImageProps): JSX.Element {
    useSuspenseImage(src);
    return (
        <img
            className={className || undefined}
            src={src}
            alt={altText}
            ref={imageRef}
            style={{
                height,
                maxWidth,
                width,
            }}
            draggable="false"
        />
    );
}

export interface ImageComponentProps {
    altText: string;
    height: 'inherit' | number;
    maxWidth?: number;
    nodeKey: NodeKey;
    resizable: boolean;
    src: string | null;
    setNodeSrc: (src: string) => void;
    imageId: string | null;
    width: 'inherit' | number;
}
export function ImageComponent({
    src,
    altText,
    imageId,
    nodeKey,
    width,
    height,
    maxWidth,
    setNodeSrc,
    resizable,
}: ImageComponentProps): JSX.Element {
    const imageRef = useRef<null | HTMLImageElement>(null);
    const [isSelected, setSelected, clearSelection] =
        useLexicalNodeSelection(nodeKey);
    const [isResizing, setIsResizing] = useState<boolean>(false);
    const [editor] = useLexicalComposerContext();
    const [selection, setSelection] = useState<
        RangeSelection | NodeSelection | GridSelection | null
    >(null);
    const [dataSrc, setDataSrc] = useState(src);
    const activeEditorRef = useRef<LexicalEditor | null>(null);

    const onDelete = useCallback(
        (payload: KeyboardEvent) => {
            if (isSelected && $isNodeSelection($getSelection())) {
                const event: KeyboardEvent = payload;
                event.preventDefault();
                const node = $getNodeByKey(nodeKey);
                if ($isImageNode(node)) {
                    node.remove();
                }
            }
            return false;
        },
        [isSelected, nodeKey]
    );

    useEffect(() => {
        if (src === null) {
            requestAttachedImage(imageId as string)
                .then(convertToBase64)
                .then((data) => {
                    if (typeof data === 'string') {
                        setDataSrc(data);
                        setNodeSrc(data);
                    }
                });
        }
    }, [src, imageId, setNodeSrc]);

    useEffect(() => {
        let isMounted = true;
        const unregister = mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                if (isMounted) {
                    setSelection(editorState.read(() => $getSelection()));
                }
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_, activeEditor) => {
                    activeEditorRef.current = activeEditor;
                    return false;
                },
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand<MouseEvent>(
                CLICK_COMMAND,
                (payload) => {
                    const event = payload;

                    if (isResizing) {
                        return true;
                    }
                    if (event.target === imageRef.current) {
                        if (event.shiftKey) {
                            setSelected(!isSelected);
                        } else {
                            clearSelection();
                            setSelected(true);
                        }
                        return true;
                    }

                    return false;
                },
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                DRAGSTART_COMMAND,
                (event) => {
                    if (event.target === imageRef.current) {
                        // TODO This is just a temporary workaround for FF to behave like other browsers.
                        // Ideally, this handles drag & drop too (and all browsers).
                        event.preventDefault();
                        return true;
                    }
                    return false;
                },
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                KEY_DELETE_COMMAND,
                onDelete,
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                KEY_BACKSPACE_COMMAND,
                onDelete,
                COMMAND_PRIORITY_LOW
            )
        );
        return () => {
            isMounted = false;
            unregister();
        };
    }, [
        clearSelection,
        editor,
        isResizing,
        isSelected,
        nodeKey,
        onDelete,
        setSelected,
    ]);

    const onResizeEnd = (
        nextWidth: 'inherit' | number,
        nextHeight: 'inherit' | number
    ) => {
        // Delay hiding the resize bars for click case
        setTimeout(() => {
            setIsResizing(false);
        }, 200);

        editor.update(() => {
            const node = $getNodeByKey(nodeKey);
            if ($isImageNode(node)) {
                node.setWidthAndHeight(nextWidth, nextHeight);
            }
        });
    };

    const onResizeStart = () => {
        setIsResizing(true);
    };

    const draggable = isSelected && $isNodeSelection(selection) && !isResizing;
    const isFocused = isSelected || isResizing;
    return (
        <Suspense fallback={null}>
            {dataSrc && (
                <>
                    <div draggable={draggable}>
                        <LazyImage
                            className={
                                isFocused
                                    ? `focused ${
                                          $isNodeSelection(selection)
                                              ? 'draggable'
                                              : ''
                                      }`
                                    : null
                            }
                            src={dataSrc}
                            altText={altText}
                            imageRef={imageRef}
                            width={width}
                            height={height}
                            maxWidth={maxWidth}
                        />
                    </div>
                    {resizable && $isNodeSelection(selection) && isFocused && (
                        <ImageResizer
                            editor={editor}
                            imageRef={imageRef}
                            maxWidth={maxWidth}
                            onResizeStart={onResizeStart}
                            onResizeEnd={onResizeEnd}
                        />
                    )}
                </>
            )}
        </Suspense>
    );
}
