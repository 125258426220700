/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Badge } from '../Badge';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Constants
 */
import { ColorNames } from 'constants/colors';
/**
 * Types
 */
import { TVulnerabilityBadgeProps } from './types';
/**
 * Expo
 */
export const VulnerabilityBadge = ({
    caption,
}: TVulnerabilityBadgeProps): JSX.Element => (
    <Badge
        cx={css.vulnerabilityBadge}
        fill="solid"
        caption={caption.toUpperCase()}
        color={ColorNames.gray10}
    />
);
