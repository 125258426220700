/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createSlice } from '@reduxjs/toolkit';
/**
 * Constants
 */
import { INITIAL_STATE } from './constants';
/**
 * Expo
 */
export const { reducer, actions } = createSlice({
    name: 'singleRequest',
    initialState: INITIAL_STATE,
    reducers: {
        resetData: () => INITIAL_STATE,
        saveData: (
            state,
            {
                payload: {
                    request_date,
                    sales_manager,
                    request_number,
                    created_at,
                    created_by,
                    operation_manager,
                    previous_status,
                    updated_at,
                    updated_by,
                    ...data
                },
            }
        ) => {
            state.data = {
                ...data,
                requestDate: request_date,
                salesManager: sales_manager,
                requestNumber: request_number,
                createdAt: created_at,
                createdBy: created_by,
                operationManager: operation_manager,
                previousStatus: previous_status,
                updatedAt: updated_at,
                updatedBy: updated_by,
            };

            state.isInitialized = true;
        },
        saveSteps: (state, { payload }) => {
            state.stepsData = payload;
        },
        checkStep: (state, { payload: { stepId, isChecked } }) => {
            state.stepsData.totalCheckedCount += isChecked ? 1 : -1;

            if (state.stepsData.stepsById) {
                state.stepsData.stepsById[stepId].checked = isChecked;
            }
        },
        changeStatus: (
            state,
            { payload: { currentStatus, previousStatus } }
        ) => {
            state.data.status = currentStatus;
            state.data.previousStatus = previousStatus;
        },
        changeDocumentLink: (state, { payload: { stepId, documentLink } }) => {
            if (state.stepsData.stepsById) {
                state.stepsData.stepsById[stepId].document_link = documentLink;
            }
        },
        setInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        changeSalesManager: (state, { payload }) => {
            state.data.salesManager = payload;
        },
        changeOperationsManager: (state, { payload }) => {
            state.data.operationManager = payload;
        },
        addPendingMessage: (state, { payload: { type, pendingMessage } }) => {
            state.messageChannels[type].pendingMessages.unshift(pendingMessage);
        },
        addMessage: (state, { payload: { type, message, pendingMessage } }) => {
            const { messages, pendingMessages } = state.messageChannels[type];

            messages.unshift(message);

            if (pendingMessage) {
                const index = pendingMessages.findIndex(
                    (item) => item.id === pendingMessage.id
                );

                if (index !== -1) {
                    pendingMessages.splice(index, 1);
                }
            }
        },
        saveMessages: (state, { payload: { type, messages } }) => {
            state.messageChannels[type] = {
                messages,
                pendingMessages: [],
            };
        },
    },
});
