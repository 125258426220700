/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useState } from 'react';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
/**
 * Components
 */
import { Blocker, Modal } from 'components/common';
/**
 * Types
 */
import { IModal } from '@epam/uui-core';
import { WithDataTestId } from 'types';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
/**
 * Utils
 */
import { showNotification, templateString } from 'utils';
/**
 * Store
 */
import { selectCurrentProjectId } from 'store/project';
/**
 * Expo
 */
export const RequestAssessmentModal: React.FC<
    IModal<string> & WithDataTestId
> = ({ dataTestId, ...props }) => {
    const [inProgress, setInProgress] = useState(false);

    const projectId = useSelector(selectCurrentProjectId);

    const handleSuccess = async () => {
        setInProgress(true);

        try {
            const url = templateString(ENDPOINTS.requestAssessment, {
                projectId,
            });

            await api.request(Methods.post, {
                url,
            });

            showNotification({
                text: 'The Security team will contact you shortly to discuss the new assessment.',
            });
        } finally {
            setInProgress(false);
        }
    };

    return (
        <>
            <Blocker isEnabled={inProgress} />
            <Modal
                {...props}
                dataTestId="request-assessment-modal"
                title="Request new assessment"
                onSuccess={handleSuccess}
                cancelButtonCaption="Cancel"
                successButtonCaption="Submit"
                bodyText="Would you like to request another assessment?"
            />
        </>
    );
};
