/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { forwardRef } from 'react';
import { cx, IDropdownToggler } from '@epam/uui-core';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
/**
 * Components
 */
import { Text, FlexRow } from '@epam/promo';
import { Panel, IconContainer } from 'components/common';
/**
 * Store
 */
import { selectHeaderInformation } from 'store/user';
/**
 * Assets
 */
import css from './style.module.scss';
import { ReactComponent as rightArrowIcon } from '@epam/assets/icons/common/navigation-chevron-right-18.svg';
import { ReactComponent as bottomArrowIcon } from '@epam/assets/icons/common/navigation-chevron-down-18.svg';
/**
 * Expo
 */
export const DropdownTarget: React.FC<IDropdownToggler> = forwardRef(
    (props, ref) => {
        const { pathname } = useLocation();
        const { projectName, organizationName } = useSelector(
            selectHeaderInformation
        );

        const isOrganizationPage = pathname.includes('/organization/');

        return (
            <Panel
                {...props}
                ref={ref}
                cx={css.container}
                dataTestId="header/chooseProjectByOrganization"
            >
                <FlexRow>
                    <div>
                        <Text fontSize="10">Organization</Text>
                        <div className={css.nameContainer}>
                            <Text
                                cx={cx(css.projectName, 'text-bold')}
                                fontSize="16"
                            >
                                {organizationName}
                            </Text>
                        </div>
                    </div>
                    {!isOrganizationPage && (
                        <>
                            <IconContainer
                                cx={css.arrowIcon}
                                icon={rightArrowIcon}
                            />
                            <div>
                                <Text fontSize="10">Project</Text>
                                <div className={css.nameContainer}>
                                    <Text
                                        cx={cx(css.projectName, 'text-bold')}
                                        fontSize="16"
                                    >
                                        {projectName}
                                    </Text>
                                </div>
                            </div>
                        </>
                    )}
                    <IconContainer
                        cx={css.arrowIcon}
                        icon={bottomArrowIcon}
                        dataTestId="header/chooseProject"
                    />
                </FlexRow>
            </Panel>
        );
    }
);
