/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { useMemo } from 'react';
/**
 * Utils
 */
import { AsyncDataSource, IDataSource } from '@epam/uui-core';
import { getDataSourceConfig } from './utils';
/**
 * Expo
 */

interface AsyncSelectProps<T> {
    idKey?: keyof T;
    url: string;
    filter?: (item: T) => boolean;
    additionalOptions?: T[];
}

export function useDataSource<T>({
    url,
    idKey,
    filter,
    additionalOptions,
}: AsyncSelectProps<T>): IDataSource<T, T[keyof T], unknown> {
    const dataSourceConfig = useMemo(
        () => getDataSourceConfig(url, idKey, filter, additionalOptions),
        [url, idKey, filter, additionalOptions]
    );

    const dataSource = useMemo(
        () => new AsyncDataSource<T>(dataSourceConfig),
        [dataSourceConfig]
    );

    return dataSource;
}
