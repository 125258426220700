/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { forwardRef, useMemo } from 'react';
/**
 * Components
 */
import { FlexRow as UuiFlexRow } from '@epam/promo';
/**
 * Types
 */
import { WithDataTestId } from 'types';
import type { ComponentProps } from 'react';
/**
 * Utils
 */
import { getRawProps } from 'utils';
/**
 * Expo
 */

export type TFlexRowProps = ComponentProps<typeof UuiFlexRow> & WithDataTestId;

export const FlexRow: React.FC<TFlexRowProps> = forwardRef(
    ({ dataTestId, rawProps, ...props }, ref) => {
        const modifiedRawProps = useMemo(
            () => Object.assign(getRawProps({ dataTestId }), rawProps),
            [dataTestId, rawProps]
        );
        return <UuiFlexRow {...props} ref={ref} rawProps={modifiedRawProps} />;
    }
);
