/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { ZodNamedTmDto } from 'pages/ThreatModeling/tabs/ModelTreeTab/components/ExpandableTree/components/ItemEditor/forms/constants';
import { TElement } from 'types';

export function transformElementOption(value: TElement): ZodNamedTmDto {
    return {
        ...value,
        // To make form work, since schema uses camelCase
        readableId: value.readable_id,
    };
}
