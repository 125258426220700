/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Expo
 */
export enum ReportTypes {
    withLink = 'LINK',
    withFiles = 'FILES',
    withDocxFile = 'UPLOADED_DOCX',
    docx = 'CONFIGURED_DOCX',
    pdf = 'CONFIGURED_PDF',
}

export enum ReportState {
    published = 'PUBLISHED',
    draft = 'DRAFT',
}

export enum ReportsNavigationType {
    pdf = 'pdf',
    docx = 'docx',
}

export enum ReportStatus {
    completed = 'COMPLETED',
    processing = 'PROCESSING',
    failed = 'FAILED',
}
