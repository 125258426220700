/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, useCallback } from 'react';
/**
 * Hooks
 */
import { useRouteMatchParams } from 'hooks';
/**
 * Components
 */
import { RichEditor2 } from 'components/common';
/**
 * Types
 */
import { TRichEditorValueWithImages } from './types';
import { FieldProps, FieldValueProps, WithDataTestId } from 'types';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
import { ReportsNavigationType } from 'constants/reports';
import { TContentImages } from 'components/RichEditor/types';
/**
 * Expo
 */
export const Component: FC<
    FieldValueProps<TRichEditorValueWithImages> & FieldProps & WithDataTestId
> = ({ value, onValueChange, ...props }) => {
    const { reportType } = useRouteMatchParams(PAGES.reportWithType);
    const isPdfReport = reportType === ReportsNavigationType.pdf;

    const handleChange = useCallback(
        (html: string, contentImages: TContentImages) => {
            let currentValue = { html };

            if (isPdfReport) {
                currentValue = { ...currentValue, ...contentImages };
            }

            onValueChange(currentValue);
        },
        [onValueChange, isPdfReport]
    );

    return (
        <RichEditor2
            {...props}
            initialValue={value.html}
            withInlineImages={isPdfReport}
            pastedImages={value.pastedImages}
            onChange={handleChange}
        />
    );
};
