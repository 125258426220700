/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useState } from 'react';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useRedirect } from 'hooks';
/**
 * Components
 */
import { ScrollBars } from '@epam/promo';
import { Panel } from 'components/common';
import { OrganizationItem } from '../OrganizationItem';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
import { Tabs } from 'pages/SingleProject/constants';
/**
 * Types
 */
import { TDropdownBodyProps } from './types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Store
 */
import { handleSetCurrentProject, selectAllOrganizations } from 'store/user';
/**
 * Utils
 */
import { getRedirectUrl } from './utils';
import { THandleChooseProject } from '../OrganizationItem/types';
import { none } from 'utils';

/**
 * Expo
 */
export const DropdownBody: React.FC<TDropdownBodyProps> = ({ onClose }) => {
    const [activeOrganization, setHoveredOrganization] = useState<string>();

    const { pathname } = useLocation();

    const redirect = useRedirect();
    const dispatch = useAppDispatch();

    const data = useSelector(selectAllOrganizations);

    const handleChooseProject: THandleChooseProject = useCallback(
        async (projectId) => {
            const isMatchPath =
                pathname.includes(Tabs.findings) ||
                pathname.includes(Tabs.tasks);

            const redirectUrl = isMatchPath
                ? PAGES.dashboard
                : getRedirectUrl(pathname);

            await dispatch(handleSetCurrentProject(projectId));

            redirect(redirectUrl, { projectId });
            onClose?.();
        },
        [pathname, dispatch, redirect, onClose]
    );

    return (
        <Panel
            shadow
            cx={css.panel}
            background="white"
            dataTestId="header/chooseProjectByOrganization/organizations"
        >
            <ScrollBars>
                {data?.map(({ id, ...item }) => (
                    <OrganizationItem
                        key={id}
                        {...item}
                        id={id}
                        isHovered={activeOrganization === id}
                        onChooseProject={handleChooseProject}
                        onHoverOrganization={setHoveredOrganization}
                        onChooseOrganization={none}
                    />
                ))}
            </ScrollBars>
        </Panel>
    );
};
