/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC } from 'react';
/**
 * Components
 */
import { FlexRow, Text, UserAvatar } from 'components/common';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { WithDataTestId } from 'types';
import { TUserBlockProps } from './types';
/**
 * Utils
 */
import { cx } from '@epam/uui-core';
/**
 * Expo
 */
export const UserBlock: FC<TUserBlockProps & WithDataTestId> = ({
    text,
    dataTestId,
}) => (
    <FlexRow cx={css.userContainer} dataTestId={`${dataTestId}/user`}>
        <UserAvatar
            size="18"
            name={text}
            className={css.userAvatar}
            dataTestId={`${dataTestId}/user/avatar`}
        />
        <Text
            size="18"
            fontSize="14"
            lineHeight="18"
            cx={cx('text-bold', css.userName)}
            dataTestId={`${dataTestId}/user/name`}
        >
            {text}
        </Text>
    </FlexRow>
);
