/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { ReportState } from 'constants/reports';
/**
 * Types
 */
import { TFormValues } from './types';
/**
 * Utils
 */
import { NotificationTypes, showNotification } from 'utils';
/**
 * Expo
 */
export const showReportNotificationByState = ({
    title,
    state,
}: TFormValues): void => {
    const titleNotification = {
        [ReportState.draft]: `Report "${title}" saved as draft`,
        [ReportState.published]: `Report "${title}" published`,
    };

    showNotification({
        text: titleNotification[state],
        type: NotificationTypes.success,
    });
};
