/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { memo } from 'react';
/**
 * Hooks
 */
import { useRole, useRouteParams } from 'hooks';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
/**
 * Components
 */
import { FlexRow } from '@epam/promo';
import { LinkButton, Text } from 'components/common';
/**
 * Types
 */
import { FindingsProps } from './types';
/**
 * Utils
 */
import qs from 'qs';
import { generatePath } from 'utils';
/**
 * Expo
 */
export const Findings: React.FC<FindingsProps> = memo(
    ({ assessmentId, count = 0, dataTestId }) => {
        const { projectId } = useRouteParams();
        const { isOperationsManager } = useRole();

        const href = generatePath(PAGES.findings, {
            projectId,
        });
        const query = qs.stringify({ assessmentId }, { addQueryPrefix: true });

        return (
            <FlexRow>
                {!count || isOperationsManager ? (
                    <Text dataTestId={dataTestId}>{count}</Text>
                ) : (
                    <LinkButton
                        dataTestId={dataTestId}
                        href={href + query}
                        caption={String(count)}
                    />
                )}
            </FlexRow>
        );
    }
);
