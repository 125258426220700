/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createSelector } from '@reduxjs/toolkit';
/**
 * Types
 */
import { TSelector } from 'types';
import { TInitialState } from './types';
/**
 * Expo
 */
export const selectPolicy: TSelector<TInitialState> = ({ singlePolicy }) =>
    singlePolicy;

export const selectData = createSelector(selectPolicy, ({ data }) => data);

export const selectInProgress = createSelector(
    selectPolicy,
    ({ inProgress }) => inProgress
);

export const selectIsInitialized = createSelector(
    selectPolicy,
    ({ isInitialized }) => isInitialized
);
