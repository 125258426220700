/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Panel, FlexRow, FlexCell } from 'components/common';
import { Text } from '@epam/promo';
/**
 * Types
 */
import { TEmptyProps } from './types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Utils
 */
import { cx } from '@epam/uui-core';
/**
 * Expo
 */
export const EmptyViewPolicy: React.FC<TEmptyProps> = ({
    src,
    text,
    alt = '',
    background,
    dataTestId,
    textClassName,
}) => (
    <Panel
        cx={css.viewContainer}
        dataTestId={dataTestId}
        background={background}
    >
        <FlexCell grow={0} alignSelf="center" textAlign="center">
            <FlexCell
                cx={cx(css.text, textClassName)}
                alignSelf="center"
                textAlign="center"
                dataTestId={`${dataTestId}/text`}
            >
                <Text color="gray60" fontSize="14" lineHeight="24">
                    {text}
                </Text>
            </FlexCell>
            <FlexRow
                cx={css.imageContainer}
                dataTestId={`${dataTestId}/picture`}
            >
                <img alt={alt} src={src} />
            </FlexRow>
        </FlexCell>
    </Panel>
);
