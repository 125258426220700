/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useMemo } from 'react';
/**
 * Hooks
 */
import { useRole } from 'hooks';
import { useLocation } from 'react-router-dom';
/**
 * Components
 */
import { MainMenuButton } from 'components/common';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
/**
 * Store
 */
import { AdaptiveItemProps } from '@epam/uui-components';

/**
 * Expo
 */
export const useSecurityHeader = (): Array<AdaptiveItemProps> => {
    const { pathname } = useLocation();
    const { isOperationsManager, isSalesManager, isProjectRepresentative } =
        useRole();

    const shouldDisplayRequestsButton = useMemo(
        () => isSalesManager || isOperationsManager || isProjectRepresentative,
        [isSalesManager, isOperationsManager, isProjectRepresentative]
    );

    return shouldDisplayRequestsButton
        ? [
              {
                  id: 'requests',
                  priority: 2,
                  render: () => (
                      <MainMenuButton
                          showInBurgerMenu
                          caption="Requests"
                          href={PAGES.requests}
                          dataTestId="header/menu/requests"
                          isLinkActive={pathname.includes(PAGES.requests)}
                      />
                  ),
              },
          ]
        : [];
};
