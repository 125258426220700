/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
/**
 * Hooks
 */
/**
 * Components
 */
import { PrivateRoute } from '../PrivateRoute';
/**
 * Constants
 */
import { RoleTypes } from 'constants/roles';
/**
 * Store
 */

/**
 * Pages
 */
const Requests = lazy(() => import('pages/Requests'));
const SingleRequest = lazy(() => import('pages/SingleRequest'));
/**
 * Expo
 */
export const SecurityDeliveryConsole: React.FC = () => {
    const roles = [
        RoleTypes.salesManager,
        RoleTypes.projectRepresentative,
        RoleTypes.operationsManager,
    ];

    return (
        <Suspense fallback={null}>
            <Routes>
                <Route
                    path="requests"
                    element={
                        <PrivateRoute component={Requests} roles={roles} />
                    }
                />
                <Route
                    path="requests/:requestId"
                    element={
                        <PrivateRoute component={SingleRequest} roles={roles} />
                    }
                />
            </Routes>
        </Suspense>
    );
};
