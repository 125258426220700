/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import React, { FC } from 'react';
import { ToolbarButton } from './ToolbarButton';
import { ReactComponent as italicIcon } from '@epam/assets/icons/common/editor-format_italic-24.svg';
import { ReactComponent as textColorIcon } from '@epam/assets/icons/common/editor-format_text_color-24.svg';
import { ReactComponent as boldIcon } from '@epam/assets/icons/common/editor-format_bold-24.svg';
import { ReactComponent as underlineIcon } from '@epam/assets/icons/common/editor-format_underlined-24.svg';
import { ReactComponent as linkIcon } from '@epam/assets/icons/common/content-link-18.svg';
import {
    $getSelection,
    $isRangeSelection,
    FORMAT_TEXT_COMMAND,
    LexicalEditor,
    RangeSelection,
} from 'lexical';
import { EDIT_LINK_COMMAND } from '../../LinkEditorPlugin';
import {
    $getSelectionStyleValueForProperty,
    $patchStyleText,
} from '@lexical/selection';
import { Highlighting } from '../../../enums';

export enum InlineStyle {
    bold = 'bold',
    italic = 'italic',
    underline = 'underline',
}

interface InlineControlProps {
    isActive: boolean;
    style: InlineStyle;
    editor: LexicalEditor;
}

const iconToStyleMap = {
    [InlineStyle.bold]: boldIcon,
    [InlineStyle.italic]: italicIcon,
    [InlineStyle.underline]: underlineIcon,
};

export const InlineControl: FC<InlineControlProps> = ({
    isActive,
    style,
    editor,
}) => {
    const handleClick = () => {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, style);
    };
    return (
        <ToolbarButton
            icon={iconToStyleMap[style]}
            style={style}
            onClick={handleClick}
            isActive={isActive}
        />
    );
};

export const isHighlighted = (selection: RangeSelection) =>
    $getSelectionStyleValueForProperty(
        selection,
        'background-color',
        'plain'
    ) !== 'plain';

export interface HighlightControlProps {
    isActive: boolean;
    editor: LexicalEditor;
}

export const HighlightControl: FC<HighlightControlProps> = ({
    isActive,
    editor,
}) => {
    const handleClick = () => {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                $patchStyleText(selection, {
                    'background-color': isActive ? null : Highlighting.Color,
                });
            }
        });
    };
    return (
        <ToolbarButton
            icon={textColorIcon}
            onClick={handleClick}
            isActive={isActive}
        />
    );
};

export interface LinkControlProps {
    editor: LexicalEditor;
    isActive: boolean;
}

export const LinkControl: FC<LinkControlProps> = ({ editor, isActive }) => {
    const handleClick = () => {
        editor.dispatchCommand(EDIT_LINK_COMMAND, undefined);
    };

    return (
        <ToolbarButton
            icon={linkIcon}
            onClick={handleClick}
            isActive={isActive}
        />
    );
};
