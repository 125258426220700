/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { TOption, TTrackerIssues } from './types';
/**
 * Expo
 */
export const INITIAL_STATE: TTrackerIssues = {
    inProgress: false,
    isTrackerIssueRequested: false,
    isInitialized: false,
    manualTasks: { idList: [], tasksById: {} },
    trackerTasks: { idList: [], tasksById: {} },
    filter: {
        options: [],
        totalCount: 0,
    },
};

export const PARENT_IDS = {
    sprints: 'sprints',
    releases: 'releases',
};

export const PARENT_OPTIONS: Record<string, TOption> = {
    releases: {
        name: 'Release',
        id: PARENT_IDS.releases,
    },
    sprints: {
        name: 'Sprint',
        id: PARENT_IDS.sprints,
    },
};

export const QUERY_PARAMETERS = {
    [PARENT_IDS.sprints]: 'sprint_ids',
    [PARENT_IDS.releases]: 'release_ids',
};

export const DEFAULT_ID_LISTS: Record<string, string[]> = {
    [QUERY_PARAMETERS[PARENT_IDS.sprints]]: [],
    [QUERY_PARAMETERS[PARENT_IDS.releases]]: [],
};
