/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { createSelector } from '@reduxjs/toolkit';
import { transformElementOption } from './utils';
import { TElement, TSelector } from 'types';
import { ZodNamedTmDto } from '../../../pages/ThreatModeling/tabs/ModelTreeTab/components/ExpandableTree/components/ItemEditor/forms/constants';

export const selectElements: TSelector<TElement[]> = ({
    threatModeling: {
        elements: { list },
    },
}) => list;

export const selectElementsCount: TSelector<number> = ({
    threatModeling: {
        elements: { list },
    },
}) => list.length;

export const selectElementsOptions = createSelector(
    [selectElements],
    (elements) => elements.map(transformElementOption)
);

export const selectElementsOptionsByTrustBoundaryId: (
    id?: string
) => TSelector<ZodNamedTmDto[]> = (id) => (state) =>
    state.threatModeling.elements.list
        .filter(
            (element) =>
                element.trust_boundary?.id === id || !element.trust_boundary
        )
        .map(transformElementOption);
