/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { DataColumnProps } from '@epam/uui-core';
import { NameCell, DateRangeCell } from './components';
/**
 * Types
 */
import { TAssessment } from 'types';
/**
 * Expo
 */
export const getColumns = (): DataColumnProps<TAssessment>[] => [
    {
        grow: 1,
        width: 100,
        key: 'name',
        caption: 'Assessment name',
        render: (props) => <NameCell {...props} />,
    },
    {
        grow: 2,
        width: 0,
        key: 'findings',
        caption: 'Dates',
        render: (props) => <DateRangeCell {...props} />,
    },
];
