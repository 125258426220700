/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, memo } from 'react';
/**
 * Types
 */
import { TProjectReportActionLog } from 'types';
import { DefaultRecentAction } from 'components/RecentActions/RecentAction/DefaultRecentAction';
/**
 * Expo
 */
export const ReportDeletedDraft: FC<TProjectReportActionLog> = memo(
    ({
        author: { name },
        details: {
            previous_state: { name: reportName },
        },
    }) => (
        <DefaultRecentAction
            dataTestId="reportDeletedDraft"
            user={name}
            action="deleted draft report"
            target={reportName}
            targetStyle="gray"
        />
    )
);
