/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { LevelOfRisk } from 'types/threatModeling/trustBoundary';

export const TRUST_BOUNDARY_READABLE_ID_PREFIX = 'TB';

export const riskLevelToLabelMap: Record<LevelOfRisk, string> = {
    [LevelOfRisk.low]: 'Low',
    [LevelOfRisk.medium]: 'Medium',
    [LevelOfRisk.high]: 'High',
};
