/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useEffect } from 'react';
/**
 * Hooks
 */
import {
    useAppDispatch,
    usePageLoadingPerformance,
    useRedirect,
    useRole,
} from 'hooks';
/**
 * Components
 */
import { ListFilter } from 'components/ListFilter';
import { Modal, ReportsContent } from './components';
import { Panel } from 'components/common';
import { PageLoadHandler } from 'components/PageLoadHandler';
import { Header } from './components/Header';
/**
 * Types
 */
import { TFormValues } from './components/Form/types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
/**
 * Utils
 */
import { showModal } from 'utils';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
import { FILTER_FIELDS } from './constants';
import { ReportTypes } from 'constants/reports';
import { PagesToFilter } from 'constants/filter';
/**
 * Store
 */
import { selectUserProjectId } from 'store/user';
import {
    handleCreateReport,
    handleGetReports,
    saveFilter,
    saveSearch,
    selectFilter,
    selectInProgress,
    selectIsEmpty,
    selectIsInitialized,
} from 'store/reports';
/**
 * Types
 */
import { TFilter } from 'types';
/**
 * Expo
 */
export const Reports: React.FC = () => {
    usePageLoadingPerformance({
        waitForApi: true,
        reducerNames: ['user', 'project', 'reports'],
    });

    const redirect = useRedirect();
    const dispatch = useAppDispatch();

    const filter = useSelector(selectFilter);
    const isEmpty = useSelector(selectIsEmpty);
    const inProgress = useSelector(selectInProgress);
    const projectId = useSelector(selectUserProjectId);
    const isInitialized = useSelector(selectIsInitialized);

    const { isSecuritySpecialist } = useRole();

    const handleCreate = useCallback(
        async (formValues: TFormValues) => {
            if (!projectId) return;

            await dispatch(handleCreateReport(formValues));

            dispatch(handleGetReports(projectId));
        },
        [dispatch, projectId]
    );

    const handleAddReport = useCallback(
        (creationType: ReportTypes) => {
            const withDocxFile = creationType === ReportTypes.withDocxFile;

            const title = withDocxFile
                ? 'Add docx report and convert to pdf'
                : 'Add report';

            const dataTestId = withDocxFile
                ? 'reports/uploadModal'
                : 'reports/creationModal';

            switch (creationType) {
                case ReportTypes.pdf:
                    return redirect(PAGES.configurePdfReport);
                case ReportTypes.docx:
                    return redirect(PAGES.configureDocxReport);
                default:
                    return showModal(Modal, {
                        title,
                        dataTestId,
                        creationType,
                        onSubmit: handleCreate,
                    });
            }
        },
        [handleCreate, redirect]
    );

    const handleFilter = useCallback(
        (filterValue: TFilter) => {
            if (!projectId) return;

            dispatch(saveFilter(filterValue));

            dispatch(handleGetReports(projectId));
        },
        [dispatch, projectId]
    );

    const handleSearch = useCallback(
        (search?: string) => {
            if (!projectId) return;

            dispatch(saveSearch(search));

            dispatch(handleGetReports(projectId));
        },
        [dispatch, projectId]
    );

    useEffect(() => {
        if (isInitialized || !projectId) return;

        dispatch(handleGetReports(projectId));
    }, [dispatch, projectId, isInitialized]);

    return (
        <PageLoadHandler
            inProgress={inProgress}
            isInitialized={isInitialized}
            renderHeader={() => (
                <Header
                    isEmpty={isEmpty}
                    onSearch={handleSearch}
                    onAddReport={handleAddReport}
                    isSecuritySpecialist={isSecuritySpecialist}
                />
            )}
        >
            <div className={css.pageContent}>
                {!isEmpty && (
                    <div className={css.filter}>
                        <ListFilter
                            value={filter}
                            dataTestId="reports"
                            fields={FILTER_FIELDS}
                            onChange={handleFilter}
                            pageType={PagesToFilter.reports}
                        />
                    </div>
                )}
                <Panel cx={css.list} dataTestId="reports/list">
                    <ReportsContent />
                </Panel>
            </div>
        </PageLoadHandler>
    );
};
