/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { call, put, takeLatest } from 'redux-saga/effects';
/**
 * Actions
 */
import { getList, saveList, setInProgress } from './actions';
/**
 * Utils
 */
import { getActionLogs } from './utils';
/**
 * Constants
 */
/**
 * Types
 */
import { TResponse, TQueryParams } from './types';
import { PayloadAction } from '@reduxjs/toolkit';
/**
 * Expo
 */
function* handleGetList({ payload }: PayloadAction<TQueryParams>) {
    yield put(setInProgress(true));

    const logs: TResponse = yield call(getActionLogs, payload);

    yield put(saveList(logs));
    yield put(setInProgress(false));
}

export default function* sagas(): Generator {
    yield takeLatest(getList.type, handleGetList);
}
