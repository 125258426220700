/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Hooks
 */
import { useAppDispatch, useCheckRole, useOnMount } from 'hooks';
import { useSelector } from 'react-redux';

/**
 * Components
 */
/**
 * Types
 */
import { RouteType } from './types';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Store
 */
import { handleLogin, selectIsUserExists } from 'store/user';
/**
 * Expo
 */
export const PrivateRoute: React.FC<RouteType> = ({
    roles,
    component: Component,
}) => {
    const checkRole = useCheckRole();

    const isUserExists = useSelector(selectIsUserExists);
    const dispatch = useAppDispatch();
    const { isTokenExists } = api;

    useOnMount(async () => {
        if (isUserExists) return;
        if (isTokenExists) {
            await dispatch(handleLogin());
        }
    });

    if (roles && !checkRole(roles)) {
        return null;
    }

    return <Component />;
};
