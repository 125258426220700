import React, { FC, PropsWithChildren } from 'react';
import { WithDataTestId } from 'types';
import css from './style.module.scss';

export const RecentActionContent: FC<PropsWithChildren<WithDataTestId>> = ({
    children,
    dataTestId,
}) => (
    <div id={dataTestId} className={css.actionBlock}>
        {children}
    </div>
);
