/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback } from 'react';
/**
 * Hooks
 */
import { useRedirect } from 'hooks';
/**
 * Components
 */
import { LinkButton, Panel, FlexRow, Text } from 'components/common';
import { FlexSpacer } from '@epam/promo';
/**
 * Utils
 */
import { formatDate } from 'utils';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
/**
 * Types
 */
import { SmallCardProps } from './types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const Small: React.FC<SmallCardProps> = ({
    id,
    dataTestId,
    name,
    withLink,
    updateDate,
    commentsCount,
    assessmentId = '',
    assessmentName,
    onClick,
    renderInformation,
}) => {
    const redirect = useRedirect();

    const handleClick = useCallback(() => {
        if (onClick) onClick(id);
    }, [id, onClick]);

    const handleRedirectToAssessment = useCallback(() => {
        redirect(PAGES.singleAssessment, { assessmentId });
    }, [redirect, assessmentId]);

    return (
        <Panel
            shadow
            onClick={handleClick}
            background="white"
            cx={css.smallCardContainer}
            dataTestId={`${dataTestId}/cardContainer`}
        >
            {!withLink ? null : (
                <FlexRow cx={css.spaceReducer} size="24">
                    <LinkButton
                        size="24"
                        caption={assessmentName}
                        onClick={handleRedirectToAssessment}
                    />
                </FlexRow>
            )}
            <FlexRow cx={css.spaceReducer} size="24">
                <Text
                    fontSize="16"
                    lineHeight="24"
                    dataTestId={`${dataTestId}/card/title`}
                >
                    {name}
                </Text>
                <FlexSpacer />
                {!commentsCount ? null : (
                    <LinkButton
                        caption={`comments ${commentsCount}`}
                        size="36"
                    />
                )}
            </FlexRow>
            <FlexRow size="24" columnGap="12">
                {renderInformation?.()}
                <FlexSpacer />
                <Text color="gray60" fontSize="12" size="18">
                    Last changed {formatDate(updateDate)}
                </Text>
            </FlexRow>
        </Panel>
    );
};
