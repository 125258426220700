/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { renderItem } from './renderItem';
import { DataPickerRow } from '@epam/promo';
/**
 * Types
 */
import { TOption } from '../types';
import { DataRowProps } from '@epam/uui-core';
/**
 * Expo
 */
export const renderSeverityRow = (
    props: DataRowProps<TOption, string>
): JSX.Element => (
    <DataPickerRow<TOption, string>
        {...props}
        padding="12"
        key={props.rowKey}
        renderItem={renderItem}
    />
);
