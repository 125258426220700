/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
export const SYSTEM_NAME = 'Agile Security Platform';
export const SERVICES_DISCOVERY_CONSOLE = 'Services Discovery Console';

export const SYSTEM_LABEL = 'agile-security';

export enum PlatformTypes {
    agileSecurityPlatform = 'AGILE_SECURITY_PLATFORM',
    securityDeliveryConsole = 'SECURITY_DELIVERY_CONSOLE',
}

export enum PlatformRoutes {
    files = 'files',
    agileSecurityPlatform = 'agile',
    securityDeliveryConsole = 'discover',
}

export const PLATFORM_CAPTIONS = {
    [PlatformTypes.agileSecurityPlatform]: SYSTEM_NAME,
    [PlatformTypes.securityDeliveryConsole]: SERVICES_DISCOVERY_CONSOLE,
};

export const browserIsLoaded = Symbol('browserIsLoaded');
export const redirectStartTime = Symbol('redirectStartTime');

export enum LogoUrl {
    deliveryConsole = '/assets/images/header/securityCenterLogo.svg',
    securityPlatform = '/assets/images/header/logo.svg',
}
