/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import {
    SeverityTypes,
    SEVERITY_CAPTIONS,
    SEVERITY_COLORS,
} from 'constants/severity';
import {
    StatusTypes,
    CAPTIONS_BY_STATUS,
    STATUS_COLORS,
} from 'constants/statuses';
/**
 * Types
 */
import {
    TFindingsBySeverity,
    TFindingsByStatuses,
    TFindingForChart,
} from './types';
/**
 * Expo
 */
const removeEmpty = (finding: TFindingForChart) => finding.value !== 0;

export const getFindingsForChartBySeverity = (
    findings: TFindingsBySeverity
): TFindingForChart[] =>
    Object.entries(findings)
        .map(([severity, { total, count_by_status }]) => ({
            id: severity,
            value: total,
            additionalData: count_by_status,
            color: SEVERITY_COLORS[severity as SeverityTypes],
            label: SEVERITY_CAPTIONS[severity as SeverityTypes],
        }))
        .filter(removeEmpty);

export const getFindingsForChartByStatuses = (
    findings: TFindingsByStatuses
): TFindingForChart[] =>
    Object.entries(findings)
        .map(([status, { total, count_by_severity }]) => ({
            id: status,
            value: total,
            additionalData: count_by_severity,
            color: STATUS_COLORS[status as StatusTypes],
            label: CAPTIONS_BY_STATUS[status as StatusTypes],
        }))
        .filter(removeEmpty);
