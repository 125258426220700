/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Utils
 */
import { AsyncDataSourceProps } from '@epam/uui-core';
/**
 * Constants
 */
import { Methods } from 'constants/request';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Expo
 */
export function getDataSourceConfig<T>(
    url: string,
    idKey?: keyof T,
    filter: (item: T) => boolean = () => true,
    additionalOptions?: T[]
): AsyncDataSourceProps<T, T[keyof T], unknown> {
    const config: AsyncDataSourceProps<T, T[keyof T], unknown> = {
        api: () =>
            api.request<T[]>(Methods.get, { url }).then((response) => {
                let result = response.filter(filter);

                if (additionalOptions) {
                    result = [...additionalOptions, ...result];
                }

                return result;
            }),
    };

    if (idKey) {
        config.getId = (item: T) => item[idKey];
    }

    return config;
}
