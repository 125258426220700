/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { useState } from 'react';
import { Blocker, Modal } from 'components/common';
import { TRequestAssessmentRetestModalInternalProps } from './types';

export const RequestAssessmentRetestModal: React.FC<
    TRequestAssessmentRetestModalInternalProps
> = ({ dataTestId, onSubmit, ...props }) => {
    const [inProgress, setInProgress] = useState(false);

    const handleSuccess = async () => {
        setInProgress(true);
        try {
            onSubmit();
        } finally {
            setInProgress(false);
        }
    };

    return (
        <>
            <Blocker isEnabled={inProgress} />
            <Modal
                {...props}
                dataTestId={dataTestId}
                title="Request assessment retest"
                onSuccess={handleSuccess}
                cancelButtonCaption="Cancel"
                successButtonCaption="Submit"
                bodyText="Would you like to request assessment retest?"
            />
        </>
    );
};
