/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { forwardRef, useCallback, useMemo } from 'react';
/**
 * Components
 */
import { Button as UuiButton } from '@epam/uui';
/**
 * Types
 */
import { ButtonProps, TRef } from './types';
/**
 * Utils
 */
import { getRawProps, none } from 'utils';
import { useLinkClickHandler } from 'react-router-dom';
import { RawButtonHTMLProps } from '../../../types';
/**
 * Expo
 */
export const Button = forwardRef((props: ButtonProps, ref: TRef) => {
    const {
        href,
        target,
        className,
        dataTestId,
        onClick = none,
        type = 'button',
        disabled = false,
        iconPosition = 'left',
        ...rest
    } = props;

    const internalOnClick = useLinkClickHandler(href || '');

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            if (onClick) {
                onClick(event);
            }

            if (href && !href.startsWith('#') && target !== '_blank') {
                internalOnClick(event);
            }
        },
        [onClick, internalOnClick, href, target]
    );

    const rawProps: RawButtonHTMLProps = useMemo(
        () => ({ ...getRawProps({ dataTestId }), type }),
        [dataTestId, type]
    );

    return (
        <UuiButton
            {...rest}
            ref={ref}
            href={href}
            cx={className}
            rawProps={rawProps}
            isDisabled={disabled}
            onClick={handleClick}
            iconPosition={iconPosition}
        />
    );
});
