/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, memo } from 'react';
/**
 * Types
 */
import { THandlers } from '../types';
import { TProjectActivityCommonActionLog } from 'types';
/**
 * Utils
 */
import {
    DefaultRecentAction,
    useHref,
} from 'components/RecentActions/RecentAction/DefaultRecentAction';
/**
 * Expo
 */
export const UpdateActivity: FC<TProjectActivityCommonActionLog & THandlers> =
    memo(
        ({
            author: { name },
            getActivityHref,
            details: {
                current_state: {
                    id,
                    name: activityName,
                    assessment_id: assessmentId,
                },
            },
        }) => {
            const href = useHref({
                getHref: () => getActivityHref(assessmentId, id),
            });
            return (
                <DefaultRecentAction
                    dataTestId="activityUpdated"
                    user={name}
                    action="updated activity"
                    target={activityName}
                    href={href}
                />
            );
        }
    );
