/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { useMemo, useState } from 'react';
import { cx } from '@epam/uui-core';
import { Button } from 'components/common';
import { FlexRow, FlexSpacer, Panel, ScrollBars } from '@epam/promo';
import { VerticalTabs } from 'components/common/VerticalTabs';
import css from './style.module.scss';
import { getFlatSteps, getAvailableSteps } from './utils';
import { IStepsProps } from './types';

export const Steps: React.FC<IStepsProps> = ({
    steps,
    disabledSteps = [],
    shouldDisableNextButton,
    dataTestId,
    className,
    validateStep,
    renderButtons,
    renderStepContent,
    hideStepButtons = false,
}) => {
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    const flatSteps = useMemo(() => getFlatSteps(steps), [steps]);

    const availableSteps = useMemo(
        () => getAvailableSteps(flatSteps, disabledSteps),
        [flatSteps, disabledSteps]
    );

    const { id: currentStepId } = availableSteps[currentTabIndex];

    const lastTabId = availableSteps[availableSteps.length - 1].id;

    const handleGoNext = async () => {
        if (validateStep) {
            await validateStep(currentStepId);
        }
        setCurrentTabIndex((originIndex) => originIndex + 1);
    };

    const handleGoPrev = () => {
        setCurrentTabIndex((originIndex) => originIndex - 1);
    };

    const isLastAvailableStep = currentTabIndex === availableSteps.length - 1;
    const isNextButtonDisabled = shouldDisableNextButton || isLastAvailableStep;

    return (
        <Panel background="white" cx={cx(css.contentWrapper, className)}>
            <Panel cx={css.content}>
                <Panel cx={css.sideBar}>
                    <ScrollBars>
                        <VerticalTabs
                            tabs={steps}
                            currentTabId={currentStepId}
                            disabledTabs={disabledSteps}
                            dataTestId="assessmentWizard-tabs"
                        />
                    </ScrollBars>
                </Panel>
                <Panel cx={css.form}>{renderStepContent(currentStepId)}</Panel>
            </Panel>
            <Panel cx={css.footer}>
                <FlexRow columnGap="12">
                    {!hideStepButtons && (
                        <>
                            <Button
                                caption="Previous"
                                fill="none"
                                onClick={handleGoPrev}
                                disabled={currentTabIndex === 0}
                                dataTestId={`${dataTestId}-buttons-previous`}
                            />
                            <Button
                                caption="Next"
                                fill="none"
                                onClick={handleGoNext}
                                dataTestId={`${dataTestId}-buttons-next`}
                                disabled={isNextButtonDisabled}
                            />
                        </>
                    )}
                    <FlexSpacer />
                    {renderButtons(currentStepId, lastTabId)}
                </FlexRow>
            </Panel>
        </Panel>
    );
};
