/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { INTEGRATION_CAPTION } from 'constants/project';
/**
 * Expo
 */
export const getTrackerName = ({
    isIntegratedWithJira,
    isIntegratedWithAzure,
}: Record<string, boolean>) => {
    switch (true) {
        case isIntegratedWithJira:
            return INTEGRATION_CAPTION.JIRA;
        case isIntegratedWithAzure:
            return INTEGRATION_CAPTION.AZURE_DEVOPS;
        default:
            return null;
    }
};
