/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Hooks
 */
import { useAppSelector, useRole } from 'hooks';
/**
 * Components
 */
import { Panel, Text } from 'components/common';
import { IntegrationsCard } from 'components/IntegrationsCard';
/**
 * Store
 */
import { selectFlag } from 'store/featureFlags';
/**
 * Utils
 */
import { cx } from '@epam/uui-core';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
import { FEATURE_FLAGS } from 'constants/featureFlags';
/**
 * Assets
 */
import css from './style.module.scss';

/**
 * Expo
 */
export const Integrations: React.FC = () => {
    const { isOperationsManager, isProjectRepresentative } = useRole();

    const integrationWithAzureFeatureFlag = useAppSelector(
        selectFlag(FEATURE_FLAGS.integrationWithAzure)
    );

    const isAzureIntegrationAvailable =
        integrationWithAzureFeatureFlag &&
        (isOperationsManager || isProjectRepresentative);

    const azureIntegrationCreationUrl = isOperationsManager
        ? PAGES.addAzureIntegrationOperationsManager
        : PAGES.addAzureIntegration;

    const azureEditUrl = isOperationsManager
        ? PAGES.editAzureIntegrationOperationsManager
        : PAGES.editAzureIntegration;

    const teamsEditUrl = PAGES.editTeamsIntegration;

    return (
        <Panel
            background="white"
            dataTestId="block/integrations"
            cx={css.panel}
        >
            <Text fontSize="24" lineHeight="30" cx={cx(css.title, 'text-bold')}>
                Project Integrations
            </Text>
            <IntegrationsCard
                isJiraAvailable={isOperationsManager}
                isSlackAvailable={isProjectRepresentative}
                isTeamsAvailable={isProjectRepresentative}
                isAzureAvailable={isAzureIntegrationAvailable}
                azureCreationUrl={azureIntegrationCreationUrl}
                azureEditUrl={azureEditUrl}
                isAzureDisconnectAvailable={integrationWithAzureFeatureFlag}
                teamsEditUrl={teamsEditUrl}
            />
        </Panel>
    );
};
