/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import React from 'react';
import { FieldDecorator } from './FieldDecorator';
import { FieldController } from 'components/Fields';
import { RangeDatePicker, TextInput } from 'components/common';

import { TTextInputProps } from 'components/common/TextInput/types';
import {
    TRangeDatePickerProps,
    TRangeDatePickerValue,
} from 'components/common/RangeDatePicker/types';
import { dateRangeValidator } from 'utils';
import { getDateRangeLimitFromStatus } from 'components/Forms/AssessmentGeneral/utils';
import { TAssessmentStatus } from 'types';

export interface AssessmentGeneralProps {
    status?: TAssessmentStatus;
}

export const AssessmentGeneral: React.FC<AssessmentGeneralProps> = ({
    status,
}) => {
    const getDateRangeLimit = () => getDateRangeLimitFromStatus(status);

    return (
        <>
            <FieldDecorator>
                <FieldController<TTextInputProps>
                    isRequired
                    name="name"
                    maxLength={200}
                    component={TextInput}
                    label="Assessment name"
                    placeholder="e.g. Release v.1"
                    dataTestId="assessmentCreation/general/fields/name"
                />
            </FieldDecorator>
            <FieldDecorator>
                <FieldController<TRangeDatePickerProps, TRangeDatePickerValue>
                    isRequired
                    name="dates"
                    getLimit={getDateRangeLimit}
                    label="Assessment dates"
                    component={RangeDatePicker}
                    validate={dateRangeValidator}
                    dataTestId="assessmentCreation/general/fields/dates"
                />
            </FieldDecorator>
        </>
    );
};
