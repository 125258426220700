/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
import { cx } from '@epam/uui-core';
/**
 * Components
 */
import { FlexRow } from 'components/common';
import { SingleAttachedFile } from 'components/SingleAttachedFile';
/**
 * Types
 */
import { TAttachmentList } from './types';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const AttachmentList: React.FC<TAttachmentList> = ({
    list,
    columnsInRow = 1,
    onRemove,
    contentUrl = ENDPOINTS.downloadAttachedFile,
    error,
}) => {
    const className = cx(css.cardContainer, css[`columnSize${columnsInRow}`]);

    return (
        <FlexRow cx={className} vPadding="18">
            {list.map(({ id, file: { name, size }, created_by }) => (
                <SingleAttachedFile
                    id={id}
                    key={id}
                    name={name}
                    size={size}
                    onClose={onRemove}
                    contentUrl={contentUrl}
                    isEditingMode
                    createdBy={created_by}
                    error={error}
                />
            ))}
        </FlexRow>
    );
};
