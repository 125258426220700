/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Expo
 */
// TODO: remove lite here and in other places in the codebase
export enum ProjectTypes {
    basic = 'BASIC',
    lite = 'LITE',
    template = 'TEMPLATE',
}

export const JIRA_INTEGRATION = 'jiraIntegration';
export const AZURE_INTEGRATION = 'azureIntegration';

export enum IntegrationTypes {
    azure = 'AZURE_DEVOPS',
    jira = 'JIRA',
    slack = 'SLACK',
    sonarQube = 'SONAR_QUBE',
    teams = 'TEAMS',
}

enum IntegrationIcons {
    azure = 'azure.svg',
    jira = 'jira.svg',
    slack = 'slack.svg',
    sonarQube = 'sonar.svg',
    teams = 'teams.svg',
}

export const INTEGRATION_CAPTION: Record<IntegrationTypes, string> = {
    [IntegrationTypes.slack]: 'Slack',
    [IntegrationTypes.teams]: 'Teams',
    [IntegrationTypes.jira]: 'Jira',
    [IntegrationTypes.sonarQube]: 'SonarQube',
    [IntegrationTypes.azure]: 'Azure DevOps',
};

export const INTEGRATION_ICONS: Record<IntegrationTypes, string> = {
    [IntegrationTypes.azure]: IntegrationIcons.azure,
    [IntegrationTypes.jira]: IntegrationIcons.jira,
    [IntegrationTypes.slack]: IntegrationIcons.slack,
    [IntegrationTypes.sonarQube]: IntegrationIcons.sonarQube,
    [IntegrationTypes.teams]: IntegrationIcons.teams,
};
