/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import {
    ReportCreatedPublic,
    ReportDeletedPublic,
    ReportCreatedDraft,
    ReportDeletedDraft,
    ReportPublished,
    FindingCreated,
    FindingUpdated,
    FindingArchived,
    FindingGenerated,
    FindingCommentCreated,
    FindingCommentUpdated,
    FindingCommentDeleted,
    AssessmentCreated,
    AssessmentStarted,
    AssessmentUpdated,
    AssessmentCompleted,
    FindingMarkedAsIssue,
    AddProjectAttachments,
    AddTrackerIntegration,
    DeleteProjectAttachments,
    DeleteTrackerIntegration,
    UpdateActivity,
    TargetCreated,
    ProjectCreated,
    ActivityCommentCreated,
    AddMessengerIntegration,
    DeleteMessengerIntegration,
    UpdateMessengerIntegration,
    UpdateTrackerIntegration,
    AttachmentToFindingCommentDeleted,
    ManualTaskCreated,
    AssessmentScopeManaged,
} from './RecentActions';
/**
 * Constants
 */
import {
    ActivityActionLogTypes,
    ProjectActionLogTypes,
} from 'constants/actionLog';
/**
 * Types
 */
import { THandlers } from './types';
import { TProjectActionLog } from 'types';
/**
 * Expo
 */
export const renderAction = (
    props: TProjectActionLog,
    handlers: THandlers
): JSX.Element | null => {
    switch (props.type) {
        case ProjectActionLogTypes.assessmentCreated:
            return <AssessmentCreated {...props} {...handlers} />;
        case ProjectActionLogTypes.assessmentStarted:
            return <AssessmentStarted {...props} {...handlers} />;
        case ProjectActionLogTypes.assessmentUpdated:
            return <AssessmentUpdated {...props} {...handlers} />;
        case ProjectActionLogTypes.assessmentCompleted:
            return <AssessmentCompleted {...props} {...handlers} />;
        case ProjectActionLogTypes.addTrackerIntegration:
            return <AddTrackerIntegration {...props} />;
        case ProjectActionLogTypes.deleteTrackerIntegration:
            return <DeleteTrackerIntegration {...props} />;
        case ProjectActionLogTypes.updateTrackerIntegration:
            return <UpdateTrackerIntegration {...props} />;
        case ProjectActionLogTypes.addProjectAttachments:
            return <AddProjectAttachments {...props} />;
        case ProjectActionLogTypes.deleteProjectAttachments:
            return <DeleteProjectAttachments {...props} />;
        case ProjectActionLogTypes.reportCreatedPublic:
            return <ReportCreatedPublic {...props} {...handlers} />;
        case ProjectActionLogTypes.reportCreatedDraft:
            return <ReportCreatedDraft {...props} {...handlers} />;
        case ProjectActionLogTypes.reportDeletedPublished:
            return <ReportDeletedPublic {...props} />;
        case ProjectActionLogTypes.reportDeletedDraft:
            return <ReportDeletedDraft {...props} />;
        case ProjectActionLogTypes.reportPublish:
            return <ReportPublished {...props} {...handlers} />;
        case ProjectActionLogTypes.targetCreated:
            return <TargetCreated {...props} {...handlers} />;
        case ActivityActionLogTypes.activityCommentCreated:
            return <ActivityCommentCreated {...props} {...handlers} />;
        case ActivityActionLogTypes.updateActivity:
            return <UpdateActivity {...props} {...handlers} />;
        case ProjectActionLogTypes.findingArchived:
            return <FindingArchived {...props} {...handlers} />;
        case ProjectActionLogTypes.findingCreated:
            return <FindingCreated {...props} {...handlers} />;
        case ProjectActionLogTypes.findingGenerated:
            return <FindingGenerated {...props} {...handlers} />;
        case ProjectActionLogTypes.findingMarkedAsIssue:
            return <FindingMarkedAsIssue {...props} {...handlers} />;
        case ProjectActionLogTypes.findingMarkedUpdated:
        case ProjectActionLogTypes.findingOpenedUpdated:
            return <FindingUpdated {...props} {...handlers} />;
        case ProjectActionLogTypes.projectCreatedPrivate:
            return <ProjectCreated {...props} />;
        case ProjectActionLogTypes.commentCreatedForMarkedFinding:
        case ProjectActionLogTypes.commentCreatedForOpenedFinding:
            return <FindingCommentCreated {...props} {...handlers} />;
        case ProjectActionLogTypes.commentUpdatedForMarkedFinding:
        case ProjectActionLogTypes.commentUpdatedForOpenedFinding:
            return <FindingCommentUpdated {...props} {...handlers} />;
        case ProjectActionLogTypes.commentDeletedForMarkedFinding:
        case ProjectActionLogTypes.commentDeletedForOpenedFinding:
            return <FindingCommentDeleted {...props} {...handlers} />;
        case ProjectActionLogTypes.addMessengerIntegration:
            return <AddMessengerIntegration {...props} />;
        case ProjectActionLogTypes.deleteMessengerIntegration:
            return <DeleteMessengerIntegration {...props} />;
        case ProjectActionLogTypes.updateMessengerIntegration:
            return <UpdateMessengerIntegration {...props} />;
        case ProjectActionLogTypes.manualTaskCreated:
            return <ManualTaskCreated {...props} {...handlers} />;
        case ProjectActionLogTypes.assessmentScopeManaged:
            return <AssessmentScopeManaged {...props} {...handlers} />;
        case ProjectActionLogTypes.markedFindingAttachmentToCommentDeleted:
        case ProjectActionLogTypes.openedFindingAttachmentToCommentDeleted:
            return (
                <AttachmentToFindingCommentDeleted {...props} {...handlers} />
            );
        default:
            return null;
    }
};
