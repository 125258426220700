/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback } from 'react';
/**
 * Components
 */
import { Panel } from '@epam/promo';
import { ListItem } from './ListItem';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { TDropdownBody } from './types';
/**
 * Expo
 */
export const DropdownBody = <TValue extends string>({
    options,
    onClick,
    onClose,
}: TDropdownBody<TValue>): JSX.Element => {
    const handleClick = useCallback(
        (chosenId: TValue) => {
            onClose?.();

            onClick(chosenId);
        },
        [onClose, onClick]
    );

    return (
        <Panel background="white" shadow cx={css.dropdownBody}>
            {options.map(({ id, name, dataTestId }) => (
                <ListItem<TValue>
                    key={id}
                    id={id}
                    name={name}
                    onClick={handleClick}
                    dataTestId={dataTestId}
                />
            ))}
        </Panel>
    );
};
