/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { DisconnectModal } from './components';
import { SingleIntegration } from 'components/SingleIntegration';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
import { INTEGRATION_CAPTION, IntegrationTypes } from 'constants/project';
/**
 * Types
 */
import { TIntegrationsCardProps } from './types';
/**
 * Utils
 */
import { NotificationTypes, showModal, showNotification } from 'utils';
/**
 * Store
 */
import {
    handleRemoveMessengerIntegration,
    handleRemoveTrackerIntegration,
    selectProjectData,
} from 'store/project';
/**
 * Hooks
 */
import { useAppDispatch } from 'hooks';
import { useSelector } from 'react-redux';

/**
 * Expo
 */
export const IntegrationsCard: React.FC<TIntegrationsCardProps> = ({
    isJiraAvailable,
    isSlackAvailable,
    isTeamsAvailable,
    isAzureAvailable,
    azureCreationUrl,
    azureEditUrl,
    teamsEditUrl,
    isAzureDisconnectAvailable,
}) => {
    const { slack, teams, azure, jira } = useSelector(selectProjectData);

    const dispatch = useAppDispatch();
    const handleDisconnect = (
        integrationType: IntegrationTypes,
        callback: () => Promise<void>
    ) => {
        const title = INTEGRATION_CAPTION[integrationType];

        showModal(DisconnectModal, {
            integrationTitle: title,
            onSuccess: async () => {
                await callback();

                showNotification({
                    text: `${title} disconnected`,
                    type: NotificationTypes.success,
                });
            },
            dataTestId: 'disconnectModal',
        });
    };

    const handleDisconnectTracker = (
        integrationType: IntegrationTypes,
        integrationId = ''
    ) => {
        const handleSuccess = async () => {
            await dispatch(
                handleRemoveTrackerIntegration(integrationId, integrationType)
            );
        };

        handleDisconnect(integrationType, handleSuccess);
    };

    const handleDisconnectMessenger = (
        integrationType: IntegrationTypes,
        integrationId = ''
    ) => {
        const handleSuccess = async () => {
            await dispatch(
                handleRemoveMessengerIntegration(integrationId, integrationType)
            );
        };

        handleDisconnect(integrationType, handleSuccess);
    };
    return (
        <>
            {isJiraAvailable && (
                <SingleIntegration
                    title="Jira"
                    integrationId={jira?.id}
                    dataTestId="JiraIntegration"
                    icon="/assets/images/jira.svg"
                    creationUrl={PAGES.jiraIntegration}
                    integrationType={IntegrationTypes.jira}
                    onDisconnect={handleDisconnectTracker}
                />
            )}
            {isSlackAvailable && (
                <SingleIntegration
                    title="Slack"
                    icon="/assets/images/slack.svg"
                    integrationType={IntegrationTypes.slack}
                    integrationId={slack?.id}
                    creationUrl={PAGES.addSlackIntegration}
                    dataTestId="SlackIntegration"
                    editUrl={PAGES.editSlackIntegration}
                    onDisconnect={handleDisconnectMessenger}
                />
            )}
            {isTeamsAvailable && (
                <SingleIntegration
                    title="Teams"
                    icon="/assets/images/teams.svg"
                    integrationType={IntegrationTypes.teams}
                    integrationId={teams?.id}
                    creationUrl={PAGES.addTeamsIntegration}
                    dataTestId="TeamsIntegration"
                    editUrl={teamsEditUrl}
                    onDisconnect={handleDisconnectMessenger}
                />
            )}
            {isAzureAvailable && (
                <SingleIntegration
                    title="Azure DevOps"
                    icon="/assets/images/azure.svg"
                    integrationType={IntegrationTypes.azure}
                    integrationId={azure?.id}
                    creationUrl={azureCreationUrl}
                    dataTestId="AzureIntegration"
                    editUrl={azureEditUrl}
                    onDisconnect={
                        isAzureDisconnectAvailable
                            ? handleDisconnectTracker
                            : undefined
                    }
                />
            )}
        </>
    );
};
