/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import {
    PATTERNS,
    NUMBER_IN_RANGE_VALIDATION_MESSAGE,
    GENERATE_REPORT_VALIDATION_MESSAGE,
} from 'constants/validation';
/**
 * Types
 */
import { TPattern } from 'types/form';
import { ValidateResult } from 'react-hook-form';
/**
 * Utils
 */
import { templateString } from './string';
/**
 * Expo
 */
export const dateRangeValidator = ({
    to,
    from,
}: {
    to: string;
    from: string;
}): boolean => Boolean(to && from);

export const checkboxValidator = (value: boolean): boolean => value;

export const checkBoxesGroupValidator = (value: string[]): boolean | string =>
    Boolean(value.length) || GENERATE_REPORT_VALIDATION_MESSAGE;

export const numberRangeValidator =
    (min: number, max: number, precision = 0) =>
    (value: string): ValidateResult => {
        const numberValue = Number(value);
        const isValid = !value || (numberValue >= min && numberValue <= max);
        const error = templateString(NUMBER_IN_RANGE_VALIDATION_MESSAGE, {
            min: min.toFixed(precision),
            max: max.toFixed(precision),
        });

        return isValid || error;
    };

export const patternCreator =
    (value: RegExp) =>
    (message: string): TPattern => ({
        value,
        message,
    });

export const emailPattern = patternCreator(PATTERNS.email);
export const urlPattern = patternCreator(PATTERNS.url);
export const firstOrgPattern = patternCreator(PATTERNS.firstOrg);

export const isEmpty = (
    value: Record<string, unknown> | unknown[] | unknown
): boolean => {
    if (Array.isArray(value)) return !value.length;

    if (value && value instanceof Object && value.constructor === Object)
        return !Object.values(value).some(Boolean);

    return !value;
};

export const checkEmptyFields = (formValues: string[]): boolean =>
    formValues.every((value: string) => value !== '');
