/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createSlice } from '@reduxjs/toolkit';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { INITIAL_STATE } from './constants';
import { Methods } from 'constants/request';
/**
 * Utils
 */
import { showNotification, NotificationTypes } from 'utils';
import {
    configureTrackerIssueBodyJira,
    configureTrackerIssueBodyAzure,
    convertToIssueTypes,
    convertToTrackerDetails,
} from './utils';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Types
 */
import { AppDispatch, TSelector } from 'types';
import {
    TIssueTrackerJira,
    TIssueTrackerAzure,
    TMappingOption,
    TMappingOptionsByType,
    TIssueMappingOptions,
} from './types';
/**
 * Reducer
 */
const { reducer, actions } = createSlice({
    name: 'configureProject',
    initialState: INITIAL_STATE,
    reducers: {
        saveIssueTracker: (
            state,
            { payload: { tracker_issue_details, jira_tracker_issue_details } }
        ) => {
            const trackerDetails =
                tracker_issue_details ?? jira_tracker_issue_details;

            state.trackerIssueDetails = convertToTrackerDetails(trackerDetails);

            state.issueTypes = convertToIssueTypes(trackerDetails);

            state.issueTrackerIsConnected = true;
        },
        setInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        setIsConnected: (state, { payload }) => {
            state.issueTrackerIsConnected = payload;
        },
        resetForm: () => INITIAL_STATE,
    },
});

export default reducer;
/**
 * Selectors
 */
export const selectTrackerIssueDetailsByIssueType =
    (issueType: string): TSelector<TMappingOptionsByType> =>
    ({ configureProject: { trackerIssueDetails } }) =>
        trackerIssueDetails?.[issueType] || {};
export const selectIsConnected: TSelector<boolean> = ({
    configureProject: { issueTrackerIsConnected },
}) => issueTrackerIsConnected;
export const selectInProgress: TSelector<boolean> = ({
    configureProject: { inProgress },
}) => inProgress;
export const selectIssueTypes: TSelector<TMappingOption[]> = ({
    configureProject: { issueTypes },
}) => issueTypes;
export const selectTrackerIssueDetails: TSelector<TIssueMappingOptions> = ({
    configureProject: { trackerIssueDetails },
}) => trackerIssueDetails;
/**
 * Actions
 */
export const { saveIssueTracker, setInProgress, setIsConnected, resetForm } =
    actions;
/**
 * Dispatchers
 */
export const handleConnectIssueTrackerJira =
    (values: TIssueTrackerJira) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const body = configureTrackerIssueBodyJira(values);

        try {
            dispatch(setInProgress(true));

            const response = await api.request(Methods.post, {
                url: ENDPOINTS.issueTrackerCheck,
                data: body,
            });
            showNotification({
                text: 'Jira instance connected',
                type: NotificationTypes.success,
            });
            dispatch(saveIssueTracker(response));
        } catch {
            dispatch(setIsConnected(false));
        } finally {
            dispatch(setInProgress(false));
        }
    };

export const handleConnectIssueTrackerAzure =
    (values: TIssueTrackerAzure) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const body = configureTrackerIssueBodyAzure(values);

        try {
            dispatch(setInProgress(true));

            const response = await api.request(Methods.post, {
                url: ENDPOINTS.issueTrackerCheck,
                data: body,
            });

            showNotification({
                text: 'Azure DevOps connected',
                type: NotificationTypes.success,
            });

            dispatch(saveIssueTracker(response));
        } catch {
            dispatch(setIsConnected(false));
        } finally {
            dispatch(setInProgress(false));
        }
    };
