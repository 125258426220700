/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { FILE_ICON_BY_EXTENSION, ICON_SRC_TEMPLATE } from './constants';
/**
 * Utils
 */
import { templateString } from 'utils';

export const getIconSrcByExtension = (extension: string): string => {
    const icon =
        FILE_ICON_BY_EXTENSION[extension] || FILE_ICON_BY_EXTENSION.OTHER;

    return templateString(ICON_SRC_TEMPLATE, { icon });
};
