/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import React, { FC } from 'react';
import { ReactComponent as alignLeftIcon } from '@epam/assets/icons/common/editor-format_text_alignment_left-24.svg';
import { ReactComponent as alignCenterIcon } from '@epam/assets/icons/common/editor-format_text_alignment_center-24.svg';
import { ReactComponent as alignRightIcon } from '@epam/assets/icons/common/editor-format_text_alignment_right-24.svg';
import { ToolbarButton } from './ToolbarButton';
import { FORMAT_ELEMENT_COMMAND, LexicalEditor } from 'lexical';

export enum AlignStyle {
    left = 'left',
    center = 'center',
    right = 'right',
}

const iconToStyleMap = {
    [AlignStyle.left]: alignLeftIcon,
    [AlignStyle.center]: alignCenterIcon,
    [AlignStyle.right]: alignRightIcon,
};

interface AlignControlProps {
    style: AlignStyle;
    editor: LexicalEditor;
}
export const AlignControl: FC<AlignControlProps> = ({ style, editor }) => {
    const handleClick = () => {
        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, style);
    };
    return (
        <ToolbarButton
            isActive={false}
            icon={iconToStyleMap[style]}
            style={style}
            onClick={handleClick}
        />
    );
};
