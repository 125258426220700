/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useState } from 'react';
/**
 * Components
 */
import { SideBar } from './SideBar';
import { Content } from './Content';
import { SwitcherButton } from './SwitcherButton';
/**
 * Expo
 */
export const PlatformSwitcher: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = useCallback(() => {
        setIsOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    return (
        <>
            <SwitcherButton onCLick={handleOpen} />
            <SideBar isOpen={isOpen}>
                <Content onClose={handleClose} />
            </SideBar>
        </>
    );
};
