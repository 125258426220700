/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Button } from 'components/common';
/**
 * Constants
 */
import { ReportState } from 'constants/reports';
/**
 * Types
 */
import { TButtonsProps } from './types';

/**
 * Expo
 */
export const Buttons: React.FC<TButtonsProps> = ({
    tabId,
    lastTabId,
    selectedSteps,
    onCancel,
    onSubmit,
}) => {
    const handleSubmitAsPublished = () => {
        onSubmit(ReportState.published);
    };

    const handleSubmitAsDraft = () => {
        onSubmit(ReportState.draft);
    };

    const isDisabled = tabId !== lastTabId || selectedSteps?.length === 0;

    return (
        <>
            <Button
                caption="Cancel"
                color="neutral"
                fill="none"
                onClick={onCancel}
                dataTestId="configureReport/buttons/cancel"
            />
            <Button
                fill="outline"
                caption="Save as draft"
                onClick={handleSubmitAsDraft}
                disabled={isDisabled}
                dataTestId="configureReport/buttons/submitAsDraft"
            />
            <Button
                caption="Submit"
                onClick={handleSubmitAsPublished}
                disabled={isDisabled}
                dataTestId="configureReport/buttons/submitAsPublished"
            />
        </>
    );
};
