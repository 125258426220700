/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
/**
 * Hooks
 */
import { useRouteParams } from 'hooks';
/**
 * Components
 */
import { FlexRow } from '@epam/promo';
import { ArraySelect } from 'components/common';
/**
 * Utils
 */
import { pick, templateString } from 'utils';
import { getNamesString, renderFooter } from './utils';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
/**
 * Types
 */
import { TTarget } from 'types';
import { TProps } from './types';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const SelectTargets: React.FC<TProps> = ({ onChange }) => {
    const [value, changeValue] = useState<string[]>([]);
    const [options, setOptions] = useState<TTarget[]>([]);

    const { assessmentId } = useRouteParams();

    const handleSelectActivity = useCallback(
        (selectedValue: string[]) => {
            changeValue(selectedValue);

            onChange(selectedValue);
        },
        [onChange]
    );

    const handleGetTargets = useCallback(async () => {
        const url = templateString(ENDPOINTS.assessmentTargets, {
            assessmentId,
        });

        const response = await api.request<TTarget[]>(Methods.get, { url });

        const idList = pick(response, 'id');

        setOptions(response);
        changeValue(idList);
        onChange(idList);
    }, [assessmentId, onChange]);

    useEffect(() => {
        handleGetTargets();
    }, [handleGetTargets]);

    const tooltipContent = useMemo(
        () => getNamesString({ value, options }),
        [value, options]
    );

    return (
        <FlexRow cx={css.container}>
            <ArraySelect<TTarget>
                mode="multi"
                maxItems={1}
                value={value}
                labelKey="name"
                options={options}
                entityName="target"
                searchPosition="none"
                renderFooter={renderFooter}
                tooltipContent={tooltipContent}
                onValueChange={handleSelectActivity}
                dataTestId="singleAssessment/selectTarget"
            />
        </FlexRow>
    );
};
