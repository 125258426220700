/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback } from 'react';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
/**
 * Components
 */
import { useHeaderContent } from './components';
import { Blocker, MainMenu } from 'components/common';
import { FlexCell, BurgerButton } from '@epam/promo';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
/**
 * Store
 */
import { handleLogout, selectInProgress } from 'store/user';
/**
 * Expo
 */
export const Header: React.FC = () => {
    const { pathname } = useLocation();
    const dispatch = useAppDispatch();
    const headerContent = useHeaderContent();

    const inProgress = useSelector(selectInProgress);

    const handleLogOut = useCallback(async () => {
        await dispatch(handleLogout());
    }, [dispatch]);

    const renderBurger = useCallback(
        () => (
            <>
                <BurgerButton
                    caption="Projects"
                    isLinkActive={pathname.includes(PAGES.projects)}
                />
                <BurgerButton caption="Log out" onClick={handleLogOut} />
            </>
        ),
        [pathname, handleLogOut]
    );

    const { isTokenExists } = api;

    return (
        <FlexCell grow={1}>
            <Blocker isFixed isEnabled={inProgress} />
            <MainMenu
                renderBurger={isTokenExists ? renderBurger : undefined}
                items={isTokenExists ? headerContent : []}
            />
        </FlexCell>
    );
};
