/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useMemo } from 'react';
/**
 * Components
 */
import { TabButton } from 'components/common';
/**
 * Types
 */
import { TabProps } from './types';
/**
 * Utils
 */
import { getRawProps } from 'utils';
/**
 * Expo
 */
export const Tab: React.FC<TabProps> = ({
    tabId,
    title,
    dataTestId,
    activeTabId,
    onClick,
}) => {
    const handleClick = useCallback(() => {
        onClick(tabId);
    }, [tabId, onClick]);

    const rawProps = useMemo(() => getRawProps({ dataTestId }), [dataTestId]);

    return (
        <TabButton
            caption={title}
            rawProps={rawProps}
            onClick={handleClick}
            isLinkActive={activeTabId === tabId}
        />
    );
};
