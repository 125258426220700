/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback } from 'react';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
import { useAppDispatch, useRedirect, useRole, useRouteParams } from 'hooks';
/**
 * Components
 */
import { FlexSpacer } from '@epam/uui-components';
import { FlexRow } from '@epam/promo';
import { Button, LinkButton, Panel, Text } from 'components/common';
import { DocumentList } from 'components/DocumentList';
import {
    AttachFilesModal,
    DeleteAttachmentConfirmation,
} from 'components/modals';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
/**
 * Store
 */
import {
    selectAttachments,
    handleAddAttachments,
    handleGetAttachments,
    handleRemoveAttachment,
} from 'store/project';
import { selectUserId } from 'store/user';
/**
 * Utils
 */
import { cx } from '@epam/uui-core';
import { NotificationTypes, showModal, showNotification } from 'utils';
/**
 * Types
 */
import { TAttachment } from 'types';
import { TModalAttachmentProps } from './types';
import { TUploadedFileInfo } from 'types/files';
/**
 * Assets
 */
import css from './style.module.scss';
import generalCss from '../../style.module.scss';
import { ReactComponent as addIcon } from '@epam/assets/icons/common/action-add-18.svg';
/**
 * Expo
 */
export const Attachments: React.FC = () => {
    const redirect = useRedirect();
    const dispatch = useAppDispatch();
    const { isProjectRepresentative, isOperationsManager } = useRole();

    const currentAttachments = useSelector(selectAttachments);
    const currentUserId = useSelector(selectUserId);
    const { projectId } = useRouteParams();

    const handleDelete = useCallback(
        async (id: string) => {
            await dispatch(handleRemoveAttachment(id));

            dispatch(handleGetAttachments(projectId));
        },
        [dispatch, projectId]
    );

    const handleCreate = useCallback(
        async (attachments: TAttachment[]) => {
            await dispatch(handleAddAttachments(attachments));

            dispatch(handleGetAttachments(projectId));
        },
        [dispatch, projectId]
    );

    const handleAddAttachment = useCallback(() => {
        showModal<TModalAttachmentProps>(AttachFilesModal, {
            onSubmit: handleCreate,
            dataTestId: 'projectInfo',
            allowCloseOnCross: false,
            successButtonCaption: 'Submit',
            infoText: 'Up to 15 files. Limit for 1 file is 50 MB.',
        });
    }, [handleCreate]);

    const handleClickAttachments = useCallback(() => {
        const redirectPath = isOperationsManager
            ? PAGES.projectsProjecAttachments
            : PAGES.projectAttachments;
        redirect(redirectPath);
    }, [isOperationsManager, redirect]);

    const handleDeleteAttachment = useCallback(
        ({ id, name, createdBy }: TUploadedFileInfo) => {
            if (createdBy !== currentUserId) {
                showNotification({
                    text: 'You cannot delete files attached by other user',
                    type: NotificationTypes.warning,
                });
                return;
            }
            showModal(DeleteAttachmentConfirmation, {
                name,
                onSuccess: () => handleDelete(id),
            });
        },
        [currentUserId, handleDelete]
    );

    const deleteHandler =
        isProjectRepresentative || isOperationsManager
            ? handleDeleteAttachment
            : undefined;

    return (
        <Panel
            background="white"
            cx={generalCss.panel}
            dataTestId="projectInfo/attachments"
        >
            <FlexRow size="30" cx={generalCss.panelHeader}>
                <Text
                    fontSize="24"
                    fontWeight="400"
                    lineHeight="30"
                    size="30"
                    dataTestId="projectInfo/attachments/title"
                >
                    Attachments
                </Text>
                <FlexSpacer />
                {(isProjectRepresentative || isOperationsManager) && (
                    <LinkButton
                        size="30"
                        icon={addIcon}
                        caption="Add new attachment"
                        onClick={handleAddAttachment}
                        dataTestId="projectInfo/attachments/add"
                    />
                )}
            </FlexRow>
            {currentAttachments?.length ? (
                <DocumentList
                    columnsInRow={2}
                    clickable={false}
                    list={currentAttachments}
                    onDelete={deleteHandler}
                    dataTestId="projectInfo/attachments"
                />
            ) : (
                <Panel cx={css.emptyAttachments}>
                    <Text
                        cx={cx(
                            generalCss.sectionText,
                            css.emptyAttachmentsText
                        )}
                        color="gray60"
                    >
                        No attachments added
                    </Text>
                    <img
                        alt="no attachments"
                        src="/assets/images/emptyViews/noContent.svg"
                        className={css.emptyAttachmentsImage}
                    />
                </Panel>
            )}
            {Boolean(currentAttachments?.length) && (
                <FlexRow vPadding="18">
                    <FlexSpacer />
                    <Button
                        caption="Show attachments"
                        onClick={handleClickAttachments}
                        dataTestId="projectInfo/attachments/show"
                    />
                </FlexRow>
            )}
        </Panel>
    );
};
