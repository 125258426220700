/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */

import React, { useMemo } from 'react';
import { useRedirect } from 'hooks';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
/**
 * Assets
 */
import { ReactComponent as settings } from '@epam/assets/icons/common/action-settings-24.svg';
import css from '../ActionLogButton/style.module.scss';
/**
 * Components
 */
import { IconContainer } from 'components/common';
/**
 * State
 */
import { selectUserId } from 'store/user';
import { cx } from '@epam/uui-core';

export const NotificationSettingsButton: React.FC = () => {
    const redirect = useRedirect();
    const personId = useSelector(selectUserId);
    const { pathname } = useLocation();
    const isActive = useMemo(() => {
        return pathname.endsWith('notification-settings');
    }, [pathname]);
    return (
        <IconContainer
            style={{ fill: 'white' }}
            icon={settings}
            onClick={() => redirect(PAGES.notificationSettings, { personId })}
            dataTestId="notification-settings/button"
            cx={cx(css.button, isActive && css.darkBackground)}
        />
    );
};
