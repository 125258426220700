/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import {
    Text,
    HintNotification,
    ErrorNotification,
    SuccessNotification,
    WarningNotification,
} from '@epam/promo';
/**
 * Types
 */
import { TFunctionWithArgs } from 'types';
import { INotification, NotificationParams } from '@epam/uui-core';
/**
 * Services
 */
import { svc } from 'services';
/**
 * Types
 */
export enum NotificationTypes {
    hint = 'hint',
    error = 'error',
    success = 'success',
    warning = 'warning',
}

type TAction = {
    name: string;
    action: TFunctionWithArgs<INotification, void>;
};

interface TShowNotificationParams {
    text: string;
    type?: NotificationTypes;
    actions?: TAction[];
    config?: {
        duration?: NotificationParams['duration'];
        position?: NotificationParams['position'];
    };
    setNotificationId?: TFunctionWithArgs<number>;
}

type TShowNotification = (params: TShowNotificationParams) => void;
/**
 * Expo
 */
const getNotificationComponent = (type: NotificationTypes) => {
    switch (type) {
        case NotificationTypes.hint:
            return HintNotification;
        case NotificationTypes.error:
            return ErrorNotification;
        case NotificationTypes.warning:
            return WarningNotification;
        case NotificationTypes.success:
        default:
            return SuccessNotification;
    }
};

const bindActions = (actions: TAction[], props: INotification) =>
    actions.map(({ action, ...rest }) => ({
        ...rest,
        action: action.bind(null, props),
    }));

export const showNotification: TShowNotification = ({
    text,
    type = NotificationTypes.success,
    actions = [],
    config = {
        position: 'top-center',
    },
    setNotificationId,
}) => {
    const NotificationComponent = getNotificationComponent(type);

    svc.uuiNotifications
        .show((props) => {
            if (setNotificationId) {
                setNotificationId(props.id);
            }

            return (
                <NotificationComponent
                    {...props}
                    actions={bindActions(actions, props)}
                >
                    <Text>{text}</Text>
                </NotificationComponent>
            );
        }, config)
        .then(() => {})
        .catch(() => {});
};
