/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { ArrayTable } from 'components/common';
/**
 * Types
 */
import { TableProps } from './types';
import { TAssessment } from 'types';
/**
 * Utils
 */
import { getColumns } from './utils';
/**
 * Assets
 */
import css from '../style.module.scss';
/**
 * Expo
 */
export const Table: React.FC<TableProps> = ({ data }) => (
    <ArrayTable<TAssessment>
        data={data}
        withoutPaddings
        getColumns={getColumns}
        tableClassName={css.historyTable}
        dataTestId="projectInfo/history/table"
    />
);
