/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, useCallback, useContext, useMemo } from 'react';
/**
 * Components
 */
import { FlexRow } from '@epam/promo';
import { ToolbarButton } from '../../ToolbarButton';
/**
 * Context
 */
import { EditorContext } from '../../../context';
/**
 * Utils
 */
import { setAlignmentInSelectedBlocks } from './utils';
/**
 * Types
 */
import { TAlignmentControlsProps } from './types';
/**
 * Expo
 */
export const AlignmentControls: FC<TAlignmentControlsProps> = ({
    buttonList,
}) => {
    const { editorState, onChange } = useContext(EditorContext);

    const currentBlockAlignment = useMemo(() => {
        const contentState = editorState.getCurrentContent();
        const focusKey = editorState.getSelection().getFocusKey();

        const block = contentState.getBlockForKey(focusKey);

        return block?.getData().get('alignment');
    }, [editorState]);

    const handleClick = useCallback(
        (alignment?: string) => {
            if (alignment) {
                onChange(setAlignmentInSelectedBlocks(editorState, alignment));
            }
        },
        [onChange, editorState]
    );

    return (
        <FlexRow cx="RichEditor-controls">
            {buttonList.map(({ style, icon }) => (
                <ToolbarButton
                    key={style}
                    icon={icon}
                    style={style}
                    onClick={handleClick}
                    isActive={currentBlockAlignment === style}
                />
            ))}
        </FlexRow>
    );
};
