/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useEffect } from 'react';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
import {
    useRole,
    useAppDispatch,
    useRouteParams,
    useAppSelector,
    usePageLoadingPerformance,
} from 'hooks';
/**
 * Components
 */
import { LeftSide } from './LeftSide';
import { RightSide } from './RightSide';
import { BackTo } from 'components/common/BackTo';
import { PanelsContainer } from 'components/common/Panel/PanelsContainer';
import { PageLoadHandler } from 'components/PageLoadHandler';
import { Header } from './components/Header';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
/**
 * Store
 */
import {
    resetData,
    selectActivity,
    selectAssessment,
    selectInProgress,
    handleGetAssessmentWithActivities,
} from 'store/assessment';
import {
    handleGetData,
    resetData as resetActivityData,
    selectInProgress as selectInProgressActivity,
    selectIsInitialized,
} from 'store/singleActivity';
/**
 * Expo
 */
export const SingleActivity: React.FC = () => {
    usePageLoadingPerformance({
        waitForApi: true,
        reducerNames: ['user', 'project', 'assessment', 'singleActivity'],
    });

    const dispatch = useAppDispatch();
    const { assessmentId, activityId } = useRouteParams();
    const { isOperationsManager } = useRole();

    const pathToBack = isOperationsManager
        ? PAGES.singleAssessmentOperationsManager
        : PAGES.singleAssessment;

    const inProgress = useSelector(selectInProgress);
    const inProgressActivity = useSelector(selectInProgressActivity);
    const isInitialized = useSelector(selectIsInitialized);
    const { name: assessmentName } = useSelector(selectAssessment);

    const activity = useAppSelector((state) =>
        selectActivity(state, activityId)
    );

    const { target, name: activityName } = activity;

    useEffect(() => {
        dispatch(handleGetAssessmentWithActivities(assessmentId));
        dispatch(handleGetData(activityId));

        return () => {
            dispatch(resetData());
            dispatch(resetActivityData());
        };
    }, [dispatch, activityId, assessmentId]);

    return (
        <PageLoadHandler
            isInitialized={isInitialized}
            inProgress={inProgress || inProgressActivity}
            renderHeader={() => <Header activityName={activityName} />}
            renderBackTo={() => (
                <BackTo
                    path={pathToBack}
                    caption={`"${assessmentName}"`}
                    dataTestId="activity/backTo"
                />
            )}
        >
            <PanelsContainer>
                <LeftSide targetName={target?.name || ''} size={60} />
                <PanelsContainer direction="vertical" size={40}>
                    <RightSide activity={activity} />
                </PanelsContainer>
            </PanelsContainer>
        </PageLoadHandler>
    );
};
