/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { TGetScrollRef } from './types';
/**
 * Expo
 */
/**
 * Scrollbars ref.current.container provides static node element, which won't be changed on re-render.
 * We need actual scrollTop prop for the useVirtualList hook
 * but prop scrollTop always the same and equal to 0 on each scroll event.
 * So we need to intercept and redefine scrollTop property to get actual scrollTop on scroll event
 */
export const getScrollRef: TGetScrollRef = ({ container, getScrollTop }) =>
    new Proxy(container, {
        get: (divElement: HTMLDivElement, key: keyof HTMLDivElement) => {
            if (key === 'scrollTop') {
                return getScrollTop();
            }

            const value = divElement[key];

            if (value && typeof value === 'function') {
                return value.bind(divElement);
            }

            return value;
        },
    });
