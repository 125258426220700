/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Actions
 */
import { saveComments, setInProgress, setInitialized } from './actions';
/**
 * Types
 */
import { AppDispatch, TComment } from 'types';
import { TAddCommentPayload, TBlockActivityPayload } from './types';
/**
 * Utils
 */
import {
    requestComments,
    requestAddComment,
    requestBlockActivity,
    requestUnblockActivity,
} from './utils';
import { activityStatusDidUpdateAction } from 'store/middleware/backgroundUpdate/actions';
/**
 * Expo
 */
const handleGetComments =
    (activityId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const comments: TComment[] = await requestComments(activityId);

        dispatch(saveComments(comments));
    };

export const handleAddComments =
    ({ activityId, commentData }: TAddCommentPayload) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setInProgress(true));

        try {
            await requestAddComment(activityId, commentData);
            await dispatch(handleGetComments(activityId));
        } finally {
            dispatch(setInProgress(false));
        }
    };

export const handleBlockActivity =
    ({ activityId, comment }: TBlockActivityPayload) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setInProgress(true));

        try {
            await requestBlockActivity({ activityId, comment });

            dispatch(activityStatusDidUpdateAction());

            await dispatch(handleGetComments(activityId));
        } finally {
            dispatch(setInProgress(false));
        }
    };

export const handleUnblockActivity =
    (activityId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setInProgress(true));

        try {
            await requestUnblockActivity(activityId);

            dispatch(activityStatusDidUpdateAction());
        } finally {
            dispatch(setInProgress(false));
        }
    };

export const handleGetData =
    (activityId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            await dispatch(handleGetComments(activityId));
        } finally {
            dispatch(setInitialized(true));
        }
    };
