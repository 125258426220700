/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createSlice } from '@reduxjs/toolkit';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { INITIAL_STATE } from './constants';
import { Methods } from 'constants/request';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Types
 */
import { AppDispatch, TPerson, TSelector } from 'types';
/**
 * Utils
 */
import { getQuery } from './utils';
import { createQueryUrl } from 'utils';
import { PersonTabName } from './types';

/**
 * Reducer
 */
const { reducer, actions } = createSlice({
    name: 'persons',
    initialState: INITIAL_STATE,
    reducers: {
        saveList: (state, { payload }) => {
            state.list = payload;

            state.isInitialized = true;
        },
        setInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
    },
});

export default reducer;
/**
 * Selectors
 */
export const selectList: TSelector<TPerson[]> = ({ persons: { list } }) => list;
export const selectInProgress: TSelector<boolean> = ({
    persons: { inProgress },
}) => inProgress;
export const selectIsInitialized: TSelector<boolean> = ({
    persons: { isInitialized },
}) => isInitialized;
/**
 * Actions
 */
export const { saveList, setInProgress } = actions;
/**
 * Dispatchers
 */
export const handleGetList =
    (activeTab: PersonTabName, search?: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setInProgress(true));

        const url = createQueryUrl(
            ENDPOINTS.persons,
            getQuery(activeTab, search)
        );

        const response = await api.request<TPerson[]>(Methods.get, { url });

        dispatch(saveList(response));

        dispatch(setInProgress(false));
    };
