/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Components
 */
import { WarningAlert, SuccessAlert, HintAlert, ErrorAlert } from '@epam/uui';

/**
 * Expo
 */
export enum AlertsType {
    warning = 'warning',
    success = 'success',
    hint = 'hint',
    error = 'error',
}

// TODO: use satisfies after babel upgrade
export const ALERTS_BY_TYPE = {
    [AlertsType.warning]: WarningAlert,
    [AlertsType.success]: SuccessAlert,
    [AlertsType.hint]: HintAlert,
    [AlertsType.error]: ErrorAlert,
};
