/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { FC, useCallback, useEffect } from 'react';
import { Comments } from 'components/Comments';
import {
    handleAddComments,
    handleGetData,
    selectComments,
} from 'store/singleActivity';
import { TCommentCreationFormValue } from 'types';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks';
import { resetData } from 'store/assessment';

export interface IActivityCommentsProps {
    activityId: string;
}

export const ActivityComments: FC<IActivityCommentsProps> = ({
    activityId,
}) => {
    const comments = useSelector(selectComments);
    const dispatch = useAppDispatch();

    const handleCreateComment = useCallback(
        async (commentData: TCommentCreationFormValue) => {
            dispatch(handleAddComments({ activityId, commentData }));
        },
        [dispatch, activityId]
    );

    useEffect(() => {
        dispatch(handleGetData(activityId));

        return () => {
            dispatch(resetData());
        };
    }, [dispatch, activityId]);

    return (
        <>
            {/* Credentials/Attachments have to be hidden till they are actually implemented */}
            {/* <Credentials />
            <Attachments /> */}
            <Comments
                editable={false}
                list={comments}
                onCreate={handleCreateComment}
                dataTestId="activity"
            />
        </>
    );
};
