/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useState, useCallback } from 'react';
/**
 * Components
 */
import { FlexRow } from '@epam/promo';
import { Button, Text } from 'components/common';
import { CommentList, CreateComment } from './components';
import { AttachmentsContextProvider } from './components/CommentList/Context';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { TAttachment } from 'types';
import { TCommentsProps } from './types';
/**
 * Utils
 */
import { checkIsEmptyComment } from './utils';
/**
 * Expo
 */
export const Comments: React.FC<TCommentsProps> = ({
    onCreate,
    list,
    editable,
    dataTestId,
    attachmentsEndpoint,
}) => {
    const [comment, setComment] = useState('');
    const [showEditor, setShowEditor] = useState(false);
    const [attachments, setAttachments] = useState<TAttachment[]>([]);

    const [isEditingMode, setIsEditingMode] = useState(false);

    const handleToggleEditorVisibility = useCallback(() => {
        setShowEditor((origin) => !origin);
    }, []);

    const handleCancel = useCallback(() => {
        handleToggleEditorVisibility();

        setComment('');
        setAttachments([]);
    }, [handleToggleEditorVisibility]);

    const handleSetAttachments = useCallback(async (payload: TAttachment[]) => {
        setAttachments(payload);
    }, []);

    const handleAdd = useCallback(async () => {
        await onCreate({ comment, attachments });

        handleCancel();
    }, [comment, attachments, onCreate, handleCancel]);

    return (
        <div id={`${dataTestId}/comments`}>
            <FlexRow vPadding="18" cx={css.title}>
                <Text
                    fontSize="24"
                    fontWeight="400"
                    dataTestId={`${dataTestId}/comments/title`}
                >
                    Comments
                </Text>
            </FlexRow>
            {!list.length && !showEditor && (
                <Text
                    cx={css.noComments}
                    color="gray60"
                    dataTestId={`${dataTestId}/comments/noCommentsYet`}
                >
                    There are no comments yet
                </Text>
            )}
            <AttachmentsContextProvider
                attachmentsEndpoint={attachmentsEndpoint}
            >
                {list.map((item) => {
                    if (checkIsEmptyComment(item)) return null;

                    return (
                        <CommentList
                            key={item.id}
                            {...item}
                            setIsEditingMode={setIsEditingMode}
                            editable={editable}
                            dataTestId={dataTestId}
                        />
                    );
                })}
            </AttachmentsContextProvider>
            {!showEditor && !isEditingMode && (
                <FlexRow>
                    <Button
                        caption="Add comment"
                        className={css.addButton}
                        onClick={handleToggleEditorVisibility}
                        dataTestId={`${dataTestId}/comments/addCommentButton`}
                    />
                </FlexRow>
            )}
            {showEditor && (
                <CreateComment
                    onSubmit={handleAdd}
                    onCancel={handleCancel}
                    attachments={attachments}
                    onChangeComment={setComment}
                    isDisabledAddButton={!comment}
                    onChangeAttachments={handleSetAttachments}
                    dataTestId={dataTestId}
                />
            )}
        </div>
    );
};
