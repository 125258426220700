/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { ReportTypes } from 'constants/reports';
/**
 * Expo
 */
enum FormIds {
    generateReportForm = 'generateReportForm',
    uploadReportFiles = 'uploadReportFiles',
    uploadDocxFile = 'uploadDocxFile',
    generatePdfReport = 'generatePdfReport',
}

export const FORM_ID_BY_REPORT_TYPE = {
    [ReportTypes.withLink]: FormIds.generateReportForm,
    [ReportTypes.withFiles]: FormIds.uploadReportFiles,
    [ReportTypes.docx]: FormIds.generateReportForm,
    [ReportTypes.withDocxFile]: FormIds.uploadDocxFile,
    [ReportTypes.pdf]: FormIds.generatePdfReport,
};
