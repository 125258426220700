/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { forwardRef, Ref, useCallback, useMemo, useState } from 'react';
/**
 * Components
 */
import { SearchInput as UuiSearchInput, LabeledInput } from '@epam/promo';
/**
 * Utils
 */
import { getRawProps } from 'utils';
/**
 * Types
 */
import { SearchInputProps } from './types';
/**
 * Expo
 */
export const SearchInput: React.FC<SearchInputProps> = forwardRef(
    (
        {
            label,
            isInvalid,
            isDisabled,
            isRequired,
            isReadonly,
            dataTestId,
            placeholder,
            iconPosition,
            validationMessage,
            debounceDelay = 300,
            onValueChange,
            onCancel,
            ...props
        },
        ref: Ref<HTMLInputElement>
    ) => {
        const [value, changeValue] = useState('');

        const handleChange = useCallback(
            (inputValue: string) => {
                changeValue(inputValue);

                if (onValueChange) onValueChange(inputValue);
            },
            [onValueChange]
        );

        const rawProps = useMemo(
            () => getRawProps({ dataTestId }),
            [dataTestId]
        );

        return (
            <LabeledInput
                label={label}
                isInvalid={isInvalid}
                isRequired={isRequired}
                validationMessage={validationMessage}
            >
                <UuiSearchInput
                    value={value}
                    {...props}
                    rawProps={rawProps}
                    isInvalid={isInvalid}
                    isDisabled={isDisabled}
                    isReadonly={isReadonly}
                    placeholder={placeholder}
                    iconPosition={iconPosition}
                    onValueChange={handleChange}
                    debounceDelay={debounceDelay}
                    ref={ref}
                    onCancel={onCancel}
                />
            </LabeledInput>
        );
    }
);
