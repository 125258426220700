/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useContext, useMemo } from 'react';
/**
 * Components
 */
import { FlexRow } from '@epam/promo';
import { ArraySelect } from 'components/common';
/**
 * Types
 */
import { TFieldProps, TOption } from '../../types';
/**
 * Utils
 */
import { renderFooter } from '../utils';
import { getNamesString } from '../../utils';
/**
 * Context
 */
import { Context } from '../FilterContext/context';
/**
 * Expo
 */
export const Persons: React.FC<TFieldProps<string[]>> = ({
    fieldName,
    dataTestId,
    onChange,
}) => {
    const { getFieldData } = useContext(Context);

    const { value, options, queryParameter } = useMemo(
        () => getFieldData(fieldName),
        [fieldName, getFieldData]
    );

    const handleChange = useCallback(
        (selectedValue: string[]) => {
            onChange({ [queryParameter]: selectedValue });
        },
        [onChange, queryParameter]
    );

    const tooltipContent = useMemo(
        () => getNamesString({ value, options }),
        [value, options]
    );

    return (
        <FlexRow vPadding="18">
            <ArraySelect<TOption>
                disableClear
                value={value}
                maxItems={1}
                mode="multi"
                labelKey="name"
                label="Reporter"
                options={options}
                entityName="reporter"
                tooltipPlacement="right"
                renderFooter={renderFooter}
                onValueChange={handleChange}
                tooltipContent={tooltipContent}
                dataTestId={`${dataTestId}/persons`}
                placeholder="All security specialists"
            />
        </FlexRow>
    );
};
