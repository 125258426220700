/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Form } from './components/Form';
import { Modal } from 'components/common';
/**
 * Types
 */
import { ModalProps } from './types';
/**
 * Expo
 */
export const MarkAsBlockedModal: React.FC<ModalProps> = ({
    onSubmit,
    ...props
}) => {
    return (
        <Modal
            {...props}
            withoutFooter
            title="Mark activity as blocked"
            allowCloseOnCross={false}
            renderBody={({ abort }) => (
                <Form onSubmit={onSubmit} onClose={abort} />
            )}
            dataTestId="activity/markAsBlockedModal"
        />
    );
};
