/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
import { SESSION_STORAGE_KEYS } from 'constants/localStorage';
/**
 * Services
 */
import SessionStorage from 'services/sessionStorage';
/**
 * Utils
 */
import { getDeepAddress } from 'utils';
/**
 * Expo
 */
export const getIssueKey = (url: string): string => {
    const urlParts = url.split('/');

    const lastPart = urlParts[urlParts.length - 1];

    return lastPart;
};

const SAFE_URL_PATTERN =
    /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi;

/** A pattern that matches safe data URLs. It only matches image, video, and audio types. */
const DATA_URL_PATTERN =
    /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+\\/]+=*$/i;

const DEFAULT_URL = 'about:blank';

const sanitizeUrlHelper = (url: string): string => {
    if (url === 'null' || url.length === 0 || url === DEFAULT_URL)
        return DEFAULT_URL;
    if (url.match(SAFE_URL_PATTERN) || url.match(DATA_URL_PATTERN)) return url;

    return `unsafe:${url}`;
};

export const sanitizeUrl = (url?: string | null): string => {
    return sanitizeUrlHelper((url ?? DEFAULT_URL).trim());
};

export const emailReplacerToStars = (
    currentString: string,
    firstMatch: string,
    secondMatch: string,
    thirdMatch: string
): string => {
    return (
        firstMatch[0] +
        firstMatch.substring(1).replaceAll(/[^@]/g, '*') +
        secondMatch[0] +
        secondMatch.substring(1).replaceAll(/./g, '*') +
        thirdMatch
    );
};

export const saveRedirectUrl = (): void => {
    const { pathname, search } = window.location;
    const deepLink = getDeepAddress(search);
    const redirectUrl = deepLink || pathname;

    if (redirectUrl !== PAGES.home && redirectUrl !== PAGES.login) {
        SessionStorage.set(SESSION_STORAGE_KEYS.redirectUrl, redirectUrl);
    }
};
