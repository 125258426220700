/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import {
    InteractionCreatePayload,
    InteractionUpdatePayload,
} from 'store/threatModeling/interaction/types';
import { Interaction } from 'types';
import { createReadableId } from '../../../utils';

import { FormValuesWithoutTmId } from '../baseItemFormSchema';
import { ZodNamedTmDto } from '../constants';
import { INTERACTION_READABLE_ID_PREFIX } from './constants';
import { InteractionFormValues } from './schema';

export function mapInteractionToFormValues(
    interaction: Interaction
): FormValuesWithoutTmId<InteractionFormValues> {
    const from: ZodNamedTmDto = {
        id: interaction.from.id,
        name: interaction.from.name,
        readableId: interaction.from.readable_id,
    };

    const to: ZodNamedTmDto = {
        id: interaction.to.id,
        name: interaction.to.name,
        readableId: interaction.to.readable_id,
    };

    return {
        id: interaction.id,
        name: interaction.name,
        readableId: createReadableId(
            INTERACTION_READABLE_ID_PREFIX,
            String(interaction.readable_id)
        ),
        from,
        to,
        technologies: interaction.technologies,
        protocol: interaction.protocol,
        comment: interaction.comment,
        assets: interaction.asset_footprints
            .map((assetFootprint) => assetFootprint.asset)
            .map((asset) => ({
                id: asset.id,
                name: asset.name,
                readableId: asset.readable_id,
            })),
    };
}

export function mapFormValuesToInteractionCreatePayload(
    form: InteractionFormValues
): InteractionCreatePayload {
    return {
        tm_id: form.tmId,
        name: form.name,
        technologies:
            form?.technologies?.map((technology) => technology.id) ?? null,
        protocol_id: form.protocol?.id ?? null,
        comment: form.comment ?? null,
        assets: form?.assets?.map((asset) => asset.id) ?? null,
        from_id: form.from.id,
        to_id: form.to.id,
    };
}

export function mapFormValuesToInteractionUpdatePayload(
    form: InteractionFormValues
): InteractionUpdatePayload {
    return {
        name: form.name,
        technologies:
            form?.technologies?.map((technology) => technology.id) ?? null,
        protocol_id: form.protocol?.id ?? null,
        comment: form.comment ?? null,
        assets: form?.assets?.map((asset) => asset.id) ?? null,
        from_id: form.from.id,
        to_id: form.to.id,
    };
}
