/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { MAX_SEVERITY_VISIBLE_ITEMS } from './constants';
import {
    SeverityTypes,
    SEVERITY_CAPTIONS,
    SEVERITY_RANGE_CAPTIONS,
} from 'constants/index';
import { TOption } from './types';
/**
 * Expo
 */
export const getSeveritySelectorTooltipContent = (
    severityIds: string[] | undefined
): string | undefined => {
    if (!severityIds || severityIds.length <= MAX_SEVERITY_VISIBLE_ITEMS)
        return undefined;

    return severityIds
        .map((severity) => SEVERITY_CAPTIONS[severity as SeverityTypes])
        .join('; ');
};

export const getName = ({ name }: TOption): string =>
    SEVERITY_RANGE_CAPTIONS[name as SeverityTypes];
