/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { memo } from 'react';
/**
 * Components
 */
import { Tab } from './Tab';
import { FlexRow } from 'components/common';
/**
 * Types
 */
import { MainMenuProps } from './types';
/**
 * Assets
 */
import css from './style.module.scss';
import { cx } from '@epam/uui-core';

/**
 * Expo
 */
export const PageNavigation: React.FC<MainMenuProps> = memo(
    ({ tabs, dataTestId, className, currentTab = '' }) => (
        <FlexRow
            background="white"
            borderBottom
            dataTestId={dataTestId}
            cx={cx(css.tabsButtonList, className)}
        >
            {tabs.map(({ id, ...rest }) => (
                <Tab
                    {...rest}
                    id={id}
                    key={id}
                    dataTestId={dataTestId}
                    activeTabId={currentTab}
                />
            ))}
        </FlexRow>
    )
);
