/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Modal as ModalComponent } from 'components/common';
/**
 * Constants
 */
import { ModalSizes } from 'constants/modals';
/**
 * Types
 */
import { TProps } from './types';
/**
 * Expo
 */
export const WithoutApprovedTarget: React.FC<TProps> = ({
    onSubmit,
    ...props
}) => (
    <ModalComponent
        {...props}
        allowCloseOnCross={false}
        onSuccess={onSubmit}
        width={ModalSizes.large}
        cancelButtonCaption="Got it"
        title="Assessment cannot be added"
        successButtonCaption="Go to targets"
        dataTestId="assessments/creation/modals/withoutApprovedTarget"
        bodyText="At least one target should be approved before adding assessment"
    />
);
