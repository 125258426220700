/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { createSlice } from '@reduxjs/toolkit';
import { ASSETS_INITIAL_STATE } from './constants';

export const {
    reducer: assetsReducer,
    actions: {
        saveAssets,
        setAssetsLoadingInProgress,
        resetAssets,
        addAsset,
        updateAsset,
    },
} = createSlice({
    name: 'assets',
    initialState: ASSETS_INITIAL_STATE,
    reducers: {
        saveAssets: (state, { payload }) => {
            state.list = payload;
            state.isInitialized = true;
        },
        setAssetsLoadingInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        resetAssets: () => ASSETS_INITIAL_STATE,
        addAsset: (state, { payload }) => {
            state.list = [...state.list, payload];
        },
        updateAsset: (state, { payload }) => {
            const index = state.list.findIndex(
                (asset) => asset.id === payload.id
            );
            if (index !== -1) {
                state.list[index] = { ...state.list[index], ...payload };
            }
        },
    },
});
