/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { EnvironmentTypes, environment } from './environments';

/**
 * Expo
 */

const THREAT_MODELING_ENDPOINTS = {
    uploadDfd: '/api/tm/{activityId}/upload-dfd',
    getDfdMetadata: '/api/tm/{activityId}/dfd-metadata',
    removeDfd: '/api/tm/{activityId}/dfd',
    technologies: '/api/technologies',
    assets: '/api/assets',
    elements: '/api/elements',
    interactions: '/api/interactions',
    interactionProtocols: '/api/interactions/protocols',
    trustBoundaries: '/api/trust-boundaries',
    trustBoundaryTypes: '/api/trust-boundaries/types',
} as const;

export const ENDPOINTS = {
    ...THREAT_MODELING_ENDPOINTS,
    auth: '/api/auth',
    sso: '/api/sso',
    mfa: '/api/mfa',
    exchangeAuthCode: '/api/sso/exchange-auth-code',
    resetPassword: '/api/password/reset',
    setNewPassword: '/api/password/set-new',
    changePassword: '/api/change-password',
    refreshToken: '/api/exchange-refresh-token',
    rotatePassword: '/api/password/rotate',
    logout: '/api/logout',
    personLogout: '/api/persons/current/logout',
    personLogin: '/api/persons/current/login',
    persons: '/api/persons',
    person: '/api/persons/{personId}',
    vulnerabilities: '/api/vulnerabilities',
    singleVulnerability: '/api/vulnerabilities/{vulnerabilityId}',
    personsAssignees: '/api/persons/possible-assignees',
    organizations: '/api/organizations',
    singleOrganization: '/api/organizations/{organizationId}',
    myOrganizations: 'api/organizations/my',
    accounts: '/api/accounts',
    personsByOrganization: 'api/organizations/{organizationId}/persons',
    activities: '/api/activity-references',
    allActivities: '/api/activity-references/all',
    singleActivityReferences: '/api/activity-references/{activityId}',
    archiveActivity: '/api/activity-references/{activityId}/archive',
    blockPerson: '/api/persons/{personId}/is-blocked',
    resendInvitation: '/api/persons/{personId}/resend-invitation',
    personRoles: '/api/persons/roles',
    currentPersons: '/api/persons/current',
    projects: '/api/projects',
    currentProject: '/api/persons/current/current-project',
    currentRole: '/api/persons/current/current-role',
    project: '/api/projects/{id}',
    projectDescription: '/api/projects/{projectId}/description',
    projectAttachments: '/api/projects/{projectId}/attachments',
    singleProjectAttachment:
        '/api/projects/{projectId}/attachments/{attachmentId}',
    downloadAttachedFile: '/api/projects/attachments/{attachmentId}/content',
    downloadReport: '/api/reports/documents/{attachmentId}/content',
    assessment: '/api/assessments',
    assessmentShort: 'api/assessment/short/by/{projectId}',
    loadIssues: '/api/projects/{projectId}/issues/load',
    archiveProject: '/api/projects/{id}/archive',
    issueTrackerCheck: '/api/issue-tracker-check',
    singleAssessment: '/api/assessment/{id}',
    manageScopeAssessment: '/api/assessment/{id}/manage-scope',
    updateActivitiesByTargets: '/api/assessment/{id}/activities',
    trackerIssues: '/api/projects/{projectId}/tracker-issues',
    assessmentStatus: '/api/assessment/{id}/status',
    projectAssessments: '/api/projects/{projectId}/assessments',
    addAssessmentAllowed: '/api/projects/{projectId}/assessments/add-allowed',
    requestAssessment: '/api/projects/{projectId}/assessments/request',
    requestAssessmentRetest: '/api/assessment/{id}/request-retest',
    assessmentHasNonResolvedFindings:
        '/api/assessment/{id}/has-non-resolved-findings',
    assessmentTrackerIssues: '/api/assessment/{id}/tracker-issues',
    files: '/api/files/{attachmentId}',
    assessmentHistory: '/api/assessment/history',
    tasksFiltered: '/api/tasks/filtered',
    tasks: '/api/tasks',
    manualTasks: '/api/tasks/manual',
    singleManualTask: '/api/tasks/{taskId}',
    activityStatus: '/api/activity/{id}/status',
    blockActivity: '/api/activity/{activityId}/block',
    unblockActivity: '/api/activity/{activityId}/unblock',
    reports: '/api/reports',
    reportsFiltered: '/api/reports/filtered',
    reportsWithLinks: '/api/reports/link-report',
    singleReport: '/api/reports/{reportId}',
    singleReportState: '/api/reports/{id}/state',
    reportAttachments: '/api/reports/{reportId}/documents',
    reportWithLink: '/api/reports/link-report',
    reportWithFiles: '/api/reports/files-report',
    reportConfiguredDocx: '/api/reports/configured-docx-report',
    reportUploadDocs: '/api/reports/upload-docx-report',
    reportConfiguredPdf: '/api/reports/configured-pdf-report',
    existingReportByName: 'api/reports/exists',
    findings: '/api/findings',
    findingTypes: '/api/findings/types',
    singleFinding: '/api/findings/{findingId}',
    findingsFiltered: '/api/findings/filtered',
    findingTemplates: 'api/finding-templates',
    generateFinding: '/api/findings/from-sast-vulnerability',
    asyncTask: '/api/async-tasks/{id}',
    statusReferences: '/api/findings/status-references',
    severityReferences: '/api/findings/severity-references',
    projectTracker: '/api/projects/{projectId}/tracker',
    singleProjectTracker: '/api/projects/{projectId}/tracker/{trackerId}',
    projectMessenger: '/api/messengers/project/{projectId}',
    singleMessenger: '/api/messengers/{messengerId}',
    findingMarkAsIssue: '/api/findings/{findingId}/mark-as-issue',
    findingsMarkAsIssuesAll: '/api/findings/mark-as-issues',
    findingMarkAsRiskAccepted: '/api/findings/{findingId}/mark-risk-accepted',
    findingUnmarkAsRiskAccepted:
        '/api/findings/{findingId}/unmark-risk-accepted',
    findingResolutions: '/api/findings/resolutions',
    findingReadyToRetest: '/api/findings/{findingId}/ready-to-retest',
    findingResolutionsReferences: '/api/findings/resolution-references',
    singleFindingComments: '/api/findings/{findingId}/comments',
    singleComment: '/api/findings/{findingId}/comments/{commentId}',
    findingDocument: '/api/findings/documents/{documentId}/content',
    singleFindingEditComment: '/api/findings/{findingId}/comments/{commentId}',
    projectTargets: '/api/projects/{projectId}/targets',
    assessmentTargets: '/api/assessment/{assessmentId}/targets',
    targetActivities: '/api/projects/{projectId}/activity-references',
    targets: '/api/targets',
    approveTarget: '/api/targets/{targetId}/approve',
    rejectTarget: '/api/targets/{targetId}/reject',
    undoApproveTarget: '/api/targets/{targetId}/undo-approve',
    findingStatus: '/api/findings/{findingId}/status',
    archiveFinding: '/api/findings/{findingId}/archive',
    assignees: '/api/activity/{id}/assignees',
    assessmentActivities: '/api/assessment/{assessmentId}/activities',
    policies: '/api/policies',
    policiesByProject: '/api/policies/filtered-by-project',
    policy: '/api/policies/{policyId}',
    policyLocalization: 'api/policies/holders/list',
    policyRules: 'api/policies/rules/list',
    policySeverities: 'api/policies/severities/list',
    organizationsProjects: '/api/projects/names/sorted/{organizationId}',
    assignedOrCreatedProjects: '/api/projects/names/assigned-or-created',
    createPolicy: '/api/policies/{organizationId}',
    createProjectPolicy: '/api/policies',

    singleFindingByProject: '/api/projects/{projectId}/findings/{findingId}',
    singleTaskByProject: '/api/projects/{projectId}/tracker-issues/{taskId}',

    assessmentsForDashboard: '/api/dashboards/assessments',
    dashboardFindingsSummary: '/api/dashboards/findings-summary',
    dashboardViolationWarnings:
        '/api/dashboards/violation-warnings/{assessmentId}',
    topFindings: '/api/dashboards/{assessmentId}/top-vulnerabilities',

    assessmentsForReports: '/api/reports/assessments',

    cweWeaknessSuggestions: '/api/cwe/weakness-suggestions',

    filters: '/api/filters',

    myNotifications: '/api/notifications/my/filtered',
    readSingleNotification: '/api/notifications/{notificationId}/mark-viewed',
    readNotifications: '/api/notifications/mark-viewed',
    projectNotificationSettings: '/api/notification-settings',
    loadTasksCategories: '/api/projects/{projectId}/issues-filter/load',

    featureToggles: '/api/feature-toggles',
    featureTogglesExtended: '/api/feature-toggles/list',
    singleFeatureToggle: '/api/feature-toggles/{featureToggleId}',
    singleFeatureToggleState: '/api/feature-toggles/{featureToggleId}/state',

    singleActivityComments: '/api/activity/{activityId}/comments',
    privateActionLog: '/api/action-logs/private',
    publicActionLog: '/api/action-logs/public',

    demoRequest: '/api/salespipeline/demo-requests',
    singleRequest: '/api/salespipeline/demo-requests/{requestId}',
    singleRequestStatus: '/api/salespipeline/demo-requests/{requestId}/status',
    singleRequestProgressSteps:
        '/api/salespipeline/demo-requests/{requestId}/progress-steps',
    singleRequestProgressStep:
        '/api/salespipeline/demo-requests/progress-steps/{stepId}',
    singleRequestMessages:
        'api/salespipeline/demo-requests/{requestId}/messages',
    singleRequestMessage:
        'api/salespipeline/demo-requests/{requestId}/messages/{messageId}',
    singleRequestAttachment:
        'api/salespipeline/demo-requests/attachments/{attachmentId}/content',
    singleRequestAssignees:
        '/api/salespipeline/demo-requests/possible-assignees',
    projectsAssignees: '/api/projects/{projectId}/assignees',
    asvs: '/api/asvs',

    singleActivity: 'api/activity/{activityId}',
};

export enum ApiStatusCodes {
    success = 200,
    validationError = 400,
    unauthorized = 401,
    forbidden = 403,
    notFound = 404,
    serverError = 500,
    restApiErrors = 498,
}

export enum ApiTypes {
    basic = 'BASIC',
    gateway = 'GATEWAY',
}

export const API_URLS = {
    [ApiTypes.basic]: {
        local: 'http://localhost:8090',
        local_api: 'http://localhost:8080',
        /** Compose env is for building the web for usage in docker-compose with api */
        compose: 'http://agpt-api:8090',
        drp: 'https://alb.drp.epamsecuritycenter.com',
        dev: 'https://alb.dev.epamsecuritycenter.com',
        uat: 'https://alb.uat.epamsecuritycenter.com',
        demo: 'https://alb.demo.epamsecuritycenter.com',
        prod: 'https://alb.prod.epamsecuritycenter.com',
        ext: 'https://alb.ext.epamsecuritycenter.com',
        // Review env is using by GitLab CI/CD pipelines
        review: `https://${process.env.REACT_APP_REVIEW_ENV_DOMAIN_NAME}:8090`,
    },
    [ApiTypes.gateway]: {
        local: 'http://localhost:8090',
        local_api: 'http://localhost:8080',
        /** Compose env is for building the web for usage in docker-compose with api */
        compose: 'http://agpt-api:8090',
        drp: 'https://api.drp.epamsecuritycenter.com',
        dev: 'https://api.dev.epamsecuritycenter.com',
        uat: 'https://api.uat.epamsecuritycenter.com',
        demo: 'https://api.demo.epamsecuritycenter.com',
        prod: 'https://api.prod.epamsecuritycenter.com',
        ext: 'https://api.ext.epamsecuritycenter.com',
        // Review env is using by GitLab CI/CD pipelines
        review: `https://${process.env.REACT_APP_REVIEW_ENV_DOMAIN_NAME}:8090`,
    },
};

export const AUTH_URLS = {
    [ApiTypes.basic]: {
        local: 'http://localhost:9095',
        local_api: 'http://localhost:9095',
        compose: 'http://agpt-auth:9095',
        drp: 'https://alb.drp.epamsecuritycenter.com:9095',
        dev: 'https://alb.dev.epamsecuritycenter.com:9095',
        uat: 'https://alb.uat.epamsecuritycenter.com:9095',
        demo: 'https://alb.demo.epamsecuritycenter.com:9095',
        prod: 'https://alb.prod.epamsecuritycenter.com:9095',
        ext: 'https://alb.ext.epamsecuritycenter.com:9095',
        // Review env is using by GitLab CI/CD pipelines
        review: `https://${process.env.REACT_APP_REVIEW_ENV_DOMAIN_NAME}:9095`,
    },
    [ApiTypes.gateway]: {
        local: 'http://localhost:9095',
        local_api: 'http://localhost:9095',
        compose: 'http://agpt-api:9095',
        drp: 'https://auth.drp.epamsecuritycenter.com',
        dev: 'https://auth.dev.epamsecuritycenter.com',
        uat: 'https://auth.uat.epamsecuritycenter.com',
        demo: 'https://auth.demo.epamsecuritycenter.com',
        prod: 'https://auth.prod.epamsecuritycenter.com',
        ext: 'https://auth.ext.epamsecuritycenter.com',
        // Review env is using by GitLab CI/CD pipelines
        review: `https://${process.env.REACT_APP_REVIEW_ENV_DOMAIN_NAME}:9095`,
    },
};

export const WEB_SOCKETS_URLS = {
    local: 'ws://localhost:8090/ws',
    local_api: 'ws://localhost:8090/ws',
    compose: 'ws://agpt-api:8090/ws',
    drp: 'wss://ws.drp.epamsecuritycenter.com',
    dev: 'wss://ws.dev.epamsecuritycenter.com',
    uat: 'wss://ws.uat.epamsecuritycenter.com',
    demo: 'wss://ws.demo.epamsecuritycenter.com',
    prod: 'wss://ws.prod.epamsecuritycenter.com',
    ext: 'wss://ws.ext.epamsecuritycenter.com',
    // Review env is using by GitLab CI/CD pipelines
    review: `wss://${process.env.REACT_APP_REVIEW_ENV_DOMAIN_NAME}/ws`,
};

export const apiType =
    (process.env.REACT_APP_API_TYPE as ApiTypes) ||
    (environment === EnvironmentTypes.local ||
    environment === EnvironmentTypes.local_api ||
    environment === EnvironmentTypes.review ||
    environment === EnvironmentTypes.compose
        ? ApiTypes.basic
        : ApiTypes.gateway);
