/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback } from 'react';
/**
 * Components
 */
import { Dropdown } from '@epam/promo';
import { DropdownBody, DropdownTarget } from './components';
/**
 * Types
 */
import { TButtonWithOptionsProps } from './types';
import { IDropdownToggler, DropdownBodyProps } from '@epam/uui-core';
/**
 * Expo
 */
export const ButtonWithOptions = <TValue extends string>({
    options,
    onClick,
}: TButtonWithOptionsProps<TValue>): JSX.Element => {
    const renderTarget = useCallback(
        (props: IDropdownToggler) => <DropdownTarget {...props} />,
        []
    );

    const renderBody = useCallback(
        (props: DropdownBodyProps) => (
            <DropdownBody<TValue>
                {...props}
                onClick={onClick}
                options={options}
            />
        ),
        [onClick, options]
    );

    return (
        <Dropdown
            placement="bottom-end"
            renderBody={renderBody}
            renderTarget={renderTarget}
        />
    );
};
