/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import {
    mapAssetFootprintsFormToPayload,
    mapAssetFootprintsPayloadToForm,
    mapNamedTmDtoToZodNamedTmDto,
} from '../utils';
import { FormValuesWithoutTmId } from '../baseItemFormSchema';
import { ELEMENT_READABLE_ID_PREFIX } from './constants';
import { ElementFormValues } from './schema';
import { createReadableId } from '../../../utils';
import {
    ElementCreatePayload,
    ElementUpdatePayload,
} from 'store/threatModeling/element/types';
import { TElement } from 'types';
import { ElementType } from 'types/threatModeling/element';
import { elementTypeToLabelMap } from '../constants';

export const mapElementTypeToLabel = (elementType: ElementType) =>
    elementTypeToLabelMap[elementType];

export function mapElementToFormValues(
    element: TElement
): FormValuesWithoutTmId<ElementFormValues> {
    return {
        id: element.id,
        name: element.name,
        readableId: createReadableId(
            ELEMENT_READABLE_ID_PREFIX,
            String(element.readable_id)
        ),
        type: element.type,
        technologies: element.technologies,
        comment: element.comment,
        assetFootprints: mapAssetFootprintsPayloadToForm(
            element.asset_footprints
        ),
        trustBoundary: mapNamedTmDtoToZodNamedTmDto(element.trust_boundary),
    };
}

export function mapFormValuesToElementCreatePayload(
    form: ElementFormValues
): ElementCreatePayload {
    return {
        tm_id: form.tmId,
        name: form.name,
        type: form.type,
        technologies: form.technologies.map((technology) => technology.id),
        comment: form.comment ?? null,
        asset_footprints: mapAssetFootprintsFormToPayload(form.assetFootprints),
        trust_boundary_id: form.trustBoundary?.id ?? null,
    };
}

export function mapFormValuesToElementUpdatePayload(
    form: ElementFormValues
): ElementUpdatePayload {
    return {
        name: form.name,
        type: form.type,
        technologies: form.technologies.map((technology) => technology.id),
        comment: form.comment ?? null,
        asset_footprints:
            form?.assetFootprints?.map((assetFootprint) => ({
                id: assetFootprint.id ?? null,
                asset_id: assetFootprint.asset.id,
                action_on_asset: assetFootprint.actionOnAsset,
            })) ?? null,
        trust_boundary_id: form.trustBoundary?.id ?? null,
    };
}
