/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/* eslint-disable class-methods-use-this, no-underscore-dangle */
import {
    $applyNodeReplacement,
    $createParagraphNode,
    DOMConversionMap,
    DOMConversionOutput,
    DOMExportOutput,
    EditorConfig,
    ElementFormatType,
    ElementNode,
    LexicalEditor,
    LexicalNode,
    RangeSelection,
} from 'lexical';
import {
    $createListItemNode,
    $createListNode,
    $isListItemNode,
    $isListNode,
    ListNode,
} from '@lexical/list';
import { SerializedQuoteNode } from '@lexical/rich-text';
import { calculateNextListStartNumber } from 'components/common/RichEditor2/utils';

function convertCodeElement(domNode: HTMLElement): null | DOMConversionOutput {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const node = $createCodeBlockNode();
    if (domNode.style !== null) {
        node.setFormat(domNode.style.textAlign as ElementFormatType);
    }
    return { node };
}

export class CodeBlockNode extends ElementNode {
    static getType(): string {
        return 'code-block';
    }

    static clone(node: CodeBlockNode): CodeBlockNode {
        return new CodeBlockNode(node.__key);
    }

    createDOM(config: EditorConfig): HTMLElement {
        const el = document.createElement('pre');

        const className = config.theme.codeBlock;
        if (className) el.className = className;

        return el;
    }

    exportDOM(editor: LexicalEditor): DOMExportOutput {
        const { element } = super.exportDOM(editor);

        if (element) {
            if (this.isEmpty() && element instanceof HTMLElement) {
                element.append(document.createElement('br'));
            }
        }

        return { element };
    }

    static importDOM(): DOMConversionMap | null {
        return {
            pre: () => ({
                conversion: convertCodeElement,
                priority: 0,
            }),
        };
    }

    static importJSON(serializedNode: SerializedQuoteNode) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const node = $createCodeBlockNode();
        node.setFormat(serializedNode.format);
        node.setIndent(serializedNode.indent);
        node.setDirection(serializedNode.direction);
        return node;
    }

    exportJSON() {
        return {
            ...super.exportJSON(),
            type: CodeBlockNode.getType(),
        };
    }

    updateDOM(): boolean {
        return false;
    }

    replaceWith(node: ElementNode): ElementNode {
        const children = this.getChildren();
        children.forEach((child) => node.append(child));
        this.replace(node);
        return node;
    }

    collapseAtStart(): boolean {
        const paragraph = $createParagraphNode();
        const children = this.getChildren();
        children.forEach((child) => paragraph.append(child));
        this.replace(paragraph);
        return true;
    }

    // When a user put a code block inside a list, the list is not continued due to Lexical limitations,
    // Lexical cannot insert a code block inside list items and put the code block outside the list as a new separate node (next sibling of this list).
    // https://github.com/facebook/lexical/issues/5218
    // This method is a workaround for this issue:
    // If a code block is inside a list (technically, it's a sibling), a new list is created after the code block
    private continueListAfterCodeBlock(
        initialList: ListNode,
        restoreSelection?: boolean
    ): null | LexicalNode {
        // code blocks are siblings of a nested list (just like in flat ones) therefore they are children of the parent list and need to be filtered out
        const childListItemsLength =
            initialList.getChildren()?.filter($isListItemNode).length ?? 0;
        const nextNumber = calculateNextListStartNumber(
            initialList,
            childListItemsLength
        );

        const newList = $createListNode(initialList.getListType(), nextNumber);
        const listItemNode = $createListItemNode();
        newList.append(listItemNode);
        const direction = this.getDirection();
        newList.setDirection(direction);
        this.insertAfter(newList, restoreSelection);

        return newList;
    }

    insertNewAfter(
        selection: RangeSelection,
        restoreSelection?: boolean
    ): null | LexicalNode {
        const children = this.getChildren();
        const childrenLength = children.length;
        const lastChild = children.at(-1);
        const previousSibling = this.getPreviousSibling();
        const nextSibling = this.getNextSibling();

        if (
            children.length >= 1 &&
            lastChild?.getTextContent() === '\n' &&
            selection.isCollapsed() &&
            selection.anchor.key === this.__key &&
            selection.anchor.offset === childrenLength
        ) {
            lastChild?.remove();
            const shouldContinueList =
                $isListNode(previousSibling) && !$isListNode(nextSibling);

            if (shouldContinueList) {
                const listNode = previousSibling;
                return this.continueListAfterCodeBlock(
                    listNode,
                    restoreSelection
                );
            }

            const newParagraphNode = $createParagraphNode();
            this.insertAfter(newParagraphNode, restoreSelection);

            return newParagraphNode;
        }

        return null;
    }
}

export function $isCodeBlockNode(
    node: LexicalNode | null
): node is CodeBlockNode {
    return node instanceof CodeBlockNode;
}

export const $createCodeBlockNode = (): CodeBlockNode => {
    return $applyNodeReplacement(new CodeBlockNode());
};
