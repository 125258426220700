/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createSelector } from '@reduxjs/toolkit';
/**
 * Types
 */
import { TSelector, TSelectorWithProps } from 'types';
import { TInitialState } from './types';
/**
 * Expo
 */
export const selectRequest: TSelector<TInitialState> = ({ singleRequest }) =>
    singleRequest;

const selectData = createSelector(selectRequest, ({ data }) => data);
const selectStepsData = createSelector(
    selectRequest,
    ({ stepsData }) => stepsData
);
const selectProps: TSelectorWithProps<
    Record<string, string>,
    Record<string, string>
> = (_, props) => props;

const selectStepsById = createSelector(
    selectStepsData,
    ({ stepsById }) => stepsById
);

export const selectDocumentLink = createSelector(
    [selectStepsData, selectProps],
    ({ stepsById }, { id }) => stepsById?.[id]?.document_link || {}
);

export const selectRequestId = createSelector(selectData, ({ id }) => id);

export const selectSalesManager = createSelector(
    selectData,
    ({ salesManager }) => salesManager
);

export const selectOperationsManager = createSelector(
    selectData,
    ({ operationManager }) => operationManager
);

export const selectInProgress = createSelector(
    selectRequest,
    ({ inProgress }) => inProgress
);

export const selectIsInitialized = createSelector(
    selectRequest,
    ({ isInitialized }) => isInitialized
);

export const selectRequestNumber = createSelector(
    selectData,
    ({ requestNumber }) => requestNumber || ''
);

export const selectStatus = createSelector(selectData, ({ status }) => status);
export const selectPreviousStatus = createSelector(
    selectData,
    ({ previousStatus }) => previousStatus
);

export const selectRequestInfo = createSelector(
    selectData,
    ({
        requestDate,
        organization,
        representative,
        status,
        salesManager,
        email,
        operationManager,
        message,
    }) =>
        requestDate && organization && representative && status
            ? {
                  status,
                  requestDate,
                  organization,
                  salesManager,
                  representative,
                  email,
                  operationManager,
                  message,
              }
            : undefined
);

export const selectAssignedSalesManger = createSelector(
    selectData,
    ({ salesManager }) => salesManager?.id
);

export const selectAssignedOperationManager = createSelector(
    selectData,
    ({ operationManager }) => operationManager?.id
);

export const selectTotalCheckedCount = createSelector(
    selectStepsData,
    ({ totalCheckedCount }) => totalCheckedCount
);
export const selectStepsIds = createSelector(
    selectStepsData,
    ({ stepsIds }) => stepsIds
);

export const selectStep = createSelector(
    [selectStepsById, selectProps],
    (stepsById, { id }) => stepsById && stepsById[id]
);

export const selectStepType = createSelector(
    [selectStep],
    (step) => step?.type
);

export const selectIsLastStep = createSelector(
    [selectTotalCheckedCount, selectStepsIds, selectStepsById, selectProps],
    (totalCheckedCount, stepsIds, stepsById, { id }) =>
        totalCheckedCount === stepsIds.length - 1 && !stepsById?.[id].checked
);

export const selectMessages = createSelector(
    [selectRequest, selectProps],
    ({ messageChannels }, { type }) =>
        messageChannels[type] || {
            messages: new Set(),
            pendingMessages: new Set(),
        }
);
