/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Expo
 */
export enum RoleTypes {
    admin = 'ROLE_ADMIN',
    manager = 'ROLE_MANAGER',
    salesManager = 'ROLE_SALES_MANAGER',
    operationsManager = 'ROLE_OPERATIONS_MANAGER',
    securitySpecialist = 'ROLE_SECURITY_SPECIALIST',
    projectRepresentative = 'ROLE_PROJECT_REPRESENTATIVE',
}

export const ROLE_TITLES = {
    [RoleTypes.manager]: 'Manager',
    [RoleTypes.admin]: 'Administrator',
    [RoleTypes.salesManager]: 'Sales Manager',
    [RoleTypes.operationsManager]: 'Operations Manager',
    [RoleTypes.securitySpecialist]: 'Security Specialist',
    [RoleTypes.projectRepresentative]: 'Project Representative',
};
