/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { forwardRef, useMemo } from 'react';
/**
 * Components
 */
import { Badge as UuiBadge } from '@epam/promo';
/**
 * Types
 */
import { BadgeProps } from './types';
/**
 * Utils
 */
import { getRawProps } from 'utils';
/**
 * Expo
 */
export const Badge: React.FC<BadgeProps> = forwardRef(
    (
        {
            cx,
            fill,
            color,
            isOpen,
            caption,
            isDropdown,
            dataTestId,
            dropdownIcon,
            size = '18',
            ...props
        },
        ref
    ) => {
        const rawProps = useMemo(
            () => getRawProps({ dataTestId }),
            [dataTestId]
        );

        return (
            <UuiBadge
                {...props}
                ref={ref}
                cx={cx}
                size={size}
                fill={fill}
                isOpen={isOpen}
                caption={caption}
                rawProps={rawProps}
                isDropdown={isDropdown}
                dropdownIcon={dropdownIcon}
                color={color}
            />
        );
    }
);
