/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback } from 'react';
/**
 * Components
 */
import { Button } from 'components/common';
/**
 * Hooks
 */
import { useRedirect } from 'hooks';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
/**
 * Assets
 */
import { ReactComponent as editIcon } from '@epam/assets/icons/common/content-edit-18.svg';
/**
 * Expo
 */
export const EditButton: React.FC = () => {
    const redirect = useRedirect();

    const handleEdit = useCallback(() => {
        redirect(PAGES.assessmentEditing);
    }, [redirect]);

    return (
        <Button
            iconPosition="left"
            icon={editIcon}
            fill="outline"
            caption="Manage scope"
            onClick={handleEdit}
            dataTestId="singleAssessment-edit-button"
        />
    );
};
