/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { PersonTabName, TListQuery } from './types';

/**
 * Expo
 */

export const getQuery = (
    activeTab: PersonTabName,
    search?: string
): TListQuery => {
    let query: TListQuery = {
        tabName: activeTab,
    };

    if (search) {
        query = {
            ...query,
            search,
            searchBy: 'fullName,email',
        };
    }

    return query;
};
