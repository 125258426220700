/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useMemo } from 'react';
/**
 * Components
 */
import { Dropdown } from '@epam/promo';
import { Link } from 'react-router-dom';
import { SingleTab } from '../SingleTab';
import { Panel, Text } from 'components/common';
/**
 * Types
 */
import { TNestedTab } from './types';
/**
 * Utils
 */
import { pick } from 'utils';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const NestedTab: React.FC<TNestedTab> = ({
    nestedMenu,
    dataTestId,
    activeTabId,
}) => {
    const isActive = useMemo(() => {
        const nestedIds = pick(nestedMenu, 'id');

        return nestedIds.includes(activeTabId);
    }, [nestedMenu, activeTabId]);

    const activeCurrentTabTitle = useMemo(() => {
        const { title } =
            nestedMenu.find(({ id }) => id === activeTabId) || nestedMenu[0];

        return title;
    }, [activeTabId, nestedMenu]);

    return (
        <Dropdown
            renderBody={(props) => (
                <Panel {...props} background="white" cx={css.dropdownBody}>
                    {nestedMenu.map(({ title, href = '' }) => (
                        <Link
                            to={href}
                            className={css.link}
                            id={`${dataTestId}-nestedItem`}
                        >
                            <Text>{title}</Text>
                        </Link>
                    ))}
                </Panel>
            )}
            renderTarget={(props) => (
                <SingleTab
                    {...props}
                    isActive={isActive}
                    dataTestId={dataTestId}
                    title={activeCurrentTabTitle || ''}
                />
            )}
        />
    );
};
