/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
/**
 * Components
 */
import { PrivateRoute } from '../../../PrivateRoute';
/**
 * Constants
 */
import { RoleTypes } from 'constants/roles';
/**
 * Pages
 */
const Tasks = lazy(() => import('pages/Tasks'));
const SingleTask = lazy(() => import('pages/SingleTask'));
const SingleManualTask = lazy(() => import('pages/SingleManualTask'));
/**
 * Expo
 */
export const TasksRoute: React.FC = () => (
    <Suspense fallback={null}>
        <Routes>
            <Route
                path="/"
                element={
                    <PrivateRoute
                        component={Tasks}
                        roles={[
                            RoleTypes.securitySpecialist,
                            RoleTypes.projectRepresentative,
                        ]}
                    />
                }
            />
            <Route
                path=":taskId"
                element={
                    <PrivateRoute
                        component={SingleTask}
                        roles={[
                            RoleTypes.securitySpecialist,
                            RoleTypes.projectRepresentative,
                        ]}
                    />
                }
            />
            <Route
                path="manual/:taskId"
                element={
                    <PrivateRoute
                        component={SingleManualTask}
                        roles={[
                            RoleTypes.securitySpecialist,
                            RoleTypes.projectRepresentative,
                        ]}
                    />
                }
            />
        </Routes>
    </Suspense>
);
