/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { useBlocker } from 'hooks/useBlocker/useBlocker';
import { useCallback } from 'react';
import { showModal } from 'utils';
import { Modal } from 'components/common';
import { ModalProps } from 'components/common/Modal/types';
import { useBeforeUnload } from 'hooks/useBeforeUnload';
import { Transition } from 'hooks/useBlocker/types';

/**
 * Shows confirmation dialog before a user leave the current screen.
 * `useBlocker` and `usePrompt` were temporarily removed after the 6.0.0 beta. This is a reimplementation.
 *
 * Source: https://gist.github.com/rmorse/426ffcc579922a82749934826fa9f743
 *
 * Issue: https://github.com/remix-run/react-router/issues/8139
 * @see https://reactrouter.com/en/main/hooks/use-prompt
 */
export function useUnsavedChangesDialog(
    when = true,
    title = 'Save changes',
    message = 'Do you want to save changes to the item?'
) {
    useBeforeUnload(when);

    const blocker = useCallback(
        (tx: Transition) => {
            showModal<Partial<ModalProps>>(Modal, {
                title,
                bodyText: message,
                successButtonCaption: 'Continue',
                cancelButtonCaption: "Don't save",
                allowCloseOnCross: false,
                onCancel: tx.retry,
                onSuccess: () => {},
                cancelButtonAppearance: {
                    fill: 'outline',
                    color: 'critical',
                },
            });
        },
        [message, title]
    );
    useBlocker(blocker, when);
}
