/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { generatePath } from 'utils';
import { PAGES } from 'constants/pages';
import { useAppSelector } from 'hooks';

import { selectIsThreatModelingActivity } from 'store/threatModeling';

export function useActivityPath(
    id: string,
    isOperationsManager: boolean,
    isSecuritySpecialist: boolean,
    params: {
        projectId: string;
        assessmentId: string;
    }
) {
    const { projectId, assessmentId } = params;
    const isThreatModeling = useAppSelector(selectIsThreatModelingActivity(id));

    if (isThreatModeling && isSecuritySpecialist) {
        return generatePath(PAGES.threatModeling, {
            projectId,
            activityId: id,
        });
    }

    const redirectPath = isOperationsManager
        ? PAGES.singleActivityOperationManager
        : PAGES.singleActivity;

    return generatePath(redirectPath, {
        projectId,
        assessmentId,
        activityId: id,
    });
}
