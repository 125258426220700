/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import React from 'react';
import { Text } from '@epam/promo';
import { GetColumnsFn } from './types';
import { DataColumnProps } from '@epam/uui-core';
import { AssessmentStatus } from 'components/AssessmentStatus';
import {
    ActionsCell,
    Activities,
    Findings,
    NameCell,
    Performers,
} from './components';
import { RoleTypes } from 'constants/roles';
import { CrudTypes } from 'constants/actions';
import { TAssessment } from 'types';
import { formatDateRange } from 'utils';
import { DATE_FORMATS } from 'constants/dates';

const COMMON_COLUMNS: Record<string, DataColumnProps<TAssessment>> = {
    status: {
        grow: 1,
        width: 100,
        key: 'status',
        caption: 'Status',
        render: ({ status }) => (
            <AssessmentStatus
                type={status}
                dataTestId="assessments-table-cells-status"
            />
        ),
    },
    assessmentName: {
        grow: 1,
        width: 100,
        key: 'assessment',
        caption: 'Assessment',
        render: ({ id, name }) => <NameCell id={id} name={name} />,
    },
    performers: {
        grow: 1,
        width: 50,
        key: 'performers',
        caption: 'Performers',
        render: (props) => <Performers {...props} />,
    },
    activities: {
        grow: 4,
        width: 400,
        key: 'activities',
        caption: 'Current activities',
        render: ({ name, metrics }) => (
            <div style={{ width: 400 }}>
                <Activities
                    name={name}
                    activity_statuses={metrics?.activity_statuses}
                />
            </div>
        ),
    },
    findings: {
        grow: 1,
        width: 50,
        key: 'findings',
        caption: 'Findings ',
        render: ({
            id,
            metrics: {
                finding_metrics: { findings },
            },
        }) => (
            <Findings
                assessmentId={id}
                count={findings}
                dataTestId="assessments-table-cells-findings"
            />
        ),
    },
    period: {
        grow: 2,
        width: 150,
        key: 'period',
        caption: 'Period',
        render: ({ start_date, end_date }) => (
            <Text>
                {formatDateRange({
                    to: end_date,
                    from: start_date,
                    format: DATE_FORMATS.US.date,
                })}
            </Text>
        ),
    },
    edit: {
        grow: 0,
        width: 54,
        key: CrudTypes.edit,
        render: ({ id }) => {
            return <ActionsCell assessmentId={id} />;
        },
    },
};

export const getDefaultColumns: GetColumnsFn = () => [
    COMMON_COLUMNS.status,
    COMMON_COLUMNS.assessmentName,
    COMMON_COLUMNS.performers,
    COMMON_COLUMNS.activities,
    COMMON_COLUMNS.findings,
    COMMON_COLUMNS.period,
];

export const getColumnsForOperationsManager: GetColumnsFn = () => [
    ...getDefaultColumns(),
    COMMON_COLUMNS.edit,
];

export const getColumnsByRole = (role: RoleTypes | undefined): GetColumnsFn => {
    if (role === RoleTypes.operationsManager) {
        return getColumnsForOperationsManager;
    }

    return getDefaultColumns;
};
