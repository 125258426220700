/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Panel } from '@epam/promo';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const HelpGuideButton: React.FC = () => (
    <Panel cx={css.container}>
        <a href="/help" target="_blank" className={css.link}>
            <img src="/assets/icons/help.svg" alt="help guide" />
        </a>
    </Panel>
);
