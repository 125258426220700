/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/* eslint-disable react/no-unused-prop-types */
import React, { EffectCallback, FC, useEffect, useMemo, useRef } from 'react';

import {
    InitialConfigType,
    LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { Nodes } from './nodes';
import './theme/theme.scss';
import { editorID, theme } from './theme';
import { ToolbarPlugin } from './plugins/ToolbarPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { CodeBlockPlugin } from './plugins/CodeBlock';
import ImagesPlugin, { useDecorateChangeHandler } from './plugins/ImagePlugin';
import DragDropPaste from './plugins/DragDropPastePlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { LinkEditorPlugin } from './plugins/LinkEditorPlugin';
import { LabeledInput } from '@epam/promo';
import { getRawProps } from '../../../utils';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import {
    $createParagraphNode,
    $getRoot,
    $isBlockElementNode,
    EditorState,
    LexicalEditor,
} from 'lexical';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { TContentImages, TRichEditorProps } from './types';
import { cx } from '@epam/uui-core';
import { AttachmentsPlugin } from './plugins/AttachmentsPlugin';
import { ImageNode } from './nodes/Image/ImageNode';
import { useOnMount } from 'hooks';

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: Error) {
    // eslint-disable-next-line no-console
    console.error(error);
}

const initialConfig: InitialConfigType = {
    namespace: editorID,
    onError,
    nodes: [...Nodes],
    theme,
};

function initializeEditor(editor: LexicalEditor, html: string) {
    if (html)
        editor.update(() => {
            const parser = new DOMParser();
            // TODO: fix this later
            // parseFromString('<p><figure><img src="some" /></figure></p>')
            // By some result looks like '<p></p><figure><img src="some" /></figure><p></p>'
            const dom = parser.parseFromString(
                html.replace(/<.?figure>/g, ''),
                'text/html'
            );

            const nodes = $generateNodesFromDOM(editor, dom);

            const root = $getRoot();
            root.clear();
            nodes.forEach((node) => {
                if (!$isBlockElementNode(node)) {
                    const p = $createParagraphNode();
                    p.append(node);
                    node = p;
                }
                root.append(node);
            });
        });
}

const InitialStatePlugin: FC<{
    html: string;
    updateOn?: unknown[];
    isFormReset?: boolean;
}> = ({ html, isFormReset }) => {
    const [editor] = useLexicalComposerContext();

    useOnMount(() => {
        initializeEditor(editor, html);
    });

    useEffect(() => {
        const usedWithoutForm = isFormReset === undefined;

        if (usedWithoutForm) {
            initializeEditor(editor, html);
            return;
        }

        if (isFormReset) initializeEditor(editor, html);
    }, [editor, html, isFormReset]);

    return null;
};

type EffectPluginProps = {
    fn: (editor: LexicalEditor) => ReturnType<EffectCallback>;
    dependencyList: unknown[];
};
const EffectPlugin: FC<EffectPluginProps> = ({ fn, dependencyList }) => {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        return fn(editor);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor, ...dependencyList]);
    return null;
};

export function RichEditor2({
    isRequired,
    readOnly,
    placeholder,
    label,
    isInvalid,
    validationMessage,
    dataTestId,
    onChange,
    initialValue,
    value,
    attachedImages,
    minRows,
    formState,
    withInlineImages = false,
}: TRichEditorProps) {
    const initialHtml = (value || initialValue) ?? '';
    const editorRef = useRef<LexicalEditor | null>(null);
    const config: InitialConfigType = {
        editable: !readOnly,
        editorState: (editor) => {
            editorRef.current = editor;
        },
        ...initialConfig,
    };
    const rawProps = useMemo(() => getRawProps({ dataTestId }), [dataTestId]);

    const handleChange =
        (imagesPayload: TContentImages) =>
        (editorState: EditorState, editor: LexicalEditor) => {
            if (onChange) {
                editorState.read(() => {
                    ImageNode.turnOnExportMode();
                    const html = $generateHtmlFromNodes(editor);
                    ImageNode.turnOffExportMode();

                    onChange(html, imagesPayload);
                });
            }
        };
    const decoratedChangeHandler = useDecorateChangeHandler(
        handleChange,
        editorRef.current
    );

    return (
        <LexicalComposer initialConfig={config}>
            <LabeledInput
                label={label}
                cx={theme.labeledInput}
                isInvalid={isInvalid}
                isRequired={isRequired}
                validationMessage={validationMessage}
                rawProps={rawProps}
            >
                {readOnly || <ToolbarPlugin />}
                <div
                    className={cx(
                        theme.editor.root,
                        readOnly ? theme.editor.readonly : ''
                    )}
                >
                    <RichTextPlugin
                        contentEditable={
                            <ContentEditable
                                placeholder={placeholder}
                                className={theme.editor.input}
                                style={
                                    minRows == null
                                        ? undefined
                                        : { minHeight: `${minRows * 16}px` }
                                }
                            />
                        }
                        placeholder={<></>}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <DragDropPaste />
                    <ListPlugin />
                    <LinkPlugin />
                    <LinkEditorPlugin />
                    <TabIndentationPlugin />
                    <HistoryPlugin />
                    <CodeBlockPlugin />
                    {withInlineImages && (
                        <ImagesPlugin attachedImages={attachedImages} />
                    )}
                    <OnChangePlugin
                        onChange={decoratedChangeHandler}
                        ignoreSelectionChange
                    />
                    <EffectPlugin
                        fn={(editor) => editor.setEditable(!readOnly)}
                        dependencyList={[readOnly]}
                    />
                    <AttachmentsPlugin />

                    <InitialStatePlugin
                        html={initialHtml}
                        isFormReset={formState && !formState.isDirty}
                    />
                </div>
            </LabeledInput>
        </LexicalComposer>
    );
}
