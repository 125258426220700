/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { AppDispatch, TPolicy } from 'types';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
/**
 * Actions
 */
import { saveData, setInProgress } from './actions';
/**
 * Utils
 */
import { templateString } from 'utils';
/**
 * Expo
 */
export const handleGetData =
    (policyId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = templateString(ENDPOINTS.policy, {
            policyId,
        });

        try {
            dispatch(setInProgress(true));

            const data = await api.request<TPolicy>(Methods.get, {
                url,
            });

            dispatch(saveData(data));
        } finally {
            dispatch(setInProgress(false));
        }
    };
