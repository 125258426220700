/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { RoleTypes } from 'constants/roles';
import { UserStatuses } from 'constants/statuses';
import { TGeneralInitialState, TPerson } from 'types';

/**
 * Expo
 */
export const INITIAL_STATE: TGeneralInitialState<TPerson> = {
    data: {
        is_blocked: false,
        email: '',
        first_name: '',
        full_name: '',
        id: '',
        last_name: '',
        organization_id: '',
        role: {
            id: 0,
            name: RoleTypes.admin,
            description: '',
            enabled: false,
        },
        roles: [],
        status: UserStatuses.active,
    },
    isInitialized: false,
    inProgress: false,
};
