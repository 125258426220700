/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { ReportTypes } from 'constants/reports';
/**
 * Types
 */
import { TInitialState } from './types';
/**
 * Utils
 */
import {
    createRequestBody,
    createRequestBodyWithLink,
    createRequestBodyWithFiles,
    createRequestBodyWithDocxFiles,
} from './utils';
/**
 * Expo
 */
export const INITIAL_STATE: TInitialState = {
    list: [],
    filter: {},
    isEmpty: true,
    inProgress: false,
    isInitialized: false,
};

export const ENDPOINTS_BY_TYPE = {
    [ReportTypes.withLink]: ENDPOINTS.reportWithLink,
    [ReportTypes.withFiles]: ENDPOINTS.reportWithFiles,
    [ReportTypes.docx]: ENDPOINTS.reportConfiguredDocx,
    [ReportTypes.withDocxFile]: ENDPOINTS.reportUploadDocs,
    [ReportTypes.pdf]: ENDPOINTS.reportConfiguredPdf,
};

export const CREATE_REQUEST_BODY_BY_TYPE = {
    [ReportTypes.withLink]: createRequestBodyWithLink,
    [ReportTypes.withFiles]: createRequestBodyWithFiles,
    [ReportTypes.docx]: createRequestBody,
    [ReportTypes.withDocxFile]: createRequestBodyWithDocxFiles,
    [ReportTypes.pdf]: createRequestBody,
};
