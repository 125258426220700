/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useMemo } from 'react';
/**
 * Components
 */
import { Switch as UuiSwitcher } from '@epam/promo';
/**
 * Types
 */
import { SwitchProps } from './types';
/**
 * Utils
 */
import { getRawProps } from 'utils';
import { IHasCX } from '@epam/uui-core';
/**
 * Expo
 */
export const Switch: React.FC<SwitchProps & IHasCX> = ({
    onValueChange,
    dataTestId,
    value = false,
    ...props
}) => {
    const rawProps = useMemo(
        () => ({ ...getRawProps({ dataTestId }) }),
        [dataTestId]
    );
    return (
        <UuiSwitcher
            {...props}
            value={value}
            rawProps={rawProps}
            onValueChange={onValueChange}
        />
    );
};
