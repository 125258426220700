/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
    ElementNode,
    LexicalNode,
    NodeMutation,
    RangeSelection,
    TextNode,
} from 'lexical';
import { $isAtNodeEnd } from '@lexical/selection';
import { templateString } from 'utils';
import { ENDPOINTS } from 'constants/api';
import { api } from 'services/api';
import { Methods } from 'constants/request';

export function getSelectedNode(
    selection: RangeSelection
): TextNode | ElementNode {
    const { anchor } = selection;
    const { focus } = selection;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
        return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
        return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    }
    return $isAtNodeEnd(anchor) ? anchorNode : focusNode;
}

export const requestAttachedImage = async (id: string): Promise<File> => {
    const url = templateString(ENDPOINTS.findingDocument, {
        documentId: id,
    });

    return api.request<File>(Methods.get, {
        url,
        responseType: 'blob',
        headers: { 'Content-type': 'application/octet-stream' },
    });
};

export const calculateNextListStartNumber = (
    node: LexicalNode,
    listItemChildrenLength: number,
    action?: NodeMutation
) => {
    return action === 'destroyed'
        ? node.getStart()
        : node.getStart() + listItemChildrenLength;
};
