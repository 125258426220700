/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Modal } from 'components/common';
/**
 * Constants
 */
import { ModalSizes } from 'constants/modals';
/**
 * Types
 */
import { TModal } from './types';
/**
 * Expo
 */
export const DisconnectModal: TModal = ({ integrationTitle, ...props }) => (
    <Modal
        {...props}
        title={`Disconnect integration with ${integrationTitle}?`}
        bodyText={`Integration with ${integrationTitle} will be deleted. You can connect to ${integrationTitle} any time.`}
        successButtonCaption="Submit"
        width={ModalSizes.large}
    />
);
