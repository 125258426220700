/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
    $getSelection,
    $isRangeSelection,
    COMMAND_PRIORITY_LOW,
    createCommand,
} from 'lexical';
import { useEffect } from 'react';
import { showModal } from 'utils';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { LinkModal } from './LinkModal';
import { getSelectedNode } from '../../utils';

export const EDIT_LINK_COMMAND = createCommand<undefined>('EDIT_LINK_COMMAND');

export const LinkEditorPlugin = () => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        const createLink = (url: string) => {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
        };
        editor.registerCommand(
            EDIT_LINK_COMMAND,
            () => {
                const selection = $getSelection();
                // TODO: fix this shitty modal typing
                let initialValue = 'https://' as string | undefined;

                if ($isRangeSelection(selection)) {
                    const node = getSelectedNode(selection);
                    const parent = node.getParent();
                    // eslint-disable-next-line no-nested-ternary
                    const linkNode = $isLinkNode(node)
                        ? node
                        : $isLinkNode(parent)
                        ? parent
                        : null;
                    if (linkNode) {
                        initialValue = linkNode.getURL();
                    }
                }

                showModal(LinkModal, {
                    initialValue,
                    onSubmit: createLink,
                });

                return true;
            },
            COMMAND_PRIORITY_LOW
        );
    }, [editor]);

    return null;
};
