/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * To add a new Non-Local environment, follow these steps:
 * 1. This file:
 *      1.1 Add a new field to EnvironmentTypes, e.g. new_env_name = 'name'
 *      1.2 Add a new field to ENVIRONMENT_BY_HOST_NAME, e.g 'new-env-host-name.com' = EnvironmentTypes.new_env_name
 * 2. In src/constants/api.ts file:
 *      2.1 Add a new field to API_URLS.ApiTypes.basic and API_URLS.ApiTypes.gateway, e.g. new_env_name = 'URL'
 *      2.2 Add a new field to AUTH_URLS.ApiTypes.basic and AUTH_URLS.ApiTypes.gateway, e.g. new_env_name = 'URL'
 *      2.3 Add a new field to WEB_SOCKETS_URLS, e.g. new_env_name = 'URL'
 * 3. In package.json file:
 *      3.1 Add a new build script, e.g. "build:new_env_name": "cross-env GENERATE_SOURCEMAP=false REACT_APP_STAGE=new_env_name node scripts/build",
 */
export enum EnvironmentTypes {
    local = 'local',
    local_api = 'local_api',
    compose = 'compose',
    review = 'review',
    drp = 'drp',
    dev = 'dev',
    uat = 'uat',
    demo = 'demo',
    prod = 'prod',
    ext = 'ext',
}

export const LOCAL_ENVIRONMENTS = [
    EnvironmentTypes.local,
    EnvironmentTypes.local_api,
    EnvironmentTypes.compose,
    EnvironmentTypes.review,
];

export const ENVIRONMENT_BY_HOST_NAME: Record<string, EnvironmentTypes> = {
    'dev.epamsecuritycenter.com': EnvironmentTypes.dev,
    'drp.epamsecuritycenter.com': EnvironmentTypes.drp,
    'uat.epamsecuritycenter.com': EnvironmentTypes.uat,
    'demo.epamsecuritycenter.com': EnvironmentTypes.demo,
    'epamsecuritycenter.com': EnvironmentTypes.prod,
    'ext.epamsecuritycenter.com': EnvironmentTypes.ext,
};

export const environment = process.env.REACT_APP_STAGE as EnvironmentTypes;
