/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { FlexRow, Text } from 'components/common';
import { HARDCODED_INTERACTION_TYPE } from './forms/Interaction/constants';
import { FC } from 'react';
import { ItemType } from '../../../../constants';
import { FormMode } from '../../types';
import { itemTypeToEditorTitleMap } from './constants';
import { typeToItemCardMap } from './helpers';

export interface ItemEditorProps {
    type: ItemType;
    onClose: () => void;
}

export const ItemEditor: FC<ItemEditorProps> = ({ onClose, type }) => {
    const title = itemTypeToEditorTitleMap[type];

    const FormComponent = typeToItemCardMap[type];
    // TODO: any because it's a temporary solution, defaults should be extracted from schema
    const defaultValues = (
        type === ItemType.interaction
            ? { type: HARDCODED_INTERACTION_TYPE }
            : {}
    ) as any; // eslint-disable-line @typescript-eslint/no-explicit-any

    return (
        <FormComponent
            initialMode={FormMode.create}
            isOpen
            defaultValues={defaultValues}
            header={
                <FlexRow>
                    <Text fontSize="14" cx="text-bold">
                        New {title}
                    </Text>
                </FlexRow>
            }
            onClose={onClose}
        />
    );
};
