/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
import { SYSTEM_NAME } from 'constants/system';
/**
 * Expo
 */
export const DEFAULT_PAGE_TITLE = SYSTEM_NAME;

export const TITLE_BY_PAGE = {
    [PAGES.home]: `${SYSTEM_NAME} | Pentesting as a Service`,
    [PAGES.about]: `About | ${SYSTEM_NAME}`,
    // TODO: Remove this after new landing release
    [PAGES.demoRequest]: `Request a demo | ${SYSTEM_NAME}`,
    [PAGES.login]: `Log in | ${SYSTEM_NAME}`,
    [PAGES.actionLog]: 'Action log',
    [PAGES.assessmentCreation]: 'Add Assessment',
    [PAGES.assessmentEditing]: 'Edit assessment',
    [PAGES.targets]: 'Targets',
    [PAGES.dashboard]: 'Dashboard',
    [PAGES.findingGeneration]: 'Generate finding from vulnerability',
    [PAGES.projectAttachments]: 'Project attachments',
    [PAGES.projectsCreate]: 'Add Project',
    [PAGES.projectInfo]: 'Project Info',
    [PAGES.findingCreation]: 'Add finding',
    [PAGES.findingEditing]: 'Edit Finding',
    [PAGES.singleReport]: 'View Report',
    [PAGES.jiraIntegration]: 'Add Jira integration',
    [PAGES.addTeamsIntegration]: 'Add Teams integration',
    [PAGES.addSlackIntegration]: 'Add Slack integration',
    [PAGES.addAzureIntegration]: 'Add Azure DevOps integration',
    [PAGES.editAzureIntegration]: 'Edit Azure DevOps integration',
    [PAGES.addAzureIntegrationOperationsManager]:
        'Add Azure DevOps integration',
    [PAGES.editAzureIntegrationOperationsManager]:
        'Edit Azure DevOps integration',
    [PAGES.blockedUser]: 'Blocked user page',
    [PAGES.singleRequest]: 'Request page',
    [PAGES.singleAssessment]: 'Assessment page',
    [PAGES.singleFinding]: 'Finding page',
    [PAGES.singleTask]: 'Task page',
    [PAGES.singleManualTask]: 'Task page',
    [PAGES.projectsProjectInfo]: 'Project page',
    [PAGES.singleVulnerability]: 'Vulnerability page',
    [PAGES.withoutProject]: 'Blank project page',
    [PAGES.persons]: 'Users list',
    [PAGES.person]: 'Person page',
    [PAGES.projects]: 'Projects list',
    [PAGES.reports]: 'Reports list',
    [PAGES.organizations]: 'Organizations list',
    [PAGES.findings]: 'Findings list',
    [PAGES.assessments]: 'Assessments list',
    [PAGES.activities]: 'Activities list',
    [PAGES.requests]: 'Requests list',
    [PAGES.singleActivity]: 'Activity card',
    [PAGES.tasks]: 'Tasks list',
    [PAGES.vulnerabilities]: 'Vulnerabilities list',
    [PAGES.featureToggles]: 'Feature toggles',
    [PAGES.projectEdit]: 'Edit project',
    [PAGES.editSlackIntegration]: 'Edit Slack integration',
    [PAGES.editTeamsIntegration]: 'Edit MS Teams integration',
    [PAGES.projectsProjectInfo]: 'Project info',
    [PAGES.singleProjectNotifications]: 'Notifications list',
    [PAGES.notifications]: 'Notifications list',
};

export const DESCRIPTION_BY_PAGE = {
    [PAGES.home]: 'Get the pen tests you need with a provider you can trust.',
    [PAGES.demoRequest]:
        "Take your security to the next level with EPAM's Agile Security Platform.",
    [PAGES.login]:
        'Access your security posture dashboard, active assessments, findings, and reports.',
    [PAGES.about]:
        "EPAM's Agile Security Platform is an enterprise-grade security testing platform that enables companies to respond to security testing needs with the speed and quality required to meet security requirements applicable to their technology assets.",
};
