/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Utils
 */
import { v4 as uuidv4 } from 'uuid';
import { NotificationTypes, showNotification } from 'utils';
/**
 * Types
 */
import { TUploadedFile } from 'types';
/**
 * Expo
 */
export const getSizeToMegabytes = (sizeInBytes: number): number => {
    return Number((sizeInBytes / (1024 * 1024)).toFixed(2));
};

export const getExtensionFromFileName = (fileName: string): string => {
    const firstExtensionIndex = fileName.lastIndexOf('.') + 1;

    return fileName.substring(firstExtensionIndex).toUpperCase();
};

export const getNameWithoutExtension = (str: string): string =>
    str.replace(/\.[^/.]+$/, '');

export const downloadFile = (data: Blob, name: string): void => {
    const link = document.createElement('a');

    link.href = URL.createObjectURL(data);
    link.setAttribute('download', name);
    link.style.display = 'none';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
};

export const convertToBase64 = (
    file: File
): Promise<string | ArrayBuffer | null> =>
    new Promise((resolve) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onloadend = () => {
            resolve(reader.result);
        };
    });

export const getUploadedFiles = ({
    files,
    fileFormat,
    maxSize = 50,
    onWrongExtension,
}: {
    files: File[];
    maxSize?: number;
    fileFormat?: string;
    onWrongExtension?: () => void;
}): TUploadedFile[] =>
    files.reduce((accumulator: TUploadedFile[], file) => {
        const { name, size } = file;
        const extension = getExtensionFromFileName(file.name).toLowerCase();

        // max size of single file
        if (getSizeToMegabytes(size) > maxSize) {
            showNotification({
                text: `File "${name}" cannot be uploaded: file size exceeds ${maxSize} MB, try to upload another file`,
                type: NotificationTypes.error,
            });
        } else if (fileFormat && !fileFormat.includes(extension)) {
            if (onWrongExtension) {
                onWrongExtension();
                return accumulator;
            }
            showNotification({
                text: `File "${name}" cannot be uploaded: file format should be ${fileFormat} only, try to upload another file`,
                type: NotificationTypes.error,
            });
        } else {
            accumulator.push({
                file,
                id: uuidv4(),
            });
        }

        return accumulator;
    }, []);

export const urlToFile = async (
    url: string,
    filename: string,
    mimeType: string
): Promise<File> => {
    const result = await fetch(url);
    const buffer = await result.arrayBuffer();

    return new File([buffer], filename, { type: mimeType });
};
