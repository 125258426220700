/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { PreviewNotPDF } from './PreviewNotPDF';
import { Blocker, Panel } from '@epam/promo';
import { PreviewPDF } from 'components/common/PreviewPDF';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Constants
 */
import { FileExtensions } from 'constants/file';
/**
 * Types
 */
import { TDocumentPreview } from './types';
/**
 * Expo
 */
export const DocumentPreview: React.FC<TDocumentPreview> = ({
    file,
    name,
    inProgress,
}) => {
    const fileExtension = name.split('.').pop();
    const isPDF = fileExtension === FileExtensions.pdf;

    return (
        <Panel cx={css.container}>
            {!file || !isPDF ? null : <PreviewPDF file={file} />}
            {!file || isPDF ? null : <PreviewNotPDF name={name} />}
            {file ? null : (
                <Panel cx={css.blockerImageContainer}>
                    <img
                        alt="report"
                        className={css.blockerImage}
                        src="/assets/images/reportPlaceholder.png"
                    />
                </Panel>
            )}
            <Blocker isEnabled={inProgress} />
        </Panel>
    );
};
