/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { SyntheticEvent, useMemo } from 'react';
/**
 * Components
 */
import { LabeledInput, TextInput as UuiTextInput } from '@epam/promo';
/**
 * Constants
 */
import { VALIDATION_MESSAGE } from 'constants/validation';
import { NUMBER_REGEXP } from './constants';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { TNumericInputProps } from './types';
/**
 * Utils
 */
import { getRawProps } from 'utils';
import { cx } from '@epam/uui-core';
/**
 * Expo
 */
export const NumericInput: React.FC<TNumericInputProps> = ({
    label,
    isInvalid,
    value,
    isDisabled,
    isReadonly,
    isRequired,
    dataTestId,
    placeholder,
    validationMessage = VALIDATION_MESSAGE,
    onValueChange,
    isOptional,
    info,
    onBlur,
    labelCx,
    ...props
}) => {
    const handleChange = (inputValue: string) => {
        if (!onValueChange) return;

        const isValid = NUMBER_REGEXP.test(inputValue);

        if (isValid) {
            onValueChange(inputValue);
        }
    };

    const handleBlur = (event: SyntheticEvent<HTMLElement, Event>) => {
        const numberValue = Number(value);

        if (!Number.isNaN(numberValue) && value !== '') {
            onValueChange(numberValue.toString());
        }
        onBlur?.(event);
    };

    const rawProps = useMemo(() => getRawProps({ dataTestId }), [dataTestId]);

    return (
        <LabeledInput
            label={label}
            isInvalid={isInvalid}
            isRequired={isRequired}
            validationMessage={validationMessage}
            isOptional={isOptional}
            cx={cx(css.inputLabel, labelCx)}
            info={info}
        >
            <UuiTextInput
                {...props}
                value={value}
                rawProps={rawProps}
                isInvalid={isInvalid}
                isDisabled={isDisabled}
                isReadonly={isReadonly}
                placeholder={placeholder}
                onValueChange={handleChange}
                onBlur={handleBlur}
            />
        </LabeledInput>
    );
};
