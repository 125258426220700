/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { WithChildren } from 'types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const SideBar: React.FC<{ isOpen: boolean } & WithChildren> = ({
    children,
    isOpen,
}) =>
    ReactDOM.createPortal(
        <>
            <CSSTransition
                in={isOpen}
                timeout={{ enter: 200, exit: 200 }}
                unmountOnExit
            >
                <div className={css.blurBackground} />
            </CSSTransition>
            <CSSTransition
                classNames="animationPlatformSwitcher"
                in={isOpen}
                timeout={{ enter: 100, exit: 500 }}
                unmountOnExit
            >
                <div>{children}</div>
            </CSSTransition>
        </>,
        document.body
    );
