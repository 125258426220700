/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createSlice } from '@reduxjs/toolkit';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { INITIAL_STATE } from './constants';
import { Methods } from 'constants/request';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Types
 */
import { AppDispatch, TGetState, TRequest, TSelector } from 'types';
/**
 * Reducer
 */
const { reducer, actions } = createSlice({
    name: 'requests',
    initialState: INITIAL_STATE,
    reducers: {
        saveList: (state, { payload }) => {
            state.list = payload;

            state.isInitialized = true;
        },
        setInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        setIsEmpty: (state, { payload }) => {
            state.isEmpty = payload;
        },
        resetData: () => INITIAL_STATE,
    },
});

export default reducer;
/**
 * Selectors
 */
export const selectRequests: TSelector<TRequest[]> = ({ requests: { list } }) =>
    list;
export const selectInProgress: TSelector<boolean> = ({
    requests: { inProgress },
}) => inProgress;
export const selectIsInitialized: TSelector<boolean> = ({
    requests: { isInitialized },
}) => isInitialized;
export const selectIsEmpty: TSelector<boolean> = ({ requests: { isEmpty } }) =>
    isEmpty;
/**
 * Actions
 */
export const { saveList, resetData, setIsEmpty, setInProgress } = actions;
/**
 * Dispatchers
 */
export const handleGetRequests =
    () =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const isEmpty = selectIsEmpty(getState());

        dispatch(setInProgress(true));

        const data = await api.request<TRequest[]>(Methods.get, {
            url: ENDPOINTS.demoRequest,
        });

        if (data.length && isEmpty) {
            dispatch(setIsEmpty(false));
        }

        dispatch(saveList(data));

        dispatch(setInProgress(false));
    };
