/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
import { useSelector } from 'react-redux';
/**
 * Components
 */
import { PanelBlock, Text, FlexRow, UserList } from 'components/common';
import { AssessmentStatus } from 'components/AssessmentStatus';
/**
 * Types
 */
import { TInformationProps } from './types';
/**
 * Store
 */
import { selectProjectAdditionalInfo } from 'store/project';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const Information: React.FC<TInformationProps> = ({ status }) => {
    const { securitySpecialists } = useSelector(selectProjectAdditionalInfo);

    return (
        <PanelBlock>
            <Text
                fontSize="24"
                color="gray80"
                fontWeight="400"
                dataTestId="assessment/infoSection/title"
            >
                Assessment info
            </Text>

            <FlexRow vPadding="12" columnGap="6">
                <Text color="gray60" fontSize="16" cx={css.titleColumn}>
                    Status
                </Text>
                {status && (
                    <AssessmentStatus
                        type={status}
                        dataTestId="assessment/infoSection/status"
                    />
                )}
            </FlexRow>

            <FlexRow cx="align-baseline">
                <Text color="gray60" fontSize="16" cx={css.titleColumn}>
                    Performers
                </Text>
                <UserList
                    list={securitySpecialists}
                    dataTestId="assessment/infoSection/performers"
                />
            </FlexRow>
        </PanelBlock>
    );
};
