/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useMemo } from 'react';
/**
 * Components
 */
import { Button } from 'components/common';
/**
 * Hooks
 */
import { useLocation } from 'react-router-dom';
import { useRedirect, useRouteMatchParams } from 'hooks';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
/**
 * Assets
 */
import { ReactComponent as actionLogIcon } from './actionLog.svg';
import css from './style.module.scss';
/**
 * Utils
 */
import { cx } from '@epam/uui-core';
/**
 * Expo
 */
export const ActionLogButton: React.FC = () => {
    const redirect = useRedirect();
    const { pathname } = useLocation();
    const { projectId } = useRouteMatchParams(PAGES.projectWithEntity);

    const isActive = useMemo(() => {
        return pathname.endsWith('action-log');
    }, [pathname]);

    const handleRedirect = useCallback(() => {
        redirect(PAGES.actionLog, { projectId });
    }, [redirect, projectId]);

    return (
        <Button
            fill="outline"
            icon={actionLogIcon}
            onClick={handleRedirect}
            dataTestId="header/buttons/actionLog"
            className={cx(css.button, isActive && css.darkBackground)}
        />
    );
};
