/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, useCallback, useContext } from 'react';
import { RichUtils } from 'draft-js';
/**
 * Components
 */
import { FlexRow } from '@epam/promo';
import { ToolbarButton } from '../../ToolbarButton';
/**
 * Context
 */
import { EditorContext } from '../../../context';
/**
 * Assets
 */
import { ReactComponent as codeIcon } from '@epam/assets/icons/common/editor-code-24.svg';
/**
 * Utils
 */
import { mergeSelectedBlocks } from './utils';
import { getSelectedBlocksList } from 'components/common/RichEditorInput/utils';
/**
 * Expo
 */
export const CodeBlockButton: FC = () => {
    const { editorState, onChange } = useContext(EditorContext);

    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    const handleClick = useCallback(() => {
        const selectedBlockList = getSelectedBlocksList(editorState);

        if (blockType === 'code-block' || selectedBlockList.length === 1) {
            onChange(RichUtils.toggleBlockType(editorState, 'code-block'));
            return;
        }

        const newEditorState = mergeSelectedBlocks(editorState);

        onChange(RichUtils.toggleBlockType(newEditorState, 'code-block'));
    }, [editorState, blockType, onChange]);

    return (
        <FlexRow cx="RichEditor-controls">
            <ToolbarButton
                icon={codeIcon}
                // eslint-disable-next-line react/style-prop-object
                style="code-block"
                blockType={blockType}
                onClick={handleClick}
                isActive={blockType === 'code-block'}
            />
        </FlexRow>
    );
};
