/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import React from 'react';
import { RecentActionContent } from './RecentActionContent';
import { LinkBlock, TextBlock, UserBlock } from './components';
import { useRole } from 'hooks';
import { ReportTypes } from 'constants/reports';
import { FlexRow } from '@epam/promo';

type UseHrefProps = {
    getHref: () => string;
    url?: string;
    type?: string;
};
export function useHref({ getHref, type, url }: UseHrefProps): string {
    const { isOperationsManager } = useRole();
    if (isOperationsManager) {
        return '';
    }
    if (type === ReportTypes.withLink) {
        // url would be string if type is ReportTypes.withLink
        return url as string;
    }
    return getHref();
}

type Action = {
    dataTestId: string;
    user: string;
    action: string;

    target?: string;
    href?: string;
    inNewTab?: boolean;
    targetStyle?: 'bold' | 'gray';
};
export function DefaultRecentAction({
    dataTestId,
    user,
    action,
    target,
    href,
    inNewTab,
    targetStyle = 'bold',
}: Action) {
    let targetBlock = null;
    if (target != null) {
        targetBlock = href ? (
            <LinkBlock
                text={target}
                target={inNewTab ? '_blank' : undefined}
                href={href}
            />
        ) : (
            <TextBlock
                text={target}
                cx={targetStyle && targetStyle === 'bold' ? 'text-bold' : ''}
                color={
                    targetStyle && targetStyle === 'gray' ? 'gray60' : undefined
                }
            />
        );
    }

    return (
        <RecentActionContent dataTestId={dataTestId}>
            <FlexRow columnGap={6}>
                <UserBlock text={user} />
                <TextBlock text={action} />
                {targetBlock}
            </FlexRow>
        </RecentActionContent>
    );
}
