/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createSlice } from '@reduxjs/toolkit';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { RoleTypes } from 'constants/roles';
import { INITIAL_STATE } from './constants';
import { Methods } from 'constants/request';
import { StatusTypes } from 'constants/statuses';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Types
 */
import { AppDispatch, TTarget, TGetState, TSelector } from 'types';
/**
 * Utils
 */
import { templateString } from 'utils';
/**
 * Reducer
 */
const { reducer, actions } = createSlice({
    name: 'targets',
    initialState: INITIAL_STATE,
    reducers: {
        saveList: (state, { payload }) => {
            state.list = payload;

            state.isInitialized = true;
        },
        setInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        resetData: () => INITIAL_STATE,
    },
});

export default reducer;
/**
 * Selectors
 */
export const selectList: TSelector<TTarget[]> = ({ targets: { list } }) => list;
export const selectInProgress: TSelector<boolean> = ({
    targets: { inProgress },
}) => inProgress;
export const selectIsInitialized: TSelector<boolean> = ({
    targets: { isInitialized },
}) => isInitialized;
/**
 * Actions
 */
export const { saveList, setInProgress, resetData } = actions;
/**
 * Dispatchers
 */
export const handleGetList =
    (projectId: string) =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const { role } = getState().user.data;

        dispatch(setInProgress(true));

        const url = templateString(ENDPOINTS.projectTargets, {
            projectId,
        });

        let response = await api.request<TTarget[]>(Methods.get, { url });

        if (role === RoleTypes.projectRepresentative) {
            response = response.filter(
                ({ status }) => status !== StatusTypes.rejected
            );
        }

        dispatch(saveList(response));

        dispatch(setInProgress(false));
    };
