/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { ColorNames } from 'constants/colors';
import { StatusCaptions, StatusTypes } from 'constants/statuses';
/**
 * Types
 */
import { TActivityStatus } from 'types';
import { TStatusList } from './types';
/**
 * Expo
 */
export const CAPTIONS = {
    [StatusTypes.toDo]: StatusCaptions.toDo,
    [StatusTypes.done]: StatusCaptions.done,
    [StatusTypes.inProgress]: StatusCaptions.inProgress,
};

export const COLORS = {
    [StatusTypes.toDo]: ColorNames.blue,
    [StatusTypes.done]: ColorNames.green,
    [StatusTypes.inProgress]: ColorNames.amber,
};

export const DEFAULT_STATUS_LIST: TStatusList = [
    {
        amount: 0,
        status: StatusTypes.toDo,
    },
    {
        amount: 0,
        status: StatusTypes.inProgress,
    },
    {
        amount: 0,
        status: StatusTypes.done,
    },
];

export const STATUS_LIST_ORDER: TActivityStatus[] = [
    StatusTypes.done,
    StatusTypes.inProgress,
    StatusTypes.toDo,
];
