/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useMemo } from 'react';
/**
 * Components
 */
import { Button } from 'components/common';
import { FlexRow, Panel } from '@epam/promo';
import { FilterContext } from './components/FilterContext';
/**
 * Types
 */
import { TProps } from './types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Constants
 */
import { FilterFields } from 'constants/filter';
import { FIELDS_BY_NAME, INITIAL_VALUE } from './constants';
import { TFilter } from 'types';
/**
 * Expo
 */
export const ListFilter: React.FC<TProps> = ({
    fields,
    onChange,
    pageType,
    dataTestId,
    value = INITIAL_VALUE,
}) => {
    const hasValue = useMemo(() => Object.values(value).some(Boolean), [value]);

    const handleChangeFilter = useCallback(
        (filterValue: TFilter) => {
            onChange({ ...value, ...filterValue });
        },
        [value, onChange]
    );

    const handleReset = useCallback(() => {
        onChange(INITIAL_VALUE);
    }, [onChange]);

    const renderField = useCallback(
        (fieldName: FilterFields) => {
            const Component = FIELDS_BY_NAME[fieldName];

            return (
                <Component
                    key={fieldName}
                    fieldName={fieldName}
                    onChange={handleChangeFilter}
                    dataTestId={`${dataTestId}/filter/fields`}
                />
            );
        },
        [handleChangeFilter, dataTestId]
    );

    return (
        <Panel background="white" cx={css.container}>
            <FilterContext value={value} pageType={pageType}>
                {fields.map(renderField)}
            </FilterContext>
            <FlexRow vPadding="18">
                <Button
                    fill="none"
                    disabled={!hasValue}
                    onClick={handleReset}
                    caption="Reset filtering"
                    dataTestId={`${dataTestId}/filter/reset`}
                />
            </FlexRow>
        </Panel>
    );
};
