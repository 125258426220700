/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { useCallback, useMemo } from 'react';
import { FlexRow, Panel, Text, IconContainer, Button } from 'components/common';
import { TDocument, WithDataTestId } from 'types';
import css from './style.module.scss';
import { FlexSpacer } from '@epam/promo';
import { getIconSrcByExtension } from 'components/SingleAttachedFile/utils';
import { getSizeToMegabytes, getExtensionFromFileName, showModal } from 'utils';
import { ReactComponent as downloadIcon } from '@epam/assets/icons/common/file-download-18.svg';
import { ReactComponent as deleteIcon } from '@epam/assets/icons/common/action-delete-18.svg';
import { handleDownloadFile } from 'components/DocumentList/utils';
import { ENDPOINTS } from 'constants/api';
import { handleRemoveDfd } from 'store/threatModeling/utils';
import { DeleteDfdConfirmation } from '../DfdDeleteModal';
import { useAppDispatch, useRedirect } from 'hooks';
import { resetDfdMetadata } from 'store/threatModeling/slice';
import { PAGES } from 'constants/pages';
import { Tabs } from 'pages/ThreatModeling/constants';

export const DfdAttachment: React.FC<
    { document: TDocument } & WithDataTestId
> = ({ document, dataTestId }) => {
    const dispatch = useAppDispatch();
    const redirect = useRedirect();

    const fileExt = useMemo(
        () => getExtensionFromFileName(document.name),
        [document.name]
    );
    const docIcon = useMemo(() => getIconSrcByExtension(fileExt), [fileExt]);

    const sizeInMegabytes = useMemo(
        () => getSizeToMegabytes(document.size_bytes),
        [document.size_bytes]
    );

    const handleDownloadDfd = useCallback(async () => {
        await handleDownloadFile({
            id: document.id,
            name: document.name,
            contentUrl: ENDPOINTS.files,
        });
    }, [document]);

    const handleDeleteDfd = useCallback(async () => {
        showModal(DeleteDfdConfirmation, {
            name: document.name,
            dataTestId: `${dataTestId}/delete/confirmationModal`,
            onSuccess: () =>
                handleRemoveDfd(document.holder_id).then(() => {
                    dispatch(resetDfdMetadata());
                }),
        });
    }, [dispatch, document, dataTestId]);

    const handleRedirectToModelTree = useCallback(() => {
        redirect(PAGES.threatModelingWithTab, {
            tabId: Tabs.modelTree,
        });
    }, [redirect]);

    return (
        <Panel>
            <FlexRow size="30" cx={css.attachmentContainer}>
                <FlexRow size="30">
                    <img
                        className={css.documentIcon}
                        src={docIcon}
                        alt="Default"
                    />
                </FlexRow>
                <Text
                    fontSize="14"
                    color="gray60"
                    lineHeight="12"
                    dataTestId={`${dataTestId}/name`}
                >
                    {document.name} ({sizeInMegabytes}MB)
                </Text>
                <FlexSpacer />
                <FlexRow size="30">
                    <IconContainer
                        style={{ fill: 'var(--uui-neutral-60)' }}
                        icon={downloadIcon}
                        onClick={handleDownloadDfd}
                        dataTestId={`${dataTestId}/download`}
                    />
                </FlexRow>
                <FlexRow size="30">
                    <IconContainer
                        cx={css.deleteIcon}
                        style={{ fill: 'var(--uui-neutral-60)' }}
                        icon={deleteIcon}
                        onClick={handleDeleteDfd}
                        dataTestId={`${dataTestId}/delete`}
                    />
                </FlexRow>
            </FlexRow>
            <FlexRow size="30" cx={css.modelTreeButtonContainer}>
                <Button
                    fill="none"
                    onClick={handleRedirectToModelTree}
                    caption="See Model Tree"
                    dataTestId={`${dataTestId}/modelTree`}
                />
            </FlexRow>
        </Panel>
    );
};
