/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

export enum AssetType {
    technical = 'TECHNICAL',
    business = 'BUSINESS',
}

export enum AssetConfidentiality {
    high = 'HIGH',
    moderate = 'MODERATE',
    low = 'LOW',
}

export enum AssetAvailability {
    high = 'HIGH',
    moderate = 'MODERATE',
    low = 'LOW',
}

export enum AssetIntegrity {
    high = 'HIGH',
    moderate = 'MODERATE',
    low = 'LOW',
}
