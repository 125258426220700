/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { ITab } from 'components/common/PageNavigation/types';

export enum Tabs {
    activityInfo = 'activity-info',
    modelTree = 'model-tree',
    threats = 'threats',
    controls = 'controls',
}

export const availableTabs: Array<ITab> = [
    { id: Tabs.activityInfo, title: 'Activity Info' },
    { id: Tabs.modelTree, title: 'Model tree' },
    { id: Tabs.threats, title: 'Threats' },
    { id: Tabs.controls, title: 'Controls' },
];
