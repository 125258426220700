/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, useCallback, useContext } from 'react';
import { RichUtils } from 'draft-js';
/**
 * Components
 */
import { FlexRow } from '@epam/promo';
import { ToolbarButton } from '../../ToolbarButton';
/**
 * Context
 */
import { EditorContext } from '../../../context';
/**
 * Types
 */
import { TControl } from '../types';
/**
 * Expo
 */
export const InlineStyleControls: FC<TControl> = ({ buttonList }) => {
    const { editorState, onChange } = useContext(EditorContext);

    const currentStyle = editorState.getCurrentInlineStyle();

    const handleClick = useCallback(
        (style = '') => {
            onChange(RichUtils.toggleInlineStyle(editorState, style));
        },
        [onChange, editorState]
    );

    return (
        <FlexRow cx="RichEditor-controls">
            {buttonList.map(({ style = '', icon }) => (
                <ToolbarButton
                    key={style}
                    icon={icon}
                    style={style}
                    onClick={handleClick}
                    isActive={currentStyle.has(style)}
                />
            ))}
        </FlexRow>
    );
};
