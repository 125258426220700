/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Hooks
 */
import { useMemo } from 'react';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
/**
 * Constants
 */
import { ProjectTypes } from 'constants/project';
/**
 * Store
 */
import { selectProjectType } from 'store/project';
/**
 * Types
 */
type TUseProjectType = {
    isLite: boolean;
    isBasic: boolean;
    isTemplate: boolean;
};
/**
 * Expo
 */
export const useProjectType = (): TUseProjectType => {
    const projectType = useSelector(selectProjectType);

    const projectTypes = useMemo(
        () => ({
            isLite: projectType === ProjectTypes.lite,
            isBasic: projectType === ProjectTypes.basic,
            isTemplate: projectType === ProjectTypes.template,
        }),
        [projectType]
    );

    return projectTypes;
};
