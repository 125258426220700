/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { PrivateRoute } from 'navigation/PrivateRoute';
import { RoleTypes } from 'constants/roles';
import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import ThreatModeling from 'pages/ThreatModeling';

export const ThreatModelingRoute: React.FC = () => (
    <Suspense fallback={null}>
        <Routes>
            <Route
                path="/"
                element={
                    <PrivateRoute
                        component={ThreatModeling}
                        roles={[RoleTypes.securitySpecialist]}
                    />
                }
            />

            <Route
                path="/:tabId"
                element={
                    <PrivateRoute
                        component={ThreatModeling}
                        roles={[RoleTypes.securitySpecialist]}
                    />
                }
            />
        </Routes>
    </Suspense>
);
