/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { FlexCell, FlexRow } from 'components/common';
import { Children, FC } from 'react';
import css from './style.module.scss';
import { WithRequiredChildren } from 'types';

export const TwoFieldsRow: FC<WithRequiredChildren> = ({ children }) => (
    <FlexRow cx={css.twoFieldsRow}>
        {Children.map(children, (child) => (
            <FlexCell grow={1}>{child}</FlexCell>
        ))}
    </FlexRow>
);
