enum Locales {
    us = 'US',
}

const US_TIME = 'h:mm a';
const US_DATE = 'MMM d, yyyy';

const API_TIME = 'HH:mm:ss+00:00';
const API_DATE = 'yyyy-MM-dd';

export const DATE_FORMATS = {
    forApi: {
        time: API_TIME,
        date: API_DATE,
        dateTime: `${API_DATE}'T'${API_TIME}`,
    },
    [Locales.us]: {
        time: US_TIME,
        date: US_DATE,
        dateTime: `${US_DATE} ${US_TIME}`,
        dateAtTime: `${US_DATE} 'at' ${US_TIME}`,
    },
};

export const currentYear = new Date().getFullYear();
