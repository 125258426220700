/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import {
    mapAssetFootprintsPayloadToForm,
    mapAssetFootprintsFormToPayload,
} from '../utils';
import { FormValuesWithoutTmId } from '../baseItemFormSchema';
import { TECHNOLOGY_READABLE_ID_PREFIX } from './constants';
import { TechnologyFormValues } from './schema';
import { createReadableId } from '../../../utils';
import {
    TechnologyCreatePayload,
    TechnologyUpdatePayload,
} from 'store/threatModeling/technology/types';
import { TTechnology } from 'types';

export function mapTechnologyToFormValues(
    technology: TTechnology
): FormValuesWithoutTmId<TechnologyFormValues> {
    return {
        id: technology.id,
        name: technology.name,
        readableId: createReadableId(
            TECHNOLOGY_READABLE_ID_PREFIX,
            String(technology.readable_id)
        ),
        assetFootprints: mapAssetFootprintsPayloadToForm(
            technology.asset_footprints
        ),
        entryPoints: technology.entry_points,
    };
}

export function mapFormValuesToTechnologyPayload(
    form: TechnologyFormValues
): TechnologyCreatePayload {
    return {
        tm_id: form.tmId,
        name: form.name,
        asset_footprints: mapAssetFootprintsFormToPayload(form.assetFootprints),
        entry_points: form.entryPoints.map((entryPoint) => entryPoint.name),
    };
}

export function mapFormValuesToTechnologyUpdatePayload(
    form: TechnologyFormValues
): TechnologyUpdatePayload {
    return {
        name: form.name,
        asset_footprints:
            form?.assetFootprints?.map((assetFootprint) => ({
                id: assetFootprint.id ?? null,
                asset_id: assetFootprint.asset.id,
                action_on_asset: assetFootprint.actionOnAsset,
            })) ?? null,
        entry_points: form.entryPoints.map((entryPoint) => ({
            id: entryPoint.id ?? null,
            name: entryPoint.name,
        })),
    };
}
