/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback } from 'react';
/**
 * Components
 */
import { LinkButton, Text } from 'components/common';
import { FlexRow, FlexSpacer, Panel } from '@epam/promo';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { PickerFooterProps } from '@epam/uui-core';
/**
 * Expo
 */
export function SelectFooter<TOption>({
    view,
    view: { selectAll },
}: PickerFooterProps<TOption, TOption[keyof TOption]>): JSX.Element {
    const { totalCount } = view.getListProps();

    const handleSelectAll = useCallback(() => {
        if (!selectAll) return;

        const { onValueChange, value } = selectAll;

        onValueChange(!value);
    }, [selectAll]);

    const selectedRows = view.getSelectedRows();

    return (
        <Panel background="white" cx={css.footerContainer}>
            <FlexRow padding="12">
                <Text>
                    {selectedRows.length} of {totalCount} items selected
                </Text>
                <FlexSpacer />
                <LinkButton
                    onClick={handleSelectAll}
                    caption={selectAll?.value ? 'CLEAR ALL' : 'SELECT ALL'}
                />
            </FlexRow>
        </Panel>
    );
}
