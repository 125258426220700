/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

export enum FilterFields {
    status = 'STATUS',
    persons = 'REPORTER',
    severity = 'SEVERITY',
    assessment = 'ASSESSMENT',
    target = 'TARGET',
    activity = 'ACTIVITY',
}

export enum PagesToFilter {
    tasks = 'TASKS',
    reports = 'REPORTS',
    findings = 'FINDINGS',
}

export enum FilterQueryParams {
    assessmentId = 'activity.assessment.id',
}

export enum SortVariants {
    severityPriorityDesc = 'findingSeverity.priority,desc',
    severityPriorityAsc = 'findingSeverity.priority,asc',
    updatedAtDesc = 'updatedAt,desc',
    updatedAtASC = 'updatedAt,asc',
    summaryAsc = 'summary,asc',
    summaryDesc = 'summary,desc',
    reporterAsc = 'authorNameSnapshot,asc',
    reporterDesc = 'authorNameSnapshot,desc',
}
