/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { DefaultValues, FieldValues } from 'react-hook-form';
import { WithDataTestId } from 'types';
import { ItemType } from '../../constants';
import { BaseFormValuesWithoutID } from './components/ItemEditor/forms/baseItemFormSchema';

export interface TExpandableTreeProps extends WithDataTestId {
    title: string;
    emptyListMessage: string;
    subItems: Array<BaseFormValuesWithoutID>;
    openByDefault?: boolean;
    type: ItemType;
}

export enum FormMode {
    create = 'create',
    edit = 'edit',
    view = 'view',
}

export interface FormFieldsProps<Fields extends FieldValues> {
    initialData?: DefaultValues<Fields>;
}
