/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import React from 'react';
import { useRole } from 'hooks';
import { useLocation } from 'react-router-dom';
import { MainMenuButton } from 'components/common';
import { PAGES } from 'constants/pages';
import { AdaptiveItemProps } from '@epam/uui-components';
import { adaptiveItemsBuilder } from 'utils/adaptiveItems';

export const usePagesHeader = (): Array<AdaptiveItemProps> => {
    const { pathname } = useLocation();
    const { isAdmin, isOperationsManager } = useRole();

    return adaptiveItemsBuilder()
        .add(
            isOperationsManager
                ? {
                      id: 'projects',
                      priority: 2,
                      render: () => (
                          <MainMenuButton
                              showInBurgerMenu
                              caption="Projects"
                              href={PAGES.projects}
                              dataTestId="header/menu/projects"
                              isLinkActive={pathname.includes(PAGES.projects)}
                          />
                      ),
                  }
                : null
        )
        .add(
            isAdmin
                ? [
                      {
                          id: 'users',
                          priority: 3,
                          render: () => (
                              <MainMenuButton
                                  caption="Users"
                                  href={PAGES.persons}
                                  dataTestId="header/menu/users"
                                  isLinkActive={pathname.includes(
                                      PAGES.persons
                                  )}
                              />
                          ),
                      },
                      {
                          id: 'activities',
                          priority: 4,
                          render: () => (
                              <MainMenuButton
                                  showInBurgerMenu
                                  caption="Activities"
                                  href={PAGES.activities}
                                  dataTestId="header/menu/activities"
                                  isLinkActive={pathname.includes(
                                      PAGES.activities
                                  )}
                              />
                          ),
                      },
                      {
                          id: 'organizations',
                          priority: 5,
                          render: () => (
                              <MainMenuButton
                                  showInBurgerMenu
                                  caption="Organizations"
                                  href={PAGES.organizations}
                                  dataTestId="header/menu/organizations"
                                  isLinkActive={pathname.includes(
                                      PAGES.organizations
                                  )}
                              />
                          ),
                      },
                      {
                          id: 'feature-toggles',
                          priority: 6,
                          render: () => (
                              <MainMenuButton
                                  showInBurgerMenu
                                  caption="Feature Toggles"
                                  href={PAGES.featureToggles}
                                  dataTestId="header/menu/featureToggles"
                                  isLinkActive={pathname.includes(
                                      PAGES.featureToggles
                                  )}
                              />
                          ),
                      },
                  ]
                : null
        )
        .build();
};
