/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useState } from 'react';
/**
 * Components
 */
import { Legend, Popover } from './components';
import { ResponsivePie, ComputedDatum } from '@nivo/pie';
/**
 * Types
 */
import { IChartProps, TChartData } from './types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Constants
 */
import { DEFAULT_PROPS } from './constants';
/**
 * Expo
 */
export function Chart({
    data,
    dataTestId,
    renderLabel,
    renderTooltip,
    renderLegendBadge,
    isInteractive,
    withLegend,
    onMouseEnter,
    onMouseLeave,
}: IChartProps): JSX.Element {
    const [hoveredData, setHoveredData] = useState<TChartData | null>(null);
    const [mouseCoordinates, setMouseCoordinates] = useState({ x: 0, y: 0 });

    const handleMouseEnter = useCallback(
        (
            chartData: ComputedDatum<TChartData>,
            { pageX, pageY }: React.MouseEvent<SVGPathElement>
        ) => {
            if (onMouseEnter) {
                onMouseEnter(chartData);
            }

            setHoveredData(chartData.data);
            setMouseCoordinates({ x: pageX, y: pageY });
        },
        [onMouseEnter]
    );

    const handleMouseLeave = useCallback(() => {
        if (onMouseLeave) {
            onMouseLeave();
        }

        setHoveredData(null);
        setMouseCoordinates({ x: 0, y: 0 });
    }, [onMouseLeave]);

    const handleMouseMove = useCallback(
        (
            chartData: ComputedDatum<TChartData>,
            { pageX, pageY }: React.MouseEvent<SVGPathElement>
        ) => {
            setMouseCoordinates({ x: pageX, y: pageY });
        },
        []
    );

    return (
        <>
            <div className={css.chartContainer}>
                <div className={css.label}>{renderLabel()}</div>
                <div id={`${dataTestId}/chart`} className={css.chart}>
                    <ResponsivePie
                        {...DEFAULT_PROPS}
                        onMouseMove={handleMouseMove}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        isInteractive={isInteractive}
                        data={data}
                        // Tooltip can't be turned off without isInteractive={false}
                        // Without empty tooltip prop - default tooltip will be rendered
                        tooltip={() => <></>}
                    />
                </div>
            </div>
            {withLegend && (
                <Legend
                    data={data}
                    renderBadge={renderLegendBadge}
                    dataTestId={dataTestId}
                />
            )}
            {!isInteractive || !hoveredData ? null : (
                <Popover
                    data={hoveredData}
                    renderTooltip={renderTooltip}
                    mouseCoordinates={mouseCoordinates}
                />
            )}
        </>
    );
}
