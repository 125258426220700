/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, {
    FC,
    memo,
    useRef,
    useMemo,
    useState,
    useEffect,
    useContext,
    useCallback,
    useLayoutEffect,
} from 'react';
/**
 * Hooks
 */
import { useOnMount } from 'hooks';
/**
 * Component
 */
import { ResizeBox } from './ResizeBox';
/**
 * Context
 */
import { EditorContext } from '../../../context';
/**
 * Types
 */
import { TSize } from './types';
import { TBlockProps } from '../types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Constants
 */
import { IMAGE_REG_EXP } from 'constants/url';
/**
 * Utils
 */
import { v4 as uuidv4 } from 'uuid';
import { convertToBase64, urlToFile } from 'utils';
import { EditorState, SelectionState } from 'draft-js';
import {
    getImage,
    getImageSize,
    getAlignmentStyle,
    getSizeFromEntityData,
} from './utils';
/**
 * Expo
 */
export const Image: FC<TBlockProps> = memo(({ entity, block, entityKey }) => {
    const imageContainerRef = useRef<HTMLDivElement>(null);

    const [src, setSrc] = useState<string>();
    const [size, setSize] = useState(getSizeFromEntityData(entity.getData()));

    const { readOnly, editorState, setEditorState, onChange } =
        useContext(EditorContext);

    const {
        src: name,
        alignment,
        pastedImage,
        attachedImage,
    } = entity.getData();

    const setImageToEntityFromBase64 = async () => {
        const [, type] = name.split(';')[0].split('/');

        const newName = `${uuidv4()}.${type}`;

        const file = await urlToFile(name, newName, `image/${type}`);

        const currentContent = editorState
            .getCurrentContent()
            .mergeEntityData(entityKey, { pastedImage: file, src: newName });

        onChange(EditorState.set(editorState, { currentContent }));
    };

    useOnMount(() => {
        if (name?.startsWith('data:image')) {
            setImageToEntityFromBase64();
        }
    });

    const changeEntityData = (changedSize?: TSize) => {
        if (!changedSize) return;

        const contentState = editorState.getCurrentContent();
        const contentStateWithMergedData = contentState.mergeEntityData(
            entityKey,
            changedSize
        );

        const newEditorState = EditorState.set(editorState, {
            currentContent: contentStateWithMergedData,
        });

        onChange(newEditorState);
    };

    const getSize = useCallback(
        async (image?: File) => {
            const { width, height } = entity.getData();

            if (!image || (width && height)) return;

            const imageSize = await getImageSize(image);

            setSize(imageSize);
        },
        [entity]
    );

    const getSrc = useCallback(
        async (image?: File) => {
            let imageSrc;

            if (image) {
                imageSrc = await convertToBase64(image);
            } else if (IMAGE_REG_EXP.test(name)) {
                imageSrc = name;
            }

            setSrc(imageSrc);
        },
        [name]
    );

    const getImageParams = useCallback(async () => {
        const file = await getImage({ attachedImage, pastedImage });

        getSize(file);
        getSrc(file);
    }, [getSrc, getSize, attachedImage, pastedImage]);

    const handleClickImage = () => {
        const blockKey = block.getKey();
        const selectionState = SelectionState.createEmpty(blockKey);

        const newEditorState = EditorState.forceSelection(
            editorState,
            selectionState
        );

        setEditorState(newEditorState);
    };

    const alignmentsStyle = useMemo(
        () => getAlignmentStyle(alignment),
        [alignment]
    );

    useEffect(() => {
        if (src && size) return;

        getImageParams();
    }, [src, size, getImageParams]);

    useLayoutEffect(() => {
        changeEntityData(size);
        // we don't need to track changeEntityData here
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [size]);

    return (
        <div
            role="none"
            style={alignmentsStyle}
            className={css.container}
            onClick={handleClickImage}
        >
            <div
                style={size}
                ref={imageContainerRef}
                className={css.imageContainer}
            >
                {!readOnly && size && (
                    <ResizeBox
                        src={src}
                        size={size}
                        onChangeSize={setSize}
                        containerRef={imageContainerRef}
                    />
                )}
                <img
                    src={src}
                    alt="loading..."
                    draggable={readOnly}
                    className={css.image}
                />
            </div>
        </div>
    );
});
