/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Hooks
 */
import { useEffect, useRef } from 'react';
/**
 * Types
 */
type TUseOnUpdate = (effect: () => void, dependencies: unknown[]) => void;
/**
 * Expo
 */
export const useOnUpdate: TUseOnUpdate = (effect, dependencies = []) => {
    const isMounted = useRef(false);

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;

            return undefined;
        }

        return effect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
};
