/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useEffect } from 'react';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
import {
    useAppDispatch,
    usePageLoadingPerformance,
    useRole,
    useRouteParams,
} from 'hooks';
/**
 * Components
 */
import { PanelBlock, PanelsContainer, Text } from 'components/common';
import { Header } from './components/Header';
import { BackTo } from 'components/common/BackTo';
import { PageLoadHandler } from 'components/PageLoadHandler';
import { RecentActions } from 'components/RecentActions';
import { FlexCell, FlexRow, FlexSpacer } from '@epam/promo';
import { ActivityBoard, Information, SelectTargets } from './components';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Utils
 */
import { renderAction } from './utils';
import { formatDate, templateString } from 'utils';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
import { EntityTypes } from 'constants/actionLog';
import { AssessmentTypes } from 'constants/assessment';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Store
 */
import {
    handleGetActivities,
    handleGetAssessmentWithActivities,
    resetData,
    saveActivitiesIdByStatus,
    selectActivitiesIdByStatus,
    selectAssessment,
    selectInProgress,
    selectIsInitialized,
} from 'store/assessment';
import { handleGetList } from 'store/assessments';
/**
 * Types
 */
import { TActivityStatus, TAssessmentActionLog } from 'types';
import { TActivitiesByStatus } from './components/ActivityBoard/types';

/**
 * Expo
 */
export const SingleAssessment: React.FC = () => {
    usePageLoadingPerformance({
        waitForApi: true,
        reducerNames: ['user', 'project', 'assessment'],
    });

    const dispatch = useAppDispatch();
    const { assessmentId } = useRouteParams();
    const { isOperationsManager } = useRole();
    const { projectId } = useRouteParams();

    const inProgress = useSelector(selectInProgress);
    const isInitialized = useSelector(selectIsInitialized);
    const activitiesIdByStatus = useSelector(selectActivitiesIdByStatus);
    const { name, updatedAt, status, type } = useSelector(selectAssessment);

    const pathToBack = isOperationsManager
        ? PAGES.assessmentsOperationsManager
        : PAGES.assessments;

    const handleChangeStatus = useCallback(
        async (id: string, newActivityStatus: TActivityStatus) => {
            const url = templateString(ENDPOINTS.activityStatus, { id });

            await api.request(Methods.post, {
                url,
                data: { status: newActivityStatus },
            });
            dispatch(handleGetList(projectId));
        },
        [dispatch, projectId]
    );

    const handleChangeActivityStatus = useCallback(
        ({
            reorderedList,
            status: newActivityStatus,
            activityId,
        }: {
            activityId: string;
            status: TActivityStatus;
            reorderedList: TActivitiesByStatus;
        }) => {
            dispatch(saveActivitiesIdByStatus(reorderedList));

            handleChangeStatus(activityId, newActivityStatus);
        },
        [dispatch, handleChangeStatus]
    );

    const handleChooseTarget = useCallback(
        (targetIds: string[]) => {
            dispatch(handleGetActivities(assessmentId, targetIds));
        },
        [dispatch, assessmentId]
    );

    useEffect(() => {
        dispatch(handleGetAssessmentWithActivities(assessmentId));

        return () => {
            dispatch(resetData());
        };
    }, [dispatch, assessmentId]);

    return (
        <PageLoadHandler
            inProgress={inProgress}
            isInitialized={isInitialized}
            renderBackTo={() => (
                <FlexRow>
                    <BackTo
                        caption="assessments"
                        path={pathToBack}
                        dataTestId="singleAssessment/backTo"
                    />
                    <FlexSpacer />
                    <Text
                        color="gray5"
                        fontSize="12"
                        dataTestId="singleAssessment/updatedAt"
                    >
                        {updatedAt && `Last changed ${formatDate(updatedAt)}`}
                    </Text>
                </FlexRow>
            )}
            renderHeader={() => (
                <Header id={assessmentId} name={name} status={status} />
            )}
        >
            <PanelsContainer>
                <PanelBlock size={70}>
                    {type === AssessmentTypes.regular && (
                        <FlexCell cx={css.select}>
                            <SelectTargets onChange={handleChooseTarget} />
                        </FlexCell>
                    )}
                    <ActivityBoard
                        list={activitiesIdByStatus}
                        onChange={handleChangeActivityStatus}
                    />
                </PanelBlock>
                <PanelsContainer direction="vertical" size={30}>
                    <Information status={status} />
                    <RecentActions<TAssessmentActionLog>
                        entityId={assessmentId}
                        dataTestId="assessment"
                        renderAction={renderAction}
                        entityType={EntityTypes.assessment}
                    />
                </PanelsContainer>
            </PanelsContainer>
        </PageLoadHandler>
    );
};
