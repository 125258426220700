/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
export const pick = <TListItem, TKey extends keyof TListItem>(
    list: TListItem[] | undefined | null,
    key: TKey
): TListItem[TKey][] => {
    if (!list?.length) return [];

    return list.map((item) => item[key]);
};

export const uniqBy = <TListItem>(
    list: TListItem[],
    key: keyof TListItem
): TListItem[] => {
    const keyValueSet = new Set();

    return list.reduce((accumulator: TListItem[], item) => {
        const keyValue = item[key];

        if (!keyValueSet.has(keyValue)) {
            keyValueSet.add(keyValue);

            accumulator.push(item);
        }

        return accumulator;
    }, []);
};
