/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { DataTable } from '@epam/promo';
import { DataColumnProps, useArrayDataSource } from '@epam/uui-core';
import { IconContainer, Text } from 'components/common';
import { EmptyView } from 'pages/ThreatModeling/tabs/ControlsTab/components/Table/EmptyView';
import { Control } from '../../index';
import { useState } from 'react';
import { ReactComponent as NavigationExpandAllOutlineIcon } from '@epam/assets/icons/common/navigation-unfold-more-18.svg';

const columns: Array<DataColumnProps<Control>> = [
    {
        key: 'expand',
        width: 10,
        grow: 1,
        caption: <IconContainer icon={NavigationExpandAllOutlineIcon} />,
    },
    {
        key: 'id',
        width: 20,
        grow: 1,
        caption: 'ID',
        render: ({ id }: Control) => <Text>{id}</Text>,
    },
    {
        grow: 1,
        key: 'controlName',
        width: 100,
        caption: 'Control Name',
        render: ({ controlName }: Control) => <Text>{controlName}</Text>,
    },
    {
        grow: 1,
        key: 'controlCategory',
        width: 100,
        caption: 'Control Category',
        render: ({ controlCategory }: Control) => (
            <Text>{controlCategory}</Text>
        ),
    },
    {
        grow: 1,
        key: 'implementationPoint',
        width: 100,
        caption: 'Implementation Point',
        render: ({ implementationPoint }: Control) => (
            <Text>{implementationPoint}</Text>
        ),
    },
];

export const Table = () => {
    const dataSource = useArrayDataSource<Control, Control['id'], unknown>(
        {
            items: [],
        },
        []
    );
    const [value, onValueChange] = useState({});

    const view = dataSource.useView(value, onValueChange);
    return (
        <DataTable
            {...view.getListProps()}
            value={value}
            onValueChange={onValueChange}
            columns={columns}
            getRows={view.getVisibleRows}
            headerTextCase="upper"
            renderNoResultsBlock={() => <EmptyView />}
        />
    );
};
