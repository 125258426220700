/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useState } from 'react';
import { FormProvider } from 'react-hook-form';
/**
 * Hooks
 */
import { useAppForm } from 'hooks';
/**
 * Components
 */
import { UploadCard } from 'components';
import { Button, Blocker } from 'components/common';
import { FieldController } from 'components/Fields';
import { FlexSpacer, ModalFooter, Panel } from '@epam/promo';
/**
 * Types
 */
import { TUploadedFile } from 'types';
import { FormProps, TFormValues } from './types';
import { TUploadCardProps } from 'components/UploadCard/types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Utils
 */
import { showError } from 'utils';
/**
 * Expo
 */
export const Form: React.FC<FormProps> = ({
    onClose,
    onSubmit,
    infoText,
    dataTestId,
    successButtonCaption = 'Save',
}) => {
    const methods = useAppForm<TFormValues>({ defaultValues: { files: [] } });

    const [inProgress, setInProgress] = useState(false);

    const handleSave = useCallback(
        async ({ files }: TFormValues) => {
            setInProgress(true);

            try {
                await onSubmit(files);

                onClose();
            } finally {
                setInProgress(false);
            }
        },
        [onSubmit, onClose]
    );

    const handleSubmit = methods.handleSubmit((values) => {
        if (values.files.length < 1) {
            showError('At least one file should be uploaded');

            return;
        }

        handleSave(values);
    });

    return (
        <>
            <Blocker isFixed isEnabled={inProgress} />
            <Panel cx={css.formContainer}>
                <FormProvider {...methods}>
                    <FieldController<TUploadCardProps, TUploadedFile[]>
                        name="files"
                        infoText={infoText}
                        component={UploadCard}
                        dataTestId={`${dataTestId}/attachFilesModal/uploadCard`}
                    />
                </FormProvider>
            </Panel>
            <ModalFooter borderTop>
                <FlexSpacer />
                <Button
                    fill="none"
                    color="neutral"
                    caption="Cancel"
                    onClick={onClose}
                    dataTestId={`${dataTestId}/attachFilesModal/buttons/cancel`}
                />
                <Button
                    color="primary"
                    onClick={handleSubmit}
                    caption={successButtonCaption}
                    dataTestId={`${dataTestId}/attachFilesModal/buttons/submit`}
                />
            </ModalFooter>
        </>
    );
};
