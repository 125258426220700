/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Hooks
 */
import { useRedirect } from 'hooks';
/**
 * Components
 */
import { Avatar, LinkButton, IconContainer, FlexRow } from 'components/common';
import { Blocker, Badge, FlexSpacer, Text } from '@epam/promo';
/**
 * Assets
 */
import css from './style.module.scss';
import { ReactComponent as disconnectIcon } from '@epam/assets/icons/common/content-link_off-18.svg';
import { ReactComponent as editIcon } from '@epam/assets/icons/common/content-edit-18.svg';
import { ReactComponent as doneIcon } from '@epam/assets/icons/common/notification-done-12.svg';
import { ReactComponent as addIcon } from '@epam/assets/icons/common/action-add-18.svg';
/**
 * Types
 */
import { TIntegration } from './types';
/**
 * Expo
 */
export const SingleIntegration: React.FC<TIntegration> = ({
    icon,
    title,
    editUrl,
    dataTestId,
    creationUrl,
    integrationId,
    integrationType,
    onDisconnect,
}) => {
    const redirect = useRedirect();

    const handleCreate = () => {
        redirect(creationUrl);
    };

    const handleEdit = () => {
        if (editUrl) {
            redirect(editUrl);
        }
    };

    const handleDisconnect = () => {
        if (onDisconnect) {
            onDisconnect(integrationType, integrationId);
        }
    };

    return (
        <FlexRow
            cx={css.block}
            padding="18"
            vPadding="24"
            columnGap="12"
            dataTestId={`projectInfoPanels/integrations/${dataTestId}`}
        >
            {!integrationId && (
                <Blocker
                    isEnabled
                    cx={css.blocker}
                    renderSpinner={() => (
                        <LinkButton
                            color="primary"
                            icon={addIcon}
                            onClick={handleCreate}
                            cx={css.addIntegration}
                            dataTestId="addIntegration"
                            caption="Add integration"
                        />
                    )}
                />
            )}
            <Avatar size="30" img={icon} className={css.avatar} />
            <Text fontSize="18" cx="text-bold">
                {title}
            </Text>
            {Boolean(integrationId) && (
                <>
                    <Badge
                        icon={doneIcon}
                        size="18"
                        color="green"
                        fill="outline"
                        caption="Connected"
                    />
                    <FlexSpacer />
                    <FlexRow columnGap="18">
                        {editUrl && (
                            <IconContainer
                                style={{ fill: 'var(--uui-link)' }}
                                icon={editIcon}
                                onClick={handleEdit}
                                dataTestId={`projectInfoPanels/integrations/${dataTestId}/edit`}
                            />
                        )}
                        {onDisconnect && (
                            <IconContainer
                                style={{ fill: 'var(--uui-link)' }}
                                icon={disconnectIcon}
                                onClick={handleDisconnect}
                                dataTestId={`projectInfoPanels/integrations/${dataTestId}/disconnect`}
                            />
                        )}
                    </FlexRow>
                </>
            )}
        </FlexRow>
    );
};
