/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
/**
 * Store
 */
import {
    selectIsIntegratedWithAzure,
    selectIsIntegratedWithJira,
} from 'store/project';
/**
 * Expo
 */
export const UserAvatarSubicon: React.FC<{ fromTracker: boolean }> = ({
    fromTracker,
}) => {
    const fromJira = useSelector(selectIsIntegratedWithJira);
    const fromAzure = useSelector(selectIsIntegratedWithAzure);
    if (!fromTracker) {
        return null;
    }
    switch (true) {
        case fromJira:
            return (
                <div className={css.subiconContainer}>
                    <img
                        width="10"
                        height="9"
                        alt="Jira user"
                        src="/assets/images/jira.svg"
                    />
                </div>
            );
        case fromAzure:
            return (
                <div className={css.subiconContainer}>
                    <img
                        width="13"
                        height="12"
                        alt="Azure user"
                        src="/assets/images/azure.svg"
                    />
                </div>
            );
        default:
            return null;
    }
};
