/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { useCallback, useMemo } from 'react';
import { Button, FlexRow, Text } from 'components/common';
import { FlexSpacer } from '@epam/promo';
import { ThreatModelingHeaderProps } from 'pages/ThreatModeling/components/Header/types';
import { formatDate, showModal } from 'utils';
import { DfdUploadModal } from 'pages/ThreatModeling/components/DfdUploadModal';
import css from './style.module.scss';
import { Tabs } from 'pages/ThreatModeling/constants';

const UploadIcon = (): JSX.Element => (
    <img
        src="/assets/icons/uploadToCloud.svg"
        alt="Upload DFD"
        height="18"
        width="18"
    />
);

export const ThreatModelingHeader: React.FC<ThreatModelingHeaderProps> = ({
    activityId,
    hasDfd,
    updatedAt,
    tabId,
    dataTestId,
}) => {
    const handleUploadDfd = useCallback(() => {
        showModal(DfdUploadModal, {
            activityId,
            dataTestId: `${dataTestId}/uploadDiagram/modal`,
        });
    }, [activityId, dataTestId]);
    const caption = useMemo(
        () => (hasDfd ? 'Reload data flow' : 'Upload data flow'),
        [hasDfd]
    );
    const date = formatDate(updatedAt);
    const showUploadButton = [Tabs.activityInfo, Tabs.modelTree]
        .map((t) => t.toString())
        .includes(tabId);
    return (
        <>
            {updatedAt && (
                <Text
                    cx={css.lastChangedAt}
                    dataTestId={`${dataTestId}/updatedAt`}
                >
                    Last changed {date}
                </Text>
            )}
            <FlexRow>
                <Text
                    cx="text-bold text-white text-size-30"
                    dataTestId={`${dataTestId}/title`}
                >
                    Threat Modeling
                </Text>
                <FlexSpacer />
                {showUploadButton && (
                    <Button
                        fill="outline"
                        icon={UploadIcon}
                        caption={caption}
                        onClick={handleUploadDfd}
                        dataTestId={`${dataTestId}/uploadDiagram`}
                    />
                )}
            </FlexRow>
        </>
    );
};
