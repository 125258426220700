/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { forwardRef, FC } from 'react';
/**
 * Components
 */
import { Button } from 'components/common';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { IDropdownToggler } from '@epam/uui-core';
/**
 * Expo
 */
export const DropdownTarget: FC<IDropdownToggler> = forwardRef((props, ref) => (
    <Button
        {...props}
        ref={ref}
        fill="outline"
        color="primary"
        caption="Add report"
        className={css.button}
    />
));
