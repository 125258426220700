/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Components
 */
import { WithoutApprovedTarget } from './components';
/**
 * Utils
 */
import { showModal } from 'utils';
/**
 * Types
 */
import { TCheckCanAddAssessment } from './types';
/**
 * Expo
 */
export const checkCanAddAssessment: TCheckCanAddAssessment = ({
    redirect,
    redirectUrl,
    hasApprovedTarget,
}) => {
    if (!hasApprovedTarget) {
        showModal(WithoutApprovedTarget, {
            onSubmit: () => redirect(redirectUrl),
        });

        return false;
    }

    return true;
};
