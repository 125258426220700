/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import React from 'react';
import { IModal } from '@epam/uui-core';
import { svc } from 'services';

type TModalComponent<T> = React.FC<T & IModal<string>>;

/**
 * TODO: fix typing, now it requires to pass `key`, `zIndex`, `success`, `abort` props,
 * even though they are filled inside `svc.uuiModals.show()` from Context.
 * Direct call of `svc.uuiModals.show()` works as expected, but this wrapper doesn't.
 * @see https://github.com/epam/UUI/blob/dc1fb92b525ad558bcebdda6601c2c9d192e7829/uui-core/src/services/ModalContext.ts#L61-L65
 */
export function showModal<T>(
    ModalComponent: TModalComponent<T>,
    modalProps: T
): void {
    svc.uuiModals
        .show((props) => <ModalComponent {...props} {...modalProps} />)
        .then(() => {})
        .catch(() => {});
}
