/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { AnyAction, CombinedState, combineReducers } from 'redux';

import tasksReducer from './tasks';
import projectReducer from './project';
import reportsReducer from './reports';
import findingsReducer from './findings';
import dashboardReducer from './dashboard';
import singleTaskReducer from './singleTask';
import userReducer, { logout } from './user';
import assessmentReducer from './assessment';
import singleReportReducer from './singleReport';
import singleFindingReducer from './singleFinding';
import trackerIssuesReducer from './trackerIssues';
import configureProjectReducer from './configureProject';
import personsReducer from './persons';
import personReducer from './person';
import activitiesReducer from './activities';
import organizationsReducer from './organizations';
import projectsReducer from './projects';
import targetsReducer from './targets';
import assessmentsReducer from './assessments';
import notificationsReducer from './notifications/reducers';
import notificationSettingsReducer from './notificationSettings';
import featureFlagsReducer from './featureFlags';
import policiesReducer from './policies/reducers';
import singlePolicyReducer from './singlePolicy';
import singleActivityReducer from './singleActivity/reducers';
import actionLogReducer from './actionLog/reducers';
import authReducer from './auth/reducers';
import requestsReducer from './requests';
import singleRequestReducer from './singleRequest';
import projectAttachmentsReducer from './projectAttachments';
import threatModelingReducer from './threatModeling';

const combinedReducer = combineReducers({
    targets: targetsReducer,
    projects: projectsReducer,
    assessments: assessmentsReducer,
    persons: personsReducer,
    person: personReducer,
    activities: activitiesReducer,
    organizations: organizationsReducer,
    user: userReducer,
    tasks: tasksReducer,
    reports: reportsReducer,
    project: projectReducer,
    findings: findingsReducer,
    dashboard: dashboardReducer,
    assessment: assessmentReducer,
    singleTask: singleTaskReducer,
    singleReport: singleReportReducer,
    trackerIssues: trackerIssuesReducer,
    singleFinding: singleFindingReducer,
    configureProject: configureProjectReducer,
    notifications: notificationsReducer,
    notificationSettings: notificationSettingsReducer,
    featureFlags: featureFlagsReducer,
    policies: policiesReducer,
    singlePolicy: singlePolicyReducer,
    singleActivity: singleActivityReducer,
    actionLog: actionLogReducer,
    auth: authReducer,
    requests: requestsReducer,
    singleRequest: singleRequestReducer,
    projectAttachments: projectAttachmentsReducer,
    threatModeling: threatModelingReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

export default (
    state: RootState | undefined,
    action: AnyAction
): CombinedState<RootState> => {
    let rootState = state;

    if (action.type === logout.type) {
        rootState = undefined;
    }

    return combinedReducer(rootState, action);
};
