/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { createSlice } from '@reduxjs/toolkit';
import { INTERACTIONS_INITIAL_STATE } from './constants';

export const {
    reducer: interactionsReducer,
    actions: {
        saveInteractions,
        setInteractionsLoadingInProgress,
        resetInteractions,
        addInteraction,
        updateInteraction,
    },
} = createSlice({
    name: 'interactions',
    initialState: INTERACTIONS_INITIAL_STATE,
    reducers: {
        saveInteractions: (state, { payload }) => {
            state.list = payload;
            state.isInitialized = true;
        },
        setInteractionsLoadingInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        resetInteractions: () => INTERACTIONS_INITIAL_STATE,
        addInteraction: (state, { payload }) => {
            state.list = [...state.list, payload];
        },
        updateInteraction: (state, { payload }) => {
            const index = state.list.findIndex(
                (interaction) => interaction.id === payload.id
            );
            if (index !== -1) {
                state.list[index] = payload;
            }
        },
    },
});
