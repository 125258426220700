/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
/**
 * Components
 */
import { PrivateRoute } from '../../../PrivateRoute';
import { AssessmentsRoute } from './Assessments';
/**
 * Constants
 */
import { RoleTypes } from 'constants/roles';
/**
 * Hooks
 */
/**
 * Store
 */
/**
 * Pages
 */
const Projects = lazy(() => import('pages/Projects'));
const Attachments = lazy(() => import('pages/ProjectAttachments'));
const ProjectCreationPage = lazy(() => import('pages/ProjectCreation'));
const ProjectEditingPage = lazy(() => import('pages/ProjectEditing'));
const JiraIntegration = lazy(() => import('pages/Integrations/Jira'));
const AzureIntegration = lazy(() => import('pages/Integrations/Azure'));
const SingleProjectInfo = lazy(() => import('pages/SingleProjectInfo'));
const SingleProject = lazy(() => import('pages/SingleProject'));
const Targets = lazy(() => import('pages/Targets'));
/**
 * Expo
 */
export const ProjectsRoute: React.FC = () => {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <PrivateRoute
                        component={Projects}
                        roles={[RoleTypes.operationsManager]}
                    />
                }
            />

            <Route
                path=":projectId"
                element={
                    <PrivateRoute
                        component={SingleProject}
                        roles={[RoleTypes.operationsManager]}
                    />
                }
            >
                <Route
                    path="info"
                    element={
                        <PrivateRoute
                            component={SingleProjectInfo}
                            roles={[RoleTypes.operationsManager]}
                        />
                    }
                />
                <Route
                    path="info/attachments"
                    element={
                        <PrivateRoute
                            component={Attachments}
                            roles={[RoleTypes.operationsManager]}
                        />
                    }
                />
                <Route
                    path="targets"
                    element={
                        <PrivateRoute
                            component={Targets}
                            roles={[RoleTypes.operationsManager]}
                        />
                    }
                />
                <Route path="assessments/*" element={<AssessmentsRoute />} />
            </Route>

            <Route
                path=":projectId/jira/add"
                element={
                    <PrivateRoute
                        component={JiraIntegration}
                        roles={[RoleTypes.operationsManager]}
                    />
                }
            />
            <Route
                path=":projectId/azure/add"
                element={
                    <PrivateRoute
                        component={AzureIntegration}
                        roles={[RoleTypes.operationsManager]}
                    />
                }
            />
            <Route
                path=":projectId/azure/edit"
                element={
                    <PrivateRoute
                        component={AzureIntegration}
                        roles={[RoleTypes.operationsManager]}
                    />
                }
            />
            <Route
                path="create"
                element={
                    <PrivateRoute
                        component={ProjectCreationPage}
                        roles={[RoleTypes.operationsManager]}
                    />
                }
            />
            <Route
                path=":projectId/edit"
                element={
                    <PrivateRoute
                        component={ProjectEditingPage}
                        roles={[RoleTypes.operationsManager]}
                    />
                }
            />
        </Routes>
    );
};
