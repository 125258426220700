/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { PickerFooterProps } from '@epam/uui-core';
import { ArraySelect, TextArea, TextInput } from 'components/common';
import { useAppSelector, useSelectOptionsFromEnum } from 'hooks';
import { AssetFootprints } from '../common/AssetFootprints';
import { TmArraySelect } from '../common/TmArraySelect';
import { mapElementTypeToLabel } from './utils';
import { ElementType } from 'types/threatModeling/element';
import { FC, memo } from 'react';
import {
    handleCreateElement,
    handleUpdateElement,
} from 'store/threatModeling/element/dispatchers';
import { selectElementsCount } from 'store/threatModeling/element/selectors';
import { AppDispatch, TNamed } from 'types';
import { selectTechnologies } from 'store/threatModeling/technology/selectors';
import { FormFieldsProps } from '../../../../types';
import { createEditableItemCard } from '../../../createEditableItemCard';
import { FullFieldRow, HalfFieldRow, TwoFieldsRow } from '../common';
import { createEditableField } from '../common/EditableField/EditableField';
import { SelectFooter } from '../common/multiSelectArrayFooter';
import { TEXT_AREA_ROWS, ZodNamedTmDto } from '../constants';
import { ELEMENT_READABLE_ID_PREFIX } from './constants';
import { elementFormSchema, ElementFormValues } from './schema';
import { selectTrustBoundaryOptions } from 'store/threatModeling/trustBoundary/selectors';
import { transformTrustBoundaryViewValue } from '../TrustBoundary/utils';

const ElementEditableField = createEditableField<ElementFormValues>();

const ElementFormFields: FC<FormFieldsProps<ElementFormValues>> = () => {
    const typeOptions = useSelectOptionsFromEnum(
        ElementType,
        mapElementTypeToLabel
    );
    const technologyOptions = useAppSelector(selectTechnologies);
    const trustBoundaryOptions = useAppSelector(selectTrustBoundaryOptions);

    // TODO: remove any and extract to a common place
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const renderFooter = (props: PickerFooterProps<any, any>): JSX.Element => (
        <SelectFooter {...props} />
    );
    // TODO: change id in AQA repo from elementForm/id to elementForm/readableId

    return (
        <>
            <TwoFieldsRow>
                <ElementEditableField
                    name="readableId"
                    label="Element ID"
                    controllerComponent={TextInput}
                    controllerProps={{
                        // TODO: remove hardcoded value
                        placeholder: 'Enter a text',
                        isReadonly: true,
                    }}
                    dataTestId="elementForm/readableId"
                />
                <ElementEditableField
                    name="name"
                    label="Element name"
                    controllerComponent={TextInput}
                    controllerProps={{
                        placeholder: 'Enter a text',
                    }}
                    dataTestId="elementForm/name"
                />
            </TwoFieldsRow>
            <TwoFieldsRow>
                <ElementEditableField
                    name="technologies"
                    label="Technologies"
                    controllerComponent={ArraySelect<TNamed>}
                    controllerProps={{
                        placeholder: 'Please select',
                        labelKey: 'name',
                        valueType: 'entity',
                        options: technologyOptions,
                        disableClear: true,
                        mode: 'multi',
                        searchPosition: 'body',
                        idKey: 'id',
                        renderFooter,
                        maxItems: 3,
                        sorting: { field: 'name', direction: 'asc' },
                    }}
                    transformValueForView={(technologies) =>
                        technologies?.map((technology) => technology.name)
                    }
                    dataTestId="elementForm/technologies"
                />
                <ElementEditableField
                    name="type"
                    label="Type"
                    controllerComponent={TmArraySelect}
                    controllerProps={{
                        placeholder: 'Please select',
                        labelKey: 'label',
                        options: typeOptions,
                    }}
                    transformValueForView={(type) =>
                        mapElementTypeToLabel(type)
                    }
                    dataTestId="elementForm/type"
                />
            </TwoFieldsRow>
            <HalfFieldRow>
                <ElementEditableField
                    name="trustBoundary"
                    label="Trust boundary"
                    controllerComponent={ArraySelect<ZodNamedTmDto>}
                    controllerProps={{
                        placeholder: 'Please select',
                        valueType: 'entity',
                        options: trustBoundaryOptions,
                        getName: transformTrustBoundaryViewValue,
                    }}
                    transformValueForView={(value) =>
                        value && transformTrustBoundaryViewValue(value)
                    }
                    dataTestId="elementForm/trustBoundary"
                />
            </HalfFieldRow>
            <FullFieldRow>
                <ElementEditableField
                    name="comment"
                    label="Comment"
                    controllerComponent={TextArea}
                    controllerProps={{
                        placeholder: 'Enter a text',
                        rows: TEXT_AREA_ROWS,
                    }}
                    dataTestId="elementForm/comment"
                />
            </FullFieldRow>
            <AssetFootprints />
        </>
    );
};

export const ElementItemCard = memo(
    createEditableItemCard(ElementFormFields, {
        formSchema: elementFormSchema,
        onCreate: (values) => {
            return async (dispatch: AppDispatch) => {
                await dispatch(handleCreateElement(values));
            };
        },
        onEdit: (id, values) => {
            return async (dispatch: AppDispatch) => {
                await dispatch(handleUpdateElement(id, values));
            };
        },
        readableIdPrefix: ELEMENT_READABLE_ID_PREFIX,
        countSelector: selectElementsCount,
    })
);

ElementItemCard.displayName = 'ElementItemCard';
