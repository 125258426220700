/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { RefObject, useEffect, useState } from 'react';
/**
 * Expo
 */
export const useHover = (
    ref: RefObject<HTMLDivElement | null>,
    callback?: (isHovered: boolean) => void
): boolean => {
    const [isHovered, changeIsHovered] = useState(false);

    useEffect(() => {
        const { current } = ref;

        if (!current) return undefined;

        const onMouseEnter = () => {
            changeIsHovered(true);

            if (callback) {
                callback(true);
            }
        };

        const onMouseLeave = () => {
            changeIsHovered(false);

            if (callback) {
                callback(false);
            }
        };

        current.addEventListener('mouseenter', onMouseEnter);
        current.addEventListener('mouseleave', onMouseLeave);

        return () => {
            current.removeEventListener('mouseenter', onMouseEnter);
            current.removeEventListener('mouseleave', onMouseLeave);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    return isHovered;
};
