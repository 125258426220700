/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, memo } from 'react';
/**
 * Types
 */
import { TAssessmentCommonActionLog } from 'types';
import {
    RecentActionContent,
    TextBlock,
} from 'components/RecentActions/RecentAction';
/**
 * Expo
 */
export const StartAssessment: FC<TAssessmentCommonActionLog> = memo(
    ({
        details: {
            current_state: { name: assessmentName },
        },
    }) => (
        <RecentActionContent dataTestId="assessmentStarted">
            <TextBlock
                dataTestId="assessmentStarted"
                text={`Assessment ${assessmentName} started`}
            />
        </RecentActionContent>
    )
);
