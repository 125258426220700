/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useMemo } from 'react';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
import { useAppDispatch, useRole, useRouteParams, useAppSelector } from 'hooks';
/**
 * Components
 */
import { Button } from 'components/common';
import { MarkAsBlockedModal } from './MarkAsBlockedModal';
/**
 * Constants
 */
import { StatusTypes } from 'constants/statuses';
/**
 * Assets
 */
import { ReactComponent as doneIcon } from '@epam/assets/icons/common/notification-done-18.svg';
import { ReactComponent as errorIcon } from '@epam/assets/icons/common/notification-error-outline-18.svg';
/**
 * Store
 */
import { selectUserId } from 'store/user';
import {
    handleBlockActivity,
    handleUnblockActivity,
} from 'store/singleActivity';
import {
    selectActivity,
    selectAssessment,
    handleGetAssessmentWithActivities,
} from 'store/assessment';
/**
 * Types
 */
import { TFormValues } from './MarkAsBlockedModal/types';
/**
 * Utils
 */
import { showModal } from 'utils';
/**
 * Expo
 */
export const Buttons: React.FC = () => {
    const dispatch = useAppDispatch();
    const { assessmentId, activityId } = useRouteParams();

    const userId = useSelector(selectUserId);
    const { status: assessmentStatus } = useSelector(selectAssessment);

    const { isProjectRepresentative } = useRole();

    const {
        blocked,
        status: activityStatus,
        assignees: { persons } = { persons: [] },
    } = useAppSelector((state) => selectActivity(state, activityId));

    const isUserPerformedOnActivity = useMemo(
        () => persons.some(({ id }) => id === userId),
        [persons, userId]
    );

    const handleSave = useCallback(
        async ({ comment }: TFormValues) => {
            await dispatch(handleBlockActivity({ activityId, comment }));
            dispatch(handleGetAssessmentWithActivities(assessmentId));
        },
        [activityId, assessmentId, dispatch]
    );

    const handleShowBlockModal = useCallback(() => {
        showModal(MarkAsBlockedModal, {
            onSubmit: handleSave,
        });
    }, [handleSave]);

    const handleUnblock = useCallback(async () => {
        await dispatch(handleUnblockActivity(activityId));
        dispatch(handleGetAssessmentWithActivities(assessmentId));
    }, [activityId, assessmentId, dispatch]);

    const canBlock =
        !blocked &&
        !isProjectRepresentative &&
        isUserPerformedOnActivity &&
        assessmentStatus === StatusTypes.active &&
        (activityStatus === StatusTypes.toDo ||
            activityStatus === StatusTypes.inProgress);

    const canUnblock =
        blocked && !isProjectRepresentative && isUserPerformedOnActivity;

    return (
        <>
            {canBlock && (
                <Button
                    fill="outline"
                    icon={errorIcon}
                    onClick={handleShowBlockModal}
                    caption="Mark as blocked"
                    dataTestId="activity/button/markAsBlocked"
                />
            )}
            {canUnblock && (
                <Button
                    fill="outline"
                    icon={doneIcon}
                    onClick={handleUnblock}
                    caption="Mark as unblocked"
                    dataTestId="activity/button/unblock"
                />
            )}
        </>
    );
};
