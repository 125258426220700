/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import * as z from 'zod';
import { zodAssetFootprintArray, zodEntryPointsDtoArray } from '../constants';
import { baseItemFormSchema } from '../baseItemFormSchema';

export const technologyFormSchema = baseItemFormSchema.extend({
    assetFootprints: zodAssetFootprintArray.default([]),
    entryPoints: zodEntryPointsDtoArray.default([]),
});

export type TechnologyFormValues = z.infer<typeof technologyFormSchema>;
export type TechnologyFormSchema = typeof technologyFormSchema;
