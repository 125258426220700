/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { FlexSpacer } from '@epam/promo';
import React from 'react';
import { cx } from '@epam/uui-core';
/**
 * Hooks
 */
import { useAppSelector, useRedirectToDefaultPage, useRole } from 'hooks';
/**
 * Constants
 */
import { PlatformTypes, PLATFORM_CAPTIONS } from 'constants/system';
/**
 * Components
 */
import { FlexRow, Panel, IconContainer, Text } from 'components/common';
import { AgileSecurityPlatform, ServicesDiscoveryConsole } from './components';
/**
 * Types
 */
import { TContentProps } from './type';
/**
 * Assets
 */
import css from './style.module.scss';
import { ReactComponent as crossIcon } from '@epam/assets/icons/common/navigation-close-18.svg';
/**
 * Utils
 */
import { NotificationTypes, showNotification } from 'utils';
/**
 * Store
 */
import { selectUserProjectId, selectUserRole } from 'store/user';
/**
 * Expo
 */
export const Content: React.FC<TContentProps> = ({ onClose }) => {
    const { isSalesManager, isSecuritySpecialist } = useRole();
    const redirectToDefaultPage = useRedirectToDefaultPage();

    const projectId = useAppSelector(selectUserProjectId);
    const role = useAppSelector(selectUserRole);

    const handleRedirect = (platformType: PlatformTypes) => {
        const isAgileSecurityPlatform =
            platformType === PlatformTypes.agileSecurityPlatform;

        const isApplicationNoAccess =
            (isSalesManager && isAgileSecurityPlatform) ||
            (isSecuritySpecialist && !isAgileSecurityPlatform);

        if (isApplicationNoAccess) {
            showNotification({
                text: `You cannot access ${PLATFORM_CAPTIONS[platformType]}`,
                type: NotificationTypes.error,
            });
            return;
        }
        onClose();

        if (role) {
            redirectToDefaultPage(role, projectId, platformType);
        }
    };

    return (
        <Panel cx={cx(css.sideBar, 'rightSlide')}>
            <FlexRow vPadding="24" padding="24" cx={css.header}>
                <Text lineHeight="24" fontSize="18" cx={css.title}>
                    EPAM Security Center
                </Text>
                <FlexSpacer />
                <IconContainer icon={crossIcon} onClick={onClose} />
            </FlexRow>
            <Panel cx={css.marginTop}>
                <ServicesDiscoveryConsole onClick={handleRedirect} />
                <AgileSecurityPlatform onClick={handleRedirect} />
            </Panel>
        </Panel>
    );
};
