/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, useCallback, useState } from 'react';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
import { useOnMount } from 'hooks/useOnMount';
import { useFormContext } from 'react-hook-form';
/**
 * Components
 */
import { ArraySelect } from 'components/common';
/**
 * Types
 */
import { TAssessment } from 'types';
import { TAssessmentForReportsProps } from './types';
/**
 * Utils
 */
import { getOptions } from './utils';
/**
 * Store
 */
import { selectUserProjectId } from 'store/user';
/**
 * Expo
 */
export const AssessmentForReport: FC<TAssessmentForReportsProps> = ({
    value,
    isInvalid,
    isRequired,
    dataTestId,
    onValueChange,
    onBlur,
}) => {
    const [options, setOptions] = useState<TAssessment[]>([]);

    const { resetField } = useFormContext();

    const projectId = useSelector(selectUserProjectId);

    const handleGetOptions = async () => {
        if (!projectId) return;

        const response = await getOptions(projectId);

        setOptions(response);

        if (response.length && !value) {
            const [{ id: firstAssessmentId }] = response;

            onValueChange(firstAssessmentId);
        }
    };

    const handleChange = useCallback(
        (props: string) => {
            resetField('findingsIds');
            onValueChange(props);
        },
        [onValueChange, resetField]
    );

    useOnMount(handleGetOptions);

    return (
        <ArraySelect<TAssessment>
            disableClear
            value={value}
            labelKey="name"
            options={options}
            label="Assessment"
            isInvalid={isInvalid}
            isRequired={isRequired}
            dataTestId={dataTestId}
            onValueChange={handleChange}
            onBlur={onBlur}
        />
    );
};
