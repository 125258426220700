/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { templateString } from 'utils';
import { ENDPOINTS } from 'constants/api';
import { api } from 'services/api';
import { Methods } from 'constants/request';

export const handleUploadDfd = async (activityId: string, file: File) => {
    const url = templateString(ENDPOINTS.uploadDfd, {
        activityId,
    });
    const formData = new FormData();
    formData.append('file', file, file.name);
    await api.request(Methods.post, { url, data: formData });
};

export const handleRemoveDfd = async (activityId: string) => {
    const url = templateString(ENDPOINTS.removeDfd, {
        activityId,
    });
    await api.request(Methods.delete, { url });
};
