/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Text } from 'components/common';
/**
 * Types
 */
import { TListProps } from './types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const List: React.FC<TListProps> = ({ list }) => (
    <ul className={css.list}>
        {list.map((listElement, listElementIndex) => (
            <li key={`listElement_${listElementIndex + 1}`}>
                <Text cx={css.removeBottomPadding}>{listElement}</Text>
            </li>
        ))}
    </ul>
);
