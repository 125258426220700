/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Text } from 'components/common';
import { ScrollBars } from '@epam/promo';
/**
 * Expo
 */
export const FindingSeverityTable: React.FC = () => (
    <ScrollBars>
        <table>
            <thead>
                <tr>
                    <th scope="col">
                        <Text>Severity</Text>
                    </th>
                    <th scope="col">
                        <Text>Description</Text>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <th scope="row">
                        <Text fontSize="12" lineHeight="18">
                            Critical
                        </Text>
                    </th>
                    <td>
                        <Text fontSize="10">
                            The finding may result in unauthorized privileged
                            access to systems, hosts, or confidential data,
                            remote code execution.
                        </Text>
                        <Text fontSize="10">
                            The finding should be prioritized and addressed
                            immediately.
                        </Text>
                    </td>
                </tr>

                <tr>
                    <th scope="row">
                        <Text fontSize="12" lineHeight="18">
                            High
                        </Text>
                    </th>
                    <td>
                        <Text fontSize="10">
                            The finding can allow malicious attackers to access
                            application resources and data without remote code
                            execution. This can allow an attacker to steal
                            session information or sensitive data from the
                            application or server.
                        </Text>
                        <Text fontSize="10">
                            The finding should be prioritized and addressed
                            immediately.
                        </Text>
                    </td>
                </tr>

                <tr>
                    <th scope="row">
                        <Text fontSize="12" lineHeight="18">
                            Medium
                        </Text>
                    </th>
                    <td>
                        <Text fontSize="10">
                            The finding might not result in immediate
                            unauthorized access or data loss but may be combined
                            with other vulnerabilities or weaknesses to
                            potentially gain unauthorized access to systems,
                            hosts, applications, or confidential data. These
                            findings should be addressed in a timely manner to
                            avoid risk escalation through chaining of
                            vulnerabilities.
                        </Text>
                    </td>
                </tr>

                <tr>
                    <th scope="row">
                        <Text fontSize="12" lineHeight="18">
                            Low
                        </Text>
                    </th>
                    <td>
                        <Text fontSize="10">
                            Finding may result in exposure of sensitive
                            information, which may aid an attacker, or
                            represents a process that can be improved to
                            strengthen the overall security program. These
                            findings are a risk to business and should be given
                            attention after High and Medium severity issues are
                            addressed.
                        </Text>
                    </td>
                </tr>

                <tr>
                    <th scope="row">
                        <Text fontSize="12" lineHeight="18">
                            Informational
                        </Text>
                    </th>
                    <td>
                        <Text fontSize="10">
                            This finding is kind of recommendation how to
                            improve the system. Usually, these findings do not
                            pose risk by themselves but can help attacker in
                            some cases: e.g. understand application logic.
                            Attention to these issues should be given after all
                            previous issues have been fixed.
                        </Text>
                    </td>
                </tr>
            </tbody>
        </table>
    </ScrollBars>
);
