/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/*
 * Constants
 */
import { StyleAlignments } from 'constants/richTextEditor';
/*
 * Assets
 */
import { ReactComponent as textColorIcon } from '@epam/assets/icons/common/editor-format_text_color-24.svg';
import { ReactComponent as hIcon } from '@epam/assets/icons/common/editor-headline-24.svg';
import { ReactComponent as h1Icon } from '@epam/assets/icons/common/editor-headline_h1-24.svg';
import { ReactComponent as h2Icon } from '@epam/assets/icons/common/editor-headline_h2-24.svg';
import { ReactComponent as h3Icon } from '@epam/assets/icons/common/editor-headline_h3-24.svg';
import { ReactComponent as boldIcon } from '@epam/assets/icons/common/editor-format_bold-24.svg';
import { ReactComponent as italicIcon } from '@epam/assets/icons/common/editor-format_italic-24.svg';
import { ReactComponent as bulletListIcon } from '@epam/assets/icons/common/editor-list_bullet-24.svg';
import { ReactComponent as numberListIcon } from '@epam/assets/icons/common/editor-list_number-24.svg';
import { ReactComponent as underlineIcon } from '@epam/assets/icons/common/editor-format_underlined-24.svg';
import { ReactComponent as alignLeftIcon } from '@epam/assets/icons/common/editor-format_text_alignment_left-24.svg';
import { ReactComponent as alignCenterIcon } from '@epam/assets/icons/common/editor-format_text_alignment_center-24.svg';
import { ReactComponent as alignRightIcon } from '@epam/assets/icons/common/editor-format_text_alignment_right-24.svg';
/**
 * Utils
 */
import { pick } from 'utils';
/*
 * Expo
 */
const HEADINGS = [
    { icon: h1Icon, style: 'header-one' },
    { icon: h2Icon, style: 'header-two' },
    { icon: h3Icon, style: 'header-three' },
];

export const HEADING_BLOCK = {
    icon: hIcon,
    items: HEADINGS,
    includedStyle: pick(HEADINGS, 'style'),
};

export const INLINE_STYLES = [
    { icon: boldIcon, style: 'BOLD' },
    { icon: italicIcon, style: 'ITALIC' },
    { icon: underlineIcon, style: 'UNDERLINE' },
    { icon: textColorIcon, style: 'BACKGROUND_HIGHLIGHTS_YELLOW' },
];

export const ALIGNMENT_STYLES = [
    { icon: alignLeftIcon, style: StyleAlignments.left },
    { icon: alignCenterIcon, style: StyleAlignments.center },
    { icon: alignRightIcon, style: StyleAlignments.right },
];

export const BLOCK_TYPES = [
    { icon: bulletListIcon, style: 'unordered-list-item' },
    { icon: numberListIcon, style: 'ordered-list-item' },
];
