/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, useCallback, useEffect, useState } from 'react';
/**
 * Hooks
 */
import { useRouteParams } from 'hooks';
import { useFormContext, useWatch } from 'react-hook-form';
/**
 * Components
 */
import { ListItem } from './ListItem';
import { Checkbox, Panel, Text } from 'components/common';
import { FlexCell, FlexRow, ScrollBars } from '@epam/promo';
/**
 * Types
 */
import { PaginatedResponse, TFindingListItem } from 'types';
import { TListProps } from './types';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
import { StatusTypes } from 'constants/statuses';
import { FilterQueryParams } from 'constants/filter';
/**
 * Utils
 */
import { createQueryUrl } from 'utils';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const FindingList: FC<TListProps> = ({ onValueChange, value }) => {
    const [findingList, setFindingList] = useState<TFindingListItem[]>([]);

    const { projectId } = useRouteParams();

    const { control } = useFormContext();

    const assessmentId = useWatch({ control, name: 'assessmentId' });

    const handleGetFindings = useCallback(async () => {
        const actualFindingStatuses = [
            StatusTypes.resolved,
            StatusTypes.unresolved,
            StatusTypes.retest,
            StatusTypes.readyToRetest,
        ];

        const queryUrl = createQueryUrl(
            ENDPOINTS.findingsFiltered,
            {
                [FilterQueryParams.assessmentId]: assessmentId,
                projectId,
                status: actualFindingStatuses,
            },
            { arrayFormat: 'repeat' }
        );

        const { data } = await api.request<
            PaginatedResponse<TFindingListItem[]>
        >(Methods.get, {
            url: queryUrl,
        });

        setFindingList(data);
    }, [assessmentId, projectId]);

    useEffect(() => {
        handleGetFindings();
    }, [handleGetFindings]);

    const handleChange = useCallback(
        (checked: boolean, id: string) => {
            let valueToChange = [...value];

            if (checked) {
                valueToChange.push(id);
            } else {
                valueToChange = valueToChange.filter(
                    (valueItem) => valueItem !== id
                );
            }

            onValueChange(valueToChange);
        },
        [value, onValueChange]
    );

    const handleMarkAll = useCallback(
        (checked: boolean) => {
            const chosenFindingsIds: string[] = [];

            if (checked) {
                findingList.forEach((finding) => {
                    chosenFindingsIds.push(finding.id);
                });
            }

            onValueChange(chosenFindingsIds);
        },
        [findingList, onValueChange]
    );

    return (
        <>
            <Panel cx={css.countContainer}>
                <FlexCell cx={css.countCheckboxHeader}>
                    <FlexRow columnGap="12">
                        <Checkbox
                            onValueChange={handleMarkAll}
                            value={Boolean(value.length)}
                            dataTestId="configureReport/findings/checkbox/selectAll"
                        />
                        <Text
                            color="gray60"
                            dataTestId="configureReport/findings/text/totalSelected"
                        >
                            {`${value.length} of ${findingList.length} Findings selected`}
                        </Text>
                    </FlexRow>
                </FlexCell>
            </Panel>
            <ScrollBars>
                <Panel cx={css.form}>
                    {findingList.map((finding) => (
                        <ListItem
                            {...finding}
                            key={finding.id}
                            onChange={handleChange}
                            isChecked={value.includes(finding.id)}
                        />
                    ))}
                </Panel>
            </ScrollBars>
        </>
    );
};
