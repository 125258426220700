/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
import { TrustBoundaryFormValues } from 'pages/ThreatModeling/tabs/ModelTreeTab/components/ExpandableTree/components/ItemEditor/forms/TrustBoundary/schema';
import {
    mapFormValuesToTrustBoundaryPayload,
    mapFormValuesToTrustBoundaryUpdatePayload,
} from 'pages/ThreatModeling/tabs/ModelTreeTab/components/ExpandableTree/components/ItemEditor/forms/TrustBoundary/utils';
import { api } from 'services/api';
import { AppDispatch, TNamed, TrustBoundary } from 'types';
import { createQueryUrl } from 'utils';
import {
    addTrustBoundary,
    saveTrustBoundaries,
    saveTrustBoundaryTypes,
    setTrustBoundariesLoadingInProgress,
    updateTrustBoundary,
} from './slice';
import {
    CreateTypePayload,
    TrustBoundaryCreatePayload,
    TrustBoundaryUpdatePayload,
} from './types';
import { handleElements } from '../element/fetchAllDispatcher';

export const handleGetTrustBoundaries =
    (tmId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            dispatch(setTrustBoundariesLoadingInProgress(true));
            const url = createQueryUrl(ENDPOINTS.trustBoundaries, {
                tmId,
            });
            const trustBoundaries = await api.request<TrustBoundary[]>(
                Methods.get,
                {
                    url,
                }
            );
            dispatch(saveTrustBoundaries(trustBoundaries));
        } finally {
            dispatch(setTrustBoundariesLoadingInProgress(false));
        }
    };

export const handleCreateTrustBoundary =
    (form: TrustBoundaryFormValues) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = ENDPOINTS.trustBoundaries;
        const requestBody = mapFormValuesToTrustBoundaryPayload(form);
        const trustBoundary = await api.request<
            TrustBoundary,
            TrustBoundaryCreatePayload
        >(Methods.post, { url, data: requestBody });

        dispatch(addTrustBoundary(trustBoundary));
        await dispatch(handleElements(form.tmId));
    };

export const handleUpdateTrustBoundary =
    (id: string, form: TrustBoundaryFormValues) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = `${ENDPOINTS.trustBoundaries}/${id}`;
        const requestBody = mapFormValuesToTrustBoundaryUpdatePayload(form);
        const updatedTrustBoundary = await api.request<
            TrustBoundary,
            TrustBoundaryUpdatePayload
        >(Methods.put, { url, data: requestBody });

        dispatch(updateTrustBoundary(updatedTrustBoundary));
        await dispatch(handleElements(form.tmId));
    };

export const handleGetTypes =
    (tmId: string) => async (dispatch: AppDispatch) => {
        const url = createQueryUrl(ENDPOINTS.trustBoundaryTypes, { tmId });
        const types = await api.request(Methods.get, { url });

        dispatch(saveTrustBoundaryTypes(types));
    };

export const handleCreateType =
    (name: string, tmId: string) => async (dispatch: AppDispatch) => {
        const url = ENDPOINTS.trustBoundaryTypes;
        const createdType = await api.request<TNamed, CreateTypePayload>(
            Methods.post,
            {
                url,
                data: { name, tm_id: tmId },
            }
        );
        dispatch(handleGetTypes(tmId));

        return createdType;
    };
