/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
import { Methods } from 'constants/request';
import { ApiStatusCodes, apiType, ApiTypes, ENDPOINTS } from 'constants/api';
import { DEFAULT_API_HEADERS, TokenStorageKeys } from './constants';
import { SESSION_STORAGE_KEYS } from 'constants/localStorage';
/**
 * Services
 */
import SessionStorage from 'services/sessionStorage';
/**
 * Types
 */
import { THttpError } from 'types';
import { TRestParams } from './request/types';
import { TCreateWebSocketInstanceParams } from './types';
/**
 * Utils
 */
import { request } from './request';
import { requestAuth } from './requestAuth';
import {
    getBaseApiUrl,
    getWebSocketUrl,
    saveRedirectUrl,
    showErrors,
} from 'utils';

/**
 * Expo
 */
function createApiInstance() {
    const storage = localStorage;

    let refreshRequest: null | Promise<void> = null;

    const getItem = (key: TokenStorageKeys): string | null =>
        storage.getItem(key);

    const prepareRequestParams = (withAuth = true) => {
        const authentication = withAuth
            ? { Authorization: `Bearer ${getItem(TokenStorageKeys.token)}` }
            : {};
        return <TData>({ headers, ...params }: TRestParams<TData>) => ({
            ...params,
            baseURL: getBaseApiUrl(),
            headers: {
                ...DEFAULT_API_HEADERS,
                ...headers,
                ...authentication,
            } as Record<string, string>,
        });
    };

    return {
        get isTokenExists() {
            return Boolean(getItem(TokenStorageKeys.token));
        },

        setItem(key: TokenStorageKeys, value: string): void {
            storage.setItem(key, value);
        },

        removeItem(key: TokenStorageKeys): void {
            storage.removeItem(key);
        },

        async reset(): Promise<void> {
            this.socket?.close();
            try {
                await this.notifyAboutLogout();
                SessionStorage.remove(SESSION_STORAGE_KEYS.redirectUrl);
                const { redirectUrl } = await requestAuth(Methods.get, {
                    url: ENDPOINTS.logout,
                });
                this.removeItem(TokenStorageKeys.refreshToken);
                this.removeItem(TokenStorageKeys.token);
                if (redirectUrl) {
                    window.location.replace(redirectUrl);
                }
            } catch (messages) {
                showErrors(messages);
            }
        },

        async notifyAboutLogout(): Promise<void> {
            try {
                const getRequestParams = prepareRequestParams();
                await request(
                    Methods.post,
                    getRequestParams({
                        url: ENDPOINTS.personLogout,
                    })
                );
            } catch (error) {
                const {
                    response: {
                        status,
                        data: { messages },
                    },
                } = <THttpError>error;
                if (status === ApiStatusCodes.restApiErrors) {
                    showErrors(messages);
                }
            }
        },

        async renewToken() {
            this.removeItem(TokenStorageKeys.token);

            try {
                const { idToken, refreshToken } = await requestAuth(
                    Methods.post,
                    {
                        url: ENDPOINTS.refreshToken,
                        data: {
                            refresh_token: getItem(
                                TokenStorageKeys.refreshToken
                            ),
                        },
                    }
                );

                if (idToken) {
                    this.setItem(TokenStorageKeys.token, idToken);
                }

                if (refreshToken) {
                    this.setItem(TokenStorageKeys.refreshToken, refreshToken);
                }
            } catch (error) {
                saveRedirectUrl();
                window.location.replace(PAGES.login);
            }
        },

        async request<TResponse, TData = undefined>(
            method: Methods,
            params: TRestParams<TData>,
            withAuth = true,
            showError = true
        ): Promise<TResponse> {
            try {
                const getRequestParams = prepareRequestParams(withAuth);
                const { data } = await request<TResponse, TData>(
                    method,
                    getRequestParams(params)
                );

                // Blob file doesn't have object payload
                return data.payload ?? data;
            } catch (error) {
                const {
                    response: {
                        status,
                        data: { messages },
                    },
                } = <THttpError>error;

                if (status === ApiStatusCodes.unauthorized) {
                    if (refreshRequest == null) {
                        refreshRequest = this.renewToken();
                    }
                    await refreshRequest;
                    refreshRequest = null;
                    const getRequestParams = prepareRequestParams(withAuth);
                    const { data } = await request<TResponse, TData>(
                        method,
                        getRequestParams(params)
                    );

                    // Blob file doesn't have object payload
                    return data.payload ?? data;
                }

                // handle authentication exception codes
                if (status === ApiStatusCodes.forbidden) {
                    window.location.replace(PAGES.blockedUser);
                }
                if (status === ApiStatusCodes.notFound) {
                    window.location.replace(PAGES.unknownUser);
                }

                // handle specific exception codes
                if (
                    (status === ApiStatusCodes.restApiErrors ||
                        status === ApiStatusCodes.validationError) &&
                    showError
                ) {
                    showErrors(messages);
                }

                throw error;
            }
        },

        createWebSocketInstance({
            userId,
            onOpen,
        }: TCreateWebSocketInstanceParams): WebSocket {
            this.socket = new WebSocket(getWebSocketUrl());
            this.socket.onopen = () => {
                const token = getItem(TokenStorageKeys.token);
                const gatewayPayload = JSON.stringify({
                    payload: { token, userId },
                });

                const authPayload =
                    apiType === ApiTypes.basic ? token : gatewayPayload;

                if (token) {
                    this.socket.send(authPayload);
                }

                if (onOpen) {
                    onOpen(this.socket);
                }
            };

            return this.socket;
        },
    };
}

export default createApiInstance();
