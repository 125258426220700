/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { forwardRef, Ref } from 'react';
import { cx } from '@epam/uui-core';
/**
 * Components
 */
import { Avatar as UuiAvatar } from '@epam/promo';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { AvatarProps } from './types';
/**
 * Expo
 */
export const Avatar: React.FC<AvatarProps> = forwardRef(
    (
        {
            img = '/assets/images/defaultAvatar.png',
            className,
            size = '36',
            alt = 'avatar',
        },
        ref: Ref<HTMLDivElement>
    ) => (
        <div ref={ref} className={cx(css.avatarContainer, className)}>
            <UuiAvatar alt={alt} img={img} size={size} />
        </div>
    )
);
