/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Hooks
 */
import { useRouteParams } from 'hooks';
/**
 * Types
 */
import { TListProps } from './types';
/**
 * Components
 */
import { LinkButton } from 'components/common';
import { EmptyViewPolicy } from '../EmptyViewPolicy';
/**
 * Assets
 */
import css from '../style.module.scss';
/**
 * Utils
 */
import { generatePath } from 'utils';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
/**
 * Expo
 */
export const List: React.FC<TListProps> = ({ list }) => {
    const { projectId } = useRouteParams();

    return list.length ? (
        <>
            {list.map(({ name, id }) => (
                <LinkButton
                    caption={name}
                    cx={css.policyLinkButton}
                    dataTestId="projectInfo-policy-cells-name"
                    href={generatePath(PAGES.projectSinglePolicy, {
                        projectId,
                        policyId: id,
                    })}
                />
            ))}
        </>
    ) : (
        <EmptyViewPolicy
            alt="no policies"
            src="/assets/images/emptyViews/box_image.svg"
            dataTestId="policies/table/active/emptyView"
            text="No policies added. You can align the project with practices followed by your organization (e.g., remediating critical findings within three days or remediating findings that do not comply with Application Security Verification Standard (ASVS) requirements)."
        />
    );
};
