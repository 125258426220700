/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { SelectFooter } from 'components';
/**
 * Constants
 */
import { MAX_TARGETS_VISIBLE_ITEMS } from './constants';
/**
 * Types
 */
import { TTarget } from 'types';
import { PickerFooterProps } from '@epam/uui-core';
/**
 * Expo
 */
export type TGetNameString = (params: {
    value?: string[];
    options: TTarget[];
}) => string;

export const getNamesString: TGetNameString = ({ options, value }) => {
    if (!value || value.length <= MAX_TARGETS_VISIBLE_ITEMS) {
        return '';
    }

    const nameList = value.map((id: string) => {
        const { name } = options.find((option) => option.id === id) || {};

        return name;
    });

    return nameList.join('; ');
};

export const renderFooter = (
    props: PickerFooterProps<TTarget, TTarget[keyof TTarget]>
): JSX.Element => <SelectFooter<TTarget> {...props} />;
