/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useMemo } from 'react';
/**
 * Components
 */
import { Button, Text, FlexRow } from 'components/common';
import {
    Panel,
    ScrollBars,
    FlexSpacer,
    ModalFooter,
    ModalHeader,
    ModalWindow,
    ModalBlocker,
} from '@epam/promo';
/**
 * Utils
 */
import { getRawProps } from 'utils';
/**
 * Types
 */
import { ButtonAppearance, ModalProps } from './types';
/**
 * Constants
 */
import { ModalSizes } from 'constants/modals';

/**
 * Expo
 */
const DEFAULT_CANCEL_BUTTON_APPEARANCE: ButtonAppearance = {
    fill: 'outline',
    color: 'neutral',
};

const DEFAULT_SUCCESS_BUTTON_APPEARANCE: ButtonAppearance = {
    fill: 'solid',
    color: 'primary',
};

export const Modal: React.FC<ModalProps> = ({
    title,
    width = ModalSizes.large,
    bodyText,
    className,
    dataTestId,
    withoutFooter,
    allowCloseOnCross = true,
    successButtonCaption = 'Ok',
    cancelButtonCaption = 'Cancel',
    onCancel,
    onSuccess,
    renderBody,
    cancelButtonAppearance = DEFAULT_CANCEL_BUTTON_APPEARANCE,
    successButtonAppearance = DEFAULT_SUCCESS_BUTTON_APPEARANCE,
    ...props
}) => {
    const { abort, success } = props;

    const handleClose = useCallback(() => {
        if (onCancel) onCancel();

        abort();
    }, [abort, onCancel]);

    const handleSuccess = useCallback(async () => {
        if (onSuccess) {
            await onSuccess();
        }

        abort();
    }, [onSuccess, abort]);

    const rawProps = useMemo(() => getRawProps({ dataTestId }), [dataTestId]);
    const titleRawProps = useMemo(
        () => getRawProps({ dataTestId: `${dataTestId}/title` }),
        [dataTestId]
    );

    return (
        <ModalBlocker
            {...props}
            isActive={props.isActive || false}
            disallowClickOutside
        >
            <ModalWindow width={width} cx={className} rawProps={rawProps}>
                <Panel background="white">
                    {title && (
                        <>
                            <ModalHeader
                                borderBottom
                                title={title}
                                onClose={
                                    allowCloseOnCross ? handleClose : undefined
                                }
                                rawProps={titleRawProps}
                            />
                        </>
                    )}
                    <ScrollBars>
                        {renderBody ? (
                            renderBody({ abort, success })
                        ) : (
                            <FlexRow padding="24" vPadding="48">
                                <Text
                                    lineHeight="24"
                                    dataTestId={`${dataTestId}/text`}
                                >
                                    {' '}
                                    {bodyText}{' '}
                                </Text>
                            </FlexRow>
                        )}
                    </ScrollBars>
                    {!withoutFooter && (
                        <ModalFooter borderTop>
                            <FlexSpacer />
                            <Button
                                {...cancelButtonAppearance}
                                caption={cancelButtonCaption}
                                dataTestId={`${dataTestId}/cancel`}
                                onClick={handleClose}
                            />
                            {onSuccess && (
                                <Button
                                    {...successButtonAppearance}
                                    caption={successButtonCaption}
                                    dataTestId={`${dataTestId}/success`}
                                    onClick={handleSuccess}
                                />
                            )}
                        </ModalFooter>
                    )}
                </Panel>
            </ModalWindow>
        </ModalBlocker>
    );
};
