/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, memo } from 'react';
/**
 * Types
 */
import { THandlers } from '../types';
import { TProjectReportActionLog } from 'types';
/**
 * Constants
 */
import { ReportTypes } from 'constants/reports';
/**
 * Utils
 */
import {
    DefaultRecentAction,
    useHref,
} from 'components/RecentActions/RecentAction/DefaultRecentAction';
/**
 * Expo
 */
export const ReportCreatedDraft: FC<TProjectReportActionLog & THandlers> = memo(
    ({
        getReportHref,
        author: { name },
        details: {
            current_state: { id, url, type, name: reportName },
        },
    }) => {
        const href = useHref({ getHref: () => getReportHref(id), type, url });
        return (
            <DefaultRecentAction
                dataTestId="reportCreatedDraft"
                user={name}
                action="created draft report"
                target={reportName}
                href={href}
                inNewTab={type === ReportTypes.withLink}
            />
        );
    }
);
