/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useMemo } from 'react';
/**
 * Components
 */
import { IconButton as UuiIconButton } from '@epam/uui';
/**
 * Utils
 */
import { getRawProps } from 'utils';
/**
 * Types
 */
import { IconButtonProps } from './types';
/**
 * Expo
 */
export const IconButton: React.FC<IconButtonProps> = ({
    dataTestId,
    type = 'button',
    ...props
}) => {
    const rawProps = useMemo(
        () => ({ ...getRawProps({ dataTestId }), type }),
        [dataTestId, type]
    );

    return <UuiIconButton {...props} rawProps={rawProps} />;
};
