/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC } from 'react';
/**
 * Components
 */
import { Text } from 'components/common';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { WithDataTestId } from 'types';
import { TTextBlockProps } from './types';
/**
 * Utils
 */
import { cx } from '@epam/uui-core';
/**
 * Expo
 */
export const TextBlock: FC<TTextBlockProps & WithDataTestId> = ({
    text,
    dataTestId,
    cx: className,
    ...props
}) => (
    <Text
        {...props}
        cx={cx(css.text, className)}
        dataTestId={`${dataTestId}/text`}
    >
        {text}
    </Text>
);
