/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
/**
 * Components
 */
import { PrivateRoute } from '../../../PrivateRoute';
/**
 * Constants
 */
import { RoleTypes } from 'constants/roles';
/**
 * Pages
 */
const SingleOrganization = lazy(() => import('pages/SingleOrganization'));
/**
 * Expo
 */
export const SingleOrganizationRoute: React.FC = () => (
    <Suspense fallback={null}>
        <Routes>
            <Route
                path="/*"
                element={
                    <PrivateRoute
                        component={SingleOrganization}
                        roles={[RoleTypes.projectRepresentative]}
                    />
                }
            />
        </Routes>
    </Suspense>
);
