/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useState, useCallback, useMemo, Children } from 'react';
/**
 * Components
 */
import { Tab } from './Tab';
import { TabPane } from './TabPane';
import { FlexRow } from '@epam/promo';
/**
 * Types
 */
import { TabsProps, TChild } from './types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Utils
 */
import { getFirstTab } from './utils';
/**
 * Expo
 */
const TabList: React.FC<TabsProps> = ({ children, currentTab, onTabClick }) => {
    const arrayChildren = useMemo(
        () => Children.toArray(children) as TChild[],
        [children]
    );

    const [activeTab, changeActiveTab] = useState<string>(
        currentTab ?? getFirstTab(arrayChildren)
    );

    const handleChange = useCallback(
        (currentTabId: string) => {
            if (onTabClick) onTabClick(currentTabId);

            changeActiveTab(currentTabId);
        },
        [onTabClick, changeActiveTab]
    );

    return (
        <>
            <FlexRow background="none" borderBottom cx={css.tabContainer}>
                {arrayChildren.map(({ props: { id, title, dataTestId } }) => (
                    <Tab
                        key={id}
                        tabId={id}
                        title={title}
                        onClick={handleChange}
                        dataTestId={dataTestId}
                        activeTabId={activeTab}
                    />
                ))}
            </FlexRow>
            <div className={css.content}>
                {arrayChildren.map(({ props: { id, children: tabChildren } }) =>
                    id !== activeTab ? null : tabChildren
                )}
            </div>
        </>
    );
};

export { TabPane, TabList };
