/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { createSelector } from '@reduxjs/toolkit';
import { TSelector, TSelectorWithProps } from 'types';
import { TNotificationsById } from './types';
/**
 * Expo
 */
export const selectNotViewedCount: TSelector<number> = ({
    notifications: { notViewedCount },
}) => notViewedCount;
export const selectIdList: TSelector<string[]> = ({
    notifications: {
        notificationsListPage: { idList },
    },
}) => idList;
const selectNotificationsById: TSelector<TNotificationsById> = ({
    notifications: {
        notificationsListPage: { itemById },
    },
}) => itemById;

const selectNotificationId: TSelectorWithProps<string, string> = (state, id) =>
    id;

export const selectNotifications = createSelector(
    [selectNotificationsById],
    (notificationsById) => Object.values(notificationsById)
);

export const selectNotification = createSelector(
    [selectNotificationsById, selectNotificationId],
    (notificationsById, id) => notificationsById[id]
);

export const selectDropdownIdList: TSelector<string[]> = ({
    notifications: {
        notificationsListDropdown: { idList },
    },
}) => idList;

const selectDropdownNotificationsById: TSelector<TNotificationsById> = ({
    notifications: {
        notificationsListDropdown: { itemById },
    },
}) => itemById;

export const selectDropdownNotifications = createSelector(
    [selectDropdownNotificationsById],
    (notificationsById) => Object.values(notificationsById)
);

export const selectDropdownNotification = createSelector(
    [selectDropdownNotificationsById, selectNotificationId],
    (notificationsById, id) => notificationsById[id]
);
