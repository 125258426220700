/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { FieldController } from 'components/Fields';
import { CheckboxGroup, Panel } from 'components/common';
/**
 * Utils
 */
import { cx } from '@epam/uui-core';
import { checkBoxesGroupValidator } from 'utils';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { TCheckboxGroupProps } from 'components/common/CheckboxGroup/types';
/**
 * Expo
 */
export const Content: React.FC<{
    contentSteps?: { id: string; name: string }[];
}> = ({ contentSteps = [] }) => (
    <Panel cx={cx(css.formPaddings)}>
        <FieldController<TCheckboxGroupProps, string[]>
            isRequired
            name="content"
            component={CheckboxGroup}
            items={contentSteps}
            label="Included content in report"
            validate={checkBoxesGroupValidator}
        />
    </Panel>
);
