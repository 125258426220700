/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
import {
    AtomicBlockUtils,
    ContentBlock,
    ContentState,
    EditorState,
    Modifier,
    RichUtils,
    SelectionState,
    convertFromHTML as convertFromHTMLDraftJs,
    genKey,
} from 'draft-js';
/**
 * Components
 */
import { Image } from './components/CustomBlocks';
/**
 * Constants
 */
import {
    ALIGNMENT_CLASSES,
    ALIGNMENT_TO_STYLE,
    BACKSPACE_COMMANDS,
    DELETE_COMMANDS,
    ENTITY_HTML_TEMPLATES,
    STYLE_TO_ALIGNMENT,
    TAG_BY_BLOCK_TYPE,
} from './constants';
import {
    BlockNodeNames,
    BlockTypes,
    BlockTypeByTagName,
    EntityTypes,
    HandleValue,
    MutabilityTypes,
    NodeNames,
    StyleAlignments,
    StyleBackgroundHighLights,
    NodeAlignments,
} from 'constants/richTextEditor';
/**
 * Types
 */
import { TDocument } from 'types';
import {
    TBlockProps,
    TBlockRender,
    TEntitiesBlockRange,
    TEntityParams,
    TEntityToHTML,
    THandlePastedTextWithImageEntities,
    THtmlToEntity,
    TImageEntityBlockParams,
    TNode,
    TReplaceImageEntityWithAtomicImageEntity,
} from './types';
/**
 * Utils
 */
import {
    getExtensionFromFileName,
    none,
    sanitizeUrl,
    templateString,
} from 'utils';
import {
    convertFromHTML,
    convertToHTML,
    RawDraftContentBlockWithCustomType,
} from 'draft-convert';
/**
 * Decorators
 */
import decorators from './decorators';
import { TContentImages } from 'components/RichEditor/types';
import { List, OrderedMap } from 'immutable';
/**
 * Expo
 */

export enum Block {
    UNSTYLED = 'unstyled',
    OL = 'ordered-list-item',
    UL = 'unordered-list-item',
    CODE = 'code-block',
}

export const isListBlock = (blockType: string): boolean =>
    blockType === Block.OL || blockType === Block.UL;

const renderImage = ({ contentState, block }: TBlockProps) => {
    const entityKey = block.getEntityAt(0);

    if (!entityKey) return null;

    const entity = contentState.getEntity(entityKey);

    return <Image entity={entity} entityKey={entityKey} block={block} />;
};

export const getBlockStyle = (block: ContentBlock): string => {
    const alignment = block.getData().get('alignment');
    let className = '';
    if (block.getType() === Block.CODE) {
        className += 'RichEditor-codeBlock';
    }

    if (alignment) {
        className += ` RichEditor-align-${
            ALIGNMENT_CLASSES[alignment as StyleAlignments]
        }`;
    }

    return className;
};

export const mediaBlockRenderer: TBlockRender = (block) => {
    if (block.getType() === BlockTypes.atomic) {
        return {
            editable: false,
            component: renderImage,
        };
    }

    return null;
};

export const hasSelectionInCodeBlock = (editorState: EditorState): boolean =>
    RichUtils.getCurrentBlockType(editorState) === Block.CODE;

const putEmptyBlock = (
    editorState: EditorState,
    neededKey: string,
    action: 'before' | 'after'
) => {
    const newBlock = new ContentBlock({
        key: genKey(),
        type: Block.UNSTYLED,
        text: '',
        characterList: List(),
    });

    const contentState = editorState.getCurrentContent();
    const oldBlockMap = contentState.getBlockMap();

    const newBlockMap = OrderedMap<string, ContentBlock>()
        .withMutations((map) => {
            oldBlockMap.forEach((value, key) => {
                if (!value || !key) return;

                const isNeededKey = neededKey === key;

                if (action === 'before' && isNeededKey) {
                    map.set(newBlock.getKey(), newBlock);
                }

                map.set(key, value);

                if (action === 'after' && isNeededKey) {
                    map.set(newBlock.getKey(), newBlock);
                }
            });
        })
        .toArray();

    const newContentState = ContentState.createFromBlockArray(newBlockMap);

    return EditorState.forceSelection(
        EditorState.push(editorState, newContentState, 'insert-fragment'),
        SelectionState.createEmpty(newBlock.getKey())
    );
};

export const handleKeyInCodeBlock = (
    event: React.KeyboardEvent,
    editorState: EditorState
): { editorState: EditorState; handleValue: HandleValue } => {
    const selectionState = editorState.getSelection();

    if (!selectionState.isCollapsed()) {
        return {
            editorState,
            handleValue: HandleValue.notHandled,
        };
    }

    const currentContent = editorState.getCurrentContent();

    const anchorKey = selectionState.getAnchorKey();
    const previousBlock = currentContent.getBlockBefore(anchorKey);
    const nextBlock = currentContent.getBlockAfter(anchorKey);

    const currentSelectionOffset = selectionState.getAnchorOffset();
    const currentBlockLength = currentContent
        .getBlockForKey(anchorKey)
        .getLength();

    const isAtStartOfBlock = currentSelectionOffset === 0;
    const isAtEndOfBlock = currentSelectionOffset === currentBlockLength;

    if (event.key === 'ArrowDown' && !nextBlock && isAtEndOfBlock) {
        const newEditorState = putEmptyBlock(editorState, anchorKey, 'after');
        return {
            editorState: newEditorState,
            handleValue: HandleValue.handled,
        };
    }

    if (event.key === 'ArrowUp' && !previousBlock && isAtStartOfBlock) {
        const newEditorState = putEmptyBlock(editorState, anchorKey, 'before');
        return {
            editorState: newEditorState,
            handleValue: HandleValue.handled,
        };
    }

    return {
        editorState,
        handleValue: HandleValue.notHandled,
    };
};

// This function moves cursor for the correct removal of pictures
const getEditorStateAndMoveCursor = (
    state: EditorState,
    command: string
): EditorState => {
    const content = state.getCurrentContent();
    const key = state.getSelection().getFocusKey();
    const type = content.getBlockForKey(key).getType();

    if (type !== BlockTypes.atomic) {
        return state;
    }

    let newEditorState = state;
    let newSelection = null;
    let keyBeforeOrAfter = null;

    if (BACKSPACE_COMMANDS.includes(command)) {
        keyBeforeOrAfter = content.getKeyAfter(key);
        newSelection = SelectionState.createEmpty(keyBeforeOrAfter);
    } else if (DELETE_COMMANDS.includes(command)) {
        keyBeforeOrAfter = content.getKeyBefore(key);
        newSelection = new SelectionState({
            anchorKey: keyBeforeOrAfter,
            focusKey: keyBeforeOrAfter,
            // We need to move cursor at the end of keyBeforeOrAfter block
            anchorOffset: content.getBlockForKey(keyBeforeOrAfter).getLength(),
            focusOffset: content.getBlockForKey(keyBeforeOrAfter).getLength(),
        });
    }

    if (keyBeforeOrAfter && newSelection) {
        newEditorState = EditorState.forceSelection(state, newSelection);
    }

    return newEditorState;
};

export const handleKeyCommandDefault = (
    command: string,
    editorState: EditorState
): { editorState: EditorState; handleValue: HandleValue } => {
    const currentEditorState = getEditorStateAndMoveCursor(
        editorState,
        command
    );

    const newEditorState = RichUtils.handleKeyCommand(
        currentEditorState,
        command
    );

    if (newEditorState) {
        return {
            editorState: newEditorState,
            handleValue: HandleValue.handled,
        };
    }

    return {
        editorState,
        handleValue: HandleValue.notHandled,
    };
};

export const handleTabKey = (
    event: React.KeyboardEvent,
    editorState: EditorState
): EditorState => {
    const blockType = RichUtils.getCurrentBlockType(editorState);
    const selection = editorState.getSelection();

    if (
        selection.isCollapsed() &&
        (blockType === Block.UNSTYLED || blockType === Block.CODE)
    ) {
        event.preventDefault();

        const contentStateWithTab = Modifier.insertText(
            editorState.getCurrentContent(),
            selection,
            '    '
        );

        return EditorState.push(
            editorState,
            contentStateWithTab,
            'insert-characters'
        );
    }

    return RichUtils.onTab(event, editorState, 4);
};

export const onMapKeyToEditorCommand = (
    event: React.KeyboardEvent,
    editorState: EditorState
): { editorState: EditorState; handleValue: HandleValue } => {
    if (
        hasSelectionInCodeBlock(editorState) &&
        ['ArrowDown', 'ArrowUp'].includes(event.key)
    ) {
        const { handleValue, editorState: newEditorState } =
            handleKeyInCodeBlock(event, editorState);

        if (handleValue === HandleValue.handled) {
            return {
                editorState: newEditorState,
                handleValue: HandleValue.handled,
            };
        }
    }

    if (event.key === 'Tab') {
        const newEditorState = handleTabKey(event, editorState);

        return {
            editorState: newEditorState,
            handleValue: HandleValue.handled,
        };
    }

    return {
        editorState,
        handleValue: HandleValue.notHandled,
    };
};

export const getCurrentEntityParams = (
    editorState: EditorState
): TEntityParams => {
    const contentState = editorState.getCurrentContent();
    const focusKey = editorState.getSelection().getFocusKey();
    const block = contentState.getBlockForKey(focusKey);
    const entityKey = block.getEntityAt(0);

    if (!entityKey) {
        return { data: {} };
    }

    const entity = contentState.getEntity(entityKey);

    return { type: entity.getType(), data: entity.getData() };
};

export const getSelectedBlocksList = (
    editorState: EditorState
): ContentBlock[] => {
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const startKey = selectionState.getStartKey();
    const endKey = selectionState.getEndKey();
    const blockMap = contentState.getBlockMap();

    return blockMap
        .toSeq()
        .skipUntil((_, key) => key === startKey)
        .takeUntil((_, key) => key === endKey)
        .concat([[endKey, blockMap.get(endKey)]])
        .toArray();
};

export const entityToHTML: TEntityToHTML = ({ type, data }, text) => {
    const template = ENTITY_HTML_TEMPLATES[type as EntityTypes];

    if (type === EntityTypes.link) {
        return templateString(template, {
            url: data.url,
            alignment: STYLE_TO_ALIGNMENT[data.alignment],
            text,
        });
    }

    if (type === EntityTypes.image) {
        return templateString(template, {
            ...data,
            alignment: STYLE_TO_ALIGNMENT[data.alignment],
        });
    }

    return text;
};

export const styleToHTML = (style: string) => {
    if (
        Object.values(StyleBackgroundHighLights).includes(
            style as StyleBackgroundHighLights
        )
    ) {
        return <span data-background={style} />;
    }

    return undefined;
};

export const htmlToEntity: THtmlToEntity = (
    nodeName,
    node,
    createEntity,
    attachedImages,
    pastedImages
) => {
    if (nodeName === NodeNames.image) {
        const src = node.getAttribute('src');
        const pastedImage = pastedImages?.find(({ name }) => name === src);
        const attachedImage = attachedImages?.find(({ name }) => name === src);

        return createEntity(EntityTypes.image, MutabilityTypes.immutable, {
            src,
            pastedImage,
            attachedImage,
            width: node.getAttribute('width'),
            height: node.getAttribute('height'),
            alignment:
                node.dataset.align &&
                ALIGNMENT_TO_STYLE[node.dataset.align as NodeAlignments],
        });
    }

    if (nodeName === NodeNames.link) {
        return createEntity(EntityTypes.link, MutabilityTypes.mutable, {
            url: sanitizeUrl(node.getAttribute('href')),
        });
    }

    return undefined;
};

export const htmlToBlock = (nodeName: string, node: TNode) => {
    if (nodeName === NodeNames.image) {
        return {
            type: BlockTypes.atomic,
            data: { alignment: node.dataset.align },
        };
    }
    if (nodeName === NodeNames.li) {
        return {
            type: '',
            data: { alignment: node.dataset.align },
        };
    }
    if (Object.values(TAG_BY_BLOCK_TYPE).includes(nodeName)) {
        return {
            type: BlockTypeByTagName[nodeName as BlockNodeNames].toString(),
            data: { alignment: node.dataset.align },
        };
    }

    return undefined;
};

export const htmlToStyle = (
    nodeName: string,
    node: TNode,
    currentStyle: Set<string>
) => {
    if (nodeName === NodeNames.li && node.dataset.align) {
        return currentStyle.add(node.dataset.align);
    }
    if (nodeName === NodeNames.span && node.dataset.background) {
        return currentStyle.add(node.dataset.background);
    }

    return currentStyle;
};

export const blockToHTML = (
    block: RawDraftContentBlockWithCustomType<BlockTypeByTagName>
) => {
    const { alignment } = block.data || {};

    if (Object.values(BlockTypeByTagName).includes(block.type)) {
        if (block.type === BlockTypeByTagName.ul) {
            return {
                start: alignment ? `<li data-align=${alignment}>` : '<li>',
                end: '</li>',
                nest: '<ul>',
                nestStart: '<ul>',
                nestEnd: '</ul>',
            };
        }
        if (block.type === BlockTypeByTagName.ol) {
            return {
                start: alignment ? `<li data-align=${alignment}>` : '<li>',
                end: '</li>',
                nest: '<ol>',
                nestStart: '<ol>',
                nestEnd: '</ol>',
            };
        }

        const Component = `${TAG_BY_BLOCK_TYPE[block.type]}`;

        return <Component data-align={alignment} />;
    }

    return null;
};

export const getHtmlContent = (editorState: EditorState): string => {
    const contentState = editorState.getCurrentContent();

    const contentStateConverter = convertToHTML({
        styleToHTML,
        entityToHTML,
        blockToHTML,
    });

    return contentStateConverter(contentState);
};

const checkImageEntities = (editorState: EditorState): boolean => {
    let isExisted = false;

    const contentState = editorState.getCurrentContent();

    contentState.getBlocksAsArray().forEach((block) => {
        block.findEntityRanges((character) => {
            const entityKey = character.getEntity();

            if (entityKey === null) return false;

            const entity = contentState.getEntity(entityKey);

            if (entity.getType() === 'IMAGE') {
                isExisted = true;

                return true;
            }

            return false;
        }, none);
    });

    return isExisted;
};

export const getEditorHtmlState = (state: EditorState): string => {
    const isImagesExisted = checkImageEntities(state);
    const currenEditorText = state.getCurrentContent().getPlainText().trim();

    return !currenEditorText && !isImagesExisted ? '' : getHtmlContent(state);
};

export const getEditorState = ({
    value,
    attachedImages,
    pastedImages,
}: {
    value?: string;
    attachedImages?: TDocument[];
    pastedImages?: File[];
}): EditorState => {
    if (!value) {
        return EditorState.createEmpty(decorators);
    }

    const htmlConverter = convertFromHTML({
        htmlToBlock,
        htmlToStyle,
        htmlToEntity: (nodeName, node, createEntity) =>
            htmlToEntity(
                nodeName,
                node,
                createEntity,
                attachedImages,
                pastedImages
            ),
    });

    const editedValue = value.replaceAll('\n', '<br>');

    const contentState = htmlConverter(editedValue);

    return EditorState.createWithContent(contentState, decorators);
};

export const getImageName = (image: File, uuid: string): string => {
    return `${uuid}.${getExtensionFromFileName(image.name).toLowerCase()}`;
};

export const checkIfImage = (type: string): boolean => {
    const [specificType] = type.split('/');

    return specificType === 'image';
};

export const insertImage = (
    editorState: EditorState,
    uuid: string,
    file: File
): EditorState => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
        EntityTypes.image,
        MutabilityTypes.immutable,
        {
            src: uuid,
            pastedImage: file,
        }
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    let newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity,
    });

    newEditorState = AtomicBlockUtils.insertAtomicBlock(
        newEditorState,
        entityKey,
        ' '
    );

    return EditorState.forceSelection(
        newEditorState,
        newEditorState.getCurrentContent().getSelectionAfter()
    );
};

const getPastedImageEntitiesBlockParams = (
    contentState: ContentState
): TEntitiesBlockRange => {
    const entitiesBlockRange: TEntitiesBlockRange = [];

    contentState.getBlocksAsArray().forEach((block) => {
        let imageEntityBlockParams: TImageEntityBlockParams;

        block.findEntityRanges(
            (character) => {
                if (character.getEntity() !== null) {
                    const entity = contentState.getEntity(
                        character.getEntity()
                    );

                    const isNeededEntity =
                        entity.getType() === 'IMAGE' &&
                        entity.getData().src.startsWith('data:image');

                    if (isNeededEntity) {
                        imageEntityBlockParams = {
                            blockKey: block.getKey(),
                            src: entity.getData().src,
                        };

                        return true;
                    }
                }

                return false;
            },
            (start, end) => {
                entitiesBlockRange.push({
                    end,
                    start,
                    ...imageEntityBlockParams,
                });
            }
        );
    });

    return entitiesBlockRange;
};

const replaceImageEntityWithAtomicImageEntity: TReplaceImageEntityWithAtomicImageEntity =
    ({ src, range, blockKey, editorState }) => {
        const selectionState = SelectionState.createEmpty(blockKey).merge({
            focusKey: blockKey,
            focusOffset: range.end,
            anchorOffset: range.start,
        });

        const contentState = editorState.getCurrentContent();

        const newContentState = contentState.createEntity(
            EntityTypes.image,
            MutabilityTypes.immutable,
            {
                src,
            }
        );

        const entityKey = contentState.getLastCreatedEntityKey();

        const newEditorState = EditorState.set(editorState, {
            currentContent: newContentState,
        });

        return AtomicBlockUtils.insertAtomicBlock(
            EditorState.forceSelection(newEditorState, selectionState),
            entityKey,
            ' '
        );
    };

export const handlePastedTextWithImageEntities: THandlePastedTextWithImageEntities =
    (html, editorState) => {
        const blocksFromHTML = convertFromHTMLDraftJs(html);
        let newState = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        );

        const pastedEntities = getPastedImageEntitiesBlockParams(newState);

        if (!pastedEntities.length) {
            return {
                editorState,
                handleValue: HandleValue.notHandled,
            };
        }

        newState = Modifier.replaceWithFragment(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            newState.getBlockMap()
        );

        let newEditorState = EditorState.push(
            editorState,
            newState,
            'insert-fragment'
        );

        const pastedEntitiesFromNewEditorState =
            getPastedImageEntitiesBlockParams(
                newEditorState.getCurrentContent()
            );

        pastedEntitiesFromNewEditorState.forEach(
            async ({ start, end, blockKey, src }) => {
                newEditorState = replaceImageEntityWithAtomicImageEntity({
                    src,
                    blockKey,
                    range: { start, end },
                    editorState: newEditorState,
                });
            }
        );

        newEditorState = EditorState.forceSelection(
            newEditorState,
            newEditorState.getCurrentContent().getSelectionAfter()
        );

        return {
            editorState: newEditorState,
            handleValue: HandleValue.handled,
        };
    };

export const getImagesFromEntities = (
    editorState: EditorState
): { pastedImages: File[]; attachedImages: TDocument[] } => {
    const pastedImages: File[] = [];
    const attachedImages: TDocument[] = [];

    const contentState = editorState.getCurrentContent();

    contentState.getBlocksAsArray().forEach((block) => {
        block.findEntityRanges((character) => {
            const entityKey = character.getEntity();

            if (entityKey === null) return false;

            const entity = contentState.getEntity(entityKey);

            if (entity.getType() === 'IMAGE') {
                const { pastedImage, attachedImage } = entity.getData();
                if (pastedImage) {
                    pastedImages.push(pastedImage);
                }
                if (attachedImage) {
                    attachedImages.push(attachedImage);
                }

                return true;
            }

            return false;
        }, none);
    });

    return { pastedImages, attachedImages };
};

export const getRemovedImages = (
    currentAttachedImages: TDocument[],
    attachedImages: TDocument[] = []
): TDocument[] => {
    return attachedImages.reduce((removedImages: TDocument[], document) => {
        const isImageExists = currentAttachedImages.find(
            (image) => image.name === document.name
        );

        if (!isImageExists) {
            removedImages.push(document);
        }

        return removedImages;
    }, []);
};

export const getContentImages = (
    editorState: EditorState,
    attachedImages?: TDocument[]
): TContentImages => {
    const { pastedImages, attachedImages: currentAttachedImages } =
        getImagesFromEntities(editorState);

    return {
        pastedImages,
        removedAttachedImages: getRemovedImages(
            currentAttachedImages,
            attachedImages
        ),
    };
};

export const handleEnterInCodeBlock = (
    { shiftKey, altKey, ctrlKey, metaKey }: React.KeyboardEvent,
    editorState: EditorState
): { editorState: EditorState; handleValue: HandleValue } => {
    if (!shiftKey && !altKey && !ctrlKey && !metaKey) {
        return {
            handleValue: HandleValue.handled,
            editorState: RichUtils.insertSoftNewline(editorState),
        };
    }

    const selectionState = editorState.getSelection();
    const currentContent = editorState.getCurrentContent();
    const anchorKey = selectionState.getAnchorKey();
    const currentSelectionOffset = selectionState.getAnchorOffset();
    const currentBlock = currentContent.getBlockForKey(anchorKey);

    const lastLineLength =
        currentBlock.getText().split('\n').pop()?.length ?? 0;

    const isOnLastLine =
        currentSelectionOffset >= currentBlock.getLength() - lastLineLength;

    if (isOnLastLine) {
        return {
            handleValue: HandleValue.handled,
            editorState: putEmptyBlock(editorState, anchorKey, 'after'),
        };
    }

    return { handleValue: HandleValue.notHandled, editorState };
};

export const handlePasteTextInCodeBlock = (
    text: string,
    editorState: EditorState
): EditorState => {
    const modifierFunction = editorState.getSelection().isCollapsed()
        ? Modifier.insertText
        : Modifier.replaceText;

    const contentState = modifierFunction(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        text
    );

    const newEditorState = EditorState.set(editorState, {
        currentContent: contentState,
    });

    const currentBlockKey = newEditorState.getSelection().getAnchorKey();
    const currentBlock = newEditorState
        .getCurrentContent()
        .getBlockForKey(currentBlockKey);

    if (!currentBlock) return newEditorState;

    const newSelectionState = new SelectionState({
        anchorKey: currentBlockKey,
        anchorOffset: currentBlock.getLength(),
        focusKey: currentBlockKey,
        focusOffset: currentBlock.getLength(),
    });

    return EditorState.forceSelection(newEditorState, newSelectionState);
};
