/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback } from 'react';
/**
 * Components
 */
import { DropdownMenuButton } from '@epam/promo';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { TChooseRoleButtonProps } from './types';
import { ReactComponent as successIcon } from '@epam/assets/icons/common/notification-done-18.svg';
/**
 * Expo
 */
export const ChooseRoleButton: React.FC<TChooseRoleButtonProps> = ({
    id,
    caption,
    isActive,
    isDisabled,
    onClose,
    onValueChange,
}) => {
    const handleChange = useCallback(() => {
        if (!isActive) onValueChange(id);

        onClose?.();
    }, [id, isActive, onValueChange, onClose]);

    return (
        <DropdownMenuButton
            caption={caption}
            iconPosition="right"
            cx={isActive && css.activeItem}
            onClick={handleChange}
            isDisabled={isDisabled}
            icon={isActive ? successIcon : undefined}
        />
    );
};
