/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useOnMount, useRedirect } from 'hooks';
import { HtmlHead } from 'components/HtmlHead';
import { Navigation } from 'navigation';
import { getDeepAddress } from 'utils';

export const App: React.FC = memo(() => {
    const redirect = useRedirect();

    const { search } = useLocation();

    useOnMount(() => {
        const deepAddress = getDeepAddress(search);

        if (deepAddress) {
            redirect(deepAddress);
        }
    });

    return (
        <>
            <HtmlHead />
            <Navigation />
        </>
    );
});
