/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { TAttachment } from 'types';
import { TFormValues, TReportPayload } from './types';
/**
 * Expo
 */
export const createRequestBody = (
    projectId: string,
    {
        title = '',
        creationType,
        assessmentId = '',
        state,
    }: Omit<TFormValues, 'files'>
): TReportPayload => ({
    project_id: projectId,
    type: creationType,
    report_name: title,
    assessment_id: assessmentId,
    state,
});

export const createRequestBodyWithLink = (
    projectId: string,
    { url = '', ...formValues }: TFormValues
): TReportPayload => {
    const requestBody = createRequestBody(projectId, formValues);

    return { ...requestBody, url };
};

export const createRequestBodyWithFiles = (
    projectId: string,
    { files, ...formValues }: TFormValues
): FormData => {
    const formData = new FormData();

    const reportPayload: TReportPayload = createRequestBody(
        projectId,
        formValues
    );

    files?.forEach(({ file }: TAttachment) => {
        formData.append('files', file, file.name);
    });

    formData.append('reportPayload', JSON.stringify(reportPayload));

    return formData;
};

export const createRequestBodyWithDocxFiles = (
    projectId: string,
    { files: [{ file }], ...formValues }: TFormValues
): FormData => {
    const formData = new FormData();

    const reportPayload: TReportPayload = createRequestBody(
        projectId,
        formValues
    );

    formData.append('file', file, file.name);

    formData.append('reportPayload', JSON.stringify(reportPayload));

    return formData;
};
