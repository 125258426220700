/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { FC, ReactNode } from 'react';
import { Text } from 'components/common';
import css from './style.module.scss';
import { WithDataTestId } from 'types';

export interface ViewAndEditItemHeaderProps extends WithDataTestId {
    readableId: string;
    name: string;
    additionalText?: ReactNode;
}

// TODO: after full migration to createEditableItemCard, rename and refactor to use with create as well
export const ViewAndEditItemHeader: FC<ViewAndEditItemHeaderProps> = ({
    readableId,
    name,
    additionalText,
    dataTestId,
}) => (
    <section className={css.treeSubitemHeader}>
        <div className={css.treeSubitemHeaderName}>
            <Text
                dataTestId={`${dataTestId}/id`}
                cx={css.treeSubitemHeaderNameText}
                fontWeight="400"
            >
                {readableId}
            </Text>
            <Text
                dataTestId={`${dataTestId}/name`}
                cx={css.treeSubitemHeaderNameText}
            >
                {name}
            </Text>
        </div>
        {additionalText && (
            <Text
                lineHeight="12"
                fontSize="12"
                cx={css.treeSubitemHeaderAdditionalText}
            >
                {additionalText}
            </Text>
        )}
    </section>
);
