/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { TIntegrationWithType } from 'types';
import { TIntegrationsByType } from './types';
/**
 * Constants
 */
import { INTEGRATIONS_BY_TYPE } from './constants';
/**
 * Expo
 */
export const getIntegrationsByType = (
    integrations: TIntegrationWithType[]
): TIntegrationsByType => {
    if (!integrations) return {};

    return integrations.reduce(
        (accumulator: TIntegrationsByType, { id, integration_type }) => {
            const fieldByType = INTEGRATIONS_BY_TYPE[integration_type];

            accumulator[fieldByType] = { id };

            return accumulator;
        },
        {}
    );
};
