/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useContext } from 'react';
import { EditorState, RichUtils } from 'draft-js';
/**
 * Components
 */
import { LinkModal } from '../../LinkModal';
import { ToolbarButton } from '../../ToolbarButton';
/**
 * Assets
 */
import { ReactComponent as myIcon } from '@epam/assets/icons/common/content-link-18.svg';
/**
 * Context
 */
import { EditorContext } from '../../../context';
/**
 * Utils
 */
import { showModal } from 'utils';
/**
 * Constants
 */
import { EntityTypes, MutabilityTypes } from 'constants/richTextEditor';
/**
 * Expo
 */
export const LinkButton: React.FC = () => {
    const { editorState, onChange } = useContext(EditorContext);

    const confirmLink = useCallback(
        (url: string) => {
            const contentState = editorState.getCurrentContent();
            const contentStateWithEntity = contentState.createEntity(
                EntityTypes.link,
                MutabilityTypes.mutable,
                { url }
            );

            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const newEditorState = EditorState.set(editorState, {
                currentContent: contentStateWithEntity,
            });

            onChange(
                RichUtils.toggleLink(
                    newEditorState,
                    newEditorState.getSelection(),
                    entityKey
                )
            );
        },
        [editorState, onChange]
    );

    const showLinkModal = useCallback(() => {
        const selection = editorState.getSelection();

        if (selection.isCollapsed()) return;

        const contentState = editorState.getCurrentContent();
        const startKey = editorState.getSelection().getStartKey();
        const startOffset = editorState.getSelection().getStartOffset();
        const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
        const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

        let url: string | undefined;

        if (linkKey) {
            const linkInstance = contentState.getEntity(linkKey);

            url = linkInstance.getData().url;
        }

        showModal(LinkModal, {
            initialValue: url,
            onSubmit: confirmLink,
        });
    }, [confirmLink, editorState]);

    return <ToolbarButton icon={myIcon} onClick={showLinkModal} />;
};
