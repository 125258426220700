/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, useCallback, useContext } from 'react';
import { RichUtils } from 'draft-js';
/**
 * Components
 */
import { FlexRow } from '@epam/promo';
import { ToolbarButton } from '../../ToolbarButton';
/**
 * Context
 */
import { EditorContext } from '../../../context';
/**
 * Types
 */
import { TControl } from '../types';
/**
 * Expo
 */
export const BlockStyleControls: FC<TControl> = ({ buttonList }) => {
    const { editorState, onChange } = useContext(EditorContext);

    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    const handleClick = useCallback(
        (type = '') => {
            onChange(RichUtils.toggleBlockType(editorState, type));
        },
        [editorState, onChange]
    );

    const checkIsActive = useCallback(
        (style: string, includedStyle?: string[]) => {
            if (includedStyle) {
                return includedStyle.includes(blockType);
            }

            return style === blockType;
        },
        [blockType]
    );

    return (
        <FlexRow cx="RichEditor-controls">
            {buttonList.map(({ style = '', includedStyle, items, icon }) => (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <ToolbarButton
                    key={style}
                    icon={icon}
                    style={style}
                    items={items}
                    blockType={blockType}
                    onClick={handleClick}
                    isActive={checkIsActive(style, includedStyle)}
                />
            ))}
        </FlexRow>
    );
};
