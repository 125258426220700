/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { IntegrationTypes } from 'constants/project';
/**
 * Types
 */
import { TFullOrganization, TPerson } from 'types';
import { IInitialState } from './types';

/**
 * Expo
 */
export const INITIAL_STATE: IInitialState = {
    data: {
        id: '',
        name: '',
        description: '',
        organization: {} as TFullOrganization,
        securitySpecialists: [],
        projectRepresentatives: [],
        operationsManager: {} as TPerson,
        threatModelingActivityId: null,
    },
    attachments: [],
    assessmentHistory: [],
    policies: [],
    inProgress: false,
    isInitialized: false,
};

// project integration key ID
enum IntegrationKeyId {
    jira = 'jira',
    azure = 'azure',
    sonarQube = 'sonarQube',
    slack = 'slack',
    teams = 'teams',
}

export const INTEGRATIONS_BY_TYPE: Record<IntegrationTypes, IntegrationKeyId> =
    {
        [IntegrationTypes.jira]: IntegrationKeyId.jira,
        [IntegrationTypes.sonarQube]: IntegrationKeyId.sonarQube,
        [IntegrationTypes.slack]: IntegrationKeyId.slack,
        [IntegrationTypes.teams]: IntegrationKeyId.teams,
        [IntegrationTypes.azure]: IntegrationKeyId.azure,
    };
