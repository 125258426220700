/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
/**
 * Components
 */
import { ContextProvider } from '@epam/uui-core';
import { ErrorBoundary } from './ErrorBoundary';
import { BrowserRouter } from 'react-router-dom';
import { Snackbar, Modals } from '@epam/uui-components';
/**
 * Store
 */
import store from 'store';
/**
 * Services
 */
import { svc } from 'services';
/**
 * Types
 */
import { WithChildren } from 'types';
import { GlobalLoader } from './GlobalLoader';
/**
 * Expo
 */
const history = createBrowserHistory();

export const UuiEnhancedApp: React.FC<WithChildren> = ({ children }) => (
    <BrowserRouter>
        <Provider store={store}>
            <ContextProvider
                history={history}
                onInitCompleted={(context) => {
                    Object.assign(svc, context);
                }}
            >
                <GlobalLoader>
                    <ErrorBoundary>
                        {children}
                        <Snackbar />
                        <Modals />
                    </ErrorBoundary>
                </GlobalLoader>
            </ContextProvider>
        </Provider>
    </BrowserRouter>
);
