/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useMemo } from 'react';
/**
 * Components
 */
import { LabeledInput, DatePicker as UuiDatePicker } from '@epam/promo';
/**
 * Types
 */
import { TDatePickerProps } from './types';
/**
 * Utils
 */
import { getDateFilter, getRawProps } from 'utils';
/**
 * Expo
 */
export const DatePicker: React.FC<TDatePickerProps> = ({
    label,
    value,
    isInvalid,
    isRequired,
    isDisabled,
    isReadonly,
    iconPosition,
    validationMessage,
    format = 'DD/MM/YYYY',
    getLimit,
    onValueChange,
    disableClear,
    dataTestId,
}) => {
    const { minDate, maxDate } = useMemo(
        () => (getLimit ? getLimit(value) : {}),
        [value, getLimit]
    );

    const rawProps = useMemo(() => {
        if (!dataTestId) return undefined;

        return {
            input: getRawProps({ dataTestId: `${dataTestId}/input` }),
            body: getRawProps({ dataTestId: `${dataTestId}/body` }),
        };
    }, [dataTestId]);

    const dateFilter = useMemo(
        () =>
            minDate || maxDate ? getDateFilter(minDate, maxDate) : undefined,
        [minDate, maxDate]
    );

    return (
        <LabeledInput
            label={label}
            isInvalid={isInvalid}
            isRequired={isRequired}
            validationMessage={validationMessage}
        >
            <UuiDatePicker
                value={value}
                format={format}
                isInvalid={isInvalid}
                isDisabled={isDisabled}
                isReadonly={isReadonly}
                iconPosition={iconPosition}
                filter={dateFilter}
                onValueChange={onValueChange}
                disableClear={disableClear}
                rawProps={rawProps}
            />
        </LabeledInput>
    );
};
