/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Hooks
 */
import { Link, useLocation } from 'react-router-dom';
/**
 * Components
 */
/**
 * Expo
 */
export const HashLink = ({
    to,
    caption,
    className,
    dataTestId,
}: {
    to: string;
    dataTestId: string;
    caption: string;
    className?: string;
}) => {
    const { pathname } = useLocation();

    return pathname === '/' ? (
        <a id={dataTestId} href={to} className={className}>
            {caption}
        </a>
    ) : (
        <Link id={dataTestId} to={to} className={className}>
            {caption}
        </Link>
    );
};
