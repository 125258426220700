/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useMemo, useState } from 'react';
/**
 * Components
 */
import { Tag } from './Tag';
import { IconContainer, LabeledInput } from '@epam/promo';
/**
 * Assets
 */
import css from './style.module.scss';
import { ReactComponent as crossIcon } from '@epam/assets/icons/common/navigation-close-18.svg';
/**
 * Types
 */
import { TTagInputProps } from './types';
import { TKeyboardElement, TTargetElement } from 'types';
/**
 * Utils
 */
import { getRawProps } from 'utils';
/**
 * Constants
 */
import { DEFAULT_TAGS } from './constants';
/**
 * Expo
 */
export const TagInput: React.FC<TTagInputProps> = ({
    label,
    value = [],
    isRequired,
    dataTestId,
    onValueChange,
}) => {
    const [inputValue, changeInputValue] = useState('');

    const handleChangeValue = useCallback(
        (newLabel: string) => {
            const labelList = [...value, newLabel];

            if (onValueChange) {
                onValueChange(labelList);
            }
        },
        [value, onValueChange]
    );

    const handleKeyDown = useCallback(
        ({ key, target: { value: newLabel } }: TKeyboardElement) => {
            if (key === 'Enter' && newLabel) {
                handleChangeValue(newLabel);

                changeInputValue('');
            }
        },
        [handleChangeValue]
    );

    const handleChange = useCallback(
        ({ target: { value: newLabel } }: TTargetElement) => {
            changeInputValue(newLabel);
        },
        []
    );

    const handleBlur = useCallback(
        ({ target: { value: newLabel } }: TTargetElement) => {
            if (newLabel) handleChangeValue(newLabel);
            changeInputValue('');
        },
        [handleChangeValue]
    );

    const handleClear = useCallback(
        (index: number) => {
            const labelList = [...value];

            labelList.splice(index, 1);

            if (onValueChange) {
                onValueChange(labelList);
            }
        },
        [onValueChange, value]
    );

    const handleReset = useCallback(() => {
        if (onValueChange) {
            onValueChange(DEFAULT_TAGS);
        }
    }, [onValueChange]);

    const rawProps = useMemo(() => getRawProps({ dataTestId }), [dataTestId]);

    return (
        <LabeledInput label={label} isRequired={isRequired}>
            <div className={css.inputContainer} tabIndex={-1} {...rawProps}>
                <div className={css.tagsContainer}>
                    {value.map((item, index) => (
                        <Tag
                            index={index}
                            caption={item}
                            onClear={handleClear}
                            dataTestId={dataTestId}
                        />
                    ))}
                    <input
                        type="text"
                        value={inputValue}
                        className={css.input}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        onBlur={handleBlur}
                        maxLength={255}
                    />
                </div>
                {value.length > 1 && (
                    <IconContainer
                        cx={css.icon}
                        icon={crossIcon}
                        onClick={handleReset}
                    />
                )}
            </div>
        </LabeledInput>
    );
};
