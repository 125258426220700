/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { TechnologyFormValues } from 'pages/ThreatModeling/tabs/ModelTreeTab/components/ExpandableTree/components/ItemEditor/forms/Technology/schema';
import {
    mapFormValuesToTechnologyPayload,
    mapFormValuesToTechnologyUpdatePayload,
} from 'pages/ThreatModeling/tabs/ModelTreeTab/components/ExpandableTree/components/ItemEditor/forms/Technology/utils';
import { AppDispatch, TTechnology } from 'types';
import { createQueryUrl } from 'utils';
import { ENDPOINTS } from 'constants/api';
import { api } from 'services/api';
import { Methods } from 'constants/request';
import {
    addTechnology,
    saveTechnologies,
    setTechnologiesLoadingInProgress,
    updateTechnology,
} from 'store/threatModeling/technology/slice';
import {
    TechnologyCreatePayload,
    TechnologyUpdatePayload,
} from 'store/threatModeling/technology/types';

export const handleGetTechnologies =
    (tmId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            dispatch(setTechnologiesLoadingInProgress(true));
            const url = createQueryUrl(ENDPOINTS.technologies, {
                tmId,
            });
            const techs = await api.request<TTechnology[]>(Methods.get, {
                url,
            });
            dispatch(saveTechnologies(techs));
        } finally {
            dispatch(setTechnologiesLoadingInProgress(false));
        }
    };

export const handleCreateTechnology =
    (form: TechnologyFormValues) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = ENDPOINTS.technologies;
        const requestBody = mapFormValuesToTechnologyPayload(form);
        const tech = await api.request<TTechnology, TechnologyCreatePayload>(
            Methods.post,
            { url, data: requestBody }
        );
        dispatch(addTechnology(tech));
    };

export const handleUpdateTechnology =
    (id: string, form: TechnologyFormValues) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = `${ENDPOINTS.technologies}/${id}`;
        const requestBody = mapFormValuesToTechnologyUpdatePayload(form);
        const tech = await api.request<TTechnology, TechnologyUpdatePayload>(
            Methods.put,
            { url, data: requestBody }
        );
        dispatch(updateTechnology(tech));
    };
