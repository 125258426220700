/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import React from 'react';
import { Modal as ModalComponent } from 'components/common';
import { TDeleteDfdConfirmationModalProps } from './types';
import { ModalSizes } from 'constants/modals';
import { WithDataTestId } from 'types';

export const DeleteDfdConfirmation: React.FC<
    TDeleteDfdConfirmationModalProps & WithDataTestId
> = ({ name, dataTestId, ...props }) => (
    <ModalComponent
        {...props}
        width={ModalSizes.large}
        title={`Delete "${name}"?`}
        allowCloseOnCross={false}
        successButtonCaption="Delete"
        bodyText="Are you sure that you want to delete this diagram?"
        dataTestId={dataTestId}
    />
);
