/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { memo, useRef } from 'react';
/**
 * Components
 */
import { IconContainer, Tooltip } from '@epam/promo';
/**
 * Hooks
 */
import { useHover } from 'hooks';
/**
 * Constants
 */
import { ReportState } from 'constants/reports';
import { HOVER_DRAFT_ICON_SRC, REPORT_ICON_SRC } from './constants';
/**
 * Types
 */
import { TStateIconProps } from './types';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export const StateIcon: React.FC<TStateIconProps> = memo(
    ({ state, onClick }) => {
        const ref = useRef(null);
        const isHovered = useHover(ref);

        const isPublished = state === ReportState.published;
        const draftIconSrc = isHovered
            ? HOVER_DRAFT_ICON_SRC
            : REPORT_ICON_SRC[state];
        const publishedIconSrc = REPORT_ICON_SRC[state];

        const renderIcon = () => (
            <img
                className={css.reportIcon}
                alt={isPublished ? 'published' : 'draft'}
                src={isPublished ? publishedIconSrc : draftIconSrc}
            />
        );

        return (
            <Tooltip content="Publish report">
                {/* Additional div is needed to make Tooltip/Hover work(Tooltip is using ref) */}
                <div id="reports/list/item/publishButton">
                    <IconContainer
                        ref={ref}
                        icon={renderIcon}
                        onClick={isPublished ? undefined : onClick}
                    />
                </div>
            </Tooltip>
        );
    }
);
