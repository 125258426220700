/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, memo } from 'react';
/**
 * Types
 */
import { THandlers } from '../types';
import { TProjectAssessmentActionLog } from 'types';
/**
 * Utils
 */
import {
    DefaultRecentAction,
    useHref,
} from 'components/RecentActions/RecentAction/DefaultRecentAction';
/**
 * Expo
 */
export const AssessmentUpdated: FC<TProjectAssessmentActionLog & THandlers> =
    memo(
        ({
            getAssessmentHref,
            author: { name },
            details: {
                current_state: { name: assessmentName, id },
            },
        }) => {
            const href = useHref({ getHref: () => getAssessmentHref(id) });
            return (
                <DefaultRecentAction
                    dataTestId="assessmentUpdated"
                    user={name}
                    action="updated assessment"
                    target={assessmentName}
                    href={href}
                />
            );
        }
    );
