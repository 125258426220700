/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { createSlice } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { INITIAL_STATE } from './constants';
import { Methods } from 'constants/request';
import { api } from 'services/api';
import { AppDispatch, TSelector } from 'types';
import { createQueryUrl } from 'utils';
import {
    NotificationSettingGroups,
    TNotificationSetting,
    TNotificationSettingPayload,
    TProjectWithOrganization,
} from 'store/notificationSettings/types';

/**
 * Reducer
 */
const { reducer, actions } = createSlice({
    name: 'notificationSettings',
    initialState: INITIAL_STATE,
    reducers: {
        setInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        saveFilterOptions: (state, { payload }) => {
            state.data.filter.options = payload;
            if (payload.length === 0) {
                state.isInitialized = true;
            }
        },
        saveFilterValue: (state, { payload }) => {
            state.data.filter.value = payload;
        },
        saveState: (state, { payload }) => {
            state.data.state = payload;
            state.isInitialized = true;
        },
        saveSingleSetting: (state, { payload }) => {
            const setting = Object.values(state.data.state)
                .flat()
                .find((item) => item.type === payload.type);
            if (setting) {
                setting.enabled = payload.enabled;
                setting.readonly = payload.readonly;
            }
        },
        setReadonly: (state, { payload }) => {
            const setting = Object.values(state.data.state)
                .flat()
                .find((item) => item.type === payload.type);
            if (setting) {
                setting.readonly = payload.readonly;
            }
        },
        reset: () => INITIAL_STATE,
    },
});

export default reducer;
/**
 * Selectors
 */
export const selectFilterOptions: TSelector<TProjectWithOrganization[]> = ({
    notificationSettings: {
        data: {
            filter: { options },
        },
    },
}) => [...options];
export const selectFilterValue: TSelector<TProjectWithOrganization | null> = ({
    notificationSettings: {
        data: {
            filter: { value },
        },
    },
}) => value || null;
export const selectSettingsByGroup =
    (group: NotificationSettingGroups): TSelector<TNotificationSetting[]> =>
    ({
        notificationSettings: {
            data: { state },
        },
    }) =>
        state[group];
export const selectInProgress: TSelector<boolean> = ({
    notificationSettings: { inProgress },
}) => inProgress;
export const selectIsInitialized: TSelector<boolean> = ({
    notificationSettings: { isInitialized },
}) => isInitialized;
/**
 * Actions
 */
export const {
    saveFilterOptions,
    saveFilterValue,
    saveState,
    reset,
    setInProgress,
    saveSingleSetting,
} = actions;
/**
 * Dispatchers
 */
export const handleGetFilterOptions = async (
    dispatch: AppDispatch
): Promise<void> => {
    dispatch(setInProgress(true));
    try {
        const options: TProjectWithOrganization[] = await api.request(
            Methods.get,
            {
                url: ENDPOINTS.assignedOrCreatedProjects,
            }
        );
        dispatch(saveFilterOptions(options));
    } finally {
        dispatch(setInProgress(false));
    }
};

export const handleGetNotificationSettings =
    (projectId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setInProgress(true));
        try {
            const url = createQueryUrl(ENDPOINTS.projectNotificationSettings, {
                projectId,
            });
            const settings = await api.request(Methods.get, { url });
            dispatch(saveState(settings));
        } finally {
            dispatch(setInProgress(false));
        }
    };

export const handleUpdateSetting =
    (payload: TNotificationSettingPayload) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const { enabled, type } = payload;
        dispatch(saveSingleSetting({ type, enabled, readonly: true }));
        try {
            const response: TNotificationSetting = await api.request(
                Methods.post,
                {
                    url: ENDPOINTS.projectNotificationSettings,
                    data: payload,
                }
            );
            dispatch(saveSingleSetting(response));
        } catch (error) {
            dispatch(
                saveSingleSetting({ type, enabled: !enabled, readonly: false })
            );
        }
    };

export const handleUpdateSettings =
    (
        project: TProjectWithOrganization | null,
        settings: TNotificationSetting[],
        value: boolean
    ) =>
    async (dispatch: AppDispatch): Promise<void> => {
        if (project == null) {
            return;
        }
        settings
            .filter(({ enabled, readonly }) => enabled !== value && !readonly)
            .forEach(({ type }) =>
                dispatch(
                    handleUpdateSetting({
                        enabled: value,
                        type,
                        project_id: project.id,
                    })
                )
            );
    };
