/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { MouseEvent, useCallback } from 'react';
import { cx, Icon } from '@epam/uui-core';
/**
 * Components
 */
import { Dropdown, FlexRow, IconContainer, Panel } from '@epam/promo';

/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Expo
 */
export function ToolbarButton<T extends string>(props: {
    icon: Icon;
    style: T;
    isActive?: boolean;
    blockType?: string;
    onClick: (style: T) => void;
}): JSX.Element;
export function ToolbarButton<T extends string>(props: {
    icon: Icon;
    isActive?: boolean;
    items?: { icon: Icon; style: T }[];
    blockType?: string;
    onClick: () => void;
}): JSX.Element;
export function ToolbarButton<T extends string>(props: {
    icon: Icon;
    isActive?: boolean;
    items: { icon: Icon; style: T }[];
    blockType?: string;
    onClick: (style: T) => void;
}): JSX.Element;
export function ToolbarButton<T extends string>({
    icon,
    style,
    items,
    isActive,
    blockType,
    onClick,
}: {
    icon: Icon;
    style?: T;
    isActive?: boolean;
    items?: { icon: Icon; style: T }[];
    blockType?: string;
    onClick: (style?: T) => void;
}) {
    const className = cx(css.toolbarButton, css[isActive ? 'gray90' : 'gray5']);

    const handleClick = useCallback(
        (event: MouseEvent) => {
            event.preventDefault();
            onClick(style);
        },
        [onClick, style]
    );

    if (items) {
        return (
            <Dropdown
                renderTarget={({
                    isOpen,
                    isDropdown,
                    toggleDropdownOpening,
                    isInteractedOutside,
                    ...props
                }) => (
                    <button {...props} type="button" className={className}>
                        <IconContainer icon={icon} />
                    </button>
                )}
                renderBody={() => (
                    <Panel shadow>
                        <FlexRow cx={css.toolbarDropdownBody}>
                            {items.map(({ icon: secIcon, style: secStyle }) => (
                                <button
                                    onMouseDown={() => onClick(secStyle)}
                                    type="button"
                                    className={cx(
                                        css.toolbarButton,
                                        css[
                                            blockType === secStyle
                                                ? 'gray90'
                                                : 'gray5'
                                        ]
                                    )}
                                    key={secStyle}
                                >
                                    <IconContainer icon={secIcon} />
                                </button>
                            ))}
                        </FlexRow>
                    </Panel>
                )}
            />
        );
    }

    return (
        <button onMouseDown={handleClick} type="button" className={className}>
            <IconContainer icon={icon} />
        </button>
    );
}
