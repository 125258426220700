/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { useCallback } from 'react';
import { useNavigate, Params } from 'react-router-dom';
import { useRouteParams } from './useRouteParams';
/**
 * Utils
 */
import { generatePath } from 'utils';
/**
 * Types
 */
type TParams = { state?: Record<string, unknown> } | Params<string>;
type TRedirect = (path: string, params?: TParams) => void;
/**
 * Expo
 */
export const useRedirect = (): TRedirect => {
    const navigate = useNavigate();
    const existingParams = useRouteParams();

    return useCallback(
        (path: string, { state, ...params }: TParams = {}) => {
            const pathToRedirect = generatePath(path, {
                ...existingParams,
                ...params,
            });

            navigate(pathToRedirect, { state });
        },
        [navigate, existingParams]
    );
};
