/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { all, call, spawn } from 'redux-saga/effects';
/**
 * Sagas
 */
import policiesSaga from './policies/sagas';
import actionLogSaga from './actionLog/sagas';
import notificationSaga from './notifications/sagas';
/**
 * Types
 */
import { TFunction } from '../types';
/**
 * Expo
 */
function wrapSaga(saga: TFunction<Generator>) {
    return function* wrapper() {
        while (true) {
            try {
                yield call(saga);
                break;

                // eslint-disable-next-line no-empty
            } catch {}
        }
    };
}

export default function* rootSaga(): Generator {
    const sagas = [policiesSaga, actionLogSaga, notificationSaga];

    yield all(sagas.map(wrapSaga).map(spawn));
}
