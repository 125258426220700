/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { createSlice } from '@reduxjs/toolkit';
import { DFD_METADATA_INITIAL_STATE } from './constants';

export const {
    reducer: dfdMetadataReducer,
    actions: {
        saveDfdMetadata,
        setDfdMetadataLoadingInProgress,
        setDfdMetadataInitialized,
        resetDfdMetadata,
    },
} = createSlice({
    name: 'dfdMetadata',
    initialState: DFD_METADATA_INITIAL_STATE,
    reducers: {
        saveDfdMetadata: (state, { payload }) => {
            state.document = payload;
            state.isInitialized = true;
        },
        setDfdMetadataLoadingInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        setDfdMetadataInitialized: (state, { payload }) => {
            state.isInitialized = payload;
        },
        resetDfdMetadata: () => DFD_METADATA_INITIAL_STATE,
    },
});
