/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { useMemo } from 'react';
import { FlexRow, PanelBlock, PanelTitle, Text } from 'components/common';
import { TDocument, WithDataTestId } from 'types';
import css from './style.module.scss';
import { useSelector } from 'react-redux';
import { selectThreatModelingDfdInProgress } from 'store/threatModeling';
import { Spinner } from '@epam/promo';
import { DfdAttachment } from '../DfdAttachment';

export const DfdCard: React.FC<{ document?: TDocument } & WithDataTestId> = ({
    document,
    dataTestId,
}) => {
    const dfdInProgress = useSelector(selectThreatModelingDfdInProgress);
    const empty = useMemo(
        () => !dfdInProgress && !document,
        [document, dfdInProgress]
    );
    return (
        <PanelBlock>
            <PanelTitle
                title="Data flow diagrams"
                dataTestId={`${dataTestId}/title`}
            />
            {dfdInProgress && <Spinner />}
            {empty && (
                <>
                    <FlexRow cx={css.diagramText}>
                        <Text
                            cx={css.diagramText}
                            color="gray60"
                            fontSize="14"
                            dataTestId={`${dataTestId}/diagramText`}
                        >
                            Nothing here yet. Please upload data flow diagram to
                            create model tree.
                        </Text>
                    </FlexRow>
                    <FlexRow cx={css.imageContainer}>
                        <img
                            alt="no diagram"
                            src="/assets/images/emptyViews/box_image.svg"
                        />
                    </FlexRow>
                </>
            )}
            {!empty && document && (
                <>
                    <DfdAttachment
                        document={document}
                        dataTestId={`${dataTestId}/attachment`}
                    />
                </>
            )}
        </PanelBlock>
    );
};
