/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Utils
 */
import { downloadFile, templateString } from 'utils';
/**
 * Constants
 */
import { Methods } from 'constants/request';

/**
 * Services
 */
import { api } from 'services/api';
/**
 * Types
 */
import { TDownloadOptions } from 'components/SingleAttachedFile/types';
import { TPresignedContent } from 'types';
import { request } from 'services/api/request';
/**
 * Expo
 */

export const handleGetFileUrl = async (
    attachmentId: string,
    contentUrl: string
) => {
    const url = templateString(contentUrl, {
        attachmentId,
    });

    const { presigned_url: presignedUrl } =
        await api.request<TPresignedContent>(Methods.get, {
            url,
        });
    return presignedUrl;
};

export const handleGetDocument = async (
    attachmentId: string,
    contentUrl: string
): Promise<Blob> => {
    const presignedUrl = await handleGetFileUrl(attachmentId, contentUrl);

    const { data } = await request<Blob>(Methods.get, {
        url: presignedUrl,
        responseType: 'blob',
        headers: { 'Content-type': 'application/doc' },
    });

    return data;
};

export const handleDownloadFile = async ({
    id,
    name: documentName,
    contentUrl,
}: TDownloadOptions): Promise<void> => {
    const blobFile: Blob = await handleGetDocument(id, contentUrl);

    downloadFile(blobFile, documentName);
};
