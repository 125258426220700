/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { createSlice } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { INITIAL_STATE } from './constants';
import { Methods } from 'constants/request';
import { api } from 'services/api';
import { AppDispatch, TPerson, TRole, TSelector } from 'types';
import { pick, showNotification, templateString } from 'utils';

/**
 * Reducer
 */
const { reducer, actions } = createSlice({
    name: 'person',
    initialState: INITIAL_STATE,
    reducers: {
        setInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        save: (state, { payload }) => {
            state.data = payload;
            state.isInitialized = true;
        },
        reset: () => INITIAL_STATE,
    },
});

export default reducer;
/**
 * Selectors
 */
export const selectPerson: TSelector<TPerson> = ({ person: { data } }) => data;
export const selectInProgress: TSelector<boolean> = ({
    person: { inProgress },
}) => inProgress;
export const selectIsInitialized: TSelector<boolean> = ({
    person: { isInitialized },
}) => isInitialized;
/**
 * Actions
 */
export const { save, reset, setInProgress } = actions;
/**
 * Dispatchers
 */
export const handleGetPerson =
    (personId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setInProgress(true));
        try {
            const url = templateString(ENDPOINTS.person, { personId });
            const response = await api.request(Methods.get, { url });
            dispatch(save(response));
        } finally {
            dispatch(setInProgress(false));
        }
    };

export const handleBlock =
    (personId: string, blocked: boolean) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = templateString(ENDPOINTS.blockPerson, { personId });

        const response = await api.request(Methods.post, {
            url,
            data: { is_blocked: blocked },
        });
        dispatch(save(response));
    };

export const handleResendInvitation =
    (person: TPerson) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = templateString(ENDPOINTS.resendInvitation, {
            personId: person.id,
        });

        const response = await api.request<TPerson>(Methods.post, { url });
        dispatch(save(response));
        showNotification({
            text: `Invitation email to user ${person.full_name} was successfully re-sent`,
        });
    };

export const handleUpdatePerson =
    (personId: string, updatedRoles: TRole[]) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const roleIds = pick(updatedRoles, 'id');
        const url = templateString(ENDPOINTS.person, {
            personId,
        });

        const response = await api.request(Methods.put, {
            url,
            data: {
                role_ids: roleIds,
            },
        });
        dispatch(save(response));
    };
