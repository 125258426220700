/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import {
    FONT_SIZE_LARGE,
    FONT_SIZE_MEDIUM,
    BACKGROUND_COLORS,
} from './constants';
/**
 * Types
 */
import { TAvatarSize, TTextSize } from '../User/types';
/**
 * Expo
 */
/**
 * Get first letters from full name. If full name contains not 2 words (it could be 1 or, the
 * opposite, more than 2 words) that avatar should contain the first capital letter only.
 * @param name
 * @return firstLetters
 */
export const getFirstLetters = (name: string): string => {
    if (!name) return '';

    const letters = name
        .trim()
        .split(' ')
        .map((word) => word[0]);

    return letters
        .slice(0, letters.length === 2 ? 2 : 1)
        .join('')
        .toUpperCase();
};

/**
 * To calculate the color, we need to take the first letter of the name and surname from the full name.
 * Then sum up their serial numbers, divide by count of colors in the palette and take the color by the remainder.
 * @param name
 * @return backgroundColor
 */
export const getAvatarBackgroundColor = (name: string): string => {
    const firstLetters = getFirstLetters(name);
    const startingLetter = 'a'.charCodeAt(0);
    const lettersSum = firstLetters
        .toLowerCase()
        .split('')
        .map((letter) => letter.charCodeAt(0) - startingLetter)
        .reduce((sum, curr) => sum + curr, 0);
    const idx = lettersSum % BACKGROUND_COLORS.length;
    return BACKGROUND_COLORS[idx];
};

export const getFontSize = (avatarSize: TAvatarSize): TTextSize => {
    if (Number(avatarSize) < 36) return FONT_SIZE_MEDIUM;

    return FONT_SIZE_LARGE;
};
