/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createSlice, createSelector } from '@reduxjs/toolkit';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
import { INITIAL_STATE } from './constants';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Types
 */
import { TData } from './types';
import { TGetState, AppDispatch, TAssessment, TSelector } from 'types';
/**
 * Utils
 */
import { createQueryUrl, templateString } from 'utils';
/**
 * Reducer
 */
const { reducer, actions } = createSlice({
    name: 'assessments',
    initialState: INITIAL_STATE,
    reducers: {
        saveList: (state, { payload }) => {
            state.data.list = payload;

            state.isInitialized = true;
        },
        saveAddAssessmentAllowed: (state, { payload: { approved_target } }) => {
            state.data.hasApprovedTarget = approved_target;
        },
        setIsEmpty: (state, { payload }) => {
            state.isEmpty = payload;
        },
        setInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        resetData: () => INITIAL_STATE,
    },
});

export default reducer;
/**
 * Selectors
 */
export const selectData: TSelector<TData> = ({ assessments: { data } }) => data;

export const selectList = createSelector(selectData, ({ list }) => list);
export const selectAddAssessmentAllowed = createSelector(
    selectData,
    ({ hasApprovedTarget }) => ({ hasApprovedTarget })
);
export const selectInProgress: TSelector<boolean> = ({
    assessments: { inProgress },
}) => inProgress;

export const selectIsInitialized: TSelector<boolean> = ({
    assessments: { isInitialized },
}) => isInitialized;

export const selectIsEmpty: TSelector<boolean> = ({
    assessments: { isEmpty },
}) => isEmpty;
/**
 * Actions
 */
export const {
    saveList,
    setIsEmpty,
    setInProgress,
    resetData,
    saveAddAssessmentAllowed,
} = actions;
/**
 * Dispatchers
 */
export const handleGetAddAssessmentAllowed =
    (projectId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = templateString(ENDPOINTS.addAssessmentAllowed, {
            projectId,
        });

        const response = await api.request(Methods.get, { url });

        dispatch(saveAddAssessmentAllowed(response));
    };

export const handleGetList =
    (projectId: string, search?: string) =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const isEmpty = selectIsEmpty(getState());
        dispatch(setInProgress(true));

        const url = templateString(ENDPOINTS.projectAssessments, {
            projectId,
        });

        const queryUrl = createQueryUrl(url, { search });

        try {
            const response = await api.request<TAssessment[]>(Methods.get, {
                url: queryUrl,
            });

            dispatch(saveList(response));

            if (response.length && isEmpty) {
                dispatch(setIsEmpty(false));
            }
        } finally {
            dispatch(setInProgress(false));
        }
    };
