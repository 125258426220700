/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import {
    DEFAULT_PAGE_TITLE,
    DESCRIPTION_BY_PAGE,
    TITLE_BY_PAGE,
} from './constants';
import { PAGES } from 'constants/pages';
/**
 * Utils
 */
import { matchRoutes, RouteObject } from 'react-router-dom';
/**
 * Expo
 */
const PAGES_ROUTES: RouteObject[] = Object.values(PAGES).map((path) => ({
    path,
}));

export const getPageMetaByPath = (
    pathname: string
): Record<string, string | undefined> => {
    const routes = matchRoutes(PAGES_ROUTES, pathname);

    const page = routes?.[0]?.route.path;

    const title = page
        ? TITLE_BY_PAGE[page] ?? DEFAULT_PAGE_TITLE
        : DEFAULT_PAGE_TITLE;

    return {
        title,
        description: page && DESCRIPTION_BY_PAGE[page],
    };
};
