/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Constants
 */
import { ReportState } from 'constants/reports';
/**
 * Expo
 */
export const REPORT_ICON_SRC = {
    [ReportState.draft]: '/assets/images/reportDraft.svg',
    [ReportState.published]: '/assets/images/reportPublished.svg',
};

export const HOVER_DRAFT_ICON_SRC = '/assets/images/hoverDraft.svg';
