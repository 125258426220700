/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { PickerFooterProps } from '@epam/uui-core';
import { ArraySelect, TextArea, TextInput } from 'components/common';
import { useAppSelector, useSelectOptions } from 'hooks';
import { transformElementViewValue } from '../utils';
import { FC, memo, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { selectElementsOptions } from 'store/threatModeling/element/selectors';
import {
    handleCreateInteraction,
    handleUpdateInteraction,
} from 'store/threatModeling/interaction/dispatchers';
import { selectInteractionsCount } from 'store/threatModeling/interaction/selectors';
import { AppDispatch, TNamed } from 'types';
import { ENDPOINTS } from 'constants/api';
import { selectAssets } from 'store/threatModeling/asset/selectors';
import { selectTechnologies } from 'store/threatModeling/technology/selectors';
import { FormFieldsProps } from '../../../../types';
import { createEditableItemCard } from '../../../createEditableItemCard';
import { FullFieldRow, TwoFieldsRow } from '../common';
import { createEditableField } from '../common/EditableField/EditableField';
import { SelectFooter } from '../common/multiSelectArrayFooter';
import { TEXT_AREA_ROWS, ZodNamedTmDto } from '../constants';
import { INTERACTION_READABLE_ID_PREFIX } from './constants';
import { interactionFormSchema, InteractionFormValues } from './schema';

const InteractionEditableField = createEditableField<InteractionFormValues>();

const InteractionFormFields: FC<
    FormFieldsProps<InteractionFormValues>
> = () => {
    const elementOptions = useAppSelector(selectElementsOptions);
    const [fromField, toField] = useWatch<InteractionFormValues>({
        name: ['from', 'to'],
    }) as [TNamed | undefined, TNamed | undefined];
    const fromOptions = useMemo(
        () => elementOptions.filter((element) => element.id !== toField?.id),
        [elementOptions, toField]
    );
    const toOptions = useMemo(
        () => elementOptions.filter((element) => element.id !== fromField?.id),
        [elementOptions, fromField]
    );

    const protocolOptions = useSelectOptions<Array<TNamed>>(
        ENDPOINTS.interactionProtocols
    );

    const assetsOptions = useAppSelector(selectAssets);

    const technologyOptions = useAppSelector(selectTechnologies);

    // TODO: remove any and extract  to a common place
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const renderFooter = (props: PickerFooterProps<any, any>): JSX.Element => (
        <SelectFooter {...props} />
    );

    return (
        <>
            <TwoFieldsRow>
                <InteractionEditableField
                    name="readableId"
                    label="Interaction ID"
                    controllerComponent={TextInput}
                    controllerProps={{
                        // TODO: remove hardcoded value
                        placeholder: 'Enter a text',
                        isReadonly: true,
                    }}
                    dataTestId="interactionForm/readableId"
                />
                <InteractionEditableField
                    name="name"
                    label="Interaction name"
                    controllerComponent={TextInput}
                    controllerProps={{
                        placeholder: 'Enter a text',
                    }}
                    dataTestId="interactionForm/name"
                />
            </TwoFieldsRow>
            <TwoFieldsRow>
                <InteractionEditableField
                    name="from"
                    label="From"
                    controllerComponent={ArraySelect<ZodNamedTmDto>}
                    controllerProps={{
                        placeholder: 'Please select',
                        valueType: 'entity',
                        labelKey: 'name',
                        options: fromOptions,
                        getName: transformElementViewValue,
                    }}
                    transformValueForView={transformElementViewValue}
                    dataTestId="interactionForm/from"
                />
                <InteractionEditableField
                    name="to"
                    label="To"
                    controllerComponent={ArraySelect<ZodNamedTmDto>}
                    controllerProps={{
                        placeholder: 'Please select',
                        valueType: 'entity',
                        labelKey: 'name',
                        options: toOptions,
                        getName: transformElementViewValue,
                    }}
                    transformValueForView={transformElementViewValue}
                    dataTestId="interactionForm/to"
                />
            </TwoFieldsRow>
            <TwoFieldsRow>
                {/* This is not sent to the backend, it's just for presentation in the form AKA "design sugar" */}
                <InteractionEditableField
                    name="type"
                    label="Type"
                    controllerComponent={TextInput}
                    controllerProps={{
                        isReadonly: true,
                    }}
                    dataTestId="interactionForm/type"
                />
                <InteractionEditableField
                    name="assets"
                    label="Assets transferred"
                    controllerComponent={ArraySelect<TNamed>}
                    controllerProps={{
                        placeholder: 'Please select',
                        labelKey: 'name',
                        valueType: 'entity',
                        options: assetsOptions,
                        disableClear: true,
                        mode: 'multi',
                        searchPosition: 'body',
                        idKey: 'id',
                        renderFooter,
                        maxItems: 3,
                        sorting: { field: 'name', direction: 'asc' },
                    }}
                    transformValueForView={(assets) =>
                        assets?.map((asset) => asset.name)
                    }
                    dataTestId="interactionForm/assets"
                />
            </TwoFieldsRow>
            <TwoFieldsRow>
                <InteractionEditableField
                    name="protocol"
                    label="Protocol"
                    controllerComponent={ArraySelect<TNamed>}
                    controllerProps={{
                        placeholder: 'Enter a text',
                        labelKey: 'name',
                        options: protocolOptions,
                        valueType: 'entity',
                    }}
                    transformValueForView={(value) => value?.name ?? null}
                    dataTestId="interactionForm/protocol"
                />

                <InteractionEditableField
                    name="technologies"
                    label="Technology"
                    controllerComponent={ArraySelect<TNamed>}
                    controllerProps={{
                        placeholder: 'Please select',
                        labelKey: 'name',
                        valueType: 'entity',
                        options: technologyOptions,
                        disableClear: true,
                        mode: 'multi',
                        searchPosition: 'body',
                        idKey: 'id',
                        renderFooter,
                        maxItems: 3,
                        sorting: { field: 'name', direction: 'asc' },
                    }}
                    transformValueForView={(technologies) =>
                        technologies?.map((technology) => technology.name)
                    }
                    dataTestId="interactionForm/technologies"
                />
            </TwoFieldsRow>
            <FullFieldRow>
                <InteractionEditableField
                    name="comment"
                    label="Comment"
                    controllerComponent={TextArea}
                    controllerProps={{
                        placeholder: 'Enter a text',
                        rows: TEXT_AREA_ROWS,
                    }}
                    dataTestId="interactionForm/comment"
                />
            </FullFieldRow>
        </>
    );
};

export const InteractionItemCard = memo(
    createEditableItemCard(InteractionFormFields, {
        formSchema: interactionFormSchema,
        onCreate: (values) => {
            return async (dispatch: AppDispatch) => {
                await dispatch(handleCreateInteraction(values));
            };
        },
        onEdit: (id, values) => {
            return async (dispatch: AppDispatch) => {
                await dispatch(handleUpdateInteraction(id, values));
            };
        },
        readableIdPrefix: INTERACTION_READABLE_ID_PREFIX,
        countSelector: selectInteractionsCount,
    })
);

InteractionItemCard.displayName = 'InteractionItemCard';
