/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

export enum ColorNames {
    /**
     * Lightest
     */
    redLightest = 'redLightest', // #FADED9
    blueLightest = 'blueLightest', // #CEEFFF
    cyanLightest = 'cyanLightest', // #E3FCFC
    greenLightest = 'greenLightest', // #EEFFCC
    amberLightest = 'amberLightest', // #FFF2CC
    purpleLightest = 'purpleLightest', // #F2CCFA
    violetLightest = 'violetLightest', // #DBCCFA
    orangeLightest = 'orangeLightest', // #FAE4CF
    /**
     * Light
     */
    redLight = 'redLight', // #F5B7AB
    blueLight = 'blueLight', // #9BDEFF
    cyanLight = 'cyanLight', // #B4F8F8
    greenLight = 'greenLight', // #DDFF99
    amberLight = 'amberLight', // #FFE699
    purpleLight = 'purpleLight', // #E79DF5
    violetLight = 'violetLight', // #BB9DF5
    orangeLight = 'orangeLight', // #F6CBA0
    /**
     * Rested
     */
    red = 'red', // #E54322
    blue = 'blue', // #008ACE
    cyan = 'cyan', // #14CCCC
    green = 'green', // #88CC00
    amber = 'amber', // #FFC000
    purple = 'purple', // #AD14CC
    violet = 'violet', // #5214CC
    orange = 'orange', // #E67E17
    /**
     * Dark (Hovered)
     */
    redDark = 'redDark', // #A72014
    blueDark = 'blueDark', // #00689B
    cyanDark = 'cyanDark', // #0F9E9E
    greenDark = 'greenDark', // #669900
    amberDark = 'amberDark', // #995A00
    purpleDark = 'purpleDark', // #860F9E
    violetDark = 'violetDark', // #3F0F9E
    orangeDark = 'orangeDark', // #89370E
    /**
     * Darkest (Pressed)
     */
    redDarkest = 'redDarkest', // #901111
    blueDarkest = 'blueDarkest', // #004668
    cyanDarkest = 'cyanDarkest', // #0B6F6F
    greenDarkest = 'greenDarkest', // #446600
    amberDarkest = 'amberDarkest', // #804000
    purpleDarkest = 'purpleDarkest', // #5E0B6F
    violetDarkest = 'violetDarkest', // #2D0B6F
    orangeDarkest = 'orangeDarkest', // #72250B
    /**
     * Gray
     */
    // TODO: rework this and all this file.
    //  Possibly, we need to remove this and other colors enums.
    //  Instead, we should use colors from uui directly.
    gray5 = 'gray5', // #FAFAFC
    gray10 = 'gray10', // #F5F6FA
    gray20 = 'gray20', // #EBEDF5
    gray30 = 'gray30', // #E1E3EB
    gray40 = 'gray40', // #CED0DB
    gray50 = 'gray50', // #ACAFBF
    gray60 = 'gray60', // #6C6F80
    gray70 = 'gray70', // #474A59
    gray80 = 'gray80', // #303240
    gray90 = 'gray90', // #1D1E26
}

export enum Colors {
    red = '#E54322',
    blue = '#008ACE',
    cyan = '#14CCCC',
    green = '#88CC00',
    amber = '#FFC000',
    purple = '#AD14CC',
    violet = '#5214CC',
    orange = '#E67E17',
    greenDark = '#669900',
    gray50 = '#ACAFBF',
    gray80 = '#303240',
    gray20 = '#EBEDF5',
    redLightest = '#FADED9',
    blueLightest = '#CEEFFF',
    cyanLightest = '#E3FCFC',
    purpleLightest = '#F2CCFA',
    violetLightest = '#DBCCFA',
    greenLightest = '#efc',
    orangeLightest = '#FAE4CF',
    amberLightest = '#FFF2CC',
}
