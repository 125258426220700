/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
    DOMConversionMap,
    DOMExportOutput,
    LexicalEditor,
    SerializedTextNode,
    TextNode,
} from 'lexical';
import { exportDom, importWithHighlighting } from './helpers';

// Lexical adds redundant tags for some text formats, such as combining <strong> with <b> and <em> with <i>, for compatibility with various editors.
// During export, we transform the `backgroundColor` style of highlighted nodes to a data attribute to preserve highlighting, which can later be restored.
// However, if the highlighted node also has one of the mentioned formats, it gets wrapped by the superclass method before we can perform the transformation.
// As a result, the original node with the style cannot be reached, leading to the loss of highlighting.
// To handle this, we need to go through the wrappers in the DOM hierarchy until we reach the original node.
// For reference, see: https://github.com/facebook/lexical/blob/a02164ba480d02aff1d1bb22006df1773393419b/packages/lexical/src/nodes/LexicalTextNode.ts#L605

/**
 * Extended text node which allows you to extend text nodes with additional properties
 * For example, apply CSS properties or modify the way how text nodes are exported
 */
export class ExtendedTextNode extends TextNode {
    static getType(): string {
        return 'extended-text';
    }

    static clone(node: ExtendedTextNode): ExtendedTextNode {
        // eslint-disable-next-line no-underscore-dangle
        return new ExtendedTextNode(node.__text, node.__key);
    }

    static importDOM(): DOMConversionMap | null {
        const importers = TextNode.importDOM();
        return {
            ...importers,
            code: () => ({
                conversion: importWithHighlighting(importers?.code),
                priority: 1,
            }),
            em: () => ({
                conversion: importWithHighlighting(importers?.em),
                priority: 1,
            }),
            span: () => ({
                conversion: importWithHighlighting(importers?.span),
                priority: 1,
            }),
            strong: () => ({
                conversion: importWithHighlighting(importers?.strong),
                priority: 1,
            }),
            sub: () => ({
                conversion: importWithHighlighting(importers?.sub),
                priority: 1,
            }),
            sup: () => ({
                conversion: importWithHighlighting(importers?.sup),
                priority: 1,
            }),
        };
    }

    static importJSON(serializedNode: SerializedTextNode): TextNode {
        return TextNode.importJSON(serializedNode);
    }

    exportDOM(editor: LexicalEditor): DOMExportOutput {
        const dom = super.exportDOM(editor);
        const { element } = dom;

        if (element) exportDom(element);

        return dom;
    }

    exportJSON(): SerializedTextNode {
        return {
            ...super.exportJSON(),
            type: 'extended-text',
        };
    }
}
