/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { ITab } from 'components/common/PageNavigation/types';
import { PAGES } from 'constants/pages';
import { generatePath } from 'utils';
import { availableTabs } from './constants';

const getPageHref = ({
    tab,
    projectId,
    activityId,
}: {
    tab: ITab;
    projectId: string;
    activityId: string;
}) => {
    const rootPath = PAGES.threatModeling;

    return generatePath(tab.href ?? `${rootPath}/${tab.id}`, {
        projectId,
        activityId,
    });
};

export const buildTabList = (projectId: string, activityId: string): ITab[] => {
    return availableTabs.map((tab) => ({
        ...tab,
        href: getPageHref({ tab, projectId, activityId }),
    }));
};
