/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { useAppDispatch, useAppSelector } from 'hooks';
import { mapTechnologyToFormValues } from '../components/ExpandableTree/components/ItemEditor/forms/Technology/utils';
import { selectTechnologies } from 'store/threatModeling/technology/selectors';
import { useEffect, useMemo } from 'react';
import { selectInteractions } from 'store/threatModeling/interaction/selectors';
import { mapAssetToFormValues } from '../components/ExpandableTree/components/ItemEditor/forms/Asset/utils';
import { mapInteractionToFormValues } from '../components/ExpandableTree/components/ItemEditor/forms/Interaction/utils';
import { selectAssets } from 'store/threatModeling/asset/selectors';
import { handleModelTreeItems } from 'store/threatModeling';
import { ItemType } from '../constants';
import { selectElements } from 'store/threatModeling/element/selectors';
import { mapTrustBoundaryToFormValues } from '../components/ExpandableTree/components/ItemEditor/forms/TrustBoundary/utils';
import { selectTrustBoundaries } from 'store/threatModeling/trustBoundary/selectors';
import { mapElementToFormValues } from '../components/ExpandableTree/components/ItemEditor/forms/Element/utils';

export const useModelTreeItems = (tmId: string) => {
    const dispatch = useAppDispatch();
    const technologies = useAppSelector(selectTechnologies);

    const technologyItems = useMemo(
        () =>
            technologies.map((technology) =>
                mapTechnologyToFormValues(technology)
            ),
        [technologies]
    );

    const assets = useAppSelector(selectAssets);
    const assetItems = useMemo(
        () => assets.map((asset) => mapAssetToFormValues(asset)),
        [assets]
    );

    const elements = useAppSelector(selectElements);
    const elementItems = useMemo(
        () => elements.map((element) => mapElementToFormValues(element)),
        [elements]
    );

    const interactions = useAppSelector(selectInteractions);

    const interactionItems = useMemo(
        () =>
            interactions.map((interaction) =>
                mapInteractionToFormValues(interaction)
            ),
        [interactions]
    );

    const trustBoundaries = useAppSelector(selectTrustBoundaries);
    const trustBoundaryItems = useMemo(
        () =>
            trustBoundaries.map((trustBoundary) =>
                mapTrustBoundaryToFormValues(trustBoundary)
            ),
        [trustBoundaries]
    );

    const treeItems = useMemo(
        () => [
            {
                title: 'Elements',
                type: ItemType.element,
                emptyListMessage:
                    'No elements added. Upload data flow or add elements manually.',
                subItems: elementItems,
            },
            {
                title: 'Interactions',
                type: ItemType.interaction,
                emptyListMessage:
                    'No interactions added. Upload data flow or add interactions manually.',
                subItems: interactionItems,
            },
            {
                title: 'Trust boundaries',
                type: ItemType.trustBoundary,
                emptyListMessage:
                    'No trust boundaries added. Upload data flow or add trust boundaries manually.',
                subItems: trustBoundaryItems,
            },
            {
                title: 'Assets',
                type: ItemType.asset,
                emptyListMessage: 'No assets added. Add assets manually.',
                subItems: assetItems,
            },
            {
                title: 'Attackers',
                type: ItemType.attacker,
                emptyListMessage: 'No attackers added. Add attackers manually.',
                subItems: [],
            },
            {
                title: 'Technologies',
                type: ItemType.technology,
                emptyListMessage:
                    'No technologies added. Add technologies manually.',
                subItems: technologyItems,
            },
        ],
        [
            elementItems,
            interactionItems,
            trustBoundaryItems,
            assetItems,
            technologyItems,
        ]
    );

    useEffect(() => {
        dispatch(handleModelTreeItems(tmId));
    }, [dispatch, tmId]);

    return { treeItems };
};
