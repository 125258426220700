/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useMemo } from 'react';
/**
 * Components
 */
import { MainMenuButton as UuiMainMenuButton } from '@epam/promo';
/**
 * Types
 */
import { WithDataTestId } from 'types';
import { IAdaptiveItem } from '@epam/uui-core';
import { ButtonProps } from '@epam/uui-components';
import { MainMenuButtonProps as MainMenuButtonMods } from '@epam/uui';
/**
 * Utils
 */
import { getRawProps } from 'utils';
import { useLinkClickHandler } from 'react-router-dom';
/**
 * Expo
 */
export const MainMenuButton: React.FC<
    ButtonProps & MainMenuButtonMods & IAdaptiveItem & WithDataTestId
> = ({ dataTestId, href, onClick, target, ...props }) => {
    const internalOnClick = useLinkClickHandler(href || '');

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            if (onClick) {
                onClick(event);
            }

            if (href && target !== '_blank') {
                internalOnClick(event);
            }
        },
        [onClick, internalOnClick, href, target]
    );

    const rawProps = useMemo(() => getRawProps({ dataTestId }), [dataTestId]);

    return (
        <UuiMainMenuButton
            {...props}
            href={href}
            rawProps={rawProps}
            onClick={handleClick}
        />
    );
};
