/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback } from 'react';
/**
 * Hooks
 */
import { useRedirect } from 'hooks';
/**
 * Components
 */
import { ReportList } from './List';
import { EmptyView } from 'components/EmptyView';
/**
 * Hooks
 */
import { useSelector } from 'react-redux';
/**
 * Constants
 */
import { PAGES } from 'constants/pages';
/**
 * Store
 */
import { selectReports, selectInProgress, selectIsEmpty } from 'store/reports';
/**
 * Expo
 */
export const ReportsContent: React.FC = () => {
    const redirect = useRedirect();

    const list = useSelector(selectReports);
    const isEmpty = useSelector(selectIsEmpty);
    const inProgress = useSelector(selectInProgress);

    const handleOpenReport = useCallback(
        (props: { reportId: string }) => {
            redirect(PAGES.singleReport, props);
        },
        [redirect]
    );

    if (inProgress) {
        return null;
    }

    if (isEmpty) {
        return (
            <EmptyView
                alt="no reports"
                background="white"
                title="No reports"
                dataTestId="reports/emptyView"
                src="/assets/images/emptyViews/noContent.svg"
                text="Security specialists will add them soon"
            />
        );
    }

    return list.length ? (
        <ReportList list={list} onClick={handleOpenReport} />
    ) : (
        <EmptyView
            alt="no reports"
            background="white"
            dataTestId="reports/list/emptyView"
            src="/assets/images/emptyViews/noResults.svg"
            text="Try to modify filtering criteria or reset it"
            title="No results with this filter parameters"
        />
    );
};
