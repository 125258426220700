import React from 'react';
import { useSelector } from 'react-redux';
import {
    selectIsIntegratedWithAzure,
    selectIsIntegratedWithJira,
} from 'store/project';
import { Text } from 'components/common';
import css from './style.module.scss';

export const IssueKey: React.FC<{ issueKey?: string; dataTestId?: string }> = ({
    issueKey,
    dataTestId,
}) => {
    const fromJira = useSelector(selectIsIntegratedWithJira);
    const fromAzure = useSelector(selectIsIntegratedWithAzure);
    if (!issueKey) {
        return null;
    }
    const key = (
        <Text cx={css.itemOnRight} dataTestId={`${dataTestId}/issueKey`}>
            {issueKey}
        </Text>
    );
    switch (true) {
        case fromJira:
            return (
                <>
                    <img
                        width="15"
                        height="13"
                        alt="Jira issue"
                        src="/assets/images/jira.svg"
                    />
                    {key}
                </>
            );
        case fromAzure:
            return (
                <>
                    <img
                        width="19"
                        height="18"
                        alt="Azure issue"
                        src="/assets/images/azure.svg"
                    />
                    {key}
                </>
            );
        default:
            return null;
    }
};
