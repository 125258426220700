/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { TActivityStatus } from 'types';
/**
 * Expo
 */
export type TStatusList = {
    status: TActivityStatus;
    amount: number;
}[];

export type TPopover = {
    children: JSX.Element;
    title: string;
    statusList: TStatusList;
};

export type ActivityProps = { activity_statuses: TStatusList; name: string };

export enum TotalActivitiesTitles {
    activities = 'activities in total',
    activity = 'activity in total',
}
