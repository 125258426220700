/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC, memo } from 'react';
/**
 * Types
 */
import { THandlers } from '../types';
import { TProjectTaskActionLog } from 'types';
import { DefaultRecentAction } from 'components/RecentActions/RecentAction/DefaultRecentAction';
/**
 * Expo
 */
export const ManualTaskCreated: FC<TProjectTaskActionLog & THandlers> = memo(
    ({
        author: { name },
        getManualTaskHref,
        details: {
            current_state: { id, name: taskName },
        },
    }) => (
        <DefaultRecentAction
            dataTestId="manualTaskCreated"
            user={name}
            action="created manual task"
            target={taskName}
            href={getManualTaskHref(id)}
        />
    )
);
