/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Hooks
 */
import { useAppDispatch } from 'hooks';
/**
 * Constants
 */
import { Methods } from 'constants/request';
import { ENDPOINTS } from 'constants/api';
/**
 * Services
 */
import { requestAuth } from 'services/api/requestAuth';
/**
 * Store
 */
import { setInProgress } from 'store/auth/actions';
/**
 * Utils
 */
import { showErrors } from 'utils';

/**
 * Expo
 */
const LoginButton: React.FC = () => {
    const dispatch = useAppDispatch();
    const handleSso = async () => {
        try {
            dispatch(setInProgress(true));

            const { redirectUrl } = await requestAuth(Methods.get, {
                url: ENDPOINTS.sso,
            });

            if (redirectUrl) {
                window.location.replace(redirectUrl);
            } else {
                dispatch(setInProgress(false));
            }
        } catch (messages) {
            showErrors(messages);
        }
    };
    return (
        <button type="button" onClick={handleSso} id="home/buttons/logIn">
            CLIENT LOGIN
        </button>
    );
};

export default LoginButton;
