/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC } from 'react';
/**
 * Components
 */
import { FindingList } from './Fields/FindingList';
import { FieldController } from 'components/Fields';
/**
 * Expo
 */
export const Findings: FC = () => (
    <FieldController
        defaultValue={[]}
        name="findingsIds"
        component={FindingList}
    />
);
