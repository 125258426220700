/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createSlice } from '@reduxjs/toolkit';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { RoleTypes } from 'constants/roles';
import { Methods } from 'constants/request';
import {
    INITIAL_STATE,
    PROJECT_REPRESENTATIVE_DEFAULT_STATUSES,
    SETH_DEFAULT_STATUSES,
} from './constants';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Types
 */
import { TFormValues } from 'components/Forms/Finding/types';
import {
    TFilter,
    TGetState,
    TSelector,
    AppDispatch,
    PaginatedResponse,
    TFilterPagination,
    TFindingListItem,
} from 'types';
/**
 * Store
 */
import { selectUserRole } from 'store/user';
/**
 * Utils
 */
import { createQueryUrl } from 'utils';
import { SortVariants } from 'constants/filter';
import { createFinding, editFinding, generateFinding } from './utils';
import { findingsDidUpdateAction } from 'store/middleware/backgroundUpdate/actions';
/**
 * Reducer
 */
const { reducer, actions } = createSlice({
    name: 'findings',
    initialState: INITIAL_STATE,
    reducers: {
        saveList: (state, { payload: { list, totalElements } }) => {
            state.list = list;
            state.totalElements = totalElements;

            state.isInitialized = true;
        },
        saveFilter: (state, { payload }) => {
            state.filter = payload;
        },
        saveSearch: (state, { payload }) => {
            state.search = payload;
        },
        savePagination: (state, { payload }) => {
            state.pagination = payload;
        },
        saveSort: (state, { payload }) => {
            state.sort = payload;
        },
        setInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        setIsEmpty: (state, { payload }) => {
            state.isEmpty = payload;
        },
        resetData: () => INITIAL_STATE,
        resetDataPartially: (state) => ({
            ...state,
            sort: INITIAL_STATE.sort,
            search: INITIAL_STATE.search,
            pagination: INITIAL_STATE.pagination,
        }),
    },
});

export default reducer;
/**
 * Selectors
 */
export const selectFindings: TSelector<TFindingListItem[]> = ({
    findings: { list },
}) => list;
export const selectIsEmpty: TSelector<boolean> = ({ findings: { isEmpty } }) =>
    isEmpty;
export const selectInProgress: TSelector<boolean> = ({
    findings: { inProgress },
}) => inProgress;
export const selectIsInitialized: TSelector<boolean> = ({
    findings: { isInitialized },
}) => isInitialized;
export const selectTotalElements: TSelector<number> = ({
    findings: { totalElements },
}) => totalElements;
export const selectFilter: TSelector<TFilter> = ({ findings: { filter } }) =>
    filter;
export const selectSearch: TSelector<string> = ({ findings: { search } }) =>
    search;
export const selectPagination: TSelector<TFilterPagination> = ({
    findings: { pagination },
}) => pagination;
export const selectSort: TSelector<SortVariants> = ({ findings: { sort } }) =>
    sort;
/**
 * Actions
 */
export const {
    saveList,
    saveSort,
    resetData,
    setIsEmpty,
    saveSearch,
    saveFilter,
    setInProgress,
    savePagination,
    resetDataPartially,
} = actions;
/**
 * Dispatchers
 */
export const handleGetFindings =
    (projectId: string) =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const state = getState();
        const sort = selectSort(state);
        const role = selectUserRole(state);
        const search = selectSearch(state);
        const isEmpty = selectIsEmpty(state);
        const pagination = selectPagination(state);
        const { status, ...filter } = selectFilter(state);

        const defaultStatuses =
            role === RoleTypes.projectRepresentative
                ? PROJECT_REPRESENTATIVE_DEFAULT_STATUSES
                : SETH_DEFAULT_STATUSES;

        const queryParams = {
            sort,
            search,
            projectId,
            status: status?.length ? status : defaultStatuses,
            ...filter,
            ...pagination,
        };

        const queryUrl = createQueryUrl(
            ENDPOINTS.findingsFiltered,
            queryParams,
            { arrayFormat: 'repeat' }
        );

        dispatch(setInProgress(true));

        try {
            const {
                data,
                page: { total_elements: totalElements },
            } = await api.request<PaginatedResponse<TFindingListItem[]>>(
                Methods.get,
                {
                    url: queryUrl,
                }
            );

            if (data.length && isEmpty) {
                dispatch(setIsEmpty(false));
            }

            dispatch(saveList({ list: data, totalElements }));
        } finally {
            dispatch(setInProgress(false));
        }
    };

export const handleCreateFinding =
    (values: TFormValues) =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const { currentProject } = getState().user.data;

        if (!currentProject) return;

        await createFinding(values, currentProject.id);

        dispatch(handleGetFindings(currentProject.id));
        dispatch(findingsDidUpdateAction());
    };

export const handleEditFinding =
    (findingId: string, values: TFormValues, notify: boolean) =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const { currentProject } = getState().user.data;

        if (!currentProject) return;

        await editFinding(values, currentProject.id, findingId, notify);

        dispatch(findingsDidUpdateAction());
    };

export const handleGenerateFinding =
    (vulnerabilityId: string, values: TFormValues) =>
    async (dispatch: AppDispatch): Promise<string> => {
        const findingId = await generateFinding(values, vulnerabilityId);

        dispatch(findingsDidUpdateAction());

        return findingId;
    };
