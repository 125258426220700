/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { TDashboardState } from './types';
/**
 * Expo
 */
export const INITIAL_STATE: TDashboardState = {
    inProgress: false,
    isInitialized: false,
    blockedActivities: [],
    violatedPolicies: [],
    assessments: {
        list: [],
    },
    findings: {
        total: 0,
        list: [],
        comparison: [],
        chartData: {
            statuses: [],
            severities: [],
        },
    },
};

export const DEFAULT_TOP_FINDINGS_COUNT = '5';
