/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
export enum FindingResolution {
    fixed = 'FIXED',
    falsePositive = 'FALSE_POSITIVE',
    noLongerValid = 'NO_LONGER_VALID',
}

export const FINDING_RESOLUTION_CAPTIONS = {
    [FindingResolution.fixed]: 'Fixed',
    [FindingResolution.falsePositive]: 'False-positive',
    [FindingResolution.noLongerValid]: 'No longer valid',
};

export enum CvssScoreValues {
    min = 0,
    max = 10,
    precision = 1,
}

export enum AsvsLevelViolated {
    first = 'FIRST_LEVEL',
    second = 'SECOND_LEVEL',
    third = 'THIRD_LEVEL',
}
