/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useAppForm } from 'hooks';
import { Button } from 'components/common';
import { Panel, FlexSpacer, ModalFooter } from '@epam/promo';
import { AssessmentGeneral } from 'components/Forms/AssessmentGeneral';
import { TFormContainer, TFormValues } from './types';
import css from './style.module.scss';

export const FormContainer: React.FC<TFormContainer> = ({
    onClose,
    onSubmit,
}) => {
    const methods = useAppForm<TFormValues>({
        id: 'assessmentCreationModalForm',
    });

    const handleSubmit = methods.handleSubmit(async (values) => {
        await onSubmit(values);

        onClose();
    });

    return (
        <>
            <Panel cx={css.formContainer}>
                <FormProvider {...methods}>
                    <AssessmentGeneral />
                </FormProvider>
            </Panel>
            <ModalFooter borderTop>
                <FlexSpacer />
                <Button
                    fill="none"
                    color="neutral"
                    onClick={onClose}
                    caption="Cancel"
                    dataTestId="assessments/creationModal/buttons/cancel"
                />
                <Button
                    color="primary"
                    caption="Save"
                    onClick={handleSubmit}
                    dataTestId="assessments/creationModal/buttons/submit"
                />
            </ModalFooter>
        </>
    );
};
