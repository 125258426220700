/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Hooks
 */
import { useRouteParams } from 'hooks';
/**
 * Components
 */
import { Link } from 'react-router-dom';
import { FlexRow } from 'components/common';
import { Text, IconContainer } from '@epam/promo';
/**
 * Assets
 */
import css from './style.module.scss';
import { ReactComponent as backIcon } from '@epam/assets/icons/common/navigation-back-18.svg';
/**
 * Types
 */
import { TProps } from './types';
/**
 * Utils
 */
import { generatePath } from 'utils';
/**
 * Expo
 */
export const BackTo: React.FC<TProps> = ({
    path,
    caption,
    onClick,
    dataTestId,
}) => {
    const routeParams = useRouteParams();

    const href = path && generatePath(path, routeParams);

    const backToElement = (
        <FlexRow onClick={onClick} cx={css.container} dataTestId={dataTestId}>
            <IconContainer icon={backIcon} style={{ fill: 'white' }} />
            <Text color="gray5" fontSize="12">
                {caption ? `Back to ${caption}` : 'Back'}
            </Text>
        </FlexRow>
    );

    return !href ? (
        backToElement
    ) : (
        <Link className={css.link} to={href}>
            {backToElement}
        </Link>
    );
};
