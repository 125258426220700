/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Store
 */
import { handleLogout } from '../user';
import { handleGetMessage } from '../singleRequest';
import { handleGetReports } from '../reports';
import { selectCurrentProjectId } from '../project';
import { getList as getNotificationsList } from '../notifications/actions';
/**
 * Constants
 */
import { EventTypes } from 'constants/sockets';
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
import { ReportStatus } from 'constants/reports';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Types
 */
import { AppDispatch, TGetState, TReport } from 'types';
import { TEventData, TOpenSocketChannelProps } from './types';
/**
 * Utils
 */
import { NotificationTypes, showNotification, templateString } from 'utils';

/**
 * Expo
 */
const getReportDataAndShowNotification =
    (reportId: string) =>
    async (dispatch: AppDispatch, getState: TGetState): Promise<void> => {
        const currentProjectId = selectCurrentProjectId(getState());
        const url = templateString(ENDPOINTS.singleReport, {
            reportId,
        });
        const {
            name,
            project_id: projectId,
            status,
        } = await api.request<TReport>(Methods.get, { url });

        const isFailedStatus = status === ReportStatus.failed;

        const text = !isFailedStatus
            ? `Report ${name} was generated successfully`
            : `Report ${name} was not generated successfully.
                                   Please, try again or contact the support team`;

        showNotification({
            text,
            type: isFailedStatus
                ? NotificationTypes.error
                : NotificationTypes.success,
        });

        if (currentProjectId === projectId)
            dispatch(handleGetReports(projectId));
    };

export const openSocketChannel =
    ({ userId }: TOpenSocketChannelProps) =>
    (dispatch: AppDispatch): void => {
        const webSocketInstance = api.createWebSocketInstance({ userId });

        webSocketInstance.onmessage = ({ data }) => {
            try {
                const {
                    source_id: sourceId,
                    event_type: eventType,
                }: TEventData = JSON.parse(data);

                switch (eventType) {
                    case EventTypes.forcedUserLogout:
                        dispatch(handleLogout());
                        break;
                    case EventTypes.newMessage:
                        dispatch(handleGetMessage(sourceId));
                        break;
                    case EventTypes.projectStarted:
                        dispatch(getNotificationsList());
                        break;
                    case EventTypes.reportDocxCreated: {
                        dispatch(getReportDataAndShowNotification(sourceId));
                        break;
                    }
                    case EventTypes.reportDocxFailed: {
                        dispatch(getReportDataAndShowNotification(sourceId));
                        break;
                    }
                    default:
                        break;
                }
            } catch (error) {
                webSocketInstance.close();
            }
        };
    };
