/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
/**
 * Hooks
 */
import { useRouteParams } from 'hooks';
/**
 * Types
 */
import {
    TFilterData,
    TOptionsByName,
    TFilterContextProps,
    TQueryParametersByName,
    TGetFieldDataReturnValue,
} from './types';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Utils
 */
import { createQueryUrl } from 'utils';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
/**
 * Context
 */
import { Context } from './context';
/**
 * Expo
 */
export const FilterContext: React.FC<TFilterContextProps> = ({
    value,
    children,
    pageType,
}) => {
    const [optionsByName, setOptionsByName] = useState<TOptionsByName>({});
    const [queryParameterByName, setQueryParameterByName] =
        useState<TQueryParametersByName>({});

    const { projectId } = useRouteParams();

    const handleGetFilterData = useCallback(async () => {
        const url = createQueryUrl(ENDPOINTS.filters, {
            projectId,
            pageType,
        });

        const { data, query_parameters: queryParameters } =
            await api.request<TFilterData>(Methods.get, { url });

        setOptionsByName(data);
        setQueryParameterByName(queryParameters);
    }, [projectId, pageType]);

    useEffect(() => {
        handleGetFilterData();
    }, [handleGetFilterData]);

    const handleGetFieldData = useCallback(
        (fieldName: string): TGetFieldDataReturnValue => {
            const queryParameter = queryParameterByName[fieldName];

            return {
                queryParameter,
                value: value[queryParameter],
                options: optionsByName[fieldName],
            };
        },
        [value, optionsByName, queryParameterByName]
    );

    const contextValue = useMemo(
        () => ({ getFieldData: handleGetFieldData }),
        [handleGetFieldData]
    );

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
