/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createSlice } from '@reduxjs/toolkit';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
import { INITIAL_STATE } from './constants';
/**
 * Utils
 */
import { templateString } from 'utils';
import { mapDataToState } from './utils';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Types
 */
import { TData } from './types';
import { AppDispatch, TSelector } from 'types';
/**
 * Reducer
 */
const { reducer, actions } = createSlice({
    name: 'singleTask',
    initialState: INITIAL_STATE,
    reducers: {
        saveData: (state, { payload }) => {
            state.data = mapDataToState(payload);

            state.isInitialized = true;
        },
        setInProgress: (state, { payload }) => {
            state.inProgress = payload;
        },
        resetData: () => INITIAL_STATE,
    },
});

export default reducer;
/**
 * Selectors
 */
export const selectSingleTask: TSelector<TData> = ({ singleTask: { data } }) =>
    data;
export const selectInProgress: TSelector<boolean> = ({
    singleTask: { inProgress },
}) => inProgress;
export const selectIsInitialized: TSelector<boolean> = ({
    singleTask: { isInitialized },
}) => isInitialized;
/**
 * Actions
 */
export const { saveData, resetData, setInProgress } = actions;
/**
 * Dispatchers
 */
export const handleGetData =
    (taskId: string, projectId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setInProgress(true));

        const url = templateString(ENDPOINTS.singleTaskByProject, {
            taskId,
            projectId,
        });

        const response = await api.request(Methods.get, { url });

        dispatch(saveData(response));

        dispatch(setInProgress(false));
    };
export const handleResetData =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(resetData());
    };
