/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { generatePath as commonGeneratePath, Params } from 'react-router-dom';
/**
 * Constants
 */
import { environment, EnvironmentTypes } from 'constants/environments';
/**
 * Expo
 */
export const generatePath = (path: string, params: Params<string>): string => {
    if (environment === EnvironmentTypes.local) {
        return commonGeneratePath(path, params);
    }

    try {
        return commonGeneratePath(path, params);
    } catch (error) {
        if (environment !== EnvironmentTypes.prod) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
        return '';
    }
};
