/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { AppDispatch, Interaction } from 'types';
import { createQueryUrl } from 'utils';
import { ENDPOINTS } from 'constants/api';
import { api } from 'services/api';
import { Methods } from 'constants/request';
import { InteractionFormValues } from 'pages/ThreatModeling/tabs/ModelTreeTab/components/ExpandableTree/components/ItemEditor/forms/Interaction/schema';
import {
    mapFormValuesToInteractionCreatePayload,
    mapFormValuesToInteractionUpdatePayload,
} from 'pages/ThreatModeling/tabs/ModelTreeTab/components/ExpandableTree/components/ItemEditor/forms/Interaction/utils';
import {
    addInteraction,
    saveInteractions,
    setInteractionsLoadingInProgress,
    updateInteraction,
} from './slice';
import { InteractionCreatePayload, InteractionUpdatePayload } from './types';

export const handleGetInteractions =
    (tmId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            dispatch(setInteractionsLoadingInProgress(true));
            const url = createQueryUrl(ENDPOINTS.interactions, {
                tmId,
            });
            const interactions = await api.request<Interaction[]>(Methods.get, {
                url,
            });
            dispatch(saveInteractions(interactions));
        } finally {
            dispatch(setInteractionsLoadingInProgress(false));
        }
    };

export const handleCreateInteraction =
    (form: InteractionFormValues) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = ENDPOINTS.interactions;
        const requestBody = mapFormValuesToInteractionCreatePayload(form);
        const interaction = await api.request<
            Interaction,
            InteractionCreatePayload
        >(Methods.post, { url, data: requestBody });

        dispatch(addInteraction(interaction));
    };

export const handleUpdateInteraction =
    (id: string, form: InteractionFormValues) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const url = `${ENDPOINTS.interactions}/${id}`;
        const requestBody = mapFormValuesToInteractionUpdatePayload(form);
        const updatedInteraction = await api.request<
            Interaction,
            InteractionUpdatePayload
        >(Methods.put, { url, data: requestBody });

        dispatch(updateInteraction(updatedInteraction));
    };
