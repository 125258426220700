/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Types
 */
import { TData } from './types';
import { TGeneralInitialState } from 'types';
/**
 * Expo
 */
export const INITIAL_STATE: TGeneralInitialState<TData> & { isEmpty: boolean } =
    {
        data: {
            list: [],
            hasApprovedTarget: false,
        },
        inProgress: false,
        isInitialized: false,
        isEmpty: true,
    };
