/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { Panel, Text } from '@epam/promo';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { IPreviewNotPDF } from './types';
/**
 * Utils
 */
import { getNameWithoutExtension } from 'utils';
/**
 * Expo
 */
export const PreviewNotPDF: React.FC<IPreviewNotPDF> = ({ name }) => (
    <Panel cx={css.previewNotPDF}>
        <Panel cx={css.previewImgContainer}>
            <img
                className={css.previewImg}
                alt="preview for this file type not supported"
                src="/assets/images/documentPreviewPlaceholder.svg"
            />
        </Panel>
        <Text cx="text-center text-bold" fontSize="18" color="gray80">
            Unable to preview file &quot;{getNameWithoutExtension(name)}&quot;
        </Text>
        <Text cx="text-center" fontSize="14" color="gray60">
            Download file to view it
        </Text>
    </Panel>
);
