/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React from 'react';
/**
 * Components
 */
import { FlexCell } from '@epam/promo';
import { DocumentList } from 'components/DocumentList';
import { AttachmentList } from 'components/AttachmentList';
import { AttachmentsContext } from './Context';
/**
 * Assets
 */
import css from './style.module.scss';
/**
 * Types
 */
import { TCombineAttachmentsProps } from './types';
/**
 * Expo
 */
export const CombineAttachments: React.FC<TCombineAttachmentsProps> = ({
    newAttachments,
    isEditingMode,
    savedAttachments,
    dataTestId,
    onDeleteNewAttachment,
    onDeleteSavedAttachment,
}) => {
    const { attachmentsEndpoint } = React.useContext(AttachmentsContext);
    return (
        <FlexCell cx={css.attachments}>
            {!savedAttachments?.length ? null : (
                <DocumentList
                    columnsInRow={3}
                    clickable={false}
                    list={savedAttachments}
                    isEditingMode={isEditingMode}
                    onRemove={onDeleteSavedAttachment}
                    dataTestId={dataTestId}
                    contentUrl={attachmentsEndpoint}
                />
            )}
            {!newAttachments?.length ? null : (
                <AttachmentList
                    columnsInRow={3}
                    list={newAttachments}
                    onRemove={onDeleteNewAttachment}
                    contentUrl={attachmentsEndpoint}
                />
            )}
        </FlexCell>
    );
};
