/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import { createSlice } from '@reduxjs/toolkit';
/**
 * Constants
 */
import { INITIAL_STATE } from './constants';
/**
 * Types
 */
import { AppDispatch, TSelector } from 'types';
/**
 * Services
 */
import { api } from 'services/api';
/**
 * Constants
 */
import { ENDPOINTS } from 'constants/api';
import { Methods } from 'constants/request';
/**
 * Utils
 */
import { normalize } from './utils';

/**
 * Reducer
 */
const { reducer, actions } = createSlice({
    name: 'featureFlags',
    initialState: INITIAL_STATE,
    reducers: {
        saveFlags: (state, { payload }) => {
            state.flags = normalize(payload);
        },
    },
});

export default reducer;
/**
 * Selectors
 */
export const selectFlag =
    (name: string): TSelector<boolean> =>
    ({ featureFlags: { flags } }) =>
        Boolean(flags[name]);

/**
 * Actions
 */
export const { saveFlags } = actions;
/**
 * Dispatchers
 */
export const handleGetFlags =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        const response = await api.request(Methods.get, {
            url: ENDPOINTS.featureToggles,
        });

        dispatch(saveFlags(response));
    };
