/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/**
 * Dependencies
 */
import React, { FC } from 'react';
/**
 * Components
 */
import { IconContainer } from 'components/common';
/**
 * Utils
 */
import { getIconProps } from './utils';
/**
 * Expo
 */
export const FileIcon: FC<{ extension: string; size?: number }> = ({
    extension,
    size = 24,
}) => <IconContainer size={size} {...getIconProps(extension)} />;
